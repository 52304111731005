import axios from "axios";

export const getAll = (cancelToken) => {
    return axios.get("/api/services/app/ProgramCode/GetAllGearUpActivityCodes", { cancelToken });
};

export const create = (input) => {
    return axios.post("/api/services/app/ProgramCode/CreateGearUpActivityCode", input);
};

export const update = (input) => {
    return axios.put("/api/services/app/ProgramCode/UpdateGearUpActivityCode", input);
};
