import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import TagClassifications from "./classifications";
import TagsAdmin from "./tagsAdmin";
import TertiaryNav from "../../../common/tertiaryNav";
import * as api from "../../../api/tagging";
import TagList from "./components/taglist";

import "./index.css";

const links = [
    {
        label: "Active Tags",
        to: "/admin/tagging/activeTags",
        permission: "Admin.Admissions",
    },
    {
        label: "Inactive Tags",
        to: "/admin/tagging/inactiveTags",
        permission: "Admin.Admissions",
    },
    {
        label: "Active Classifications",
        to: "/admin/tagging/activeClassifications",
        permission: "Admin.Admissions",
    },
    {
        label: "Inactive Classifications",
        to: "/admin/tagging/inctiveClassifications",
        permission: "Admin.Admissions",
    },
];

class AdminTaggingPage extends Component {
    state = {
        activeTags: [],
        inactiveTags: [],
        activeTagClassifications: [],
        inactiveTagClassifications: [],
        inactiveClass: [],
    };
    componentDidMount = async () => {
        await this.updateAll();
    };

    doGetActiveTags = async  () => {
   
        const tags = await api.getActiveTags();

        const input = {
            searchText: "",
            isActive: true,
            UseEvents: true,
            UseAdvisors: true
}
       // const choices = await api.returnChoices(input);
       
        return tags.data.result;

        }

    doGetActiveClassifications = async () => {
       
        const tags = await api.getActiveClassifications();

        return tags.data.result;

    }

    doGetInactiveTags = async () => {
       
        const tags = await api.getInactiveTags();

        return tags.data.result;

    } 
    doGetInactiveClassifications = async () => {
       
        const tags = await api.getInactiveClassifications();

        return tags.data.result;

    }


    doAddClassifications = async (input) => {

        const tags = await api.addClassification(input);
        await this.updateAll();
        return tags.data.result;

    }

    doUpdateClassifications = async (input) => {

        const tags = await api.updateClassification(input);
        await this.updateAll();
        return tags.data.result;

    }
    doAddTag = async (input) => {
        const tag = await api.addTag(input);
        await this.updateAll();
        return tag.data.result;
    }

    doUpdateTag = async (input) => {

        
        const tags = await api.updateTag(input);
        await this.updateAll();
        return tags.data.result;

    }
    onAddSubtag = (id, title) => {
        let sel = [];
        
        if (title == "Active Tags") {

            sel = this.state.activeTags.filter(tag => tag.id == id);

            

            sel[0].subtags.subtags.push({ tagId: id, isActive: true, id: "", ipedRequired: false, descriptorRequired: false });

        } else {

        }
        
        
    }
    onSubTagUpdate = async (values) => {
        if (values.id == "00000000-0000-0000-0000-000000000000") {
            await api.addSubTag(values);
        } else {
            await api.updateSubTag(values);
        }
        
        await this.updateAll();

    }

    updateAll = async () => {
        const active = await this.doGetActiveTags();
        const inactive = await this.doGetInactiveTags();
        const activeTagClassifications = await this.doGetActiveClassifications();

        const inactiveClass = await this.doGetInactiveClassifications();
        const inactiveTagClassifications = activeTagClassifications.concat(inactiveClass);



        this.setState({ activeTags: active, inactiveTags: inactive, activeTagClassifications: activeTagClassifications, inactiveTagClassifications: inactiveTagClassifications, inactiveClass: inactiveClass });


    }
    render() {
        
    return (
        <div className="admin-admissions-page">
            <TertiaryNav links={links} />
            <div className="hr-line-medium margin-top" />
            <Route
                exact
                path="/admin/tagging/activeTags"
                render={() => (
                    <Fragment>
                        <TagsAdmin tags={this.state.activeTags} title="Active Tags" classifications={this.state.activeTagClassifications} onAdd={this.doAddTag} onUpdate={this.doUpdateTag} onSubTagUpdate={this.onSubTagUpdate} onAddSubtag={this.onAddSubtag } />
                    </Fragment>
                )}
            />
            <Route
                exact
                path="/admin/tagging/inactiveTags"
                render={() => (
                    <Fragment>
                        <TagsAdmin tags={this.state.inactiveTags} title="Inactive Tags" classifications={this.state.inactiveTagClassifications} onAdd={this.doAddTag} onUpdate={this.doUpdateTag} onSubTagUpdate={this.onSubTagUpdate} onAddSubtag={this.onAddSubtag} />
                    </Fragment>
                )}
            />
            <Route
                exact
                path="/admin/tagging/activeClassifications"
                render={() => (
                    <Fragment>
                        <TagClassifications classifications={this.state.activeTagClassifications} title="Active Classifications" onAdd={this.doAddClassifications} onUpdate={this.doUpdateClassifications} />
                    </Fragment>
                )}
            />
            <Route
                exact
                path="/admin/tagging/inctiveClassifications"
                render={() => (
                    <Fragment>
                        <TagClassifications classifications={this.state.inactiveClass} title="Inactive Classifications" onAdd={this.doAddClassifications} onUpdate={this.doUpdateClassifications} />
                    </Fragment>
                )}
            />
            <Route path="/admin/tagging" exact render={() => <Redirect exact path="/admin/tagging" to="/admin/tagging/activeTags" />} />
        </div>
    );
}
}

export default AdminTaggingPage;