import React, { useContext, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import * as ApplicationApi from "../../../api/applications";
import { AdmissionsTabs, ApplicationStatus } from "../../logic/enums";
import Button from "../../../support/components/Button";

import "./index.css";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { ApplicationStatusContext } from "../../../support/context";
import useAdmissionsSettings from "../../../common/useAdmissionsSettings";
import useAdmissions from "../../../common/useAdmissions";

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const NavMenu = (props) => {
    const [showInComplete, setShowInComplete] = useState(false);

    const { ajaxLoad, ajaxComplete, ajaxError } = useAjaxStatus();
    const { admissionSettings } = useAdmissionsSettings();
    const { admissions, loadIdentifiers, loadApplication } = useAdmissions();
    const intl = useIntl();
    const context = useContext(ApplicationStatusContext);
    const location = useLocation();
    const history = useHistory();
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const onSubmitClick = async () => {
        if (admissions === null) {
            return;
        }
        if (admissions.progress.complete) {
            ajaxLoad();
            try {
                await ApplicationApi.submitApplication(admissions.id);
                loadApplication(admissions.id);
                ajaxComplete();
            } catch (err) {
                ajaxError(err);
            }
            context.updateApplicationStatus(true, true);
            // TODO: Show Modal
        } else {
            setShowInComplete(true);
            context.updateApplicationStatus(true, false);
        }
    };

    const isTabComplete = (tabName) => {
        if (admissions === null) {
            return false;
        }

        return admissions.progress.tabProgress[tabName] === true;
    };

    const renderStatus = (tabName) => {
        const complete = isTabComplete(tabName);

        if (complete) {
            return <FontAwesome name="circle" className="complete" />;
        } else if (context.isApplicationSubmitted && !context.isApplicationValid) {
            return <FontAwesome name="circle" className="incomplete" />;
        } else {
            return <FontAwesome name="circle" />;
        }
    };

    const submitEnabled = admissions !== null && admissions.progress.complete;
    const isSubmitted =
        admissions !== null &&
        (admissions.status === ApplicationStatus.Submitted ||
            admissions.status === ApplicationStatus.VirtualOrientation ||
            admissions.status === ApplicationStatus.Complete ||
            admissions.status === 3 ||
            admissions.status === ApplicationStatus.ApplicantRejected);
    const submitDate = admissions !== null && admissions.submissionDate !== null ? moment(admissions.submissionDate).format("M/D/YY h:mm a") : "";
    const showVirtualOrientationNav =
        admissions !== null &&
        admissions.didVirtualOrientation &&
        admissions.status !== ApplicationStatus.NotSubmitted &&
        admissions.status !== ApplicationStatus.Submitted &&
        admissions.status !== ApplicationStatus.Rejected &&
        admissionSettings.orientationVideoEnabled;

    const initialTabsLocation = location.pathname === "/admissions" ? "/admissions/student" : location.pathname;
    
    return (
        <>
            {!isDesktop ? (
                <div className="mobile-nav-menu">
                    
                    
                    <Tabs
                        value={initialTabsLocation}
                        onChange={(e, value) => history.push(value)}
                        aria-label="wrapped label tabs example"
                        scrollButtons="on"
                        variant="scrollable"
                        className="nav-menu"
                    >
                        <LinkTab
                            value="/admissions/student"
                            label={intl.formatMessage({ id: "nav.studentOnly", defaultMessage: "[Student Information]" })}
                            icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.Student) ? "complete" : ""} />}
                            wrapped
                            {...a11yProps("nav.student")}
                        />
                        <LinkTab
                            value="/admissions/parent"
                            label={intl.formatMessage({ id: "nav.parentOnly", defaultMessage: "[Parent/Guardian Information]" })}
                            icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.Parent) ? "complete" : ""} />}
                            wrapped
                            {...a11yProps("nav.parent")}
                        />
                        <LinkTab
                            value="/admissions/household"
                            label={intl.formatMessage({ id: "nav.householdOnly", defaultMessage: "[Household Information]" })}
                            icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.Household) ? "complete" : ""} />}
                            wrapped
                            {...a11yProps("nav.household")}
                        />
                        <LinkTab
                            value="/admissions/documents"
                            label={intl.formatMessage({ id: "nav.documentsOnly", defaultMessage: "[Common Documents]" })}
                            icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.CommonDocs) ? "complete" : ""} />}
                            wrapped
                            {...a11yProps("nav.documents")}
                        />
                        {!admissionSettings.orientationVideoEnabled && (
                            <LinkTab
                                value="/admissions/contracts"
                                label={intl.formatMessage({ id: "nav.contracts", defaultMessage: "[Contracts]" })}
                                icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.Contracts) ? "complete" : ""} />}
                                wrapped
                                {...a11yProps("nav.contracts")}
                            />
                        )}

                        {showVirtualOrientationNav && (
                            <>
                                <LinkTab
                                    value="/admissions/orientationvideo"
                                    label={intl.formatMessage({ id: "nav.orientationvideoOnly", defaultMessage: "[Orientation Videos]" })}
                                    icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.OrientationVideo) ? "complete" : ""} />}
                                    wrapped
                                    {...a11yProps("nav.orientationvideo")}
                                />
                                {admissionSettings.orientationVideoEnabled && (
                                    <LinkTab
                                        value="/admissions/contracts"
                                        label={intl.formatMessage({ id: "nav.contracts", defaultMessage: "[Contracts]" })}
                                        icon={<FiberManualRecordIcon className={isTabComplete(AdmissionsTabs.Contracts) ? "complete" : ""} />}
                                        wrapped
                                        {...a11yProps("nav.contracts")}
                                    />
                                )}
                            </>
                        )}
                    </Tabs>
                    <Box display="flex" flexDirection="column" p="0 10px">
                        
                            <Button
                                id="applications.backmobile"
                                defaultMessage="Back"
                                className={`submit-button`}
                                onClick={() => props.back()}

                            />
                        
                        <Button
                            id="student.submit"
                            defaultMessage="[student.submit]"
                            className={`submit-button ${submitEnabled && !isSubmitted ? "" : "disabled"}`}
                            onClick={onSubmitClick}
                            invalid={isSubmitted}
                            disabled={!submitEnabled }
                        />
                        <FormattedMessage
                            id="student.submit.notice"
                            defaultMessage="[student.submit.notice]"
                            children={(msg) => <div className="submit-message">{msg}</div>}
                        />
                        {isSubmitted && (
                            <div className="submit-message">
                                <FormattedMessage id="student.submit.submitted" defaultMessage="[student.submit.submitted]" tagName="span" />
                                <span>: {submitDate}</span>
                            </div>
                        )}
                    </Box>
                </div>
            ) : (
                    <div>
                        <Button
                            id="student.back"
                            defaultMessage="Back"
                            className={`backbutton`}
                            onClick={() => props.back()}

                        /><br />
                    <div className="nav-menu">
                       
                        
                    <div>
                        <NavLink to="/admissions/student" activeClassName="selected">
                            <div className="nav-menu__nav-item">
                                <FormattedMessage id="nav.student" defaultMessage="[Student Information]" tagName="span" />
                            </div>
                            {renderStatus(AdmissionsTabs.Student)}
                        </NavLink>
                        <NavLink to="/admissions/parent" activeClassName="selected">
                            <div className="nav-menu__nav-item">
                                <FormattedMessage id="nav.parent" defaultMessage="[Parent/Guardian Information]" tagName="span" />
                            </div>
                            {renderStatus(AdmissionsTabs.Parent)}
                        </NavLink>
                        <NavLink to="/admissions/household" activeClassName="selected">
                            <div className="nav-menu__nav-item">
                                <FormattedMessage id="nav.household" defaultMessage="[Household Information]" tagName="span" />
                            </div>
                            {renderStatus(AdmissionsTabs.Household)}
                        </NavLink>
                        <NavLink to="/admissions/documents" activeClassName="selected">
                            <div className="nav-menu__nav-item">
                                <FormattedMessage id="nav.documents" defaultMessage="[Common Documents]" tagName="span" />
                            </div>
                            {renderStatus(AdmissionsTabs.CommonDocs)}
                        </NavLink>
                        {!admissionSettings.orientationVideoEnabled && (
                            <NavLink to="/admissions/contracts" activeClassName="selected">
                                <div className="nav-menu__nav-item">
                                    <FormattedMessage id="nav.contracts" defaultMessage="[Contracts]" tagName="span" />
                                </div>
                                {renderStatus(AdmissionsTabs.Contracts)}
                            </NavLink>
                        )}
                        {showVirtualOrientationNav && (
                            <>
                                <NavLink to="/admissions/orientationvideo" className="nav-menu__extra" activeClassName="selected">
                                    <div className="nav-menu__nav-item">
                                        <FormattedMessage id="nav.orientationvideo" defaultMessage="[Orientation Video]" tagName="span" />
                                    </div>
                                    {renderStatus(AdmissionsTabs.OrientationVideo)}
                                </NavLink>
                                {admissionSettings.orientationVideoEnabled && (
                                    <NavLink to="/admissions/contracts" className="nav-menu__extra" activeClassName="selected">
                                        <div className="nav-menu__nav-item">
                                            <FormattedMessage id="nav.contracts" defaultMessage="[Contracts]" tagName="span" />
                                        </div>
                                        {renderStatus(AdmissionsTabs.Contracts)}
                                    </NavLink>
                                )}
                            </>
                        )}
                    </div>
                        <div>
                         
                            
                        <Button
                                    id="student.submit"
                                    defaultMessage="[student.submit]"
                                    className={`submit-button ${submitEnabled && !isSubmitted ? "" : "disabled"}`}
                                    onClick={onSubmitClick}
                                    invalid={isSubmitted}
                                    disabled={!submitEnabled }
                        />
                        <FormattedMessage
                            id="student.submit.notice"
                            defaultMessage="[student.submit.notice]"
                            children={(msg) => <div className="submit-message">{msg}</div>}
                        />
                        {isSubmitted && (
                            <div className="submit-message">
                                <FormattedMessage id="student.submit.submitted" defaultMessage="[student.submit.submitted]" tagName="span" />
                                <span>: {submitDate}</span>
                            </div>
                        )}
                    </div>
                        </div>
                    </div>
            )}
            <SweetAlert
                show={showInComplete}
                title={intl.formatMessage({ id: "admissions.incomplete.title", defaultMessage: "[Application Incomplete]" })}
                confirmBtnText={intl.formatMessage({ id: "app.ok" })}
                onConfirm={() => setShowInComplete(false)}
            >
                {intl.formatMessage({
                    id: "admissions.incomplete.message",
                    defaultMessage: "[Check tabs with red indicators]",
                })}
            </SweetAlert>
        </>
    );
};

export default NavMenu;
