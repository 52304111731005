import React from "react";
import Dropzonify from "../../../support/components/dropzonify";
import { useSelector } from "react-redux";

// const useStyles = makeStyles((theme) => ({
//     documentUpload: {},
// }));

const DocumentUpload = ({ className, readOnly, groupId, groupType, onUploadComplete, onUploadStarted, render }) => {
    // const classes = useStyles();
    const accessToken = useSelector((state) => state.user.accessToken);

    return (
        <Dropzonify
            className={className}
            accessToken={accessToken}
            accept=""
            uploadUrl="/api/services/app/documents/upload"
            disabled={readOnly}
            onUploadComplete={onUploadComplete}
            onUploadStarted={onUploadStarted}
            additionalUploadParameters={{ groupId, groupType }}
        >
            {({ uploadingFiles, onBrowse, dragging }) => render({ onBrowse, dragging, uploadingFiles })}
        </Dropzonify>
    );
};

export default DocumentUpload;
