import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

import Button from "../../../../support/components/Button";
import withAdmissionSettings from "../../../../support/hoc/withAdmissionSettings";
import withAjaxStatus from "../../../../support/hoc/withAjaxStatus";
import * as AdminApi from "../../../../api/admin";
import normalizePhone from "../../../../support/logic/normalizePhone";

import "./index.css";

const GeneralConfig = ({ admissionSettings, updateAdmissionSettings, ajaxStatusLoading, ajaxStatusComplete }) => {
    const [admissionPhone, setAdmissionPhone] = useState("");
    const [message, setMessage] = useState({ show: false, title: "", message: "" });

    useEffect(() => {
        setAdmissionPhone(admissionSettings.admissionPhone);
    }, [admissionSettings]);

    const saveSettings = async () => {
        try {
            ajaxStatusLoading();
            await AdminApi.updateAdmissionSettings(admissionPhone);
            updateAdmissionSettings({
                admissionPhone,
            });
            toast.success("Changes saved successfully!");
        } finally {
            ajaxStatusComplete();
        }
    };

    return (
        <div className="general-configuration">
            <h2>General Configuration</h2>
            <div className="general-configuration__group">
                <div className="general-configuration__field">
                    <div className="general-configuration__label">Admissions Phone Number:</div>
                    <div>
                        <input
                            type="text"
                            className="general-configuration__textinput"
                            value={admissionPhone}
                            onChange={(event) => setAdmissionPhone(normalizePhone(event.target.value.trim(), admissionPhone))}
                        />
                    </div>
                </div>
            </div>
            <div className="hr-line" />
            <div className="general-configuration__buttons">
                <Button onClick={saveSettings} id="Save" defaultMessage="Save" />
            </div>
            <SweetAlert
                show={message.show}
                title={message.title}
                onConfirm={() => {
                    setMessage({ ...message, show: false });
                }}
                confirmBtnText="OK"
            >
                {message.message}
            </SweetAlert>
        </div>
    );
};

export default withAjaxStatus(withAdmissionSettings(GeneralConfig));
