import { useCallback, useEffect, useMemo, useState } from "react";
import useAjaxStatus from "../../common/useAjaxStatus";
import { getEligibilityOverrides, updateEligibilityOverrides } from "../api/programEvents";

const useProgramEventOverrides = (programEventId) => {
    const [eligibilityOverrides, setEligibilityOverrides] = useState([]);
    const [loading, setLoading] = useState(true);
    const { ajaxErrorWrapper } = useAjaxStatus();

    const getEligibilityOverridesAsync = useCallback(async () => {
        try {
            const response = await getEligibilityOverrides(programEventId);
            setEligibilityOverrides(response.data.result);
        } catch (ex) {
            ajaxErrorWrapper(ex);
            throw ex;
        } finally {
            setLoading(false);
        }
    }, [ajaxErrorWrapper, programEventId]);

    const updateEligibilityOverridesAsync = async () => {
        try {
            setLoading(true);
            await updateEligibilityOverrides(programEventId, eligibilityOverrides);
        } catch (ex) {
            ajaxErrorWrapper(ex);
            throw ex;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEligibilityOverridesAsync();
    }, [getEligibilityOverridesAsync]);

    const { addedParticipants, removedParticipants } = useMemo(() => {
        const addedParticipants = [];
        const removedParticipants = [];
        for (const overrideItem of eligibilityOverrides) {
            if (overrideItem.eligible) {
                addedParticipants.push(overrideItem);
            } else {
                removedParticipants.push(overrideItem);
            }
        }
        return { addedParticipants, removedParticipants };
    }, [eligibilityOverrides]);

    return {
        eligibilityOverrides,
        loading,
        getEligibilityOverridesAsync,
        addedParticipants,
        removedParticipants,
        setEligibilityOverrides,
        updateEligibilityOverridesAsync,
    };
};

export default useProgramEventOverrides;
