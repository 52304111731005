import React from "react";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2),
    },
    label: {
        marginBottom: theme.spacing(1),
    },
    required: {
        color: "red",
    },
    placeholderText: {
        color: "#cecece",
    },
    paper: {
        maxHeight: 200,
    },
    disabled: {
        backgroundColor: theme.palette.inputDisabled.main,
    },
    chip: {
        margin: theme.spacing(0, 0.5, 0.1, 0),
    },
}));

const FinalFormMultiSelectChipDropdown = ({
    input: { name, value, onChange, ...restInput },
    className,
    chipContainerClassName,
    chipClassName,
    label,
    labelClassName,
    placeholder,
    fullWidth,
    meta,
    required,
    choices,
    onSelectChange,
    disabled,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <FormControl className={clsx(classes.margin, className)} fullWidth={fullWidth} error={meta.error && meta.touched}>
            {label && (
                <FormLabel required={required} className={clsx(classes.label, labelClassName)} classes={{ asterisk: classes.required }} disabled={disabled}>
                    {label}
                </FormLabel>
            )}
            <Autocomplete
                classes={{ tag: chipClassName }}
                multiple
                options={choices}
                getOptionLabel={(option) => option.label}
                onChange={(event, values, reason) => {
                    onChange(values);
                }}
                value={value}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder={placeholder} />}
                {...rest}
            />
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormMultiSelectChipDropdown;
