/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";

import { required, renderField } from "../../support/forms/index";
import Button from "../../support/components/Button";

import "./ForgotPassword.css";
import "../index.css";

let ForgotPassword = ({ handleSubmit, onSubmit, onCancel }) => {
    return (
        <div className="forgot-password">
            <div className="logo" alt="logo" />
            <div className="forgot-password-details">
                <FormattedMessage
                    id="login.forgot"
                    defaultMessage="[Forgot Password]"
                    children={(formatedMessage) => <h1 className="screen-title">{formatedMessage}</h1>}
                />
                <FormattedMessage
                    id="login.forgotUserNameEmailText"
                    defaultMessage="[Forgot Password text]"
                    children={(formatedMessage) => <p>{formatedMessage}</p>}
                />
                <FormattedMessage
                    id="login.userNameEmail"
                    defaultMessage="[userName Email]"
                    children={(formattedMessage) => (
                        <Field name="userNameEmail" size="256" type="input" component={renderField} label={formattedMessage} validate={[required]} />
                    )}
                />

                <div className="forgot-password-btns">
                    <Button id="login.submit" defaultMessage="[Submit]" onClick={handleSubmit(onSubmit)} />
                    <a href="" onClick={onCancel} style={{ marginTop: "8px", marginLeft: "30px" }}>
                        <FormattedMessage
                            id="app.cancel"
                            defaultMessage="Instructions"
                            children={(formattedMessage) => <span className="link-button">{formattedMessage}</span>}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

ForgotPassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
    form: "forgotpassword",
})(ForgotPassword);
