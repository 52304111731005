import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, render, ...other }) => {
    let user = null;
    const cachedUser = sessionStorage.getItem('user');
    if (cachedUser !== null) {
        user = JSON.parse(cachedUser);
    }

    const isAuthenticated = user !== null;

    return (
        <Route
            {...other}
            render={props =>
                isAuthenticated ? (
                    render ? (
                        render(props)
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;
