import { ajaxStatusLoadingAction } from "../redux/ajax";
import { getLocalizations } from "../api/localizations";

const LANGUAGE_MESSAGES_LOAD_SUCCESS = "LANGUAGE_MESSAGES_LOAD_SUCCESS";
const LOCALE_IN_CONTEXT = "LOCALE_IN_CONTEXT";
const LOCALE = "locale";
const EN = "en";

export const getLocaleAction = () => {
  let locale = EN;
  if (localStorage) {
    const storedLocale = localStorage.getItem(LOCALE);
    if (!storedLocale) {
      let browserLanguage = EN;
      if (navigator.language.includes("es")) {
        browserLanguage = "es-MX";
      }
      locale = browserLanguage;
    } else {
      locale = storedLocale ? storedLocale : EN;
    }
  }

  return { type: LOCALE_IN_CONTEXT, locale };
};

export const setLocaleAction = locale => {
  if (localStorage && locale) {
    localStorage.setItem(LOCALE, locale);
  }
  return { type: LOCALE_IN_CONTEXT, locale };
};

export const loadMessagesAction = () => {
  return dispatch => {
    dispatch(ajaxStatusLoadingAction());

    getLocalizations().then(result => {
      dispatch({
        type: LANGUAGE_MESSAGES_LOAD_SUCCESS,
        messages: result.data.result.languages
      });
      if (localStorage) {
        const locale = localStorage.locale;
        if (locale) {
          dispatch({ type: LOCALE_IN_CONTEXT, locale });
        }
      }
    });
  };
};

export const languageMessagesReducer = (state = null, action) => {
  if (action.type === LANGUAGE_MESSAGES_LOAD_SUCCESS) {
    return action.messages;
  }
  return state;
};

export const localeReducer = (state = EN, action) => {
  if (action.type === LOCALE_IN_CONTEXT) {
    return action.locale;
  }

  return state;
};
