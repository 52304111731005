import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-modal";

import Button from "../../../../support/components/Button";
import withAdmissionSettings from "../../../../support/hoc/withAdmissionSettings";
import withAjaxStatus from "../../../../support/hoc/withAjaxStatus";
import * as AdminApi from "../../../../api/admin";

import "./index.css";

const OrientationVideos = ({ admissionSettings, updateVideoSettings, ajaxStatusLoading, ajaxStatusComplete }) => {
    const [orientationVideoEnabled, setOrientationVideoEnabled] = useState(false);
    const [orientationVideoEnglish, setOrientationVideoEnglish] = useState("");
    const [orientationVideoSpanish, setOrientationVideoSpanish] = useState("");
    const [message, setMessage] = useState({ show: false, title: "", message: "" });
    const [preview, setPreview] = useState({ show: false, link: "" });

    useEffect(() => {
        setOrientationVideoEnabled(admissionSettings.orientationVideoEnabled);
        setOrientationVideoEnglish(admissionSettings.orientationVideoEnglish);
        setOrientationVideoSpanish(admissionSettings.orientationVideoSpanish);
    }, [admissionSettings]);

    const validVimeoLink = (link) => {
        return link.toLowerCase().startsWith("https://vimeo.com/");
    };

    const handleOrientationVideoEnabledChange = (event) => {
        setOrientationVideoEnabled(event.target.value === "yes" ? true : false);
    };

    const showMessage = (title, message) => {
        setMessage({ show: true, title, message });
    };

    const showPreview = (vimeoLink) => {
        const videoId = vimeoLink.trim().split("https://vimeo.com/")[1];
        const link = `https://player.vimeo.com/video/${videoId}?autoplay=1`;

        setPreview({ show: true, link });
    };

    const saveSettings = async () => {
        if (orientationVideoEnabled && (orientationVideoEnglish === "" || orientationVideoSpanish === "")) {
            showMessage("Missing Settings", "Both orientation video links are required when orientation video is enabled.");
            return;
        }

        if (!validVimeoLink(orientationVideoEnglish) || !validVimeoLink(orientationVideoSpanish)) {
            showMessage("Invalid Vimeo Link", "Make sure you are putting in a valid vimeo link: https://vimeo.com/123456");
            return;
        }

        try {
            ajaxStatusLoading();
            await AdminApi.updateVideoSettings(orientationVideoEnabled, orientationVideoEnglish, orientationVideoSpanish);
            updateVideoSettings({
                orientationVideoEnabled,
                orientationVideoEnglish,
                orientationVideoSpanish,
            });
            toast.success("Changes have been saved successfully!");
        } finally {
            ajaxStatusComplete();
        }
    };

    return (
        <div className="orientation-videos">
            <h2>Orientation Videos</h2>
            <div className="orientation-videos__field">
                <div className="orientation-videos__label">Turn On/Off Family Orientation &amp; Contracts</div>
                <div>
                    <label>
                        <input type="radio" value="yes" checked={orientationVideoEnabled} onChange={handleOrientationVideoEnabledChange} />
                        On
                    </label>
                    <label>
                        <input type="radio" value="no" checked={!orientationVideoEnabled} onChange={handleOrientationVideoEnabledChange} />
                        Off
                    </label>
                </div>
            </div>
            <div className="orientation-videos__group">
                <div className="orientation-videos__field">
                    <div className="orientation-videos__label">English Version - Vimeo Link:</div>
                    <div>
                        <input
                            disabled={!orientationVideoEnabled}
                            type="text"
                            className="orientation-videos__textinput"
                            value={orientationVideoEnglish}
                            onChange={(event) => setOrientationVideoEnglish(event.target.value.trim())}
                        />
                        <Button
                            className="orientation-videos__preview"
                            id="Preview"
                            defaultMessage="Preview"
                            invalid={!validVimeoLink(orientationVideoEnglish)}
                            onClick={() => showPreview(orientationVideoEnglish)}
                        />
                    </div>
                </div>
                <div className="orientation-videos__field">
                    <div className="orientation-videos__label">Spanish Version - Vimeo Link:</div>
                    <div>
                        <input
                            disabled={!orientationVideoEnabled}
                            type="text"
                            className="orientation-videos__textinput"
                            value={orientationVideoSpanish}
                            onChange={(event) => setOrientationVideoSpanish(event.target.value.trim())}
                        />
                        <Button
                            className="orientation-videos__preview"
                            id="Preview"
                            defaultMessage="Preview"
                            invalid={!validVimeoLink(orientationVideoSpanish)}
                            onClick={() => showPreview(orientationVideoSpanish)}
                        />
                    </div>
                </div>
            </div>
            <div className="hr-line" />
            <div className="orientation-videos__buttons">
                <Button onClick={saveSettings} id="Save" defaultMessage="Save" />
            </div>
            <SweetAlert
                show={message.show}
                title={message.title}
                onConfirm={() => {
                    setMessage({ ...message, show: false });
                }}
                confirmBtnText="OK"
            >
                {message.message}
            </SweetAlert>
            <Modal className="modal orientation-videos__modal" isOpen={preview.show} onRequestClose={() => setPreview({ ...preview, show: false })}>
                <iframe title="preview" src={preview.link} width="640" height="360" frameBorder={0} allow="autoplay; fullscreen" allowFullScreen />
            </Modal>
        </div>
    );
};

export default withAjaxStatus(withAdmissionSettings(OrientationVideos));
