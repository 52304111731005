import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadIdentifiers, loadApplication, refreshApplication } from '../../../admissions/logic/admissionsRedux';

const withAdmissions = WrappedComponent => {
    class WithAdmissions extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        admissions: state.admissions
    });

    const mapDispatchToProps = dispatch => ({
        refreshApplication: bindActionCreators(refreshApplication, dispatch),
        loadIdentifiers: bindActionCreators(loadIdentifiers, dispatch),
        loadApplication: bindActionCreators(loadApplication, dispatch)
    });

    WithAdmissions = connect(
        mapStateToProps,
        mapDispatchToProps
    )(WithAdmissions);

    return WithAdmissions;
};

export default withAdmissions;
