import React from "react";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormLabel from "@material-ui/core/FormLabel";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(2),
    },
    label: {
        marginBottom: theme.spacing(1),
        alignItem: "default",
    },
    required: {
        color: "red",
    },
    disabled: {
        backgroundColor: theme.palette.inputDisabled.main,
    },
    errorClass: {
        "& fieldset, & fieldset:hover": {
            borderColor: "red !important",
        },
    },
}));

const errorMessageHelper = (error) => {
    //using autocomplete and final form causes the errors to be passed in as arrays b/c autocomplete uses an object as it's data-value
    if (typeof error == "string") {
        return error;
    }

    if (Array.isArray(error) === false) {
        return "";
    }

    error.reduce((preVal, val) => preVal + val);
};

const FinalFormAutocomplete = ({
    className,
    autoCompleteClassName,
    label,
    options,
    getOptionLabel,
    placeholder,
    required,
    disabled,
    meta,
    input: { name, onChange, onBlur, onFocus, value, ...restInput },
    ...rest
}) => {
    const classes = useStyles();

    return (
        <FormControl className={clsx(classes.margin, className)} label={label} fullWidth error={meta.touched && meta.error} disabled={disabled}>
            {label && (
                <FormLabel
                    required={required}
                    className={classes.label}
                    classes={{ asterisk: classes.required }}
                    error={meta.error && meta.touched}
                    disabled={disabled}
                >
                    {label}
                </FormLabel>
            )}
            <Autocomplete
                value={value}
                classes={{ root: autoCompleteClassName, inputRoot: meta.touched && meta.error ? classes.errorClass : "" }}
                onChange={(event, newValue) => onChange(newValue)}
                options={options}
                getOptionLabel={getOptionLabel}
                fullWidth
                disabled={disabled}
                renderInput={(params) => <TextField disabled={disabled} name={name} {...params} placeholder={placeholder} variant="outlined" margin="dense" />}
                {...restInput}
                {...rest}
            />
            <Box display="flex" justifyContent="space-between">
                {meta.touched && meta.error && (
                    <FormHelperText style={{ alignSelf: "flex-start" }} className={classes.required}>
                        {errorMessageHelper(meta.error)}
                    </FormHelperText>
                )}
            </Box>
        </FormControl>
    );
};

export default FinalFormAutocomplete;
