import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Button, 
    Select,
    MenuItem,
    FormControl,
    InputLabel } from '@mui/material';
import { scholarshipLevel } from "../../../scholarship/enums";
import enumHelper from "../../../support/logic/enumHelper";
import { FinalFormCheckbox, FinalFormDropdown, FinalFormKeyboardDatePicker, FinalFormRadioGroup, FinalFormTextField } from "../../../common/formWrappers";
import AdvisorContext from "../../context/context";
import { toast } from "react-toastify";
import IPEDLookup from "../../../common/ipedLookup";




const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});



const renderTextField = ({ input, label, meta, multiline, rows, ...custom }) => (
    <TextField
        label={label}
        {...input}
        multiline={multiline}
        rows={rows}
        {...custom}
    />
);

const renderCheckbox = ({ input, label }) => (
    <FormControlLabel
        control={<Checkbox {...input} />}
        label={label}
        disabled
    />
);
const scholarshipLevelOptions = enumHelper.getSelectOptionsList(scholarshipLevel);
class StudentProfileFormPs extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);
        

        this.state = {
            selectedInstitutionId: props.student?.studentProfile?.selectedInstitutionId || "",
            ipedName: props.ipedName
        };
    }

    handleInstitutionChange = (event) => {
        this.setState({ selectedInstitutionId: event.target.value });
    };


    componentDidMount = async () => {
        
    }
    componentDidUpdate(prevProps, prevState) {



    }

    instChange = (val1, form) => {
        
        this.setState({ selectedInstitutionId: val1.target.value });
        form.change('selectedInstitutionId', val1.target.value);
    }
    onSubmit = (formValues) => {
        const updatedStudentProfile = {
            ...this.props.student.studentProfile,
            ...formValues
        };

        

        if (this.props.onUpdate) {
            this.props.onUpdate(updatedStudentProfile);
            toast.success("Student Updated.");
        }
    };
    renderDropdown = ({ input, ...rest }) => {
        return (
            <FormControl fullWidth>
                <InputLabel id="selectedInstitutionId-label">Selected Institution</InputLabel>
                <Select {...input} {...rest}>
                    <MenuItem key={0} value="">
                        Select an institution...
                    </MenuItem>
                    {this.context.institutionList.map((inst) => (
                        <MenuItem key={inst.id} value={inst.id}>
                            {inst.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };
    renderIpedDropdown = ({ input, ...rest }) => {
        return (
            <FormControl fullWidth>
                <InputLabel id="otherInstitutionId-label">Other Institution</InputLabel>
                <Select {...input} {...rest}>
                    <MenuItem key={0} value="">
                        Select an institution...
                    </MenuItem>
                    {this.context.ipedList.map((inst) => (
                        <MenuItem key={inst.ipedNo} value={inst.ipedNo}>
                            {inst.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    render() {
        const { student } = this.props;
        const initialValues = {
            ...student?.studentProfile,
            passCode: student?.passCode,
            otherInstitutionName: this.props.ipedName
        };
        
        return (
            <ThemeProvider theme={theme}>
            <Form
                onSubmit={this.onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Field name="userProfileId" component="input" type="hidden" />
                            <Field name="id" component="input" type="hidden" />
                            <Field name="nonOnwardStudent" component="input" type="hidden" />
                            <Field name="ssn" component="input" type="hidden" />
                            <Field name="alien" component="input" type="hidden" />
                            <Field name="sasId" component="input" type="hidden" />
                            <Field name="studentId" component="input" type="hidden" />
                            <Field name="gap" component="input" type="hidden" />
                            <Field name="honorific" component="input" type="hidden" />
                            <Field name="accelerate" component="input" type="hidden" />
                            <Field name="yearOfGraduation" component="input" type="hidden" />
                            <Field name="currentGrade" component="input" type="hidden" />
                            <Grid item xs={12} sm={4}>
                                <Field name="crusaderId" component={renderTextField} label="Onward ID" fullWidth disabled/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    name="passCode"
                                    component={renderTextField}
                                    label="Pass Code"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <Field name="collegeId" component={renderTextField} label="College Id" fullWidth />
                                
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <Field
                                    component={FinalFormDropdown}
                                    name="scholarshipLevel"
                                    variant="outlined"
                                    margin="dense"
                                    label="Level"
                                    choices={scholarshipLevelOptions}
                                    fullWidth
                                    disabled={!this.context.myBasics.isAdmin }
                                />
                                
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    name="selectedInstitutionId"
                                    component={this.renderDropdown}
                                    labelId="selectedInstitutionId-label"
                                    fullWidth
                                    value={this.state.selectedInstitutionId}
                                   
                                />
                            </Grid>
                            
                            {values.selectedInstitutionId == "ad774e92-61b4-44be-b710-7b0e8a196a83" && (
                                  
                                <Grid item xs={12} sm={4}>
                                    <IPEDLookup
                                        name="institutionIPEDNo"
                                        ipedList={this.context.ipedList}
                                        ipedNo={values.institutionIPEDNo }
                                    />

                                    

                                    
                                </Grid>
                                        
                                )}
                           
                            
                            
                            <Grid item xs={12} sm={12}>
                                <Field
                                    name="iep"
                                    component={renderTextField}
                                    label="IEP"
                                    multiline
                                    rows={4} 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" disabled={submitting || pristine} variant="contained" color="primary">
                                    Submit
                                </Button>
                                <Button type="button" onClick={form.reset} disabled={submitting || pristine} variant="contained" color="secondary">
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                    </form>
                )}
                />
            </ThemeProvider>
        );
    }
}

export default StudentProfileFormPs;
