import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import LoadingIndicator from "../loadingIndicator";

const useStyles = makeStyles((theme) => ({
    simpleDialog: {
        padding: theme.spacing(2),
    },
    header: {
        display: "flex",
        position: "relative",
    },
    title: {},
    closeButton: {
        position: "absolute",
        right: 0,
    },
}));

const SimpleDialog = ({ className, maxWidth = "md", fullWidth = true, open, onClose, title, showCloseButton = true, loading = false, children }) => {
    const classes = useStyles();

    const localOnClose = (evnt, reason) => {
        if (reason) {
            return;
        }

        onClose();
    };

    return (
        <Dialog
            disablePortal
            PaperProps={{ className: clsx(classes.simpleDialog, className) }}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            open={open}
            onClose={localOnClose}
            disableEscapeKeyDown
        >
            <LoadingIndicator loading={loading} />
            <div className={classes.header}>
                <Box flex={1} justifyContent="center" display="flex" mb={1}>
                    <Typography variant="h4" component="h2" className={classes.title}>
                        {title}
                    </Typography>
                </Box>
                {showCloseButton && onClose && (
                    <IconButton onClick={onClose} className={classes.closeButton}>
                       <CloseIcon />
                    </IconButton>
                )}
            </div>
            {children}
        </Dialog>
    );
};

export default SimpleDialog;
