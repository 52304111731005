import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { EventStatus } from "../../enums";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 0),
    },
    navButton: {
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.warning.main,
        borderRadius: "50%",
    },
    iconComplete: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
    },
    recruitButton: {
        padding: theme.spacing(1, 8),
    },
}));

const EventPlanningStep = ({ programEventId, isEventDetailsMissing, isEligibleCriteriaMissing, onNextStep, isActive, status, setIsEligibleCriteriaMissing, isComplete }) => {
    const classes = useStyles();
    const history = useHistory();


    const getNavLink = () => {

        if (isActive) {
            if (isComplete) {
                return `/events/complete/event/${programEventId}`
            } else {
                return `/events/active/event/${programEventId}`
            }

        } else {
            return `/events/inactive/event/${programEventId}`
        }

    }
    return (
        <Box className={classes.root} display="flex" flexDirection="column" alignItems="flex-start">
            <Button
                onClick={() => history.push(`${getNavLink()}/details`)}
                className={classes.navButton}
                startIcon={isEventDetailsMissing ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />}
            >
                Event Details
            </Button>
            <Button
                onClick={() => history.push(`${getNavLink()}/eligible-participant-list`)}
                className={classes.navButton}
                startIcon={
                    isEligibleCriteriaMissing ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />
                }
            >
                Invitation List
            </Button>
            {status === EventStatus.Planning && (
                <Button className={classes.recruitButton} variant="contained" color="primary" size="large" onClick={onNextStep}>
                    RECRUIT
                </Button>
            )}
        </Box>
    );
};

export default EventPlanningStep;
