import React, { useContext, useState } from "react";
import FontAwesome from "react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { AdmissionsTabs, ApplicationStatus } from "../logic/enums";
import Button from "../../support/components/Button";


class Appsummary extends React.Component {
    state = {
        applications: [],
    };

    appStatus = () => {
        switch (this.props.app.status) {
            case ApplicationStatus.NotSubmitted:
                return <FormattedMessage id="app.continue" defaultMessage="[app.continue]" tagName="span" />;
            case ApplicationStatus.Submitted:
                return <FormattedMessage id="app.pending" defaultMessage="[app.pending]" tagName="span" />;
            case ApplicationStatus.Complete:
                return <FormattedMessage id="app.pending" defaultMessage="[app.pending]" tagName="span" />;
            case ApplicationStatus.LunchPending:
                return <FormattedMessage id="app.pending" defaultMessage="[app.pending]" tagName="span" />;
            case ApplicationStatus.Accepted:
                return <FormattedMessage id="app.accepted" defaultMessage="[app.accepted]" tagName="span" />;
            case ApplicationStatus.ApplicantRejected:
                return <FormattedMessage id="app.rejected" defaultMessage="[app.rejected]" tagName="span" />;
            case ApplicationStatus.Rejected:
                return <FormattedMessage id="app.rejected" defaultMessage="[app.rejected]" tagName="span" />;
            default:
                return <FormattedMessage id="app.continue" defaultMessage="[app.continue]" tagName="span" />;
        }

    }

    submitDate = this.props.app !== null && this.props.app.submissionDate !== null ? moment(this.props.app.submissionDate).format("M/D/YY h:mm a") : "";

    render() {
        
        return (
            <Box className="appicationOption">
                <div className="applicant-info">
                    <span className="applicant-name">{this.props.app.userProfiles[0].userProfileName}</span>
                    {this.props.app.status > 0 && (
                        <div className="submit-message">
                            <FormattedMessage id="student.submit.submitted" defaultMessage="[student.submit.submitted]" tagName="span" />
                            <span className="submission-date">: {this.submitDate}</span>
                        </div>
                    )}
                </div>
                <div className="buttons-container">
                    {(this.props.app.status == ApplicationStatus.NotSubmitted ) && (
                        <Button
                            id="delete"
                            defaultMessage={"Delete"}
                            className="edit-button"
                            onClick={() => this.props.onDelete(this.props.app)}
                        />
                    )}
                    <Button
                        id="application.view"
                        defaultMessage={this.appStatus()}
                        className="view-button"
                        onClick={() => this.props.setActiveApplication(this.props.app)}
                    />
                    {this.props.app.status == ApplicationStatus.Accepted && (
                        <Button
                            id="profile.update"
                            defaultMessage={"Edit Profile"}
                            className="edit-button"
                            onClick={() => this.props.openProfile(this.props.app)}
                        />
                    )}
                </div>
            </Box>

        );
    }


}

export default Appsummary;