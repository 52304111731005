import React from "react";
import Badge from "@material-ui/core/Badge";
import DateComponent from "../dateComponent/dateComponent";
import { Tooltip } from "@material-ui/core";
import { useStyles } from "./styles";

const EventDateTooltip = ({ eventDates, maxItems, showBadge = false }) => {
    const classes = useStyles();

    const tooltipContent = (
        <div>
            {eventDates.slice(maxItems, eventDates.length).map(({ startTime, endTime, id }) => (
                <DateComponent key={id} date={{ startTime, endTime }} />
            ))}
        </div>
    );

    return (
        <Tooltip arrow placement="bottom-start" title={eventDates.length > maxItems ? tooltipContent : ""}>
            <div className={classes.container}>
                <Badge
                    color="primary"
                    invisible={!showBadge || maxItems >= eventDates.length}
                    badgeContent={`+${eventDates.length - maxItems}`}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <div className={classes.datesList}>
                        {eventDates.slice(0, maxItems).map(({ startTime, endTime, id }) => (
                            <DateComponent key={id} date={{ startTime, endTime }} />
                        ))}
                    </div>
                </Badge>
            </div>
        </Tooltip>
    );
};

export default EventDateTooltip;
