import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import moment from "moment";
import FileSaver from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

import { startBeforeEnd } from "../../../support/timeSupport";

import { getEvents, getUpcomingEvents, createEvent, updateEvent, getRoster } from "../../../api/admissionEvents";
import { sendReminders } from "../../../api/applicationEvent";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import StaffLayout from "../common/staffLayout";
import EventForm from "./form";
import EventList from "./list";
import b64toBlob from "../../../support/logic/b64toBlob";

import "./index.css";

const checkTimeValues = data => {
  const errors = {};
  const message = "End time before Start";

  if (!startBeforeEnd(data.startTime, data.endTime)) {
    errors.endTime = message;
  }

  return errors;
};

const FORM_NAME = "AdmissionEvent";
const NAME = "The Onward We Learn";
const ADDRESS1 = "134 Thurbers Avenue";
const ADDRESS2 = "Suite 111";
const NOTES = 'Parking: Behind building. Use "West Ramp" entrance.\n\nEstacionamiento: Detrás del edificio. Use la entrada de "West Ramp".';

class EventsContainer extends React.Component {
  state = {
    events: [],
    selectedItem: null,
    isDisabled: true,
    showReminderAlert: false,
    eventForReminder: null,
    hideInactive: false
  };

  static permission = "Applications.Events";

  componentDidMount() {
    this.doClearForm();
    this.load();
  }

  async load() {
    try {
      this.props.ajaxStatusLoading();
      const events = this.state.hideInactive ? (await getEvents()).data.result : (await getUpcomingEvents()).data.result;
      this.setState({ events });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  }

  rowClick(item) {
    this.setState({ selectedItem: item, isDisabled: false });
    this.props.initialize({ ...item, date: moment(item.startTime) });
  }

  clearSelected() {
    this.setState({ selectedItem: null, isDisabled: true });
  }

  getTdProps = (state, rowInfo, column, instance) => {
    const { selectedItem } = this.state;

    if (column.Header === "Reminder") {
      if (moment(rowInfo.original.endTime).isBefore(moment(), "day")) {
        return {};
      }

      return {
        onClick: e => {
          this.handleReminderClick(rowInfo.original.id);
        }
      };
    } else if (column.Header === "Roster") {
      return {
        onClick: e => {
          this.handleRosterClick(rowInfo.original.id);
        }
      };
    }

    return {
      onClick: e => {
        this.rowClick(rowInfo.original);
      },
      style: {
        background: selectedItem !== null && rowInfo.original.id === selectedItem.id ? "#fdf8bb" : undefined
      }
    };
  };

  handleAdd = () => {
    this.doClearForm();
    this.clearSelected();
    this.setState({ isDisabled: false });
  };

  handleSave = async values => {
    try {
      this.props.ajaxStatusLoading();

      let date = moment.utc(values.date);
      let startTime = moment(values.startTime).utc();
      let endTime = moment(values.endTime).utc();

      startTime = moment()
        .utc()
        .set({
          year: date.year(),
          month: date.month(),
          date: date.date(),
          hour: startTime.hour(),
          minute: startTime.minute()
        });
      endTime = moment()
        .utc()
        .set({
          year: date.year(),
          month: date.month(),
          date: date.date(),
          hour: endTime.hour(),
          minute: endTime.minute()
        });

      const data = { ...values, startTime, endTime };
      if (values.id) {
        await updateEvent(data);
      } else {
        await createEvent(data);
      }
      this.doClearForm();
      this.props.ajaxStatusComplete();
      await this.load();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  };

  handleCancel = () => {
    if (!this.state.selectedItem) {
      this.doClearForm();
      return;
    }
    this.props.reset();
  };
  doClearForm = () => {
    this.setState({ isDisabled: true });
    this.props.initialize({ name: "", address1: "", city: "", state: "RI", zipCode: "", startTime: null, endTime: null, date: null, notes: "" });
  };
  onToBeHeld = () => {
    const { id, date, startTime, endTime, isHeldAtHeadquarters, maxAttendees } = this.props;
    if (isHeldAtHeadquarters) {
      this.props.initialize({
        id,
        name: "",
        address1: "",
        city: "",
        state: "RI",
        zipCode: "",
        startTime,
        endTime,
        date,
        notes: "",
        maxAttendees
      });
      return;
    }
    this.props.initialize({
      id,
      name: NAME,
      address1: ADDRESS1,
      address2: ADDRESS2,
      city: "Providence",
      state: "RI",
      zipCode: "02905",
      date,
      startTime,
      endTime,
      notes: NOTES,
      maxAttendees
    });
  };

  handleRosterClick = async eventId => {
    try {
      this.props.ajaxStatusLoading();
      const response = (await getRoster(eventId)).data.result;
      this.props.ajaxStatusComplete();
      const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      FileSaver.saveAs(blob, "roster.xlsx");
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  };

  handleReminderClick = eventId => {
    this.setState({ eventForReminder: eventId, showReminderAlert: true });
  };

  handleConfirmReminder = () => {
    this.setState({ showReminderAlert: false }, async () => {
      try {
        this.props.ajaxStatusLoading();
        await sendReminders(this.state.eventForReminder);
        this.props.ajaxStatusComplete();
        toast.success("Reminders sent");
        this.load();
      } catch (err) {
        this.props.ajaxStatusError(err);
      }
    });
  };

  handleCancelReminder = () => {
    this.setState({ showReminderAlert: false });
  };

  onHideClicked = () => {
    const hideInactive = !this.state.hideInactive;
    this.setState({ hideInactive }, () => this.load());
  };

  render() {
    const { events, selectedItem, showReminderAlert, hideInactive } = this.state;
    const totalEvents = events.length;
      //console.log(this);
    return (
      <StaffLayout>
        <div>
          <h3>Total Events: {totalEvents}</h3>
        </div>
        <div className="admissions-events">
          <EventForm
            handleAdd={this.handleAdd}
            handleSave={this.props.handleSubmit(this.handleSave)}
            handleCancel={this.handleCancel}
            onToBeHeld={this.onToBeHeld}
            isDisabled={this.state.isDisabled || this.props.readonly}
          />
                <EventList onHideClicked={this.onHideClicked} hideInactive={hideInactive} selectedItem={selectedItem} events={events} getTdProps={this.getTdProps} readonly={this.props.readonly} />
        </div>
        <SweetAlert
          show={showReminderAlert}
          title="Event Reminder"
          type="info"
          showCancel
          onConfirm={this.handleConfirmReminder}
          onCancel={this.handleCancelReminder}
          confirmBtnText="OK"
          cancelBtnText="CANCEL"
        >
          Would you like to remind all registered attendees of this event?
        </SweetAlert>
      </StaffLayout>
    );
  }
}

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => {
  const date = selector(state, "date");
  const startTime = selector(state, "startTime");
  const endTime = selector(state, "endTime");
  const address1 = selector(state, "address1");
  const name = selector(state, "name");
  const isHeldAtHeadquarters = selector(state, "heldAtHeadquarters");
  const notes = selector(state, "notes");
  const id = selector(state, "id");
  const maxAttendees = selector(state, "maxAttendees");
  return {
    date,
    startTime,
    endTime,
    address1,
    name,
    isHeldAtHeadquarters,
    id,
    notes,
    maxAttendees
  };
};

EventsContainer = withAjaxStatus(EventsContainer);
EventsContainer = reduxForm({
  form: FORM_NAME,
  fields: ["startTime", "endTime"],
  validate: checkTimeValues
})(EventsContainer);

EventsContainer = connect(mapStateToProps)(EventsContainer);

export default EventsContainer;
