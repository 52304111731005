import React, { Component, createRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SimpleDialog from "../simpleDialog";
import LoadingButton from "../loadingButton";
import "./index.css";

class Export2PDF extends Component {
    exportRef = createRef();
    constructor(props) {
        super(props);

        this.state = {
            showExport: false
        };
    }


    componentDidUpdate = async (prevProps, prevState) => {



    }


    exportPDF = () => {
       
        const pdfname = this.props.fileName ? `${this.props.fileName}-${Date.now()}.pdf` : "PDF-Export.pdf";
        const pdf = new jsPDF();
        //The folowing color replacements are to handle Safari crashing


        let elementsWithProblematicStyles = this.exportRef.current.querySelectorAll('h4');
        elementsWithProblematicStyles.forEach(element => {
            element.style.color = '#b5bd00'; // Replace 'color' function with a simple color value
            // Apply other necessary style corrections here
        });
        elementsWithProblematicStyles = this.exportRef.current.querySelectorAll('.printSectionHeader');
        elementsWithProblematicStyles.forEach(element => {
            element.style.color = '#123225'; // Replace 'color' function with a simple color value
            // Apply other necessary style corrections here
        });
        elementsWithProblematicStyles = this.exportRef.current.querySelectorAll('.Mui-disabled');
        elementsWithProblematicStyles.forEach(element => {
            element.style.color = '#00000042'; // Replace 'color' function with a simple color value
            // Apply other necessary style corrections here
        });
        html2canvas(this.exportRef.current, { scrollY: -window.scrollY }).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;

            // Convert the canvas to a data URL
            const imgData = canvas.toDataURL('image/png');

            // Calculate the number of pages.
            const imgHeight = (contentHeight * 210) / contentWidth; // A4 width in mm (210mm), keep the same aspect ratio
            const pageHeight = 297;  // A4 height in mm
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, 210, imgHeight);
            heightLeft -= pageHeight;

            // Add new pages if the content is taller than page height
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, 210, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save(pdfname);
        });

        this.setState({ showExport: false });
    };


    render() {
        return (
            <div>
                <SimpleDialog open={this.state.showExport} onClose={() => this.setState({ showExport: false })} title="Generating PDF" >
                    <LoadingButton className="shareButton" onClick={() => this.exportPDF() }>Share Now</LoadingButton>
                    <div ref={this.exportRef} >

                        {this.props.children}
                    </div>

                </SimpleDialog>
                
                <LoadingButton className="shareButton" onClick={() => this.setState({ showExport: true })}>Share</LoadingButton>
            </div>
        );
    }
}

export default Export2PDF;