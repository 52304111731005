import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
class PostSecondary extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {



        };
    }



    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {



    }

    render() {
        // Get the last two items from the history stack


        return <div></div>;
    }
}

export default PostSecondary;