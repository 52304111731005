import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import Complete from "./Complete";
import StaffLayout from "../common/staffLayout";
import { unregister, markAttended } from "../../../api/applications";

class CompleteContainer extends React.Component {
    state = {
        showUnregisterModal: false,
        applicationId: null,
    };

    static permission = "Applications.Complete";

    componentDidMount() {
        this.props.loadData();
    }

    getTrProps = (state, rowInfo, column, instance) => {
        if (column.Header === "Applicant Rejected" || column.Header === "Roster") return {};
        return {
            onClick: (e) => {
                this.rowClick(rowInfo.original);
            },
        };
    };
    rowClick = (row) => {
        window.previousLocation = "complete";
        this.props.history.push(`/applications/application/${row.applicationId}/s/${row.userProfileId}`);
    };

    getSchoolData(schoolName) {
        return this.props.data.filter((d) => d.school === schoolName);
    }

    handleUnregister = async (applicationId) => {
        this.setState({ applicationId, showUnregisterModal: true });
    };

    onConfirmUnregister = async () => {
        try {
            this.props.ajaxStatusLoading();
            this.setState({ showUnregisterModal: false });
            await unregister(this.state.applicationId);
            this.props.loadData();
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    onAttended = async (applicationId) => {
        try {
            this.props.ajaxStatusLoading();
            await markAttended(applicationId);
            this.props.loadData();
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    renderSchools() {
        return this.props.schools.map((school, index) => (
            <Complete
                school={school.name}
                data={this.getSchoolData(school.name)}
                key={index}
                getTrProps={this.getTrProps}
                onUnregister={this.handleUnregister}
                onAttended={this.onAttended}
            />
        ));
    }

    render() {
        const totalComplete = this.props.data.length;
        const unassociatedData = this.getSchoolData("");

        return (
            <StaffLayout>
                <h3>Total Complete: {totalComplete}</h3>
                <br />
                {this.renderSchools()}
                {unassociatedData.length > 0 && (
                    <Complete
                        school="Unassociated"
                        data={unassociatedData}
                        getTrProps={this.getTrProps}
                        onUnregister={this.handleUnregister}
                        onAttended={this.onAttended}
                    />
                )}
                <SweetAlert
                    show={this.state.showUnregisterModal}
                    title="Cancel Registration"
                    showCancel
                    onConfirm={this.onConfirmUnregister}
                    onCancel={() => this.setState({ showUnregisterModal: false })}
                >
                    Would you like to cancel this registration?
                </SweetAlert>
            </StaffLayout>
        );
    }
}

CompleteContainer.propTypes = {
    data: PropTypes.array,
    schools: PropTypes.array,
};
const mapStateToProps = (state) => ({
    schools: state.staffMiddleSchools,
});

CompleteContainer = withAjaxStatus(CompleteContainer);
CompleteContainer = connect(mapStateToProps)(CompleteContainer);
CompleteContainer = withRouter(CompleteContainer);

export default CompleteContainer;
