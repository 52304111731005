import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import { formatCurrencyNoDecimal } from '../../../../support/numberSupport';

import './DonatedCrusaders.css';

const DonatedCrusaders = ({ schoolName, onExportToExcel, data }) => {
    const date = moment.utc(new Date()).format('M/D/YY');
    return (
        <div className="donated-crusader">
            <div className="header1">
                <h2>Donated Scholarships</h2>
                <div className="spacer" />
                <button className="btn" onClick={onExportToExcel}>
                    Export To Excel
                </button>
            </div>
            <div className="header2">
                <span>{schoolName}</span>
                <div className="spacer" />
                <span>As of {date}</span>
            </div>
            <ReactTable
                data={data}
                minRows={0}
                className="financials-table -highlight"
                columns={[
                    {
                        Header: 'Last Name',
                        accessor: 'lastName'
                    },
                    {
                        Header: 'First Name',
                        accessor: 'firstName'
                    },
                    {
                        Header: 'Social Security',
                        accessor: 'ssn'
                    },
                    {
                        Header: 'Crusader ID',
                        accessor: 'crusaderId'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        Cell: row => row.original.statusString
                    },
                    {
                        Header: 'Term',
                        accessor: 'period',
                        Cell: row => row.original.term
                    },
                    {
                        Header: 'Reason',
                        accessor: 'reason'
                    },
                    {
                        Header: 'Tier',
                        accessor: 'level',
                        Cell: row => row.original.tier
                    },
                    {
                        Header: 'Donated',
                        accessor: 'donated',
                        Cell: row => formatCurrencyNoDecimal(row.value)
                    }
                ]}
                showPagination
            />
        </div>
    );
};

DonatedCrusaders.propTypes = {
    onExportToExcel: PropTypes.func.isRequired,
    schoolName: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            ssn: PropTypes.string,
            crusaderId: PropTypes.number,
            status: PropTypes.number,
            statusString: PropTypes.string,
            reason: PropTypes.string,
            tier: PropTypes.string,
            donated: PropTypes.number,
            termStructure: PropTypes.number,
            term: PropTypes.string
        })
    )
};

export default DonatedCrusaders;
