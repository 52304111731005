import React, { Component, Fragment } from "react";
import AdvisorContext from "../context/context";
import ReactTable from "react-table";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import Link from "@material-ui/core/Link";
import { Tabs, Tab } from '@mui/material';
import { Grid, Typography, IconButton } from '@material-ui/core';
import LoadingIndicator from "../../common/loadingIndicator";
import { MilestonesGraph } from "./components";


class MilestonesPage extends Component {
    static contextType = AdvisorContext;

constructor(props) {
        super(props);

        this.state = {
            tab: 0,
            milestones: [],
            activeMilestone: [], 
            loaded: false,


        };
    }



    componentDidMount = async () => {
    
    this.calculateData();
    }
    componentDidUpdate(prevProps, prevState) {
       
        if (!this.state.loaded && this.context.loaded) {
            this.calculateData();
        }


    }

    calculateData = async () => {
        let mss = [];

        this.context.milestoneTags.forEach(mt => {
            const oName = mt.classification.classification;

            mt.tags.forEach(tag => {

                if (tag.subtagId != '00000000-0000-0000-0000-000000000000') {
                    const result = tag.tagName.match(/^\d+/);
                    // If a number is found, result[0] will contain the number as a string
                    const number = result ? parseInt(result[0], 10) : null;
                    const elegCount = this.context.myCases.filter(flt => flt.studentProfile.currentGrade == number);
                    if (elegCount.length > 0) {
                        mss.push({
                            tagsId: tag.tagId,
                            subtagId: tag.subtagId,
                            objectiveId: tag.subtagId,
                            target: 100,
                            minGrade: number,
                            displayName: tag.tagName,
                            objectiveName: oName
                        });
                    }
                    

                }


            });

        });
        if (mss.length > 0) {
            mss.sort((a, b) => a.displayName.localeCompare(b.displayName));
            this.setState({ milestones: mss, loaded: true, activeMilestone: mss[0] })
        }
        
    }


    setTab = async (event, newVal) => {
        

        this.setState({ tab: newVal, activeMilestone: this.state.milestones[newVal] });
    }




    render() {
        
        if (this.state.milestones.length == 0) {
            return <LoadingIndicator />
        }
        
        return (
            <>
        <br />
            <Link
                className={"dashboardLink"} onClick={() => this.props.gotoLocation("dashboard")}
            >
                &lt; Return to Dashboard
            </Link> <br /><br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="secondary" gutterBottom align="center">
                            Milestones
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Milestones" orientation="vertical"
                            variant="scrollable"
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    justifyContent: 'space-evenly',
                                },
                                '.MuiTab-root': {
                                    border: '1px solid #000',
                                    borderBottom: 'none',
                                    borderTopRightRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                    marginRight: '4px',
                                    '&:last-child': {
                                        marginRight: 0,
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#123225',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#123225',
                                        color: '#fff',
                                        opacity: 1,
                                    },
                                },
                            }}
                        >
                            {this.state.milestones.map(obj => {

                                return (<Tab label={obj.displayName }  />);
                            })}
                            
                        </Tabs>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            
                                <MilestonesGraph objective={this.state.activeMilestone} type="pie" user={0} display="Overall Progress"/>
                            
                            
                            {this.context.myBasics.subordinates.map(adv => {
                                return (
                                    
                                        <MilestonesGraph objective={this.state.activeMilestone} type="pie" user={adv.userProfile.userId} display={adv.userProfile.firstName + " " + adv.userProfile.lastName } />
                                    );
                            }) }

                            </Grid>
                    </Grid>

                </Grid></>)
    
    }

     }


export default MilestonesPage;