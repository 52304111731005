import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import { Button } from "@material-ui/core";
import { useStyles } from "./styles";

const DepartmentButton = ({ department, greenCheck = false, handleOnClick = () => {} }) => {
    const classes = useStyles();

    return (
        <Button
            onClick={handleOnClick}
            startIcon={greenCheck ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />}
        >
            {department}
        </Button>
    );
};

export default DepartmentButton;
