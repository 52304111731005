import React from "react";

import { isDev } from "../../logic/env";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true, error: error, info: info });
    }

    render() {
        if (this.state.hasError) {
            if (!isDev()) {
                return <h6>An error was encountered. Please refresh the page and try again.</h6>;
            }

            return (
                <div>
                    <h1>Whoops!</h1>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
