import React from "react";

const formatterNoDecimal = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});

export const formatCurrencyNoDecimal = number => {
    let tmpNumber = number;
    if (typeof tmpNumber === "string") {
        tmpNumber = tmpNumber.replace(/\D/g, "");
    }
    if (tmpNumber && !isNaN(tmpNumber)) {
        return formatterNoDecimal.format(tmpNumber);
    }
    return "$0";
};


export const formatCurrencyNoDecimalWithNegativeStyle = number => {
  const formattedNumber = formatCurrencyNoDecimal(number);

  if (number < 0) {
    return (
      <span className="negative-value">{`(${formattedNumber.replace(
        "-",
        ""
      )})`}</span>
    );
  }

  return formattedNumber;
};
