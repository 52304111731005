import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router";
import FileSaver from "file-saver";

import BackButton from "../../common/backButton";
import useProgramEvent from "../hooks/useProgramEvent";
import LoadingIndicator from "../../common/loadingIndicator";
import useAjaxStatus from "../../common/useAjaxStatus";
import { getListByGrouping, getData, remove } from "../../api/documents";
import { GroupType } from "../../admissions/logic/enums";
import b64toBlob from "../../support/logic/b64toBlob";
import DropArea from "../../support/components/dropArea";
import DocumentUpload from "../common/documentUpload";

const useStyles = makeStyles((theme) => ({
    documents: {},
    paper: {
        padding: theme.spacing(0, 2, 1),
        display: "flex",
        flexDirection: "column",
    },
    headerDivider: {
        backgroundColor: theme.palette.secondary.main,
        height: 2,
        marginBottom: theme.spacing(1),
    },
    upload: {
        marginTop: theme.spacing(1),
        alignSelf: "center",
    },
    dropArea: {
        padding: theme.spacing(0, 2),
    },
}));

const NoDocumentsFound = ({ documents, groupType }) => {
    const fileCount = documents.filter((d) => d.groupType === groupType).length;

    if (fileCount !== 0) {
        return null;
    }

    return (
        <Box mb={1}>
            <Typography variant="body2">No documents found...</Typography>
        </Box>
    );
};

const DocumentItem = ({ id, fileName, thumbnail, onDelete, onDownload }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom="1px #cacaca solid">
            <Box display="flex" alignItems="center" paddingY={1}>
                <Box mr={1}>
                    <Typography variant="body1">{fileName}</Typography>
                </Box>
                <Button size="small" variant="text" color="primary" onClick={() => onDownload(id, thumbnail !== "")}>
                    {thumbnail ? "View" : "Download"}
                </Button>
            </Box>
            <IconButton onClick={() => onDelete(id)} size="small">
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

const Documents = ({ topUser }) => {
    const classes = useStyles();
    const { id } = useParams();
    const [allDocuments, setAllDocuments] = useState([]);
    const { programEvent, loading: programLoading } = useProgramEvent(id);
    const { ajaxErrorWrapper, ajaxLoad, ajaxComplete } = useAjaxStatus();
    const [readOnly, setReadOnly] = useState(false);;
    const getEventDocuments = useCallback(
        async (eventId, tokenSource) => {
            try {
                //setLoading(true);
                ajaxLoad();
                const response = await getListByGrouping(eventId, null, tokenSource.token);
                setAllDocuments(response.data.result);
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
                //setLoading(false);
                ajaxComplete();
            }
        },
        [ajaxComplete, ajaxErrorWrapper, ajaxLoad]
    );
    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        getEventDocuments(id, tokenSource.token);
        return () => {
            tokenSource.cancel();
        };
    }, [getEventDocuments, id]);

    if (programLoading) {
        return <LoadingIndicator />;
    }

    const handleDelete = async (documentId) => {
        try {
            ajaxLoad();
            await remove(documentId);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
        const tokenSource = axios.CancelToken.source();
        await getEventDocuments(id, tokenSource.token);
    };

    const handleDownload = async (id, isImage) => {
        if (isImage) {
            window.open(`/viewimage/${id}`, "_blank");
            return;
        }

        try {
            ajaxLoad();
            const response = (await getData(id)).data.result;
            const blob = b64toBlob(response.data, response.mimeType);
            FileSaver.saveAs(blob, response.fileName);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };

    const handleUploadComplete = () => {
        const tokenSource = axios.CancelToken.source();
        getEventDocuments(id, tokenSource.token);
    };

    const handleUploadStarted = () => {};

    return (
        <div className={classes.documents}>
            <Box display="flex" flexDirection="column">
                <BackButton />
                <Typography variant="h5" color="secondary">
                    {programEvent.name}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                    Documents
                </Typography>
                <Box mb={2} />
                <Paper className={classes.paper} variant="outlined">
                    <Box marginY={1} display="flex" flexDirection="column">
                        <Typography variant="h6">Internal Documentation</Typography>
                        <Typography variant="caption">Documentation only viewable by Onward We Learn staff members</Typography>
                    </Box>
                    <Divider className={classes.headerDivider} />
                    {allDocuments
                        .filter((d) => d.groupType === GroupType.InternalEventDocumentation)
                        .map((document) => (
                            <DocumentItem key={document.id} {...document} disabled={readOnly} onDelete={handleDelete} onDownload={handleDownload} />
                        ))}
                    <NoDocumentsFound documents={allDocuments} groupType={GroupType.InternalEventDocumentation} />
                    <DocumentUpload
                        className={classes.upload}
                        groupId={id}
                        groupType={GroupType.InternalEventDocumentation}
                        onUploadComplete={handleUploadComplete}
                        onUploadStarted={handleUploadStarted}
                        readOnly={readOnly }
                        render={({ uploadingFiles, onBrowse, dragging }) => (
                            <DropArea className={classes.dropArea} onBrowse={onBrowse} disabled={readOnly} dragging={dragging} uploadingFiles={uploadingFiles} />
                        )}
                    />
                </Paper>
                <Box mb={2} />
                <Paper className={classes.paper} variant="outlined">
                    <Box marginY={1} display="flex" flexDirection="column">
                        <Typography variant="h6">Participant Documentation</Typography>
                        <Typography variant="caption">Documentation viewable by participants in the event details</Typography>
                    </Box>
                    <Divider className={classes.headerDivider} />
                    {allDocuments
                        .filter((d) => d.groupType === GroupType.ExternalEventDocumentation)
                        .map((document) => (
                            <DocumentItem key={document.id} {...document} onDelete={handleDelete} disabled={readOnly} onDownload={handleDownload} />
                        ))}
                    <NoDocumentsFound documents={allDocuments} groupType={GroupType.ExternalEventDocumentation} />
                    <DocumentUpload
                        className={classes.upload}
                        groupId={id}
                        readOnly={readOnly}
                        groupType={GroupType.ExternalEventDocumentation}
                        onUploadComplete={handleUploadComplete}
                        onUploadStarted={handleUploadStarted}
                        render={({ uploadingFiles, onBrowse, dragging }) => (
                            <DropArea className={classes.dropArea} onBrowse={onBrowse} disabled={readOnly} dragging={dragging} uploadingFiles={uploadingFiles} />
                        )}
                    />
                </Paper>
            </Box>
        </div>
    );
};

export default Documents;
