import React, { useState, useEffect } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import EligibleParticipantTable from "./components/eligibleParticipantTable";
import LoadingIndicator from "../../common/loadingIndicator";
import Reporting from "../../common/reporting";
import { useEligibleParticipants, useProgramEvent } from "../hooks";
import { EventStatus } from "../enums";
import InviteParticipantsDialog from "./components/inviteParticipantsDialog";
import EventHeader from "../common/eventHeader/eventHeader";



const useStyles = makeStyles((theme) => ({
    formHeader: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title inviteButton" "subtitle inviteButton"`,
    },
    backButton: {
        gridArea: "backButton",
    },
    title: {
        gridArea: "title",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    subtitle: {
        fontWeight: "bold",
        gridArea: "subtitle",
    },
    inviteButtonContainer: {
        gridArea: "inviteButton",
        justifySelf: "end",
        alignSelf: "start",
    },
    inviteButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    formBody: {
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(5),
    },
    actionsContainer: {
        display: "flex",
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    eligibleParticipantsText: {
        marginRight: "auto",
    },
    outlinedButton: {
        fontWeight: 700,
    },
}));


const preSelectedReportFields = ["firstName", "lastName", "grade", "crusaderId", "userType", "email", "mobilePhone", "dateOfBirth"];
const skippedFields = ["user"];


const EligibleParticipantList = ({ isActive, isComplete, topUser }) => {
    const classes = useStyles();
    const { id } = useParams();
    const { push } = useHistory();
    const { programEvent, loading: loadingProgramEvent } = useProgramEvent(id);
    const { eligibleParticipants, loading: loadingEligibleParticipants, newInvites, inviteParticipantsAsync, inviting } = useEligibleParticipants(id);
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [readOnly, setReadOnly] = useState(false);



    let profBase = '/advisor/student/'


    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    useEffect(() => {
        if (topUser != null && topUser.advProf != null && topUser.advProf.isAdmin) {

            profBase = '/admin/users/crusader/'
        }
    }, [topUser]);
    //console.log(topUser);

    if (topUser != null && topUser.advProf != null && topUser.advProf.isAdmin) {
        
        profBase = '/admin/users/crusader/'
    }
    const handleInvite = async () => {
        await inviteParticipantsAsync();
    };

    if (loadingProgramEvent || loadingEligibleParticipants) {
        return <LoadingIndicator loading />;
    }

    if (programEvent == null) {
        return null;
    }


    //console.log(topUser);
    return (
        <div>
            <Prompt
                when={newInvites.totalNewInvites !== 0}
                message={(location, action) => {
                    if (!location.pathname.startsWith(`/events/active/event/${id}`))
                        return "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here";
                    else return true;
                }}
            />
            <div className={classes.formHeader}>
                <EventHeader mainTitle={programEvent.name} subTitle={"Eligible Participant List"} />
                <div className={classes.inviteButtonContainer}>
                
                   
                {programEvent.status !== EventStatus.Planning && programEvent.status !== EventStatus.Recruitment && (
                    
                        <Button
                            className={classes.inviteButton}
                            variant="contained"
                            color="primary"
                            disabled={newInvites.totalNewInvites === 0 || readOnly}
                            onClick={() => setShowInviteDialog(true)}
                        >
                            Invite
                        </Button>
                    
                    )}
                </div>
            </div>
            <div className={classes.formBody}>
                <Box className={classes.actionsContainer}>
                    <Typography className={classes.eligibleParticipantsText} variant="h6">
                        Eligible Participants ({eligibleParticipants.length})
                    </Typography>
                    <Reporting data={eligibleParticipants}
                        preSelected={preSelectedReportFields}
                        label="Export" doNotExport={skippedFields} className="programEventInviteExportButton" />
                    <Button
                        className={classes.outlinedButton}
                        variant="outlined"
                        color="primary"
                        disabled={isComplete}
                        onClick={() => push(`/events/${isActive ? "active" : "inactive"}/event/${id}/add-remove-participants`)}
                    >
                        Add / Remove
                    </Button>
                    <Button
                        className={classes.outlinedButton}
                        variant="outlined"
                        color="primary"
                        disabled={isComplete}
                        onClick={() => push(`/events/${isActive ? "active" : "inactive"}/event/${id}/eligibility-criteria`)}
                    >
                        Eligibility Criteria
                    </Button>
                </Box>
                <EligibleParticipantTable participantList={eligibleParticipants} profBase={profBase} />
            </div>
            <InviteParticipantsDialog
                newInvites={newInvites}
                open={showInviteDialog}
                onClose={() => setShowInviteDialog(false)}
                onSubmit={handleInvite}
                loading={inviting}
            />
        </div>
    );
};

export default EligibleParticipantList;
