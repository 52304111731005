import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import { ajaxCallsInProgressReducer as ajaxCallsInProgress, errorMessageReducer as errorMessage } from "./ajax";
import { languageMessagesReducer as languageMessages, localeReducer as locale } from "../language/redux";
import { userReducer as user } from "./login";
import {
  schoolsReducer as schools,
  rolesReducer as roles,
  staffMiddleSchoolsReducer as staffMiddleSchools,
  CitiesReducer as cities,
  admissionSettingsReducer as admissionSettings,
} from "./codeTables";

import admissions from "../admissions/logic/admissionsRedux";

export default combineReducers({
  errorMessage,
  ajaxCallsInProgress,
  languageMessages,
  locale,
  user,
  form,
  admissions,
  schools,
  cities,
  roles,
  staffMiddleSchools,
  admissionSettings,
});
