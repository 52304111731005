import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";
import './RequestList.css';
import { request } from 'http';

const renderHeader = () => {
    return (
        <Fragment>
            <div className="request-list-header">
                <div className="request-list-column">
                    <span>Requests Submitted</span>
                </div>
                <div className="request-list-column">
                    <span>Status</span>
                </div>
                <div className="request-list-column">
                    <span>Amount</span>
                </div>
            </div>
        </Fragment>
    );
};
const renderBody = (currentId, requests, onClick) => {
    if (!requests || request.length === 0) return <div className="request-list-body" />;
    return (
        <div className="request-list-body">
            {requests.map((request, index) => {
                const date = moment.utc(request.date).format('M/D/YY');
                const clsName = currentId === request.id ? 'request-list-row request-list-row-selected' : 'request-list-row';
                return (
                    <button className={clsName} key={request.id} onClick={onClick.bind(null, request)}>
                        <div>
                            <div className="request-list-column">
                                <span>{date}</span>
                            </div>
                            <div className="request-list-column">
                                <span>{request.status}</span>
                            </div>
                            <div className="request-list-column">
                                <span>{formatCurrencyNoDecimalWithNegativeStyle(request.amount)}</span>
                            </div>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};
const RequestList = ({ currentId, requests, onLoadCurrent, onClick }) => {
    return (
        <div className="scholarship-request-payment-list">
            <button className="btn" onClick={onLoadCurrent}>
                Current Request List
            </button>
            {renderHeader()}
            {renderBody(currentId, requests, onClick)}
        </div>
    );
};

RequestList.propTypes = {
    requests: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.string,
            date: PropTypes.date,
            status: PropTypes.string
        })
    ),
    onClick: PropTypes.func.isRequired,
    onLoadCurrent: PropTypes.func.isRequired,
    currentId: PropTypes.string
};
export default RequestList;
