import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import LoadingIndicator from "../../common/loadingIndicator";
import EventHeader from "../common/eventHeader/eventHeader";
import NonSeriesTable from "./components/attendanceListTables/nonSeriesTable";
import ApprovingDialog from "./components/approvingDialog/approvingDialog";
import { getProgramSubCodeDepartmentAssignments, updateEventStatus } from "../api/programEvents";
import useAjaxStatus from "../../common/useAjaxStatus";
import enumHelper from "../../support/logic/enumHelper";
import { EventLocationType, EventStatus } from "../enums";
import Button from "../../support/components/Button";
import useProgramEvent from "../hooks/useProgramEvent";


const EventAttendanceList = ({topUser }) => {
    const history = useHistory();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const {
        state: { programName },
    } = useLocation();

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [isApprovingDialogOpen, setIsApprovingDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({ eventName: "programName", eventDate: "date", programEventDateId: "programEventDateId" });
    const [reloadData, setReloadData] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const { programEvent, loading: loadingProgramEvent, setProgramEvent } = useProgramEvent(id);
    const isLastToApprove = rows.filter(({ approved }) => approved).length === rows.length - 1;
    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    useEffect(() => {
        getProgramSubCodeDepartmentAssignments(id)
            .then((resp) => {
                const _rows = resp.data.result.map((data) => ({
                    ...data,
                    locationType: enumHelper.getDisplayName(EventLocationType, data.locationType),
                }));
                setRows(_rows);
                setIsLoading(false);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, id, reloadData]);




    const openApprovingDialog = (date, programEventDateId) => {
        setDialogData({ eventName: programName, eventDate: date, id: programEventDateId });
        setIsApprovingDialogOpen(true);
    };
    const closeApprovingDialog = (reload) => {
        setIsApprovingDialogOpen(false);
        if (isLastToApprove) {
            updateEventStatus(id, EventStatus.Complete);
        }
        if (reload) {
            setReloadData((prev) => !prev);
        }
       
    };

    const handleOnClickDepartment = (rowData) => {
        rowData.programName = programName;

        history.push({
            pathname: `/events/active/event/${rowData.id}/attendance-list`,
            state: { rowData, programEventId: id },
        });
    };

    if (isLoading) return <LoadingIndicator loading={isLoading} />;
    
    return (
        <>
            <EventHeader mainTitle={programName} subTitle="Attendance List" />
           
            <div style={{ height: "100%", width: "100%" }}>
                <NonSeriesTable rows={rows} handleOnClickDepartment={handleOnClickDepartment} openApprovingDialog={openApprovingDialog} disabled={readOnly} />
            </div>
            
            <ApprovingDialog
                isOpen={isApprovingDialogOpen}
                isLastToApprove={isLastToApprove}
                eventData={dialogData}
                closeDialog={closeApprovingDialog}
                setRows={setRows}
            />
        </>
    );
};

export default EventAttendanceList;
