import React, { Component } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Search from "../../common/search";
import * as userApis from "../users/apis";
import b64toBlob from "../../../support/logic/b64toBlob";

import FileSaver from "file-saver";

import "./Header.css";
import { ADMIN_USERS_ROLEID_KEY, ROLE_NAME_CRUSADER, ROLE_NAME_PARENT, SEARCH_TEXT } from "../../../support/constants";

class Header extends Component {
    state = {
        defaultValue: "",
        isExportDisbaled: true,
    };
    static getDerivedStateFromProps = (props, state) => {
        if (props.roles.length === 0 || state.defaultValue) return state;
        const lastRoleId = parseInt(localStorage.getItem(ADMIN_USERS_ROLEID_KEY), 10);
        const defaultRole = props.roles[0];
        

        props.onRoleChange(lastRoleId || defaultRole.value);
        const role = props.roles.find((r) => r.value === props.currentRoleId);

        const isAddUserBtnDisabled = role && [ ROLE_NAME_PARENT].includes(role.label);

        state={
            
            isAddUserBtnDisabled: isAddUserBtnDisabled,
        };
        return {
            defaultValue: lastRoleId || defaultRole.value,
        };
    };

    onRoleChange = (e) => {
        const roleId = parseInt(e.target.value, 10);
        
        localStorage.setItem(ADMIN_USERS_ROLEID_KEY, roleId);
        const role = this.props.roles.find((r) => r.value === roleId);
        const isAddUserBtnDisabled = role && [ROLE_NAME_PARENT].includes(role.label);
        this.setState({
            defaultValue: roleId,
            isAddUserBtnDisabled: isAddUserBtnDisabled,
            isExportDisbaled: !isAddUserBtnDisabled,
        });
        this.props.onRoleChange(roleId);
    };
    onExport = async () => {
        this.setState({
            isExportDisbaled: true,
        });
        const roleId =  parseInt(localStorage.getItem(ADMIN_USERS_ROLEID_KEY));
        let st = localStorage.getItem(SEARCH_TEXT);
        const resp = await userApis.exportUser(st, roleId);
        const blob = b64toBlob(resp.data.result.data, resp.data.result.mimeType);
        FileSaver.saveAs(blob, resp.data.result.fileName);
        this.setState({
            isExportDisbaled: false,
        });
    };

    render() {
       

        return (
            <div className="admin-user-header">
                <Search onClick={this.props.onSearch} />
                <button onClick={this.props.onAdd} disabled={this.state.isAddUserBtnDisabled}>
                    <FontAwesome name="plus" />
                    <span className="title">ADD USER</span>
                </button>

                <select onChange={this.onRoleChange} value={this.state.defaultValue}>
                    {this.props.roles.map((role) => {
                        return (
                            <option value={role.value} key={role.value}>
                                {role.label.toUpperCase()}
                            </option>
                        );
                    })}
                </select>

                <span>&nbsp; </span>
                <button onClick={this.onExport} disabled={this.state.isExportDisbaled }>
                    <FontAwesome name="download" />
                    <span className="title"> EXPORT USERS</span>
                </button>
            </div>
        );
    }
}

Header.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRoleChange: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
};

export default Header;
