import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getAllInstitutions, getIpeds } from "../api/programEvents";

const useInstitution = () => {
    const [institutionList, setInstitutionList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ipedList, setIpedList]  = useState([]);
    const getInstitutionListAsync = useCallback(async (tokenSource) => {
        try {
            const response = await getAllInstitutions(tokenSource.token);
            setInstitutionList(response.data.result);
            const ipResp = await getIpeds();
            setIpedList(ipResp.data.result);
        } catch (ex) {
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        getInstitutionListAsync(tokenSource);
        return () => {
            tokenSource.cancel();
        };
    }, [getInstitutionListAsync]);

    return {
        institutionList,
        loading,
        ipedList
    };
};

export default useInstitution;
