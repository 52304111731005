import React, { Component, Fragment } from 'react';
import { Grid, Checkbox, TextField, Button, Typography, IconButton } from '@material-ui/core';
import Multiselect from 'multiselect-react-dropdown';
import AdvisorContext from "../context/context";
import { MuiPickersUtilsProvider, DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import { addAdvisorEvent, updateAdvisorEvent, returnAdvisorTags, updateAdvisorEventHours, getAdvisorEventCreditHours, getStudentById } from "../api";
import { v4 as uuidv4 } from 'uuid';
import { Tabs, Tab } from '@mui/material';

import Tagging from '../../common/tagging';
import './index.css';
import Link from "@material-ui/core/Link";
import { eventType } from "../enums.js";
import moment from "moment";
import { toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function ensureISOFormat(date) {
    //console.log(date);
    try {
        const iso = date.toISOString();
        //console.log(iso);
        return iso;
    } catch (ex) {
        //console.log(ex)
        return date;
    }
    
}
const tagFilter = {
    searchText: "",
    IsActive: true,
    UseAdvisors: true,

}

function convertISOToEST(isoString) {
    const date = new Date(isoString);

    // Check if the Date is valid
    if (isNaN(date.getTime())) {
        return isoString;
        throw new Error("Invalid ISO date string");
    }

    // Format the date to show it in 'America/New_York' timezone
    return date.toLocaleString('en-US', { timeZone: 'America/New_York', timeZoneName: 'short' });
}
function toDate(date) {
    try {
        return date.toDate();
    } catch (ex) {
        return date;
    }
}
function isValidDate(dateString) {
    if (dateString == null || dateString == '') {
        return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

function hoursBetweenDates(date1, date2) {
    const d1Date = date1 instanceof Date;
    const d2Date = date2 instanceof Date;
    if (d1Date && d2Date) {
        // Set seconds and milliseconds to zero for both dates
        date1.setSeconds(0, 0);
        date2.setSeconds(0, 0);

        var date1_ms = date1.getTime();
        var date2_ms = date2.getTime();

        // Calculate the difference in milliseconds and convert to hours
        var difference_ms = date2_ms - date1_ms;

        return Number((difference_ms / 1000 / 60 / 60).toFixed(4));
    }
    return 0;
}


class LogEntry extends Component {

    static contextType = AdvisorContext;

    constructor(props) {
        super(props);
        this.state = {
            beginDate: new Date(),
            endDate: new Date(),
            mode: true,
            count: 0,
            activeEntry: [],
            entryMode: this.props.mode,
            hours: 0.00,
            tab: 0,
            genVis: "tabHidden",
            tagsVis: "tabVisible",
            hoursVis: "tabHidden",
            participants: [],
            tags: [],
            partHours: [], 
            changed: false,
            description: "",
            contacted: false,
            subject: "",
            simple: false,
            type: this.props.type,
            loaded: false,
            activeHours: [],
            hoursPart: "",
            reqMessage: "tabHidden",
            parentEntry: false,
            displayWarning: false

        };
        
    }

    componentDidMount = async () => {
        
        let mode = true
        let count = 0;
        let endDate = new Date();
        let beginDate = new Date();
        let ent = [];
        let hours = 0;
        let part = [];
        let description = "";
        let contacted = false;
        let subject = "";
        let simple = false;
        let parentEntry = false;
        let genVis = "tabHidden";
        let tagsVis = "tabHidden";
        let hoursVis = "tabHidden";
        
        
        if (this.props.activeEntry != [] && this.props.activeEntry.length != 0) {

            
            

            ent = this.props.activeEntry;
            hours = hoursBetweenDates(new Date(this.props.activeEntry.startTime), new Date(this.props.activeEntry.endTime));
            contacted = this.props.activeEntry.lastContacted;
            description = this.props.activeEntry.description;
            subject = this.props.activeEntry.title;
            endDate = this.props.activeEntry.endTime ? this.props.activeEntry.endTime : new Date();
            beginDate = this.props.activeEntry.startTime ? this.props.activeEntry.startTime : new Date();
            part = await  this.getParticipants(this.props.activeEntry.attendance);
            if (this.props.activeEntry.type == eventType.AdvisorEmail || this.props.activeEntry.type == eventType.SystemEmail || this.props.activeEntry.type == eventType.StudentNote || this.props.activeEntry.type == eventType.DataChanges || this.props.activeEntry.type == eventType.OneWayCommunication) {
                simple = true
            }
            if (this.props.activeEntry.type == eventType.AdvisorEmail || this.props.activeEntry.type == eventType.SystemEmail || this.props.activeEntry.type == eventType.ContactLog) {
                tagsVis = "tabVisible";
            } else {
                genVis = "tabVisible";
            }
            
        } else {
            const newEnt = {
                advisorId: this.context.myBasics.userProfileId,
                id: uuidv4()

            }
            ent = newEnt;
        }
        if (this.props.activeProfile != null && this.props.activeProfile.length != 0) {
            if (this.props.activeProfile.children) {
                parentEntry = true;
            }
            const found = part.filter(flt => flt.userProfile.id == this.props.activeProfile.userProfile.id);
            if (found.length == 0){
                part.push(this.props.activeProfile);
                mode = false;
                

            }
            
        } else {
            if (this.state.entryMode == "New") {
                part = this.context.recipients;
            } 
            
           
        }
        count = part.length;
        this.setState({ parentEntry, simple: simple, endDate: toDate(endDate), beginDate: toDate(beginDate), count: count, mode: mode, activeEntry: ent, hours: hours, participants: part, contacted: contacted, description: description, subject: subject, genVis: genVis, tagsVis: tagsVis, hoursVis: hoursVis  });
    }
    updateSubject = (event) => {

        this.setState({ subject: event.target.value });
    };
    setChanged = (newVal) => {



        this.setState({ changed: newVal });

        this.formTagHours();
    }

    getHours(partRes, userProfileId, id, weight) {
        const filt = partRes.filter(flt => flt.userProfileId == userProfileId && flt.advisorEventTagId == id);
        
        if (filt.length > 0) {
            
            return filt[0].creditHours;
        }
        return weight * this.state.hours;
    }

    formTagHours = async () => {

        const parthrs = await getAdvisorEventCreditHours(this.props.activeEntry.id);
        
        const partRes = parthrs.data.result;

        let partHour = [];
        const input = { Id: this.state.activeEntry.id };
        var tags = await returnAdvisorTags(input);

        

        // Process tags to form the base structure of tagHours
        let baseTagHours = tags.data.result.flatMap(cla =>
            cla.tags.map(tag => ({
                tagName: tag.tagName,
                tagWeight: tag.weight,
                tagId: tag.tagId,
                subtagId: tag.subtagId,
                id: tag.id,
                advisorEventId: this.state.activeEntry.id
            }))
        );

        // Iterate over participants to create their specific tag hours
        let partHourPromises = this.state.participants.map(async (part) => {
            let tagHrsPromises = baseTagHours.map(async tagHour => {
                let hours = await this.getHours(partRes, part.userProfile.id, tagHour.id, tagHour.tagWeight);
                return {
                    ...tagHour,
                    userProfileId: part.userProfile.id,
                    hours: hours
                };
            });

            let tagHrs = await Promise.all(tagHrsPromises);

            return { userProfile: part, tagDist: tagHrs };
        });

        partHour = await Promise.all(partHourPromises);

        this.setState({ partHours: partHour });
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevState.beginDate != this.state.beginDate || prevState.endDate != this.state.endDate) {
            //console.log(this.state.endDate);
            if (this.state.beginDate > this.state.endDate) {
                this.setState({ loaded: true, endDate: this.state.beginDate, hours: 0, displayWarning: true });
            } else {
                if (this.state.loaded && this.state.beginDate < this.state.endDate) {
                    let hours = this.state.hours;
                    hours = hoursBetweenDates(new Date(this.state.beginDate), new Date(this.state.endDate));
                    if (hours < 0) {
                        this.setState({ loaded: true, endDate: this.state.beginDate, hours: 0, displayWarning: true });
                    }else {
                        
                        this.setState({ hours: hours, displayWarning: false });
                        this.setChanged(true);
                    }
                    
                } else {
                    this.setState({ loaded: true });
                }
            }
            
            
        }
        
    }


    handleBeginDateChange = (date) => {
        //console.log(date);
        if (isValidDate(date)) {
            let beginDate = toDate(date);
            //console.log(beginDate);
            let end = new Date(this.state.endDate);

            let hours = hoursBetweenDates(beginDate, end);
            if (hours < 0) {
                this.setState({ loaded: true, beginDate: beginDate, endDate: beginDate,  hours: 0, displayWarning: true });
            } else {
                this.setState({ beginDate: beginDate, hours: hours, displayWarning: false });
            }
        }
        
        
    };
    handleEndDateChange = (date) => {
        //console.log(date);
        if (isValidDate(date)) {
            let endDate = toDate(date);
            //console.log(endDate);
            let begin = new Date(this.state.beginDate);

            let hours = hoursBetweenDates(begin, endDate);
            if (hours < 0) {
                this.setState({ loaded: true, endDate: this.state.beginDate, hours: 0, displayWarning: true });
            } else {
                this.setState({ endDate: endDate, hours: hours, displayWarning: false });
            }
        }
        
    };
    getParticipants = async (att) => {
        
        let parts = [];
        if (att) {
            for (const at of att) {
               
                const part = this.context.myCases.find(fnd => fnd.userProfile.id == at.userProfileId);
                if (part) {
                    parts.push(part);
                } else {
                    
                    const participant = await getStudentById(at.userProfileId);
                    
                    parts.push(participant.data.result);
                }
            }
       
        }
       
        return parts;
    }

    onAddTag = (tag) => {
        this.setState(prevState => ({
            tags: [...prevState.tags, tag] , changed: true
        }));
    };

    onRemoveTag = (tagToRemove) => {
        this.setState(prevState => ({
            tags: prevState.tags.filter(tag => tag !== tagToRemove), changed: true
        }));
    };

    onUpdateTag = (newTag) => {
        
        let filtered = this.state.tags.filter(flt => !(flt.tagsId == newTag.tagsId && flt.subtagId == newTag.subtagId));
        filtered.push(newTag);
        this.setState({ tags: filtered, changed: true });
    };
    setTab = async (event, newVal) => {
        let genVis = "tabHidden";
        let tagsVis = "tabHidden";
        let hoursVis = "tabHidden";
        switch (newVal) {
            case 1:
                genVis = "tabVisible";
                tagsVis = "tabHidden";
                hoursVis = "tabHidden";
                break;
            case 0:
                genVis = "tabHidden";
                tagsVis = "tabVisible";
                hoursVis = "tabHidden"
                break;
            case 2:
                genVis = "tabHidden";
                tagsVis = "tabHidden";
                hoursVis = "tabVisible";
                this.formTagHours();
                break;
            default:
                genVis = "tabVisible";
                tagsVis = "tabHidden";
                hoursVis = "tabHidden";
                break;


        }

        this.setState({ tab: newVal, genVis: genVis, tagsVis: tagsVis, hoursVis: hoursVis});
    }
    handleChange = (index, event) => {
        
        const newHours = event.target.value;
        const userProfileId = this.state.activeHours[0].userProfileId;



        const ah = this.state.partHours.filter(flt => flt.userProfile.userProfile.id == userProfileId);

        
        ah[0].tagDist[index].hours = newHours;
        
        

        const ph = this.state.partHours;
        for (const item of ph) {
            if (item.userProfile.userProfile.id == userProfileId) {
                item = ah[0];
            }
        }

        this.setState({ activeHours: ah[0].tagDist, partHours: ph});
        
    };



    toggleParticipant = (list, newRec) => {


        const newRecipient = this.context.myCases.filter(flt => flt.userProfile.id == newRec.userProfileId)

        // Check if the recipient is already in the array
        const isRecipientPresent = this.state.participants.includes(newRecipient[0]);

        // If the recipient is present, filter it out; if not, add it
        const updatedRecipients = isRecipientPresent
            ? this.state.participants.filter(recipient => recipient !== newRecipient[0])
            : [...this.state.participants, newRecipient[0]];

        this.setState({ participants: updatedRecipients, changed: true });





    }
    approveHours = async (item) => {
        await updateAdvisorEventHours(item);
    }

    approveActive = async () => {
        
       await this.approveHours(this.state.activeHours);
        toast.success("Update Successful");
    }

    approveAll = async () => {
        
        for (const item of this.state.partHours) {
            await this.approveHours(item.tagDist);
        } 
        toast.success("Update Successful");
    }
    updateDescription = (event) => {

        this.setState({ description: event.target.value });
    };

    customStudentOptionRenderer = (option, val, test) => {
        
        return (
            <div className="filterSelection" >

                {val.userProfile.lastName}, {val.userProfile.firstName}

            </div>
        );
    };

    onTagLoad = (tags) => {
        
        this.setState({
            tags: tags
        });
    }

    handleSubmit = async (close) => {
        let message = "";
        if (this.state.activeEntry.type == eventType.ContactLog && this.state.tags.length == 0) {
            this.setState({ reqMessage: "tabVisible" });
            this.setTab(null, 0);
            return;
        } else {

            if (this.state.activeEntry.readOnly) {
                return null;
            }
            const data = this.state.activeEntry;
            data.totalAvailableCreditHours = this.state.hours;
            data.lastContacted = this.state.contacted;
            data.description = this.state.description;
            data.startTime = new Date(this.state.beginDate.toString());
            data.endTime = new Date(this.state.endDate.toString());
            data.type = this.state.activeEntry.type;
            data.title = this.state.subject;
            data.lastModificationTime = new Date();
            data.lastModifierUserId = this.context.myBasics.userProfile.userId;
            const attend = this.state.participants.map(item => {

                return (
                    {
                        id: uuidv4() , userProfileId: item.userProfileId, userProfile: item.userProfile , AdvisorEventId: this.state.activeEntry.id, TotalAvailableCreditHours: this.state.hours, creatorUserId: this.context.myBasics.userProfile.userId, creationTime: new Date(), lastModificationTime: null, lastModifierUserId: null }
                );
            })


            data.attendance = attend;
            //console.log(data);
            if (this.state.entryMode == "New") {
                await addAdvisorEvent(data);
            } else {
                await updateAdvisorEvent(data);
            }
            this.setState({ entryMode: "Edit"});
            if (this.props.type == "note") {
                if (this.state.entryMode == "New") {
                    message = "Note Saved"
                } else {
                    message = "Note Updated"
                }
            } else {
                if (this.state.entryMode == "New") {
                    message = "Entry Saved"
                } else {
                    message = "Entry Updated"
                }
            }

            const list = this.state.participants.map((item, index) => {
                return { userProfileId: item.userProfile.id };
            });

            this.context.refreshStudentInfo(list);
            toast.success(message);
            if (close) {
                if (this.props.goBack && typeof this.props.goBack == 'function') {
                    this.props.goBack();

                } else {
                    if (this.props.onClose && typeof this.props.onClose == 'function') {
                        this.props.onClose();

                    }
                }
            } else {
                this.setTab(null, 2);
            }
        }
    }
    goBack = () => {
        if (this.props.goBack && typeof this.props.goBack == 'function') {
            this.props.goBack();

        } else {
            if (this.props.onClose && typeof this.props.onClose == 'function') {
                this.props.onClose();

            }
        }

    }
    renderParticipantChips = () => {
       
        return this.state.participants.map((item, index) => (

            
            <div key={index} className="chip">
                {item.userProfile.lastName}, {item.userProfile.firstName} {(item.studentProfile && item.studentProfile.nonOnwardStudent) ? <AccountCircleIcon style={{ color: '#e2a331' }} /> : null }
            </div>
        ));
    }

    selectPartHours = (item) => {

        const ah = this.state.partHours.filter(flt => flt.userProfile.userProfile.id == item.item.userProfile.userProfile.id);

        this.setState({ activeHours: ah[0].tagDist, hoursPart: item.item.userProfile.userProfile.lastName + ", " + item.item.userProfile.userProfile.firstName })

    }

    render() {
        
        if (!this.context.loaded & this.props.goBack && typeof this.props.goBack == 'function') {
            this.props.gotoLocation("dashboard");

        }
        
        const dispDate = moment(this.state.beginDate).format("M/D/YY h:mm a");
        const createDate = moment(this.state.activeEntry.creationTime).format("M/D/YY h:mm a");
        const editedDate = this.state.activeEntry.lastModificationTime != null ? moment(this.state.activeEntry.lastModificationTime).format("M/D/YY h:mm a") : null;
        const { activeEntry, participants } = this.state;
        const { myCases } = this.context;
        const caseList = myCases.map(mc => {
            return { display: mc.display, userProfileId: mc.userProfileId }
        }

        );
        const parts = this.state.participants.map(mc => {
            return { display: mc.display, userProfileId: mc.userProfileId }
        }

        );
        let readonly = true;
        if (this.state.entryMode == "New" || ((activeEntry.type == eventType.ContactLog || activeEntry.type == eventType.StudentNote) && (activeEntry.advisorId == this.context.myBasics.userProfileId || this.context.myBasics.isAdmin || this.context.myBasics.isSupervisor || this.context.myBasics.isStaff))) {
            readonly = false;
        }
        let content;
        if (!readonly && this.state.activeEntry.type == eventType.ContactLog && !this.state.parentEntry) {
           
            
            content = (
                <Multiselect
                    options={caseList}
                    showCheckbox={true}
                    onSelect={this.toggleParticipant}
                    onRemove={this.toggleParticipant}
                    selectedValues={parts}
                    displayValue="display"
                    className="AdvisorLogsStudents"
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    name="recipientSel"
                    placeholder="Select Participants"
                    disabled={readonly}
                />
            );
        } else {
            content = this.renderParticipantChips();
        }
        //console.log(this);
        return (
            <div className="container">
                <Link
                    className={"dashboardLink"} onClick={this.goBack}
                >
                    &lt; Back
                </Link> <br />
                <div className="content">
                <Grid container spacing={2}>

                        <Grid item xs={12}>
                            {this.props.type == "note" || this.state.activeEntry.type == eventType.StudentNote  ? <Typography variant="h6">Note</Typography> : null}
                            {this.state.activeEntry.type == eventType.ContactLog ? <Typography variant="h6">Log Entry for {this.state.count} Students</Typography> : null }
                        
                    </Grid>

                        <Grid item xs={12}>
                            {this.props.type != "note" && this.state.activeEntry.type != eventType.StudentNote && this.state.activeEntry.type != eventType.OneWayCommunication ?
                                <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Log Entry Sections" variant="fullWidth"
                                    sx={{
                                        '.MuiTabs-flexContainer': {
                                            justifyContent: 'space-evenly',
                                        },
                                        '.MuiTab-root': {
                                            border: '1px solid #000',
                                            borderBottom: 'none',
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px',
                                            marginRight: '4px',
                                            '&:last-child': {
                                                marginRight: 0,
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: '#123225',
                                                color: '#fff',
                                            },
                                            '&:hover': {
                                                backgroundColor: '#123225',
                                                color: '#fff',
                                                opacity: 1,
                                            },
                                        },
                                    }}
                                >
                                    <Tab label="Tags" />
                                    <Tab label="General Information" />
                                    {!this.state.simple ? <Tab label={this.state.changed ? "Participant Hours *" : "Participant Hours"} /> : null}

                                </Tabs> : null}
                    </Grid>
                        <Grid container spacing={2} className={this.state.genVis}>

                            <Grid item xs={12}>{content}</Grid>

                            {this.state.entryMode == "New" ?
                            <Fragment>
                            <Grid item xs={12}>
                                        {this.props.type == "note" || this.state.activeEntry.type == eventType.StudentNote ? <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Quick Summary"
                                            name="title"
                                            onBlur={this.updateSubject}


                                        > {this.state.subject} </TextField> : null}
                            </Grid>
                                    <Grid item xs={12}>

                                        {this.props.type == "note" ? <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Details"
                                            name="activityDescription"
                                            onBlur={this.updateDescription}
                                            disabled={readonly}
                                            onChange={this.updateDescription}
                                            value={this.state.description}
                                            multiline
                                            rows={4}
                                        /> : null}
                                        {this.props.type == "log" || this.state.activeEntry.type == eventType.ContactLog ? <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Describe Activity"
                                            name="activityDescription"
                                            onBlur={this.updateDescription}
                                            disabled={readonly}
                                            onChange={this.updateDescription}
                                            value={this.state.description}
                                            multiline
                                            rows={4}
                                            
                                        /> : null}



                                    </Grid>
                                </Fragment>

                                : 
                                <Fragment>
                                    <Grid item xs={12}>
                                        {this.props.type == "note" ?
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Quick Summary"
                                                name="title"
                                                onBlur={this.updateSubject}
                                                onChange={this.updateSubject}
                                                value={this.state.subject}
                                                disabled={readonly}
                                            /> : null}
                                    </Grid>
                                    <Grid item xs={12}>

                                        {this.props.type == "note" ? <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Details"
                                            name="activityDescription"
                                            onBlur={this.updateDescription}
                                            disabled={readonly}
                                            onChange={this.updateDescription}
                                            value={this.state.description}
                                            multiline
                                            rows={4}
                                        /> : null}
                                        {this.props.type == "log" ? <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Describe Activity"
                                            name="activityDescription"
                                            onBlur={this.updateDescription}
                                            disabled={readonly}
                                            onChange={this.updateDescription}
                                            value={this.state.description}
                                            multiline
                                            rows={4}
                                        /> : null}
                                        
                                        
                                        
                                    </Grid>
                                </Fragment>
                            }
                            <Grid item xs={4}>
                                
                                {this.state.activeEntry.type == eventType.ContactLog ? <MuiPickersUtilsProvider utils={MomentUtils}>

                                    <KeyboardDateTimePicker
                                        label="Begin Date and Time"
                                        name="beginDate"
                                        inputVariant="outlined"
                                        value={this.state.beginDate}
                                        disabled={readonly}
                                        onChange={this.handleBeginDateChange}
                                    />
                                </MuiPickersUtilsProvider> : <Typography variant="body1" color="primary" gutterBottom>
                                        Date:     {dispDate}

                                </Typography>}

                            
                            </Grid>
                            {!this.state.simple ? 
                                <Fragment>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>

                                <KeyboardDateTimePicker
                                        label="End Date and Time"
                                        name="endDate"
                                    inputVariant="outlined"
                                        
                                        value={this.state.endDate}
                                        disabled={readonly}
                                    onChange={this.handleEndDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>


                            <Typography variant="h5" color="primary" gutterBottom>
                                Total Hours {this.state.hours}

                                        </Typography>
                                        
                                        
                        </Grid>
                                    

                                </Fragment> : null}
                            {!readonly ?
                                <div className="buttonContainer">
                                    <Button variant="contained" color="primary" disabled={this.state.changed} onClick={()=>this.handleSubmit(true)}>Save & Close</Button>
                                    {!this.state.simple ? <> &nbsp; <Button variant="contained" color="primary" disabled={readonly} onClick={() => this.handleSubmit(false)}>Save & Continue</Button></> : null}
                                    &nbsp; <Button variant="contained" onClick={() => this.goBack()}>Cancel</Button>
                                    {this.state.displayWarning == true ? <span className="TimeWarning">
                                        End time cannot be before start time. <br/> The end time has been set to start time, and hours are 0.

                                    </span> : null}                                </div> :
                                <div className="buttonContainer">
                                    <Button variant="contained" onClick={() => this.goBack()}>Cancel</Button>
                                   
                                </div>
                                
                            }
                            
                    </Grid>
                    <Grid container spacing={2} className={this.state.tagsVis}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="secondary" gutterBottom>
                                Tagging

                            </Typography>
                                <Tagging tagFilter={tagFilter}
                                    mode="Advisor"
                                    id={this.state.activeEntry.id}
                                    onChange={this.setChanged}
                                    disabled={readonly}
                                    onTagLoad={this.onTagLoad }

                                />
                                <Typography variant="h4" color="red" className={this.state.reqMessage}>
                                    At least 1 tag is required.

                                </Typography>
                        </Grid>
                    </Grid>
                        <Grid container spacing={2} className={this.state.hoursVis}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Hours
                                </Typography>
                            </Grid>

                            {/* Container for part hours and active hours */}
                            <Grid container item xs={12} spacing={2}>
                                {/* Part Hours */}
                                <Grid item xs={4}>
                                    {this.state.partHours.map((item, index) => (
                                        <Grid item xs={12} key={index} className="partHoursPartListItem" >
                                            <Button onClick={() => this.selectPartHours({ item })} onKeyPress={() => this.selectPartHours({ item })}  fullWidth>{item.userProfile.userProfile.lastName}, {item.userProfile.userProfile.firstName}</Button>   
                                        </Grid>
                                    ))}
                                </Grid>

                                {/* Active Hours */}
                                <Grid item xs={6}>
                                    <Grid item xs={12} className="PartHoursheader">{this.state.hoursPart}</Grid>
                                    {this.state.activeHours.map((item, index) => (
                                        <Grid container xs={12} key={index}>
                                        
                                            <Grid item xs={6}>
                                                {item.tagName}</Grid> <Grid item xs={6}>  <TextField
                                                    fullWidth
                                                    name="hours"
                                                    value={item.hours}
                                                    onChange={(event) => this.handleChange(index, event)}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                    {this.state.activeHours.length > 0 ?
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={this.approveActive }>Approve</Button>
                                        </Grid> : null}
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={this.approveAll}>Approve All</Button>
                            </Grid>
                            <Grid item xs={12}>
                                {!readonly ?
                                    <div className="buttonContainer">
                                        <Button variant="contained" color="primary" disabled={readonly} onClick={() => this.handleSubmit(true)}>Save & Close</Button>
                                
                                        &nbsp; <Button variant="contained" onClick={this.goBack}>Cancel</Button> </div> :
                                    <div className="buttonContainer">
                                        <Button variant="contained" onClick={this.goBack}>Cancel</Button>
                                    </div>
                                }
                            </Grid>

                        </Grid>

                        {this.state.entryMode != "New" ? <Grid item xs={12} container alignItems="right" spacing={1}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={3}>
                                Created: {createDate}
                            </Grid>
                            <Grid item xs={3}>
                                By: {this.state.activeEntry.creator}
                            </Grid>
                            {this.state.activeEntry.editor != null ?
                                <>
                                    <Grid item xs={6}>

                                    </Grid>
                                    <Grid item xs={3}>
                                        &nbsp; Edited: {editedDate}
                                    </Grid>
                                    <Grid item xs={3}>
                                        By: {this.state.activeEntry.editor}
                                    </Grid>
                                </> : null}
                        </Grid>
                        
                     : null }
                    </Grid>
                    </div>
                   
                
                    
                    
                        
                    
                
                
            </div>

             );
    };



}


export default LogEntry;

