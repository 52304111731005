import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ajaxStatusLoadingAction, ajaxStatusCompleteAction, ajaxStatusErrorAction, ajaxClearAll } from "../../redux/ajax";

const useAjaxStatus = () => {
    const ajaxCallsInProgress = useSelector((state) => state.ajaxCallsInProgress);
    const dispatch = useDispatch();

    return {
        ajaxCallsInProgress,
        ajaxLoad: useCallback(() => dispatch(ajaxStatusLoadingAction()), [dispatch]),
        ajaxComplete: useCallback(() => dispatch(ajaxStatusCompleteAction()), [dispatch]),
        ajaxError: useCallback((error) => dispatch(ajaxStatusErrorAction(error)), [dispatch]),
        ajaxErrorWrapper: useCallback(
            (error) => {
                dispatch(
                    ajaxStatusErrorAction(
                        error && error.response && error.response.data && error.response.data.error && error.response.data.error.message
                            ? error.response.data.error.message
                            : error
                    )
                );
            },
            [dispatch]
        ),
        ajaxClearAll: useCallback(() => dispatch(ajaxClearAll), [dispatch]),
    };
};

export default useAjaxStatus;
