export const LOCALE_EN = "en";
export const LOCALE_ES_MX = "es-MX";

export const COLLEGE_ID_REMINDER_TEXT = "College ID Reminder TEXT";
export const FERPA_REMINDER_TEXT = "Ferpa Reminder TEXT";

export const OTHER_INSTITUTION_ID = "ad774e92-61b4-44be-b710-7b0e8a196a83";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const ROLE_NAME_ADMIN = "Admin";
export const ROLE_NAME_STAFF = "Staff";
export const ROLE_NAME_PARENT = "Parent";
export const ROLE_NAME_INSTITUTION = "Institution";
export const ROLE_NAME_FINANCE = "Finance";
export const ROLE_NAME_CRUSADER = "Student";
export const ROLE_NAME_ADVISOR = "Advisor";

export const EVENTS_STEP_KEY = "event-current-step";
export const ADMIN_USERS_ROLEID_KEY = "admin-users-roleid";
export const SEARCH_TEXT = "admin-search-text";