import React from "react";
import ReactTable from "react-table";
import moment from "moment";

import enumHelper from "../../../support/logic/enumHelper";
import { Gender, ApplicationStatus } from "../../../admissions/logic/enums";

import "./inreview.css";

const InReview = ({ data, getTrProps, school }) => (
  <div className="school-table">
    <h2>{school}</h2>
    {data && data.length === 0 && <div>No currently in review applications for this school.</div>}
    {data && data.length > 0 && (
      <ReactTable
        getTrProps={getTrProps}
        data={data}
        minRows={0}
        columns={[
          {
            Header: "Last Name",
            accessor: "lastName",
          },
          {
            Header: "First Name (Middle)",
            accessor: "firstName",
            Cell: (props) => {
              const d = props.original;
              return `${d.firstName} ${d.middleName}`;
            },
          },
          {
            Header: "DOB",
            accessor: "dateOfBirth",
            Cell: (props) => moment(props.value).utc().format("M/D/YYYY"),
          },
          {
            Header: "Gender",
            accessor: "gender",
            Cell: (props) => enumHelper.getDisplayName(Gender, props.value),
            sortMethod: (a, b) => {
              if (a === b) return 0;
              if (a === 0 && b === 1) return 1;
              if (a === 1 && b === 0) return -1;
              if (a === null) return -1;
              if (b === null) return 1;
              return a > b ? 1 : -1;
            },
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: (props) => enumHelper.getDisplayName(ApplicationStatus, props.value),
          },
          {
            Header: "Last Login",
            accessor: "lastLogin",
            Cell: (props) => moment(props.value).format("M/D/YYYY LT"),
          },
        ]}
        showPagination={false}
        defaultPageSize={500}
        className="-highlight"
      />
    )}
  </div>
);

export default InReview;
