import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./index.css";

import { SecurityContext } from "../../support/security";

let TertiaryNav = ({ links }) => {
    return (
        <div className="tertiary-nav">
            <SecurityContext.Consumer>
                {(context) => {
                    return links
                        .filter((link) => context.hasEditPermission(link.permission))
                        .map((link) => (
                            <NavLink key={link.to} to={link.to} activeClassName="active-link">
                                <div className="option">{link.label}</div>
                            </NavLink>
                        ));
                }}
            </SecurityContext.Consumer>
        </div>
    );
};

TertiaryNav.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string,
            label: PropTypes.string,
            permission: PropTypes.string,
        })
    ),
};

TertiaryNav = withRouter(TertiaryNav);

export default TertiaryNav;
