import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useAjaxStatus from "../../common/useAjaxStatus";
import { getEligibleParticipants, getNewInviteCount, inviteEligibleParticipants } from "../api/programEvents";

const useEligibleParticipants = (id) => {
    const [eligibleParticipants, setEligibleParticipants] = useState(null);
    const [newInvites, setNewInvites] = useState({});
    const [loading, setLoading] = useState(true);
    const [inviting, setInviting] = useState(false);
    const { ajaxErrorWrapper } = useAjaxStatus();
    const cancelTokenSource = useMemo(() => axios.CancelToken.source(), []);
    const cancelTokenInviteSource = useMemo(() => axios.CancelToken.source(), []);

    const getDataAsync = useCallback(async () => {
        try {
            const responseParticipants = await getEligibleParticipants(id, cancelTokenSource.token);
            setEligibleParticipants(responseParticipants.data.result);
            const responseNewInvites = await getNewInviteCount(id, cancelTokenSource.token);
            setNewInvites(responseNewInvites.data.result);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setLoading(false);
        }

        return () => {
            cancelTokenSource.cancel();
        };
    }, [ajaxErrorWrapper, cancelTokenSource, id]);

    const inviteParticipantsAsync = useCallback(async () => {
        try {
            setInviting(true);
            await inviteEligibleParticipants(id, cancelTokenInviteSource.token);
            await getDataAsync();
            toast.success("Additional invitations sent");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setInviting(false);
        }

        return () => {
            cancelTokenInviteSource.cancel();
        };
    }, [id, cancelTokenInviteSource, getDataAsync, ajaxErrorWrapper]);

    useEffect(() => {
        getDataAsync();
    }, [getDataAsync]);

    return {
        eligibleParticipants,
        loading,
        getEligibleParticipantsAsync: getDataAsync,
        setEligibleParticipants,
        newInvites,
        inviteParticipantsAsync,
        inviting,
    };
};

export default useEligibleParticipants;
