/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";

import "./schoolList.css";

const SchoolList = ({ schools, onViewAllClick, onSchoolClick, selectedSchool, onQuotaChange, onQuotaBlur }) => (
    <div className="school-list">
        <div className="title-container">
            <div>Crusaders Per School</div>
            <div>
                <button className={`btn ${selectedSchool === null ? "" : "inactive"}`} onClick={onViewAllClick}>
                    View All
                </button>
            </div>
        </div>
        <div className="list-container">
            <ul>
                {schools.map((item) => (
                    <li key={item.id} className={item.id === selectedSchool ? "selected" : ""}>
                        <a tabIndex="0" role="button" onKeyPress={() => onSchoolClick(item.id)} onClick={(e) => onSchoolClick(e, item.id)} style={{ flex: 1 }}>
                            {item.name}
                        </a>
                        <div className="list-quota">
                            {item.crusaders} /{" "}
                            <input
                                className="form-input"
                                type="number"
                                min="0"
                                value={item.count}
                                onChange={(e) => onQuotaChange(e, item.id)}
                                onBlur={(e) => onQuotaBlur(e, item.id)}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

const GradeList = ({ grades, onViewAllClick, onGradeClick, selectedGrade, onQuotaChange, onQuotaBlur }) => {
    // //console.log(grades);
    return (
        <div className="school-list">
            <div className="title-container">
                <div>Crusaders Per Grade</div>
                <div>
                    <button className={`btn ${selectedGrade === null ? "" : "inactive"}`} onClick={onViewAllClick}>
                        View All
                    </button>
                </div>
            </div>
            <div className="list-container">
                <ul>
                    {grades.map((item) => (
                        <li key={item.grade} className={item.grade === selectedGrade ? "selected" : ""}>
                            <a
                                tabIndex="0"
                                role="button"
                                onKeyPress={() => onGradeClick(item.grade)}
                                onClick={(e) => onGradeClick(e, item.grade)}
                                style={{ flex: 1 }}
                            >
                                {`Grade ${item.grade}`}
                            </a>
                            <div className="list-quota">
                                {item.crusaders} /{" "}
                                <input
                                    className="form-input"
                                    type="number"
                                    min="0"
                                    max="99999"
                                    value={item.count}
                                    onChange={(e) => onQuotaChange(e, item.grade)}
                                    onBlur={(e) => onQuotaBlur(e, item.grade)}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export { SchoolList, GradeList };
