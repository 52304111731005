export const eventType = Object.freeze({
    SystemEmail: 0,
    AdvisorEmail: 1,
    ContactLog: 2,
    DataChanges: 3,
    StudentNote: 4,
    OneWayCommunication: 5,
    GradeEntry: 6,
    Other: 99,
    _displayNames: {
        0: "System Message",
        1: "Advisor Message",
        2: "Contact Log",
        3: "Data Changes",
        4: "Student Note",
        5: "One Way Communication",
        6: "Grade Entry",
        99: "Other",
    },
});