import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Cookies from "universal-cookie";

import { loginAction } from "../../redux/login";
import * as apis from "../../api/login";
import withCodeTables from "../../support/hoc/withCodeTables";
import withLocale from "../../support/hoc/withLocale";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";

import LoginUI from "./LoginUI";
import "./LoginUI.css";
import withUser from "../../support/hoc/withUser";

const EMAIL_KEY = "email_key";
const REMEMBERME_KEY = "rememberme_key";

class Login extends Component {
  state = {
    showDialog: false,
    dialogTitle: "",
    dialogText: "",
    isRememberMe: false,
    showForgotPassword: false,
    showForgotUsername: false,
    showNewAccount: false,
    showStaffLogin: false,
    showApplications: false,
    showAdmissions: false,
    showScholarship: false,
    showAdvisor: false,
    email: null,
      primaryParent: false,
    admissionState: []
  };

  constructor() {
    super();

    const email = localStorage.getItem(EMAIL_KEY);
    const rememberMe = localStorage.getItem(REMEMBERME_KEY);

    if (rememberMe) {
      this.state.isRememberMe = true;
    }

    if (email) {
      this.state.email = email;
    }
  }

  onLocaleChange = e => {
    const locale = e.target.value;
    this.props.setLocale(locale);
  };

  onForgotPassword = () => {
    this.setState({ showForgotPassword: true });
  };

  onForgotUserName = () => {
    this.setState({ showForgotUsername: true });
  };

  onLogin = values => {
    const { userNameEmail, password } = values;
    const locale = this.props.locale;

    if (this.state.isRememberMe) {
      localStorage.setItem(EMAIL_KEY, userNameEmail);
    } else {
      localStorage.removeItem(EMAIL_KEY);
    }

    this.props.ajaxStatusLoading();

    apis
      .login(userNameEmail, password, locale)
      .then(response => {
        document.location.hash = "";
        const user = response.data.result;
        const cookies = new Cookies();
        cookies.set("enc_auth_token", user.encryptedAccessToken, { path: "/" });
        this.props.login(user);
        //persist user.
        sessionStorage.setItem("user", JSON.stringify(user));
        this.props.loadCodeTables();
        this.props.ajaxStatusComplete();
          if (user.userProfile.userType === 2) {
              let prim = false;
              
              if (user.userProfile.userFamilyType == 1) {
                  prim = true;
              }
              
              this.setState({ showAdmissions: true, primaryParent: prim });
        } else if (user.userProfile.userType === 5 || user.userProfile.userType === 6) {
          this.setState({ showScholarship: true });
        } else if (user.userProfile.userType === 7) {
          this.setState({ showAdvisor: true });
        } else {
          this.setState({ showApplications: true });
        }
      })
      .catch(error => {
        this.props.ajaxStatusComplete();
        console.error(error);
        this.setState({
          showDialog: true,
          dialogTitle: error.response.data.error.message,
          dialogText: error.response.data.error.details
        });
      });
  };

  onCreate = () => {
    this.setState({ showNewAccount: true });
  };

  onRememberMeChange = () => {
    const isRememberMe = !this.state.isRememberMe;
    this.setState({
      isRememberMe
    });

    if (!isRememberMe) {
      localStorage.removeItem(EMAIL_KEY);
      localStorage.removeItem(REMEMBERME_KEY);
    } else {
      localStorage.setItem(REMEMBERME_KEY, true);
    }
  };

  onStaffLogin = () => {
    this.setState({
      showStaffLogin: true
    });
  };

  onCloseDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    if (this.state.showApplications) {
      return <Redirect to="/applications" />;
    }
    if (this.state.showAdmissions) {
        return <Redirect to={{
            pathname: '/admissions',
            state: { primaryParent: this.state.primaryParent }
        }} />;
    }
    if (this.state.showAdvisor) {
      return <Redirect to="/advisor" />;
    }
    if (this.state.showScholarship) {
      return <Redirect to="/scholarship" />;
    }
    if (this.state.showForgotUsername) {
      return <Redirect to="/forgotusername" />;
    }
    if (this.state.showForgotPassword) {
      return <Redirect to="/forgotpassword" />;
    }
    if (this.state.showNewAccount) {
      return <Redirect to="/newaccount" />;
    }
    if (this.state.showStaffLogin) {
      return <Redirect to="/stafflogin" />;
    }

    return (
      <React.Fragment>
        <LoginUI
          onLocaleChange={this.onLocaleChange}
          onForgotPassword={this.onForgotPassword}
          onForgotUserName={this.onForgotUserName}
          onLogin={this.onLogin}
          onCreate={this.onCreate}
          onRememberMeChange={this.onRememberMeChange}
          locale={this.props.locale}
          isRememberMe={this.state.isRememberMe}
          initialValues={{ email: this.state.email }}
          isStaff={this.props.isStaff}
          onStaffLogin={this.onStaffLogin}
        />
        <SweetAlert
          show={this.state.showDialog}
          title={this.state.dialogTitle}
          onConfirm={this.onCloseDialog}
          confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
        >
          {this.state.dialogText}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: bindActionCreators(loginAction, dispatch)
  };
};

Login = connect(null, mapDispatchToProps)(Login);
Login = withUser(Login);
Login = withCodeTables(Login);
Login = withLocale(Login);
Login = withAjaxStatus(Login);
Login = withRouter(Login);
Login = injectIntl(Login);

export default Login;
