import React, { useEffect, useState } from "react";
import moment from "moment";
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import { Field, Form } from "react-final-form";
import EligibilityCriteriaSection from "../eligibleParticipantList/components/eligibilityCriteriaSection";
import Button from "@material-ui/core/Button";
import { Prompt,  useHistory, useParams } from "react-router-dom";
import LoadingButton from "../../common/loadingButton";
import EventStepper from "./components/stepper";
import EventMeta from "./components/eventMeta";
import LoadingIndicator from "../../common/loadingIndicator";
import { EventStatus } from "../enums";
import EventPlanningStep from "./steps/eventPlanningStep";
import useProgramEvent from "../hooks/useProgramEvent";
import { updateEventStatus, GetUserByIdAsync } from "../api/programEvents";
import useAjaxStatus from "../../common/useAjaxStatus";
import EventRecruitmentStep from "./steps/eventRecruitmentStep";
import EventOpenEnrollmentStep from "./steps/eventOpenEnrollmentStep";
import { toast } from "react-toastify";
import EventReviewStep from "./steps/eventReviewStep";
import { EVENTS_STEP_KEY } from "../../support/constants";
import { messageParticipants, CreateTemplateFromProgram, getNewInviteCount, inviteEligibleParticipants, cancelEvent, cancelEventDate } from "../api/programEvents";
import { FinalFormCheckbox,  FinalFormTextField } from "../../common/formWrappers";
import { required } from "../../common/formValidators";
import "./index.css";
import Modal from "react-modal";

const useStyles = makeStyles((theme) => ({
    event: {},
    button: {},
}));

const Event = ({ isActive, isComplete, topUser }) => {
    const classes = useStyles();
    const { id } = useParams();
    const { programEvent, loading, setProgramEvent } = useProgramEvent(id);
    const [currentStep, setCurrentStep] = useState(0);
    const [messaging, setMessaging] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [messagingWarningClass, setMessagingWarningClass] = useState("messagingWarningHidden");
    const [deleteWarning, setDeleteWarning] = useState("messagingWarningHidden");
    const history = useHistory();
    const [newEleg, setNewEleg] = useState(0);
    const { ajaxErrorWrapper, ajaxLoad, ajaxComplete } = useAjaxStatus();
    const [createUser, setCreateUser] = useState("");
    const [createTime, setCreateTime] = useState("");
    const [modifiedUser, setModifiedUser] = useState("");
    const [modifiedTime, setModifiedTime] = useState("");
    const [isEventDetailsMissing, setIsEventDetailsMissing] = useState(!programEvent?.logistics && !programEvent?.venue && !programEvent?.transportation);
    const [isEligibleCriteriaMissing, setIsEligibleCriteriaMissing] = useState(!programEvent?.criteria);
    const [delDisabled, setDelDisabled] = useState(false);
    const [reloadData, setReloadData] = useState(false);

    useEffect( () => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }
       
        
        
    }, [topUser, programEvent]);


    useEffect(() => {
        const curStepStorage = localStorage.getItem(EVENTS_STEP_KEY);
        if (curStepStorage) {
            setCurrentStep(parseInt(curStepStorage, 10));
        } else {
            setCurrentStep(programEvent ? programEvent.status : 0);
        }
        if (programEvent != null) {
            // const cUser = await GetUserByIdAsync(programEvent.creatorUserId);
            //   //console.log(cUser);
            setIsEventDetailsMissing(!programEvent?.logistics && !programEvent?.venue && !programEvent?.transportation);
            setIsEligibleCriteriaMissing(!programEvent?.criteria);
            setCreateUser(programEvent.createdUser);
            setCreateTime(moment(programEvent?.creationTime).toString());



            setModifiedUser(programEvent.updatedUser);
            if (programEvent?.lastModificationTime != null) {
                setModifiedTime(moment(programEvent?.lastModificationTime).toString());
            }

        }

        //console.log(programEvent);
        //setEligibleCriteriaMissing(true);
        // //console.log(programEvent);
    }, [id, programEvent, reloadData]);
    const handleChangeStep = (step) => {
        localStorage.setItem(EVENTS_STEP_KEY, step);
        setCurrentStep(step);
    };
    const handleOpenDelete = () => {

        setDeleting(true);
    }
    const handleCancelDelete = () => {
        ajaxComplete();
        setDeleting(false);
    }

    const handleTemplateSubmit = async (form) => {
        
        let name = "";
        const params = {
            ProgramEventId: id,
            templateName: name
        }
        await CreateTemplateFromProgram(params);
        toast.success("Success! New Template Created.");

    };
    const handleRecruitClick = async () => {
        try {
            ajaxLoad();
            await updateEventStatus(id, EventStatus.Recruitment);
            setProgramEvent((prev) => ({ ...prev, status: EventStatus.Recruitment }));
            localStorage.setItem(EVENTS_STEP_KEY, EventStatus.Recruitment);
            setCurrentStep(EventStatus.Recruitment);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };
    const messagingClick = async () => {
        ajaxLoad();
        const amt = await getNewInviteCount();
        
        await setNewEleg(amt.data.result.totalNewInvites);
        setMessaging(true);
        ajaxComplete();
    };
    const handleDeleteSubmit = async (form) => {
     

       


        setDelDisabled(true);
        if (form.typedName == programEvent.name) {
            ajaxLoad();
            
            const futureEventDates = programEvent.eventDates.filter(field => moment(field.startTime).isAfter(moment()));
            
           
            
           
            let isAnyDateChecked = false;

            for (const field of futureEventDates) {
                isAnyDateChecked = form[`eventDate-${field.id}`] ? form[`eventDate-${field.id}`] : isAnyDateChecked;
            }

            


            
            
            let success = "Event Canceled"
            let todelete = false;

            if (isAnyDateChecked && programEvent.eventDates.length > 1) {
                success = "Event Date(s) Canceled"
                for (const field of programEvent.eventDates) {
                    if (form[`eventDate-${field.id}`]) {
                        const input = {
                            id: field.id,
                            delete: todelete,

                        }
                        await cancelEventDate(input);
                    }
                }
                ajaxComplete();
                toast.success(success);

                setDelDisabled(false);
                setDeleting(false);
                window.location.href = '/events/active/event/' + programEvent.id;
               
            } else {

                if (form.delete) {
                    success = "Event Canceled and Deleted"
                    todelete = true;
                }

                const input = {
                    id: id,
                    delete: todelete,

                }

                try {
                    await cancelEvent(input);
                } catch (e) {
                    //console.log(e);
                }

                ajaxComplete();
                toast.success(success);
                window.location.href = '/events/';
                setDelDisabled(false);

            }


            
           
            

        } else {
            setDeleteWarning("messagingWarningShow");
            setDelDisabled(false);
        }
            

        };
    const handleMessagingSubmit = async (form) => {
        
        let ready = false;
        
        if (form.invited || form.waitlist || form.enrolled || form.attended) {
            ready = true;
            
        }

        if (ready) {
            ajaxLoad();
            setMessagingWarningClass("messagingWarningHidden");
                 let input = {
                ProgramEventId: id,
                EnglishMessage: form.messageEnglish,
                SpanishMessage: form.messageSpanish,
                Invited: form.invited,
                Waitlist: form.waitlist,
                Enrolled: form.enrolled,
                Attended: form.attended

            };

            await messageParticipants(input);
            setMessaging(false);

            ajaxComplete();
            toast.success("Messages Sent");

        } else {
            setMessagingWarningClass("messagingWarningShow");
        }
       
    };
    const inviteNewlyEleg = async () => {
        ajaxLoad();
       
        await inviteEligibleParticipants(id);
        
        const amt = await getNewInviteCount();
        
        await setNewEleg(amt.data.result.totalNewInvites);
        ajaxComplete();
        setMessaging(false);
        toast.success(newEleg + " New Invitations Sent");
    };
    const cancelMessaging = () => {
        setMessaging(false);
    };
    const handleReactivate = async ()=> {
        try {
            ajaxLoad();
            await updateEventStatus(id, EventStatus.Planning);
            
            toast.success("Success! Your event is again active");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
            window.location.href = '/events/';
        }

    };
    const handleOpenEnrollmentClick = async (values) => {
        try {
            ajaxLoad();
            await updateEventStatus(
                id,
                EventStatus.OpenEnrollment,
                values.enrollmentCap,
                values.waitlistCap,
                values.waitlistResponseTime,
                values.waitlistResponseDuration
            );
            setProgramEvent((prev) => ({ ...prev, status: EventStatus.OpenEnrollment, enrollmentCap: values.enrollmentCap, waitlistCap: values.waitlistCap }));
            setCurrentStep(EventStatus.OpenEnrollment);
            toast.success("Success! Your event is open for enrollment.");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };

    if (loading) {

        return <LoadingIndicator loading={loading} />;
    }

    if (programEvent == null) {
        return null;
    }
    //console.log(readOnly);
    return (
        <div className={classes.event}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" justifyContent="left">
                    <EventMeta programEvent={programEvent} />
                </Box>
                <Box display="flex" justifyContent="left">
                    <Button className="action-button" disabled={ readOnly } variant="contained" color="primary" onClick={handleTemplateSubmit}>
                       Save as Template
                    </Button>
                    <Button className="action-button" disabled={readOnly} variant="contained" color="primary" onClick={messagingClick }>
                        Message
                    </Button>
                    <Button
                        className="action-button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/events/${isActive ? "active" : "inactive"}/event/${id}/documents`)}
                    >
                        Documents
                    </Button>
                    
                    <Button
                        className="action-button"
                        variant="contained"
                        color="primary"
                        disabled={readOnly} 
                        onClick={ handleOpenDelete }
                    >
                        Delete / Cancel
                    </Button>
                </Box>
                
            </Box>
            <EventStepper eventStatus={programEvent.status} activeStep={currentStep} onChangeActiveStep={handleChangeStep} />
            {currentStep === EventStatus.Planning && (
                <EventPlanningStep
                    programEventId={programEvent.id}
                    isEventDetailsMissing={isEventDetailsMissing}
                    isEligibleCriteriaMissing={isEligibleCriteriaMissing}
                    onNextStep={handleRecruitClick}
                    isActive={isActive}
                    status={programEvent.status}
                    disabled={readOnly} 
                    setIsEligibleCriteriaMissing={setIsEligibleCriteriaMissing}
                    isComplete={isComplete }
                />
            )}
            {currentStep === EventStatus.Recruitment && (
                <EventRecruitmentStep
                    isDescriptionMissing={!programEvent?.descriptionEnglish || !programEvent?.descriptionSpanish}
                    isInvitationMissing={!programEvent?.invitation}
                    isPermissionSlipLanguageMissing={!programEvent?.permissionSlipLanguageEnglish || !programEvent?.permissionSlipLanguageSpanish}
                    onNextStep={handleOpenEnrollmentClick}
                    programEvent={programEvent}
                    disabled={readOnly} 
                    setProgramEvent={setProgramEvent}
                />
            )}
            {currentStep === EventStatus.OpenEnrollment && <EventOpenEnrollmentStep programEventId={programEvent.id} disabled={readOnly} programName={programEvent.name} />}
            {currentStep === EventStatus.Review && <EventReviewStep programEventId={programEvent.id} disabled={readOnly} programName={programEvent.name} />}
            <div className="eventAuditTrail">

            
            </div>
            <Modal isOpen={messaging} ariaHideApp={false}>
                <div className="MessagingVisible" >
                    <h3>Messaging</h3>
                    <Form onSubmit={handleMessagingSubmit}>
                        {({ handleSubmit, form, values, dirty, errors }) => {

                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.root}>
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <div className={classes.formHeader}>

                                        <EligibilityCriteriaSection title="Message">
                                            <Box display="flex" flexDirection="column">
                                                <Box mt={2}>
                                                    <Field
                                                        component={FinalFormTextField}
                                                        name="messageEnglish"
                                                        label="English"
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        multiline
                                                        maxLength={5000}
                                                        minRows={4}
                                                        maxRows={4}
                                                        required
                                                        validate={required}
                                                    />
                                                </Box>
                                                <Box marginY={1}>
                                                    <Field
                                                        component={FinalFormTextField}
                                                        name="messageSpanish"
                                                        label="Spanish"
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        multiline
                                                        maxLength={5000}
                                                        minRows={4}
                                                        maxRows={4}
                                                        required
                                                        validate={required}
                                                    />
                                                </Box>
                                            </Box>
                                        </EligibilityCriteriaSection>

                                        <EligibilityCriteriaSection title="Messaging Who">
                                            <div className="messagingLeft">
                                                <span className={ messagingWarningClass } >At least one recipient list must be selected.</span><br/>
                                            <Field component={FinalFormCheckbox} name="invited" label="All Invited" />
                                            <Field component={FinalFormCheckbox} name="waitlist" label="Wait List" />
                                            <Field component={FinalFormCheckbox} name="enrolled" label="Enrolled" />
                                                <Field component={FinalFormCheckbox} name="attended" label="Attended" />
                                            </div>
                                            <div className="MessagingButtons">
                                                <div className="messagingCancelSend">
                                                <LoadingButton
                                                    wrapperClassName={classes.saveButtonContainer}
                                                    className={classes.saveButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Send
                                                </LoadingButton>
                                                &nbsp;&nbsp;
                                                <LoadingButton
                                                    wrapperClassName={classes.saveButtonContainer}
                                                    className={classes.saveButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={cancelMessaging}
                                                >
                                                    Cancel
                                                    </LoadingButton>
                                                </div>
                                                <br />
                                                <LoadingButton
                                                    wrapperClassName={classes.saveButtonContainer}
                                                    className={classes.saveButton}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={inviteNewlyEleg}
                                                >
                                                    Send Invite To {newEleg} Newly Elegible 
                                                </LoadingButton>

                                            </div>
                                            
                                            
                                        </EligibilityCriteriaSection>
                                    </div>
                                </form>
                            );
                        }}
                    </Form>
                </div>
            </Modal>
            <Modal isOpen={deleting} ariaHideApp={false}>
                
                <Form onSubmit={handleDeleteSubmit}>
                    {({ handleSubmit, form, values, dirty, errors }) => {
                        if (programEvent.status < 5) {
                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.root}>
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <Grid container spacing={2}> 
                                        <Grid item xs={12} sm={12}>
                                    <div className="formHeader">
                                        <h2>Cancel Event: {programEvent.name}</h2>

                                            </div>
                                        </Grid><Grid item xs={12} sm={12}>
                                        <span>Canceling an event moves it to the "Inactive" screen. Deleting the event removes it from all views. It will still be in the database and retrievable by the programmers.</span><br />
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <span> In order to cancel or delete the event you must type the event name in the following text box.  Type: </span><br /><br />
                                            <span>{programEvent.name}</span><br /><br />
                                        <div className="eventInputs">
                                            {/* Event Name and Delete Event */}
                                            <Field
                                                component={FinalFormTextField}
                                                name="typedName"
                                                label="Event Name"
                                                variant="outlined"
                                                margin="dense"
                                                maxLength={5000}
                                                required
                                                validate={required}
                                            />
                                            <br />
                                            <span className={deleteWarning}>What you typed does not match {programEvent.name}<br /></span>
                                            <Field component={FinalFormCheckbox} name="delete" label="Also Delete Event" />
                                            <br />
                                            
                                        </div> </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="eventDates">
                                                {programEvent.eventDates.filter(field => moment(field.startTime).isAfter(moment())).length > 0 ? <> <h3>Cancel Future Date</h3> <br/> <span>Leaving all blank will cancel entire event.</span></> : null}
                                            
                                                {programEvent.eventDates.filter(field => moment(field.startTime).isAfter(moment())).map((field, index) => (
                                                    <Field
                                                        key={field.id} // Always use a key when mapping over an array for rendering
                                                        component={FinalFormCheckbox}
                                                        name={`eventDate-${field.id}`}
                                                        label={moment(field.startTime).format('LLLL')} // Format the date as needed
                                                    />
                                                ))}

                                            </div>
                                        </Grid><Grid item xs={12} sm={12}>
                                        </Grid><Grid item xs={12} sm={8}>
                                        </Grid><Grid item xs={12} sm={4}>
                                        <div className="cancelDeleteButtons">
                                            <LoadingButton
                                                wrapperClassName={classes.saveButtonContainer}
                                                className={classes.saveButton}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={delDisabled}
                                            >
                                                Cancel Event
                                            </LoadingButton>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleCancelDelete}
                                                disabled={delDisabled}
                                            >
                                                Return to Event
                                            </Button>
                                        </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        } else {
                            return (
                                <form onSubmit={handleSubmit} noValidate className={classes.root}>
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <div className={classes.formHeader}>
                                        <h2>Cancel Event: {programEvent.name}</h2>

                                        
                                        <span>Deleting the event removes it from all views. It will still be in the database and retrievable by the programmers.  Reactivating the event returns it to the Planning stage.</span><br /><span> In order to cancel or delete the event you must type the event name in the following text box.  Type: </span><br /><br />
                                        <span>{programEvent.name}</span><br /><br />
                                        <Field
                                            component={FinalFormTextField}
                                            name="typedName"
                                            label="Event Name"
                                            variant="outlined"
                                            margin="dense"
                                            maxLength={5000}
                                            required
                                            validate={required}
                                        /><br />
                                        <span className={deleteWarning}>What you typed does not match  {programEvent.name}<br /></span>
                                        <Field component={FinalFormCheckbox} name="delete" label="Also Delete Event" /><br />
                                        
                                        <div className="cancelDeleteButtons">
                                            <LoadingButton
                                                wrapperClassName={classes.saveButtonContainer}
                                                className={classes.saveButton}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={delDisabled }
                                            >
                                                Delete Event
                                            </LoadingButton>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleReactivate}
                                            >
                                                Reactivate Event
                                            </Button>
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleCancelDelete}
                                                disabled={delDisabled}
                                            >
                                                Return to Event
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            );
                        }
                       
                    }}
                </Form>


            </Modal>

            <div className="eventAuditInfo">
                <table className="eventAuditTable"><tr>
                    <th colspan="2" className="eventAuditTableHeader" >Created By</th><th colspan="2" className="eventAuditTableHeader" >Last Modified By</th>
                </tr>
                    <tr>
                        <td>{createUser}</td><td>{createTime}</td><td>{modifiedUser}</td><td>{modifiedTime}</td>
                    </tr>
                </table>
               
            </div>

        </div>

    );
};

export default Event;
