import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { SEARCH_TEXT } from "../../../support/constants";
import './index.css';
localStorage.setItem(SEARCH_TEXT, '');
class Search extends React.Component {
    state = {
        searchText: ''
    };
    
    onSearch = () => {
        localStorage.setItem(SEARCH_TEXT, this.state.searchText);
        this.props.onClick(this.state.searchText);
    };
    onClear = () => {
        this.setState({ searchText: '' });
        this.props.onClick('');
        localStorage.setItem(SEARCH_TEXT, '');
    };
    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.onSearch();
        }
    };
    render() {
        return (
            <div className="search-container">
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={e => {
                        this.setState({ searchText: e.target.value });
                    }}
                    onKeyPress={this.onKeyPress}
                />
                <button className="search-button" onClick={this.onSearch}>
                    <FontAwesome name="search" className="complete" color="white" />
                </button>
                <button className="search-button" onClick={this.onClear}>
                    <FontAwesome name="times" className="complete" color="white" />
                </button>
            </div>
        );
    }
}

Search.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default Search;
