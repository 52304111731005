import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";

import ReactTable from "react-table";
import moment from "moment";
import enumHelper from "../../../support/logic/enumHelper";
import { eventType } from "../../enums.js";
import EditIcon from '@mui/icons-material/Edit';


class Notes extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {



        };
    }



    componentDidMount() {
       
    }
    componentDidUpdate(prevProps, prevState) {
        


    }

    render() {
        let columns = [

            {
                Header: "",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.props.openNote(row.original)} /></div>
                ),

            }, {
                Header: "Title",
                accessor: "title",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.title}</div>
                ),

            },
            {
                Header: "Description",
                accessor: "description",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.description}</div>
                ),

            },
            {
                Header: "Date/Time",
                accessor: "startTime",
                width: 100,
                Cell: (row) => {
                    const lCont = row.value !== null ? moment(row.value).format("M/D/YY h:mm a") : "";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{lCont}</div>;
                },

            }
            ,
            {
                Header: "Created By",
                accessor: "creator",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.creator}</div>
                ),

            }

        ];


        return <div>
            <ReactTable
                data={this.props.data}
                minRows={0}
                columns={columns}
                defaultPageSize={50}
                className="-striped -highlight tableWrapper"
            />
        </div>;
    }
    
}

export default Notes;