export const Language = {
    English: 10,
    Spanish: 20,
    Arabic: 30,
    Armenian: 40,
    Cambodian: 50,
    CapeVerdean: 60,
    Chinese: 70,
    French: 80,
    Greek: 90,
    HaitianCreole: 100,
    Hmong: 110,
    Italian: 120,
    Korean: 130,
    Laotian: 140,
    Mandingo: 150,
    Polish: 160,
    Portuguese: 170,
    Russian: 180,
    Vietnamese: 190,
    Other: 200,
    _displayNames: {
        60: "Cape Verdean",
        100: "Haitian Creole",
    },
};

export const GroupType = {
    Unspecified: 0,
    ProofOfResidency: 1,
    ReducedLunch: 2,
    BirthCertificate: 3,
    PersonalStatement: 4,
    ReportCard: 5,
    Contracts: 6,
    FERPAForm: 7,
    InternalEventDocumentation: 8,
    ExternalEventDocumentation: 9,
    PermissionSlip: 10,
    StudentPhoto: 11,
    _displayNames: {
        1: "Proof of Residency",
        2: "Reduced Lunch",
        3: "Proof of Age",
        4: "Personal Statement",
        5: "Report Card",
        7: "FERPA Form",
        10: "Permission Slip",
        11: "Student Photo",
    },
};




export const ApplicationStatus = {
    NotSubmitted: 0,
    Submitted: 1,
    Complete: 2,
    Accepted: 3,
    Rejected: 4,
    LunchPending: 5,
    ApplicantRejected: 6,
    VirtualOrientation: 7,
    Resubmit: 8,
    _displayNames: {
        0: "Updated",
        3: "Crusader",
        5: "Lunch Pending",
        6: "Rejected Applicant",
        7: "Pending Family Orientation",
    },
};

export const Race = {
    White: 1,
    Black: 2,
    Asian: 4,
    Indian: 8,
    Hawaiian: 16,
    TwoOrMoreRaces: 32,
    _localizationIds: {
        1: "student.race.white",
        2: "student.race.black",
        4: "student.race.asian",
        8: "student.race.indian",
        16: "student.race.hawaiian",
        32: "student.race.twoormore",
    },
};

export const CitizenshipType = {
    BirthCertificateUSA: 0,
    BirthCertificateNonUSA: 1,
    GreenCard: 2,
    I94: 3,
    NoAnswer: 4, //legacy
    PassportUSA: 5,
    PassportNonUSA: 6,
    _localizationIds: {
        0: "student.citizenship.uscitizen",
        1: "student.citizenship.nonuscitizen",
        2: "student.citizenship.greencard",
        3: "student.citizenship.i94",
        5: "student.citizenship.passportusa",
        6: "student.citizenship.passportnonusa",
    },
};

export const Gender = {
    Male: 0,
    Female: 1,
    Other: 2,
    _displayNames: {
        0: "Male",
        1: "Female",
        2: "Other",
    },
};

export const AdmissionsTabs = {
    Student: "Admissions.Student",
    Parent: "Admissions.Parent",
    Household: "Admissions.Household",
    CommonDocs: "Admissions.CommonDocs",
    OrientationVideo: "Admissions.VirtualOrientation",
    Contracts: "Admissions.Contracts",
};
