import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import StaffLayout from "../common/staffLayout";
import QuotaStats from "./components/stats";
import { getCurrent, update, updateSchool, getSchoolQuotas, updateGrade } from "../../../api/applicationQuota";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";

import { SchoolList, GradeList } from "./components/schoolList";
import CrusaderList from "./components/crusaderList";

import "./index.css";

class Quotas extends React.Component {
    state = {
        overallQuotaValue: "",
        schoolQuotas: [],
        gradeQuotas: [],
        selectedSchool: null,
        selectedGrade: null,
        crusaderData: null,
    };

    static permission = "Applications.Quotas";

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedCycle !== this.props.selectedCycle) {
            this.setState({ selectedSchool: null, selectedGrade: null });
            this.loadQuotas();
            this.loadSchoolQuotas(this.props.searchText);
        }

        if (prevProps.searchText !== this.props.searchText) {
            this.setState({ selectedSchool: null, selectedGrade: null }, () => {
                this.loadSchoolQuotas(this.props.searchText);
            });
        }
    }

    async loadData() {
        await this.loadQuotas();
        await this.loadSchoolQuotas("");
    }

    async loadSchoolQuotas(searchText) {
        if (this.props.selectedCycle === "") {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const crusaderData = (await getSchoolQuotas(this.props.selectedCycle, this.state.selectedSchool, searchText, this.state.selectedGrade)).data.result;
            this.setState({ crusaderData });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    async loadQuotas() {
        if (this.props.selectedCycle === "") {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const data = (await getCurrent(this.props.selectedCycle)).data.result;
            const schoolQuotas = data.schools.map((item) => ({
                id: item.schoolId,
                count: item.count,
                crusaders: item.crusaders,
            }));

            this.setState({ schoolQuotas, gradeQuotas: data.grades, overallQuotaValue: data.count !== null ? data.count.toString() : "" });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    onOverallQuotaChange = (event) => {
        if (isNaN(event.target.value)) {
            return;
        }

        if (parseInt(event.target.value, 10) < 0) {
            return;
        }

        this.setState({ overallQuotaValue: event.target.value });
    };

    onOverallQuotaSave = async () => {
        if (this.state.overallQuotaValue === "") {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            await update(this.props.selectedCycle, this.state.overallQuotaValue);
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    onSchoolQuotaChange = (event, schoolId) => {
        if (isNaN(event.target.value)) {
            return;
        }

        if (parseInt(event.target.value, 10) < 0) {
            return;
        }

        if (event.target.value.length > 5) {
            return;
        }

        const schoolQuotas = this.state.schoolQuotas.slice();
        const index = schoolQuotas.findIndex((item) => item.id === schoolId);
        schoolQuotas[index] = { ...schoolQuotas[index], count: event.target.value };

        this.setState({ schoolQuotas });
    };

    handleGradeQuotaChange = (event, grade) => {
        if (isNaN(event.target.value)) {
            return;
        }

        if (parseInt(event.target.value, 10) < 0) {
            return;
        }

        if (event.target.value.length > 5) {
            return;
        }

        const gradeQuotas = this.state.gradeQuotas.slice();
        const index = gradeQuotas.findIndex((item) => item.grade === grade);
        gradeQuotas[index] = { ...gradeQuotas[index], count: event.target.value };

        this.setState({ gradeQuotas });
    };

    onSchoolQuotaBlur = async (event, schoolId) => {
        const school = this.state.schoolQuotas.find((item) => item.id === schoolId);

        if (school.count === null || school.count === "" || isNaN(school.count)) {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            await updateSchool(this.props.selectedCycle, schoolId, parseInt(school.count, 10));
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    handleGradeQuotaBlur = async (event, grade) => {
        const g = this.state.gradeQuotas.find((item) => item.grade === grade);

        if (g.count === null || g.count === "" || isNaN(g.count)) {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            await updateGrade(this.props.selectedCycle, grade, parseInt(g.count, 10));
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    getSchoolList() {
        return this.props.schools.reduce((acc, item) => {
            var quota = this.state.schoolQuotas.find((school) => school.id === item.id);

            acc.push({
                id: item.id,
                name: item.name,
                count: quota && quota.count !== null ? quota.count : "",
                crusaders: quota ? quota.crusaders : 0,
            });

            return acc;
        }, []);
    }

    getGradeList() {
        const grades = [
            { grade: 5, crusaders: 0, count: "" },
            { grade: 6, crusaders: 0, count: "" },
            { grade: 7, crusaders: 0, count: "" },
            { grade: 8, crusaders: 0, count: "" },
        ];

        return grades.reduce((acc, item) => {
            var quota = this.state.gradeQuotas.find((g) => g.grade === item.grade);

            acc.push({
                grade: item.grade,
                count: quota && quota.count !== null ? quota.count : "",
                crusaders: quota ? quota.crusaders : 0,
            });

            return acc;
        }, []);
    }

    handleGradeClick = (event, grade) => {
        this.setState({ selectedGrade: grade, selectedSchool: null }, () => this.loadSchoolQuotas());
    };

    onSchoolClick = (event, schoolId) => {
        this.setState({ selectedSchool: schoolId, selectedGrade: null }, () => this.loadSchoolQuotas());
    };

    onViewAllClick = () => {
        this.setState({ selectedSchool: null, selectedGrade: null }, () => this.loadSchoolQuotas());
    };

    getSelectedSchoolQuotaData() {
        if (this.state.selectedSchool === null) {
            return null;
        }

        return this.state.schoolQuotas.find((item) => item.id === this.state.selectedSchool) || { crusaders: 0 };
    }

    getSelectedGradeQuotaData() {
        if (this.state.selectedGrade === null) {
            return null;
        }

        return this.state.gradeQuotas.find((item) => item.grade === this.state.selectedGrade) || { crusaders: 0 };
    }

    getSelectedSchoolName() {
        if (this.state.selectedSchool === null) {
            return null;
        }

        return this.props.schools.find((item) => item.id === this.state.selectedSchool).name;
    }

    getTrProps = (state, rowInfo, column, instance) => {
        return {
            onClick: (e) => {
                this.rowClick(rowInfo.original);
            },
        };
    };

    rowClick = (row) => {
        window.previousLocation = "quotas";
        this.props.history.push(`/applications/application/${row.applicationId}/s/${row.id}`);
    };

    render() {
        const { overallQuotaValue, selectedSchool, selectedGrade, crusaderData } = this.state;
        const { selectedCycle } = this.props;

        // //console.log(this.state.gradeQuotas, this.state.selectedGrade);

        return (
            <StaffLayout>
                <div className="quota-layout">
                    <div className="quota-layout-left">
                        <QuotaStats
                            selectedRecruitmentCycle={selectedCycle}
                            quotaValue={overallQuotaValue}
                            onQuotaChange={this.onOverallQuotaChange}
                            onQuotaBlur={this.onOverallQuotaSave}
                        />
                        <SchoolList
                            schools={this.getSchoolList()}
                            onSchoolClick={this.onSchoolClick}
                            onQuotaChange={this.onSchoolQuotaChange}
                            onQuotaBlur={this.onSchoolQuotaBlur}
                            selectedSchool={selectedSchool}
                            onViewAllClick={this.onViewAllClick}
                        />
                        <GradeList
                            grades={this.getGradeList()}
                            onGradeClick={this.handleGradeClick}
                            onQuotaChange={this.handleGradeQuotaChange}
                            onQuotaBlur={this.handleGradeQuotaBlur}
                            selectedGrade={selectedGrade}
                            onViewAllClick={this.onViewAllClick}
                        />
                    </div>
                    <div className="quota-layout-right">
                        <CrusaderList
                            getTrProps={this.getTrProps}
                            selectedSchool={selectedSchool}
                            selectedSchoolQuotaData={this.getSelectedSchoolQuotaData()}
                            selectedSchoolName={this.getSelectedSchoolName()}
                            selectedGradeQuotaData={this.getSelectedGradeQuotaData()}
                            selectedGrade={selectedGrade}
                            crusaderData={crusaderData}
                            overallQuotaValue={overallQuotaValue}
                        />
                    </div>
                </div>
            </StaffLayout>
        );
    }
}

const mapStateToProps = (state) => ({
    schools: state.staffMiddleSchools,
});
Quotas = withAjaxStatus(Quotas);
Quotas = connect(mapStateToProps)(Quotas);
Quotas = withRouter(Quotas);

export default Quotas;
