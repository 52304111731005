import React from "react";
import { FormattedMessage } from "react-intl";
import FontAwesome from "react-fontawesome";

import "./Footer.css";

const showContactInfo = () => {
    const hash = window.location.hash;
    return !(hash.indexOf("/scholarship/") !== -1 || hash.indexOf("/stafflogin") !== -1 || hash === "#/");
};
const Footer = () => {
    return (
        <div className="footer no-print">
            <div className="footer-group" style={{ marginRight: "10px" }}>
                <FormattedMessage id="app.question" defaultMessage="Question" children={(formattedMessage) => <span>{formattedMessage}</span>} />
            </div>
            <div className="footer-group">
                <FormattedMessage id="footer.address" defaultMessage="address" children={(formattedMessage) => <span>{formattedMessage}</span>} />
                {showContactInfo() ? (
                    <FormattedMessage id="footer.contact" defaultMessage="contact" children={(formattedMessage) => <span>{formattedMessage}</span>} />
                ) : null}
            </div>
            <div style={{ flex: 1 }} />
            <div className="footer-group">
                <div className="footer-copyright">
                    <FontAwesome name="copyright" size="lg" style={{ paddingTop: "3px", paddingRight: "3px" }} />
                    <FormattedMessage id="app.CC" defaultMessage="CC" children={(formattedMessage) => <span>{formattedMessage}</span>} />
                </div>
                <FormattedMessage id="footer.allrights" defaultMessage="All Rights" children={(formattedMessage) => <span>{formattedMessage}</span>} />
            </div>
        </div>
    );
};

export default Footer;
