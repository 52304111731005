import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Route, Redirect, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import PropTypes from "prop-types";
import EventsNav from "./nav";
import { createCCMUITheme } from "../support/muiTheme";
import EventsList from "./list";
import Event from "./event";
import Settings from "./settings";
import EventDetails from "./eventDetails";
import EligibleParticipantList from "./eligibleParticipantList";
import Documents from "./documents";
import AddRemoveParticipants from "./eligibleParticipantList/addRemoveParticipants";
import AddRemoveAttendees from "./review/addRemoveAttendees";
import EligibilityCriteria from "./eligibleParticipantList/eligibilityCriteria";
import EventDateEnrollmentList from "./enrollmentList/eventDateEnrollmentList";
import EnrollmentList from "./enrollmentList/enrollmentList";
import EventDateWaitlist from "./waitlist/eventDateWaitlist";
import Waitlist from "./waitlist/waitlist";
import EventAttendanceList from "./review/eventAttendanceList";
import AttendanceList from "./review/attendanceList";

const useStyles = makeStyles((theme) => ({
    events: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 1,
        marginLeft: 10,
        marginRight: 20,
    },
}));

const Events = ({ topUser }) => {
    //console.log(topUser);
    const classes = useStyles();
    const [eligibleCriteriaMissing, setEligibleCriteriaMissing] = useState(true);
    const [eventDetailsMissing, setEventDetailsMissing] = useState(true);
    return (
        <ThemeProvider theme={createCCMUITheme()}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="en">
                <div className={classes.events}>
                    <CssBaseline />
                    <EventsNav />
                    <Switch>
                        <Redirect exact from="/events" to="/events/active" />

                        <Route path="/events/active" exact render={() => <EventsList key="active" isActive isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive" exact render={() => <EventsList key="inactive" isActive={false} isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete" exact render={() => <EventsList key="complete" isActive isComplete={true} topUser={topUser} />} />


                        <Route path="/events/active/event/:id" exact render={() => <Event isActive isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id" exact render={() => <Event isActive={false} isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id" exact render={() => <Event isActive={true} isComplete={true} topUser={topUser} />} />


                        <Route path="/events/settings" render={() => <Settings topUser={topUser} />} />

                        <Route path="/events/active/event/:id/details" exact render={() => <EventDetails isActive eligibleCriteriaMissing setEligibleCriteriaMissing isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/details" exact render={() => <EventDetails isActive={false} eligibleCriteriaMissing setEligibleCriteriaMissing isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/details" exact render={() => <EventDetails isActive eligibleCriteriaMissing setEligibleCriteriaMissing isComplete={true} topUser={topUser} />} />

                        <Route path="/events/active/event/:id/eligible-participant-list" exact render={() => <EligibleParticipantList isActive isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/eligible-participant-list" exact render={() => <EligibleParticipantList isActive={false} isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/eligible-participant-list" exact render={() => <EligibleParticipantList isActive isComplete={true} topUser={topUser} />} />

                        <Route path="/events/active/event/:id/eligibility-criteria" exact render={() => <EligibilityCriteria setEligibleCriteriaMissing={setEligibleCriteriaMissing} isActive isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/eligibility-criteria" exact render={() => <EligibilityCriteria setEligibleCriteriaMissing={setEligibleCriteriaMissing} isActive={false} isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/eligibility-criteria" exact render={() => <EligibilityCriteria setEligibleCriteriaMissing={setEligibleCriteriaMissing} isActive isComplete={true} />} topUser={topUser} />

                        <Route path="/events/active/event/:id/documents" exact render={() => <Documents isActive topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/documents" exact render={() => <Documents isActive={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/documents" exact render={() => <Documents isActive topUser={topUser} />} />

                        <Route path="/events/active/event/:id/add-remove-participants" exact render={() => <AddRemoveParticipants isActive topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/add-remove-participants" exact render={() => <AddRemoveParticipants isActive={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/add-remove-participants" exact render={() => <AddRemoveParticipants isActive topUser={topUser} />} />


                        <Route path="/events/active/event/:id/add-remove-attendees" exact render={() => <AddRemoveAttendees isActive isComplete={false} topUser={topUser} />} />
                        <Route path="/events/inactive/event/:id/add-remove-attendees" exact render={() => <AddRemoveAttendees isActive={false} isComplete={false} topUser={topUser} />} />
                        <Route path="/events/complete/event/:id/add-remove-attendees" exact render={() => <AddRemoveAttendees isActive isComplete={true} topUser={topUser} />} />


                        <Route path="/events/active/event/:id/event-date-enrollment-list" exact render={() => <EventDateEnrollmentList topUser={topUser} />} />

                        <Route path="/events/active/event/:id/enrollment-list" exact render={() => <EnrollmentList topUser={topUser} />} />

                        <Route path="/events/active/event/:id/event-date-waitlist" exact render={() => <EventDateWaitlist topUser={topUser} />} />

                        <Route path="/events/active/event/:id/waitlist" exact render={() => <Waitlist topUser={topUser} />} />

                        <Route path="/events/active/event/:id/event-attendance-list" exact render={(props) => <EventAttendanceList match={props.match} topUser={topUser} />} />

                        <Route path="/events/active/event/:id/attendance-list" exact render={(props) => <AttendanceList match={props.match} topUser={topUser} />} />


                        <Route path="/events/complete/event/:id/event-date-enrollment-list" exact render={() => <EventDateEnrollmentList topUser={topUser} />} />

                        <Route path="/events/complete/event/:id/enrollment-list" exact render={() => <EnrollmentList topUser={topUser} />} />

                        <Route path="/events/complete/event/:id/event-date-waitlist" exact render={() => <EventDateWaitlist topUser={topUser} />} />

                        <Route path="/events/complete/event/:id/waitlist" exact render={() => <Waitlist topUser={topUser} />} />

                        <Route path="/events/complete/event/:id/event-attendance-list" exact render={(props) => <EventAttendanceList match={props.match} topUser={topUser} />} />

                        <Route path="/events/complete/event/:id/attendance-list" exact render={(props) => <AttendanceList match={props.match} topUser={topUser} />}  />
                    
                    </Switch>
                </div>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};
Events.propTypes = {
    topUser: PropTypes.object,  // Define prop types
};
export default Events;
