import React from "react";
import { connect } from "react-redux";
import { updateVideoSettingsAction, updateAdmissionSettingsAction } from "../../../redux/codeTables";

const withAdmissionSettings = (WrappedComponent) => {
    class WithAdmissionSettings extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = (state) => ({
        admissionSettings: state.admissionSettings,
    });

    const mapDispatchToProps = (dispatch) => ({
        updateVideoSettings: (videoSettings) => dispatch(updateVideoSettingsAction(videoSettings)),
        updateAdmissionSettings: (admissionSettings) => dispatch(updateAdmissionSettingsAction(admissionSettings)),
    });

    WithAdmissionSettings = connect(mapStateToProps, mapDispatchToProps)(WithAdmissionSettings);

    return WithAdmissionSettings;
};

export default withAdmissionSettings;
