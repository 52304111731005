import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { KeyboardTimePicker } from "@material-ui/pickers";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  timePicker: {
    "& .MuiOutlinedInput-input": {
      paddingTop: theme.spacing(1.05),
      paddingBottom: theme.spacing(1.05),
    },
    "& .MuiPickersDay-day .MuiIconButton-label": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiPickersDay-dayDisabled .MuiIconButton-label": {
      color: theme.palette.lightGray.main,
    },
  },
  timePickerAdornedEnd: {
    paddingRight: theme.spacing(0.5),
  },
  timePickerAdornment: {
    padding: theme.spacing(0.5),
  },
}));

const FinalFormKeyboardTimePicker = ({
  label,
  input,
  meta,
  formControlClassName,
  labelClassName,
  formControlProps,
  timePickerProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <FormControl className={clsx(classes.formControl, formControlClassName)} fullWidth {...formControlProps}>
      {label && <FormLabel className={clsx(classes.label, labelClassName)}>{label}</FormLabel>}
      <KeyboardTimePicker
        {...input}
        className={classes.timePicker}
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="hh:mm a"
        orientation="landscape"
        PopoverProps={{ className: classes.timePicker }}
        InputProps={{ classes: { adornedEnd: classes.timePickerAdornedEnd } }}
        KeyboardButtonProps={{ className: classes.timePickerAdornment }}
        keyboardIcon={<ScheduleIcon />}
        minutesStep={15}
        {...timePickerProps}
      />
      {meta.error && meta.touched && (
        <FormHelperText style={{ color: theme.palette.error.main }}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FinalFormKeyboardTimePicker;
