import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import './search.css';

const Search = ({ searchText, onSearchTextChange, onSearch, onViewAll }) => {
    return (
        <div className="scholarship-reports-summary-search">
            <button onClick={onViewAll}>View All</button>

            <div className="scholarship-reports-summary-row">
                <input placeholder="Institution Search" value={searchText} onChange={onSearchTextChange} type="text" />
                <button className="search-button" onClick={onSearch}>
                    <FontAwesome name="search" className="complete" color="white" />
                </button>
            </div>
        </div>
    );
};

Search.propTypes = {
    searchText: PropTypes.string,
    onSearchTextChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onViewAll: PropTypes.func.isRequired
};
export default Search;
