import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getAllDistricts } from "../api/programEvents";

const useDistrict = () => {
    const [districtList, setDistrictList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDistrictListAsync = useCallback(async (tokenSource) => {
        try {
            const response = await getAllDistricts(tokenSource.token);
            setDistrictList(response.data.result);
        } catch (ex) {
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        getDistrictListAsync(tokenSource);
        return () => {
            tokenSource.cancel();
        };
    }, [getDistrictListAsync]);

    return {
        districtList,
        loading,
    };
};

export default useDistrict;
