import React from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "redux-form";
import moment from "moment";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { detectIE } from "../browserSupport";
import "./index.css";
import { ApplicationStatusContext } from "../context";
import { LOCALE_EN, LOCALE_ES_MX } from "../constants";

const version = detectIE();
const isIE = !(version === false || version >= 12);

/*
    TODO: after the application app is complete, the
    ApplicationStatusContext form fields may need to be moved
    to another file.  Keep context form elements seperate.
*/
export const userName = (value) => {
    const pattern = /^[a-z0-9]+$/i;

    if (!pattern.test(value)) {
        return (
            <FormattedMessage
                id="InvalidUserName"
                defaultMessage="[invalid user name]"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }

    if (!value || value.length < 6) {
        return (
            <FormattedMessage
                id="InvalidUserNameLength"
                defaultMessage="[InvalidUserNameLength]"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }

    return undefined;
};

export const required = (value) => {
    if (value || typeof value === "number") {
        return undefined;
    } else {
        return (
            <FormattedMessage
                id="ThisFieldIsRequired"
                defaultMessage="required"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }
};

export const email = (value) => {
    // eslint-disable-next-line
    const pattern =
        // eslint-disable-next-line
        /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (value === "admin") return undefined;

    return !value || (value && typeof value === "string" && pattern.test(value)) ? undefined : (
        <FormattedMessage
            id="login.invalidEmail"
            defaultMessage="invalid email"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const phone = (value) => {
    const pattern = /^\(?([0-9]{3})\)? [-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

    return !value || (value && typeof value === "string" && pattern.test(value)) ? undefined : (
        <FormattedMessage
            id="error.invalidPhone"
            defaultMessage="[invalid phone number format]"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const isDate = (value) => {
    if (!value) {
        return undefined;
    }
    const dateValue = value.format ? value.format("MM/DD/YYYY") : value;

    return /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|(20)([01234])(\d{1})|([8901])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|(20)([01234])(\d{1})|([8901])(\d{1})))$/.test(
        dateValue
    ) ? undefined : (
        <FormattedMessage
            id="error.invalidDate"
            defaultMessage="[error.invalidDate]"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const requiredDateParts = (value) => {
    if (!value || !value.year || value.month === undefined || value.month === "" || !value.day) {
        return (
            <FormattedMessage
                id="ThisFieldIsRequired"
                defaultMessage="required"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }
};

export const zip = (value) => {
    const pattern = /^[0-9]{5}(?:[-\s]\d{4})?$/;

    return !value || (value && typeof value === "string" && pattern.test(value)) ? undefined : (
        <FormattedMessage
            id="error.invalidZip"
            defaultMessage="[invalid zip code format]"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const password = (value) => {
    if (!value) return undefined;
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
    return value && typeof value === "string" && pattern.test(value) ? undefined : (
        <FormattedMessage
            id="login.invalidPassword"
            defaultMessage="invalid password"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const alphaNumeric = (value) => {
    const pattern = /^[a-zA-Z0-9]*$/;

    return !value || (value && typeof value === "string" && pattern.test(value)) ? undefined : (
        // <FormattedMessage
        //     id="error.invalidEntry"
        //     defaultMessage="[invalid entry, alphanumeric only]"
        //     children={(formattedMessage) => (
        <span style={{ color: "red" }} title="Alphanumeric only">
            Alphanumeric only
        </span>
        //     )}
        // />
    );
};

export const passwordMatch = (data, props) => {
    const errors = {};
    if (data.password && data.confirm && data.password !== data.confirm) {
        let msg = "Passwords do not match";
        if (props.intl) {
            msg = props.intl.formatMessage({ id: "login.PasswordNotMatch" });
        }
        errors.confirm = msg;
    }
    return errors;
};

export const renderDateField = ({
    input,
    label,
    skipContextCheck,
    disabled,
    className,
    closeOnSelect,
    outerClassName,
    meta: { touched, error },
    viewDate,
    ...otherProps
}) => {
    let value = input.value;
    const defaultDate = moment.utc(new Date());
    if (value && typeof value === "string") {
        value = moment.utc(value).format("M/D/YYYY");
    }
    return (
        <div className={className || outerClassName}>
            <span className="form-label" title={label}>
                {label}
            </span>
            <div className="form-inner-div">
                <Datetime
                    {...otherProps}
                    {...input}
                    closeOnSelect={closeOnSelect}
                    inputProps={{ readOnly: true, placeholder: "M/D/YY", disabled }}
                    placeholder={label}
                    value={value}
                    dateFormat
                    timeFormat={false}
                    utc
                    viewDate={viewDate || value || defaultDate}
                    className={className}
                />
                {touched && error && <span className="form-input-error">{error}</span>}
            </div>
        </div>
    );
};

export const dateOfBirthFromFormToString = (obj, locale) => {
    const year = parseInt(obj.year, 10);
    const month = parseInt(obj.month, 10) + 1;
    const day = parseInt(obj.day, 10);
   // if (locale === LOCALE_EN) {
        return `${month}/${day}/${year}`;
   // } else if (locale === LOCALE_ES_MX) {
   //     return `${day}/${month}/${year}`;
  //  } else {
  //      console.error("unsupported locale");
  //  }
};

export const stringToDateOfBirth = (str) => {
    const splitTime = str.split("T");
    const dateParts = splitTime[0].split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    return { year, month, day };
};

export class DropdownDatePicker extends React.Component {
    renderYears = () => {
        const { intl } = this.props;
        const selectYear = intl ? intl.formatMessage({ id: "app.selectyear", defaultMessage: "[YEAR]" }) : "YEAR";
        const yearOptions = [];
        yearOptions.push(
            <option value="" key="initial">
                {`-- ${selectYear} --`}
            </option>
        );
        const now = new Date().getFullYear();
        for (let i = now - 10; i > now - 40; i--) {
            yearOptions.push(
                <option value={i} key={i}>
                    {i.toString()}
                </option>
            );
        }

        return yearOptions;
    };

    renderDays = () => {
        const { intl } = this.props;
        const selectDay = intl ? intl.formatMessage({ id: "app.selectday", defaultMessage: "[DAY]" }) : "DAY";

        const dayOptions = [];
        dayOptions.push(
            <option value="" key="initial">
                {`-- ${selectDay} --`}
            </option>
        );

        for (let i = 1; i <= 31; i++) {
            dayOptions.push(
                <option value={i} key={i}>
                    {i.toString()}
                </option>
            );
        }

        return dayOptions;
    };

    onChangeYear = (e) => {
        const { input } = this.props;
        // const { year } = input.value;
        const newValue = { ...input.value, year: e.target.value };
        input.onChange(newValue);
    };

    onChangeMonth = (e) => {
        const { input } = this.props;
        // const { month } = input.value;
        const newValue = { ...input.value, month: e.target.value };
        input.onChange(newValue);
    };

    onChangeDay = (e) => {
        const { input } = this.props;
        // const { day } = input.value;
        const newValue = { ...input.value, day: e.target.value };
        input.onChange(newValue);
    };

    render() {
        const {
            disabled,
            meta: { touched, error },
            intl,
            input,
            skipContextCheck,
        } = this.props;

        const selectMonth = intl ? intl.formatMessage({ id: "app.selectmonth", defaultMessage: "[MONTH]" }) : "MONTH";

        const month = (
            <select className="form-select" onChange={this.onChangeMonth} value={input.value ? input.value.month : ""} disabled={disabled}>
                <option value="">{`-- ${selectMonth} --`}</option>
                <option value="0">{intl ? intl.formatMessage({ id: "app.january", defaultMessage: "[January]" }) : "January"}</option>
                <option value="1">{intl ? intl.formatMessage({ id: "app.february", defaultMessage: "[February]" }) : "February"}</option>
                <option value="2">{intl ? intl.formatMessage({ id: "app.march", defaultMessage: "[March]" }) : "March"}</option>
                <option value="3">{intl ? intl.formatMessage({ id: "app.april", defaultMessage: "[April]" }) : "April"}</option>
                <option value="4">{intl ? intl.formatMessage({ id: "app.may", defaultMessage: "[May]" }) : "May"}</option>
                <option value="5">{intl ? intl.formatMessage({ id: "app.june", defaultMessage: "[June]" }) : "June"}</option>
                <option value="6">{intl ? intl.formatMessage({ id: "app.july", defaultMessage: "[July]" }) : "July"}</option>
                <option value="7">{intl ? intl.formatMessage({ id: "app.august", defaultMessage: "[August]" }) : "August"}</option>
                <option value="8">{intl ? intl.formatMessage({ id: "app.september", defaultMessage: "[September]" }) : "September"}</option>
                <option value="9">{intl ? intl.formatMessage({ id: "app.october", defaultMessage: "[October]" }) : "October"}</option>
                <option value="10">{intl ? intl.formatMessage({ id: "app.november", defaultMessage: "[November]" }) : "November"}</option>
                <option value="11">{intl ? intl.formatMessage({ id: "app.december", defaultMessage: "[December]" }) : "December"}</option>
            </select>
        )

        const day = (
            <select className="form-select" onChange={this.onChangeDay} value={input.value ? input.value.day : ""} disabled={disabled}>
                {this.renderDays()}
            </select>
        )

        const year = (
            <select className="form-select" onChange={this.onChangeYear} value={input.value ? input.value.year : ""} disabled={disabled}>
                {this.renderYears()}
            </select>
        )
        return (
            <ApplicationStatusContext.Consumer>
                {(context) => (
                    <div className="dropdown-date-picker">
                        {context != null && context.local != null && context.locale === LOCALE_ES_MX ? (
                            <>
                                {day}
                                {month}
                                {year}
                            </>
                        ) : (
                            <>
                                {month}
                                {day}
                                {year}
                            </>
                        )}
                        
                        {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                            <span className="form-input-error" style={{ paddingTop: "10px", paddingRight: "10px" }}>
                                {error}
                            </span>
                        )}
                    </div>
                )}
            </ApplicationStatusContext.Consumer>
        );
    }
}

export const renderDOBField = ({ input, label, skipContextCheck, disabled, className, closeOnSelect, outerClassName, meta: { touched, error } }) => {
    let value = input.value;
    const defaultDate = moment.utc("20070101");
    if (value && typeof value === "string") {
        value = moment.utc(value).format("M/D/YYYY");
    }
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className || outerClassName}>
                    <span className="form-label" title={label}>
                        {label}
                    </span>
                    <div className="form-inner-div">
                        <Datetime
                            {...input}
                            closeOnSelect={closeOnSelect}
                            inputProps={{ readOnly: true, placeholder: "M/D/YYYY", disabled }}
                            placeholder={label}
                            value={value}
                            dateFormat
                            timeFormat={false}
                            utc
                            viewDate={defaultDate}
                            className={className}
                        />
                        {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                            <span className="form-input-error">{error}</span>
                        )}
                    </div>
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderField = ({ disabled, input, label, maxlength, skipContextCheck, className, type, hideLabel, meta: { touched, error }, ...otherValues }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    {!hideLabel && (
                        <span className="form-label" title={label}>
                            {label}
                        </span>
                    )}
                    <div className="form-inner-div">
                        <input className="form-input" disabled={disabled} {...input} placeholder={label} type={type} maxLength={maxlength} {...otherValues} />
                        {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                            <span className="form-input-error">{error}</span>
                        )}
                    </div>
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderTextArea = ({ disabled, input, rows, label, maxlength, skipContextCheck, className, type, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    <span className="form-label" title={label}>
                        {label}
                    </span>
                    <div className="form-inner-div">
                        <textarea className="form-input" disabled={disabled} {...input} rows={rows} placeholder={label} type={type} maxLength={maxlength} />
                        {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                            <span className="form-input-error">{error}</span>
                        )}
                    </div>
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderCheckboxField = ({ id, disabled, className, inputWrapperClassName, input, skipContextCheck, label, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    <div className="checkbox-label-wrapper">
                        <div className={inputWrapperClassName}>
                            <input type="checkbox" id={id} disabled={disabled} {...input} />
                        </div>
                        <label htmlFor={id} className="form-label checkbox-label">
                            {label}
                        </label>
                    </div>
                    {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error">{error}</span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderRadioField = ({
    input,
    lbl,
    className,
    wrapperClassName,
    skipContextCheck,
    onChange,
    disabled,
    items,
    meta: { touched, error },
    noMargin,
    id,
}) => {
    const cls = className ? `form-radio-div ${className}` : "form-radio-div";
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={wrapperClassName}>
                    <div className={cls}>
                        {items.map((item, index) => (
                            <FormattedMessage
                                key={item.value}
                                id={item.text}
                                defaultMessage={item.text}
                                children={(formattedMessage) => {
                                    return (
                                        <label
                                            key={item.value}
                                            htmlFor={id ? `${id}-${index}` : `id-${index}`}
                                            title={formattedMessage}
                                            style={noMargin ? undefined : { marginRight: 15 }}
                                        >
                                            <input
                                                id={id ? `${id}-${index}` : `id-${index}`}
                                                name={lbl}
                                                checked={input && item.value?.toString() === input.value}
                                                {...input}
                                                type="radio"
                                                value={item.value}
                                                disabled={disabled}
                                            />{" "}
                                            {formattedMessage}
                                        </label>
                                    );
                                }}
                            />
                        ))}
                    </div>
                    {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error">{error}</span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderSelectIdNameField = ({
    input,
    label,
    className,
    skipContextCheck,
    disabled,
    data,
    hideDefault,
    meta: { touched, error },
    labelPrefix = "Select an",
}) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    <div className="form-inner-div">
                        {label ? (
                            isIE ? (
                                <span className="form-label" style={{ marginBottom: "33px" }} title={label}>
                                    {label}
                                </span>
                            ) : (
                                <span className="form-label" title={label}>
                                    {label}
                                </span>
                            )
                        ) : null}
                        <Field name={input.name} component="select" className="form-select" style={{ marginBottom: "10px" }} disabled={disabled}>
                            {hideDefault ? null : <option value="">{`${labelPrefix} ${label}`}</option>}
                            {data.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </Field>
                    </div>
                    {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error">{error}</span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderSelectIdNameMaterialField = ({ input, label, className, skipContextCheck, disabled, data, hideDefault, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    <div className="form-inner-div">
                        {label ? (
                            isIE ? (
                                <span className="form-label" style={{ marginBottom: "33px" }} title={label}>
                                    {label}
                                </span>
                            ) : (
                                <span className="form-label" title={label}>
                                    {label}
                                </span>
                            )
                        ) : null}
                        <Select variant="outlined" margin="dense" inputProps={input} className="material-select">
                            {data.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error">{error}</span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const renderSelectList = ({ input, label, skipContextCheck, className, disabled, data, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className={className}>
                    {label ? (
                        isIE ? (
                            <span className="form-label" style={{ marginBottom: "33px" }} title={label}>
                                {label}
                            </span>
                        ) : (
                            <span className="form-label" title={label}>
                                {label}
                            </span>
                        )
                    ) : null}
                    <div className="form-inner-div">
                        <Field name={input.name} component="select" disabled={disabled} style={{ marginBottom: "10px" }} className="form-select">
                            {data.map((item, index) => {
                                if (typeof item.label === "string") {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                } else {
                                    return item.label;
                                }
                            })}
                        </Field>
                    </div>
                    {(touched || (!skipContextCheck && context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error">{error}</span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};
