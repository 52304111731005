export const remapData = (apiResponse) => {
    const mappedData = [];
    
    apiResponse.forEach((item) => {
        
        let subC = [];
        try {
            item.subCodes.forEach((subcode) => {

                subC.push({
                    subCodeDescription: subcode.subCodeDescription,
                    programCodeId: item.ProgramCodeId,
                    subCode: subcode.programSubCodeId,
                    creditHours: subcode.creditHours,
                    programSubCodeId: subcode.programSubCodeId,
                });


            });
        } catch (e) {
            //console.log(e);
        }
        
        
        let idx = mappedData.findIndex((c) => c.programCodeId === item.programCodeId);
        if (idx === -1) {
            mappedData.push({
                programCode: item.programCode,
                programCodeId: item.ProgramCodeId,
                colorCode: item.colorCode,
                programCodeDescription: item.programCodeDescription,
                subCodes: subC,
                creditHours: item.creditHours,
            });
            idx = mappedData.findIndex((c) => c.programCodeId === item.programCodeId);
        } else {
            mappedData[idx].subCodes = subC;
        }

        
        

            
        
    });
   
    return mappedData;
};

export const rawIt = (result) => {
    const header = [];

    result.forEach((item) =>
        header.push({
            programCodeId: item.programCodeId,
            programCode: item.programCode,
            programCodeDescription: item.programCodeDescription,
        })
    );

    const content = result.map((item) => item.subCodes);

    const rawData = [];

    for (let i = 0; i < content.length; i++) {
        for (let j = 0; j < content[i].length; j++) {
            rawData.push({
                ...header[i],
                ...content[i][j],
            });
        }
    }

    return rawData;
};
