import React from 'react';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';

import './index.css';

const IconButton = ({ icon, onClick }) => (
    <button className="icon-button" onClick={onClick}>
        <FontAwesome name={icon} />
    </button>
);

IconButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired
};

export default IconButton;
