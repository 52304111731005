import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import FontAwesome from "react-fontawesome";
import moment from "moment";

import "./Crusader.css";

const Crusader = ({ crusader, schools, onEdit, onDelete, readonly }) => {
    const dob = moment(crusader.dateOfBirth).utc().format("MM/DD/YYYY");
    const name = `${crusader.firstName} ${crusader.lastName}`;
    const sex = crusader.gender === 0 ? "M" : crusader.gender === 1 ? "F" : "O";
    const sexdob = `${sex} ${dob}`;
    const schoolName = schools.find((school) => school.id === crusader.schoolId).name;
    const gradeSchool = `${crusader.grade} ${schoolName}`;

    return (
        <div className="household-crusader-container">
            <div className="household-crusader-column-start">
                <span>{name}</span>
                <span>{sexdob}</span>
                <span>{gradeSchool}</span>
            </div>
            <button className="household-crusader-column-button" onClick={onEdit}>
                <FontAwesome name="pencil" size="lg" />
                <FormattedMessage id="Edit" defaultMessage="[edit]" children={(formattedMessage) => <span>{formattedMessage}</span>} />
            </button>
            <button className="household-crusader-column-button" onClick={onDelete} disabled={readonly}>
                <FontAwesome name="trash" size="lg" />
                <FormattedMessage id="Delete" defaultMessage="[delete]" children={(formattedMessage) => <span>{formattedMessage}</span>} />
            </button>
        </div>
    );
};

Crusader.protoTypes = {
    crusader: PropTypes.shape({
        dateOfBirth: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        gender: PropTypes.number.isRequired,
        genderDescription: PropTypes.string,
        grade: PropTypes.number.isRequired,
        schoolId: PropTypes.string.isRequired,
    }).isRequired,
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
};

export default Crusader;
