import React from "react";
import ConfirmDialog from "../../../common/confirmDialog";
import enumHelper from "../../../support/logic/enumHelper";
import { ProgramEventDateWaitlistResponseDuration } from "../../enums";
import { addToEvent } from "../../api/programEvents";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

const WaitlistAddToEventDialog = ({
    isOpen,
    closeWaitlistAddToEventDialog,
    selectedUsers,
    setRegisteredUsers,
    waitlistResponseTime,
    waitlistResponseDuration,
}) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handleOnConfirm = async () => {
        const payload = selectedUsers.map((user) => user.waitlistId);

        try {
            const resp = await addToEvent(payload);
            setRegisteredUsers((prev) => {
                prev.forEach((user) => {
                    if (payload.includes(user.waitlistId)) user.status = "Invitation Pending";
                });
                return prev;
            });
            resp.data.success && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        closeWaitlistAddToEventDialog();
    };

    const dialogContent = (
        <div className={classes.dialogContent}>
            <p>Are you sure you want to add the following participants to the Event?</p>
            <ul>
                {selectedUsers.map((user, idx) => (
                    <li key={idx}>
                        {user.firstName} {user.lastName}
                    </li>
                ))}
            </ul>
            <br />
            <p>
                Participant(s) will be automatically notified and have {waitlistResponseTime}{" "}
                {enumHelper.getDisplayName(ProgramEventDateWaitlistResponseDuration, waitlistResponseDuration)} to accept.
            </p>
        </div>
    );

    return (
        <ConfirmDialog
            open={isOpen}
            disablePortal
            handleClose={closeWaitlistAddToEventDialog}
            confirmAction={handleOnConfirm}
            confirmActionText="Enroll"
            fullWidth
            maxWidth="sm"
            title="Add to Event"
            body={dialogContent}
        />
    );
};

export default WaitlistAddToEventDialog;
