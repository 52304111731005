import React, { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { remapData } from "./programCodeDialogHelpers";
import { useStyles } from "./styles";
import { Field } from "react-final-form";
import { FinalFormTextField } from "../../../../common/formWrappers";
import { DecimalNumberMaskInput } from "../../../../common/masks";
import { OnChange } from "react-final-form-listeners";

const ProgramCodeList = ({ form, showDelete = false, showEdit = false, totalHours, programCodesRecord = [], onDelete = () => { }, fields, tagWeights, recalculate, setRecalculate }) => {
    const classes = useStyles();
  //  //console.log(programCodesRecord);
   // const mappedData = remapData(programCodesRecord);
    const [mappedData, setMappedData] = useState([]);

    useEffect(() => {
        setMappedData(programCodesRecord);
    }, [programCodesRecord]);


    useEffect(() => {
        if (recalculate) {
            const currentTotalHours = form.getState().values.totalHours;
            // A function to find the tag weight for a sub-item
            const findTagWeight = (subItem, idKey) =>
                tagWeights.find(tw => tw.tags.some(tag => tag.id === subItem[idKey]));

            const updatedData = mappedData.map(item => ({
                ...item,
                subCodes: item.subCodes.map(subItem => {
                    let tagWeight = findTagWeight(subItem, 'programCodeId') || findTagWeight(subItem, 'programSubCodeId');

                    if (tagWeight) {
                        let matchingTag = tagWeight.tags.find(tag => tag.id === subItem.programCodeId) ||
                            tagWeight.tags.find(tag => tag.id === subItem.programSubCodeId);

                        if (matchingTag && currentTotalHours !== undefined) {
                            subItem.creditHours = matchingTag.weight * currentTotalHours;
                        }
                    }

                    return subItem;
                })
            }));

            updatedData.forEach(item => {
                item.subCodes.forEach(subCode => {
                    form.change(subCode.programSubCodeId, subCode.creditHours);
                });
            });

            setRecalculate(false);
            setMappedData(updatedData);
        }
    }, [recalculate, mappedData, tagWeights, form]);




   
    const handleOnDelete = (programCodeRecord) => {
        onDelete(programCodeRecord);
    };

    const emptyTable = (
        <TableBody>
            <TableRow>
                <TableCell className={classes.redLabel}>No Tags Set</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {showDelete && <TableCell></TableCell>}
            </TableRow>
        </TableBody>
    );

    const filledTable = (
        <TableBody>
            {mappedData.map((eventProgramCode) => {
  //              //console.log(eventProgramCode);
                const upperRow = (
                    <React.Fragment key={eventProgramCode.programCode}>
                        <TableRow>
                            <TableCell
                                rowSpan={eventProgramCode.subCodes.length + 1}
                            ><span style={{ color: eventProgramCode.colorCode, fontWeight: '800' }}  > {` ${eventProgramCode.programCodeDescription}`} </span></TableCell>
                        </TableRow>
                        {eventProgramCode.subCodes.map((subCode, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{` ${subCode.subCodeDescription}`} </TableCell>
                                <TableCell colSpan={2}>
                                    {showEdit ? (
                                        <>
                                            
                                            <Field
                                                required
                                                component={FinalFormTextField}
                                                variant="outlined"
                                                margin="dense"
                                                name={`${subCode.programSubCodeId}`}
                                                inputComponent={DecimalNumberMaskInput}
                                            >{subCode.creditHours }</Field>

                                            
                                        </>
                                    ) : (
                                        subCode.creditHours
                                    )}
                                </TableCell>
                                {showDelete && (
                                    <TableCell>
                                        <Button
                                            className={classes.input}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() =>
                                                handleOnDelete({
                                                    programCodeDescription: eventProgramCode.programCodeDescription,
                                                    programCode: eventProgramCode.programCode,
                                                    ...subCode,
                                                })
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </React.Fragment>
                );

                return upperRow;
            })}
        </TableBody>
    );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Classification</TableCell>
                    <TableCell>Tag</TableCell>
                    <TableCell>Credit Hours</TableCell>
                    <TableCell></TableCell>
                    {showDelete && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
            {mappedData.length > 0 ? filledTable : emptyTable}
        </Table>
    );
};

export default ProgramCodeList;
