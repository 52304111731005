import React from "react";
import Badge from "@material-ui/core/Badge";
import DateComponent from "../dateComponent/dateComponent";

const EventDateRow = ({ date, totalEvents, showMore }) => {
    return (
        <div>
            <Badge
                color="primary"
                invisible={!showMore}
                badgeContent={`+${totalEvents - 3}`}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <DateComponent date={{ startTime: date, endTime: null }} />
            </Badge>
        </div>
    );
};

export default EventDateRow;
