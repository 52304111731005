import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import { ajaxStatusLoadingAction, ajaxStatusCompleteAction, ajaxStatusErrorAction } from "../../redux/ajax";
import withPromptChange from "../../support/hoc/withPromptChange";
import withAdmissions from "../../support/hoc/withAdmissions";

import { ApplicationStatus, Language } from "../logic/enums";
import * as apis from "./apis";
import Form from "./Form";
import { SecurityContext } from "../../support/security";

const FORM_NAME = "household";

class Household extends Component {
  isStaffReview = false;

  componentDidMount = () => {
      const { match } = this.props;
      //console.log(this);
    if (match.params && match.params.id) {
      this.id = match.params.id;
      this.apiMethod = "getByApplicationId";
        
    } else if(this.props.admissions && this.props.admissions.id)
        {
            this.id = this.props.admissions.id;
            this.apiMethod = "getByApplicationId";
        } else {
          this.apiMethod = "get";
        }

    this.isStaffReview = match.params && match.params.id !== undefined;

    this.load();
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.admissions !== null && this.props.admissions.id !== nextProps.admissions.id) {
      this.load();
    }
  }

  load() {
    this.props.ajaxStatusLoading();
    apis[this.apiMethod](this.id)
      .then((results) => {
        this.doLoadInitialData(results.data.result);
        this.props.ajaxStatusComplete();
      })
      .catch((error) => {
        this.props.ajaxStatusError(`status: ${error.response.status} message: ${error.response.statusText}`);
      });
  }

  onSave = (values) => {
    this.props.ajaxStatusLoading();
    apis
      .update({ ...values, applicationId: this.props.admissions.id })
      .then((results) => {
        if (this.isStaffReview) {
          this.props.refreshApplication();
        } else {
            this.props.refreshApplication();
           // this.props.loadIdentifiers();
           // this.props.loadApplicationAsync(this.props.admissions.id)
        }
        this.props.ajaxStatusComplete();
        toast.success("Save Complete");
      })
      .catch((error) => {
        this.props.ajaxStatusError(`status: ${error.response.status} message: ${error.response.statusText}`);
      });
  };
  onCancel = (e) => {
    e.preventDefault();
    this.props.reset();
  };

  doLoadInitialData = (data) => {
    const formData = data && data.length && data.length > 0 ? data[0] : null;
    if (formData) {
      const locale = this.props.locale;
      if (!formData.primaryLanguage) {
        if (locale === "en") {
          formData.primaryLanguage = Language.English;
        } else if (locale === "es-MX") {
          formData.primaryLanguage = Language.Spanish;
          }

        }
        if (formData.hasHomeComputer == "True") {
            formData.hasHomeComputer = "1";
        } else {
            formData.hasHomeComputer = "2";
        }
        if (formData.hasInternet == "True") {
            formData.hasInternet = "1";
        } else {
            formData.hasInternet = "2";
        }
    }
    this.props.initialize(formData);
  };

  render() {
    const { admissions } = this.props;

    let readonly =
      admissions === null ||
      (admissions.status !== ApplicationStatus.NotSubmitted &&
        admissions.status !== ApplicationStatus.Rejected &&
            admissions.status !== ApplicationStatus.LunchPending &&
            admissions.status !== ApplicationStatus.Submitted &&
        this.props.admissions.status !== ApplicationStatus.Resubmit &&
        this.props.admissions.status !== ApplicationStatus.Accepted
      );
      if (this.props.readOnly || admissions.status === ApplicationStatus.Submitted) {
          readonly = true;
      }
      //console.log(readonly);
    return (
      <SecurityContext.Consumer>
        {context => {
            return (
              <Form
                isStaff={this.isStaffReview}
                {...this.props}
                primaryLanguage={this.props.primaryLanguage}
                change={this.props.change}
                onSave={this.onSave}
                onCancel={this.onCancel}
                readonly={context.hasEditPermission("Admin.Admissions") ? false : readonly}
                match={this.props.match}
                pristine={this.props.pristine}
              />
            );
        }}
      </SecurityContext.Consumer>
    );
  }
}

Household.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  primaryLanguage: PropTypes.number,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
  form: FORM_NAME,
})(Household);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  const primaryLanguage = parseInt(selector(state, "primaryLanguage"), 10);

  return {
    primaryLanguage,
    locale: state.locale,
    admissions: state.admissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ajaxStatusLoading: bindActionCreators(ajaxStatusLoadingAction, dispatch),
    ajaxStatusComplete: bindActionCreators(ajaxStatusCompleteAction, dispatch),
    ajaxStatusError: bindActionCreators(ajaxStatusErrorAction, dispatch),
  };
};

let HouseholdForm = connect(mapStateToProps, mapDispatchToProps)(ReduxForm);

HouseholdForm = withAdmissions(HouseholdForm);
HouseholdForm = withPromptChange(HouseholdForm, FORM_NAME);
HouseholdForm = withRouter(HouseholdForm);

export default HouseholdForm;
