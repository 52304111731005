import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { setLocaleAction } from "../../language/redux";

import Logo from "../../images/OWL_Logos_Final-03.png";

import "./index.css";

class ApplicationRejected extends Component {
  UNSAFE_componentWillMount() {
    const query = this.props.location.search.replace("?", "");
    const params = query.split("&");
    this.id = params[0].split("=")[1];
    this.locale = params[1].split("=")[1];
    this.props.setLocale(this.locale);
  }

  getRejectReason(reason) {
    switch (reason) {
      case "1":
        return "admissions.rejectProofOfResidency";
      case "2":
        return "admissions.rejectProofOfFreeLunch";
      case "3":
        return "admissions.rejectProofOfAge";
      case "4":
        return "admissions.rejectReportCard";
      case "5":
        return "admissions.rejectPersonalStatement";
      case "6":
        return "admissions.rejectNotIn6thGrade";
      case "7":
        return "admissions.rejectContracts";
      case "100":
        return "admissions.rejectApplicant";
      default:
        return "x";
    }
  }

  getTitle(reason) {
    return reason === "100"
      ? "admissions.rejectApplicantTitle"
      : reason === "6"
      ? "admissions.rejectApplicantIneligibleTitle"
      : "admissions.applicationIncompleteTitle";
  }

  render() {
    return (
      <div className="application-rejected">
        <div className="application-rejected__logo-container">
          <img src={Logo} className="application-rejected__logo" alt="logo" />
        </div>
        <FormattedMessage id={this.getTitle(this.id)} children={(formattedMessage) => <h1 className="screen-title">{formattedMessage}</h1>} />
        <div className="rejection-text">
          <FormattedHTMLMessage id={this.getRejectReason(this.id)} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLocale: bindActionCreators(setLocaleAction, dispatch),
});

const mapStateToProps = (state) => ({
  locale: state.locale,
});

ApplicationRejected = injectIntl(ApplicationRejected);
ApplicationRejected = connect(mapStateToProps, mapDispatchToProps)(ApplicationRejected);
ApplicationRejected = withRouter(ApplicationRejected);

export default ApplicationRejected;
