import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";
import "./CrusaderList.css";

const getStatusValue = statusWord => {
  let results = 5;
  switch (statusWord) {
    case "Full Time":
      results = 1;
      break;
    case "3/4 Time":
      results = 2;
      break;
    case "1/2 Time":
      results = 3;
      break;
    case "1/4 Time":
      results = 4;
      break;
    default:
      results = 5;
      break;
  }

  return results;
};
class CrusaderList extends Component {
  render() {
    return (
      <div className="bottom">
        <ReactTable
          data={this.props.paymentRequest.details}
          minRows={0}
          columns={[
            {
              Header: "Last Name",
              accessor: "lastName",
              minWidth: 150
            },
            {
              Header: "First Name",
              accessor: "firstName",
              minWidth: 150
            },
            {
              Header: "Term",
              accessor: "term",
              minWidth: 150
            },
            {
              Header: "Certified",
              accessor: "enrollmentStatus",
              minWidth: 150
            },
            {
              Header: "Description",
              accessor: "certified",
              minWidth: 150,
              sortMethod: (a, b) => {
                const aValue = getStatusValue(a);
                const bValue = getStatusValue(b);
                if (aValue === bValue) return 0;
                if (aValue > bValue) return 1;
                if (aValue < bValue) return -1;
              }
            },
            {
              Header: "Total Due",
              accessor: "totalDue",
              Cell: row => {
                  return <div style={{ textAlign: "right" }}>{formatCurrencyNoDecimalWithNegativeStyle(row.original.totalDue)}</div>;
              },
              sortMethod: (a, b) => {
                const aInt = parseInt(
                  a
                    .replace("$", "")
                    .replace(",", "")
                    .replace(".00", ""),
                  10
                );
                const bInt = parseInt(
                  b
                    .replace("$", "")
                    .replace(",", "")
                    .replace(".00", ""),
                  10
                );
                if (aInt === bInt) return 0;
                if (aInt > bInt) return 1;
                if (aInt < bInt) return -1;
              }
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight financials-table"
        />
      </div>
    );
  }
}

CrusaderList.propTypes = {
  paymentRequest: PropTypes.shape({
    total: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        term: PropTypes.string,
        certified: PropTypes.string,
        totalDue: PropTypes.string
      })
    )
  })
};

export default CrusaderList;
