import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import EventDateItem from "../components/eventDateItem";
import { Condition, FinalFormRadioGroup } from "../../../common/formWrappers";
import { EventLocationType } from "../../enums";

const useStyles = makeStyles((theme) => ({
    addDateButton: {
        margin: theme.spacing(2, 0),
    },
}));

const EventDates = ({ eventDates, eventStatus, isSeries, topLevelLocationType, eventLocationOptions, mutators, change, disabled }) => {
    const classes = useStyles();

    return (
        <>
            <FieldArray name="eventDates">
                {({ fields, meta, errors }) => {
                    return (
                        <>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Start date</TableCell>
                                        <TableCell>Start time</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>End time</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Credit Hours</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((field, index) => {
                                        return (
                                            <EventDateItem
                                                key={field}
                                                id={eventDates[index].id}
                                                field={field}
                                                locationTypes={eventLocationOptions}
                                                showDeleteButton={fields.length > 1}
                                                disabled={disabled}
                                                onDelete={() => mutators.remove("eventDates", index)}
                                                onCancel={() => {
                                                    const eventDate = eventDates[index];
                                                    mutators.update("eventDates", index, { ...eventDate, cancelled: true });
                                                }}
                                                onUndo={() => {
                                                    const eventDate = eventDates[index];
                                                    mutators.update("eventDates", index, { ...eventDate, cancelled: false });
                                                }}
                                                onTimeChange={(val, previous) => {
                                                    const eventDate = eventDates[index];
                                                    const { date, startTime, endTime } = eventDate;

                                                    if (!date || !startTime || !endTime) {
                                                        return;
                                                    }

                                                    const sTime = date.clone().hour(startTime.hour()).minute(startTime.minute()).second(0).millisecond(0);
                                                    const eTime = date.clone().hour(endTime.hour()).minute(endTime.minute()).second(0).millisecond(0);
                                                    if (!val.isSame(previous) && sTime?.isValid() && eTime?.isValid()) {
                                                        const creditHours = eTime.diff(sTime, "hours", true);
                                                        mutators.update("eventDates", index, { ...eventDate, creditHours });
                                                    }
                                                }}
                                                topLevelLocationType={topLevelLocationType}
                                                eventStatus={eventStatus}
                                                cancelled={eventDates[index].cancelled}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Button
                                className={classes.addDateButton}
                                variant="contained"
                                color="primary"
                                disabled={disabled }
                                onClick={() => {
                                    mutators.push("eventDates", {
                                        date: moment().add(1, "hours").startOf("hour"),
                                        startTime: moment().add(1, "hours").startOf("hour"),
                                        endTime: moment().add(2, "hours").startOf("hour"),
                                        locationType: topLevelLocationType,
                                        creditHours: 1,
                                    });
                                }}
                            >
                                Add Date
                            </Button>
                            <OnChange name="locationType">
                                {(locationType) => {
                                    if (locationType === EventLocationType.Virtual || locationType === EventLocationType.InPerson) {
                                        for (let i = 0; i < eventDates.length; i++) {
                                            mutators.update("eventDates", i, { ...eventDates[i], locationType });
                                        }
                                    }
                                }}
                            </OnChange>
                        </>
                    );
                }}
            </FieldArray>
            {eventDates?.length > 1 && (
                <>
                    <Field
                        required
                        name="isSeries"
                        row
                        label="Is this Event a Series?"
                        fullWidth
                        component={FinalFormRadioGroup}
                        choices={[
                            { label: "Yes (Participants must attend all dates for the event)", value: true },
                            { label: "No (Each date is a separate event)", value: false },
                        ]}
                        format={(value) => {
                            return value ? value.toString() : "false";
                        }}
                        parse={(value) => {
                            return value === "true";
                        }}
                    />
                    <Condition when="isSeries" is={false}>
                        <Field
                            required
                            name="canAttendMultipleEvents"
                            row
                            label="Can participants attend multiple dates of this event?"
                            fullWidth
                            component={FinalFormRadioGroup}
                            choices={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                            ]}
                            format={(value) => {
                                return value ? value.toString() : "false";
                            }}
                            parse={(value) => {
                                return value === "true";
                            }}
                        />
                    </Condition>
                </>
            )}
            <OnChange name="isSeries">{(isSeries) => isSeries && change("canAttendMultipleEvents", false)}</OnChange>
        </>
    );
};

export default EventDates;
