import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import './Header.css';

class Header extends React.Component {
    static permission = 'Admin.Institutions';

    render() {
        const { readonly } = this.props;
        return (
            <div className="admin-institution-header">
                <button onClick={this.props.onAdd} disabled={readonly}>
                    <FontAwesome name="plus" />
                    <span className="title">ADD INSTITUTION</span>
                </button>
            </div>
        );
    }
}

Header.propTypes = {
    onAdd: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired
};

export default Header;
