import React from "react";
import { useStyles } from "./styles";

const SubmittedCard = ({ submittedData: { submitted, submittedBy, submittedDate } }) => {
    const classes = useStyles();

    const greenCard = (
        <div className={classes.card}>
            <span className={classes.greenLabel}>Yes</span>
            <small className={classes.italic}>{`by: ${submittedBy}`}</small>
            <small className={classes.italic}>{submittedDate}</small>
        </div>
    );

    const redCard = (
        <div className={classes.card}>
            <span className={classes.redLabel}>No</span>
        </div>
    );

    return submitted ? greenCard : redCard;
};

export default SubmittedCard;
