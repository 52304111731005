import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    dateComponent: {
        display: "flex",
        margin: "2px 0px",
    },
    date: {
        fontSize: 16,
        color: theme.palette.lightGray.main,
    },
    time: {
        width: 160,
        fontSize: 16,
        color: theme.palette.lightGray.main,
    },
    divider: {
        height: 22,
        margin: theme.spacing(0, 1),
    },
}));
