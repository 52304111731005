import React from 'react';
import PropTypes from 'prop-types';

import './CashScholarship.css';

const CashScholarship = ({ name, amount }) => {
    return (
        <div className="scholarship-programs-cash-column">
            <span>{name}</span>
            <span>{amount}</span>
        </div>
    );
};

CashScholarship.propTypes = {
    name: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired
};

export default CashScholarship;
