import React from "react";
import PropTypes from "prop-types";

import { TermStructure } from "../../../support/enums";
import { formatCurrencyNoDecimal as f } from "../../../support/numberSupport";

import "./TotalCounts.css";

const TotalCounts = ({ data }) => {
    const label = data.termStructure === TermStructure.Semester ? "Semester" : "Trimester";
    return (
        <div className="scholarship-certify-total-counts-container">
            {data.terms.map((item) => {
                if (item.period < 4) {
                    return (
                        <div className="term-container" key={item.period}>
                            <span className="term">{`${label} ${item.period}`}</span>
                            <div className="donation">
                                <span className="title">Donated Scholarships ($)</span>
                                <span className="value">{`${f(item.amountUsed)} / ${f(data.amountAvailable)}`}</span>
                            </div>
                            <div className="donation">
                                <span className="title">Donated Scholarships</span>
                                <span className="value">{`${item.scholarshipsUsed} / ${data.scholarshipsAvailable}`}</span>
                            </div>
                            <div className="breakdown">
                                <span className="title">{`Freshman (${f(data.freshmanAmount)} ea)`}</span>
                                <span className="value">{`${item.freshmanTotalUsed} / ${data.freshmanTotalAvailable}`}</span>
                            </div>
                            <div className="breakdown">
                                <span className="title">{`Sophmores (${f(data.sophomoreAmount)} ea)`}</span>
                                <span className="value">{`${item.sophomoreTotalUsed} / ${data.sophomoreTotalAvailable}`}</span>
                            </div>
                            <div className="breakdown">
                                <span className="title">{`Juniors (${f(data.juniorAmount)} ea)`}</span>
                                <span className="value">{`${item.juniorTotalUsed} / ${data.juniorTotalAvailable}`}</span>
                            </div>
                            <div className="breakdown">
                                <span className="title">{`Seniors (${f(data.seniorAmount)} ea)`}</span>
                                <span className="value">{`${item.seniorTotalUsed} / ${data.seniorTotalAvailable}`}</span>
                            </div>
                            <div className="breakdown">
                                <span className="title">{`Trade Programs (${f(data.tradeProgramAmount)} ea)`}</span>
                                <span className="value">{`${item.tradeProgramTotalUsed} / NA`}</span>
                            </div>
                        </div>
                    );
                }
                
            })}
            <div className="scholarship-certify-total-counts-subcontainer">
                <span className="title">Onward We Learn Scholarships</span>
                <div className="levels">
                    <div className="level">
                        <span className="level-title">Gold Level</span>
                        <span className="value">{f(data.gold)}</span>
                    </div>
                    <div className="level">
                        <span className="level-title">Silver Level</span>
                        <span className="value">{f(data.silver)}</span>
                    </div>
                    <div className="level">
                        <span className="level-title">Bronze Level</span>
                        <span className="value">{f(data.bronze)}</span>
                    </div>
                    <div className="level">
                        <span className="level-title">Pewter Level</span>
                        <span className="value">{f(data.pewter)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

TotalCounts.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TotalCounts;
