import React, { useState, props, useEffect } from "react";
import { Route, Redirect, withRouter, useHistory } from "react-router-dom";
import * as ApplicationApi from "../api/applications";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { loadIdentifiers, loadApplication, refreshApplication, loadActiveApplication } from "./logic/admissionsRedux";
import { ApplicationStatusContext } from "../support/context";
import Applications from "./landing";
import Documents from "./documents";
import Parent from "./parent";
import Student from "./student";
import Household from "./household";
import Contracts from "./contracts";
import OrientationVideo from "./orientationVideo";
import useAdmissions from "../common/useAdmissions";
import StudentProfile from "./profile";
import "./index.css";
import NavMenu from "./common/navMenu";


//  props.location.state.primaryParent


let Admissions = ({ props, onNewAdmission, topUser }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [state, setState] = useState({
        totalApplications: 0,
        activeApplication: null,
        isApplicationSubmitted: false,
        isApplicationValid: false,
        primaryParent: false,
        secondaryParent: [],
        secondaryMode: 2,
        topUser: topUser,
        updateApplicationStatus: (isApplicationSubmitted, isApplicationValid) => {
            setState((prev) => ({
                ...prev,
                isApplicationSubmitted,
                isApplicationValid,
            }));
        },
    });
    const history = useHistory();
    const [editProfile, setEditProfile] = useState({})
    const [allApps, setAllApps] = useState({})
    const { admissions, loadApplication, loadApplicationAsync, loadApplicationFromValues } = useAdmissions();


    useEffect(() => {
        //console.log(topUser);
        //console.log(state);
    }, []);




    const setActiveApp = (app) => {

        loadApplicationFromValues(app);

        setState({ activeApplication: app });

    };


    const appDelete = async (app) => {

        await ApplicationApi.deleteApplication(app.id);
        const res = await ApplicationApi.getAllByUser();
        const allapps = res.data.result;
        setAllApps(allapps);
        setState({ activeApplication: null });
        //console.log("delete");
        history.push("/admissions/");
    }
    const checkForDuplicateApp = (values) => {
        //console.log(values);
        const { firstName, lastName, dateOfBirth, id } = values;
        
        const birthDate = new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day);
        const matchingApps = [];
        const birthDateIso = birthDate.toISOString().split('T')[0];
        //console.log(birthDateIso);

        const ret = allApps.some(app => {
            const matchingUserProfile = app.userProfiles.some(userProfile => {
                const userBirthDate = new Date(userProfile.dateOfBirth).toISOString().split('T')[0];
                //console.log(userBirthDate);
                //console.log(userProfile);
                return (
                    userProfile.firstName.toLowerCase() == firstName.toLowerCase() &&
                    userProfile.lastName.toLowerCase() == lastName.toLowerCase() &&
                    userBirthDate == birthDateIso &&
                    userProfile.userProfileId != id
                );
            });
            if (matchingUserProfile == true) {
                matchingApps.push(app);
            }
            return matchingUserProfile;
        });
        //console.log(matchingApps);
        //console.log(ret);
        if (ret == true) {
            return matchingApps;
        }
        return ret;
    };


    const closeProfile = () => {
        history.push("/admissions/");
    }
    const openProfile = (app) => {
        setEditProfile(app);

        const url = "/admissions/profile/" + app.userProfiles[0].userProfileId + "/";
        history.push(url);
    };
    const setSecondary = (sec, mode) => {
        setState({ secondaryParent: sec, secondaryMode: mode });
    }
    const noActiveApp = () => {
        setState({ activeApplication: null });
    };

    if (state.activeApplication) {

        return (
            <ApplicationStatusContext.Provider value={state}>
                <div className="admissions">
                    <div className={`admissions-content ${!isDesktop ? "mobile" : ""}`}>
                        <ApplicationStatusContext.Consumer>{(context) => <NavMenu back={noActiveApp} />}</ApplicationStatusContext.Consumer>
                        <Route path="/admissions" exact render={() => <Redirect to="/admissions/student" />} />
                        <Route path="/admissions/student" render={() => <Student topUser={topUser} checkForDuplicateApp={checkForDuplicateApp} appDelete={appDelete} />} />
                        <Route path="/admissions/parent" render={() => <Parent secondaryGuardian={state.secondaryParent} secMode={state.secondaryMode} topUser={topUser} />} />
                        <Route path="/admissions/household" render={() => <Household topUser={topUser} />} />
                        <Route path="/admissions/documents" render={() => <Documents topUser={topUser} />} />
                        <Route path="/admissions/orientationvideo" render={() => <OrientationVideo topUser={topUser} />} />
                        <Route path="/admissions/contracts" render={() => <Contracts topUser={topUser} />} />
                    </div>
                </div>
            </ApplicationStatusContext.Provider>
        );

    } else {


        return (
            <ApplicationStatusContext.Provider value={state}>
                <div className="admissions">
                    <div className={`admissions-content ${!isDesktop ? "mobile" : ""}`}>

                        <Route path="/admissions" exact render={() => <Applications activeApplication={state.activeApplication} setActiveApp={setActiveApp} primaryParent={state.primaryParent} openProfile={openProfile} setSecondary={setSecondary} topUser={topUser} setAllApps={setAllApps} />} />
                        <Route path="/admissions/student" render={() => <Redirect to="/admissions" primaryParent={state.primaryParent} />} />
                        <Route path="/admissions/parent" render={() => <Redirect to="/admissions" primaryParent={state.primaryParent} />} />
                        <Route path="/admissions/household" render={() => <Redirect to="/admissions" primaryParent={state.primaryParent} />} />
                        <Route path="/admissions/documents" render={() => <Redirect to="/admissions" primaryParent={state.primaryParent} />} />
                        <Route path="/admissions/orientationvideo" render={() => <Redirect to="/admissions" />} />
                        <Route path="/admissions/contracts" render={() => <Redirect to="/admissions" primaryParent={state.primaryParent} />} />
                        <Route path="/admissions/profile/:id/" exact render={(props) => <StudentProfile
                            id={props.match}
                            activeProfile={editProfile}
                            match={props.match}
                            onClose={closeProfile}
                            topUser={topUser}
                        />} />
                    </div>
                </div>
            </ApplicationStatusContext.Provider>
        );
    }

};

Admissions = withRouter(Admissions);

export default Admissions;
