import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import ReactTable from "react-table";
import { Tabs, Tab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";

class EventsList extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data.attended,
            tab: 0


        };
    }



    componentDidMount() {
        
    }
    componentDidUpdate(prevProps, prevState) {



    }

    setTab = async (event, newVal) => {
        
        switch (newVal) {
            case 0:
                this.setState({ tab: newVal, data: this.props.data.attended });
                break;
            case 1:
                this.setState({ tab: newVal, data: this.props.data.registered });
                break;
            case 2:
                this.setState({ tab: newVal, data: this.props.data.waitlisted });               
                break;
            case 3:
                this.setState({ tab: newVal, data: this.props.data.invited });
                break;
            default:
                this.setState({ tab: newVal, data: this.props.data.attended });
                break;


        }

        
    }
    render() {
        let columns = [

            {
                Header: "",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.props.openEvent(row.original)} /></div>
                ),

            }, {
                Header: "Title",
                accessor: "name",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.name}</div>
                ),

            },
            {
                Header: "Description",
                accessor: "description",
                width: 300,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.description}</div>
                ),

            },
            {
                Header: "Credit Hours",
                accessor: "totalAvailableCreditHours",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.creditHours}</div>
                ),

            },
            {
                Header: "Start Date/Time",
                accessor: "date",
                width: 100,
                Cell: (row) => {
                    const lCont = row.value !== null ? moment(row.value).format("M/D/YY h:mm a") : "";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{lCont}</div>;
                },

            }
            ,
            {
                Header: "Season",
                accessor: "season",
                width: 100,
                Cell: (row) => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.season}</div>
                ),

            }
            ,
            {
                Header: "Approved",
                accessor: "approved",
                width: 100,
                Cell: (row) => {
                    const approved = row.value ? "Yes" : "No";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{approved}</div>;
                },

            }
            ,
            {
                Header: "Advisor",
                accessor: "creator",
                width: 300,
                Cell: (row) => {
                    const advisor = row.original.advisor ? row.original.advisor.firstName + ' ' + row.original.advisor.lastName : "N/A";
                    return <div className="dataCell" style={{ textAlign: "center" }}>{advisor}</div>;
                },

            }


        ];


        return <div>
            <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Log Entry Sections" variant="fullWidth"
                sx={{
                    '.MuiTabs-flexContainer': {
                        justifyContent: 'space-evenly',
                    },
                    '.MuiTab-root': {
                        border: '1px solid #000',
                        borderBottom: 'none',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        marginRight: '4px',
                        '&:last-child': {
                            marginRight: 0,
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#123225',
                            color: '#fff',
                        },
                        '&:hover': {
                            backgroundColor: '#123225',
                            color: '#fff',
                            opacity: 1,
                        },
                    },
                }}
            >
                <Tab label="Attended" />
                <Tab label="Enrolled" />
                <Tab label="Waitlisted" />
                <Tab label="Invited" />
            </Tabs>

            <ReactTable
                data={this.state.data}
                minRows={0}
                columns={columns}
                defaultPageSize={50}
                className="-striped -highlight tableWrapper"
            />
        </div>;
    }
}

export default EventsList;