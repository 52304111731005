import React, { Component } from "react";
import PropType from "prop-types";

import SearchBox, { searchType } from "../../components/searchBox";
import { searchInstitutions } from "../../apis";

import "./institutionSearch.css";

class Institution extends Component {
  state = {
    results: []
  };

  handleSearch = async searchTerm => {
    this.setState({ resultsLoading: true, showResults: false });
    const results = (await searchInstitutions(searchTerm)).data.result;
    this.setState({
      results,
      showResults: true,
      resultsLoading: false,
      noResultsFound: results.length > 0 ? false : true
    });
  };

  handleClear = () => {
    this.setState({ results: [], showResults: false });
  };

  handleResultClick = institution => {
    this.setState({ showResults: false, results: [] });
    this.props.onInstitutionSelected(institution);
  };

  render() {
    return (
      <div style={{ marginTop: 10 }}>
        <SearchBox
          placeholder="Search for an Institution"
          showResults={this.state.showResults}
          resultsLoading={this.state.resultsLoading}
          results={this.state.results}
          onSearch={this.handleSearch}
          type={searchType.institution}
          onResultClick={this.handleResultClick}
          onClear={this.handleClear}
          noResultsFound={this.state.noResultsFound}
        />
      </div>
    );
  }
}

Institution.propType = {
  institutions: PropType.arrayOf({
    id: PropType.string,
    name: PropType.string
  }),
  onInstitutionSelected: PropType.func.isRequired,
  onInstitutionClear: PropType.func.isRequired
};

export default Institution;
