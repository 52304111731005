import React, { Component } from "react";
import { connect } from "react-redux";
import Dropdown, { DropdownTrigger, DropdownContent } from "react-simple-dropdown";
import FontAwesome from "react-fontawesome";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { FormattedMessage, injectIntl } from "react-intl";

import { clearIdentifiers } from "../../../admissions/logic/admissionsRedux";
import { logoutAction } from "../../../redux/login";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import withAdmissions from "../../../support/hoc/withAdmissions";

import { createCCMUITheme } from "../../../support/muiTheme";
import "./index.css";

class UserMenu extends Component {
    userDropdown = null;

    constructor(props) {
        super(props);

        this.state = {



        };
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {
        
        
    }
   

    hideDropdown() {
        
        this.userDropdown.hide();
    }

    assignUserDropdownRef = (ref) => {
        this.userDropdown = ref;
    };

    handleProfileClick = (value, type) => {
        this.hideDropdown();
        this.props.openProfile(value, type);
    };

    handleSendMessage = (row) => {
        this.hideDropdown();
        this.props.sendMessage(row);
    }
    logEntry = (row) => {
        this.hideDropdown();
        this.props.logEntry(row);
    }
    comEntry = (row) => {
        this.hideDropdown();
        this.props.onContactAttempt(row);
    }
    render() {
      
        
        const userId = this.props.row.userProfile.id;
                    
                    
                    return (
                        <div className="advisor-menu">

                            <Dropdown ref={this.assignUserDropdownRef}>

                                <DropdownTrigger id={`trig-${userId}`}>
                                    <span className="fa-stack">
                                        <FontAwesome className="green" name="circle" stack="2x" />
                                        <FontAwesome className="white" name="ellipsis-h" stack="1x" />
                                    </span>
                                </DropdownTrigger>
                                <DropdownContent>
                                    <ul className="advisor-menu-list">
                                        <li>
                                            <div role="button" id={`student-profile-${userId}`} onClick={() => this.handleProfileClick(this.props.row, "Student")} onKeyPress={() => this.handleProfileClick(this.props.row, "Student")} tabIndex="0">
                                                Student Profile
                                            </div>
                                        </li>
                                        {this.props.loaded && this.props.row.family && this.props.row.family.primaryParent && this.props.row.family.primaryParent.id != "00000000-0000-0000-0000-000000000000" ? (
                                        
                                               
                                                    <li>
                                                <div role="button" id={`primary-parent-profile-${userId}`} onClick={() => this.handleProfileClick(this.props.row, "PrimaryParent")} onKeyPress={() => this.handleProfileClick(this.props.row, "PrimaryParent")} tabIndex="0">
                                                            Primary Guardian Profile
                                                        </div>
                                                    </li>
                                                
                                        ) : null}
                                        {this.props.loaded && this.props.row.family && this.props.row.family.secondaryParent && this.props.row.family.secondaryParent.id != "00000000-0000-0000-0000-000000000000" ? (


                                            <li>
                                                <div role="button" id={`secondard-parent-profile-${userId}`} onClick={() => this.handleProfileClick(this.props.row, "SecondaryParent")} onKeyPress={() => this.handleProfileClick(this.props.row, "SecondaryParent")} tabIndex="0">
                                                    Secondary Guardian Profile
                                                </div>
                                            </li>

                                        ) : null}
                                        {this.props.isDirector && this.props.row.advisor ? (
                                            <li>
                                                <div role="button" id={`advisor-profile-${userId}`} onClick={() => this.handleProfileClick(this.props.row, "Advisor")} onKeyPress={() => this.handleProfileClick(this.props.row, "Advisor")} tabIndex="0">
                                                    Advisor Profile
                                                </div>
                                            </li>
                                        ) : null}
                                        <li>
                                            <div role="button" id={`log-entry-${userId}`} onClick={() => this.logEntry(this.props.row)} onKeyPress={() => this.logEntry(this.props.row)} tabIndex="0">
                                                New Log Entry
                                            </div>
                                        </li>

                                        <li>
                                            <div role="button" id={`comm-attempt-${userId}`} onClick={() => this.comEntry(this.props.row)} onKeyPress={() => this.logEntry(this.props.row)} tabIndex="0">
                                                Communication Attempt
                                            </div>
                                        </li>
                                        <li>
                                            <div role="button" id={`message-${userId}`} onClick={() => this.handleSendMessage(this.props.row)} onKeyPress={() => this.handleSendMessage(this.props.row)} tabIndex="0">
                                                Message
                                            </div>
                                        </li>
                                    </ul>
                                </DropdownContent>
                            </Dropdown>

                        </div>
                    );
               
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction()),
    clearIdentifiers: () => dispatch(clearIdentifiers()),
});

UserMenu = connect(null, mapDispatchToProps)(UserMenu);

UserMenu = withAjaxStatus(UserMenu);
UserMenu = withAdmissions(UserMenu);

UserMenu = withRouter(UserMenu);

export default injectIntl(UserMenu);
