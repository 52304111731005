import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import {  Typography} from '@material-ui/core';
import moment from "moment";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';



class MilestonesGraph extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false,
            barData: [],
            total: 0,
            succeeded: 0,

        };
    }



    componentDidMount() {
        if (this.context.loaded && !this.state.loaded) {

            this.fetchData();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.context.loaded && !this.state.loaded ) {
           
            this.fetchData();
        }
    }




    fetchData = async () => {
        let students = [];
        let objectives = [];
        let trend = [];
        let total = 0;
        let succeeded = 0;
        let advisor = [];
        let objs = [];
        
            advisor = this.context.myBasics;
       
        this.context.milestoneTags.forEach(mt => {
            const oName = mt.classification.classification;

            mt.tags.forEach(tag => {

                if (tag.subtagId != '00000000-0000-0000-0000-000000000000') {
                    const result = tag.tagName.match(/^\d+/);
                    // If a number is found, result[0] will contain the number as a string
                    const number = result ? parseInt(result[0], 10) : null;

                    objs.push({
                        tagsId: tag.tagId,
                        subtagId: tag.subtagId,
                        objectiveId: tag.subtagId,
                        target: 100,
                        minGrade: number,
                        displayName: tag.tagName,
                        objectiveName: oName
                    });

                }


            });

        });

        const tmp = this.context.myCases;


        objs.forEach(ob => {
            const appl = tmp.filter(flt => flt.studentProfile.currentGrade == ob.minGrade);
           
            appl.forEach(st => {

                const tags = st.studentContactInfo.tags.filter(flt => flt.tagId == ob.tagsId && flt.subTagId == ob.subtagId);
                const status = tags.length > 0;
               
                total++;
                if (status) {
                   
                    succeeded++;
                }

                

                
            });


        });
        
        this.setState({ total: total, succeeded: succeeded, loaded: true });
    }


    calculateData = () => {
        let data = [];
        let barData = [];
        
        let matched = 0;
        let unmatched = 0;

        const obj = [];
        const uniqueIdentifiers = new Set();

        const addUniqueObjective = (objective) => {
            const identifier = `${objective.tagsId}|${objective.subtagId}|${objective.objectiveId}`;
            if (!uniqueIdentifiers.has(identifier)) {
                uniqueIdentifiers.add(identifier);
                obj.push(objective);
            }
        };

        // Assuming this.context.myBasics.objectives and sub.objectives are arrays of objectives
       // this.context.milestoneTags.forEach(addUniqueObjective);

       // this.context.myBasics.subordinates.forEach(sub => {
       //     sub.objectives.forEach(addUniqueObjective);
      //  });
        // Assuming 'data' is the array that contains your tags
        const processedData = this.context.milestoneTags.map((item) => {
            // Map through each item to process tags
            const updatedTags = item.tags.map((tag) => {
                // Use a regular expression to match the leading number
                const result = tag.tagName.match(/^\d+/);
                // If a number is found, result[0] will contain the number as a string
                const number = result ? parseInt(result[0], 10) : null;
                // Return the original tag along with the extracted number
                return {
                    ...tag,
                    number, // This is the extracted number, now associated with the tag
                };
            });

            // Return the updated item with the processed tags
            return {
                ...item,
                tags: updatedTags,
            };
        });

// Now processedData contains the tags with the associated numbers

        
        

        processedData.forEach(pd => {
            pd.tags.forEach(ob => {
            const eligible = this.context.myCases.filter((flt) => flt.studentProfile.currentGrade == ob.number);
            
                const inProgram = eligible.filter((flt) =>
                flt.studentContactInfo.tags.some(tag =>
                    tag.tagId === ob.tagId && tag.subTagId === ob.subtagId)
            );
            barData.push({
                name: ob.displayName,
                Meeting: inProgram.length,
                Short: eligible.length - inProgram.length,
                amt: eligible.length
            });


            matched = matched + inProgram.length;
            unmatched = unmatched + (eligible.length - inProgram.length)
            
        });
        });

        data.push({
            name: 'Meeting Milestones',
            value: matched,
            fill: '#123225'
        });

        data.push({
            name: 'Not Meeting Milestones',
            value: unmatched,
            fill: '#e2a331'
        });
        
        
        this.setState({ data: data, loaded: true, barData: barData });
    }

    renderBarChart = () =>
    {

        return (
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={150}
                height={150}
                data={this.state.barData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                
                <Bar dataKey="Meeting" fill="#123225"  />
                <Bar dataKey="Short" fill="#e2a331"  />
            </BarChart>
            </ResponsiveContainer>
        );
    }


    renderPieChart = () => {
        const renderCustomLabel = ({ name, percent }) => {
            return `${name}: ${(percent * 100).toFixed(0)}%`;
        };
        return (
            <ResponsiveContainer width="100%" height="100%">
            <PieChart >
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={this.state.data}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#123225"
                    label={renderCustomLabel} // Using the custom label function
                    labelLine={false} // Optional: if you don't want the lines from sectors to labels
                />
                <Tooltip />
            </PieChart>
                </ResponsiveContainer>
        );
    }

    renderPercentage = () => {
        let total = 0;
        let achieved = 0;

        total = this.state.total;
        achieved = this.state.succeeded;


        let percentage = 0;
        try {
            if (total != 0) {
                percentage = ((achieved / total) * 100).toFixed(2);
            }

        } catch {

        }


        return (
            <Typography variant="h5" color="primary" gutterBottom>
                {percentage}%
            </Typography>
        );
    }


    render() {


        return (
            <div style={{
                display: 'flex', // Use flexbox layout
                flexDirection: 'column', // Stack children vertically
                height: '100%', // Take the full height available
                justifyContent: 'flex-start', // Start alignment at the top for the first child
            }}>
                <div style={{
                    alignSelf: 'flex-start', // Align to the start of the flex container
                    width: '100%', // Ensure the div takes the full width
                    textAlign: 'center', // Center text horizontally
                    marginBottom: '0', // Remove any default bottom margin
                    paddingTop: '10px', // Space from the top of the container
                }}>Milestones Met</div>
                                <div style={{
                                    flex: 1, // Take up all available space
                                    display: 'flex', // Use flexbox layout
                                    flexDirection: 'column', // Stack children vertically
                                    justifyContent: 'center', // Center vertically in the remaining space
                                    textAlign: 'center', // Center text horizontally
                                }}>
                    {this.renderPercentage()}
                </div>
            </div>
        );

    }

}

export default MilestonesGraph;
