import axios from "axios";

export function getNavigationStatus(recruitmentCycleId, showAll) {
    return axios.get("/api/services/app/StaffQuery/GetNavigationStatus", { params: { recruitmentCycleId, showAll } });
}

export function getStartedApplications(recruitmentCycleId, showAll, search) {
    return axios.get("/api/services/app/StaffQuery/GetStartedApplications", { params: { recruitmentCycleId, showAll, search } });
}

export function getInReviewApplications(recruitmentCycleId, showAll, search) {
    return axios.get("/api/services/app/StaffQuery/GetInReviewApplications", { params: { recruitmentCycleId, showAll, search } });
}
export function getResubmitApplications(recruitmentCycleId, search) {
    return axios.get("/api/services/app/StaffQuery/GetResubmitApplications", { params: { recruitmentCycleId, search } });
}

export function getRejectedApplications(recruitmentCycleId, search) {
    return axios.get("/api/services/app/StaffQuery/GetRejectedApplications", { params: { recruitmentCycleId, search } });
}

export function getCompletedApplications(recruitmentCycleId, search) {
    return axios.get("/api/services/app/StaffQuery/GetCompletedApplications", { params: { recruitmentCycleId, search } });
}
