/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import Spinner from "react-spinkit";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import Button from "../../support/components/Button";
import {
  password,
  passwordMatch,
  required,
  renderField
} from "../../support/forms/index";
import * as apis from "../../api/login";

import "./index.css";
import withLocale from "../../support/hoc/withLocale";

class ResetPasswordUserName extends Component {
  state = {
    showLoader: false,
    showDialog: false,
    dialogTitle: "",
    dialogText: "",
    showLogin: false
  };

  componentDidMount() {
    const { userName } = this.props.match.params;
    this.userName = userName;
  }

  onSubmit = values => {
    const { locale } = this.props;

    this.setState({ showLoader: true });
    apis
      .resetPasswordByUserName(
        this.userName,
        values.password,
        values.confirm,
        values.code,
        locale
      )
      .then(result => {
        this.setState({ showLoader: false }, () => {
          this.setState({ showLogin: true });
        });
        toast.success(
          this.props.intl.formatMessage({ id: "verify.passwordReset" })
        );
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          showDialog: true,
          dialogTitle: this.props.intl.formatMessage({ id: "app.errorTitle" }),
          dialogText: error.response.data.error.message
        });
      });
  };

  onResend = e => {
    e.preventDefault();
    const { locale } = this.props;
    this.setState({ showLoader: true });
    apis
      .resendTextCode(this.userName, locale)
      .then(result => {
        this.setState({
          showLoader: false,
          showDialog: true,
          dialogTitle: "",
          dialogText: this.props.intl.formatMessage({
            id: "verify.instructions2"
          })
        });
      })
      .catch(error => {
        this.setState({
          showLoader: false,
          showDialog: true,
          dialogTitle: this.props.intl.formatMessage({ id: "app.errorTitle" }),
          dialogText: error.response.data.error.message
        });
      });
  };

  render() {
    if (this.state.showLogin) {
      return <Redirect to="/login" />;
    }

    return (
      <Fragment>
        <div className="reset-password-by-user-name">
          <div className="logo" alt="logo" />
          <div>
            <FormattedMessage
              id="login.reset"
              defaultMessage="[Enter Verification Code]"
              children={formatedMessage => (
                <h1 className="screen-title">{formatedMessage}</h1>
              )}
            />
            <div className="password-fields">
              <FormattedMessage
                id="login.password"
                defaultMessage="Password"
                children={formattedMessage => (
                  <Field
                    name="password"
                    type="password"
                    component={renderField}
                    label={formattedMessage}
                    validate={[required, password]}
                  />
                )}
              />

              <FormattedMessage
                id="login.confirmPassword"
                defaultMessage="Confirm"
                children={formattedMessage => (
                  <Field
                    name="confirm"
                    type="password"
                    component={renderField}
                    label={formattedMessage}
                    validate={[required, password]}
                  />
                )}
              />
            </div>
            <FormattedMessage
              id="login.passwordRequire"
              defaultMessage="Password Requirements"
              children={formattedMessage => (
                <span className="login-password-requirements">
                  {formattedMessage}
                </span>
              )}
            />
            <FormattedMessage
              id="verify.instructions"
              defaultMessage="[instructions]"
              children={formatedMessage => <p>{formatedMessage}</p>}
            />

            <FormattedMessage
              id="verify.tempCode"
              defaultMessage="[tempCode]"
              children={formattedMessage => (
                <Field
                  name="code"
                  component={renderField}
                  label={formattedMessage}
                  validate={[required]}
                />
              )}
            />

            <Button
              id="login.submit"
              defaultMessage="[Submit]"
              onClick={this.props.handleSubmit(this.onSubmit)}
            />
          </div>
          <div className="verify-resend-row">
            <FormattedMessage
              id="verify.resendPart1"
              defaultMessage="[resend part 1]"
              children={formattedMessage => <span>{formattedMessage}</span>}
            />
            <a href="" onClick={this.onResend} style={{ marginTop: "1px" }}>
              <FormattedMessage
                id="verify.resendPart2"
                defaultMessage="[resend part2]"
                children={formattedMessage => (
                  <span className="link-button">{formattedMessage}</span>
                )}
              />
            </a>
          </div>
          <SweetAlert
            show={this.state.showDialog}
            title={this.state.dialogTitle}
            onConfirm={() => this.setState({ showDialog: false })}
            confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
          >
            {this.state.dialogText}
          </SweetAlert>
        </div>

        {this.state.showLoader ? (
          <React.Fragment>
            <Spinner name="line-scale" color="#3cc6e8" fadeIn="none" />
            <div className="Loading"></div>
          </React.Fragment>
        ) : null}
      </Fragment>
    );
  }
}

ResetPasswordUserName.propTypes = {
  locale: PropTypes.string.isRequired
};

ResetPasswordUserName = reduxForm({
  form: "resetpasswordusername",
  fields: ["password", "confirm"],
  validate: passwordMatch,
  destroyOnUnmount: false
})(ResetPasswordUserName);

ResetPasswordUserName = withLocale(ResetPasswordUserName);
ResetPasswordUserName = withRouter(ResetPasswordUserName);
ResetPasswordUserName = injectIntl(ResetPasswordUserName);

export default ResetPasswordUserName;
