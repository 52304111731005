import React from "react";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "./styles";

const DateComponent = ({ date: { startTime, endTime } }) => {
    const classes = useStyles();
 
    return (
        <div className={classes.dateComponent}>
            <span className={classes.date}>{moment(startTime).format("MM/DD/YY")}</span>
            {endTime !== null && (
                <>
                    <Divider orientation="vertical" className={classes.divider} />
                    <span className={classes.time}>{`${moment(startTime).format("h:mm a")} - ${moment(endTime).format("h:mm a")}`}</span>
                </>
            )}
        </div>
    );
};

export default DateComponent;
