import React, { useState, useEffect, useReducer } from "react";

import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";

import AddIcon from "@material-ui/icons/Add";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import { FinalFormAutocomplete, FinalFormTextField } from "../../common/formWrappers";
import { composeValidators, maxLength, mustBeNumber, required } from "../../common/formValidators";
import LoadingButton from "../../common/loadingButton";
import { NumberMaskInput } from "../../common/masks";

import { EMPTY_GUID } from "../../support/constants";

import useProgramCodeHelpers from "./programCodeHelpers";

const useStyles = makeStyles((theme) => ({
    programCodeForm: {},
    weight: {},
    autoComplete: {
        "& .MuiTextField-root": {
            marginTop: 0,
            paddingRight: 10,
        },
    },
}));

const defaultProgramCodeState = {
    programData: {},
};

const programCodeReducer = (state, action) => {
    const newState = { ...state };

    if (action.type === "INIT") {
        newState.programData = action.item;
        return newState;
    }

    return defaultProgramCodeState;
};

const defaultProgramData = {
    programCodeId: EMPTY_GUID,
    programCode: 0,
    programCodeDescription: "",
    subCode: "",
    subCodeDescription: "",
    hasEvent: false,
    coreOfferings: [
        {
            name: "",
            abbreviation: "",
            weight: "",
            id: "",
        },
    ],
    gearUpActivityCodes: [
        {
            weight: "",
            activityCode: "",
            description: "",
            shortDescription: "",
            id: EMPTY_GUID,
        },
    ],
    id: EMPTY_GUID,
};

const ProgramCodeForm = ({ programData, saveButtonText, onSubmit, allActivityCodes, allCoreOfferings, programCodeOptions, newItem }) => {
    const classes = useStyles();
    const [selectedItemInput, setItemInput] = useState("");
    const [descriptionDisabled, setIsDescDisabled] = useState(false);
    const [disableFormFields, setDisabledFormFields] = useState(false);

    const { sortProgramCodesAsc, sortActivityCodesAsc, sortCoreOfferingsAsc } = useProgramCodeHelpers();

    const [programCodeState, dispatchProgramAction] = useReducer(programCodeReducer, defaultProgramCodeState);

    useEffect(() => {
        let tempData = { ...defaultProgramData };
        if (programData != null) {
            tempData = { ...programData };
            const obj = programCodeOptions.find((i) => i.id === programData.programCodeId);
            tempData.programCode = obj;

            setDisabledFormFields(Boolean(programData.hasEvent || window.location.href.indexOf("disableall") > 0));
        }
        dispatchProgramAction({ type: "INIT", item: tempData });
    }, [programCodeOptions, programData]);

    const getActivityCodeObjects = (activityCodes) => {
        let codes = [];
        for (let i = 0; i < activityCodes.length; i++) {
            let currentItem = activityCodes[i];
            let currentCode = currentItem.activityCode;

            if (typeof currentCode === "number") {
                currentItem.activityCode = allActivityCodes.find((item) => item.activityCode === currentCode);
            }

            codes.push(currentItem);
        }

        return codes;
    };

    const getcoreOfferingObjects = (coreOfferings) => {
        let offerings = [];
        for (let i = 0; i < coreOfferings.length; i++) {
            let currentItem = coreOfferings[i];
            let currentCode = currentItem.id;

            if (typeof currentCode === "number") {
                currentItem.id = allCoreOfferings.find((item) => item.id === currentCode); //seting the currentItem.id correct.  "ID" is the key name for the entire coreOffering object. This is required b/c of validation on autocomplete.
            }

            offerings.push(currentItem);
        }

        return offerings;
    };

    const setWeightErrors = (valueObjectArray, selectedItems, errorArray) => {
        if (selectedItems.length > 0) {
            const totalWeight = selectedItems.reduce((prev, val) => {
                return prev + +val.weight;
            }, 0);
            if (totalWeight !== 100) {
                for (let i = 0; i < valueObjectArray.length; i++) {
                    errorArray[i] = {};
                    errorArray[i].weight = "Combined weight must equal 100%";
                }
            }
        }
    };

    const handleFormSave = (values, onSuccessCallback) => {
        const data = {
            id: values.id,
            programCodeId: values.programCodeId,
            programCodeDescription: values.programCodeDescription,
            subCode: values.subCode,
            subCodeDescription: values.subCodeDescription,
        };

        //the only time the selectedInput will be numbers is if the user enters something in via free solo
        const isFreeHandText = isNaN(selectedItemInput) === false;
        const isEdit = values.id !== EMPTY_GUID;

        const coreOfferingsActual = getcoreOfferingObjects(values.coreOfferings);
        const gearUpCodesActual = getActivityCodeObjects(values.gearUpActivityCodes);

        const activityCodes = gearUpCodesActual.map((item) => {
            return { gearUpActivityCodeId: item.activityCode.id, weight: item.weight };
        });

        const coreOfferings = coreOfferingsActual.map((item) => {
            return { coreOfferingId: item.id.id, weight: item.weight }; //using item.id.id is correct.  "ID" is the key name for the entire coreOffering object. This is required b/c of validation on autocomplete.
        });

        ///data structures are different for insert vs edit
        if (isEdit) {
            data.programSubCodeCoreOfferings = coreOfferings;
            data.programSubCodeGearUpActivityCodes = activityCodes;

            data.programCode = {
                code: isFreeHandText ? selectedItemInput : values.programCode?.code,
                description: values.programCodeDescription,
                id: isFreeHandText ? "" : values.programCode?.id || values.programCodeId,
            };

            data.programCodeId = isFreeHandText ? "" : data.programCode.id;

            return onSubmit(data);
        }

        data.programCode = isFreeHandText ? selectedItemInput : values.programCode?.code;
        data.gearUpActivityCodes = activityCodes;
        data.coreOfferings = coreOfferings;
        data.programCodeId = isFreeHandText ? "" : values.programCode?.id;

        return onSubmit(data, onSuccessCallback);
    };

    const handleFormValidation = (values) => {
        const errors = {};

        //do reduce to get sum
        if (!values.gearUpActivityCodes && !values.coreOfferings) {
            return errors;
        }

        const gearUpCodes = getActivityCodeObjects(values.gearUpActivityCodes);

        if (gearUpCodes.length > 0) {
            errors.gearUpActivityCodes = [];
            setWeightErrors(values.gearUpActivityCodes, gearUpCodes, errors.gearUpActivityCodes);
        }

        const coreOfferings = getcoreOfferingObjects(values.coreOfferings);
        if (coreOfferings.length > 0) {
            errors.coreOfferings = [];
            setWeightErrors(values.coreOfferings, coreOfferings, errors.coreOfferings);
        }

        return errors;
    };

    return (
        <div className={classes.programCodeForm}>
            <Form onSubmit={handleFormSave} initialValues={programCodeState.programData} validate={handleFormValidation} mutators={{ ...arrayMutators }}>
                {({ handleSubmit, values, form, errors }) => {
                    const filteredActivityCodes =
                        values.gearUpActivityCodes &&
                        allActivityCodes.filter((code) => !values.gearUpActivityCodes.find((selected) => selected?.activityCode?.id === code?.id));

                    const filteredCoreOfferings =
                        values.coreOfferings && allCoreOfferings.filter((code) => !values.coreOfferings.find((selected) => selected?.id?.id === code.id));
                    return (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FinalFormAutocomplete}
                                                autoCompleteClassName={classes.autoComplete}
                                                name="programCode"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                fullWidth
                                                validate={(val) => {
                                                    if (Boolean(val && typeof val === "object")) {
                                                        return required(selectedItemInput);
                                                    }

                                                    return required(selectedItemInput) || mustBeNumber(selectedItemInput) || maxLength(6)(selectedItemInput);
                                                }}
                                                label="Program Code"
                                                id="programData"
                                                placeholder="Program Code"
                                                inputValue={selectedItemInput}
                                                onInputChange={(_, newValue) => {
                                                    setItemInput(newValue);
                                                }}
                                                freeSolo={newItem}
                                                options={sortProgramCodesAsc(programCodeOptions)}
                                                getOptionLabel={(option) => {
                                                    return !option.code ? "" : `${option.code} - ${option.description}`;
                                                }}
                                                getOptionSelected={(option, value) => option.code === value.code}
                                                disabled={!newItem}
                                            />
                                            <OnChange name="programCode">
                                                {(val, _) => {
                                                    const itemIsSelected = Boolean(val && typeof val === "object");
                                                    setIsDescDisabled(newItem && itemIsSelected);
                                                    if (!itemIsSelected) {
                                                        setItemInput(selectedItemInput.replace(/\D/g, ""));
                                                    }

                                                    form.change("programCodeDescription", val?.description || "");
                                                }}
                                            </OnChange>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FinalFormTextField}
                                                name="programCodeDescription"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                fullWidth
                                                label="Program Code Description"
                                                id="programCodeDescription"
                                                placeholder="Program Code Description"
                                                validate={composeValidators(required, maxLength(100))}
                                                multiline={true}
                                                disabled={descriptionDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FinalFormTextField}
                                                name="subCode"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                fullWidth
                                                label="Program Subcode"
                                                id="subCode"
                                                placeholder="Program Subcode"
                                                validate={required}
                                                maxLength={3}
                                                inputComponent={NumberMaskInput}
                                                disabled={!newItem || disableFormFields}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={FinalFormTextField}
                                                name="subCodeDescription"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                fullWidth
                                                label="Program Subcode Description"
                                                id="subCodeDescription"
                                                placeholder="Program Subcode Description"
                                                validate={composeValidators(required, maxLength(100))}
                                                multiline={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <FieldArray name="gearUpActivityCodes">
                                        {({ fields }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Grid container key={field} display="flex" spacing={1}>
                                                        <Grid item xs={7}>
                                                            <Field
                                                                label={index === 0 ? "Gear Up ID" : ""}
                                                                component={FinalFormAutocomplete}
                                                                key={`${field}.activityCode`}
                                                                name={`${field}.activityCode`}
                                                                options={sortActivityCodesAsc(filteredActivityCodes)}
                                                                getOptionLabel={(option) => {
                                                                    if (typeof option === "number") {
                                                                        option = allActivityCodes.find((item) => item.activityCode === option);
                                                                    }
                                                                    return !option.activityCode ? "" : `${option.activityCode} - ${option.shortDescription}`;
                                                                }}
                                                                getOptionSelected={(option, value) => {
                                                                    return option.activityCode === value || option.activityCode === value.activityCode;
                                                                }}
                                                                placeholder="Gear up ID"
                                                                required
                                                                validate={required}
                                                                autoCompleteClassName={classes.autoComplete}
                                                                disabled={disableFormFields}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Field
                                                                component={FinalFormTextField}
                                                                name={`${field}.weight`}
                                                                key={`${field}.weight`}
                                                                variant="outlined"
                                                                defaultValue=""
                                                                margin="dense"
                                                                required
                                                                fullWidth
                                                                maxLength={3}
                                                                label={index === 0 ? "Weight (%)" : ""}
                                                                id={`${field}.weight`}
                                                                placeholder="Weight"
                                                                validate={required}
                                                                inputComponent={NumberMaskInput}
                                                                className={classes.weight}
                                                                disabled={disableFormFields}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {fields.length > 1 && disableFormFields === false && (
                                                                <IconButton
                                                                    size="small"
                                                                    edge="start"
                                                                    style={index === 0 ? { marginTop: 30 } : { marginTop: 4 }}
                                                                    aria-label="remove"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        form.mutators.remove("gearUpActivityCodes", index);
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>

                                    <Box display="flex">
                                        <Box flex={1} display="flex">
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onClick={() => form.mutators.push("gearUpActivityCodes", { activityCode: "", weight: "" })}
                                                className={classes.addButton}
                                                disabled={disableFormFields}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Gear Up Code
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <FieldArray name="coreOfferings">
                                        {({ fields }) => {
                                            return (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Grid container key={field} spacing={1} display="flex">
                                                            <Grid item xs={7}>
                                                                <Field
                                                                    component={FinalFormAutocomplete}
                                                                    label={index === 0 ? "Core Offering" : ""}
                                                                    key={`${field}.id`}
                                                                    name={`${field}.id`}
                                                                    options={sortCoreOfferingsAsc(filteredCoreOfferings)}
                                                                    getOptionLabel={(option) => {
                                                                        if (typeof option === "number") {
                                                                            option = allCoreOfferings.find((item) => item.id === option);
                                                                        }
                                                                        return (option?.abbreviation && `${option.abbreviation} - ${option.name}`) || "";
                                                                    }}
                                                                    getOptionSelected={(option, value) => option.id === value || option.id === value.id}
                                                                    placeholder="Core Offering"
                                                                    required
                                                                    validate={required}
                                                                    autoCompleteClassName={classes.autoComplete}
                                                                    disabled={disableFormFields}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Field
                                                                    component={FinalFormTextField}
                                                                    name={`${field}.weight`}
                                                                    key={`${field}.weight`}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    required
                                                                    fullWidth
                                                                    label={index === 0 ? "Weight (%)" : ""}
                                                                    id={`${field}.weight`}
                                                                    placeholder="Weight"
                                                                    validate={required}
                                                                    className={classes.weight}
                                                                    maxLength={3}
                                                                    inputComponent={NumberMaskInput}
                                                                    disabled={disableFormFields}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                {fields.length > 1 && disableFormFields === false && (
                                                                    <IconButton
                                                                        size="small"
                                                                        edge="start"
                                                                        style={index === 0 ? { marginTop: 30 } : { marginTop: 4 }}
                                                                        aria-label="remove"
                                                                        // className={classes.deleteBtn}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            form.mutators.remove("coreOfferings", index);
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </>
                                            );
                                        }}
                                    </FieldArray>
                                    <Box display="flex">
                                        <Box flex={1} display="flex">
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onClick={() => form.mutators.push("coreOfferings", { name: "", abbreviation: "", weight: "" })}
                                                className={classes.addButton}
                                                disabled={disableFormFields}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Core Offering
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            {disableFormFields && (
                                <Typography variant="h5" style={{ color: "red" }}>
                                    Program code is in use, ID numbers and weights are not editable.
                                </Typography>
                            )}

                            <DialogActions>
                                <LoadingButton color="primary" variant="contained" type="submit">
                                    {saveButtonText}
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default ProgramCodeForm;
