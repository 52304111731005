import React from 'react';
import './index.css';

const StaffLayout = ({ children }) => {

    return (
    <div className="staff-layout">
        {children}
    </div>);
};

export default StaffLayout;
