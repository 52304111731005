import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import * as apis from "./apis";

import "./index.css";
import withUser from "../hoc/withUser";

export const SecurityContext = new React.createContext();

export class Navigation extends Component {
    render() {
        return (
            <SecurityContext.Consumer>
                {(context) => {
                    
                    return (
                        <div className="trilix-security-navigation">
                            {context.getSectionNames().map((section, index) => {
                                const sectionName = section.displayName.replace("-", " ");
                                const link = `/${section.route}`;
                                return (
                                    <NavLink key={index} to={link} activeClassName="trilix-security-navigation-active-link">
                                        <div className="option">{sectionName}</div>
                                    </NavLink>
                                );
                            })}
                            <div className="trilix-security-navigation-bottom-stretch" />
                        </div>
                    );
                }}
            </SecurityContext.Consumer>
        );
    }
}

export class SubNavigation extends Component {
    render() {
        return (
            <SecurityContext.Consumer>
                {(context) => {
                    return (
                        <div className="trilix-security-subnavigation">
                            <div style={{ display: "flex" }}>
                                {context
                                    .getSubSectionNames(this.props.sectionName)
                                    .filter((n) => n !== "Manage") //Explicitly remove manage for now
                                    .map((subSectionName, index) => {
                                        let link = `/${this.props.sectionName.toLowerCase()}/${subSectionName.toLowerCase()}`;
                                        if (link === "/admin/schools") {
                                            //hack the hacky route logic that is tightly coupled to security - SCOTT FAIL
                                            link = "/admin/middle-high";
                                        }
                                        return (
                                            <NavLink key={index} to={link} activeClassName="trilix-security-subnavigation-active-link">
                                                <div className="option">{subSectionName.replace("-", " ").replace("Crusaders","Students")}</div>
                                            </NavLink>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                }}
            </SecurityContext.Consumer>
        );
    }
}

SubNavigation.propTypes = {
    sectionName: PropTypes.string.isRequired,
};

const hasPermission = (permission, isRead, permissions) => {
    if (!permission) return false;
    if (!permissions) return false;
    if (isRead) {
        ////console.log(permission);
        return permissions.indexOf(`${permission}.view`) !== -1; // || this.state.permissions.indexOf(`${permission}.edit`) !== -1;
    }
    return permissions.indexOf(`${permission}.edit`) !== -1;
};
class SecurityProvider extends Component {
    state = {
        permissions: [],
        sectionNames: [],
        subSectionNames: [],
        hasReadPermission: (permission) => {
            if (typeof permission === "object") {
                let results = false;
                for (let i = 0; i < permission.length; i++) {
                    if (hasPermission(permission[i], true, this.state.permissions)) {
                        results = true;
                        break;
                    }
                }

                return results;
            }
            return hasPermission(permission, true, this.state.permissions);
        },
        hasEditPermission: (permission) => {
            if (typeof permission === "object") {
                let results = false;
                for (let i = 0; i < permission.length; i++) {
                    if (hasPermission(permission[i], false, this.state.permissions)) {
                        results = true;
                        break;
                    }
                }

                return results;
            }
            return hasPermission(permission, false, this.state.permissions);
        },
        getSectionNames: () => {
            return this.state.sectionNames;
        },
        getSubSectionNames: (sectionName) => {
            var subSection = this.state.subSectionNames.find((sub) => {
                return sub.sectionName === sectionName;
            });
            if (!subSection) {
                // console.warn(`can't file section ${sectionName} in context`);
                return [];
            }
            return [...subSection.subSections];
        },
        logout: () => {
            sessionStorage.removeItem("user");
            this.setState({ permissions: [], sectionNames: [], subSectionNames: [] });
        },
    };

    componentDidMount() {
        if (this.props.user) {
            this.loadPermissions();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user && this.props.user) {
            this.loadPermissions();
        }
    }

    loadPermissions = async () => {
        try {
            const data = (await apis.getPermissions(this.props.locale)).data.result;
            
            this.doParsePermissions(data);
        } catch (error) {
            this.props.onError(error);
        }
    };

    doParsePermissions = (data) => {
        let permissions = [];
        const sectionNames = [];
        const subSectionNames = [];

        for (let i = 0, len = data.length; i < len; i++) {
            const permission = data[i].permissions[0];
            const posSection = permission.indexOf(".");

            sectionNames.push({
                displayName: data[i].displayName,
                route: permission.substring(0, posSection).toLowerCase(),
            });

            permissions = permissions.concat(data[i].permissions);

            const subSectionName = {
                sectionName: data[i].displayName,
                subSections: [],
            };

            for (let x = 0, len2 = data[i].permissions.length; x < len2; x++) {
                const subPermission = data[i].permissions[x];
                const posSubSection = subPermission.indexOf(".", posSection + 1);
                const name = subPermission.substring(posSection + 1, posSubSection);
                if (subSectionName.subSections.indexOf(name) === -1) {
                    subSectionName.subSections.push(name);
                }
            }
            subSectionNames.push(subSectionName);
        }

        this.setState(
            {
                sectionNames,
                subSectionNames,
                permissions,
            },
            () => {
                if (this.state.sectionNames.length > 0) {
                    //this.props.history.push(`/${this.state.sectionNames[0].route}`);
                }
            }
        );
    };

    render() {
        return <SecurityContext.Provider value={this.state}>{this.props.children}</SecurityContext.Provider>;
    }
}

SecurityProvider.propTypes = {
    onError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ locale }) => {
    return { locale };
};

SecurityProvider = connect(mapStateToProps)(SecurityProvider);
SecurityProvider = withUser(SecurityProvider);
SecurityProvider = withRouter(SecurityProvider);

export default SecurityProvider;
