import React from "react";
import moment from "moment";
import ReactTable from "react-table";
import FontAwesome from "react-fontawesome";

import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

import "./refunds.css";

const Refunds = ({ data, getTdProps }) => (
  <div className="scholarship-recieve-refund-payments">
    <ReactTable
      className="financials-table -highlight"
      getTdProps={getTdProps}
      data={data}
      minRows={0}
      showPageSizeOptions={false}
      defaultPageSize={8}
      showPagination={true}
      columns={[
        {
          Header: "Refunds",
          accessor: "schoolName"
        },
        {
          Header: "Submitted",
          accessor: "submitted",
          Cell: row => {
            if (row.value === null)
              return <FontAwesome name="exclamation-circle" />;
            return moment.utc(row.value).format("M/D/YY");
          }
        },
        {
          Header: "Check #",
          accessor: "checkNumber",
          Cell: row => {
            const { value } = row;
            if (value === 0) {
              return "";
            }
            return row.value;
          }
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: row => {
            return formatCurrencyNoDecimalWithNegativeStyle(row.value);
          }
        },
        {
          Header: "Received On",
          accessor: "receivedOn",
          Cell: row => {
            const { value } = row;
            if (value) {
              return moment.utc(value).format("M/D/YY");
            }
            return "";
          }
        }
      ]}
    />
  </div>
);

export default Refunds;
