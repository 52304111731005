import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FontAwesome from "react-fontawesome";
import FileSaver from "file-saver";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import b64toBlob from "../../support/logic/b64toBlob";
import Instructions from "./Instructions";
import { getData } from "../../api/documents";
import OrientatioCompleteAlert from "../common/orientationCompleteAlert";
import ContractCompleteAlert from "../common/contractCompleteAlert";

import "./Form.css";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import withAdmissionSettings from "../../support/hoc/withAdmissionSettings";

const ViewContract = ({
    documentId,
    ajaxStatusLoading,
    ajaxStatusError,
    ajaxStatusComplete,
    showOrientationComplete,
    onConfirmOrientationComplete,
    showContractComplete,
    onConfirmContractComplete,
    admissionSettings,
}) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);
    const intl = useIntl();

    const handleDocumentClick = async () => {
        try {
            ajaxStatusLoading();
            const documentDataResponse = await getData(documentId);
            const documentData = documentDataResponse.data.result;
            const blob = b64toBlob(documentData.data, documentData.mimeType);
            FileSaver.saveAs(blob, documentData.fileName);
        } catch (err) {
            ajaxStatusError(err);
        } finally {
            ajaxStatusComplete();
        }
    };

    return (
        <div className="contracts-container" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            {!isDesktop && (
                <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                    {showInstructions
                        ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                        : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                </Link>
            )}
            {!isDesktop && showInstructions && <Instructions isDesktop={isDesktop} />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="hr-line-medium" />
                    <FormattedMessage id="nav.contracts" defaultMessage="[Contracts]" tagName="h2" />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormattedMessage id="contracts.compactAndMembershipTitle" defaultMessage="[Compact and Membership]" tagName="h3" />
                    <div
                        style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginTop: 10 }}
                        role="button"
                        onClick={() => handleDocumentClick()}
                        onKeyPress={() => handleDocumentClick()}
                        tabIndex="0"
                        title="contract_signed.pdf"
                    >
                        <FontAwesome style={{ fontSize: 40, cursor: "pointer" }} name="file-text" />
                        <button className="link-button" href="#">
                            <FormattedMessage id="contracts.download" defaultMessage="[Download]" />
                        </button>
                    </div>
                </Grid>
                <Hidden only="xs">
                    <Grid item sm={4} style={isDesktop ? { display: "flex", justifyContent: "flex-end" } : {}}>
                        <Instructions isDesktop={isDesktop} />
                    </Grid>
                </Hidden>
            </Grid>
            <OrientatioCompleteAlert show={showOrientationComplete && admissionSettings.orientationVideoEnabled} onConfirm={onConfirmOrientationComplete} />
            <ContractCompleteAlert show={showContractComplete && admissionSettings.orientationVideoEnabled} onConfirm={onConfirmContractComplete} />
        </div>
    );
};

let ViewContractWrapped = withAjaxStatus(ViewContract);
ViewContractWrapped = withAdmissionSettings(ViewContractWrapped);

export default ViewContractWrapped;
