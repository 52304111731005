import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { FinalFormAutocomplete, FinalFormTextField } from "../../../../common/formWrappers";
import { required } from "../../../../common/formValidators";
import { DecimalNumberMaskInput } from "../../../../common/masks";
import { useStyles } from "./styles";

const ProgramCodeForm = ({ addEventProgramCode, programCodes, setAux }) => {
    const classes = useStyles();
    const creditHours = "";
    const programCode = "";

    const handleValidate = (values) => {
        const errors = {};

        const creditHours = parseFloat(values.creditHours, 10);
        if (isNaN(creditHours)) {
            errors.creditHours = "This field is required";
        }

        if (creditHours <= 0) {
            errors.creditHours = "Must be greater than zero";
        }

        if (!values.programCode) {
            errors.programCode = "This field is required";
        }

        return errors;
    };

    const handleOnSubmit = (values, form) => {
        const {
            programCode: { programSubCodeId, programCode, subCode, programCodeId, programCodeDescription, subCodeDescription },
            creditHours,
        } = values;

        const _programCodes = programCodes.filter((item) => !(item.programCode === programCode && item.subCode === subCode));
        setAux(_programCodes);
        form.restart();

        addEventProgramCode({
            programSubCodeId,
            programCodeId,
            programCode,
            programCodeDescription,
            subCode,
            subCodeDescription,
            creditHours,
        });
    };

    return (
        <Form onSubmit={handleOnSubmit} initialValues={{ creditHours, programCode }} validate={handleValidate}>
            {({ handleSubmit, invalid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Program Code - Sub Code</TableCell>
                                    <TableCell>Credit Hours</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Field
                                            className={classes.dropDown}
                                            component={FinalFormAutocomplete}
                                            name="programCode"
                                            variant="outlined"
                                            margin="dense"
                                            placeholder="Program Code - Sub Code"
                                            required
                                            validate={required}
                                            getOptionLabel={(option) => {
                                                if (!option) return "";
                                                return `(${option.programCode}) ${option.programCodeDescription} - (${option.subCode}) ${option.subCodeDescription}`;
                                            }}
                                            options={programCodes || []}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Field
                                            component={FinalFormTextField}
                                            name="creditHours"
                                            variant="outlined"
                                            margin="dense"
                                            placeholder="Credit Hours"
                                            inputComponent={DecimalNumberMaskInput}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button type="submit" variant="contained" color="primary" disabled={invalid}>
                                            Add
                                        </Button>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </form>
                );
            }}
        </Form>
    );
};

export default ProgramCodeForm;
