import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { getData } from "../../../api/documents";
import b64toBlob from "../../../support/logic/b64toBlob";
import FileSaver from "file-saver";

const useStyles = makeStyles((theme) => ({
    fileDownload: {},
}));

const FileDownload = () => {
    const classes = useStyles();
    const [id, setId] = useState("");

    const handleDownload = async () => {
        const response = (await getData(id)).data.result;
        const blob = b64toBlob(response.data, response.mimeType);
        FileSaver.saveAs(blob, response.fileName);
    };

    return (
        <div className={classes.fileDownload}>
            <input type="text" onChange={(e) => setId(e.target.value)} />
            <button onClick={handleDownload}>Download</button>
        </div>
    );
};

export default FileDownload;
