import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field, Form } from "react-final-form";
import EditIcon from "@material-ui/icons/Edit";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Grid } from "@material-ui/core";
import { FinalFormCheckbox, FinalFormTextField } from "../../../common/formWrappers";
import { composeValidators, maxLength, minLength, required } from "../../../common/formValidators";
import { checkUsernameExists, resendEmailCode, resendTextCode, getUserNameSuggestion, createUserForProfile } from "./apis";
import { toast } from "react-toastify";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
    },
    actionBtn: {
        alignSelf: "start",
        marginBottom: theme.spacing(1),
    },
}));

const AccountLoginForm = ({ user, onUsernameSubmit, onPasswordSubmit, onActiveStatusSubmit, userProfileId, onUserUpdated }) => {
    const classes = useStyles();
    const [showUpdateUsernameDialog, setShowUpdateUsernameDialog] = React.useState(false);
    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = React.useState(false);
    const [showActiveStatusDialog, setShowActiveStatusDialog] = React.useState(false);
    const [showCreateLoginDialog, setShowCreateLoginDialog] = React.useState(false);
    const [suggestedUserName, setSuggestedUserName] = React.useState("");
    const { ajaxErrorWrapper } = useAjaxStatus();
    const handleCloseUsernameDialog = () => setShowUpdateUsernameDialog(false);
    const handleClosePasswordDialog = () => setShowUpdatePasswordDialog(false);
    const handleCloseActiveStatusDialog = () => setShowActiveStatusDialog(false);

    const handlePasswordValidate = ({ password, confirmPassword }) => {
        if (password && confirmPassword && password !== confirmPassword) {
            return { confirmPassword: "Passwords do not match" };
        }
    };

    const handleUsernameValidate = async ({ username }) => {
        if (await (await checkUsernameExists(username)).data.result) {
            return { username: "Username in use" };
        }
    };
    const resendEmail = async () => {
        await resendEmailCode({ id: user.userId });

    }
    const resendText = async () => {
        await resendTextCode({ id: user.userId });

    }
    const handleUsernameSubmit = (values) => {
        onUsernameSubmit(values);
        handleCloseUsernameDialog();
    };
    const handleCreateLoginSubmit = async (values) => {
        setShowCreateLoginDialog(false);
        //console.log(values);
        try {
            const inp = { userProfileId: userProfileId, username: values.username };
            const res = await createUserForProfile(inp);
            //console.log(res.data.result);
            await onUserUpdated();
            toast.success("Password updated successfully");
        } catch (error) {
            ajaxErrorWrapper(error);
        }

       
        

    }
    const handleGenerateLoginAccount = async () => {
        const sug = (await getUserNameSuggestion(userProfileId)).data.result;
        setSuggestedUserName(sug);
        setShowCreateLoginDialog(true);
    }
    const handlePasswordSubmit = (values) => {
        handleClosePasswordDialog();
        onPasswordSubmit(values);
    };

    const handleActiveStatusSubmit = (values) => {
        handleCloseActiveStatusDialog();
        onActiveStatusSubmit(values);
    };

    const handleCloseUserNameDialog = () => {
        setShowCreateLoginDialog(false);
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
            <Typography className={classes.accountLoginTitle} variant="h5" color="secondary" gutterBottom>
                ACCOUNT LOGIN
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" color={!!user ? "textPrimary" : "textSecondary"}>
                        <strong>Username:</strong> {user?.userName}
                    </Typography>
                    <IconButton color="primary" aria-label="edit-username" onClick={() => setShowUpdateUsernameDialog(true)} disabled={!user}>
                        <EditIcon color="primary" />
                    </IconButton>
                </Box>
                <Button className={classes.actionBtn} variant="contained" color="primary" onClick={() => setShowUpdatePasswordDialog(true)} disabled={!user}>
                    Update password
                </Button>
                <Button className={classes.actionBtn} variant="outlined" color="primary" disabled={!user} onClick={() => resendEmail()} >
                    Resend Email Verification Code
                </Button>
                <Button className={classes.actionBtn} variant="outlined" color="primary" disabled={!user} onClick={() => resendText()}  >
                    Resend Text Verification Code
                </Button>
                <Button className={classes.actionBtn} variant="contained" color="primary" onClick={() => setShowActiveStatusDialog(true)} disabled={!user}>
                    Update active status
                </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} >
                    <Box display="flex" flexDirection="column">
                    <Field component={FinalFormCheckbox} name="isLockoutEnabled" label="Locked Out" disabled={!user} />
                        <Button className={classes.actionBtn} variant="contained" color="primary" onClick={() => handleGenerateLoginAccount()} disabled={user}>
                        Create Login Account
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={showUpdateUsernameDialog} aria-labelledby="username-form-dialog-title" onClose={handleCloseUsernameDialog}>
                <Form onSubmit={handleUsernameSubmit} initialValues={{ username: user?.userName }} validate={handleUsernameValidate}>
                    {({ handleSubmit: handleUsernameSubmit }) => (
                        <form onSubmit={handleUsernameSubmit} noValidate>
                            <DialogTitle id="username-form-dialog-title">Update username</DialogTitle>
                            <DialogContent>
                                <Field
                                    component={FinalFormTextField}
                                    name="username"
                                    label="Username"
                                    placeholder="Username"
                                    margin="dense"
                                    required
                                    validate={composeValidators(required, minLength(6), maxLength(25), (value) => {
                                        if (user.userName?.trim() === value?.trim()) {
                                            return "Username is the same";
                                        }
                                        if (value?.trim()?.length === 0) {
                                            return "This field is required";
                                        }
                                    })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseUsernameDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
            <Dialog open={showUpdatePasswordDialog} aria-labelledby="password-form-dialog-title" onClose={handleClosePasswordDialog}>
                <Form onSubmit={handlePasswordSubmit} validate={handlePasswordValidate}>
                    {({ handleSubmit: handlePasswordSubmit }) => (
                        <form onSubmit={handlePasswordSubmit} noValidate>
                            <DialogTitle id="password-form-dialog-title">Update password</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogContent }}>
                                <Field
                                    component={FinalFormTextField}
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    margin="dense"
                                    password
                                    required
                                    validate={required}
                                />
                                <Field
                                    component={FinalFormTextField}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    margin="dense"
                                    password
                                    required
                                    validate={required}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClosePasswordDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
            <Dialog open={showActiveStatusDialog} aria-labelledby="active-status-form-dialog-title" onClose={handleCloseActiveStatusDialog}>
                <Form onSubmit={handleActiveStatusSubmit} initialValues={{ isActive: user?.isActive }}>
                    {({ handleSubmit: handleActiveStatusSubmit }) => (
                        <form onSubmit={handleActiveStatusSubmit} noValidate>
                            <DialogTitle id="activeStatus-form-dialog-title">Update Active Status</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogContent }}>
                                <Field component={FinalFormCheckbox} name="isActive" label="Active Status" />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseActiveStatusDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
            <Dialog open={showCreateLoginDialog} aria-labelledby="password-form-dialog-title" onClose={handleCloseUserNameDialog}>
                <Form onSubmit={handleCreateLoginSubmit} validate={handleUsernameValidate} initialValues={{ username: suggestedUserName }}>
                    {({ handleSubmit: handleCreateLoginSubmit }) => (
                        <form onSubmit={handleCreateLoginSubmit} >
                            <DialogTitle id="password-form-dialog-title">Choose Username</DialogTitle>
                            <DialogContent classes={{ root: classes.dialogContent }}>
                                <Field
                                    component={FinalFormTextField}
                                    name="username"
                                    label="Username"
                                    placeholder="Username"
                                    margin="dense"
                                    required
                                    value={suggestedUserName}
                                />
                               
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseUserNameDialog} color="secondary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
        </>
    );
};

export default AccountLoginForm;
