import React, { Component, Fragment } from "react";
import "./index.css";
import Modal from "react-modal";
import { DateRangePicker, Calendar } from 'react-date-range';
import format from 'date-fns/format';
import Button from '@mui/material/Button';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 


function formatDate(dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}


class DateRange extends Component {
    userDropdown = null;

    constructor(props) {
        super(props);

        this.state = {
            beginDate: new Date(),
            endDate: new Date(), 
            showCustom: false,

        };
    }

    componentDidMount() {
        //console.log(this.props);
        //console.log(this.state);
    }
    componentDidUpdate(prevProps, prevState) {
        //console.log(prevProps);
        //console.log(this);

    }
    submitRange = () => {
        //console.log(this.state);
        const formattedBeginDate = formatDate(this.state.beginDate);
        const formattedEndDate = formatDate(this.state.endDate);
        const input = {
            label: formattedBeginDate + " - " + formattedEndDate,
            beginDate: formattedBeginDate,
            endDate: formattedEndDate
        }

       this.props.onDateRangeSelect(input);
        this.toggleCustom();
    }
    toggleCustom = () => {
        //console.log(this.state);
        this.setState({ showCustom: !this.state.showCustom });
    }

    setBegin = (item) => {
        this.setState({ beginDate: item });
    }
    setEnd = (item) => {
        this.setState({ endDate: item });
    }
    render() {
        //console.log(this.state);
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: 0, // Remove padding inside the modal content
                overflow: 'hidden', // Prevent scrollbars if the content is too big
                // Set a max height and width if necessary
                maxHeight: '90vh',
                maxWidth: '90vw',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)' // Darken the background behind the modal
            }
        };
        return(
        <Fragment>

                <div role="button" onClick={ this.toggleCustom} onKeyPress={this.toggleCustom} tabIndex="0" className="rangeSelector">
                Custom Range
                </div>


                <Modal isOpen={this.state.showCustom} ariaHideApp={false} style={customStyles}>
                    <div style={{ display: 'flex', paddingTop: '20px' }}> {/* Add padding to the top */}
                        <Calendar onChange={item => this.setBegin(item)}
                        date={this.state.beginDate} />
                    <Calendar onChange={item => this.setEnd(item)}
                        date={this.state.endDate} /> </div>
                    <br />
                    <div className="actionButtonWrapper">
                    <Button onClick={this.submitRange} className="fieldSelectorButton">Save</Button> &nbsp; <Button onClick={this.toggleCustom} className="fieldSelectorButton">Cancel</Button>
                    </div>
                </Modal>
               
            </Fragment>
        );
    }

}
export default DateRange;