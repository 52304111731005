import React from "react";
import { FormattedMessage } from "react-intl";
import ContactInfo from "../common/contactInfo";

const Instructions = ({ isDesktop }) => {
    return (
        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
            <div>
                <FormattedMessage id="app.instruction" tagName="h5" />
                <FormattedMessage id="contracts.instructions" />
            </div>
            <ContactInfo />
        </div>
    );
};

export default Instructions;
