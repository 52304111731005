import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

import './Checkbox.css';

const Checkbox = ({ isChecked, onClick, showUnchecked }) => {
    const showCheck = isChecked ? true : false;
    const clickEvent = onClick ? onClick : () => {};
    const clsName = onClick ? 'chkbox chkbox-pointer' : 'chkbox';
    return (
        <button className={clsName} onClick={clickEvent}>
            {showCheck ? <FontAwesome name="check" color="white" /> : null}
            {!showCheck && showUnchecked ? <div className="chkbox-unchecked" /> : null}
        </button>
    );
};

Checkbox.propTypes = {
    isChecked: PropTypes.bool,
    onClick: PropTypes.func,
    showUnchecked: PropTypes.bool
};

export default Checkbox;
