import { Race } from "../logic/enums";

export function getRaceValue(values) {
    let raceValue = 0;

    if (values.raceWhite) {
        raceValue |= Race.White;
    }

    if (values.raceBlack) {
        raceValue |= Race.Black;
    }

    if (values.raceAsian) {
        raceValue |= Race.Asian;
    }

    if (values.raceIndian) {
        raceValue |= Race.Indian;
    }

    if (values.raceHawaiian) {
        raceValue |= Race.Hawaiian;
    }

    return raceValue;
}
