import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import { toast } from "react-toastify";
import { states } from "../../../support/states";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { required, renderField, renderTextArea, renderSelectList, phone, zip } from "../../../support/forms";
import Button from "../../../support/components/Button";
import normalizeZip from "../../../support/logic/normalizeZip";
import normalizePhone from "../../../support/logic/normalizePhone";

import * as apis from "./apis";
import "./Edit.css";
import withHistory from "../../../support/hoc/withHistory";

const FORM_NAME = "institution";
const TYPES = [
    { label: "Two Year", value: "1" },
    { label: "Four Year", value: "2" },
    { label: "Trade School", value: "3" },
];
const TERMS = [
    { label: "Trimester", value: "1" },
    { label: "Semester", value: "2" },
];
const FUNDTYPES = [
    { label: "Public", value: "0" },
    { label: "Private", value: "1" },
];
const Checkbox = (props) => {
    return <input {...props.input} type="checkbox" checked={props.input.value} />;
};

export const fedCodValid = (value) => {
    const pattern = /[a-zA-Z0-9]{6}/;
    const message = "Please enter a valid Federal School Code format of 6 alphanumeric characters";
    return !value || (value && typeof value === "string" && pattern.test(value) && value.length === 6) ? undefined : (
        <span style={{ color: "red" }} title={message}>
            {message}
        </span>
    );
};

export const urlValid = (value) => {
    const pattern = /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/;
    const message = "Please enter a valid Website Address format of www.google.com or google.com";
    return !value || (value && typeof value === "string" && pattern.test(value)) ? undefined : (
        <span style={{ color: "red" }} title={message}>
            {message}
        </span>
    );
};
class Edit extends Component {
    static permission = "Admin.Institutions";
    constructor(props) {
        super(props);
        this.state = {
            showComplete: false,
            ipeds: [],
        };
    }
    componentDidMount = () => {
        const { match } = this.props;
        const { id } = match.params;
        this._isMounted = true;
        if (id === "0") {
            this.props.initialize({
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "RI",
                zipCode: "",
                phone: "",
                fax: "",
                type: "2",
                termStructure: "1",
                note: "",
                fedCode: "",
                paymentAddress1: "",
                paymentAddress2: "",
                paymentCity: "",
                paymentState: "RI",
                paymentZipCode: "",
                isActive: true,
            });
            return;
        }

        this.props.ajaxStatusLoading();
        apis.getIpeds()
            .then((response) => {
                const ipeds = response.data.result;
                
                    this.setState({ ipeds });
                
                
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });
        apis.getById(id)
            .then((response) => {
                const institution = response.data.result;
                if (this._isMounted) {
                    institution.phone = normalizePhone(institution.phone, institution.phone); // Preformatting the phone number
                    institution.fax = normalizePhone(institution.fax, institution.fax); // Preformatting the phone number
                    this.props.initialize(institution);
                }
                this.props.ajaxStatusComplete();
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    onSave = (values) => {
        const { match } = this.props;
        const { id } = match.params;
        values.id = id;
        values.type = values.type.toString();
        values.termStructure = values.termStructure.toString();
        let method = "create";

        if (id !== "0") {
            method = "update";
        } else {
            delete values.id;
        }

        this.props.ajaxStatusLoading();
        apis[method](values)
            .then((response) => {
                this.props.ajaxStatusComplete();
                this.props.onSaveComplete();
                this.setState({
                    showComplete: true,
                });
                toast.success("Save Complete");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.error) {
                    this.props.ajaxStatusError(error.response.data.error.message);
                    return;
                }
                this.props.ajaxStatusError(error.message);
            });
    };
    onCancel = (e) => {
        e.preventDefault();
        this.props.reset();
        this.props.history.goBack();
    };

    render() {
        if (this.state.showComplete) return <Redirect to="/admin/institutions" />;
        const { readonly, match } = this.props;
        const { id } = match.params;
        const label = id === "0" ? "Add" : "Edit";

        return (
            <div className="admin-institution-edit">
                <h1>
                    {label}
                    &nbsp;Institution
                </h1>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Field
                        name="name"
                        component={renderField}
                        label="School Name"
                        validate={[required]}
                        maxlength="50"
                        className="admin-institution-edit-input is-active-field"
                        disabled={readonly}
                    />
                    <div className="admin-institution-edit-input is-active-field">
                        <span className="form-label" title="Active">
                            Active?
                        </span>
                        <Field name="isActive" component={Checkbox} label="Active" disabled={readonly} defaultValue={true} />
                    </div>
                </div>
                <div className="admin-institution-edit-row">
                    <span className="admin-institution-edit-address-label">Address</span>
                    <span className="admin-institution-edit-address-label">Payment Address</span>
                </div>

                <div className="admin-institution-edit-row">
                    <Field name="address1" component={renderField} label="Address 1" validate={[required]} maxlength="100" disabled={readonly} />
                    <Field name="paymentAddress1" component={renderField} label=" Address 1" validate={[required]} maxlength="100" disabled={readonly} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field name="address2" component={renderField} label="Address 2" maxlength="100" disabled={readonly} />
                    <Field name="paymentAddress2" component={renderField} label="Address 2 " maxlength="100" disabled={readonly} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field name="city" component={renderField} label="City" validate={[required]} maxlength="50" disabled={readonly} />
                    <Field name="paymentCity" component={renderField} label="City" validate={[required]} maxlength="50" disabled={readonly} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field name="state" component={renderSelectList} label="State" data={states} validate={[required]} disabled={readonly} />
                    <Field name="paymentState" component={renderSelectList} label="State" data={states} validate={[required]} disabled={readonly} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field
                        name="zipCode"
                        component={renderField}
                        label="Zip Code"
                        validate={[required, zip]}
                        maxlength="10"
                        disabled={readonly}
                        normalize={normalizeZip}
                    />
                    <Field
                        name="paymentZipCode"
                        component={renderField}
                        label="Zip Code"
                        validate={[required, zip]}
                        maxlength="10"
                        disabled={readonly}
                        normalize={normalizeZip}
                    />
                </div>
                <div className="admin-institution-edit-row admin-institution-edit-line">
                    <Field
                        name="phone"
                        component={renderField}
                        label="Main Phone Number"
                        validate={[required, phone]}
                        disabled={readonly}
                        normalize={normalizePhone}
                    />
                    <Field name="fax" component={renderField} label="Fax Number" validate={[phone]} disabled={readonly} normalize={normalizePhone} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field name="type" component={renderSelectList} label="School Type" data={TYPES} validate={[required]} disabled={readonly} />
                    <Field name="termStructure" component={renderSelectList} label="Term Structure" data={TERMS} validate={[required]} disabled={readonly} />
                </div>
                <div className="admin-institution-edit-row">
                    <Field
                        name="fedCode"
                        component={renderField}
                        label="Federal School Code"
                        disabled={readonly}
                        validate={[required, fedCodValid]}
                        maxlength="6"
                    />
                    <Field name="url" component={renderField} label="Website Address" maxlength="200" disabled={readonly} validate={[urlValid]} />

                    
                   
                </div>
                <span className="form-label" title="Active">
                    Has Trade Programs?
                </span>
                <Field name="hasTradePrograms" component={Checkbox} label="Has Trade Programs" disabled={readonly} defaultValue={true} />
                
                <div className="admin-institution-edit-row">
                    <div>
                <span class="form-label" title="IPED Pairing">IPED Pairing</span>
                <Field name="ipedNo" label="IPED Pairing" component="select" disabled={readonly}>
                    <option value="">Select an option</option>
                    {this.state.ipeds.map((iped) => {
                        return (
                            <option key={iped.ipedNo} value={iped.ipedNo}>
                                {iped.name}
                            </option>
                        );

                    })}

                </Field>
                    </div>
                       <div>
                    <Field name="fundType" component={renderSelectList} label="School Fund Type" data={FUNDTYPES} validate={[required]} disabled={readonly} />
                    </div>
                       
                </div>               

                <Field name="note" component={renderTextArea} label="Note" maxlength="500" rows={4} disabled={readonly} />

                <div className="admin-institution-edit-buttons">
                    <Button onClick={this.props.handleSubmit(this.onSave)} id="Save" defaultMessage="Save" disabled={readonly} />

                    <a href="/admin/institutions" onClick={(e) => this.onCancel(e)}>
                        Cancel
                    </a>
                </div>
            </div>
        );
    }
}

Edit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    onSaveComplete: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
};

Edit = reduxForm({
    form: FORM_NAME,
})(Edit);

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset(FORM_NAME)),
    };
};

Edit = connect(null, mapDispatchToProps)(Edit);

Edit = withRouter(Edit);
Edit = withAjaxStatus(Edit);
Edit = withHistory(Edit);

export default Edit;
