import React, { Component } from "react";
import moment from "moment";
import FileSaver from "file-saver";
import ReactTable from "react-table";
import b64toBlob from "../../../support/logic/b64toBlob";
import InstitutionSearch from "../../programs/components/institutionSearch";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import withUser from "../../../support/hoc/withUser";
import { formatCurrencyNoDecimal } from "../../../support/numberSupport";
import { certifiedSortMethod } from "../../logic";

import * as apis from "../../apis";
import * as TransactionApi from "../../../api/scholarshipTransactions";

import "./index.css";

const getTierValue = value => {
  switch (value) {
    case "B":
      return 1;
    case "S":
      return 2;
    case "G":
      return 3;
    default:
      return 4;
  }
};

const sortTier = (a, b) => {
  const aValue = getTierValue(a);
  const bValue = getTierValue(b);
  if (aValue === bValue) return 0;
  if (aValue > bValue) return 1;
  if (aValue < bValue) return -1;
};

class Certification extends Component {
  state = {
    institutions: [],
    institution: null,
    certList: []
  };

  static permission = "Scholarship.Reports";

  componentDidMount() {
    this.loadInstitutions();
    this.loadCertificationList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scholarship !== prevProps.scholarship) {
      this.loadCertificationList();
    }
  }

  loadInstitutions = async () => {
    try {
      this.props.ajaxStatusLoading();
      var institutions = (await apis.getInstitutionByUser()).data.result;
      this.setState({ institutions });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error loading institutions list");
    }
  };

  loadCertificationList = async () => {
    if (!this.props.scholarship) {
      return;
    }

    try {
      this.props.ajaxStatusLoading();
      var certList = (
        await TransactionApi.getCertificationList(
          this.props.scholarship.id,
          this.state.institution && this.state.institution !== null ? this.state.institution.id : null
        )
      ).data.result;
      this.setState({ certList });
      this.props.ajaxStatusComplete();
    } catch (err) {
      console.error(err);
      this.props.ajaxStatusError("Error loading certification list");
    }
  };

  onInstitutionClear = institution => {
    this.setState({ institution }, () => this.loadCertificationList());
  };

  onInstitutionSelected = institution => {
    this.setState({ institution }, () => this.loadCertificationList());
  };

  onViewAllClick = () => {
    if (this.searchRef) this.searchRef.onClear();
    this.setState({ institution: null }, () => this.loadCertificationList());
  };
  onExportToExcel = async () => {
    const institutionId = this.state.institution ? this.state.institution.id : null;
    try {
      this.props.ajaxStatusLoading();
      let response = (await TransactionApi.getCertificationExport(this.props.scholarship.id, institutionId)).data.result;
      let fileName = "certification.xlsx";
      this.props.ajaxStatusComplete();
      const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      FileSaver.saveAs(blob, fileName);
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  };
  setSearchRef = element => {
    this.searchRef = element;
  };

  render() {
    const { institution, institutions, certList } = this.state;
    const isInstitutionUser = this.props.user && this.props.user.userProfile.userType === 5;

    return (
      <div className="certification-list">
        {!isInstitutionUser && (
          <React.Fragment>
            <div className="button-area">
              <button className={`btn ${institution == null ? "" : "inactive"}`} onClick={this.onViewAllClick}>
                View All
              </button>
            </div>
            <InstitutionSearch
              ref={this.setSearchRef}
              institutions={institutions}
              onInstitutionSelected={this.onInstitutionSelected}
              onInstitutionClear={this.onInstitutionClear}
            />
          </React.Fragment>
        )}
        <div className="header-area-top">
          <button className="btn" onClick={this.onExportToExcel}>
            Export To Excel
          </button>
        </div>
        <div className="header-area">
          <h2>Certified Students ({certList.length})</h2>
          <div className="report-date">As of {moment().format("M/D/YY")}</div>
        </div>
        <ReactTable
          className="financials-table"
          data={certList}
          minRows={0}
          showPagination
          defaultPageSize={10}
          columns={[
            {
              Header: "Last Name",
              accessor: "lastName",
              maxWidth: 150
            },
            {
              Header: "First Name",
              accessor: "firstName",
              maxWidth: 150
            },
            {
              Header: "Social Security/Alien ID",
              accessor: "ssn",
              maxWidth: 100
            },
            {
              Header: "Crusader ID",
              accessor: "crusaderId",
              maxWidth: 100
            },
            {
              Header: "Institution",
              accessor: "institution"
            },
            {
              Header: "Term",
              accessor: "term"
            },
            {
              Header: "Status",
              accessor: "status",
              maxWidth: 100,
              sortMethod: certifiedSortMethod
            },
            {
              Header: "Eligibility",
              accessor: "eligible",
              maxWidth: 100
            },
            {
              Header: "Reason",
              accessor: "reason"
            },
            {
              Header: "Tier",
              accessor: "tier",
              maxWidth: 50,
              sortMethod: sortTier
            },
            {
              Header: "Donated",
              accessor: "donated",
              maxWidth: 100,
              Cell: props => (props.value ? formatCurrencyNoDecimal(props.value) : "")
            },
            {
              Header: "Cash",
              accessor: "cash",
              maxWidth: 100,
              Cell: props => (props.value ? formatCurrencyNoDecimal(props.value) : "")
            }
          ]}
        />
      </div>
    );
  }
}

Certification = withAjaxStatus(Certification);
Certification = withUser(Certification);

export default Certification;
