import React, { Component } from "react";

import { Form, Field } from "react-final-form";

import { FinalFormCheckbox, FinalFormTextField, FinalFormRadioGroup, FinalFormKeyboardTimePicker } from "../../../common/formWrappers";
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";

import { required, renderField, renderRadioField, renderSelectList, email, phone, zip } from "../../../support/forms";
import Link from "@material-ui/core/Link";
import Button from "../../../support/components/Button";
import TimePicker from 'react-time-picker';
import * as api from './api';
import normalizePhone from "../../../support/logic/normalizePhone";
import moment from 'moment-timezone';
import { Gender } from "../../../admissions/logic/enums";
import enumHelper from "../../../support/logic/enumHelper";
import { languages } from "../../../support/languages";
import { loadCities } from "../../../redux/codeTables";

import { bindActionCreators } from "redux";
import { getList as getCities } from "../../../api/city";
import { states } from "../../../support/states";
import './index.css';
import 'react-time-picker/dist/TimePicker.css';

class UserProfile extends Component {
   

    state = {
        activeUser: [],
        cities: [],
        states: []
    };
    componentDidMount = async () => {

        const convertISOToTimePickerFormat = (isoValue) => {
            if (!isoValue) return '';

            // Parse the ISO string to a moment object in UTC
            const momentDate = moment.utc(isoValue);

            // Convert the moment object to Eastern Time
            const momentDateEt = momentDate.tz('America/New_York');

            // Format the date to 24-hour time format
            return momentDateEt.format('HH:mm');
        };



        var user = await api.getMyProfile();
        // Convert the bestTimeCallStart and bestTimeCallEnd to the TimePicker format
        if (user.data.result.bestTimeCallStart) {
            user.data.result.bestTimeCallStart = convertISOToTimePickerFormat(user.data.result.bestTimeCallStart);
        }
        if (user.data.result.bestTimeCallEnd) {
            user.data.result.bestTimeCallEnd = convertISOToTimePickerFormat(user.data.result.bestTimeCallEnd);
        }

        var cities = await getCities();
        //console.log(user.data.result);
        this.setState({ activeUser: user.data.result, cities: cities.data.result, states: states });
        
    }
    handleSubmit = async (values, form) => {

        const appendDateToTime = (timeValue) => {
            const isoDateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})$/;
            const simpleTimePattern = /^(\d{2}:\d{2})$/;

            if (isoDateTimePattern.test(timeValue)) {
                // If the value is already in ISO 8601 format, return it as is
                return timeValue;
            } else if (simpleTimePattern.test(timeValue)) {
                // If the value is in simple time format, append today's date in EST and convert to UTC
                const timeWithTodayDateEst = moment.tz(`${moment().format('YYYY-MM-DD')}T${timeValue}:00`, 'America/New_York');
                return timeWithTodayDateEst.utc().format(); // Convert to UTC and format to ISO 8601 string
            } else {
                // If the value does not match any expected format, return it as is
                return timeValue;
            }
        };

        if (values.bestTimeCallStart) {
            values.bestTimeCallStart = appendDateToTime(values.bestTimeCallStart);
        }
        if (values.bestTimeCallEnd) {
            values.bestTimeCallEnd = appendDateToTime(values.bestTimeCallEnd);
        }

        // Rest of your existing code...
        if (values.cityId) {
            values.city = this.state.cities.find(city => city.id === values.cityId).name;
        }
        await api.updateMyProfile(values);
        this.props.onClose();
    }

    handleCityChange = (event) => {
        
        // Find the selected city using the event.target.value which is cityId
        const selectedCityId = event.target.value;
        const selectedCity = this.state.cities.find(city => city.id === selectedCityId);

        // Dispatch the change action to update the "city" field
        if (selectedCity) {

            var selectedUser = this.state.activeUser;
            selectedUser.city = selectedCity;
            this.setState({ activeUser: selectedUser });
            return true;
        }
    };
    genderOptions = enumHelper.getSelectOptionsList(Gender);
    render() {
        const verified = true;
        
        return (
            <Form onSubmit={this.handleSubmit} initialValues={this.state.activeUser}>
                {({ handleSubmit, values, dirty, errors }) => {
                    
                    return (
                        <form onSubmit={handleSubmit} FormValues={this.state.activeUser} noValidate className="test">

                            <div className="admin-user-edit">
                                
                                <FormattedMessage id="events.profile" defaultMessage="[events.profile]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage }</span> )} />
                                
                {/* {isParent && <div className="parent-note">For parent users, only password edits will be saved.</div>} */}
                                <FormattedMessage
                                    id="app.firstName"
                                    defaultMessage="[First Name]"
                                    children={(formattedMessage) => (
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={renderField}
                                            label={formattedMessage}
                                           
                                            maxlength="50"
                                            
                                        />
                                    )}
                                />
                                <FormattedMessage
                                    id="app.middleName"
                                    defaultMessage="[Middle Name]"
                                    children={(formattedMessage) => (
                                        <Field name="middleName" type="text" component={renderField} label={formattedMessage}  />
                                    )}
                                />
                                <FormattedMessage
                                    id="app.lastName"
                                    defaultMessage="[Last Name]"
                                    children={(formattedMessage) => (
                                        <Field name="lastName" type="text" component={renderField} label={formattedMessage} />
                                    )}
                                />
                                
                                <FormattedMessage id="app.gender" defaultMessage="[app.gender]" tagName="span" />
                                <Field
                                    row
                                    name="gender"
                                    fullWidth
                                    format={(value) => {
                                        return value ? value.toString() : "0";
                                    }}
                                    parse={(value) => {
                                        return parseInt(value, 0);
                                    }}
                                    component={FinalFormRadioGroup}
                                    choices={this.genderOptions}
                                    required
                                   
                                />
                                {values.gender === Gender.Other && (
                                  <div>  <Field
                                        component={renderField}
                                        name="genderDescription"
                                        label="Description"
                                        placeholder="Description"
                                        margin="dense"
                                        
                                    /><br/></div>
                                )}
                    
                <span className="FormHeading">Address</span>
                                <FormattedMessage
                                    id="app.address1"
                                    defaultMessage="[Address Line 1]"
                                    children={(formattedMessage) => (
                                        <Field
                                            name="address1"
                                            type="text"
                                            component={renderField}
                                            label={formattedMessage}
                                            
                                            maxlength="100"
                                            
                                        />
                                    )}
                                />
                                <FormattedMessage
                                    id="app.address2"
                                    defaultMessage="[Address Line 2]"
                                    children={(formattedMessage) => (
                                        <Field name="address2" type="text" component={renderField} label={formattedMessage} maxlength="100"  />
                                    )}
                                />

                                {values.state !== 'RI' && (
                                <FormattedMessage
                                        id="app.city"
                                        defaultMessage="[City]"
                                        children={(formattedMessage) => (
                                            <Field name="city" component={renderField} label={formattedMessage} />
                                        )}
                                    />
                                
                                )}
                                {values.state === 'RI' && (
                                    <React.Fragment>
                                    <FormattedMessage
                                        id="app.city"
                                        defaultMessage="[City]"
                                        children={(formattedMessage) => (
                                            <span className={"form-label"}>{formattedMessage}</span>
                                            )}
                                    />
                                            <Field
                                                name="cityId"
                                                component="select"
                                                label="City"
                                            
                                            >
                                                {this.state.cities.map((option) => {
                                                    return (
                                                        <option key={option.cityCode} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    );

                                                })}
                                        </Field>
                                        <Field name="city" component="input" type="hidden" />
                                        </React.Fragment>
                                        
                                )}
                                <span className={"form-label"}>State</span>
                                <Field name="state" label="State" component="select" >

                                    {this.state.states.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        );

                                    })}

                                </Field>




                                <Field name="zipCode" component={renderField} label="Zip Code" />
                                <br />
                                <span className="FormHeading">Authentication</span>

                                <Field
                                    name="phoneNumber"
                                    component={renderField}
                                    label="Mobile Phone"
                                    placeholder="(xxx) xxx-xxxx"
                                    normalize={normalizePhone}

                                    disabled={false}
                                />

                                <Field name="userName" component={renderField} label="User Name" />
                                <Field name="email" component={renderField} label="Email" />
                                <span className="FormHeading">Password Reset</span>
                    <React.Fragment>
                        <div className="user-row">
                            <Field name="password" disabled={!verified} component={renderField} type="password" label="Password" />
                            <div />
                            <Field
                                name="confirmPassword"
                                disabled={!verified}
                                component={renderField}
                                type="password"
                                
                                label="Confirm Password"
                            />
                        </div>
                        <div className="password-help">
                            Leave blank to keep current password. Email must be verified first.
                            <br />
                            Passwords must be at least 8 characters long, and must contain a lowercase and uppercase letter, a number, and a special character
                            ($&amp;*@! etc)
                        </div>
                                </React.Fragment>
                                <br />
                                <span className="FormHeading">Medical</span>
                                <Field name="allergies" component={renderField} label="Allergies" />
                                <Field name="medications" component={renderField} label="Medications" />
                                <Field name="medicalConditions" component={renderField} label="Medical Conditions" />
                                <Field name="dietaryRestrictions" component={renderField} label="Dietary Restrictions" />
                                <Field name="specialAccommodations" component={renderField} label="Special Accommodations" />
                                <br />
                                
                                <FormattedMessage id="events.contactPreferences.name" defaultMessage="[events.contactPreferences.name]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage}</span>)} />
                                
                                
                                <FormattedMessage
                                    id="profile.languagePreference"
                                    defaultMessage="[All communication will be sent in the following language]"
                                    children={(formattedMessage) => (
                                        <div>
                                            <span class="form-label" title="Language Preference">{formattedMessage}</span>
                                        <Field
                                            name="primaryLocale"
                                            component="select"
                                            label={formattedMessage}
                                            data={languages}
                                            className="medium-select"
                                            
                                            
                                        >
                                                <FormattedMessage
                                                    id="login.english"
                                                    defaultMessage="English"
                                                    children={(formattedMessage) => <option value="en">{formattedMessage}</option>}
                                                />
                                                <FormattedMessage
                                                    id="login.spanish"
                                                    defaultMessage="Spanish"
                                                    children={(formattedMessage) => <option value="es-MX">{formattedMessage}</option>}
                                                />
                                            </Field>
                                        </div>
                                    )}
                                />
                                
                                <FormattedMessage id="profile.communicationPreference" defaultMessage="[profile.communicationPreference]" children={(formattedMessage) => (<span class="form-label" title="Language Preference">{formattedMessage}</span>)} />
                                <Field name="communicationPreference" label="Contact Preference" component="select" >
                                    <option value="">Select an option</option>
                                    
                                    <option key={'EMAIL'} value={'EMAIL'}>
                                                EMAIL
                                    </option>
                                    <option key={'TEXT'} value={'TEXT'}>
                                        TEXT
                                    </option>
                                    <option key={'TEXT AND EMAIL'} value={'TEXT AND EMAIL'}>
                                        TEXT AND EMAIL
                                    </option>
                                    

                                </Field>
                                <FormattedMessage id="parent.bestTime" defaultMessage="[parent.bestTime]" children={(formattedMessage) => (<span class="form-label" title="Best Time to Call">{formattedMessage}</span>)} />
                                <div className="bestTimeToCallWrapper">
                                    <Field name="bestTimeCallStart" className="profileTimePicker">
                                        {props => (
                                            <div className="profileTimePicker">
                                                <TimePicker name="bestTimeCallStart"  value={props.input.value} onChange={props.input.onChange} />

                                            </div>
                                        )}
                                    </Field>

                                    <span class="form-label" title="-">&nbsp; - &nbsp;</span>
                                    <Field name="bestTimeCallEnd" className="profileTimePicker">
                                        {props => (
                                            <div className="profileTimePicker">
                                                <TimePicker name="bestTimeCallEnd" value={props.input.value} onChange={props.input.onChange} />

                                            </div>
                                        )}
                                    </Field>

                                </div>
                                <br/>
                                <div className="admin-user-edit-buttons">

                                    <FormattedMessage
                                        id="Save"
                                        defaultMessage="[Save]"
                                        children={(formattedMessage) => (
                                            <Button
                                                className="margin-right"
                                                onClick={handleSubmit}
                                                id="Save"
                                                defaultMessage={formattedMessage}
                                                
                                            />
                                        )}
                                    />


                    
                                    <Link id={'Cancel'} onClick={(e) => this.props.onClose(e)}  >
                                        Cancel
                                    </Link>
                                    
                       
                    
                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
            );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        
        loadCities: bindActionCreators(loadCities, dispatch),

    };
};

export default UserProfile;