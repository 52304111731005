import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import * as RecruitmentApi from "../../../../api/recruitment";
import useAjaxStatus from "../../../../common/useAjaxStatus";

const useRecruitmentCycle = () => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [alertData, setAlertData] = useState({ title: "", message: "", show: false });
    const { ajaxLoad, ajaxComplete, ajaxError } = useAjaxStatus();

    const getLatestDay = (items) => {
        if (items.length === 0) {
            return null;
        }

        var latest = null;
        for (let i = 0; i < items.length; i++) {
            var day = moment(items[i].endDate);
            if (latest === null || latest.isBefore(day)) {
                latest = day;
            }
        }

        return latest;
    };

    const itemSort = (a, b) => {
        return -moment(a.endDate).diff(moment(b.endDate));
    };

    const load = useCallback(async () => {
        try {
            ajaxLoad();
            const result = (await RecruitmentApi.getAll()).data.result.items;
            result.sort(itemSort);
            setData(
                result.map((d) => ({
                    ...d,
                    startDate: moment(d.startDate),
                    endDate: moment(d.endDate),
                }))
            );
            ajaxComplete();
        } catch (error) {
            ajaxError(error);
        }
    }, [ajaxLoad, ajaxComplete, ajaxError]);

    const onSave = async (values) => {
        try {
            ajaxLoad();

            var saveData = {
                ...values,
                startDate: moment(values.startDate).utc().format("MM/DD/YYYY"),
                endDate: moment(values.endDate).utc().format("MM/DD/YYYY"),
            };

            if (saveData.id) {
                await RecruitmentApi.update(saveData);
                toast.success("Changes saved successfully.");
            } else {
                await RecruitmentApi.create(saveData);
                toast.success("Recruitment cycle successfully created.");
            }
            ajaxComplete();
            onCloseEdit();
            load();
        } catch (error) {
            if (error?.response?.data?.error) {
                ajaxError(error.response.data.error.message);
            } else {
                ajaxError(error);
            }
        }
    };

    const onAdd = () => {
        const latestDay = getLatestDay(data);
        setSelectedItem({
            startDate: latestDay === null ? "" : latestDay.add(1, "day"),
            grade5: { isEligible: true },
            grade6: { isEligible: true },
            grade7: { isEligible: true },
            grade8: { isEligible: true },
        });
        setShowEdit(true);
    };

    const onEdit = (item) => {
        setSelectedItem(item);
        setShowEdit(true);
    };

    const onCloseEdit = () => {
        setSelectedItem(null);
        setShowEdit(false);
    };

    const onDelete = (item) => {
        setAlertData({
            itemId: item.id,
            show: true,
            title: "Confirm Deletion",
            message:
                "Are you sure you want to delete this Recruitment Cycle? Cohort numbers will not be removed from student applications created during this time and quota data will no longer be available.",
        });
    };

    const onConfirm = async () => {
        try {
            setAlertData((cur) => ({
                ...cur,
                itemId: null,
                show: false,
            }));
            ajaxLoad();
            await RecruitmentApi.remove(alertData.itemId);
            ajaxComplete();
            load();
        } catch (error) {
            ajaxError(error);
        }
    };

    const onCancel = () => {
        setAlertData((cur) => ({
            ...cur,
            itemId: null,
            show: false,
        }));
    };

    useEffect(() => {
        load();
    }, [load]);

    return { data, onSave, onAdd, onEdit, onCloseEdit, showEdit, onDelete, selectedItem, onConfirm, onCancel, alertData };
};

export default useRecruitmentCycle;
