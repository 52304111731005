import React, { Component } from "react";
import Link from "@material-ui/core/Link";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import "./index.css";
import FontAwesome from "react-fontawesome";
import AdvisorContext from "../context/context";
import { Grid, Checkbox, TextField, Button, Typography, IconButton } from '@material-ui/core';
import Modal from "react-modal";
import { Tabs, Tab } from '@mui/material';
import { advisorSearch } from "../api.js";
import ContactedGraph from "./components/contactedGraph";
import InProgramGraph from "./components/inProgramGraph";
import ObjectivesGraph from "./components/objectivesGraph";
import MilestonesGraph from "./components/milestonesGraph";



const processData = (cases) => {
    const gradeCounts = {};

    cases.forEach(caseItem => {
        if (caseItem.studentContactInfo.contacted) {
            const grade = caseItem.studentProfile.currentGrade;
            gradeCounts[grade] = (gradeCounts[grade] || 0) + 1;
        }
    });

    return Object.entries(gradeCounts).map(([grade, count]) => ({ grade, count }));
};


class AdvisorsDashboard extends Component {
    static contextType = AdvisorContext;
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false,
            searchTab: 0,
            searchStudents: [],
            searchPriParents: [],
            searchSecParents: [],
            studVis: "tabHidden",
            parVis: "tabHidden",
            searchVal: ""

        };
    }

    closeSearch = () => {
        this.setState({
            showSearch: false,
            searchTab: 0,
            searchStudents: [],
            searchPriParents: [],
            searchSecParents: [],
            studVis: "tabHidden",
            parVis: "tabHidden",
            searchVal: ""
            });
    }

    componentDidMount() {
        if (this.context.recipients.length > 0) {
            this.setState({ recCount: this.context.recipients.length });
        }

    }
    componentDidUpdate(prevProps, prevState) {

        if (this.context.recipients.length != this.state.recCount) {
            this.setState({ recCount: this.context.recipients.length });
        }

    }
    runSearch = async () => {
        const res = await advisorSearch({ text: this.state.searchVal });
        
        this.setState({ searchStudents: res.data.result.students, searchPriParents: res.data.result.parents });
    }

    updateSearch = async (event) => {
       


        this.setState({ searchVal: event.target.value.toLowerCase() });

       
    }


    setTab = (event, newVal) => {
        let studVis = "tabHidden";
        let parVis = "tabHidden";
        
        switch (newVal) {
            case 0:
                studVis = "tabVisible";
                parVis = "tabHidden";
                
                break;
            case 1:
                studVis = "tabHidden";
                parVis = "tabVisible";
                
                break;
            
            default:
                studVis = "tabVisible";
                parVis = "tabHidden";
                break;


        }

        this.setState({ searchTab: newVal, parVis: parVis, studVis: studVis });
    }

    render() {

        //console.log(this.context);
        const customStyles = {
            content: {
                top: '30px',
                left: '25%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-25%',
               
                padding: 0, // Remove padding inside the modal content
                overflow: 'auto',
                // Set a max height and width if necessary
                maxHeight: '90vh',
                maxWidth: '90vw',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)' // Darken the background behind the modal
            }
        };

        return (
            <div className="AdvisorLandingContainer">
                <div className="AdvisorLandingHeader">
                   
                                <span className="AdvisorHeaderMessage">Welcome {this.context.myInfo.firstName} {this.context.myInfo.lastName}</span><br /><br />
                    <br /><br />
                    <div className="caseloadHeaderStatus">{this.context.statusMessage} &nbsp;</div>
                </div>
                <table className="advisorDashboardTable">
                    <tr>
                        <td id="caseloadCell" className="leftDashboardWidget">
                            <div style={{
                                display: 'flex', // Use flexbox layout
                                flexDirection: 'column', // Stack children vertically
                                height: '100%', // Take the full height available
                                justifyContent: 'flex-start', // Start alignment at the top for the first child
                            }}>
                                <div style={{
                                    alignSelf: 'flex-start', // Align to the start of the flex container
                                    width: '100%', // Ensure the div takes the full width
                                    textAlign: 'center', // Center text horizontally
                                    marginBottom: '0', // Remove any default bottom margin
                                    paddingTop: '10px', // Space from the top of the container
                                }}>
                                    Caseload
                                </div>
                                <div style={{
                                    flex: 1, // Take up all available space
                                    display: 'flex', // Use flexbox layout
                                    flexDirection: 'column', // Stack children vertically
                                    justifyContent: 'center', // Center vertically in the remaining space
                                    textAlign: 'center', // Center text horizontally
                                }}>
                                    {
                                        this.context.myCases.length > 0 ? (
                                            <Link id={"casesWidgetLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardLink">
                                                {this.context.myCases.length}
                                            </Link>
                                        ) : (
                                            <Link id={"casesWidgetLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardLink">
                                                Loading...
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>




                            </td>
                        <td id="inProgramCell" className="centerDashboardWidget"><Link id={"casesProgramLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardGraphLink"><InProgramGraph /></Link></td>
                        <td id="quickLinks" className="rightQuickLinksDashboardWidget" rowSpan="2">
                            <table className="dashboardQuickLinksTable">
                                <tr><th className="dashboardQuickLinksTableHeader AdvisorHeaderMessage" > <span className="AdvisorHeaderMessage"> Quick Links </span> </th>
                                </tr>

                                {
                                    this.context.myCases.length > 0 ? (
                                        <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardLink">

                                            <FontAwesome name="file-text" /> Caseload ({this.context.myCases.length})
                                        </Link></td>
                                        </tr>) : <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardLink">

                                            <FontAwesome name="file-text" /> Loading...
                                        </Link></td>
                                    </tr>}

                                <tr><td><Link id={"casesQuickLink"} onClick={() => this.setState({ showSearch: true, searchTab: 0, studVis: "tabVisible" })} className="dashboardLink">

                                    <FontAwesome name="search" /> Student Search
                                </Link></td>
                                </tr>

                                <tr><td><Link id={"casesQuickLink"} onClick={() => this.setState({ showSearch: true, searchTab: 1, parVis: "tabVisible" })} className="dashboardLink">

                                    <FontAwesome name="search" /> Guardian Search
                                </Link></td>
                                </tr>
                                <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("aprreport")} className="dashboardLink">

                                    <FontAwesome name="file-text" /> APR Report
                                </Link></td>
                                </tr>
                                <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.openAdvisorMilestones(this.context.myBasics.userProfile.userId)} className="dashboardLink">

                                    <FontAwesome name="file-text" /> My Milestones
                                </Link></td>
                                </tr>
                                {((this.context.myBasics.isAdmin || this.context.myBasics.isStaff || this.context.myBasics.isSupervisor) && this.context.loaded) ? <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("milestones")} className="dashboardLink">

                                    <FontAwesome name="file-text" /> Milestones Overview
                                </Link></td>
                                </tr> : null}
                                {((this.context.myBasics.isAdmin || this.context.myBasics.isStaff) && this.context.loaded )? <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("adminLog")} className="dashboardLink">

                                    <FontAwesome name="file-text" /> Admin Log
                                </Link></td>
                                </tr> : null }
                                {(this.context.myBasics.isSupervisor && !this.context.myBasics.isAdmin && !this.context.myBasics.isStaff && this.context.myCases.length > 0) ? <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("directorlog")} className="dashboardLink">

                                    <FontAwesome name="file-text" /> Director Log
                                </Link></td>
                                </tr> : null}
                                {(this.context.myBasics.isAdmin) ? <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.gotoLocation("objectives")} className="dashboardLink">

                                    <FontAwesome name="file-text" /> Objectives
                                </Link></td>
                                </tr> : null}
                                {(!this.context.myBasics.isAdmin && !this.context.myBasics.isStaff) ? <tr><td><Link id={"casesQuickLink"} onClick={() => this.props.openAdvisorObjectives(this.context.myBasics.userProfile.userId)} className="dashboardLink">

                                    <FontAwesome name="file-text" /> My Objectives
                                </Link></td>
                                </tr> : null}
                            </table>

                            
                            </td>
                    </tr>
                    <tr>
                        <td id="objectivesCell" className="leftDashboardWidget">
                            {(this.context.myBasics.isAdmin || this.context.myBasics.isSupervisor) ?
                                <Link id={"casesProgramLink"} onClick={() => this.props.gotoLocation("objectives")} className="dashboardGraphLink"> <ObjectivesGraph /> </Link> : <Link id={"casesProgramLink"} onClick={() => this.props.openAdvisorMilestones(this.context.myBasics.userProfile.userId)} className="dashboardGraphLink"><MilestonesGraph /> </Link>}

                        </td>
                        <td id="contactedCell" className="centerDashboardWidget"><Link id={"casesContactedQuickLink"} onClick={() => this.props.gotoLocation("cases")} className="dashboardGraphLink"><ContactedGraph /></Link></td>
                    </tr>

                </table>
                
                <Modal isOpen={this.state.showSearch} ariaHideApp={false} style={customStyles}>
                    <div style={{ display: 'flex', padding: '20px' }}> {/* Add padding to the top */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Search</Typography>
                            </Grid>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={11}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter first or last name to search"
                                        name="title"
                                        onBlur={this.updateSearch}
                                        onChange={this.updateSearch}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="search" onClick={() => this.runSearch()}>
                                        <FontAwesome name="search" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="actionButtonWrapper">
                                <Button onClick={this.closeSearch} className="fieldSelectorButton">Cancel</Button>
                            </div>
                            <Grid item xs={12}>
                                <Tabs value={this.state.searchTab} onChange={this.setTab} aria-label="Search" variant="fullWidth"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    justifyContent: 'space-evenly',
                                },
                                '.MuiTab-root': {
                                    border: '1px solid #000',
                                    borderBottom: 'none',
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    marginRight: '4px',
                                    '&:last-child': {
                                        marginRight: 0,
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#123225',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#123225',
                                        color: '#fff',
                                        opacity: 1,
                                    },
                                },
                            }}
                        >
                            <Tab label="Students" />
                            <Tab label="Guardians" />
                            

                                </Tabs>
                            </Grid>
                            <Grid item xs={12} className={this.state.parVis}>
                            <ul>
                                    {this.state.searchPriParents.map((prof) => (
                                    <li>
                                    <Link key={prof.id} onClick={() => this.props.openSearchProfile(prof, "PrimaryParent")}>
                                                {prof.lastName}, {prof.firstName} 
                                            </Link></li>
                                ))}
                                   </ul>
                            </Grid>


                            <Grid item xs={12} className={this.state.studVis}>
                            <ul>
                                {this.state.searchStudents.map((prof) => (
                                    
                                    <li>
                                    <Link key={prof.id} onClick={() => this.props.openSearchProfile(prof, "Student")}>
                                                {prof.lastName}, {prof.firstName} 
                                            </Link></li>
                                ))}

                                </ul>
                            </Grid>

                            </Grid>
                    </div>

                    
                </Modal>

            </div >

        );
    };
        
    

}


export default AdvisorsDashboard;
