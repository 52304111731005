import axios from "axios";

export const getAll = (isActive, cancelToken) => {
    return axios.get("/api/services/app/Season/GetAll", { params: { isActive }, cancelToken });
};

export const create = (input) => {
    return axios.post("/api/services/app/Season/Create", input);
};

export const update = (input) => {
    return axios.put("/api/services/app/Season/Update", input);
};

export const updateOrder = (input) => {
    return axios.put("/api/services/app/Season/UpdateOrder", { items: input });
};
