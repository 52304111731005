import React, { useEffect, useState } from "react";
import axios from "axios";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import useAjaxStatus from "../../../common/useAjaxStatus";
import { Form, Field } from "react-final-form";
import { FinalFormDropdown, FinalFormTextField } from "../../../common/formWrappers";
import { required } from "../../../common/formValidators";
import LoadingButton from "../../../common/loadingButton";
import { EnrollmentCapMaskInput } from "../../../common/masks";
import moment from "moment";
import enumHelper from "../../../support/logic/enumHelper";
import { EventLocationType, ProgramEventDateWaitlistResponseDuration } from "../../enums";
import { getNewInviteCount } from "../../api/programEvents";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    label: {
        fontWeight: "bold",
    },
    tooltip: {
        color: theme.palette.grey[500],
    },
    tooltipTarget: {
        cursor: "pointer",
        width: "fit-content",
    },
    badge: {
        "& .MuiBadge-badge:not(.MuiBadge-invisible)": {
            transform: "scale(1) translate(100%, 50%)",
        },
    },
    labelDDL: {
        marginBottom: 26,
    },
    content: {
        padding: theme.spacing(1, 2),
    },
}));

const OpenEnrollmentConfirmationDialog = ({
    open,
    programEvent,
    onSave,
    enrollmentCap,
    waitlistCap,
    waitlistResponseDuration,
    waitlistResponseTime,
    onClose,
    disabled
}) => {
    const classes = useStyles();
    const { ajaxCallsInProgress, ajaxComplete, ajaxLoad, ajaxErrorWrapper } = useAjaxStatus();
    const [numberOfParticipants, setNumberOfParticipants] = useState(0);
    const [first3EventDates, setFirst3EventDates] = useState([]);
    const [restOfEventDates, setRestOfEventDates] = useState([]);

    useEffect(() => {
        if (programEvent.eventDates.length > 3) {
            const first3EventDates = programEvent.eventDates.slice(0, 3);
            const restOfEventDates = programEvent.eventDates.slice(3);
            setFirst3EventDates(first3EventDates);
            setRestOfEventDates(restOfEventDates);
        } else {
            setFirst3EventDates(programEvent.eventDates);
        }
    }, [programEvent.eventDates]);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        if (open) {
            (async () => {
                try {
                    ajaxLoad();
                    const response = await getNewInviteCount(programEvent.id, tokenSource.token);
                    setNumberOfParticipants(response.data.result.totalInvitesWithAccounts);
                } catch (ex) {
                    ajaxErrorWrapper(ex);
                } finally {
                    ajaxComplete();
                }
            })();
        }
        return () => {
            tokenSource.cancel();

        };
    }, [ajaxComplete, ajaxErrorWrapper, ajaxLoad, open, programEvent.id]);

    return (
        <Form onSubmit={(values) => onSave(values)} initialValues={{ enrollmentCap, waitlistCap, waitlistResponseDuration, waitlistResponseTime }}>
            {({ handleSubmit, form, values }) => {
                const handleClose = (e, reason) => {
                    if (reason) {
                        return;
                    }

                    form.initialize({ enrollmentCap, waitlistCap, waitlistResponseDuration, waitlistResponseTime });
                    onClose();
                };
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Dialog
                            onClose={handleClose}
                            open={open}
                            className={classes.descriptionDialog}
                            maxWidth="sm"
                            fullWidth
                            disableEscapeKeyDown
                            disablePortal
                        >
                            <DialogTitle>
                                Open Event for Enrollment?
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent className={classes.content}>
                                <Box marginBottom={2}>
                                    <Box marginBottom={2}>
                                        <Typography variant="body1">Are you ready to open this event for enrollment?</Typography>
                                        
                                    </Box>
                                    <Box marginBottom={1}>
                                        <Typography className={classes.label} variant="body1" display="inline">
                                            Event Name:
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" display="inline">
                                            {programEvent.name}
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Typography className={classes.label} variant="body1" display="inline">
                                                Date(s):
                                            </Typography>
                                        </Grid>
                                        <Grid item xs container direction="column">
                                            {first3EventDates.map((dateObj, index) => {
                                                const startTime = moment(dateObj.startTime);
                                                const endTime = moment(dateObj.endTime);
                                                const isLastItem = index === first3EventDates.length - 1;
                                                return (
                                                    <Grid item key={dateObj.startTime} style={{ alignSelf: "flex-start" }}>
                                                        <Tooltip
                                                            classes={{ tooltip: classes.tooltip }}
                                                            title={
                                                                restOfEventDates.length > 0 && isLastItem ? (
                                                                    <>
                                                                        {restOfEventDates.map((dateObj) => {
                                                                            const startTime = moment(dateObj.startTime);
                                                                            const endTime = moment(dateObj.endTime);
                                                                            return (
                                                                                <Typography key={startTime} variant="body1">
                                                                                    {`${startTime.format("MM/DD/YYYY")} ${startTime.format(
                                                                                        "h:mm a"
                                                                                    )} - ${endTime.format("h:mm a")}`}
                                                                                </Typography>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )
                                                            }
                                                        >
                                                            <Badge
                                                                className={classes.badge}
                                                                color="primary"
                                                                invisible={restOfEventDates.length === 0 || !isLastItem}
                                                                badgeContent={`+${restOfEventDates.length}`}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "right",
                                                                }}
                                                            >
                                                                <Typography variant="body1" display={isLastItem ? "inline" : "block"}>
                                                                    {`${startTime.format("MM/DD/YYYY")} ${startTime.format("h:mm a")} - ${endTime.format(
                                                                        "h:mm a"
                                                                    )}`}
                                                                </Typography>
                                                            </Badge>
                                                        </Tooltip>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Box marginTop={1} marginBottom={1}>
                                        <Typography className={classes.label} variant="body1" display="inline">
                                            Type:
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" display="inline">
                                            {enumHelper.getDisplayName(EventLocationType, programEvent.locationType)}
                                        </Typography>
                                    </Box>
                                    <Box marginBottom={1}>
                                        <Typography className={classes.label} variant="body1" display="inline">
                                            Venue:
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" display="inline">
                                            {programEvent.venue?.name || "N/A"}
                                        </Typography>
                                    </Box>
                                    <Box marginBottom={1}>
                                        <Typography className={classes.label} variant="body1" display="inline">
                                            Target Participants:
                                        </Typography>
                                        &nbsp;
                                        <Typography variant="body1" display="inline">
                                            {programEvent.logistics.targetParticipantsDescription || "N/A"}
                                        </Typography>
                                    </Box>
                                    {programEvent.transportation && (
                                        <Box marginBottom={2}>
                                            <Typography className={classes.label} variant="body1" display="inline">
                                                Transportation Provided?:
                                            </Typography>
                                            &nbsp;
                                            <Typography variant="body1" display="inline">
                                                {programEvent.transportation.provided ? "Yes" : "No"}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Typography variant="body1">
                                        Text/Email will be sent to <span style={{ fontWeight: 800 }}>{numberOfParticipants}</span> participants inviting them to
                                        event.
                                    </Typography>
                                    <Typography variant="body1">Parents of students Under 18 will also be notified.</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="enrollmentCap"
                                            label="Enrollment Cap"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            disabled={disabled} 
                                            validate={required}
                                            inputComponent={EnrollmentCapMaskInput}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="waitlistCap"
                                            label="Waitlist Cap"
                                            variant="outlined"
                                            margin="dense"
                                            required
                                            disabled={disabled} 
                                            validate={required}
                                            inputComponent={EnrollmentCapMaskInput}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex">
                                            <Box mr={1}>
                                                <Field
                                                    component={FinalFormTextField}
                                                    name="waitlistResponseTime"
                                                    label="Waitlist Response Time"
                                                    variant="outlined"
                                                    margin="dense"
                                                    required
                                                    disabled={disabled} 
                                                    validate={required}
                                                />
                                            </Box>
                                            <Field
                                                component={FinalFormDropdown}
                                                name="waitlistResponseDuration"
                                                label=" "
                                                labelClassName={classes.labelDDL}
                                                variant="outlined"
                                                margin="dense"
                                                disabled={disabled} 
                                                choices={enumHelper.getSelectOptionsList(ProgramEventDateWaitlistResponseDuration)}
                                                validate={required}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={ajaxCallsInProgress !== 0}>
                                    Cancel
                                </Button>
                                <LoadingButton loading={ajaxCallsInProgress !== 0} disabled={disabled} variant="contained" color="primary" type="submit">
                                    Open
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </form>
                );
            }}
        </Form>
    );
};

export default OpenEnrollmentConfirmationDialog;
