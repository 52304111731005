import React, { Component } from "react";
import FileSaver from "file-saver";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import withUser from "../../../support/hoc/withUser";
import { UserType } from "../../../support/enums";
import * as propTypes from "../../propTypes";
import * as TransactionApi from "../../../api/scholarshipTransactions";
import b64toBlob from "../../../support/logic/b64toBlob";
import Donated from "./components/Donated";
import Cash from "./components/Cash";
import Search from "./components/search";
import DonatedList from "./components/DonatedList";
import CashList from "./components/CashList";
import DonatedCrusaders from "./components/DonatedCrusaders";
import CashCrusaders from "./components/CashCrusaders";

import "./index.css";

class Summary extends Component {
  state = {
    summaryData: null,
    searchText: "",
    isDonatedActive: true,
    donatedList: [],
    cashList: [],
    showDonatedCrusaders: false,
    donatedCrusaders: [],
    schoolName: "",
    showCashCrusaders: false,
    cashCrusaders: [],
    isPayment: false
  };

  componentDidMount = () => {
    if (this.props.scholarship) {
      this.scholarshipId = this.props.scholarship.id;
      if (this.doCheckIfStaff()) {
        Promise.all([this.doGetDonationCashSummary(), this.doLoadActiveList()]);
      } else {
        Promise.all([this.doGetDonationCashSummary(), this.doLoadActiveList(), this.doLoadDonatedCrusaders()]);
      }
    }
  };
  UNSAFE_componentWillReceiveProps = async props => {
    if (props.scholarship && this.scholarshipId !== props.scholarship.id) {
      this.scholarshipId = props.scholarship.id;
      if (this.doCheckIfStaff()) {
        Promise.all([this.doGetDonationCashSummary(), this.doLoadActiveList()]);
      } else {
        Promise.all([this.doGetDonationCashSummary(), this.doLoadActiveList(), this.doLoadDonatedCrusaders()]);
      }
      this.setState({
        showDonatedCrusaders: false,
        showCashCrusaders: false,
        donatedList: [],
        cashList: [],
        donatedCrusaders: [],
        cashCrusaders: []
      });
    }
  };
  onViewAll = () => {
    this.setState(
      {
        searchText: ""
      },
      async () => {
        await this.doLoadActiveList();
      }
    );
  };
  onSearch = async () => {
    this.setState({
      showDonatedCrusaders: false,
      donatedCrusaders: [],
      showCashCrusaders: false,
      donatedList: [],
      cashList: [],
      cashCrusaders: [],
      schoolName: ""
    });
    await this.doLoadActiveList(null, this.state.searchText);
  };
  onSearchTextChange = e => {
    const value = e.target.value;
    this.setState({
      searchText: value
    });
  };
  onCashClick = e => {
    this.setState(
      {
        isDonatedActive: false,
        showDonatedCrusaders: false,
        schoolName: "",
        donatedCrusaders: [],
        donatedList: []
      },
      async () => {
        await this.doGetCashList(null, this.state.searchText);
      }
    );
  };
  onDonatedClick = () => {
    this.setState(
      {
        isDonatedActive: true,
        showCashCrusaders: false,
        schoolName: "",
        cashList: [],
        cashCrusaders: []
      },
      async () => {
        if (this.doCheckIfStaff()) {
          await this.doGetDonationList(null, this.state.searchText);
        } else {
          Promise.all([this.doGetDonationList(null, this.state.searchText), this.doLoadDonatedCrusaders()]);
        }
      }
    );
  };
  getDonatedListTdProps = (state, rowInfo, column, instance) => {
    if (!rowInfo) {
      return {};
    }

    return {
      onClick: e => this.doLoadDonatedCrusaders(rowInfo.original)
    };
  };
  getCashListTdProps = (state, rowInfo, column, instance) => {
    if (!rowInfo) {
      return {};
    }

    return {
      onClick: e => this.doLoadCashCrusaders(rowInfo.original)
    };
  };
  onExportToExcel = async () => {
    try {
      this.props.ajaxStatusLoading();
      let response;
      let fileName = "Summary_report_Cash.xlsx";
      if (this.isCashCrusaders === true) {
        response = (await TransactionApi.getCrusadersCashExport(this.cashCrusaderId)).data.result;
      } else {
        response = (await TransactionApi.getCrusadersDonatedExport(this.scholarshipId, this.institutionId)).data.result;
        fileName = "Summary_report_Donated.xlsx";
      }
      this.props.ajaxStatusComplete();
      const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      FileSaver.saveAs(blob, fileName);
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  };
  doLoadDonatedCrusaders = async institution => {
    const institutionId = institution ? institution.id : null;
    this.isCashCrusaders = false;
    this.institutionId = institutionId;

    try {
      this.props.ajaxStatusLoading();
      const results = await TransactionApi.getCrusadersCertifiedForSchool(this.scholarshipId, institutionId);

      await this.doGetDonationCashSummary(institutionId);
      this.setState({
        donatedCrusaders: results.data.result,
        showDonatedCrusaders: true,
        schoolName: institution ? institution.institutionName : ""
      });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error Loading Donated students");
    }
  };
  doLoadCashCrusaders = async record => {
    this.isCashCrusaders = true;
    this.cashCrusaderId = record.id;
    this.isPaymentRequestId = record.isPaymentRequestId;

    try {
      this.props.ajaxStatusLoading();
      const results = await TransactionApi.getCrusadersWithPayments(record.id);

      await this.doGetDonationCashSummary(record.institutionId);
      this.setState({
        cashCrusaders: results.data.result,
        showCashCrusaders: true,
        isPayment: record.isPaymentRequestId
      });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error Loading Cash students");
    }
  };
  doGetDonationCashSummary = async institutionId => {
    try {
      this.props.ajaxStatusLoading();
      const results = await TransactionApi.getSummaryDonationCash(this.scholarshipId, institutionId);
      this.setState({
        summaryData: results.data.result
      });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error Loading Summary Donation and Cash");
    }
  };
  doLoadActiveList = async (institutionId, searchText) => {
    if (this.state.isDonatedActive) {
      await this.doGetDonationList(institutionId, searchText);
      return;
    }
    await this.doGetCashList(institutionId, searchText);
  };
  doGetDonationList = async (institutionId, searchText) => {
    try {
      this.props.ajaxStatusLoading();
      const results = await TransactionApi.getDonatedList(this.scholarshipId, institutionId, searchText);
      this.setState({
        donatedList: results.data.result
      });

      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error Institution Donated List");
    }
  };
  doGetCashList = async (institutionId, searchText) => {
    try {
      this.props.ajaxStatusLoading();
      const results = await TransactionApi.getCashList(this.scholarshipId, institutionId, searchText);
      this.setState({
        cashList: results.data.result
      });

      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error Institution Cash List");
    }
  };
  doCheckIfStaff = () => {
    const { user } = this.props;
    const userType = user ? user.userProfile.userType : 0;
    return userType === UserType.Admin || userType === UserType.Finance;
  };
  render() {
    const isStaff = this.doCheckIfStaff();
    return (
      <div className="scholarship-reports-summary">
        {this.state.summaryData ? (
          <div className="report-summary-header">
            {isStaff ? (
              <Search searchText={this.state.searchText} onSearchTextChange={this.onSearchTextChange} onSearch={this.onSearch} onViewAll={this.onViewAll} />
            ) : null}
            <Donated isActive={this.state.isDonatedActive} onSelect={this.onDonatedClick} data={this.state.summaryData.donation} />
            <Cash isActive={!this.state.isDonatedActive} onSelect={this.onCashClick} data={this.state.summaryData.cash} />
          </div>
        ) : null}

        <div className="report-summary-body">
          {isStaff && this.state.isDonatedActive && this.state.donatedList.length > 0 ? (
            <DonatedList data={this.state.donatedList} getTdProps={this.getDonatedListTdProps} />
          ) : null}
          {!this.state.isDonatedActive ? <CashList data={this.state.cashList} getTdProps={this.getCashListTdProps} isStaff={isStaff} /> : null}
          {this.state.showDonatedCrusaders ? (
            <DonatedCrusaders onExportToExcel={this.onExportToExcel} schoolName={this.state.schoolName} data={this.state.donatedCrusaders} />
          ) : null}
          {this.state.showCashCrusaders ? (
            <CashCrusaders
              onExportToExcel={this.onExportToExcel}
              schoolName={this.state.schoolName}
              isPayment={this.state.isPayment}
              data={this.state.cashCrusaders}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

Summary.propTypes = {
  scholarship: propTypes.scholarship
};
Summary = withAjaxStatus(Summary);
Summary = withUser(Summary);

export default Summary;
