import React from "react";
import { Field } from "react-final-form";
import makeStyles from "@material-ui/styles/makeStyles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import { OnChange } from "react-final-form-listeners";
import { FinalFormKeyboardDatePicker, FinalFormKeyboardTimePicker, FinalFormDropdown, FinalFormTextField } from "../../../common/formWrappers";
import { required, requiredAllowZero } from "../../../common/formValidators";
import { EventLocationType, EventStatus } from "../../enums";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 0,
    },
}));

const EventDateItem = ({
    id,
    field,
    locationTypes,
    showDeleteButton,
    cancelled,
    onUndo,
    onCancel,
    onDelete,
    onTimeChange,
    topLevelLocationType,
    eventStatus,
    disabled
}) => {
    const classes = useStyles();

    const statusIsOpenEnrollment = [EventStatus.OpenEnrollment].includes(eventStatus);
    const actionButton = () => {
        if (!showDeleteButton) {
            return null;
        }

        if (statusIsOpenEnrollment && id) {
            return null;
        }

        return (
            <IconButton onClick={onDelete} disabled={disabled}>
                <DeleteIcon />
            </IconButton>
        );
    };

    return (
        <TableRow>
            <TableCell>
                <Field
                    component={FinalFormKeyboardDatePicker}
                    formControlClassName={classes.formControl}
                    name={`${field}.date`}
                    validate={required}
                    datePickerProps={{ disabled: cancelled }}
                />
            </TableCell>
            <TableCell>
                <Field
                    component={FinalFormKeyboardTimePicker}
                    formControlClassName={classes.formControl}
                    name={`${field}.startTime`}
                    validate={required}
                    timePickerProps={{ disabled: cancelled }}
                />
            </TableCell>
            <TableCell>
                <RemoveIcon />
            </TableCell>
            <TableCell>
                <Field
                    component={FinalFormKeyboardTimePicker}
                    formControlClassName={classes.formControl}
                    name={`${field}.endTime`}
                    validate={required}
                    timePickerProps={{ disabled: cancelled }}
                />
            </TableCell>
            <TableCell>
                <Field
                    component={FinalFormDropdown}
                    className={classes.formControl}
                    name={`${field}.locationType`}
                    placeholder="Select a location..."
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    choices={locationTypes}
                    validate={required}
                    disabled={![EventLocationType.Both, null].includes(topLevelLocationType) || cancelled}
                />
            </TableCell>
            <TableCell>
                <Field
                    component={FinalFormTextField}
                    className={classes.formControl}
                    name={`${field}.creditHours`}
                    variant="outlined"
                    margin="dense"
                    number
                    fullWidth
                    validate={requiredAllowZero}
                    inputProps={{ min: 0 }}
                    disabled={cancelled}
                />
            </TableCell>
            <TableCell>{actionButton()}</TableCell>
            <OnChange name={`${field}.startTime`}>
                {(val, previous) => {
                    onTimeChange(val, previous);
                }}
            </OnChange>
            <OnChange name={`${field}.endTime`}>
                {(val, previous) => {
                    onTimeChange(val, previous);
                }}
            </OnChange>
        </TableRow>
    );
};

export default EventDateItem;
