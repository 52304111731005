import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Link } from "react-router-dom";

import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { SchoolType } from "../../../support/enums";

import "./List.css";

class List extends Component {
    static permission = "Admin.Schools";
    render() {
        return (
            <div className="admin-institution-list">
                <ReactTable
                    data={this.props.schools}
                    minRows={0}
                    columns={[
                        {
                            Header: "School Name",
                            accessor: "name",
                            minWidth: 150,
                        },
                        {
                            Header: "Type",
                            accessor: "type",
                            Cell: (data) => <span>{data.original.type === SchoolType.Middle ? "Middle School" : "High School"}</span>,
                            maxWidth: 200,
                        },
                        {
                            Header: "Code",
                            accessor: "code",
                            maxWidth: 100,
                        },
                        {
                            Header: "District",
                            accessor: "districtId",
                            Cell: (data) => {
                                return <span>{this.props.districts.find((d) => d.id === data.original.districtId)?.name}</span>;
                            },
                            maxWidth: 300,
                        },
                        {
                            Header: "Active",
                            accessor: "isActive",
                            Cell: (data) => <span>{data.original.isActive ? "Yes" : "No"}</span>,
                            maxWidth: 100,
                        },
                        {
                            Header: "",
                            accessor: "id",
                            minWidth: 30,
                            Cell: (row) => {
                                const url = `/admin/middle-high/schools/${row.value}`;
                                return <Link to={url}>Edit</Link>;
                            },
                        },
                    ]}
                    defaultPageSize={50}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

List.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};

List = withAjaxStatus(List);
export default List;
