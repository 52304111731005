import axios from 'axios';

export function createEvent(data) {
    return axios.post('/api/services/app/AdmissionEvent/CreateEvent', data);
}

export function updateEvent(data) {
    return axios.put('/api/services/app/AdmissionEvent/UpdateEvent', data);
}

export function getEvent(id) {
    return axios.get('/api/services/app/AdmissionEvent/GetEvent', { params: { id } });
}

export function getEvents() {
    return axios.get('/api/services/app/AdmissionEvent/GetEvents');
}

export function getUpcomingEvents() {
    return axios.get('/api/services/app/AdmissionEvent/GetUpcomingEvents');
}

export function getRoster(eventId) {
    return axios.get('/api/services/app/AdmissionEvent/GetRoster', { params: { id: eventId } });
}
