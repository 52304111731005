import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import useTheme from "@material-ui/styles/useTheme";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(2),
        "& .MuiFormHelperText-contained": {
            margin: 0,
        },
    },
    label: {
        marginBottom: theme.spacing(1),
    },
    datePicker: {
        "& .MuiOutlinedInput-input": {
            paddingTop: theme.spacing(1.05),
            paddingBottom: theme.spacing(1.05),
        },
        "& .MuiPickersDay-day .MuiIconButton-label": {
            color: "rgba(0, 0, 0, 0.87)",
        },
        "& .MuiPickersDay-dayDisabled .MuiIconButton-label": {
            color: theme.palette.lightGray.main,
        },
        datePickerAdornedEnd: {
            paddingRight: theme.spacing(0.5),
        },
        datePickerAdornment: {
            padding: theme.spacing(0.5),
        },
    },
}));

const FinalFormKeyboardDatePicker = ({ label, input, meta, formControlClassName, labelClassName, formControlProps, datePickerProps }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <FormControl className={clsx(classes.formControl, formControlClassName)} fullWidth {...formControlProps}>
            {label && <FormLabel className={clsx(classes.label, labelClassName)}>{label}</FormLabel>}
            <KeyboardDatePicker
                className={classes.datePicker}
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YY"
                PopoverProps={{ className: classes.datePicker }}
                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                {...input}
                {...datePickerProps}
            />
            {meta.error && meta.touched && <FormHelperText style={{ color: theme.palette.error.main }}>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormKeyboardDatePicker;
