import React, { useState } from "react";
import { connect } from "react-redux";
import DropArea from "../../support/components/dropArea";
import Dropzonify from "../../support/components/dropzonify";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import SweetAlert from "react-bootstrap-sweetalert";

import * as StudentProfileApi from "../../api/studentProfile";

import "./index.css";

let ImportPage = ({ accessToken, ajaxStatusLoading, ajaxStatusComplete, ajaxStatusError, readonly }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [result, setResult] = useState({ updated: 0, errors: [] });

    const onUploadComplete = async (document) => {
        try {
            ajaxStatusLoading();
            const response = (await StudentProfileApi.updateScholarshipAwards(document.id)).data.result;
            setResult(response);
            setShowConfirm(true);
            ajaxStatusComplete();
        } catch (err) {
            ajaxStatusError(err);
        }
    };

    const handleConfirm = () => {
        setShowConfirm(false);
    };

    return (
        <div className="import-update">
            <h2>Import Student Updates</h2>
            <p>
                This page allows you to bulk-update award tiers for given Onward Ids. Simply upload an Excel document containing columns with a header of
                Crusader_Id and Tier.
            </p>
            <table className="import-update__table">
                <thead>
                    <tr>
                        <td>Crusader_Id</td>
                        <td>Tier</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Crusader Id Number</td>
                        <td>1 = Gold</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>2 = Silver</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>3 = Bronze</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>4 = Pewter</td>
                    </tr>
                </tbody>
            </table>
            <div className="import-update__dropzone">
                <Dropzonify
                    accessToken={accessToken}
                    accept=".xlsx"
                    uploadUrl="/api/services/app/documents/upload"
                    onUploadComplete={onUploadComplete}
                    additionalUploadParameters={{ groupType: 0 }}
                    disabled={readonly }
                >
                    {({ uploadingFiles, onBrowse, dragging }) => <DropArea onBrowse={onBrowse} dragging={dragging} uploadingFiles={uploadingFiles} disabled={readonly} />}
                </Dropzonify>
            </div>
            <SweetAlert show={showConfirm} title="" type="info" onConfirm={handleConfirm} confirmBtnText="OK">
                <div>Updated Records: {result.updated}</div>
                <br />
                <div>
                    Records with Errors:
                    <ul>
                        {result.errors.map((i) => (
                            <li key={i}>Line {i}</li>
                        ))}
                    </ul>
                    {result.errors.length === 0 && <div>No Errors</div>}
                </div>
            </SweetAlert>
        </div>
    );
};

const mapStateToProps = (state) => ({
    accessToken: state.user.accessToken,
});

ImportPage = withAjaxStatus(ImportPage);
ImportPage = connect(mapStateToProps)(ImportPage);

export default ImportPage;
