import React from "react";
import moment from "moment";
import FontAwesome from "react-fontawesome";

import "./cycleItem.css";

const formatDate = (date) => moment(date).utc().format("MMMM Do YYYY");

const CycleItemGrade = ({ item, grade }) => (
    <tr>
        <td>{grade}</td>
        <td>{item.isEligible ? "Yes" : "No"}</td>
        <td>{item.cohort}</td>
        <td>{item.crusaderPrefix}</td>
    </tr>
);

const CycleItem = ({ item, onEdit, onDelete }) => {
    return (
        <div className="recruitment-cycle-item">
            <h2 className="recruitment-cycle-item__title">
                {formatDate(item.startDate)} - {formatDate(item.endDate)}
            </h2>
            <div className="recruitment-cycle-item__grade-selection">
                <strong>Grade Selection Label Year:</strong>
                {item.uiYear}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Grade</th>
                        <th>Eligible?</th>
                        <th>Cohort</th>
                        <th>Onward Id (First 4)</th>
                    </tr>
                </thead>
                <tbody>
                    <CycleItemGrade grade={5} item={item.grade5} />
                    <CycleItemGrade grade={6} item={item.grade6} />
                    <CycleItemGrade grade={7} item={item.grade7} />
                    <CycleItemGrade grade={8} item={item.grade8} />
                </tbody>
            </table>
            <div className="recruitment-cycle-item__actions">
                <button className="btn btn-small" onClick={() => onEdit(item)}>
                    <FontAwesome name="pencil" size="lg" />
                </button>
                <button className="btn btn-small" onClick={() => onDelete(item)}>
                    <FontAwesome name="trash" size="lg" />
                </button>
            </div>
        </div>
    );
};

export default CycleItem;
