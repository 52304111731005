import React, { useState, useEffect, useReducer, useCallback } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import axios from "axios";

import * as programCodeApi from "../api/programCodes";
import { getAll as getAllActivityCodes } from "../api/activityCodes";
import useAjaxStatus from "../../common/useAjaxStatus";
import LoadingIndicator from "../../common/loadingIndicator";
import ProgramCodeGrid from "./programCodeGrid";
import ProgramCodeHeader from "./programCodeHeader";
import { EMPTY_GUID } from "../../support/constants";
import SimpleDialog from "../../common/simpleDialog";
import ProgramCodeForm from "./programCodeForm";

const useStyles = makeStyles((theme) => ({
    programCodes: {},
}));

const defaultProgramCodeState = {
    programCodes: [],
    filter: () => {
        return true;
    },
};

const programCodeReducer = (state, action) => {
    const newValue = { ...state };

    if (action.type === "SET_CODES") {
        newValue.programCodes = action.codes;
        return newValue;
    }

    if (action.type === "SET_LOADING") {
        newValue.isLoading = action.isLoading;
        return newValue;
    }

    if (action.type === "SET_ISADDING") {
        newValue.isAdding = action.isAdding;
        return newValue;
    }

    if (action.type === "ADD_CODE") {
        newValue.programCodes.push(action.item);
        return newValue;
    }

    if (action.type === "SET_FILTER") {
        const lowerCaseSearchText = (action.filter.searchText || "").toLowerCase();
        const programCodeFilter = action.filter.programCode || "";
        const activityCodeFilter = action.filter.activityCode || "";

        if (action.filter.searchText === "" && programCodeFilter === "" && activityCodeFilter === "") {
            newValue.filter = () => {
                return true;
            };
            return newValue;
        }

        newValue.filter = (item) => {
            return (
                (lowerCaseSearchText === "" ||
                    item.programCodeDescription.toLowerCase().includes(lowerCaseSearchText) ||
                    item.subCodeDescription.toLowerCase().includes(lowerCaseSearchText)) &&
                (programCodeFilter === "" || item.programCode === +programCodeFilter) &&
                (activityCodeFilter === "" ||
                    item.gearUpActivityCodes.some((guCode) => {
                        return guCode.activityCode === +activityCodeFilter;
                    }))
            );
        };

        return newValue;
    }

    return defaultProgramCodeState;
};

const ProgramCodes = () => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const [allActivityCodes, setAllActivityCodes] = useState([]);
    const [allCoreOfferings, setAllCoreOfferings] = useState([]);
    const [allProgramCodes, setAllProgramCodes] = useState([]);

    const [programCodeState, dispatchProgramAction] = useReducer(programCodeReducer, defaultProgramCodeState);

    const getCodes = useCallback(() => {
        (async () => {
            try {
                dispatchProgramAction({ type: "SET_LOADING", isLoading: true });
                const response = programCodeApi.getAllSubCodes();
                const data = (await response).data.result;
                dispatchProgramAction({ type: "SET_CODES", codes: data });
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
                dispatchProgramAction({ type: "SET_LOADING", isLoading: false });
            }
        })();
    }, [ajaxErrorWrapper]);

    useEffect(() => {
        getCodes();
    }, [ajaxErrorWrapper, getCodes]);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const tokenSourceGc = axios.CancelToken.source();
        (async () => {
            try {
                const response = getAllActivityCodes(tokenSourceGc.token);
                const data = (await response).data.result;
                setAllActivityCodes(data);

                const offeringResponse = programCodeApi.getAllCoreOfferings(tokenSource.token);
                const offeringsData = (await offeringResponse).data.result;
                setAllCoreOfferings(offeringsData);
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
            }
        })();

        return () => {
            tokenSource.cancel();
            tokenSourceGc.cancel();
        };
    }, [ajaxErrorWrapper]);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        (async () => {
            try {
                if (programCodeState.programCodes.length === 0) {
                    return;
                }
                const response = programCodeApi.getAllProgramCodes(tokenSource.token);
                const data = (await response).data.result;
                setAllProgramCodes(data);
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, [ajaxErrorWrapper, programCodeState.programCodes]);

    const handleSearchBoxChange = (value) => {
        dispatchProgramAction({ type: "SET_FILTER", filter: { searchText: value } });
    };

    const handleGearUpIdChange = (value) => {
        dispatchProgramAction({ type: "SET_FILTER", filter: { activityCode: value } });
    };

    const handleProgramIdChange = (value) => {
        dispatchProgramAction({ type: "SET_FILTER", filter: { programCode: value } });
    };

    const handleAddClick = () => {
        dispatchProgramAction({ type: "SET_ISADDING", isAdding: true });
    };

    const handleOnSave = async (values, onSuccessCallback) => {
        try {
            dispatchProgramAction({ type: "SET_LOADING", isLoading: true });

            if (values.id === EMPTY_GUID) {
                await programCodeApi.createSubCode(values).then(async () => {
                    getCodes();
                });

                dispatchProgramAction({ type: "SET_ISADDING", isAdding: false });
            } else {
                await programCodeApi.updateSubCode(values).then(async () => {
                    getCodes();
                    onSuccessCallback();
                });
            }
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            dispatchProgramAction({ type: "SET_LOADING", isLoading: false });
        }
    };
    return (
        <div className={classes.programCodes}>
            <LoadingIndicator loading={programCodeState.isLoading} />
            <ProgramCodeHeader
                programCodeOptions={allProgramCodes}
                activityCodes={allActivityCodes}
                onAddClick={handleAddClick}
                onSearchBoxChange={handleSearchBoxChange}
                onProgramIdChange={handleProgramIdChange}
                onGearUpIdChange={handleGearUpIdChange}
            />
            <ProgramCodeGrid
                data={programCodeState.programCodes}
                dataFilter={programCodeState.filter}
                allActivityCodes={allActivityCodes}
                allCoreOfferings={allCoreOfferings}
                programCodeOptions={allProgramCodes}
                onSave={handleOnSave}
            />
            {programCodeState.isAdding && (
                <SimpleDialog
                    className={classes.addEventDialog}
                    open={programCodeState.isAdding}
                    onClose={() => dispatchProgramAction({ type: "SET_ISADDING", isAdding: false })}
                    title="Edit Program Code/Subcode"
                    maxWidth="xl"
                    fullWidth
                >
                    {
                        <ProgramCodeForm
                            allActivityCodes={allActivityCodes}
                            allCoreOfferings={allCoreOfferings}
                            programData={null}
                            saveButtonText="Save"
                            onSubmit={handleOnSave}
                            programCodeOptions={allProgramCodes}
                            newItem
                        />
                    }
                </SimpleDialog>
            )}
        </div>
    );
};
export default ProgramCodes;
