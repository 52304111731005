import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import moment from "moment";
import FontAwesome from "react-fontawesome";

import "./Student.css";
import { useSelector } from "react-redux";

const admin_users_permission = "Admin.Users.edit";

const formatdobSSNAlien = (dob, student) => {
    
    if (student.ssnAlien && student.ssnAlien !== "") {
        return (
            <div className="student-info">
                <div>
                    DOB: <span className="bold">{dob}</span> SSN/Alien: <span className="bold">{student.ssnAlien}</span>
                </div>
                <div>
                    HS Graduation: <span className="bold">{student.yearOfGraduation}</span>
                </div>
            </div>
        );
    }
    return "";
};
const Student = ({ student, onClick, maxAllot }) => {
    const dob = student.dateOfBirth ? moment.utc(student.dateOfBirth).format("M/D/YYYY") : moment.utc(student.dob).format("M/D/YYYY");
    const roles = useSelector((state) => state.roles);
    const canNavToAdminUserEdit = roles.includes(admin_users_permission);
    const maxClass = maxAllot ? "maxReached" : "maxNotReached";
    return (
        <div
            className="scholarship-certify-student"
            role="button"
            tabIndex={0}
            onKeyDown={() => (canNavToAdminUserEdit ? window.open(`/admin/users/crusader/${student.id}`) : onClick())}
            onClick={() => (canNavToAdminUserEdit ? window.open(`/admin/users/crusader/${student.id}`) : onClick())}
        >
            <div className="scholarship-certify-student-inner">
                <span className="name">
                    {student.lastName}, {student.firstName} &nbsp;&nbsp;
                    {student.gender}
                    &nbsp;&nbsp;
                    {student.deceased}
                </span>
                <span className={maxClass} >Maximum Allotment Reached</span>
                {formatdobSSNAlien(dob, student)}
                <span className="bold">{student.yearOfGraduation < 2016 ? "Silver" : student.level}</span>
            </div>

            <FontAwesome name="eye" size="2x" color="white" />
        </div>
    );
};

Student.propTypes = {
    student: PropTypes.shape({
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        ssnAlien: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        yearOfGraduation: PropTypes.number.isRequired,
    }),
    onClick: PropTypes.func.isRequired,
};

export default withRouter(Student);
