import axios from "axios";

export function getList() {
    return axios.get("/api/services/app/School/GetAllActive");
}

export function getMiddleSchools() {
    return axios.get("/api/services/app/School/GetMiddleSchools");
}

export function getStaffMiddleSchools() {
    return axios.get("/api/services/app/Setting/GetSelectedMiddleSchools");
}
