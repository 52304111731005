import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

import "./RequestList.css";

const renderHeader = () => {
  return (
    <Fragment>
      <div className="request-list-header">
        <div className="request-list-column">
          <span>Submission Date</span>
        </div>
        <div className="request-list-column">
          <span>Check Date</span>
        </div>
        <div className="request-list-column">
          <span>Check #</span>
        </div>
        <div className="request-list-column">
          <span>Amount</span>
        </div>
        <div className="request-list-column">
          <span>Received On</span>
        </div>
      </div>
    </Fragment>
  );
};
const renderBody = (currentId, refunds, onClick) => {
  if (!refunds || refunds.length === 0) return <div className="request-list-body" />;
  return (
    <div className="request-list-body">
      {refunds.map((refund, index) => {
        const date = moment.utc(refund.checkDate).format("M/D/YY");
        const receivedOn = refund.receivedOn ? moment.utc(refund.receivedOn).format("M/D/YY") : "Not yet received";
        const clsName = currentId === refund.id ? "request-list-row request-list-row-selected" : "request-list-row";
        return (
          <button className={clsName} key={refund.id} onClick={onClick.bind(null, refund)}>
            <div>
              <div className="request-list-column">
                <span>{moment.utc(refund.submitted).format("M/D/YY")}</span>
              </div>
              <div className="request-list-column">
                <span>{date}</span>
              </div>
              <div className="request-list-column">
                <span>{refund.checkNumber}</span>
              </div>
              <div className="request-list-column">
                <span>{formatCurrencyNoDecimalWithNegativeStyle(refund.amount)}</span>
              </div>
              <div className="request-list-column">
                <span>{receivedOn}</span>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};
const RequestList = ({ currentId, refunds, onLoadCurrent, onClick }) => {
  return (
    <div className="scholarship-refund-list">
      <button className="btn" onClick={onLoadCurrent}>
        Current refund List
      </button>
      {renderHeader()}
      {renderBody(currentId, refunds, onClick)}
    </div>
  );
};

RequestList.propTypes = {
  refunds: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      date: PropTypes.date,
      submitted: PropTypes.date,
      checkNumber: PropTypes.number
    })
  ),
  onClick: PropTypes.func.isRequired,
  onLoadCurrent: PropTypes.func.isRequired,
  currentId: PropTypes.string
};
export default RequestList;
