import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import { getStudentGrades, addStudentGrades, updateStudentGrades, deleteStudentGrades } from "../../api"
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import SimpleDialog from "../../../common/simpleDialog";
import ReactTable from "react-table";
import LoadingButton from "../../../common/loadingButton";
import EditIcon from '@mui/icons-material/Edit';
import {
    Grid,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
function generateSchoolYears() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    // If the current date is before September 1st, subtract one year
    if (currentDate < new Date(currentYear, 8, 1)) { // Months are 0-indexed
        currentYear--;
    }

    const dateRanges = [];
    for (let i = 0; i < 12; i++) {
        const beginDate = new Date(currentYear - i, 8, 1); // September 1st
        const endDate = new Date(currentYear - i + 1, 7, 31); // August 31st


        const formattedBeginDate = [

            String(beginDate.getMonth() + 1).padStart(2, '0'),
            String(beginDate.getDate()).padStart(2, '0'),
            beginDate.getFullYear(),
        ].join('/');

        const formattedEndDate = [

            String(endDate.getMonth() + 1).padStart(2, '0'),
            String(endDate.getDate()).padStart(2, '0'),
            endDate.getFullYear(),
        ].join('/');

        const label = `${currentYear - i} - ${currentYear - i + 1}`;

        dateRanges.push({
            label,
            beginDate: formattedBeginDate,
            endDate: formattedEndDate
        });
    }

    return dateRanges;
}

class Academics extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            student: this.props.student,
            activeEntry: {}, 
            data: [],
            yearList: generateSchoolYears(),
            showForm: false,
            mode: ""
        };

    }



    componentDidMount = async () => {
        const grades = await getStudentGrades(this.state.student.userProfile.id);
 
        this.setState({ data: grades.data.result });

    }
    componentDidUpdate(prevProps, prevState) {



    }

    handleInputChange(rowIndex, columnId, value) {

        let dataUpdate = {};
        this.setState(prevState => {
            const newData = prevState.data.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [columnId]: value };
                }
                dataUpdate = row;
                return row;
            });
            return { data: newData };
        });
        updateStudentGrades(dataUpdate);
    }
    cancelForm = () => {
        this.setState({ activeEntry: [], showForm: false, mode: "" });
    }

    editRow = (entry) => {
        this.setState({ activeEntry: entry, showForm: true, mode: "edit" });

    }

    addRow=()=> {
        const newRow = {
            id: uuidv4(),
            schoolYear: this.state.yearList[0].label,
            schoolId: this.state.student.school.id,
            userProfileId: this.state.student.userProfile.id,
            numericGrade: 0,
            letterGrade: '',
            studentGrade: this.state.student.studentProfile.currentGrade,
            courseName: '',
            courseType: '',
            courseId: '',
            schoolYearTerm: '',
        };
        this.setState({ activeEntry: newRow, showForm: true, mode: "new"});
        
    }


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            activeEntry: {
                ...prevState.activeEntry,
                [name]: value
            }
        }));
    };


  handleSubmit = async (event) => {
    event.preventDefault();
      
      if (this.state.mode == "new") {
          const newData = await addStudentGrades(this.state.activeEntry);
          this.setState({ activeEntry: [], showForm: false, mode: "", data: newData.data.result });
          toast.success("Grades saved");
      } else {
          const newData = await updateStudentGrades(this.state.activeEntry);
          this.setState({ activeEntry: [], showForm: false, mode: "", data: newData.data.result });
          toast.success("Grades updated.");
      }
      
      
  };

    render() {
        let columns = [
            {
                Header: "Edit",
                accessor: "id",
                width: 50,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} ><EditIcon onClick={() => this.editRow(row.original)} /></div>
                ),

            }, 
            

            {
                Header: "Course Type",
                accessor: "courseType",
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.courseType}</div>
                ),

            }
            ,
            {
                Header: "Course Id",
                accessor: "courseId",
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.courseId}</div>
                ),

            }
            ,
            {
                Header: "Course Name",
                accessor: "courseName",
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.courseName}</div>
                ),

            },

            {
                Header: "School Quarter",
                accessor: "schoolYearTerm",
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.schoolYearTerm}</div>
                ),

            },


            {
                Header: "School Name",
                accessor: "schoolId",
                Cell: (row) => {
                    
                    const schools = this.context.schoolList.filter(flt => flt.id == row.original.schoolId);
                    
                    const school = schools[0].name;
                    return <div className="dataCell" style={{ textAlign: "center" }}>{school}</div>;
                },

            },
            {
                Header: "Numeric Grade",
                accessor: "numericGrade",
                width: 75,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.numericGrade}</div>
                ),

            },
            {
                Header: "Student Grade",
                accessor: "studentGrade",
                width: 75,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }}>{row.original.studentGrade}</div>
                ),

            },
            {
                Header: "School Year",
                accessor: "schoolYear",
                width: 100,
                Cell: row => (
                    <div className="dataCell" style={{ textAlign: "center" }} >{row.original.schoolYear}</div>
                ),

            }

        ];

        return (
            <>

                <LoadingButton
                    wrapperClassName={"test"}
                    className={"test"}
                    variant="contained"
                    color="primary"
                    onClick={this.addRow}
                >
                    Add Course
                </LoadingButton>

                <ReactTable
                    data={this.state.data}
                    minRows={0}
                    columns={columns}
                    defaultPageSize={50}
                    className="-striped -highlight tableWrapper"
                />



                <SimpleDialog open={this.state.showForm} onClose={this.cancelForm} title="Course Grade">
                    <form onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="school-year-label">School Year</InputLabel>
                                    <Select
                                        labelId="school-year-label"
                                        value={this.state.activeEntry.schoolYear}
                                        onChange={this.handleChange}
                                        name="schoolYear"
                                    >
                                        {this.state.yearList.map((year) => (
                                            <MenuItem key={year.label} value={year.label}>
                                                {year.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="school-label">School</InputLabel>
                                    <Select
                                        labelId="school-label"
                                        value={this.state.activeEntry.schoolId}
                                        onChange={this.handleChange}
                                        name="schoolId"
                                    >
                                        {this.context.schoolList.map((school) => (
                                            <MenuItem key={school.id} value={school.id}>
                                                {school.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Hidden field for UserProfileId */}
                            <input
                                type="hidden"
                                name="userProfileId"
                                value={this.state.activeEntry.userProfileId}
                            />

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    name="numericGrade"
                                    label="Numeric Grade"
                                    value={this.state.activeEntry.numericGrade}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="letterGrade"
                                    label="Letter Grade"
                                    value={this.state.activeEntry.letterGrade}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="student-grade-label">Student Grade</InputLabel>
                                    <Select
                                        labelId="student-grade-label"
                                        value={this.state.activeEntry.studentGrade}
                                        onChange={this.handleChange}
                                        name="studentGrade"
                                    >
                                        {this.context.gradeList.map((grade) => (
                                            <MenuItem key={grade.value} value={grade.value}>
                                                {grade.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="courseName"
                                    label="Course Name"
                                    value={this.state.activeEntry.courseName}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="course-type-label">Course Type</InputLabel>
                                    <Select
                                        labelId="course-type-label"
                                        value={this.state.activeEntry.courseType}
                                        onChange={this.handleChange}
                                        name="courseType"
                                    >
                                        <MenuItem value="English">English</MenuItem>
                                        <MenuItem value="History">History</MenuItem>
                                        <MenuItem value="Math">Math</MenuItem>
                                        <MenuItem value="Science">Science</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="courseId"
                                    label="Course ID"
                                    value={this.state.activeEntry.courseId}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="schoolYearTerm"
                                    label="School Year Term"
                                    value={this.state.activeEntry.schoolYearTerm}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </SimpleDialog>

            </>
        );
    }

}

export default Academics;