import React, { Component } from "react";
import Link from "@material-ui/core/Link";
import SimpleDialog from "../simpleDialog";
import * as XLSX from 'xlsx';
import DualListBox from "./components/duallistbox";
import "./index.css";
import enumHelper from "../../support/logic/enumHelper";
import { scholarshipLevel, scholarshipStatus,   } from "../../scholarship/enums";
import { StudentStatus, SchoolType, UserStatus } from "../../support/enums";
import { Race, Gender, CitizenshipType, Language } from "../../admissions/logic/enums";
import Button from '@mui/material/Button';
import moment from "moment";



const isGuid = (value) => {

    return typeof value === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value);
};

const PrimaryPhoneType = (value) => {

    switch (value) {
        case 0, "0":
            return "Home";
        case 1, "1":
            return "Home";
        case 2, "2":
            return "Mobile";
        case 3, "3":
            return "Work";
        default:
            return value;
    }
}
class Reporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            data: [],
            potFields: [],
            selFields: [],
            fileName: "DataExport",
            dataloaded: false

        };
        this.dualListBoxRef = React.createRef();
    };

    componentDidMount() {
        if (this.props.data != null && this.props.data.length > 0) {
            this.updateMap();
        }
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.data !== prevProps.data && this.props.data != []) {
            this.updateMap();
           
        }
        
    }
    updateMap = () => {
        let sFld = [];

        let mapper = [];

        mapper.push(this.props.data[0]);

        let potFld = this.listObjectProperties(mapper);
        const uniqueArray = potFld.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.value === obj.value
            ))
        );
        potFld = uniqueArray;
        if (this.props.doNotExport && this.props.doNotExport.length > 0) {

            potFld = potFld.filter(element => !this.props.doNotExport.includes(element.value));
        }







        if (this.state.selFields.length == 0 && this.props.preSelected != null && this.props.preSelected.length > 0 && !this.state.dataloaded) {
            this.props.preSelected.forEach(val => {
                sFld.push({ name: this.prepName(val), value: val });
            });

        } else {
            sFld = this.state.selFields;
        }
        const resultArray = potFld.filter(element => !sFld.includes(element));
        this.setState({
            data: this.props.data,
            potFields: resultArray,
            selFields: sFld,
            dataloaded: true,
        });

    }

    dualListCallback = (newArray) => {
        this.setState({ selFields: newArray });

    }
    openDialog = () => {
        this.setState({ showDialog: true });
        
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }
    getProperties = (obj, parentKey = '') => {
        const properties = [];
        for (const [key, value] of Object.entries(obj)) {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            if (value && typeof value === 'object' && !Array.isArray(value)  && !isGuid(value)) {
                const nVals = [...this.getProperties(value, newKey)];
                
                nVals.forEach(nVal => {
                    
                    

                    properties.push(nVal);
                });

            } else {
                ;
                if (!Array.isArray(value) && !isGuid(value)) {
                    properties.push({ name: newKey, value: newKey, type: typeof value });
                }
                
            }
        }
        properties.forEach(pr => {
            pr.name = this.prepName(pr.name);
        })

        return properties;
    }
    listObjectProperties = (objectArray) => {
        // Check if the input is not null and is an array
        if (!Array.isArray(objectArray)) {
            console.error("Invalid input: Expected an array");
            return [];
        }

        // Filter out non-object elements and nulls from the array
        const filteredArray = objectArray.filter(obj => obj && typeof obj === 'object');

        // Flatten the array and filter out duplicates using a Set
        return [...new Set(filteredArray.flatMap(obj => this.getProperties(obj)))];
    }



    exportToExcel = () => {
        
        const data = this.props.data.map(srec => {
            const record = {};
            this.state.selFields.forEach(field => {
                // Use field.value to access the property of srec
                // and field.name as the key in the record object
                const valueByPath = field.value.split('.').reduce((o, k) => (o || {})[k], srec);
                
                record[field.name] = this.retValue(field.name, valueByPath);
            });
            return record;
        });

        const ws = XLSX.utils.json_to_sheet(data); // Convert data to worksheet
        const wb = XLSX.utils.book_new(); // Create a new workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Append the worksheet to the workbook

        // Write and save file
        XLSX.writeFile(wb, this.state.fileName + Date.now().toString() + ".xlsx");

        this.setState({
            showDialog: false     

        });

    };

    addField = (field) => {
        const potAr = this.state.potFields.filter(element => field);
        let selAr = this.state.selFields;
        selAr.push(field);
        this.setState({
            potFields: potAr,
            selFields: selAr
        });
    }

    prepName = (str) => {
        let mName = str.replace('.', ': ');
        mName = this.addSpaceBeforeCapitals(mName);
        mName = this.toProperCase(mName);

        return mName;
    }

    addSpaceBeforeCapitals = (str) => {
        return str.replace(/([A-Z])/g, (match, p1, offset) => {
            // Add a space before the capital letter only if it's not at the start of the string and not preceded by a space
            return offset > 0 && str[offset - 1] !== ' ' ? ' ' + p1 : p1;
        });
    }

    toProperCase = (str) => {
        return str.replace(/\w\S*/g, (word) => {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        });
    }

    retValue = (key, value) => {



        switch (key) {
            case 'Family: Secondary Parent: Gender':
            case 'Family: Primary Parent: Gender':
            case 'User Profile: Gender':
            case 'Advisor: Gender':
                return enumHelper.getDisplayName(Gender, value);
                break;
            case 'School: Type':
                return enumHelper.getDisplayName(SchoolType, value);
                break;
            case 'Student Contact Info: Last Contacted':
                if (value != null) {
                    return moment(value).format("M/D/YY h:mm a");

                }
                return value;
                break;
            case 'Student Profile: Status':
                return enumHelper.getDisplayName(StudentStatus, value);
                break;
            case 'Student Profile: Scholarship Level':
                return enumHelper.getDisplayName(scholarshipLevel, value);
                break;

            default:
                if (key.includes('Primary Phone')) {
                    return PrimaryPhoneType(value);
                }
                if (key.includes('Primary Language')) {
                    return enumHelper.getDisplayName(Language, value);
                }
                
                if (key.includes(': Race')) {
                    return enumHelper.getDisplayName(Race, value);
                }
                if (key.includes(': Citizenship Type')) {
                    return enumHelper.getDisplayName(CitizenshipType, value);
                }
                if (key.includes(': Creation Time')) {
                    if (value != null) {
                        return moment(value).format("M/D/YY h:mm a");

                    }
                    
                        return value;
                    
                    
                }
                if (key.includes(': Best Time Call')) {
                    if (value != null) {

                        return moment(value).format("h:mm a");
                    }
                   
                        return value;
                  
                    
                }

                if (key.includes('Date Of Birth')) {
                    if (value != null) {

                        return moment(value).format("M/D/YY");
                    }
                   
                        return value;
                   

                }
                return value;
        }

    }

    render() {
      

        return (
            <React.Fragment>

                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    className={"fieldSelectorButton",  this.props.className}
                    onClick={this.openDialog}
                >
                    {this.props.label}
                </Button>
               

                <SimpleDialog open={this.state.showDialog} onClose={this.closeDialog} title="Export" >
                    <table>
                        <tr>
                            <td> 

                                <DualListBox ref={this.dualListBoxRef} optFields={this.state.potFields} selected={this.state.selFields} selectionCallback={this.dualListCallback } />
                            </td>                         
                        </tr>
                        <tr>
                            <td>
                            <br/>
                                <Link
                                    className={"headerlink"}
                                    onClick={this.exportToExcel}
                                >
                                    &nbsp; &nbsp; Export &nbsp; &nbsp;
                                </Link>
                            </td>
                        </tr>
                    </table>
                    

                    


                </SimpleDialog>
            </React.Fragment>
        );
    }
}

export default Reporting;
