import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import LoadingButton from "../../../common/loadingButton";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    bodyContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(4, 0, 4, 0),
        gap: theme.spacing(1),
    },
    inputContainer: {
        display: "flex",
        alignSelf: "stretch",
        gap: theme.spacing(1),
    },
    addBtn: {
        color: "#fff",
        fontWeight: "bold",
        padding: theme.spacing(1, 2),
    },
}));

const InviteParticipantsDialog = ({ newInvites, onClose, onSubmit, loading, ...rest }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async () => {
        await onSubmit();
        handleClose();
    };

    return (
        <Dialog onClose={handleClose} {...rest}>
            <DialogTitle>
                Invite Additional Participants?
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} disabled={loading}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">{`Text/Email will be sent to ${newInvites.totalInvitesWithAccounts} NEW participant(s) inviting them to this event.`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleClose} disabled={loading}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        handleSubmit();
                    }}
                    loading={loading}
                >
                    Yes, Invite
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default InviteParticipantsDialog;
