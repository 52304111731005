import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { Prompt, useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import BackButton from "../../../common/backButton";
import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LoadingButton from "../../../common/loadingButton";
import { FinalFormCheckbox, FinalFormDropdown, FinalFormKeyboardDatePicker, FinalFormRadioGroup, FinalFormTextField } from "../../../common/formWrappers";
import LoadingIndicator from "../../../common/loadingIndicator";
import IPEDLookup from "../../../common/ipedLookup";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import enumHelper from "../../../support/logic/enumHelper";
import DeDupe from "./dedupe";
import {
    composeValidators,
    email,
    isPhoneNumber,
    maxLength,
    maxValue,
    minValue,
    mustBeNumber,
    required,
    requiredAllowZero,
} from "../../../common/formValidators";
import { useInstitution, useSchool } from "../../../events/hooks";
import { PhoneMaskInput, ZipCodeMask } from "../../../common/masks";
import { scholarshipLevel } from "../../../scholarship/enums";
import useCrusaderUser from "../hooks/useCrusaderUser";
import AccountLoginForm from "./AccountLoginForm";
import { toast } from "react-toastify";
import { updatePassword, updateUsername, getIpeds, getPotentialDupes } from "./apis";
import { states as stateOptions, USTerritories } from "../../../support/states";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { getData } from "../../../api/documents";
import b64toBlob from "../../../support/logic/b64toBlob";
import FileSaver from "file-saver";
import { Gender } from "../../../admissions/logic/enums";
import { StudentStatus } from "../../../support/enums";
import { getList as getCities } from "../../../api/city";

const focusOnError = createDecorator();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: "80%",
    },
    formHeader: {
        display: "grid",
        gridTemplateColumns: "70px auto 70px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"backButton title saveButton"`,
        marginBottom: theme.spacing(3),
        position: "sticky",
        top: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: "#ffffff",
        zIndex: 1,
    },
    backButton: {
        gridArea: "backButton",
    },
    formTitle: {
        gridArea: "title",
        justifySelf: "center",
    },
    saveButtonContainer: {
        gridArea: "saveButton",
        justifySelf: "end",
    },
    parentMenuIconBtn: {
        marginLeft: "auto",
    },
}));

const genderOptions = enumHelper.getSelectOptionsList(Gender);
const studentStatusOptions = enumHelper.getSelectOptionsList(StudentStatus);
const scholarshipLevelOptions = enumHelper.getSelectOptionsList(scholarshipLevel);

const CrusaderUserForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { crusaderUser, loading, loadCrusaderUserAsync, updateCrusaderAsync } = useCrusaderUser();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const { schoolList, loading: loadingSchoolList } = useSchool();
    const [loadingFerpaForm, setLoadingFerpaForm] = useState(false);
    const schoolOptions = useMemo(() => schoolList.map((school) => ({ value: school.id, label: school.name })), [schoolList]);
    const { institutionList, loading: loadingInstitutionList, ipedList } = useInstitution();
    const institutionOptions = useMemo(() => institutionList.map((institution) => ({ value: institution.id, label: institution.name })), [institutionList]);
    const [ipedName, setIpedName] = useState("");
    const [cityOptions, setCityOptions] = useState([]);
    const [dupes, setDupes] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await loadCrusaderUserAsync(id);
                getPotentialDupes(id)
                    .then((response) => {

                        const users = response.data.result;

                        setDupes(users);

                    })
                    .catch((error) => {
                        this.props.ajaxStatusError(error.message);
                    });
                
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        // Call the async function
        fetchData();

    }, [id, loadCrusaderUserAsync]);

    useEffect(() => {
        const getCityOptions = async () => {
            const cities = await getCities();
            const formattedCities = cities.data.result.map((city) => {
                return { label: city.name, value: city.name };
            });
            setCityOptions(formattedCities);
        }
        getCityOptions();
    }, []);

    useEffect(() => {

        
        if (crusaderUser != null && crusaderUser.institutionIPEDNo != null && ipedList != null && ipedList.length > 0) {
            const ipeds = ipedList.filter(flt => flt.ipedNo == crusaderUser.institutionIPEDNo);
            if (ipeds.length == 1) {
                setIpedName(ipeds[0].name);
               
            }
        }

    }, [crusaderUser, ipedList]);

    const handleFormSubmit = async (values) => {
        const payload = {
            ...values,
            emergencyContacts: values.emergencyContacts.filter((contact) => contact.name?.trim()?.length > 0),
        };
        await updateCrusaderAsync(payload);
    };

    const handleUsernameSubmit = async ({ username }) => {
        try {
            await updateUsername({ userId: crusaderUser.user.userId, username });
            toast.success("Username updated successfully");
        } catch (error) {
            ajaxErrorWrapper(error);
        }
    };

    const handlePasswordSubmit = async ({ password, confirmPassword }) => {
        try {
            await updatePassword({ userId: crusaderUser.user.userId, password, confirmPassword });
            toast.success("Password updated successfully");
        } catch (error) {
            ajaxErrorWrapper(error);
        }
    };
    const onUserUpdated = async () => {
        await loadCrusaderUserAsync(id);

    }
    const handleFerpaFormClick = async () => {
        const { ferpaDocumentId, firstName, lastName } = crusaderUser;
        try {
            setLoadingFerpaForm(true);
            const documentDataResponse = await getData(ferpaDocumentId);
            const blob = b64toBlob(documentDataResponse.data.result.data, documentDataResponse.data.result.mimeType);
            const filename = `${firstName.replace(/[^\w]/gi, "")}_${lastName.replace(/[^\w]/gi, "")}_ferpa.pdf`;
            FileSaver.saveAs(blob, filename);
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoadingFerpaForm(false);
        }
    };

    const handleValidate = (values) => {
        const errors = {
            emergencyContacts: [],
        };

        const firstContact = values.emergencyContacts[0];
        const secondContact = values.emergencyContacts[1];
        const thirdContact = values.emergencyContacts[2];

        if (firstContact?.name?.length > 0 && !firstContact?.relationship?.length) {
            errors.emergencyContacts[0] = { relationship: "This field is required" };
        }
        if (firstContact?.name?.length > 0 && !firstContact?.phoneNumber?.length) {
            errors.emergencyContacts[0] = { ...errors.emergencyContacts[0], phoneNumber: "This field is required" };
        }

        if (secondContact?.name?.length > 0 && !secondContact?.relationship?.length) {
            errors.emergencyContacts[1] = { relationship: "This field is required" };
        }
        if (secondContact?.name?.length > 0 && !secondContact?.phoneNumber?.length) {
            errors.emergencyContacts[1] = { ...errors.emergencyContacts[1], phoneNumber: "This field is required" };
        }

        if (thirdContact?.name?.length > 0 && !thirdContact?.relationship?.length) {
            errors.emergencyContacts[2] = { relationship: "This field is required" };
        }
        if (thirdContact?.name?.length > 0 && !thirdContact?.phoneNumber?.length) {
            errors.emergencyContacts[2] = { ...errors.emergencyContacts[2], phoneNumber: "This field is required" };
        }

        return errors;
    };

    if (loading || loadingSchoolList || loadingInstitutionList) {
        return <LoadingIndicator loading />;
    }

    return (
        <Form
            onSubmit={handleFormSubmit}
            mutators={{ ...arrayMutators }}
            decorators={[focusOnError]}
            initialValues={{
                ...crusaderUser,
                otherInstitutionName: ipedName,
                dateOfBirth: moment(crusaderUser.dob),
                parents: crusaderUser.parents.map((p) => ({
                    ...p,
                    anchorEl: React.createRef(),
                    showMenu: false,
                    
                })),
            }}
            validate={handleValidate}
        >
            {({ handleSubmit, form, values, dirty, errors }) => {
                
                return (
                    <form className={classes.root} onSubmit={handleSubmit} noValidate>
                        <Prompt
                            when={dirty}
                            message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, stay here"}
                        />
                        <div className={classes.formHeader}>
                            <div className={classes.backButton}>
                                <BackButton />
                            </div>
                            <Typography className={classes.formTitle} variant="h5">{`Edit: ${values.firstName} ${values.lastName}`}</Typography>
                            <LoadingButton
                                wrapperClassName={classes.saveButtonContainer}
                                className={classes.saveButton}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Save
                            </LoadingButton>
                        </div>
                        <DeDupe dupes={dupes} cityOptions={cityOptions} activeUp={crusaderUser} reload={loadCrusaderUserAsync} />
                        <AccountLoginForm user={crusaderUser.user} onUsernameSubmit={handleUsernameSubmit} onPasswordSubmit={handlePasswordSubmit} userProfileId={id} onUserUpdated={onUserUpdated} />
                        <Typography variant="h5" color="secondary" gutterBottom>
                            GENERAL
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            
                            <Field
                                component={FinalFormTextField}
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                margin="dense"
                                required
                                validate={composeValidators(required, maxLength(50))}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="middleName"
                                label="Middle Name"
                                placeholder="Middle Name"
                                margin="dense"
                                validate={maxLength(50)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                margin="dense"
                                required
                                validate={composeValidators(required, maxLength(50))}
                            />
                            <label className="MuiFormLabel-root">Onward Id: </label> <span className="studentFieldValue">{crusaderUser.crusaderId}</span>
                            <br/>
                            <label className="MuiFormLabel-root">Passcode:</label>  <span className="studentFieldValue">{crusaderUser.passcode}</span>
                            <br />
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="en">
                                <Field component={FinalFormKeyboardDatePicker} name="dateOfBirth" label="DOB" validate={required} />
                            </MuiPickersUtilsProvider>
                            <Field
                                row
                                name="gender"
                                fullWidth
                                format={(value) => {
                                    return value ? value.toString() : "0";
                                }}
                                parse={(value) => {
                                    return parseInt(value, 0);
                                }}
                                component={FinalFormRadioGroup}
                                choices={genderOptions}
                                required
                                validate={requiredAllowZero}
                            />
                            {values.gender === Gender.Other && (
                                <Field
                                    component={FinalFormTextField}
                                    name="genderDescription"
                                    label="Description"
                                    placeholder="Description"
                                    margin="dense"
                                    required
                                    validate={composeValidators(required, maxLength(50))}
                                />
                            )}
                            {values.cohort > 0 && (
                                <> <label className="MuiFormLabel-root">Cohort Number:</label> <span className="studentFieldValue">{crusaderUser.cohort}</span></>
                            )}
                            {values.cohort < 0 && (
                                <> <label className="MuiFormLabel-root">Cohort Number:</label> <span className="studentFieldValue">Opt Out ({crusaderUser.cohort})</span></>
                            )}
                            <br />
                            <Field
                                component={FinalFormDropdown}
                                name="status"
                                variant="outlined"
                                margin="dense"
                                label="Status"
                                choices={studentStatusOptions}
                                required
                                validate={required}
                            />
                            <Field component={FinalFormCheckbox} name="gap" label="Gap" />
                            <Field component={FinalFormCheckbox} name="honorific" label="Honorific" />
                            <Field component={FinalFormCheckbox} name="accelerate" label="Accelerate" />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            SCHOOL
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormDropdown}
                                name="schoolId"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="School"
                                choices={schoolOptions}
                                placeholder="Select a School..."
                                required
                                validate={required}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="currentGrade"
                                label="Grade"
                                placeholder="5-19"
                                validate={composeValidators(required, mustBeNumber, minValue(5))}
                                margin="dense"
                            />
                            <Field
                                component={FinalFormTextField}
                                name="academicInterests"
                                label="Academic Interests"
                                placeholder="Academic Interests"
                                margin="dense"
                                validate={maxLength(500)}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            CONTACT
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="email"
                                label="Email"
                                placeholder="Email"
                                margin="dense"
                                validate={composeValidators(email, maxLength(320))}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="mobilePhone"
                                label="Cell Phone Number"
                                placeholder="Cell Phone Number"
                                margin="dense"
                                inputComponent={PhoneMaskInput}
                                validate={composeValidators(isPhoneNumber)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="address1"
                                label="Street Address Line 1"
                                placeholder="Street Address Line 1"
                                margin="dense"
                                required
                                validate={composeValidators(required, maxLength(46))}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="address2"
                                label="Street Address Line 2"
                                placeholder="Street Address Line 2"
                                margin="dense"
                                validate={maxLength(46)}
                            />
                            <Field
                                component={FinalFormDropdown}
                                name="city"
                                label="City"
                                placeholder="City"
                                margin="dense"
                                choices={cityOptions}
                                required
                                validate={required} />
                            <Field
                                component={FinalFormDropdown}
                                name="state"
                                variant="outlined"
                                margin="dense"
                                label="State"
                                choices={[...stateOptions, ...USTerritories]}
                                required
                                validate={required}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="zipCode"
                                label="Zip Code"
                                margin="dense"
                                fullWidth
                                required
                                validate={required}
                                inputComponent={ZipCodeMask}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            PARENT/LEGAL GUARDIAN
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            {values.parents.length === 0 && (
                                <Typography variant="body1" gutterBottom>
                                    No parents linked to this student.
                                </Typography>
                            )}
                            {values.parents
                                .sort((a, b) => a.contactType - b.contactType)
                                .map((parent, index) => {
                                    const parentIndex = values.parents.indexOf(parent);
                                    const toggleMenu = () =>
                                        form.mutators.update("parents", parentIndex, {
                                            ...values.parents[parentIndex],
                                            showMenu: !values.parents[parentIndex].showMenu,
                                        });
                                    return (
                                        <Box key={parent.userProfileId} display="flex" alignItems="center">
                                            <Typography variant="body1">
                                                <strong>Parent #{index + 1}:</strong>
                                                &nbsp;
                                                {parent.userProfile.firstName}
                                                &nbsp;
                                                {parent.userProfile.lastName}
                                            </Typography>
                                            <IconButton
                                                aria-label="options"
                                                className={classes.parentMenuIconBtn}
                                                ref={values.parents[parentIndex].anchorEl}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    toggleMenu();
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={values.parents[parentIndex].anchorEl.current}
                                                keepMounted
                                                open={values.parents[parentIndex].showMenu}
                                                onClose={toggleMenu}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        toggleMenu();
                                                        const win = window.open(`/admin/users/parent/${parent.userProfileId}`, "_blank");
                                                        win.focus();
                                                    }}
                                                >
                                                    View profile
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                })}
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            EVENTS
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field component={FinalFormTextField} name="serviceHours" label="Service Hours" margin="dense" disabled />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            HEALTH
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="allergies"
                                label="Allergies"
                                placeholder="Allergies"
                                margin="dense"
                                validate={maxLength(100)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="medications"
                                label="Medications"
                                placeholder="Medications"
                                margin="dense"
                                validate={maxLength(500)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="dietaryRestrictions"
                                label="Dietary Restrictions"
                                placeholder="Dietary Restrictions"
                                margin="dense"
                                validate={maxLength(500)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="medicalConditions"
                                label="Medical Conditions"
                                placeholder="Medical Conditions"
                                margin="dense"
                                validate={maxLength(500)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="specialAccommodations"
                                label="Special Accommodations"
                                placeholder="Special Accommodations"
                                margin="dense"
                                multiline
                                maxLength={5000}
                                maxRows={4}
                                validate={maxLength(5000)}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            EMERGENCY CONTACTS
                        </Typography>
                        <Box display="flex" marginBottom={2} flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[0].name"
                                label="Name"
                                placeholder="Name"
                                margin="dense"
                                validate={composeValidators(maxLength(50))}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[0].relationship"
                                label="Relationship to Crusader"
                                placeholder="Relationship to Crusader"
                                margin="dense"
                                disabled={!values.emergencyContacts[0]?.name?.length}
                                required={values.emergencyContacts[0]?.name?.length > 0}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[0].phoneNumber"
                                label="Phone"
                                placeholder="Phone"
                                margin="dense"
                                inputComponent={PhoneMaskInput}
                                disabled={!values.emergencyContacts[0]?.name?.length}
                                required={values.emergencyContacts[0]?.name?.length > 0}
                                validate={isPhoneNumber}
                            />
                        </Box>
                        <Box display="flex" marginBottom={2} flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[1].name"
                                label="Name"
                                placeholder="Name"
                                margin="dense"
                                validate={maxLength(50)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[1].relationship"
                                label="Relationship to Crusader"
                                placeholder="Relationship to Crusader"
                                margin="dense"
                                disabled={!values.emergencyContacts[1]?.name?.length}
                                required={values.emergencyContacts[1]?.name?.length > 0}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[1].phoneNumber"
                                label="Phone"
                                placeholder="Phone"
                                margin="dense"
                                inputComponent={PhoneMaskInput}
                                disabled={!values.emergencyContacts[1]?.name?.length}
                                required={values.emergencyContacts[1]?.name?.length > 0}
                                validate={isPhoneNumber}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[2].name"
                                label="Name"
                                placeholder="Name"
                                margin="dense"
                                validate={maxLength(50)}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[2].relationship"
                                label="Relationship to Crusader"
                                placeholder="Relationship to Crusader"
                                margin="dense"
                                disabled={!values.emergencyContacts[2]?.name?.length}
                                required={values.emergencyContacts[2]?.name?.length > 0}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="emergencyContacts[2].phoneNumber"
                                label="Phone"
                                placeholder="Phone"
                                margin="dense"
                                inputComponent={PhoneMaskInput}
                                disabled={!values.emergencyContacts[2]?.name?.length}
                                required={values.emergencyContacts[2]?.name?.length > 0}
                                validate={isPhoneNumber}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            SCHOLARSHIP SECTION
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormDropdown}
                                name="scholarshipLevel"
                                variant="outlined"
                                margin="dense"
                                label="Level"
                                choices={scholarshipLevelOptions}
                            />
                            
                            
                            
                            <Field
                                component={FinalFormDropdown}
                                name="selectedInstitutionId"
                                variant="outlined"
                                margin="dense"
                                label="Selected Institution"
                                choices={institutionOptions}
                            /> {values.selectedInstitutionId === "ad774e92-61b4-44be-b710-7b0e8a196a83" && (
                                <>
                                    <label class="MuiFormLabel-root jss517 MuiFormLabel-filled">Other Institution</label>
                                    <IPEDLookup name="institutionIPEDNo"
                                        ipedList={ipedList}
                                        ipedNo={values.institutionIPEDNo} />
                                    
                                </>
                            )}
                            <Field component={FinalFormTextField} name="collegeId" label="College ID" placeholder="College ID" margin="dense" />
                            <Field component={FinalFormCheckbox} name="maxAllotmentReached" label="Max Scholarship Allotment Reached" />
                            <LoadingButton
                                color="primary"
                                variant="contained"
                                loading={loadingFerpaForm}
                                onClick={handleFerpaFormClick}
                                disabled={!crusaderUser?.ferpaDocumentId}
                            >
                                Signed Ferpa Form
                            </LoadingButton>
                        </Box>
                    </form>
                );
            }}
        </Form>
    );
};

export default CrusaderUserForm;
