import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import { Typography, Grid } from '@material-ui/core';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const lineColors = ['#123225', '#e2a331', 'blue', 'red', 'purple', 'orange', 'yellow', 'navy', 'maroon', 'teal', 'chartreuse', 'greenyellow', 'lightcoral', 'olive', 'orchid'];


class TrendsGraph extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
           


        };
    }



    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {

    }
    render() {

        //console.log(this.props);

        
        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={this.props.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {this.props.lines.map((line, index) => (
                        <Line
                            key={index}
                            type="monotone"
                            dataKey={line}
                            stroke={lineColors[index % lineColors.length]} // Cycle through lineColors
                            activeDot={{ r: 8 }}
                        />
                    ))}
                    
                    
                </LineChart>
            </ResponsiveContainer>
        );



    }

}

export default TrendsGraph;
