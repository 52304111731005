import React, { Component } from "react";
import FileSaver from "file-saver";
import PropTypes from "prop-types";
import moment from "moment";
import FontAwesome from "react-fontawesome";
import Datetime from "react-datetime";
import { formatSSN } from "../../support/formatStrings";
import b64toBlob from "../../../support/logic/b64toBlob";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import * as apis from "../../apis";
import * as ScholarshipCertificationApi from "../../../api/scholarshipCertification";

import "./StudentSearch.css";
import SearchBox, { searchType } from "../../components/searchBox";

const formatAlien = (alien) => {
    return `A${alien}`;
};
const getIdNumber = (student) => {
    if (student.ssn) return formatSSN(student.ssn);
    if (student.alien) return formatAlien(student.alien);
    return "unknown id";
};
const formatDob = (dob) => {
    const dt = moment(dob);
    if (!dt.isValid()) return "unk dob";
    return dt.format("M/D/YYYY");
};
const formatSchoolName = (name) => {
    if (!name) return "(Not Certified)";
    return name;
};
class StudentSearch extends Component {
    state = {
        students: [],
        searchText: "",
        scholarship: null,
        showDownloadBtn: true,
        ferpaStartDate: null,
        selectedSchoolDate: null,
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scholarship !== prevState.scholarship) {
            return {
                scholarship: nextProps.scholarship,
                searchText: "",
                students: [],
            };
        }
        return {
            scholarship: nextProps.scholarship,
        };
    }
    onSearch = async () => {
        var searchText = this.search.value;
        if (!searchText) {
            this.setState({ students: [] });
            return;
        }
        await this.doSearch(searchText);
    };
    onSearchTextChange = (e) => {
        this.setState({
            searchText: e.target.value,
            students: [],
        });
    };
    onStudentSelect = (student) => {
        if (student.id === -1) {
            this.setState({ students: [], searchText: "" });
            return;
        }
        this.setState({
            students: [],
            //   searchText: `${student.lastName}, ${student.firstName}`,
            showDownloadBtn: false,
        });

        // this.props.history.push(`/scholarship/certify-students/${student.id}`);
        this.props.onStudentSelected(student);
    };

    onDownloadEligible = async () => {
        try {
            this.props.ajaxStatusLoading();

            const fileName = "eligible_crusaders.xlsx";
            const response = (await apis.exportEligibleCrusaders(this.state.scholarship.id, this.state.selectedSchoolDate)).data.result;

            this.props.ajaxStatusComplete();
            const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            FileSaver.saveAs(blob, fileName);
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    handleDownloadFerpaForms = async () => {
        try {
            this.props.ajaxStatusLoading();

            const response = (await apis.downloadFerpaForms(this.state.ferpaStartDate)).data.result;

            this.props.ajaxStatusComplete();
            const blob = b64toBlob(response.data, response.mimeType);
            FileSaver.saveAs(blob, response.fileName);
        } catch (err) {
            this.props.ajaxStatusError(
                err && err.response && err.response.data && err.response.data.error && err.response.data.error.details ? err.response.data.error.details : err
            );
        }
    };

    doSearch = async (searchText) => {
        try {
            this.props.ajaxStatusLoading();
            const { id } = this.state.scholarship;
            var results = await ScholarshipCertificationApi.searchStudent(id, searchText);

            var students = results.data.result;
            if (!students || students.length === 0) {
                students = [
                    {
                        id: -1,
                        name: "Not found",
                    },
                ];
            }
            this.setState({
                students,
            });

            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error searching students");
        }
    };
    renderStudents = (students) => {
        if (!students || students.length === 0) return null;
        if (students.length === 1 && students[0].id === -1) {
            return <button className="link-button">No students found</button>;
        }
        const list = students.map((student) => (
            <li key={student.id}>
                <button className="link-button" onClick={this.onStudentSelect.bind(null, student)}>
                    <div className="div-span-long">
                        {student.lastName},{student.firstName}
                    </div>
                    <div className="div-span-short"> {getIdNumber(student)}</div>
                    <div className="div-span-short">{formatDob(student.dob)}</div>
                    <div className="div-span-long">{formatSchoolName(student.schoolName)}</div>
                </button>
            </li>
        ));
        return <ul>{list}</ul>;
    };

    onSearchKeyPress = (event) => {
        if (event.key === "Enter") {
            this.onSearch();
        }
    };

    handleSearch = async (searchTerm) => {
        this.setState({ resultsLoading: true, showResults: false });
        const results = (await ScholarshipCertificationApi.searchStudent(this.state.scholarship.id, searchTerm)).data.result;
        this.setState({
            results,
            showResults: true,
            resultsLoading: false,
            noResultsFound: results.length > 0 ? false : true,
        });
    };

    handleClear = () => {
        this.setState({ results: [], showResults: false });
    };

    handleResultClick = (student) => {
        this.setState({ showResults: false, results: [] });
        this.onStudentSelect(student);
    };

    render() {
        const { hideExport } = this.props;

        return (
            <div className="scholarship-student-search">
                <div className="scholarship-student-search-row">
                    <SearchBox
                        placeholder="Search for a Student"
                        showResults={this.state.showResults}
                        resultsLoading={this.state.resultsLoading}
                        results={this.state.results}
                        onSearch={this.handleSearch}
                        type={searchType.student}
                        onResultClick={this.handleResultClick}
                        onClear={this.handleClear}
                        noResultsFound={this.state.noResultsFound}
                    />
                    <div style={{ flex: 1 }} />
                    {!hideExport ? (
                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>
                                    <span style={{ fontSize: 13, fontWeight: 600 }}>Only forms signed from:</span>
                                    <div style={{ display: "flex", marginBottom: 5, alignItems: "center" }}>
                                        <Datetime
                                            inputProps={{
                                                readOnly: true,
                                                placeholder: "All Dates (select date)",
                                                className: "input-with-icon date",
                                                style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                                            }}
                                            onChange={(param) => this.setState({ ferpaStartDate: param.format("M/D/YYYY") })}
                                            timeFormat={false}
                                            value={this.state.ferpaStartDate}
                                            isValidDate={(current) => current.isBefore(moment().subtract(1, "days"))}
                                        />
                                        <button
                                            className="input-icon clear"
                                            style={{ borderLeft: "none", width: 25, height: 25 }}
                                            onClick={() => this.setState({ ferpaStartDate: null })}
                                        >
                                            <FontAwesome name="close" className="icon" />
                                        </button>
                                        <span style={{ marginLeft: 5, fontSize: 13, fontWeight: 600 }}>To Today</span>
                                    </div>
                                </div>
                                <button className="btn export-btn" onClick={this.handleDownloadFerpaForms} style={{ textTransform: "none" }}>
                                    <FontAwesome name="file-archive-o" className="complete" size="2x" color="white" />
                                    EXPORT SIGNED FERPAs
                                </button>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>
                                    <span style={{ fontSize: 13, fontWeight: 600 }}>Only students who selected school from:</span>
                                    <div style={{ display: "flex", marginBottom: 5, alignItems: "center" }}>
                                        <Datetime
                                            inputProps={{
                                                readOnly: true,
                                                placeholder: "All Dates (select date)",
                                                className: "input-with-icon date",
                                                style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                                            }}
                                            onChange={(param) => this.setState({ selectedSchoolDate: param.format("M/D/YYYY") })}
                                            timeFormat={false}
                                            value={this.state.selectedSchoolDate}
                                            isValidDate={(current) => current.isBefore(moment().subtract(1, "days"))}
                                        />
                                        <button
                                            className="input-icon clear"
                                            style={{ borderLeft: "none", width: 25, height: 25 }}
                                            onClick={() => this.setState({ selectedSchoolDate: null })}
                                        >
                                            <FontAwesome name="close" className="icon" />
                                        </button>
                                        <span style={{ marginLeft: 5, fontSize: 13, fontWeight: 600 }}>To Today</span>
                                    </div>
                                </div>
                                <button className="btn export-btn" onClick={this.onDownloadEligible}>
                                    <FontAwesome name="cloud-download" className="complete" size="2x" color="white" />
                                    EXPORT ELIGIBLE STUDENTS
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.state.students.length > 0 && (
                    <div className="scholarship-student-search-results">{this.renderStudents(this.state.students, this.state.scholarship)}</div>
                )}
            </div>
        );
    }
}

StudentSearch.propTypes = {
    onStudentSelected: PropTypes.func.isRequired,
    scholarship: PropTypes.shape({
        id: PropTypes.string,
    }),
    hideExport: PropTypes.bool,
    fullSearch: PropTypes.bool,
};

StudentSearch = withAjaxStatus(StudentSearch);

export default StudentSearch;
