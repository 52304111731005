export const UserType = {
    Staff: 1,
    Parent: 2,
    Applicant: 3,
    Crusader: 4,
    Institution: 5,
    Finance: 6,
    Admin: 99,
    _displayNames: {
        1: "Staff",
        2: "Parent",
        3: "Applicant",
        4: "Student",
    },
};

export const SchoolType = {
    Middle: 0,
    High: 1,
};

export const TermStructure = {
    Trimester: 1,
    Semester: 2,
};

export const UserStatus = Object.freeze({
    Active: 0,
    Inactive: 1,
    _displayNames: {
        0: "Active",
        1: "Inactive",
    },
});

export const UserFamilyType = Object.freeze({
    Mother: 1,
    Father: 2,
    Guardian: 3,
    Child: 4,
    _displayNames: {
        1: "Mother",
        2: "Father",
        3: "Guardian",
        4: "Child",
    },
});

export const Grades = Object.freeze({
    5: 5,
    6: 6,
    7: 7,
    8: 8,
});

export const StudentStatus = Object.freeze({
    // Active
    Current: 10,
    GraduatedHS: 20,
    // Inactive
    Deceased: 100,
    DroppedOut: 105,
    GED: 110,
    GraduatedCollege: 115,
    Lost: 120,
    NA: 125,
    OnlyServElem: 130,
    OutOfState: 135,
    ParentWithdraw: 140,
    Pending: 145,
    Revoked: 150,
    ToBeRemoved: 155,
    Withdrew: 160,
    AgedOut: 170,
    Unknown: 999,
    _displayNames: {
        // Active
        10: "Current",
        20: "Graduated HS",
        // Inactive
        100: "Deceased",
        105: "Dropped Out",
        110: "GED",
        115: "Graduated College",
        120: "Lost",
        125: "NA",
        130: "Only Serves Elementary School",
        135: "Out Of State",
        140: "Parent Withdraw",
        145: "Pending",
        150: "Revoked",
        155: "To Be Removed",
        160: "Withdrew",
        170: "Aged Out",
        999: "Unknown",
    },
});
