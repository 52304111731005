import React from "react";
import ReactTable from "react-table";
import moment from "moment";

import IconButton from "../../../support/components/iconButton";

import "./list.css";

const EventList = ({ events, getTdProps, selectedItem, onHideClicked, hideInactive, readonly }) => (
    <div className="admissions-event-list">
        <button className={`btn ${hideInactive ? "inactive" : ""}`} onClick={onHideClicked}>
            Hide Past Events
        </button>
        {events && events.length > 0 && (
            <ReactTable
                getTdProps={getTdProps}
                data={events}
                minRows={0}
                showPagination={false}
                defaultPageSize={500}
                className="-highlight"
                columns={[
                    {
                        Header: "Event Name",
                        accessor: "name",
                    },
                    {
                        Header: "Event Type",
                        accessor: "type",
                        Cell: (row) => "New Student Orientation",
                    },
                    {
                        Header: "Date",
                        accessor: "startTime",
                        Cell: (row) => moment(row.value).format("M/D/YYYY"),
                    },
                    {
                        Header: "Time",
                        accessor: "startTime",
                        sortable: false,
                        Cell: (row) => `${moment.utc(row.value).format("h:mm a")} - ${moment.utc(row.original.endTime).format("h:mm a")}`,
                    },
                    {
                        Header: "Max",
                        accessor: "maxAttendees",
                        maxWidth: 50,
                    },
                    {
                        Header: "Reminder",
                        accessor: "id",
                        sortable: false,
                        width: 100,
                        Cell: (row) => (
                            <div className="reminder-container">
                                <button className="btn" disabled={moment(row.original.endTime).isBefore(moment(), "day") || readonly}>
                                    Send
                                </button>
                                <div className="last-sent">
                                    {row.original.lastReminderSent !== null ? `Last Sent ${moment(row.original.lastReminderSent).format("M/D/YYYY")}` : ""}
                                </div>
                            </div>
                        ),
                    },
                    {
                        Header: "Reminders Sent",
                        accessor: "remindersSent",
                    },
                    {
                        Header: "Roster",
                        accessor: "id",
                        sortable: false,
                        Cell: (row) => <IconButton icon="print" disabled={readonly} onClick={() => {}} />,
                    },
                ]}
            />
        )}
    </div>
);

export default EventList;
