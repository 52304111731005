import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ReactTable from "react-table";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

import "./CashCrusaders.css";

class CashCrusaders extends Component {
  doRenderColumns() {
    return [
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Social Security",
        accessor: "ssn"
      },
      {
        Header: "Term",
        accessor: "period",
        Cell: row => row.original.term,
        width: 200
      },
      {
        Header: "Description",
        accessor: "status",
        width: 300,
        Cell: row => row.original.statusString
      },
      {
        Header: "Tier",
        accessor: "level",
        maxWidth: 50,
        Cell: row => row.original.tier
      },
      {
        Header: "Amount",
        accessor: "total",
        Cell: row => formatCurrencyNoDecimalWithNegativeStyle(row.value)
      }
    ];
  }
  render() {
    const date = moment.utc(new Date()).format("M/D/YY");
    const { data } = this.props;
    const schoolName = data && data.length > 0 ? data[0].schoolName : "";
    const total = data && data.length > 0 ? formatCurrencyNoDecimalWithNegativeStyle(data.reduce((sum, item) => sum + item.total, 0)) : "";
    const label = this.props.isPayment ? "Total Payment" : "Total Refund";
    return (
      <div className="cash-crusader">
        <div className="header1">
          <span>{schoolName}</span>

          <div className="spacer" />
          <button className="btn" onClick={this.props.onExportToExcel}>
            Export To Excel
          </button>
        </div>
        <div className="header2">
          <span>
            {label}: {total} (Refund values are negative)
          </span>
          <div className="spacer" />
          <span>as of {date}</span>
        </div>
        <ReactTable data={data} minRows={0} className="financials-table -highlight" columns={this.doRenderColumns()} showPagination />
      </div>
    );
  }
}

CashCrusaders.propTypes = {
  onExportToExcel: PropTypes.func.isRequired,
  isPayment: PropTypes.bool.isRequired,
  total: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      schoolName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      ssn: PropTypes.string,
      crusaderId: PropTypes.number,
      status: PropTypes.number,
      statusString: PropTypes.string,
      reason: PropTypes.string,
      term: PropTypes.string,
      termStructure: PropTypes.number,
      tier: PropTypes.string,
      owed: PropTypes.number,
      refunded: PropTypes.number,
      paid: PropTypes.number
    })
  )
};

export default CashCrusaders;
