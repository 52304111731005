import React, { Component } from "react";
import LoadingButton from "../../common/loadingButton";
import { Form, Field } from "react-final-form";
import { FinalFormTextField } from "../../common/formWrappers";
class Tag extends Component {


    formRef = React.createRef();

  
   
 

    handleSubmit = async (values) => {
        
        await this.props.updateTag(values);
    }
    onRemove = async () => {
        await this.props.onRemove(this.props.tag);
    }

    render() {
        
        let showDes = this.props.tag.descriptorRequired;
        let showIped = this.props.tag.ipedRequired;
        
        return (<div>

            
            <Form onSubmit={this.handleSubmit} initialValues={this.props.tag} ref={this.formRef }>
                {({ handleSubmit, values, dirty, errors }) => {
                    
                    return (
                        <form onSubmit={handleSubmit} FormValues={this.props.tag} noValidate className="tagForm" initialValues={this.props.tag}>
                           
                            <div className={"test"}>
                                <table style={{ width: '100%' }} >
                                    <tr>
                                        <td style={{ width: '25%' }}>{this.props.tag.tagName} </td>
                                        <td style={{ width: '50%' }}>
                                            {showDes && (
                                                <div>

                                                <Field
                                                    component={FinalFormTextField}
                                                    name="descriptor"
                                                    label="Descriptor"
                                                    variant="outlined"
                                                    margin="dense"
                                                        disabled={this.props.disabled}
                                                />
                                                </div>
                                            )}

                                            {showIped && (
                                                <div>
                                                    <span>IPED Number: {this.props.tag.ipedNo}  </span>
                                                    <Field name="ipedNo" label="IPED Number" component="select" disabled={this.props.disabled}>
                                                    <option value="">Select an option</option>
                                                    {this.props.ipeds.map((iped) => {
                                                        return (
                                                            <option key={iped.ipedNo} value={iped.ipedNo}>
                                                                {iped.name}
                                                            </option>
                                                        );

                                                    })}

                                                </Field>
                                                </div>
                                            )}
                                        </td>
                                        <td style={{ width: '25%' }}>
                                            <Field name="id" component="input" type="hidden" />

                                            <Field
                                                component={FinalFormTextField}
                                                name="weight"
                                                label="Weight"
                                                variant="outlined"
                                                margin="dense"
                                                disabled={this.props.disabled}
                                                key={`${this.props.tag.id}weight`} 
                                            > {this.props.tag.weight} </Field>
                                        </td>
                                        

                                    </tr>
                                    <tr><td colspan="2" style={{ width: '65%' }}>
                                        {this.props.tag.description}
                                    </td>
                                        <td style={{ width: '35%' }}>
                                            <table style={{ width: '90%' }}>
                                                <tr><td style={{ alignItems: "end", alignContent: "end"  }}>
                                                    

                                                    <LoadingButton
                                                        wrapperClassName={"tagSaveButton"}
                                                        className={"test"}
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={this.props.disabled}
                                                        onClick={handleSubmit}
                                                    >
                                                        &nbsp;   Save &nbsp;
                                                    </LoadingButton>
                                                </td>
                                                    <td style={{ float: 'right' }}>
                                                        
                                                        <LoadingButton
                                                            wrapperClassName={"test"}
                                                            className={"test"}
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={this.props.disabled}
                                                            onClick={this.onRemove}
                                                        >
                                                            Remove
                                                        </LoadingButton> 
                                                    </td></tr>
                                            </table>
                                            
                                           
                                        </td>
                                    </tr>



                                </table>


                            </div>
                        </form>
                    );
                }}
            </Form>


            


        </div>
        );
    }

}
export default Tag;