import React, { Component, Fragment } from "react";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import * as propTypes from "../../propTypes";
import * as apis from "./apis";
import * as TransactionApi from "../../../api/scholarshipTransactions";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../support/numberSupport";
import RequestForm from "./components/RequestForm";
import RequestList from "./components/RequestList";
import CrusaderList from "./components/CrusaderList";

import "./index.css";

const ZERO_AMOUNT = "$0.00";

class RequestPayment extends Component {
  state = {
    currentId: null,
    date: moment.utc(new Date()),
    totalAmount: 0,
    note: "",
    disabled: false,
    paymentRequest: null,
    showAlert: false,
    alertTitle: "",
    alertMsg: "",
    statuses: [],
    isDisabled: false
  };
  UNSAFE_componentWillReceiveProps = props => {
    if (!props.scholarship) return;

    if (!this.showingExisting && !this.isLoading && (!this.scholarship || this.scholarship.id !== props.scholarship.id)) {
      this.scholarship = props.scholarship;
      this.doLoadData(props.scholarship.id);
    }
  };
  componentDidMount = () => {
    //document.querySelector(".rdtPicker").style.visibility = "collapse";
    if (!this.props.scholarship) return;
    this.doLoadData(this.props.scholarship.id);
  };
  onRequestPayment = async () => {
    const { currentId, date, note } = this.state;
    if (currentId) return;
    const dt = new Date();
    const now = moment.utc(new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 1, 0, 0));

    if (now > date) {
      this.setState({
        showAlert: true,
        alertTitle: "Past Date",
        alertMsg: "Date needs to be today or in the future."
      });
      return;
    }
    if (this.state.paymentRequest.total === ZERO_AMOUNT) return;

    const scholarshipId = this.props.scholarship.id;

    try {
      const data = {
        scholarshipId,
        date: date.toDate(),
        note,
        amount: this.state.paymentRequest.total
      };
      this.props.ajaxStatusLoading();
      await TransactionApi.requestPayment(data);
      await this.doLoadData(scholarshipId);

      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving finacial data");
    }
  };
  onNoteChange = e => {
    const value = e.target.value;
    this.setState({ note: value });
  };
  onDateChange = value => {
    this.setState({ date: value });
    document.querySelector(".rdtPicker").style.visibility = "collapse";
  };
  onDateFocus = () => {
    if (this.state.isDisabled) return;
    document.querySelector(".rdtPicker").style.visibility = "visible";
  };
  onDateBlur = () => {
    document.querySelector(".rdtPicker").style.visibility = "collapse";
  };
  onLoadCurrent = async () => {
    this.showingExisting = false;
    this.setState({
      currentId: null,
      note: "",
      date: moment.utc(new Date())
    });
    await this.doLoadData(this.props.scholarship.id);
  };
  onRequestClick = async request => {
    this.showingExisting = true;
    try {
      this.props.ajaxStatusLoading();
      const paymentResults = await apis.getRequestPaymentsForExistinRequest(this.props.scholarship.id, request.id);

      this.setState({
        currentId: request.id,
        note: request.note,
        date: moment(request.date),
        paymentRequest: paymentResults.data.result,
        isDisabled: true
      });

      this.props.ajaxStatusComplete();
      this.showingExisting = false;
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving financial data");
    }
  };
  doLoadData = async scholarshipId => {
    try {
      this.isLoading = true;
      this.props.ajaxStatusLoading();
      const paymentResults = await apis.getRequestPayments(scholarshipId);
      const statusResults = await apis.getStatus(scholarshipId);
      const paymentRequest = paymentResults.data.result;

      this.setState({
        statuses: statusResults.data.result,
        paymentRequest,
        isDisabled: paymentRequest.details.length === 0
      });

      this.props.ajaxStatusComplete();
      this.showingExisting = false;
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving financial data");
    }
    this.isLoading = false;
  };
    render() {
        //console.log(this);
    const total = this.state.paymentRequest ? this.state.paymentRequest.total : ZERO_AMOUNT;
    const date = this.state.paymentRequest ? moment.utc(this.state.paymentRequest.date).format("M/D/YY") : null;
    return (
      <div className="scholarship-request-payment">
        <div className="top">
          <RequestForm
            totalAmount={total}
            note={this.state.note}
            mDate={this.state.date}
            disabled={this.state.disabled}
            onClick={this.onRequestPayment}
            onNoteChange={this.onNoteChange}
            onDateChange={this.onDateChange}
            onDateFocus={this.onDateFocus}
            onDateBlur={this.onDateBlur}
            isDisabled={this.state.isDisabled || this.props.readonly}
          />
          <RequestList currentId={this.state.currentId} requests={this.state.statuses} onClick={this.onRequestClick} onLoadCurrent={this.onLoadCurrent} />
        </div>
        {this.state.paymentRequest ? (
          <Fragment>
            <div className="middle">
              <span>{date}</span>
              <div />
                        <span>{formatCurrencyNoDecimalWithNegativeStyle(this.state.paymentRequest.total)}</span>
            </div>

            <CrusaderList paymentRequest={this.state.paymentRequest} />
          </Fragment>
        ) : null}
        <SweetAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          onConfirm={() => {
            this.setState({ showAlert: false });
          }}
          confirmBtnText="OK"
        >
          {this.state.alertMsg}
        </SweetAlert>
      </div>
    );
  }
}

RequestPayment.propTypes = {
  scholarship: propTypes.scholarship
};
export default withAjaxStatus(RequestPayment);
