import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/styles/makeStyles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    margin: {
        marginBottom: theme.spacing(1),
    },
    label: {
        marginBottom: theme.spacing(1),
    },
    required: {
        color: "red",
    },
    checkboxContainer: {
        alignSelf: "flex-start",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    cbLabel: {
        // flex: '0 0 50%'
    },
    checkbox: {
        padding: theme.spacing(1),
    },
    checked: {
        color: theme.palette.success.main,
    },
}));

const FinalFormCheckbox = ({
    className,
    formControlClassName,
    checkboxClassName,
    tooltipPlacement,
    tooltipContent = "",
    label,
    labelClassName,
    required,
    input: { name, onChange, value, ...restInput },
    fullWidth,
    meta,
    ...rest
}) => {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = React.useState(false);

    return (
        <FormControl className={clsx(classes.margin, className)} fullWidth={fullWidth} error={meta.error && meta.touched}>
            <Tooltip arrow title={tooltipContent} open={showTooltip} placement={tooltipPlacement} TransitionComponent={Zoom}>
                <FormControlLabel
                    label={label}
                    classes={{ root: formControlClassName, label: labelClassName }}
                    control={
                        <Checkbox
                            checkedIcon={<CheckBoxIcon className={classes.checked} />}
                            icon={<CheckBoxOutlineBlankIcon />}
                            classes={{ root: clsx(classes.checkbox, checkboxClassName) }}
                            {...rest}
                            name={name}
                            onChange={(event) => onChange(event.target.checked)}
                            {...restInput}
                        />
                    }
                    checked={!!value}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    {...rest}
                />
            </Tooltip>
            {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default FinalFormCheckbox;
