import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    emptySectionTitle: {
        height: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    sectionTitle: {
        height: theme.spacing(3),
        marginBottom: theme.spacing(1),
        borderBottomStyle: "solid",
        borderBottomWidth: 2,
        borderBottomColor: theme.palette.secondary.main,
    },
}));

const EligibilityCriteriaSection = ({ className, title, children }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)}>
            {title ? <Typography className={classes.sectionTitle} variant="body2">{title}</Typography>
                : <div className={classes.emptySectionTitle}></div>}
            {children}
        </div>
    );
}

export default EligibilityCriteriaSection;