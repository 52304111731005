import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import EditModal from "./editModal";
import useRecruitmentCycle from "./useRecruitmentCycle";
import CycleItem from "./cycleItem";

import "./index.css";

const RecruitmentCycle = () => {
    const { data, onSave, onAdd, onEdit, onCloseEdit, showEdit, onDelete, selectedItem, alertData, onConfirm, onCancel } = useRecruitmentCycle();

    return (
        <div className="recruitment-cycle">
            <div className="recruitment-cycle__title-container">
                <h2>Recruitment Cycle</h2>
                <button className="btn btn-small" onClick={onAdd}>
                    Add Recruitment Cycle
                </button>
            </div>
            <div className="recruitment-cycle__item-container">
                {data.map((item) => (
                    <CycleItem key={item.id} item={item} onEdit={onEdit} onDelete={onDelete} />
                ))}
            </div>
            {showEdit && <EditModal allItems={data} isOpen={showEdit} onRequestClose={onCloseEdit} onSave={onSave} initialValues={selectedItem} />}
            <SweetAlert
                showCancel
                cancelBtnText="CANCEL"
                show={alertData.show}
                title={alertData.title}
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmBtnText="OK"
            >
                {alertData.message}
            </SweetAlert>
        </div>
    );
};

export default RecruitmentCycle;
