import React from "react";
import withAdmissions from "../../support/hoc/withAdmissions";
import { injectIntl } from "react-intl";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import { withRouter } from "react-router";

import "./studentSelector.css";
import withMediaQuery from "../../support/hoc/withMediaQuery";

class StudentSelector extends React.Component {
    state = {
        students: [],
        selectedStudentId: null,
    };

    componentDidMount() {
        this.updateStudents(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.admissions !== prevProps.admissions) {
            this.updateStudents(this.props);
        }
    }

    updateStudents(props) {
        if (props.admissions === null) {
            this.setState({ students: [] });
        } else if (props.admissions.userProfiles.length <= 1) {
            this.setState({ students: [] });
            this.updateSelectedStudent(props.admissions.userProfiles[0].userProfileId);
        } else {
            this.setState({ students: props.admissions.userProfiles });

            if (this.state.selectedStudentId !== null) {
                if (props.admissions.userProfiles.length > this.state.students.length) {
                    // New Student Added, select them
                    this.updateSelectedStudent(props.admissions.userProfiles[props.admissions.userProfiles.length - 1].userProfileId);
                    return;
                }

                if (props.admissions.userProfiles.findIndex((item) => item.userProfileId === this.state.selectedStudentId) === -1) {
                    this.updateSelectedStudent(props.admissions.userProfiles[0].userProfileId);
                }
                return;
            }

            const parts = props.location.pathname.split("/");
            if (parts.length === 7) {
                const profileId = parts[parts.length - 1];
                const profile = props.admissions.userProfiles.find((p) => p.userProfileId === profileId);
                if (profile !== undefined) {
                    this.updateSelectedStudent(profile.userProfileId);
                } else {
                    this.updateSelectedStudent(props.admissions.userProfiles[0].userProfileId);
                }
            } else {
                this.updateSelectedStudent(props.admissions.userProfiles[0].userProfileId);
            }
        }
    }

    updateSelectedStudent = (studentId) => {
        if (this.state.selectedStudentId === studentId) {
            return;
        }

        this.setState({ selectedStudentId: studentId });
        if (this.props.onChange) {
            this.props.onChange(studentId);
        }
    };

    renderStudentStatus(studentId) {
        const isComplete = this.props.admissions !== null && this.props.admissions.progress.studentStatus[studentId];

        if (isComplete) {
            return <FontAwesome name="check" className="complete" />;
        } else {
            return <FontAwesome name="circle" />;
        }
    }

    renderStudent(student, index) {
        const isDesktop = this.props.mediaQuery;
        let name = student.userProfileName;

        const studentText = this.props.intl.formatMessage({ id: "app.student", defaultMessage: "[Student]" });
        const isSelected = student.userProfileId === this.state.selectedStudentId;

        if (name === "") {
            name = `${studentText}: ${index + 1}`;
        }

        const dob = student.dateOfBirth && student.dateOfBirth !== null ? moment(student.dateOfBirth).utc().format("MM/DD/YYYY") : "";
        const school = student.school && student.school !== null ? student.school : "";

        return (
            <div key={student.userProfileId} className={isSelected ? "selected" : ""}>
                <div
                    className="student-button"
                    role="button"
                    tabIndex="0"
                    onClick={() => this.updateSelectedStudent(student.userProfileId)}
                    onKeyPress={() => this.updateSelectedStudent(student.userProfileId)}
                >
                    <div className="info">
                        <div>{name}</div>
                        {isDesktop && <div>{dob}</div>}
                        {isDesktop && <div>{school}</div>}
                    </div>
                    <div>{this.renderStudentStatus(student.userProfileId)}</div>
                </div>
            </div>
        );
    }

    render() {
        const { students } = this.state;
        const isDesktop = this.props.mediaQuery;
        if (!students || students.length <= 1) {
            return null;
        }

        return <div className={`student-selector ${!isDesktop ? "mobile" : ""}`}>{students.map((student, index) => this.renderStudent(student, index))}</div>;
    }
}

StudentSelector = withAdmissions(StudentSelector);
StudentSelector = injectIntl(StudentSelector);
StudentSelector = withRouter(StudentSelector);
StudentSelector = withMediaQuery("(min-width:600px)")(StudentSelector);

export default StudentSelector;
