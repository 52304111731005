import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import moment from "moment";

import withAdmissions from "../../../support/hoc/withAdmissions";

import "./AppHeader.css";

const AppHeaderStudent = ({ item }) => (
    <div className="app-header-student">
        <span className="app-header-student__name">{item.userProfileName}</span>
        <span className="app-header-student__details">
            DOB: {moment.utc(item.dateOfBirth).format("MM/DD/YYYY")}, {item.gender}, {item.school}
        </span>
    </div>
);

let AppHeader = ({ onClose, admissions }) => {
    return (
        <div className="staff-application-header">
            <div className="staff-application-header-close">
                <button onClick={onClose}>
                    <FontAwesome name="times" className="application-close" size="2x" />
                </button>
            </div>
            {admissions != null &&
                admissions.userProfiles.reduce((acc, item) => {
                    if (item.userProfileName) {
                        acc.push(<AppHeaderStudent key={item.id} item={item} />);
                    }
                    return acc;
                }, [])}
        </div>
    );
};

AppHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
};

AppHeader = withAdmissions(AppHeader);

export default AppHeader;
