import React from "react";
import { reduxForm, Field } from "redux-form";

import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";
import { required, renderField, isDate, renderDateField } from "../../../../support/forms";

import "./payment.css";

const validateCheckNumber = value => {
  if (
    !value ||
    (value && (value.length > 10 || value === "0" || isNaN(value) || parseInt(value, 10) === 0 || (typeof value === "string" && value.indexOf(".") !== -1)))
  ) {
    return "Valid check number required";
  }
};

let Payment = ({ paymentRequest, onSave, handleSubmit, readonly }) => {
  //   //console.log(paymentRequest);
  return (
    <div className="scholarship-send-payment-payment">
      <div className="payment-top">
        <div className="payment-top-left">
          <div className="payment-top">
                      <Field component={renderDateField} label="Check Date" name="date" outerClassName="payment-field" validate={[required, isDate]} disabled={readonly} />
                      <Field component={renderField} label="Check #" name="checkNum" type="number" min="0" className="payment-field" validate={[validateCheckNumber]} disabled={readonly} />
            <div className="payment-total">
              <span className="form-label">Total</span>
              <div className="form-inner-div">
                <input className="form-input" disabled value={formatCurrencyNoDecimal(paymentRequest.amount)} />
              </div>
            </div>
          </div>
          <div>
                      <Field component={renderField} label="Notes" name="notes" type="text" hideLabel disabled={readonly} />
          </div>
        </div>
        <div className="payment-top-right">
                  <button className="btn" onClick={handleSubmit(onSave)} disabled={readonly }>
            {paymentRequest.checkNum === null ? "Send Payment" : "Update"}
          </button>
        </div>
      </div>
      <div className="payment-bottom">
        <div>
          <strong>Pay To</strong>
        </div>
        <div>
          <strong>{paymentRequest.institution}</strong>
          <br />
          {paymentRequest.paymentAddress1}
          <br />
          {paymentRequest.paymentAddress2 !== "" && (
            <React.Fragment>
              {paymentRequest.paymentAddress2}
              <br />
            </React.Fragment>
          )}
          {paymentRequest.paymentCity}, {paymentRequest.paymentState} {paymentRequest.paymentZipCode}
        </div>
      </div>
    </div>
  );
};

Payment = reduxForm({ form: "ScholarshipSendPayment" })(Payment);

export default Payment;
