import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import useProgramCodeHelpers from "./programCodeHelpers";

const useStyles = makeStyles((theme) => ({
    programCodeHeader: {},
    searchBox: { width: 300, display: "inline-block" },
}));

const { sortProgramCodesAsc, sortActivityCodesAsc } = useProgramCodeHelpers();

const ProgramCodeHeader = ({ programCodeOptions, activityCodes, onSearchBoxChange, onProgramIdChange, onGearUpIdChange, onAddClick }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box pb={1} display="flex">
                <Typography variant="h5"> Event Program Codes</Typography>
                <Box flex={1} display="flex" ml={1}>
                    <Button color="primary" variant="contained" onClick={onAddClick} className={classes.addButton} startIcon={<AddIcon />}>
                        Add
                    </Button>
                </Box>
            </Box>
            <Box>
                <Box display="inline-block" style={{ width: 500, verticalAlign: "bottom", position: "relative", top: -4 }}>
                    <OutlinedInput
                        name="search"
                        margin="dense"
                        startAdornment={<SearchIcon />}
                        onChange={(event) => {
                            onSearchBoxChange(event.target.value);
                        }}
                        className={classes.search}
                        placeholder="Search Description"
                        style={{ width: 500 }}
                    />
                </Box>
                <Box display="inline-block" ml={1}>
                    <Autocomplete
                        className={classes.searchBox}
                        options={sortProgramCodesAsc(programCodeOptions)}
                        onChange={(event, value) => onProgramIdChange(value === null ? "" : value.code)}
                        getOptionLabel={(option) => `${option.code} - ${option.description}`}
                        autoComplete
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Program ID" variant="outlined" margin="dense" style={{ margin: "0 !important" }} />
                        )}
                    />
                </Box>
                <Box display="inline-block" ml={1}>
                    <Autocomplete
                        className={classes.searchBox}
                        options={sortActivityCodesAsc(activityCodes)}
                        onChange={(event, value) => onGearUpIdChange(value === null ? "" : value.activityCode)}
                        getOptionLabel={(option) => `${option.activityCode} - ${option.shortDescription}`}
                        autoComplete
                        renderInput={(params) => <TextField {...params} placeholder="Gear up ID #" variant="outlined" margin="dense" />}
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};
export default ProgramCodeHeader;
