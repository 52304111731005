import React, { useMemo } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EligibilityCriteria from "./ElegibilityCriteria";

import enumHelper from "../../../support/logic/enumHelper";
import { EventDepartmentType, EventStatus } from "../../enums";

const useStyles = makeStyles((theme) => ({
    listHeader: {
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
    },
    search: {
        marginRight: 1,
    },
    selectEmpty: {
        marginTop: 2,
    },
}));

const ListHeader = ({
    onAdd,
    seasons,
    isActive,
    searchText,
    onSearchTextChange,
    department,
    onDepartmentChange,
    status,
    onStatusChange,
    seasonId,
    onSeasonIdChange,
    setEligibilityCriteria,
    eligibilityCriteria
}) => {
    const classes = useStyles();

    const departmentOptions = useMemo(() => {
        return enumHelper.getSelectOptionsList(EventDepartmentType, false, "All Departments");
    }, []);

    const statusOptions = useMemo(() => {
        const allStatuses = enumHelper.getSelectOptionsList(EventStatus, false, "All Statuses");

        if (isActive) {
            return allStatuses.filter((s) => s.value !== EventStatus.Cancelled && s.value !== EventStatus.Closed);
        } else {
            return allStatuses.filter((s) => s.value === EventStatus.Cancelled || s.value === EventStatus.Closed || s.value === "");
        }
    }, [isActive]);

    return (
        <div className={classes.listHeader}>
            <Grid container alignItems="center" spacing={2} style={{ paddingBottom: 10 }}>
                <Grid item xs={3}>
                    <OutlinedInput
                        className={classes.search}
                        onChange={(e) => onSearchTextChange(e.target.value)}
                        placeholder="Search by name..."
                        margin="dense"
                        fullWidth
                        startAdornment={<Search />}
                        endAdornment={
                            <InputAdornment position="end">
                                {searchText ? (
                                    <IconButton onClick={() => onSearchTextChange("")} edge="end" size="small">
                                        <Close />
                                    </IconButton>
                                ) : (
                                    <div style={{ width: 27 }} />
                                )}
                            </InputAdornment>
                        }
                        value={searchText}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="season-label">
                            Season
                        </InputLabel>
                        <Select
                            labelId="season-label"
                            id="season"
                            margin="dense"
                            value={seasonId}
                            onChange={(e) => onSeasonIdChange(e.target.value)}
                            displayEmpty
                            fullWidth
                        >
                            <MenuItem key="123" value="">
                                All Seasons
                            </MenuItem>
                            {seasons.map((season) => (
                                <MenuItem key={season.id} value={season.id}>
                                    {season.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="department-label">
                            Department
                        </InputLabel>
                        <Select
                            margin="dense"
                            labelId="department-label"
                            id="department"
                            value={department}
                            onChange={(e) => onDepartmentChange(e.target.value)}
                            displayEmpty
                            fullWidth
                            className={classes.selectEmpty}
                        >
                            {departmentOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="status-label">
                            Status
                        </InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            margin="dense"
                            value={status}
                            onChange={(e) => onStatusChange(e.target.value)}
                            displayEmpty
                            fullWidth
                        >
                            {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <EligibilityCriteria
                        eligibilityCriteria={eligibilityCriteria}
                        setEligibilityCriteria={setEligibilityCriteria}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={onAdd}>
                        Add Event
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ListHeader;
