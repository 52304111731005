import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    input: {
        width: "60px",
    },
    dropDown: {
        width: "300px",
    },
    container: {
        marginBottom: "24px",
    },
    redLabel: {
        color: "red",
        fontStyle: "italic",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "16px",
    },
    enrollmentAlert: {
        color: "red",
    },
    saveButton: {
        width: "50%",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
