import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './ApplicationStatus.css';

const ApplicationStatus = ({ description, isComplete, onVerify, url }) => {
    return (
        <div className="application-status">
            <div className="appstatus-cell-1">
                <Link to={url}>
                    <span className="form-label">{description}</span>
                </Link>
            </div>
            <div className="appstatus-cell-2">
                {isComplete ? <input type="checkbox" defaultChecked disabled /> : <input type="checkbox" disabled />}
            </div>
            <div className="appstatus-cell-3" />
            <div className="appstatus-cell-4" />
        </div>
    );
};

ApplicationStatus.propTypes = {
    description: PropTypes.string.isRequired,
    isComplete: PropTypes.bool.isRequired
};

export default ApplicationStatus;
