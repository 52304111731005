import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import FontAwesome from "react-fontawesome";
import { Field, reduxForm } from "redux-form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";

import Button from "../../support/components/Button";
import { required, renderField, renderRadioField } from "../../support/forms";

import "./Terms.css";
import "../index.css";

let Terms = ({ handleSubmit, onBack, onAgree, intl, onPrint }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    return (
        <div className="login-ui">
            <div className="login-term">
                <Box display="flex" flex={1} alignItems="center">
                    {isDesktop ? (
                        <Button id="app.back" className="back-button login-term-back-button no-print" defaultMessage="Back" onClick={onBack} />
                    ) : (
                        <Button id="app.back" className="back-button login-term-back-button no-print mobile" defaultMessage="Back" onClick={onBack}>
                            <FontAwesome name="arrow-left" color="#ffffff" className="arrow" />
                        </Button>
                    )}
                    <FormattedMessage
                        id="login.termTitle"
                        defaultMessage="Onward We Learn Terms & Conditions, Privacy Policy..."
                        children={(formattedMessage) => <h1 className={`screen-title terms ${isDesktop ? "" : "mobile"}`}>{formattedMessage}</h1>}
                    />
                </Box>
                <div>
                    <div className="login-term__text">
                        <FormattedMessage id="login.termParagraph1" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph2" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph3" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph4" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph5" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph6" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage id="login.termParagraph7" defaultMessage="blah" children={(formattedMessage) => <p>{formattedMessage}</p>} />
                        <FormattedMessage
                            id="login.termParagraph8"
                            defaultMessage="[login.termParagraph8]"
                            children={(formattedMessage) => <p>{formattedMessage}</p>}
                        />
                    </div>
                </div>
                <div className="login-term-inputs-wrapper no-print">
                    <div className="login-term-bottom">
                        <Field
                            id="termsGuardian"
                            name="guardian"
                            component={renderRadioField}
                            items={[
                                { value: "1", text: "app.mother" },
                                { value: "2", text: "app.father" },
                                { value: "3", text: "app.legalGuardian" },
                            ]}
                            validate={[required]}
                            noMargin={!isDesktop}
                            wrapperClassName="radio-wrapper"
                        />

                        <div className="login-term-inputs">
                            <FormattedMessage
                                id="app.firstName"
                                defaultMessage="First Name"
                                children={(formattedMessage) => (
                                    <Field name="firstName" type="text" component={renderField} label={formattedMessage} validate={[required]} />
                                )}
                            />
                            <FormattedMessage
                                id="app.middleName"
                                defaultMessage="Middle Name"
                                children={(formattedMessage) => <Field name="middleName" type="text" component={renderField} label={formattedMessage} />}
                            />

                            <FormattedMessage
                                id="app.lastName"
                                defaultMessage="Last Name"
                                children={(formattedMessage) => (
                                    <Field name="lastName" type="text" component={renderField} label={formattedMessage} validate={[required]} />
                                )}
                            />
                            <Button type="submit" id="login.termAgree" defaultMessage="I Agree" onClick={handleSubmit(onAgree)} className="login-term-button" />
                            <div className="login-term-print-button">
                                <FontAwesome name="print" size="2x" onClick={onPrint} />
                                <FormattedMessage
                                    id="app.print"
                                    defaultMessage="app.print"
                                    children={(formattedMessage) => <span className="link-color">{formattedMessage}</span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Terms.propTypes = {
    onBack: PropTypes.func.isRequired,
    onAgree: PropTypes.func.isRequired,
    onPrint: PropTypes.func.isRequired,
};

Terms = reduxForm({
    form: "terms",
    destroyOnUnmount: false,
})(Terms);

Terms = injectIntl(Terms);

export default Terms;
