import axios from "axios";

export function getPayHistory(scholarshipId, userProfileId) {
    return axios.get("/api/services/app/ScholarshipTransaction/GetPayHistory", { params: { studentId: userProfileId, scholarshipId } });
}

export function getDonatedList(scholarshipId, institutionId, searchText) {
    return axios.get("/api/services/app/ScholarshipTransaction/GetDonatedList", { params: { scholarshipId, institutionId, searchText } });
}

export function getCashList(scholarshipId, institutionId, searchText) {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCashList", { params: { scholarshipId, institutionId, searchText } });
}

export function getCertificationList(scholarshipId, institutionId) {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCertificationList", { params: { scholarshipId, institutionId } });
}

export const getCertificationExport = (scholarshipId, institutionId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCertificationListExport", {
        params: { scholarshipId, institutionId },
    });
};

export const getNotCertifiedList = (scholarshipId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetNotCertifiedList", {
        params: { scholarshipId },
    });
};

export const getNotCertifiedExport = (scholarshipId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetNotCertifiedExport", {
        params: { scholarshipId },
    });
};

export const getSummaryDonationCash = (scholarshipId, institutionId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetSummaryDonationCash", { params: { scholarshipId, institutionId } });
};

export const getCrusadersWithPayments = (transactionRequestId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCrusadersWithPayments", { params: { id: transactionRequestId } });
};

export const getCrusadersCertifiedForSchool = (scholarshipId, institutionId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCrusadersCertifiedForSchool", { params: { scholarshipId, institutionId } });
};

export const getCrusadersCashExport = (transactionRequestId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCrusadersCashExport", { params: { id: transactionRequestId } });
};

export const getCrusadersDonatedExport = (scholarshipId, institutionId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetCrusadersDonatedExport", { params: { scholarshipId, institutionId } });
};

export const getRefunds = (scholarshipId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetRefunds", { params: { scholarshipId } });
};

export const getRefundDetails = (scholarshipId, transactionRequestId) => {
    return axios.get("/api/services/app/ScholarshipTransaction/GetReceivedRefundDetails", {
        params: {
            scholarshipId,
            transactionRequestId,
            isComplete: transactionRequestId !== undefined,
            type: 2,
        },
    });
};

export const requestPayment = (request) => {
    return axios.post("/api/services/app/ScholarshipTransaction/RequestPayment", request);
};

export function sendPayment(requestPaymentId, date, checkNum, notes) {
    return axios.post("/api/services/app/ScholarshipTransaction/SendPayment", {
        requestPaymentId,
        date,
        checkNum,
        notes,
    });
}
