import React from "react";
import ConfirmDialog from "../../../common/confirmDialog";
import { makeStyles } from "@material-ui/styles";
import { unenrollUsers } from "../../api/programEvents";
import { toast } from "react-toastify";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

const UnenrollmentDialog = ({ isOpen, closeUnenrollmentDialog, selectedUsers, setRegisteredUsers }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handleOnConfirm = async () => {
        const payload = selectedUsers.map((user) => user.registrationId);

        try {
            const resp = await unenrollUsers(payload);
            resp.data.success && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        setRegisteredUsers((prev) => {
            const users = prev.filter((user) => !selectedUsers.filter((item) => item.registrationId === user.registrationId).length);
            return users;
        });

        closeUnenrollmentDialog();
    };

    const dialogContent = (
        <div className={classes.dialogContent}>
            <p>Are You Sure You Want to Unenroll</p>
            <ul>
                {selectedUsers.map((user, idx) => (
                    <li key={idx}>
                        {user.firstName} {user.lastName}
                    </li>
                ))}
            </ul>
            <br />
            <p>Participant Account Holder Will Be Automatically Notified of Unenrollment</p>
        </div>
    );

    return (
        <ConfirmDialog
            open={isOpen}
            disablePortal
            handleClose={closeUnenrollmentDialog}
            confirmAction={handleOnConfirm}
            confirmActionText="Unenroll"
            fullWidth
            maxWidth="sm"
            title="Unenroll"
            body={dialogContent}
        />
    );
};

export default UnenrollmentDialog;
