import React from 'react';
import ReactTable from 'react-table';

import { formatCurrencyNoDecimal } from '../../../../support/numberSupport';
import { certifiedSortMethod } from '../../../logic';

import './studentList.css';

const StudentList = ({ data }) => (
    <div className="scholarship-send-payment-student-list">
        <ReactTable
            className="financials-table"
            data={data}
            minRows={0}
            showPagination={true}
            columns={[
                {
                    Header: 'Last Name',
                    accessor: 'lastName'
                },
                {
                    Header: 'First Name',
                    accessor: 'firstName'
                },
                {
                    Header: 'Term',
                    accessor: 'term'
                },
                {
                    Header: 'Certified',
                    accessor: 'certified',
                    sortMethod: certifiedSortMethod
                },
                {
                    Header: 'Total Due',
                    accessor: 'totalDue',
                    Cell: props => formatCurrencyNoDecimal(props.value)
                }
            ]}
        />
    </div>
);

export default StudentList;
