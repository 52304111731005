import React from 'react';

import './Header.css';

const Header = ({ title }) => {
    return (
        <div className="application-header">
            <div className="app-header-row-1">
                <h2>{title}</h2>
            </div>
            <div className="app-header-row-2">Complete</div>
            <div className="app-header-row-3">Documents</div>
            <div className="app-header-row-4">Valid</div>
        </div>
    );
};

export default Header;
