import React, { useMemo } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Box from "@material-ui/core/Box";
import { EventStatus } from "../../enums";
import enumHelper from "../../../support/logic/enumHelper";

const useStyles = makeStyles((theme) => ({
    eventStepper: {
        width: "80%",
        padding: theme.spacing(2, 0.5),
        backgroundColor: "#ffffff",
        "& .MuiStep-completed": {
            "& .MuiStepButton-root": {},
        },
        "& .MuiStepButton-root": {
            width: 110,
            height: 30,
            padding: theme.spacing(1.5, 1),
            borderRadius: 10,
            border: `1px solid ${theme.palette.tertiary.main}`,
            "&:not(.Mui-disabled)": {
                "& $buttonLabel": {
                    color: theme.palette.tertiary.main,
                },
            },
            "&$active": {
                backgroundColor: theme.palette.tertiary.main,
                "&:not(.Mui-disabled) $buttonLabel": {
                    color: "#ffffff",
                },
            },
        },
        "& .MuiStepButton-root.Mui-disabled": {
            border: `1px solid ${theme.palette.lightGray.main}`,
        },
        "& .MuiStepConnector-root": {
            padding: theme.spacing(0, 1.6),
            "& .MuiStepConnector-lineHorizontal": {
                borderColor: theme.palette.secondary.main,
                borderTopWidth: 5,
            },
        },
        "& .MuiStepLabel-iconContainer": {
            padding: 0,
        },
        "& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-lineHorizontal": {
            borderColor: theme.palette.lightGray.main,
            borderTopWidth: 2,
        },
    },
    active: {},
    buttonLabel: {
        fontWeight: "bold",
        fontSize: 16,
        width: "min-content",
    },
}));

const EventStepper = ({ eventStatus, activeStep, onChangeActiveStep }) => {
    const classes = useStyles();

    const disabled = useMemo(() => {
        switch (eventStatus) {
            case EventStatus.Planning:
                return [EventStatus.Recruitment, EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment, EventStatus.Review, EventStatus.Complete];
            case EventStatus.Recruitment:
                return [EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment, EventStatus.Review, EventStatus.Complete];
            case EventStatus.OpenEnrollment:
                return [EventStatus.ClosedEnrollment, EventStatus.Review, EventStatus.Complete];
            case EventStatus.ClosedEnrollment:
                return [EventStatus.Review, EventStatus.Complete];
            case EventStatus.Review:
                return [EventStatus.Complete];
            case EventStatus.Complete:
                return []; // No steps are disabled when the event is complete
            default:
                return [EventStatus.Planning, EventStatus.Recruitment, EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment, EventStatus.Review, EventStatus.Complete];
        }
    }, [eventStatus]);

    const completed = useMemo(() => {
        switch (eventStatus) {
            case EventStatus.Review:
                return [EventStatus.Planning, EventStatus.Recruitment, EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment, EventStatus.Review];
            case EventStatus.ClosedEnrollment:
                return [EventStatus.Planning, EventStatus.Recruitment, EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment];
            case EventStatus.OpenEnrollment:
                return [EventStatus.Planning, EventStatus.Recruitment, EventStatus.OpenEnrollment];
            case EventStatus.Recruitment:
                return [EventStatus.Planning, EventStatus.Recruitment];
            case EventStatus.Planning:
                return [EventStatus.Planning];
            case EventStatus.Complete:
                // All steps are completed when the event is complete
                return [EventStatus.Planning, EventStatus.Recruitment, EventStatus.OpenEnrollment, EventStatus.ClosedEnrollment, EventStatus.Review, EventStatus.Complete];
            default:
                return [];
        }
    }, [eventStatus]);

    return (
        <Stepper activeStep={activeStep} classes={{ root: classes.eventStepper }} nonLinear>
            <Step key={EventStatus.Planning} completed={completed.includes(EventStatus.Planning)}>
                <StepButton
                    onClick={() => onChangeActiveStep(EventStatus.Planning)}
                    className={activeStep === EventStatus.Planning ? classes.active : undefined}
                    icon={
                        <Box display="flex">
                            <span className={classes.buttonLabel}>{enumHelper.getDisplayName(EventStatus, EventStatus.Planning)}</span>
                        </Box>
                    }
                ></StepButton>
            </Step>
            <Step key={EventStatus.Recruitment} completed={completed.includes(EventStatus.Recruitment)} disabled={disabled.includes(EventStatus.Recruitment)}>
                <StepButton
                    onClick={() => onChangeActiveStep(EventStatus.Recruitment)}
                    className={activeStep === EventStatus.Recruitment ? classes.active : undefined}
                    icon={
                        <Box display="flex">
                            <span className={classes.buttonLabel}>{enumHelper.getDisplayName(EventStatus, EventStatus.Recruitment)}</span>
                        </Box>
                    }
                ></StepButton>
            </Step>
            <Step
                key={EventStatus.OpenEnrollment}
                completed={completed.includes(EventStatus.OpenEnrollment)}
                disabled={disabled.includes(EventStatus.OpenEnrollment)}
            >
                <StepButton
                    onClick={() => onChangeActiveStep(EventStatus.OpenEnrollment)}
                    className={activeStep === EventStatus.OpenEnrollment ? classes.active : undefined}
                    icon={
                        <Box display="flex">
                            <span className={classes.buttonLabel}>{enumHelper.getDisplayName(EventStatus, EventStatus.OpenEnrollment)}</span>
                        </Box>
                    }
                ></StepButton>
            </Step>
            <Step key={EventStatus.Review} completed={completed.includes(EventStatus.Review)} disabled={disabled.includes(EventStatus.Review)}>
                <StepButton
                    onClick={() => onChangeActiveStep(EventStatus.Review)}
                    className={activeStep === EventStatus.Review ? classes.active : undefined}
                    icon={
                        <Box display="flex">
                            <span className={classes.buttonLabel}>{enumHelper.getDisplayName(EventStatus, EventStatus.Review)}</span>
                        </Box>
                    }
                ></StepButton>
            </Step>
        </Stepper>
    );
};

export default EventStepper;
