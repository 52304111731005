import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import PriorityHighRounded from "@material-ui/icons/PriorityHighRounded";
import LoadingIndicator from "../../common/loadingIndicator";
import LoadingButton from "../../common/loadingButton";
import Tagging from "../../common/tagging";
import { FinalFormRadioGroup } from "../../common/formWrappers";
import enumHelper from "../../support/logic/enumHelper";
import { getAll as getAllSeasons } from "../api/seasons";
import { getAvailableAdvisors, updateEventDetails } from "../api/programEvents";
import { EventDepartmentType, EventLocationType, Language } from "../enums";
import EventBasicInfo from "./components/eventBasicInfo";
import Logistics from "./components/logistics";
import EventDates from "./components/eventDates";
import Venue from "./components/venue";
import ConfirmCancelledDatesDialog from "./components/confirmCancelledDatesDialog";
import Transportation from "./components/transportation";
import useProgramEvent from "../hooks/useProgramEvent";
import useAjaxStatus from "../../common/useAjaxStatus";
import EventHeader from "../common/eventHeader/eventHeader";
import { OnChange } from "react-final-form-listeners";
import { useHistory } from "react-router";

const focusOnError = createDecorator();

const tagFilter = {
    searchText: "",
    IsActive: true,
    UseEvents: true,

}


const useStyles = makeStyles((theme) => ({
    formHeader: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title saveButton" "subtitle saveButton"`,
    },
    backButton: {
        gridArea: "backButton",
    },
    title: {
        gridArea: "title",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    subtitle: {
        fontWeight: "bold",
        gridArea: "subtitle",
    },
    saveButtonContainer: {
        gridArea: "saveButton",
        justifySelf: "end",
        alignSelf: "start",
    },
    saveButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    saveButtonContainerBottom: {
        display: "flex",
        flexDirection: "row-reverse",
        marginBottom: theme.spacing(5),
    },
    formBody: {
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(5),
    },
    outlinedSection: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    radioGroupContainer: {
        marginBottom: 0,
    },
    sectionTitleWithIcon: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        position: "relative",
        marginLeft: theme.spacing(1),
    },
}));

const EventDetails = ({ isActive, isComplete, topUser }) => {
    const classes = useStyles();
    const { id } = useParams();
    const { programEvent, loading: loadingProgramEvent, setProgramEvent } = useProgramEvent(id);
    const [readOnly, setReadOnly] = useState(false);
    const [loading, setLoading] = useState(true);
    const [seasons, setSeasons] = useState([]);
    const [advisors, setAdvisors] = useState([]);
    const [showCancelledDatesConfirmationDialog, setShowCancelledDatesConfirmationDialog] = useState(false);
    const [tempValues, setTempValues] = useState();
    const [cancelledDates, setCancelledDates] = useState([]);
    const departmentOptions = useMemo(() => enumHelper.getSelectOptionsList(EventDepartmentType), []);
    const locationOptions = useMemo(() => enumHelper.getSelectOptionsList(EventLocationType), []);
    const { ajaxErrorWrapper } = useAjaxStatus();
    const history = useHistory();



    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const fetchSeasonsAsync = async () => {
            try {
                setLoading(true);
                const [seasonsResponse, advisorsResponse] = await Promise.all([
                    getAllSeasons(true, tokenSource.token),
                    getAvailableAdvisors(tokenSource.token),
                ]);

                setSeasons(seasonsResponse.data.result.map((s) => ({ label: s.name, value: s.id })));
                setAdvisors(advisorsResponse.data.result.map((a) => ({ 
                    label: a.fullName,
                    userId: a.id,
                    phoneNumber: a.phoneNumber,
                    emailAddress: a.emailAddress
                })));
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        };
        fetchSeasonsAsync();
        return () => {
            tokenSource.cancel();
        };
    }, []);


     useEffect( () => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }
       
        
        
    }, [topUser, programEvent]);


    const saveEventDetails = async (eventDetails) => {
        try {
            setLoading(true);
            const updateEventDetailsResponse = await updateEventDetails(eventDetails);
            setProgramEvent(updateEventDetailsResponse.data.result);
            toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = async (values) => {
        const newValues = { ...values };
        for (let i = 0; i < newValues.eventDates.length; i++) {
            let { date, startTime, endTime } = newValues.eventDates[i];
            const sTime = date.clone().hour(startTime.hour()).minute(startTime.minute()).second(0).millisecond(0);
            const eTime = date.clone().hour(endTime.hour()).minute(endTime.minute()).second(0).millisecond(0);

            newValues.eventDates[i].startTime = sTime;
            newValues.eventDates[i].endTime = eTime;
        }

        const cancelledDates = values.eventDates.filter((date) => date.cancelled);
        if (cancelledDates?.length) {
            setCancelledDates(cancelledDates);
            setProgramEvent(newValues);
            setTempValues({ ...newValues, eventDates: values.eventDates.filter((date) => !date.cancelled) });
            setShowCancelledDatesConfirmationDialog(true);
            return;
        }

        await saveEventDetails(newValues);
        history.goBack();

    };

    const handleCancelledDatesConfirmation = () => {
        saveEventDetails(tempValues);
    };

    const handleValidate = (values) => {
        const errors = { eventDates: [] };
        const eventDates = values.eventDates;
        
        for (let i = 0; i < eventDates.length; i++) {
            if (!eventDates[i].locationType) {
                errors.eventDates[i] = { ...errors.eventDates[i], locationType: "This field is required" };
            }

            if (!eventDates[i].creditHours /* || eventDates[i].creditHours === "0"*/) {
                errors.eventDates[i] = { ...errors.eventDates[i], creditHours: "This field is required" };
            }

            const start =
                eventDates[i].date && eventDates[i].startTime
                    ? eventDates[i].date.clone().hour(eventDates[i].startTime.hour()).minute(eventDates[i].startTime.minute()).second(0).millisecond(0)
                    : null;
            const end =
                eventDates[i].date && eventDates[i].endTime
                    ? eventDates[i].date.clone().hour(eventDates[i].endTime.hour()).minute(eventDates[i].endTime.minute()).second(0).millisecond(0)
                    : null;

            if (!eventDates[i].date) {
                errors.eventDates[i] = { ...errors.eventDates[i], date: "This field is required" };
            }

            if (!start) {
                errors.eventDates[i] = { ...errors.eventDates[i], startTime: "This field is required" };
            }

            if (!end) {
                errors.eventDates[i] = { ...errors.eventDates[i], endTime: "This field is required" };
            }

            if (!start || !end) {
                continue;
            }

            if (end.isBefore(start) || end.isSame(start)) {
                errors.eventDates[i] = { ...errors.eventDates[i], endTime: "End time must occur AFTER start time" };
            }
            
            for (let j = 0; j < eventDates.length; j++) {
                if (i === j || eventDates.length === 1) {
                    continue;
                }

                if (eventDates.length > 1) {
                    const start1 =
                        eventDates[i].date && eventDates[i].startTime
                            ? eventDates[i].date.clone().hour(eventDates[i].startTime.hour()).minute(eventDates[i].startTime.minute()).second(0).millisecond(0)
                            : null;
                    const end1 =
                        eventDates[i].date && eventDates[i].endTime
                            ? eventDates[i].date.clone().hour(eventDates[i].endTime.hour()).minute(eventDates[i].endTime.minute()).second(0).millisecond(0)
                            : null;
                    const start2 =
                        eventDates[j].date && eventDates[j].startTime
                            ? eventDates[j].date.clone().hour(eventDates[j].startTime.hour()).minute(eventDates[j].startTime.minute()).second(0).millisecond(0)
                            : null;
                    const end2 =
                        eventDates[j].date && eventDates[j].endTime
                            ? eventDates[j].date.clone().hour(eventDates[j].endTime.hour()).minute(eventDates[j].endTime.minute()).second(0).millisecond(0)
                            : null;
                    const type1 = eventDates[i].locationType;
                    const type2 = eventDates[j].locationType;
                    if (!start1 || !end1 || !start2 || !end2) {
                        continue;
                    }

                    const range1 = moment.range(start1, end1);
                    const range2 = moment.range(start2, end2);

                    if (range1.overlaps(range2) && type1 == type2) {
                        if (!errors.eventDates.map((e) => e.index).includes(j)) {
                            errors.eventDates[j] = {
                                ...errors.eventDates[i],
                                date: "Event date overlap!",
                                startTime: "Event date overlap!",
                                endTime: "Event date overlap!",
                            };
                        }
                    }
                }
            }
        }

        return errors;
    };

    if (programEvent == null || seasons.length === 0) {
        return null;
    }

    return (
        <>
            {(loading || loadingProgramEvent) && <LoadingIndicator loading={loading} />}
            <Form
                onSubmit={handleFormSubmit}
                initialValues={{
                    ...programEvent,
                    eventDates: programEvent.eventDates.map((eventDate) => {
                        const date = moment(eventDate.startTime);
                        const startTime = moment(eventDate.startTime);
                        const endTime = moment(eventDate.endTime);
                        return { ...eventDate, date, startTime, endTime };
                    }),
                    logistics: {
                        ...programEvent.logistics,
                        advisors: programEvent.logistics ? programEvent.logistics.advisors.map((a) => ({ label: a.user.fullName, userId: a.userId })) : [],
                    },
                    venue: programEvent.locationType === EventLocationType.Virtual ? null : { ...programEvent.venue, language: Language.English },
                    transportation: programEvent.transportation ? programEvent.transportation : { provided: false },
                }}
                mutators={{ ...arrayMutators }}
                decorators={[focusOnError]}
                validate={handleValidate}
            >
                {({ handleSubmit, form, values, dirty, errors }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Prompt
                                when={dirty}
                                message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                            />
                            <ConfirmCancelledDatesDialog
                                open={showCancelledDatesConfirmationDialog}
                                cancelledDates={cancelledDates}
                                onClose={() => setShowCancelledDatesConfirmationDialog(false)}
                                onSubmit={handleCancelledDatesConfirmation}
                            />
                            <div className={classes.formHeader}>
                                <EventHeader mainTitle={programEvent.name} subTitle={"Event Details"} />
                                <LoadingButton
                                    wrapperClassName={classes.saveButtonContainer}
                                    className={classes.saveButton}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isComplete || readOnly }
                                >
                                    Save
                                </LoadingButton>
                            </div>
                            <div className={classes.formBody}>
                                <EventBasicInfo departmentOptions={departmentOptions} seasons={seasons} />
                                <Paper variant="outlined" classes={{ root: classes.outlinedSection }}>
                                    <Typography className={classes.sectionTitleWithIcon} variant="h5" color="secondary" gutterBottom>
                                        Tagging
                                        <PriorityHighRounded className={classes.icon} disabled={isComplete} />
                                    </Typography>
                                    <Tagging tagFilter={tagFilter} mode="Event" id={id} disabled={isComplete || readOnly} />
                                </Paper>
                                <Paper variant="outlined" classes={{ root: classes.outlinedSection }}>
                                    <Typography className={classes.title} variant="h5" color="secondary" gutterBottom>
                                        LOCATION
                                    </Typography>
                                    <Field
                                        formControlClassName={classes.radioGroupContainer}
                                        required
                                        name="locationType"
                                        row
                                        fullWidth
                                        format={(value) => {
                                            return value ? value.toString() : "10";
                                        }}
                                        parse={(value) => {
                                            return parseInt(value, 10);
                                        }}
                                        component={FinalFormRadioGroup}
                                        choices={locationOptions}
                                    />
                                    <OnChange name="locationType">
                                        {(value, previous) => {
                                            if (value === EventLocationType.Virtual) {
                                                form.change(
                                                    "venue",
                                                    programEvent.venue === null ? null : { ...programEvent.venue, language: Language.English }
                                                );
                                            } else {
                                                form.change("venue", { language: Language.English });
                                            }
                                        }}
                                    </OnChange>
                                </Paper>
                                <Paper variant="outlined" classes={{ root: classes.outlinedSection }}>
                                    <Typography className={classes.sectionTitleWithIcon} variant="h5" color="secondary" gutterBottom>
                                        Date &amp; Time&nbsp;
                                        <PriorityHighRounded className={classes.icon} />
                                    </Typography>
                                    <EventDates
                                        eventStatus={programEvent.status}
                                        eventDates={values.eventDates}
                                        isSeries={values.isSeries}
                                        topLevelLocationType={values.locationType}
                                        eventLocationOptions={locationOptions.filter((l) => l.value !== EventLocationType.Both)}
                                        mutators={form.mutators}
                                        change={form.change}
                                        disabled={isComplete || readOnly}
                                    />
                                </Paper>

                                <Paper variant="outlined" classes={{ root: classes.outlinedSection }}>
                                    <Typography className={classes.sectionTitleWithIcon} variant="h5" color="secondary" gutterBottom>
                                        Logistics&nbsp;
                                        <PriorityHighRounded className={classes.icon} />
                                    </Typography>
                                    <Logistics advisors={advisors} locationType={values.locationType} values={values} disabled={isComplete || readOnly} />
                                </Paper>

                                {values.locationType !== EventLocationType.Virtual && <Venue language={values.venue?.language} />}
                                {[EventLocationType.Both, null, EventLocationType.InPerson].includes(values.locationType) && (
                                    <Paper variant="outlined" classes={{ root: classes.outlinedSection }}>
                                        <Typography className={classes.sectionTitleWithIcon} variant="h5" color="secondary" gutterBottom>
                                            Transportation
                                            <PriorityHighRounded className={classes.icon} />
                                        </Typography>
                                        <Transportation form={form} disabled={isComplete || readOnly} />
                                    </Paper>
                                )}
                            </div>
                            <LoadingButton
                                wrapperClassName={classes.saveButtonContainerBottom}
                                className={classes.saveButton}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isComplete || readOnly}
                            >
                                Save
                            </LoadingButton>
                        </form>
                    );
                }}
            </Form>
        </>
    );
};

export default EventDetails;
