import { ajaxStatusLoadingAction, ajaxStatusErrorAction, ajaxStatusCompleteAction } from "./ajax";
import { getList as getSchools, getStaffMiddleSchools } from "../api/school";
import { getList as getCities } from "../api/city";
import { getVideoSettings, getAdmissionSettings } from "../api/admin";

import { getRoles } from "../api/session";

const SCHOOL_RETRIEVE_SUCCESSFUL = "SCHOOL_RETRIEVE_SUCCESSFUL";
const ROLES_SUCCESS = "ROLES_SUCCESS";
const VIDEO_SETTINGS = "VIDEO_SETTINGS";
const ADMISSION_SETTINGS = "ADMISSION_SETTINGS";
const STAFF_MIDDLE_SCHOOL_CONTEXT = "STAFF_MIDDLE_SCHOOL_CONTEXT";
const CITIES_RETRIEVE_SUCCESSFUL = "CITIES_RETRIEVE_SUCCESSFUL";

export const loadCities = () => {
    return async (dispatch) => {
        dispatch(ajaxStatusLoadingAction());

        try {
            let cities = (await getCities()).data.result;
            cities = cities.map(function (city) {
                return { value: city.id, label: city.name };
            });

            dispatch({ type: CITIES_RETRIEVE_SUCCESSFUL, cities });
            dispatch(ajaxStatusCompleteAction());
        } catch (error) {
            dispatch(ajaxStatusErrorAction("Error retrieving cities"));
        }
    };
};
export const loadStaffMiddleSchools = () => {
    return async (dispatch) => {
        dispatch(ajaxStatusLoadingAction());

        try {
            const schools = (await getStaffMiddleSchools()).data.result;
            dispatch({ type: STAFF_MIDDLE_SCHOOL_CONTEXT, schools });
            dispatch(ajaxStatusCompleteAction());
        } catch (error) {
            dispatch(ajaxStatusErrorAction("Error retrieving staff middle schools"));
        }
    };
};

export const setStaffMiddleSchools = (schools) => {
    return { type: STAFF_MIDDLE_SCHOOL_CONTEXT, schools };
};

export const staffMiddleSchoolsReducer = (state = [], action) => {
    if (action.type === STAFF_MIDDLE_SCHOOL_CONTEXT) {
        return [...action.schools];
    }
    return state;
};

export const updateVideoSettingsAction = (videoSettings) => {
    return { type: VIDEO_SETTINGS, videoSettings };
};

export const updateAdmissionSettingsAction = (admissionSettings) => {
    return { type: ADMISSION_SETTINGS, admissionSettings };
};

export const loadCodeTablesAction = () => {
    return async (dispatch) => {
        dispatch(ajaxStatusLoadingAction());

        try {
            const schools = (await getSchools()).data.result;
            dispatch({ type: SCHOOL_RETRIEVE_SUCCESSFUL, schools });

            const roles = (await getRoles()).data.result;
            dispatch({ type: ROLES_SUCCESS, roles });

            const videoSettings = (await getVideoSettings()).data.result;
            dispatch({ type: VIDEO_SETTINGS, videoSettings });

            const admissionSettings = (await getAdmissionSettings()).data.result;
            dispatch({ type: ADMISSION_SETTINGS, admissionSettings });

            //TODO: Other code tables

            dispatch(ajaxStatusCompleteAction());
        } catch (error) {
            console.error(error);
            dispatch(ajaxStatusErrorAction("Error retrieving Code Tables"));
        }
    };
};

export const schoolsReducer = (state = [], action) => {
    if (action.type === SCHOOL_RETRIEVE_SUCCESSFUL) {
        return [...action.schools];
    }
    return state;
};

export const CitiesReducer = (state = [], action) => {
    if (action.type === CITIES_RETRIEVE_SUCCESSFUL) {
        return [...action.cities];
    }
    return state;
};

export const rolesReducer = (state = [], action) => {
    if (action.type === ROLES_SUCCESS) {
        return [...action.roles];
    }
    return state;
};

const defaultAdmissionSettings = {
    orientationVideoEnabled: false,
    orientationVideoEnglish: "",
    orientationVideoSpanish: "",
    admissionPhone: "",
    eventsPhone: "",
};

export const admissionSettingsReducer = (state = defaultAdmissionSettings, action) => {
    if (action.type === VIDEO_SETTINGS) {
        return { ...state, ...action.videoSettings };
    } else if (action.type === ADMISSION_SETTINGS) {
        return { ...state, ...action.admissionSettings };
    }
    return state;
};
