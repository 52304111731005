/* eslint no-control-regex: 0 */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";

import * as apis from "../../api/login";
import ForgotPassword from "./ForgotPassword";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import withLocale from "../../support/hoc/withLocale";

class ForgotPasswordContainer extends Component {
  state = {
    showLogin: false,
    showDialog: false,
    dialogTitle: "",
    dialogText: "",
    showResetUsername: false,
    userName: "",
    isErrorDialog: false
  };

  doCheckIfEmail = userNameEmail => {
    const pattern = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return pattern.test(userNameEmail);
  };

  onSubmit = ({ userNameEmail }) => {
    this.props.ajaxStatusLoading();

    if (this.doCheckIfEmail(userNameEmail)) {
      apis
        .forgotPassword(userNameEmail, this.props.locale)
        .then(response => {
          this.props.ajaxStatusComplete();
          this.setState({
            showDialog: true,
            isErrorDialog: false,
            dialogTitle: this.props.intl.formatMessage({ id: "app.success" }),
            dialogText: this.props.intl.formatMessage({ id: "login.forgotDialog" })
          });
        })
        .catch(error => {
          this.props.ajaxStatusComplete();
          this.setState({
            showDialog: true,
            isErrorDialog: true,
            dialogTitle: this.props.intl.formatMessage({ id: "login.missingInformation" }),
            dialogText: error.response.data.error.message
          });
        });
      return;
    }

    apis
      .forgotPasswordByUserName(userNameEmail, this.props.locale)
      .then(response => {
        this.props.ajaxStatusComplete();
        this.setState({
          showResetUsername: true,
          userName: userNameEmail
        });
      })
      .catch(error => {
        this.props.ajaxStatusComplete();
        this.setState({
          showDialog: true,
          isErrorDialog: true,
          dialogTitle: this.props.intl.formatMessage({ id: "login.missingInformation" }),
          dialogText: error.response.data.error.message
        });
      });
  };

  onCancel = e => {
    e.preventDefault();
    this.setState({
      showLogin: true
    });
  };

  onCloseDialog = () => {
    this.setState({ showDialog: false }, () => {
      if (!this.state.isErrorDialog) {
        this.setState({ showLogin: true });
      }
    });
  };

  render() {
    if (this.state.showLogin) {
      return <Redirect to="/login" />;
    }

    if (this.state.showResetUsername) {
      return <Redirect to={`/resetusername/${this.state.userName}`} />;
    }

    return (
      <React.Fragment>
        <ForgotPassword onSubmit={this.onSubmit} onCancel={this.onCancel} />

        <SweetAlert
          show={this.state.showDialog}
          title={this.state.dialogTitle}
          onConfirm={this.onCloseDialog}
          confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
        >
          {this.state.dialogText}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

ForgotPasswordContainer = withAjaxStatus(ForgotPasswordContainer);
ForgotPasswordContainer = withLocale(ForgotPasswordContainer);

export default injectIntl(ForgotPasswordContainer);
