import React from "react";
import { useHistory } from "react-router";
import ConfirmDialog from "../../../../common/confirmDialog";
import useAjaxStatus from "../../../../common/useAjaxStatus";
import { EventLocationType, EventStatus } from "../../../enums";
import { toast } from "react-toastify";
import { approveEventDateAttendance, updateEventStatus } from "../../../api/programEvents";
import { useStyles } from "./styles";

const ApprovingDialog = ({ isOpen, isLastToApprove, closeDialog, eventData: { eventName, eventDate, id }, setRows }) => {
    const classes = useStyles();
    const history = useHistory();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handleOnConfirm = async () => {
        try {
            const resp = await approveEventDateAttendance(id);
            setRows((prev) =>
                prev.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            approved: true,
                        };
                    }
                    return item;
                })
            );
            resp.data.success && toast.success("Changes saved successfully");
            if (isLastToApprove) {
              // await  updateEventStatus(id, EventStatus.Complete);
            }
            isLastToApprove && history.push({ pathname: `/events/complete/` });
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        closeDialog(true);
    };

    const dialogContent = (
        <div>
            <p>
                Are you sure you’re ready to Approve attendance list for <b>{eventName.toUpperCase()}</b> on <b>{eventDate}</b>?
            </p>
            <p>Credit hours will be awarded to event participants. </p>
            {isLastToApprove && (
                <div className={classes.warning}>
                    <p className={classes.cropMargin}>
                        This is the final attendance list to be approved and event will automatically be set to <b>Complete</b>.
                    </p>
                </div>
            )}
        </div>
    );

    return (
        <ConfirmDialog
            open={isOpen}
            disablePortal
            handleClose={closeDialog}
            confirmAction={handleOnConfirm}
            confirmActionText="Approve"
            fullWidth
            maxWidth="sm"
            title="Approve Event"
            body={dialogContent}
        />
    );
};

export default ApprovingDialog;
