const normalizeZip = (value, previousValue) => {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 5) + '-' + onlyNums.slice(5);
        }
    }
    if (onlyNums.length <= 5) {
        return onlyNums;
    }
    return onlyNums.slice(0, 5) + '-' + onlyNums.slice(5);
};

export default normalizeZip;
