import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const ConfirmCancelledDatesDialog = ({ cancelledDates, onClose, onSubmit, ...rest }) => {
    const classes = useStyles();
    const plural = cancelledDates.length > 1 ? '(s)' : '';

    return (
        <Dialog onClose={onClose} {...rest}>
            <DialogTitle>
                Cancel Date{plural}
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>Are you sure you want to <strong>Cancel</strong> the following event date{plural}?:</Typography>
                {cancelledDates.map((date, index) => (
                    <Typography variant="body1" key={`cancelled-date-${index}`} gutterBottom={index === cancelledDates.length - 1}>
                        <strong>{`${date.startTime.format("L")}  ${date.startTime.format("LT")} - ${date.endTime.format("LT")}`}</strong>
                    </Typography>
                ))}
                <Typography variant="body1">
                    Participants will no longer have access to the event date{plural} and will be notified of the cancellation.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onSubmit();
                        onClose();
                    }}
                >
                    Save &amp; Cancel Date{plural}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmCancelledDatesDialog;
