import React, { useEffect, useMemo, useState } from "react";
import { OnChange } from "react-final-form-listeners";
import { Form, Field } from "react-final-form";
import { FinalFormDropdown, FinalFormSwitch, FinalFormTextField } from "../../../common/formWrappers";
import { EnrollmentCapMaskInput, WaitlistCapMaskInput } from "../../../common/masks";
import { toast } from "react-toastify";
import LoadingButton from "../../../common/loadingButton";
import { getWaitlistEventDates, updateWaitlistSettings } from "../../api/programEvents";
import { required } from "../../../common/formValidators";
import { makeStyles } from "@material-ui/styles";
import { ProgramEventDateWaitlistResponseDuration } from "../../enums";
import enumHelper from "../../../support/logic/enumHelper";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles({
    enrollmentSettingsForm: {
        display: "flex",
        flexDirection: "column",
    },
    saveButton: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    enrollmentAlert: {
        marginBottom: "8px",
        color: "red",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "row",
    },
    responseTime: {
        marginRight: "8px",
        flexGrow: 1,
    },
    responseDuration: {
        width: "48%",
    },
});

const WaitlistSettings = ({
    closeSettingsDialog,
    id,
    programEventDateId,
    registeredUsersCount,
    setWaitlistCapacityAux,
    setWaitlistResponseTimeAux,
    setWaitlistResponseDurationAux,
    disabled
}) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const [waitlistCapacity, setWaitlistCapacity] = useState(0);
    const [data, setData] = useState(null);

    const choices = useMemo(() => enumHelper.getSelectOptionsList(ProgramEventDateWaitlistResponseDuration), []);

    useEffect(() => {
        getWaitlistEventDates(id)
            .then((resp) => {
                const eventData = resp.data.result.dates.find((date) => date.id === programEventDateId);
                setWaitlistCapacity(eventData.waitlistCapacity);
                // TODO: Remove 0 validation when backend response fixed
                if (eventData.waitlistResponseDuration === 0) {
                    eventData.waitlistResponseDuration = 1;
                }
                setData(eventData);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, id, programEventDateId]);

    const handleOnSubmitSettings = async ({ waitlistOpen, waitlistCapacity, waitlistResponseTime, waitlistResponseDuration }) => {
        const payload = {
            programEventId: id,
            programEventDateId: programEventDateId,
            isOpen: waitlistOpen,
            capacity: waitlistCapacity,
            waitlistResponseTime,
            waitlistResponseDuration,
        };

        try {
            const resp = await updateWaitlistSettings(payload);
            setWaitlistCapacityAux(waitlistCapacity);
            setWaitlistResponseTimeAux(waitlistResponseTime);
            setWaitlistResponseDurationAux(waitlistResponseDuration);
            resp.data.success && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        closeSettingsDialog();
    };

    return (
        <Form onSubmit={handleOnSubmitSettings} initialValues={data}>
            {({ handleSubmit, values, errors, form }) => {
                return (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className={classes.enrollmentSettingsForm}>
                            <Field component={FinalFormSwitch} name="waitlistOpen" disabled={ disabled } label="Open Waitlist" />
                            {!values.waitlistOpen && <small className={classes.enrollmentAlert}>Participants can no longer enroll</small>}
                            <Field
                                component={FinalFormTextField}
                                name="waitlistCapacity"
                                label="Waitlist Cap"
                                variant="outlined"
                                margin="dense"
                                required
                                validate={required}
                                inputComponent={EnrollmentCapMaskInput}
                                disabled={!values.waitlistOpen || disabled}
                            />
                            {values.waitlistCapacity < registeredUsersCount && (
                                <small className={classes.enrollmentAlert}>Must be greater than current enrollment: {registeredUsersCount}</small>
                            )}

                            <div className={classes.inputContainer}>
                                <Field
                                    component={FinalFormTextField}
                                    name="waitlistResponseTime"
                                    label="Response Time"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    validate={required}
                                    inputComponent={WaitlistCapMaskInput}
                                    disabled={!values.waitlistOpen || disabled}
                                    className={classes.responseTime}
                                />

                                <Field
                                    component={FinalFormDropdown}
                                    name="waitlistResponseDuration"
                                    variant="outlined"
                                    margin="dense"
                                    label=" "
                                    required
                                    validate={required}
                                    disabled={!values.waitlistOpen || disabled}
                                    className={classes.responseDuration}
                                    choices={choices}
                                />
                            </div>

                            <OnChange name="waitlistOpen">
                                {(value, previous) => {
                                    if (!value || !values.waitlistCapacity) {
                                        form.change("waitlistCapacity", waitlistCapacity);
                                    }
                                }}
                            </OnChange>
                        </div>
                        <div className={classes.saveButton}>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={values.waitlistCapacity < registeredUsersCount || !values.waitlistCapacity || disabled}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default WaitlistSettings;
