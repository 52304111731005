import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    eventsList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: 5,
    },
    gridItem: {
        display: "flex",
    },
    list: {
        display: "flex",
        flexWrap: "wrap",
        height: "65vh",
        alignContent: "flex-start",
    },
    test: {
        display: "flex",
        justifyContent: "center",
    },
}));
