import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    header: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title saveButton" "subtitle saveButton"`,
        marginBottom: theme.spacing(3),
    },
    title: {
        gridArea: "title",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    subtitle: {
        fontWeight: "bold",
        gridArea: "subtitle",
    },
    customColor: {
        color: theme.palette.lightGray.main,
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.warning.main,
        borderRadius: "50%",
    },
    iconComplete: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
    },
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(0, 2),
    },
    greenLabel: {
        color: "green",
        fontWeight: "bold",
    },
    redLabel: {
        color: "red",
        fontWeight: "bold",
    },
    italic: {
        fontStyle: "italic",
    },
}));
