import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Spinner from "react-spinkit";
import { useTheme } from "@material-ui/core";

const LoadingIndicator = ({ loading }) => {
    const theme = useTheme();

    return loading ? (
        <Backdrop open={loading} style={{ zIndex: 2000 }}>
            <Spinner name="line-scale" color={theme.palette.primary.main} fadeIn="none" />
        </Backdrop>
    ) : null;
};

export default LoadingIndicator;
