import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isDirty, hasSubmitSucceeded } from 'redux-form';
import { Prompt } from 'react-router';

const withPrompChange = (WrappedComponent, formName) => {
    class WithPrompChange extends Component {
        state = {
            when: false
        };
        constructor(props) {
            super(props);
            const message = props.intl.formatMessage({ id: 'app.dataLoss' });
            const title = props.intl.formatMessage({ id: 'app.dataLossTitle' });
            const yes = props.intl.formatMessage({ id: 'Yes' });
            const cancel = props.intl.formatMessage({ id: 'app.cancel' });
            this.message = `${message}||${title}||${yes}||${cancel}`;
        }
        componentDidMount() {
            this.setState({ when: true });
        }
        doCheckCanGo = (location, action) => {
            if (this.props.isDirty && !this.props.isSubmitted) {
                return `${this.message}||${this.title}||${this.yes}||${this.cancel}`;
            }
        };
        render() {
            return (
                <Fragment>
                    <WrappedComponent {...this.props} />
                    <Prompt when={this.state.when} message={this.doCheckCanGo} />
                </Fragment>
            );
        }
    }

    const mapStateToProps = state => {
        return {
            isDirty: isDirty(formName)(state),
            isSubmitted: hasSubmitSucceeded(formName)(state)
        };
    };

    WithPrompChange = connect(mapStateToProps)(WithPrompChange);

    return injectIntl(WithPrompChange);
};

export default withPrompChange;
