import React, { Component } from "react";
import moment from "moment";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import memoize from "memoize-one";
import FileSaver from "file-saver";

import b64toBlob from "../../../support/logic/b64toBlob";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import Search from "../../../staff/common/search";
import * as TransactionApi from "../../../api/scholarshipTransactions";

import "./index.css";

class NotCertified extends Component {
    state = {
        searchText: "",
        studentList: [],
    };

    componentDidMount() {
        this.loadNotCertifiedList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.scholarship !== prevProps.scholarship) {
            this.loadNotCertifiedList();
        }
    }

    loadNotCertifiedList = async () => {
        if (!this.props.scholarship) {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const studentList = (await TransactionApi.getNotCertifiedList(this.props.scholarship.id)).data.result;
            this.setState({ studentList });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error getting pay history");
        }
    };

    setSearchRef = (element) => {
        this.searchRef = element;
    };

    onSearch = (searchText) => {
        this.setState({ searchText });
    };

    onViewAllClick = () => {
        if (this.searchRef) this.searchRef.onClear();
    };

    getFilterList = (searchText) => {
        return matchSorter(this.state.studentList, searchText, { keys: ["lastName", "firstName", "crusaderId", "ssn"] });
    };

    onExportToExcel = async () => {
        try {
            this.props.ajaxStatusLoading();
            let response = (await TransactionApi.getNotCertifiedExport(this.props.scholarship.id)).data.result;
            let fileName = "NotCertified.xlsx";
            this.props.ajaxStatusComplete();
            const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            FileSaver.saveAs(blob, fileName);
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    render() {
        const { searchText } = this.state;

        const memoizeGetFilterList = memoize(this.getFilterList);
        const filteredList = memoizeGetFilterList(searchText);

        return (
            <div className="not-certified-list">
                <div className="button-area">
                    <button className={`btn ${searchText === "" ? "" : "inactive"}`} onClick={this.onViewAllClick}>
                        View All
                    </button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Search ref={this.setSearchRef} onClick={this.onSearch} />
                    <button className="btn" onClick={this.onExportToExcel}>
                        Export To Excel
                    </button>
                </div>
                <div className="header-area">
                    <h2>Not-Certified Students ({filteredList.length})</h2>
                    <div className="report-date">As of {moment().format("M/D/YY")}</div>
                </div>
                <ReactTable
                    className="financials-table"
                    data={filteredList}
                    minRows={0}
                    showPagination
                    columns={[
                        {
                            Header: "Last Name",
                            accessor: "lastName",
                        },
                        {
                            Header: "First Name",
                            accessor: "firstName",
                        },
                        {
                            Header: "Social Security",
                            accessor: "ssn",
                        },
                        {
                            Header: "Onward ID",
                            accessor: "crusaderId",
                        },
                    ]}
                />
            </div>
        );
    }
}

NotCertified = withAjaxStatus(NotCertified);

export default NotCertified;
