import React, { useState, useEffect, useMemo } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form } from "react-final-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";

import * as seasonApis from "../api/seasons";
import LoadingIndicator from "../../common/loadingIndicator";
import SeasonItem from "./seasonItem";
import { EMPTY_GUID } from "../../support/constants";
import deepCopyObject from "../../support/logic/deepCopyObject";
import useAjaxStatus from "../../common/useAjaxStatus";

const useStyles = makeStyles((theme) => ({
    seasons: {},
    form: {
        display: "flex",
        flexDirection: "column",
    },
    addButton: {
        marginLeft: theme.spacing(1),
    },
}));

const Seasons = () => {
    const classes = useStyles();

    const [seasons, setSeasons] = useState([]);
    const [editRowId, setEditRowId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [reloadData, reload] = useState(false);
    const { ajaxErrorWrapper } = useAjaxStatus();

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        (async () => {
            try {
                setLoading(true);
                const response = seasonApis.getAll(null, tokenSource.token);
                setSeasons((await response).data.result);
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, [ajaxErrorWrapper, reloadData]);

    const handleEdit = (season, initialize) => {
        initialize(season);
        setEditRowId(season.id);
    };

    const handleCancel = (season) => {
        if (season.id === EMPTY_GUID) {
            //cancel click
            setSeasons((prev) => {
                const newSeasons = deepCopyObject(prev);
                newSeasons.splice(0, 1);

                return newSeasons;
            });
            setEditRowId(null);
            return;
        }

        setEditRowId(null);
    };

    const handleAddClick = (initialize) => {
        let order = 0;
        if (seasons.length > 0) {
            order = Math.min(...seasons.map((s) => s.order)) - 1;
        }
        const season = { id: EMPTY_GUID, name: "", spanishName: "", isActive: true, order };
        initialize(season);
        setSeasons((prev) => {
            const newSeasons = [...prev];
            newSeasons.unshift(season);
            return newSeasons;
        });
        setEditRowId(EMPTY_GUID);
    };

    const handleSave = async (values) => {
        try {
            setSaving(true);
            if (values.id === EMPTY_GUID) {
                const s = (await seasonApis.create(values)).data.result;
                setSeasons((prev) => {
                    const newSeasons = [...prev];
                    newSeasons[0] = s;
                    return newSeasons;
                });
            } else {
                await seasonApis.update(values);
            }
            setEditRowId(null);
            reload((prev) => !prev);
            toast.success("Changes saved successfully");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setSaving(false);
        }
    };

    const handleReorder = async ({ source, destination, draggableId }) => {
        if (!destination) {
            return;
        }

        if (destination.index === source.index) {
            return;
        }

        try {
            setSeasons((prev) => {
                const newSeasons = deepCopyObject(prev);
                const season = newSeasons.find((item) => item.id === draggableId);
                newSeasons.splice(source.index, 1);
                newSeasons.splice(destination.index, 0, season);

                seasonApis
                    .updateOrder(newSeasons.map((s) => s.id))
                    .then(() => toast.success("Seasons reordered"))
                    .catch(() => toast.error("Failed to reorder seasons, contact the administrator"));

                return newSeasons;
            });
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
        }
    };

    const renderedTableHead = useMemo(() => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: "5%" }}></TableCell>
                    <TableCell style={{ width: "35%" }}>Name</TableCell>
                    <TableCell style={{ width: "35%" }}>Spanish Translation</TableCell>
                    <TableCell style={{ width: "5%" }}>Active</TableCell>
                    <TableCell style={{ width: "20%" }}></TableCell>
                </TableRow>
            </TableHead>
        );
    }, []);

    return (
        <div className={classes.seasons}>
            <Form onSubmit={handleSave}>
                {({ handleSubmit, form, values, errors }) => {
                    return (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <LoadingIndicator loading={loading} />
                            <Box pb={1} display="flex">
                                <Box flex={1} display="flex">
                                    <Typography variant="h5">Active Seasons</Typography>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => handleAddClick(form.initialize)}
                                        className={classes.addButton}
                                        startIcon={<AddIcon />}
                                    >
                                        Add Season
                                    </Button>
                                </Box>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={showInactive} onChange={() => setShowInactive((prev) => !prev)} />}
                                    label="Show Inactive"
                                />
                            </Box>
                            <Table style={{ tableLayout: "auto" }}>
                                {renderedTableHead}
                                <DragDropContext onDragEnd={handleReorder}>
                                    <Droppable droppableId="tableBody">
                                        {(provided, snapshot) => (
                                            <TableBody ref={provided.innerRef}>
                                                {seasons.filter((s) => s.isActive).length === 0 && (
                                                    <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography>No active seasons</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {seasons
                                                    .filter((s) => s.isActive)
                                                    .map((season, index) => {
                                                        return (
                                                            <Draggable
                                                                key={season.id ? season.id : 0}
                                                                draggableId={season.id ? season.id : 0}
                                                                index={index}
                                                                isDragDisabled={editRowId !== null}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <SeasonItem
                                                                        ref={provided.innerRef}
                                                                        season={season}
                                                                        editRowId={editRowId}
                                                                        onEdit={(s) => handleEdit(s, form.initialize)}
                                                                        onCancel={handleCancel}
                                                                        saving={saving}
                                                                        DragHandleProps={provided.dragHandleProps}
                                                                        DraggableProps={provided.draggableProps}
                                                                        isDragging={snapshot.isDragging}
                                                                    />
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                {provided.placeholder}
                                            </TableBody>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Table>
                            {showInactive && (
                                <>
                                    <Box pt={4} pb={1}>
                                        <Typography variant="h5">Inactive Seasons</Typography>
                                    </Box>
                                    <Table style={{ tableLayout: "auto" }}>
                                        {renderedTableHead}
                                        <TableBody>
                                            {seasons
                                                .filter((s) => !s.isActive)
                                                .map((season) => {
                                                    return (
                                                        <SeasonItem
                                                            key={season.id ? season.id : 1}
                                                            season={season}
                                                            editRowId={editRowId}
                                                            onEdit={(s) => handleEdit(s, form.initialize)}
                                                            onCancel={handleCancel}
                                                            saving={saving}
                                                            DragHandleProps={{}}
                                                            DraggableProps={{}}
                                                            isDragging={false}
                                                        />
                                                    );
                                                })}
                                            {seasons.filter((s) => !s.isActive).length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={4} style={{ textAlign: "center" }}>
                                                        No inactive seasons found...
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default Seasons;
