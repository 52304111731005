import React, { Component } from 'react';
import * as loadImage from 'blueimp-load-image';

import b64toBlob from '../../logic/b64toBlob';

class ExifImage extends Component {
    container = null;

    async componentDidMount() {
        await this.renderImage();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.data === prevProps.data) {
            return;
        }

        await this.renderImage();
    }

    loadImage(base64Data, mimeType) {
        return new Promise(resolve => {
            const blob = b64toBlob(base64Data, mimeType);

            loadImage(blob, img => resolve(img), {
                orientation: true,
                canvas: true
            });
        });
    }

    async renderImage() {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }

        const loadedImage = await this.loadImage(this.props.base64Data, this.props.mimeType);

        loadedImage.alt = this.props.title;
        loadedImage.title = this.props.title;

        this.container.appendChild(loadedImage);
    }

    render() {
        return <div ref={el => (this.container = el)} className="exif-image" />;
    }
}

export default ExifImage;
