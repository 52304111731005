import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field, Form } from "react-final-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import LoadingButton from "../../../common/loadingButton";
import { FinalFormTextField } from "../../../common/formWrappers";
import { required } from "../../../common/formValidators";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles((theme) => ({
    descriptionDialog: {},
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const DescriptionDialog = ({ open, onClose, onSave, programEvent, disabled }) => {
    const classes = useStyles();
    const { ajaxCallsInProgress } = useAjaxStatus();

    return (
        <Form
            onSubmit={(values) => onSave(values)}
            initialValues={
                programEvent ? { descriptionEnglish: programEvent.descriptionEnglish, descriptionSpanish: programEvent.descriptionSpanish } : undefined
            }
        >
            {({ handleSubmit, form, values }) => {
                const handleClose = (evnt, reason) => {
                    if (reason) {
                        return;
                    }

                    form.initialize(
                        programEvent ? { descriptionEnglish: programEvent.descriptionEnglish, descriptionSpanish: programEvent.descriptionSpanish } : undefined
                    );
                    onClose();
                };

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Dialog
                            onClose={handleClose}
                            open={open}
                            className={classes.descriptionDialog}
                            maxWidth="md"
                            fullWidth
                            disableEscapeKeyDown
                            disablePortal
                        >
                            <DialogTitle>
                                Event Description
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body2">Event description will display to invited participants in the mobile application.</Typography>
                                <Box display="flex" flexDirection="column">
                                    <Box mt={2}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="descriptionEnglish"
                                            label="English"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            multiline
                                            disabled={disabled} 
                                            maxLength={5000}
                                            minRows={4}
                                            maxRows={4}
                                            required
                                            validate={required}
                                        />
                                    </Box>
                                    <Box marginY={1}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="descriptionSpanish"
                                            label="Spanish"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            multiline
                                            disabled={disabled} 
                                            maxLength={5000}
                                            minRows={4}
                                            maxRows={4}
                                            required
                                            validate={required}
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={ajaxCallsInProgress !== 0}>
                                    Cancel
                                </Button>
                                <LoadingButton loading={ajaxCallsInProgress !== 0} variant="contained" color="primary" disabled={disabled}  type="submit">
                                    Save
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </form>
                );
            }}
        </Form>
    );
};

export default DescriptionDialog;
