import React, { Component } from "react";
import DocumentSection from "../documents/documentSection";
import { Form, Field } from "react-final-form";
import moment from 'moment-timezone';
import { ThemeProvider } from "@material-ui/core/styles";
import { createCCMUITheme } from "../../support/muiTheme";
import { FinalFormCheckbox, FinalFormTextField, FinalFormRadioGroup, FinalFormKeyboardTimePicker, FinalFormDropdown } from "../../common/formWrappers";
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";

import { required, renderField, renderRadioField, renderSelectList, email, phone, zip } from "../../support/forms";
import Link from "@material-ui/core/Link";
import Button from "../../support/components/Button";
import TimePicker from 'react-time-picker';
import * as api from '../../advisors/users/apis';
import normalizePhone from "../../support/logic/normalizePhone";
import { GroupType } from "../logic/enums";
import { Gender } from "../logic/enums";
import enumHelper from "../../support/logic/enumHelper";
import { languages } from "../../support/languages";
import { loadCities } from "../../redux/codeTables";
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { bindActionCreators } from "redux";
import { getList as getCities } from "../../api/city";
import { getList as getSchools } from "../../api/school";
import { states } from "../../support/states";
import './index.css';
import BackButton from "../../common/backButton";
import 'react-time-picker/dist/TimePicker.css';

class StudentProfile extends Component {


    state = {
        activeUser: [],
        cities: [],
        states: [],
        schools: [],
        prof: []
    };
    componentDidMount = async () => {

        //console.log(this.props);

        
        const prof = await api.getStudentById(this.props.match.params.id)

        var user = await api.getProfile(this.props.match.params.id);
        //console.log(prof);
        //console.log(user);
        // Convert the bestTimeCallStart and bestTimeCallEnd to the TimePicker format
        

        var cities = await getCities();
        var schools = await getSchools();
        this.setState({
            activeUser: user.data.result,
            schools: schools.data.result,
            cities: cities.data.result,
            states: states,
            prof: prof.data.result,
        });
    }

    handleSubmit = async (values, form) => {
        // Function to check if the value already contains a date
        

        // Rest of your existing code...
        if (values.cityId) {
            values.city = this.state.cities.find(city => city.id === values.cityId).name;
        }
        await api.updateProfile(values);
       
        toast.success("Update Saved");
        this.props.onClose();
    }


    handleCityChange = (event) => {

        // Find the selected city using the event.target.value which is cityId
        const selectedCityId = event.target.value;
        const selectedCity = this.state.cities.find(city => city.id === selectedCityId);

        // Dispatch the change action to update the "city" field
        if (selectedCity) {

            var selectedUser = this.state.activeUser;
            selectedUser.city = selectedCity;
            this.setState({ activeUser: selectedUser });
            return true;
        }
    };
    genderOptions = enumHelper.getSelectOptionsList(Gender);
    render() {
        const verified = true;
        const schoolOptions = this.state.schools.map((school) => ({ value: school.id, label: school.name }));

        return (
            <ThemeProvider theme={createCCMUITheme()}>

            <Form onSubmit={this.handleSubmit} initialValues={this.state.activeUser}>
                {({ handleSubmit, values, dirty, errors }) => {
                        //console.log(values);
                    return (
                        <form onSubmit={handleSubmit} FormValues={this.state.activeUser} noValidate className="test">

                            <div className="genInfoFormWrapper">
                                <BackButton onClick={this.props.onClose} /><br/>

                                <FormattedMessage id="events.profile" defaultMessage="[events.profile]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage}</span>)} />

                                {/* {isParent && <div className="parent-note">For parent users, only password edits will be saved.</div>} */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.firstName"
                                            defaultMessage="[First Name]"
                                            children={(formattedMessage) => (
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    component={renderField}
                                                    label={formattedMessage}
                                                    maxlength="50"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.middleName"
                                            defaultMessage="[Middle Name]"
                                            children={(formattedMessage) => (
                                                <Field name="middleName" type="text" component={renderField} label={formattedMessage} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.lastName"
                                            defaultMessage="[Last Name]"
                                            children={(formattedMessage) => (
                                                <Field name="lastName" type="text" component={renderField} label={formattedMessage} />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                        <FormattedMessage id="app.gender" defaultMessage="[app.gender]" tagName="span" />
                                        <br/>
                                        <Field
                                            row
                                            name="gender"
                                            
                                            format={(value) => {
                                                return value ? value.toString() : "0";
                                            }}

                                                    parse={(value) => {
                                                return parseInt(value, 0);
                                            }}
                                            component={FinalFormRadioGroup}
                                            choices={this.genderOptions}
                                            required

                                                /></Grid>
                                            <Grid item xs={12} sm={6}>
                                        {values.gender === Gender.Other && (
                                              <Field
                                                component={renderField}
                                                name="genderDescription"
                                                label="Description"
                                                placeholder="Description"
                                                margin="dense"
                                                
                                            />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                   
                                    


                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">Address</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.address1"
                                            defaultMessage="[Address Line 1]"
                                            children={(formattedMessage) => (
                                                <Field
                                                    name="address1"
                                                    type="text"
                                                    component={renderField}
                                                    label={formattedMessage}

                                                    maxlength="100"

                                                />
                                            )}
                                        />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.address2"
                                            defaultMessage="[Address Line 2]"
                                            children={(formattedMessage) => (
                                                <Field name="address2" type="text" component={renderField} label={formattedMessage} maxlength="100" />
                                            )}
                                        />
                                    </Grid><Grid item xs={12} sm={4}>
                                        {(values.state != 'RI' || values.cityId == null) && (
                                            <FormattedMessage
                                                id="app.city"
                                                defaultMessage="[City]"
                                                children={(formattedMessage) => (
                                                    <Field name="city" component={renderField} label={formattedMessage} />
                                                )}
                                            />

                                        )}
                                        {(values.state == 'RI' && values.cityId != null) && (
                                            <React.Fragment>
                                                <FormattedMessage
                                                    id="app.city"
                                                    defaultMessage="[City]"
                                                    children={(formattedMessage) => (
                                                        <span className={"form-label"}>{formattedMessage}</span>
                                                    )}
                                                />
                                                <Field
                                                    name="cityId"
                                                    component="select"
                                                    label="City"
                                                    fullWidth

                                                >
                                                    {this.state.cities.map((option) => {
                                                        return (
                                                            <option key={option.cityCode} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        );

                                                    })}
                                                </Field>
                                                <Field name="city" component="input" type="hidden" />

                                            </React.Fragment>

                                        )}
                                    </Grid><Grid item xs={12} sm={4}>
                                        <span className={"form-label"}>State</span>
                                        <Field name="state" label="State" component="select" fullWidth >

                                            {this.state.states.map((option) => {
                                                return (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                );

                                            })}

                                        </Field>
                                    </Grid><Grid item xs={12} sm={4}>



                                        <Field name="zipCode" component={renderField} label="Zip Code" />
                                        

                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            name="phoneNumber"
                                            component={FinalFormTextField}
                                            label="Mobile Phone"
                                            placeholder="(xxx) xxx-xxxx"
                                            normalize={normalizePhone}
                                            required
                                            fullWidth
                                            disabled={false}
                                        />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="email" component={FinalFormTextField} label="Email" required fullWidth
                                             />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">School</span>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                         <Field
                                            component={FinalFormDropdown}
                                            name="schoolId"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            label="School"
                                            choices={schoolOptions}
                                            placeholder="Select a School..."
                                            required
                                            validate={required}
                                        /> 


                                    </Grid>
                                    <Grid item xs={12} sm={4}>

                                        <Field name="currentGrade" component={renderField} label="Grade" fullWidth />

                                    </Grid>


                                    

                                    <Grid item xs={12} sm={4}>
                                        <Field name="studentId" component={renderField} label="Student ID" fullWidth />
                                    </Grid>
                                   
                                   
                                    
                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">Medical</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field name="allergies" component={renderField} label="Allergies" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="medications" component={renderField} label="Medications" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="medicalConditions" component={renderField} label="Medical Conditions" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="dietaryRestrictions" component={renderField} label="Dietary Restrictions" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="specialAccommodations" component={renderField} label="Special Accommodations" />
                                    </Grid>
                                   
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="profile.languagePreference"
                                            defaultMessage="[All communication will be sent in the following language]"
                                            children={(formattedMessage) => (
                                                <div>
                                                    <span class="form-label" title="Language Preference">{formattedMessage}</span>
                                                    <Field
                                                        name="primaryLocale"
                                                        component="select"
                                                        label={formattedMessage}
                                                        data={languages}
                                                        className="medium-select"


                                                    >
                                                        <FormattedMessage
                                                            id="login.english"
                                                            defaultMessage="English"
                                                            children={(formattedMessage) => <option value="en">{formattedMessage}</option>}
                                                        />
                                                        <FormattedMessage
                                                            id="login.spanish"
                                                            defaultMessage="Spanish"
                                                            children={(formattedMessage) => <option value="es-MX">{formattedMessage}</option>}
                                                        />
                                                    </Field>
                                                </div>
                                            )}
                                        />
                                    </Grid>
                                    <br />
                                    <div className="admin-user-edit-buttons">

                                        <FormattedMessage
                                            id="Save"
                                            defaultMessage="[Save]"
                                            children={(formattedMessage) => (
                                                <Button
                                                    className="margin-right"
                                                    onClick={handleSubmit}
                                                    id="Save"
                                                    defaultMessage={formattedMessage}

                                                />
                                            )}
                                        />



                                        <Link id={'Cancel'} onClick={(e) => this.props.onClose()}  >
                                            Cancel
                                        </Link>



                                    </div>

                                </Grid>

                            </div>
                        </form>
                    );
                }}
                </Form>
            </ThemeProvider>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        loadCities: bindActionCreators(loadCities, dispatch),

    };
};

export default StudentProfile;