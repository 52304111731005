import React from "react";
import Modal from "react-modal";
import moment from "moment";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required, renderDateField, renderField } from "../../../../support/forms";

import "./editModal.css";

const customCheckbox = ({ input }) => (
    <label className="chk-container">
        <input type="checkbox" {...input} />
        <span className="checkmark"></span>
    </label>
);

const isDate = (value) => {
    if (!value) {
        return undefined;
    }

    const dt = moment(value);

    return dt.isValid() ? undefined : (
        <span style={{ color: "red" }} title="Invalid date">
            Invalid date
        </span>
    );
};

const normalizeYear = (value, previousValue) => {
    if (!value) {
        return value;
    }

    return value.replace(/[^\d]/g, "");
};

const isYear = (value) => {
    return value && (value < 1900 || value > 3000) ? "Enter a valid year" : undefined;
};

let EditCycleModal = ({
    itemId,
    allItems,
    isOpen,
    handleSubmit,
    onRequestClose,
    onSave,
    grade5Enabled,
    grade6Enabled,
    grade7Enabled,
    grade8Enabled,
    startDate,
    endDate,
}) => {
    const getOtherCycles = () => allItems.filter((i) => i.id !== itemId);

    const overlaps = (startDate, endDate, currentDate) => {
        return currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(endDate);
    };

    const isValidStartDate = (current) => {
        if (getOtherCycles().some((c) => overlaps(c.startDate, c.endDate, current))) {
            return false;
        }

        if (!endDate) {
            return true;
        }

        return current.isBefore(moment(endDate));
    };

    const isValidEndDate = (current) => {
        if (getOtherCycles().some((c) => overlaps(c.startDate, c.endDate, current))) {
            return false;
        }

        if (!startDate) {
            return true;
        }

        return current.isAfter(moment(startDate));
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal edit-cycle-modal">
            <div>
                <h2>{itemId ? "Edit" : "Add"} Recruitment Cycle</h2>
                {itemId && (
                    <div className="edit-cycle-modal__warning">Any student applications already submitted during this cycle will not be affected by edits.</div>
                )}
                <div style={{ display: "flex" }}>
                    <Field
                        name="startDate"
                        isValidDate={isValidStartDate}
                        component={renderDateField}
                        label="Start Date"
                        validate={[required, isDate]}
                        className="edit-cycle-modal__date"
                    />
                    <Field
                        name="endDate"
                        isValidDate={isValidEndDate}
                        component={renderDateField}
                        label="End Date"
                        viewDate={endDate || startDate}
                        validate={[required, isDate]}
                        className="edit-cycle-modal__date"
                    />
                    <Field
                        name="uiYear"
                        normalize={normalizeYear}
                        component={renderField}
                        label="Grade Selection Label Year"
                        validate={[required, isYear]}
                        maxLength={4}
                    />
                </div>
                <table className="edit-cycle-modal__table">
                    <thead>
                        <tr>
                            <th>Grade</th>
                            <th>Is Eligible?</th>
                            <th>Cohort</th>
                            <th>Onward Id (First 4)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>5</td>
                            <td>
                                <Field name="grade5.isEligible" component={customCheckbox} type="checkbox" />
                            </td>
                            <td>
                                <Field
                                    name="grade5.cohort"
                                    component={renderField}
                                    type="number"
                                    disabled={!grade5Enabled}
                                    validate={grade5Enabled ? [required] : undefined}
                                />
                            </td>
                            <td>
                                <Field
                                    name="grade5.crusaderPrefix"
                                    component={renderField}
                                    validate={grade5Enabled ? [required] : undefined}
                                    min={2000}
                                    max={3000}
                                    type="number"
                                    disabled={!grade5Enabled}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>
                                <Field name="grade6.isEligible" component={customCheckbox} type="checkbox" />
                            </td>
                            <td>
                                <Field
                                    name="grade6.cohort"
                                    component={renderField}
                                    type="number"
                                    disabled={!grade6Enabled}
                                    validate={grade6Enabled ? [required] : undefined}
                                />
                            </td>
                            <td>
                                <Field
                                    name="grade6.crusaderPrefix"
                                    component={renderField}
                                    type="number"
                                    min={2000}
                                    max={3000}
                                    disabled={!grade6Enabled}
                                    validate={grade6Enabled ? [required] : undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>
                                <Field name="grade7.isEligible" component={customCheckbox} type="checkbox" />
                            </td>
                            <td>
                                <Field
                                    name="grade7.cohort"
                                    component={renderField}
                                    type="number"
                                    disabled={!grade7Enabled}
                                    validate={grade7Enabled ? [required] : undefined}
                                />
                            </td>
                            <td>
                                <Field
                                    name="grade7.crusaderPrefix"
                                    component={renderField}
                                    type="number"
                                    min={2000}
                                    max={3000}
                                    disabled={!grade7Enabled}
                                    validate={grade7Enabled ? [required] : undefined}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>
                                <Field name="grade8.isEligible" component={customCheckbox} type="checkbox" />
                            </td>
                            <td>
                                <Field
                                    name="grade8.cohort"
                                    component={renderField}
                                    type="number"
                                    disabled={!grade8Enabled}
                                    validate={grade8Enabled ? [required] : undefined}
                                />
                            </td>
                            <td>
                                <Field
                                    name="grade8.crusaderPrefix"
                                    component={renderField}
                                    type="number"
                                    min={2000}
                                    max={3000}
                                    disabled={!grade8Enabled}
                                    validate={grade8Enabled ? [required] : undefined}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="button-container">
                    <button className="btn" onClick={handleSubmit(onSave)}>
                        Ok
                    </button>
                    <button className="link-button" onClick={onRequestClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const selector = formValueSelector("RecruitmentCycle");

const mapStateToProps = (state) => ({
    itemId: selector(state, "id"),
    startDate: selector(state, "startDate"),
    endDate: selector(state, "endDate"),
    grade5Enabled: selector(state, "grade5.isEligible"),
    grade6Enabled: selector(state, "grade6.isEligible"),
    grade7Enabled: selector(state, "grade7.isEligible"),
    grade8Enabled: selector(state, "grade8.isEligible"),
});

EditCycleModal = connect(mapStateToProps)(EditCycleModal);
EditCycleModal = reduxForm({ form: "RecruitmentCycle" })(EditCycleModal);

export default EditCycleModal;
