/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";

import { userName, email, phone, password, passwordMatch, required, renderField } from "../../support/forms/index";
import Button from "../../support/components/Button";
import ContactInfo from "../../admissions/common/contactInfo";
import RecruitmentClosed from "./recruitmentClosed";

import "./NewAccount.css";
import "../index.css";
import normalizePhone from "../../support/logic/normalizePhone";

let NewAccount = ({ recruitmentOpen, handleSubmit, onBack, onLocaleChange, onCreateAccount, onShowTerms, locale, isAgree }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);
    const intl = useIntl();

    return (
        <div className="login-ui" style={{ paddingTop: "30px" }}>
            <FormattedMessage
                id="login.title"
                defaultMessage="Welcome to Onward We Learn"
                children={(formattedMessage) => <h1 className="screen-title">{formattedMessage}</h1>}
            />
            {!recruitmentOpen && <RecruitmentClosed />}
            <div className="login-new-account">
                <div className="login-new-account-right">
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && (
                        <div className={`instructions login-new-account-instructions ${isDesktop ? "" : "mobile"}`}>
                            <FormattedMessage id="app.instruction" defaultMessage="Instructions" children={(formattedMessage) => <h5>{formattedMessage}</h5>} />
                            <FormattedMessage
                                id="login.newaccount.instruction1"
                                children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>}
                            />
                            <FormattedMessage
                                id="login.newaccount.instruction2"
                                children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>}
                            />
                            <ContactInfo />
                        </div>
                    )}
                    <FormattedMessage
                        id="login.language"
                        defaultMessage="Select a preferred language"
                        children={(formattedMessage) => <span className="login-label">{formattedMessage}</span>}
                    />
                    <select onChange={onLocaleChange} value={locale}>
                        <FormattedMessage
                            id="login.english"
                            defaultMessage="English"
                            children={(formattedMessage) => <option value="en">{formattedMessage}</option>}
                        />
                        <FormattedMessage
                            id="login.spanish"
                            defaultMessage="Spanish"
                            children={(formattedMessage) => <option value="es-MX">{formattedMessage}</option>}
                        />
                    </select>
                    <FormattedMessage
                        id="login.newSubTitle"
                        defaultMessage="Create your login information"
                        children={(formattedMessage) => <h2 className="screen-title">{formattedMessage}</h2>}
                    />
                    <div className="login-username-mobile-phone">
                        <FormattedMessage
                            id="login.username"
                            defaultMessage="User Name"
                            children={(formattedMessage) => (
                                <Field
                                    name="userName"
                                    type="text"
                                    className="login-user-name-create"
                                    maxLength="20"
                                    component={renderField}
                                    label={formattedMessage}
                                    validate={[required, userName]}
                                />
                            )}
                        />
                        <FormattedMessage
                            id="login.mobilePhone"
                            defaultMessage="Mobile Phone"
                            children={(formattedMessage) => (
                                <Field
                                    name="mobilePhone"
                                    className="login-user-name-mobile-phone"
                                    type="text"
                                    placeholder="(xxx) xxx-xxxx"
                                    component={renderField}
                                    label={formattedMessage}
                                    normalize={normalizePhone}
                                    validate={[required, phone]}
                                />
                            )}
                        />
                    </div>
                    <FormattedMessage
                        id="login.userNameRequirements"
                        defaultMessage="User Name requirements"
                        children={(formattedMessage) => <span className="login-user-name-requirements">{formattedMessage}</span>}
                    />
                    <FormattedMessage
                        id="login.email"
                        defaultMessage="Email"
                        children={(formattedMessage) => <Field name="email" type="text" component={renderField} label={formattedMessage} validate={[email, required]} />}
                    />
                    <FormattedMessage
                        id="login.password"
                        defaultMessage="Password"
                        children={(formattedMessage) => (
                            <Field name="password" type="password" component={renderField} label={formattedMessage} validate={[required, password]} />
                        )}
                    />
                    <FormattedMessage
                        id="login.passwordRequire"
                        defaultMessage="Password Requirements"
                        children={(formattedMessage) => <span className="login-password-requirements">{formattedMessage}</span>}
                    />
                    <FormattedMessage
                        id="login.confirmPassword"
                        defaultMessage="Confirm"
                        children={(formattedMessage) => (
                            <Field name="confirm" type="password" component={renderField} label={formattedMessage} validate={[required, password]} />
                        )}
                    />
                    <div className="terms-wrapper">
                        <FormattedMessage
                            id="login.agree"
                            defaultMessage="I have read and agree to the TERMS of AGREEMENT"
                            children={(formattedMessage) => (
                                <label htmlFor="login_isAgree_id" className="terms">
                                    <input type="checkbox" id="login_isAgree_id" checked={isAgree} disabled />
                                    {formattedMessage}
                                    <FormattedMessage
                                        id="login.term"
                                        defaultMessage="Terms"
                                        children={(formattedMessage) => (
                                            <button className="link-button forgot-pwd" onClick={onShowTerms}>
                                                {formattedMessage}
                                            </button>
                                        )}
                                    />
                                </label>
                            )}
                        />
                    </div>
                    <div className="login-new-account-buttons">
                        <Button
                            id="login.createMy"
                            className="login-new-account-button"
                            defaultMessage="CREATE MY ACCOUNT"
                            onClick={handleSubmit(onCreateAccount)}
                            invalid={!isAgree || !recruitmentOpen}
                        />
                        <a href="" onClick={onBack}>
                            <FormattedMessage
                                id="app.cancel"
                                defaultMessage="Instructions"
                                children={(formattedMessage) => <span className="link-button">{formattedMessage}</span>}
                            />
                        </a>
                    </div>
                </div>
                <Hidden only="xs">
                    <div className={`instructions login-new-account-instructions ${isDesktop ? "" : "mobile"}`}>
                        <FormattedMessage id="app.instruction" defaultMessage="Instructions" children={(formattedMessage) => <h5>{formattedMessage}</h5>} />
                        <FormattedMessage
                            id="login.newaccount.instruction1"
                            children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>}
                        />
                        <FormattedMessage
                            id="login.newaccount.instruction2"
                            children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>}
                        />
                        <ContactInfo />
                    </div>
                </Hidden>
            </div>
        </div>
    );
};

NewAccount.propTypes = {
    onBack: PropTypes.func.isRequired,
    onLocaleChange: PropTypes.func.isRequired,
    onCreateAccount: PropTypes.func.isRequired,
    onShowTerms: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    isAgree: PropTypes.bool.isRequired,
};

NewAccount = reduxForm({
    form: "newaccount",
    fields: ["password", "confirm"],
    validate: passwordMatch,
    destroyOnUnmount: false,
})(NewAccount);

NewAccount = injectIntl(NewAccount);

export default NewAccount;
