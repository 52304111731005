import React from "react";
import { EventLocationType } from "../../enums";
import enumHelper from "../../../support/logic/enumHelper";
import EventHeader from "../../common/eventHeader/eventHeader";
import EventDateTooltip from "../../common/eventDateTooltip/eventDateTooltip";
import { useStyles } from "./eventMetaStyles";

const EventMeta = ({ programEvent: { locationType, name, eventDates } }) => {
    const classes = useStyles();

    const location = locationType === EventLocationType.Both ? "Virtual & In Person" : enumHelper.getDisplayName(EventLocationType, locationType);

    return (
        <div className={classes.eventMeta}>
            <EventHeader mainTitle={name} subTitle={location} />
            <EventDateTooltip eventDates={eventDates} maxItems={3} showBadge />
        </div>
    );
};

export default EventMeta;
