import { toast } from "react-toastify";
import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Prompt, useHistory, useParams } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import createDecorator from "final-form-focus";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import BackButton from "../../common/backButton";
import LoadingButton from "../../common/loadingButton";
import { FinalFormCheckbox, FinalFormRadioGroup, FinalFormTextField, FinalFormAutocomplete } from "../../common/formWrappers";
import EligibilityCriteriaSection from "./components/eligibilityCriteriaSection";
import { GradeCalculationType } from "../enums";
import enumHelper from "../../support/logic/enumHelper";
import { composeValidators, maxValue, minValue, mustBeNumber } from "../../common/formValidators";
import { useProgramEvent, useInstitution, useSchool, useDistrict } from "../hooks";
import { saveEligibilityCriteria } from "../api/programEvents";
import LoadingIndicator from "../../common/loadingIndicator";
import { Box, IconButton } from "@material-ui/core";
import useAjaxStatus from "../../common/useAjaxStatus";

const focusOnError = createDecorator();

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    formHeader: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"name saveButton" "title saveButton" "subtitle saveButton"`,
    },
    backButton: {
        gridArea: "backButton",
    },
    name: {
        gridArea: "name",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    saveButtonContainer: {
        gridArea: "saveButton",
        justifySelf: "end",
        alignSelf: "start",
    },
    saveButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    title: {
        gridArea: "title",
        fontWeight: "bold",
    },
    subtitle: {
        gridArea: "subtitle",
        borderBottomColor: theme.palette.secondary.main,
    },
    formBody: {
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(5),
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        gap: theme.spacing(5),
    },
    gradesContainer: {
        display: "flex",
        flexDirection: "column",
        flexFlow: "wrap",
        maxHeight: theme.spacing(30),
    },
    rangeInputContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    rangeInputDivider: {
        alignSelf: "center",
    },
    addIcon: {
        alignSelf: "flex-start",
        color: "#fff",
        fontWeight: "bold",
        backgroundColor: "#123225",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    gradeCalculationType: {
        margin: theme.spacing(0.5, 0, 0, 1),
        alignSelf: "center",
    },
    gradeCalculationInput: {
        width: 70,
        maxWidth: 70,
    },
    cohortInput: {
        marginBottom: 0,
    },
}));

const gradeNumbers = (() => {
    const gradeNumbers = [];
    for (let i = 5; i <= 21; i++) {
        gradeNumbers.push(i);
    }
    return gradeNumbers;
})();

const criteriaDefaultValues = {
    accelerate: false,
    gap: false,
    honorific: false,
    crusaderStudents: true,
    crusaderParents: false,
    guestStudents: false,
    guestParents: false,
    gold: false,
    silver: false,
    bronze: false,
    pewter: false,
    gradeNumbers: gradeNumbers.map((grade) => ({ value: grade, selected: false })),
    graduated: false,
    currentStudents: false,
    mathMin: 0,
    mathMax: 100,
    scienceMin: 0,
    scienceMax: 100,
    englishMin: 0,
    englishMax: 100,
    mathGradeType: GradeCalculationType.Average,
    scienceGradeType: GradeCalculationType.Average,
    englishGradeType: GradeCalculationType.Average,
    cohortNumbers: [""],
    institutions: [{ name: "" }],
    schools: [{ name: "" }],
    districts: [{ name: "" }],
};

const EligibilityCriteriaUi = ({ 
    isLoading,
    criteria,
    name,
    handleSave,
    institutionNameById,
    schoolNameById,
    districtNameById,
    institutionList,
    schoolList,
    districtList,
    isDialog,
    handleClear, 
    disabled
 }) => {
    const classes = useStyles();

    if (isLoading) {
        return <LoadingIndicator loading />;
    }

    return (
        <Form
            onSubmit={handleSave}
            mutators={{ ...arrayMutators }}
            decorators={[focusOnError]}
            initialValues={
                criteria
                    ? {
                          ...criteria,
                          gradeNumbers: gradeNumbers.map((grade) => ({
                              value: grade,
                              selected: !!criteria.gradeNumbers.find((gradeNumberObj) => gradeNumberObj.gradeNumber === grade),
                          })),
                          institutions: criteria.institutions?.map((institutionObj) => ({
                              name: institutionNameById[institutionObj.institutionId],
                              id: institutionObj.institutionId,
                          })),
                          schools: criteria.schools?.map((schoolObj) => ({
                              name: schoolNameById[schoolObj.schoolId],
                              id: schoolObj.schoolId,
                          })),
                          districts: criteria.districts?.map((districtObj) => ({
                              name: districtNameById[districtObj.districtId],
                              id: districtObj.districtId,
                          })),
                          cohortNumbers: criteria.cohortNumbers?.map((cohortNumberObj) => cohortNumberObj.cohortNumber),
                      }
                    : criteriaDefaultValues
            }
        >
            {({ handleSubmit, form, values, dirty, errors }) => {
                const filteredSchoolList = schoolList.filter((school) => !values.schools.find((selected) => selected?.id === school.id));
                const filteredDistrictList = districtList.filter((district) => !values.district?.find((selected) => selected?.id === district.id));
                const filteredInstitutionList = institutionList.filter(
                    (institution) => !values.institutions.find((selected) => selected?.id === institution.id)
                );
                return (
                    <form onSubmit={handleSubmit} noValidate className={classes.root}>
                        <Prompt
                            when={dirty}
                            message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                        />
                        <div className={classes.formHeader}>
                            {!isDialog ? (
                                <div className={classes.name}>
                                    <BackButton />

                                    <Typography variant="h5" color="secondary">
                                        {name}
                                    </Typography>
                                </div>
                            ) : (
                                <Button
                                    wrapperClassName={classes.saveButtonContainer}
                                    className={classes.saveButton}
                                    variant="contained"
                                        color="secondary"
                                        disabled={disabled }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClear(criteriaDefaultValues)
                                    }}
                                >
                                    Clear
                                </Button>
                            )}

                            <LoadingButton
                                wrapperClassName={classes.saveButtonContainer}
                                className={classes.saveButton}
                                variant="contained"
                                color="primary"
                                disabled={disabled}
                                onClick={handleSubmit}
                            >
                                Save
                            </LoadingButton>
                            <Typography className={classes.title} variant="h6">
                                Eligibility Criteria
                            </Typography>
                            <Typography className={classes.subtitle} variant="h6"></Typography>
                        </div>
                        <div className={classes.formBody}>
                            <EligibilityCriteriaSection>
                                <Field component={FinalFormCheckbox} name="accelerate" label="Accelerate" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="gap" label="Gap" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="honorific" label="Honorific" disabled={disabled} />
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="Participants">
                                <Field component={FinalFormCheckbox} name="crusaderStudents" label="Students" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="crusaderParents" label="Parents" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="guestStudents" label="Guest Students" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="guestParents" label="Guest Parents" disabled={disabled} />
                            </EligibilityCriteriaSection>
                           
                            <EligibilityCriteriaSection title="Grades">
                                <FieldArray name="gradeNumbers">
                                    {({ fields }) => (
                                        <div className={classes.gradesContainer}>
                                            {fields.map((field, index) => (
                                                <Field
                                                    key={field}
                                                    component={FinalFormCheckbox}
                                                    name={`${field}.selected`}
                                                    disabled={disabled}
                                                    label={values.gradeNumbers[index].value}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </FieldArray>
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="Status">
                                <Field component={FinalFormCheckbox} name="graduated" label="Graduated" margin="dense" disabled={disabled} />
                                <Field component={FinalFormCheckbox} name="currentStudents" label="Current Students" margin="dense" disabled={disabled} />
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="Grade Requirement">
                                <div className={classes.rangeInputContainer}>
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="mathMin"
                                        label="Math"
                                        placeholder="Min"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <RemoveIcon className={classes.rangeInputDivider} />
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="mathMax"
                                        placeholder="Max"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <Field
                                        component={FinalFormRadioGroup}
                                        formControlClassName={classes.gradeCalculationType}
                                        name="mathGradeType"
                                        disabled={disabled}
                                        choices={[
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Average), value: true },
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Latest), value: false },
                                        ]}
                                        parse={(value) => (value === "true" ? GradeCalculationType.Average : GradeCalculationType.Latest)}
                                        format={(value) => value === GradeCalculationType.Average || false}
                                    />
                                </div>
                                <div className={classes.rangeInputContainer}>
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="scienceMin"
                                        label="Science"
                                        placeholder="Min"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <RemoveIcon className={classes.rangeInputDivider} />
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="scienceMax"
                                        placeholder="Max"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <Field
                                        component={FinalFormRadioGroup}
                                        formControlClassName={classes.gradeCalculationType}
                                        name="scienceGradeType"
                                        choices={[
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Average), value: true },
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Latest), value: false },
                                        ]}
                                        disabled={disabled}
                                        parse={(value) => (value === "true" ? GradeCalculationType.Average : GradeCalculationType.Latest)}
                                        format={(value) => value === GradeCalculationType.Average || false}
                                    />
                                </div>
                                <div className={classes.rangeInputContainer}>
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="englishMin"
                                        label="English"
                                        placeholder="Min"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <RemoveIcon className={classes.rangeInputDivider} />
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.gradeCalculationInput}
                                        name="englishMax"
                                        placeholder="Max"
                                        margin="dense"
                                        disabled={disabled}
                                        validate={composeValidators(mustBeNumber, minValue(0), maxValue(100))}
                                    />
                                    <Field
                                        component={FinalFormRadioGroup}
                                        name="englishGradeType"
                                        formControlClassName={classes.gradeCalculationType}
                                        choices={[
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Average), value: true },
                                            { label: enumHelper.getDisplayName(GradeCalculationType, GradeCalculationType.Latest), value: false },
                                        ]}
                                        disabled={disabled}
                                        parse={(value) => (value === "true" ? GradeCalculationType.Average : GradeCalculationType.Latest)}
                                        format={(value) => value === GradeCalculationType.Average || false}
                                    />
                                </div>
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="Institution">
                                <FieldArray name="institutions">
                                    {({ fields }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Box key={field} display="flex" alignItems="center">
                                                    <Field
                                                        component={FinalFormAutocomplete}
                                                        key={field}
                                                        name={field}
                                                        disabled={disabled}
                                                        options={filteredInstitutionList}
                                                        getOptionLabel={(option) => option?.name || ""}
                                                        placeholder="Institution Name"
                                                    />
                                                    <IconButton
                                                        aria-label="remove"
                                                        disabled={disabled}
                                                        className={classes.deleteBtn}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            form.mutators.remove("institutions", index);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            {filteredInstitutionList.length && fields.length < institutionList.length ? (
                                                <Button
                                                    className={classes.addIcon}
                                                    startIcon={<AddIcon />}
                                                    disabled={disabled}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.mutators.push("institutions", { name: "" });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </FieldArray>
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="School">
                                <FieldArray name="schools">
                                    {({ fields }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Box key={field} display="flex" alignItems="center">
                                                    <Field
                                                        component={FinalFormAutocomplete}
                                                        key={field}
                                                        name={field}
                                                        disabled={disabled}
                                                        options={filteredSchoolList}
                                                        getOptionLabel={(option) => option?.name || ""}
                                                        placeholder="School Name"
                                                    />
                                                    <IconButton
                                                        aria-label="remove"
                                                        className={classes.deleteBtn}
                                                        disabled={disabled}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            form.mutators.remove("schools", index);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            {filteredSchoolList.length && fields.length < schoolList.length ? (
                                                <Button
                                                    className={classes.addIcon}
                                                    startIcon={<AddIcon />}
                                                    disabled={disabled}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.mutators.push("schools", { name: "" });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </FieldArray>
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="District">
                                <FieldArray name="districts">
                                    {({ fields }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Box key={field} display="flex" alignItems="center">
                                                    <Field
                                                        component={FinalFormAutocomplete}
                                                        key={field}
                                                        name={field}
                                                        disabled={disabled}
                                                        options={filteredDistrictList}
                                                        getOptionLabel={(option) => option?.name || ""}
                                                        placeholder="District Name"
                                                    />
                                                    <IconButton
                                                        aria-label="remove"
                                                        disabled={disabled}
                                                        className={classes.deleteBtn}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            form.mutators.remove("districts", index);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            {filteredDistrictList.length && fields.length < districtList.length ? (
                                                <Button
                                                    className={classes.addIcon}
                                                    disabled={disabled}
                                                    startIcon={<AddIcon />}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.mutators.push("districts", { name: "" });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </FieldArray>
                            </EligibilityCriteriaSection>
                            <EligibilityCriteriaSection title="Cohort Number">
                                <FieldArray name="cohortNumbers">
                                    {({ fields }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Box key={field} display="flex" alignItems="center">
                                                    <Field
                                                        key={field}
                                                        disabled={disabled}
                                                        component={FinalFormTextField}
                                                        className={classes.cohortInput}
                                                        name={field}
                                                        placeholder="######"
                                                        margin="dense"
                                                    />
                                                    <IconButton
                                                        aria-label="remove"
                                                        disabled={disabled}
                                                        className={classes.deleteBtn}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            form.mutators.remove("cohortNumbers", index);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ))}
                                            <Button
                                                className={classes.addIcon}
                                                disabled={disabled}
                                                startIcon={<AddIcon />}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    form.mutators.push("cohortNumbers", "");
                                                }}
                                            >
                                                Add
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </EligibilityCriteriaSection>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default EligibilityCriteriaUi;
