import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import moment from "moment";
import FontAwesome from "react-fontawesome";
import "./Student.css";

const Student = ({ applicationId, student, history, match, dupes }) => {
    const dob = student.dateOfBirth ? moment.utc(student.dateOfBirth).format("MM/DD/YYYY") : "Not Set";
    const eyeLink = `/applications/application/student/${applicationId}/s/${student.userProfileId}`;
    const link = `/applications/application/${applicationId}/s/${student.userProfileId}`;
    const name = student.userProfileName ? student.userProfileName : "Unnamed Student";
    const className = match.params.studentId === student.userProfileId ? "application-student selected" : "application-student";
    const school = student.school ? student.school : "School Not Set";

    return (
        <div className={className} role="button" tabIndex={0} onKeyPress={() => history.push(link)} onClick={() => history.push(link)}>
            <div className="application-student-inner">
                <span>{name}</span>
                <span>
                    DOB:&nbsp;&nbsp;
                    {dob} &nbsp;&nbsp;&nbsp;&nbsp; {student.gender}
                </span>
                <span>{school}</span>
            </div>
            <FontAwesome
                name="eye"
                size="2x"
                color="white"
                onClick={(e) => {
                    e.stopPropagation();
                    history.push(eyeLink);
                }}
            />
        </div>
    );
};

Student.propTypes = {
    applicationId: PropTypes.string.isRequired,
    student: PropTypes.shape({
        userProfileId: PropTypes.string.isRequired,
        userProfileName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        school: PropTypes.string.isRequired,
    }),
};

export default withRouter(Student);
