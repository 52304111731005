import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import * as propTypes from "../propTypes";

import withUser from "../../support/hoc/withUser";
import NavigationHeader from "./components/NavigationHeader";
import ReceiveRefund from "./receiveRefund";
import RequestPayment from "./requestPayment";
import SendPayment from "./sendPayment";
import SubmitRefund from "./submitRefund";

import "./index.css";

class Financials extends Component {
  static permission = "Scholarship.Financials";
  onNavigate = route => {};
  render() {
      const isStaff = this.props.user && (this.props.user.userProfile.userType === 6 || this.props.user.userProfile.userType === 7 || this.props.user.userProfile.userType === 99);

    return (
      <div className="scholarship-financials">
        <NavigationHeader onNavigate={this.onNavigate} />
            {!isStaff && <Route path="/scholarship/financials/request-payment" render={() => <RequestPayment scholarship={this.props.scholarship} readonly={this.props.readonly} />} />}
            {!isStaff && <Route path="/scholarship/financials/submit-refund" render={() => <SubmitRefund scholarship={this.props.scholarship} readonly={this.props.readonly} />} />}
            {isStaff && <Route path="/scholarship/financials/request-payment" render={() => <Redirect to="/scholarship/financials/send-payment" readonly={this.props.readonly} />} />}
            {isStaff && <Route path="/scholarship/financials/send-payment" render={() => <SendPayment scholarship={this.props.scholarship} readonly={this.props.readonly} />} />}
            {isStaff && <Route path="/scholarship/financials/receive-refund" render={() => <ReceiveRefund scholarship={this.props.scholarship} readonly={this.props.readonly} />} />}
      </div>
    );
  }
}

Financials.propTypes = {
  scholarship: propTypes.scholarship
};

Financials = withUser(Financials);
Financials = withRouter(Financials);

export default Financials;
