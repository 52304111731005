import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getPermissionSlipsZip } from "../../api/programEvents";
import DateComponent from "../dateComponent/dateComponent";
import { useStyles } from "./styles";

import LoadingButton from "../../../common/loadingButton";
import useAjaxStatus from "../../../common/useAjaxStatus";
import FileSaver from "file-saver";
import b64toBlob from "../../../support/logic/b64toBlob";

const EventDateNonSeriesTable = ({ rows, handleOnClickDate, waitlist }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(-1);
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handlePermissionSlipClick = async (id) => {
        try {
            setLoading(rows.findIndex((r) => r.id === id));
            var response = await getPermissionSlipsZip(id);
            const blob = b64toBlob(response.data.result.data, response.data.result.mimeType);
            FileSaver.saveAs(blob, response.data.result.fileName);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setLoading(-1);
        }
    };

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.bold}>
                    <TableCell style={{ width: "20%" }}>Date</TableCell>
                    <TableCell>Location Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Participants/Capacity</TableCell>
                    {!waitlist && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows
                    .sort((firstEl, secondEl) => new Date(firstEl.date) - new Date(secondEl.date))
                    .map((row, idx) => {
                        const { startTime, endTime, status, participants, id, enrollmentTotal, locationType } = row;
                        //console.log(startTime);
                        //console.log(endTime);
                        return (
                            <TableRow key={idx}>
                                <TableCell className={classes.hoverDate} onClick={() => handleOnClickDate(row)}>
                                    <DateComponent date={{ startTime, endTime }} />
                                </TableCell>
                                <TableCell>{locationType}</TableCell>
                                <TableCell>{status}</TableCell>
                                <TableCell>{participants}</TableCell>
                                {!waitlist && (
                                    <TableCell>
                                        <LoadingButton
                                            wrapperClassName={classes.loadingButtonWrapper}
                                            variant="text"
                                            color="primary"
                                            onClick={() => handlePermissionSlipClick(id)}
                                            loading={loading === idx}
                                            disabled={enrollmentTotal === 0}
                                        >
                                            Permission Slip.zip
                                        </LoadingButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default EventDateNonSeriesTable;
