import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { SecurityContext, SubNavigation } from "../support/security";
import withUser from "../support/hoc/withUser";
import { UserType } from "../support/enums";

import YearPicker from "./YearPicker";
import Programs from "./programs";
import Certify from "./certify";
import Financials from "./financials";
import Reports from "./reports";

import "./index.css";
import ImportPage from "./import";

const Scholarship = ({ user, location, topUser }) => {
    const [scholarship, setScholarship] = useState(null);
    const { getSubSectionNames } = useContext(SecurityContext);
    //console.log(location);
    //console.log(user);
    const [readonly, setReadonly] = useState(false);
    const subSectionNames = getSubSectionNames("Scholarship");


    useEffect(() => {
        if (topUser != null && !readonly) {

            if (topUser.advProf != null && topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false) {
                setReadonly(true);
            }
        }
    }, [topUser]);
    

    if (location != null && location.pathname == "/scholarship" && subSectionNames.length > 0) {
        return <Redirect to={`/scholarship/${subSectionNames[0].toLowerCase()}`} />;
    }
    
    const isInstitutionUser = user && user.userProfile && user.userProfile.userType === UserType.Institution;

    return (
        <div className="scholarship-main">
            <div className="scholarship-year-container">
                <YearPicker onSelectChange={(s) => setScholarship(s)} />
            </div>
            <div className="scholarship-main-nav">
                <SubNavigation sectionName="Scholarship" />
            </div>
            <Redirect exact from="/scholarship" to="/scholarship/programs" />
            <Route path="/scholarship/programs" render={() => <Programs scholarship={scholarship} readonly={isInstitutionUser || readonly} />} />
            <Route path="/scholarship/certify-students/:id?" render={() => <Certify scholarship={scholarship} readonly={readonly} />} />
            <Route path="/scholarship/financials" render={() => <Financials scholarship={scholarship} readonly={readonly} />} />
            <Route exact path="/scholarship/financials" render={() => <Redirect to="/scholarship/financials/request-payment" />} />
            <Route path="/scholarship/reports" render={() => <Reports scholarship={scholarship}  />} />
            <Route path="/scholarship/import" render={() => <ImportPage readonly={readonly} />} />
        </div>
    );
};

export default withUser(Scholarship);
