import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import { toast } from "react-toastify";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { required, renderField, renderSelectList } from "../../../support/forms";
import Button from "../../../support/components/Button";

import * as apis from "./apis";
import "./Edit.css";
import { SchoolType } from "../../../support/enums";
import withHistory from "../../../support/hoc/withHistory";

const FORM_NAME = "school";
const TYPES = [
    { label: "Middle School", value: SchoolType.Middle },
    { label: "High School", value: SchoolType.High },
];

const Checkbox = (props) => {
    return <input {...props.input} type="checkbox" checked={props.input.value} />;
};

class Edit extends Component {
    static permission = "Admin.Schools";
    constructor(props) {
        super(props);
        this.state = {
            showComplete: false,
        };
    }
    componentDidMount = () => {
        const { match } = this.props;
        const { id } = match.params;
        this._isMounted = true;
        if (id === "0") {
            this.props.initialize({
                name: "",
                type: 0,
                code: "",
                isActive: true,
            });
            return;
        }

        this.props.ajaxStatusLoading();

        apis.getById(id)
            .then((response) => {
                const school = response.data.result;
                if (this._isMounted) {
                    this.props.initialize(school);
                }
                this.props.ajaxStatusComplete();
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    onSave = (values) => {
        const { match } = this.props;
        const { id } = match.params;
        values.id = id;
        // values.type = values.type.toString();
        // values.termStructure = values.termStructure.toString();
        let method = "create";

        if (id !== "0") {
            method = "update";
        } else {
            delete values.id;
        }

        this.props.ajaxStatusLoading();
        apis[method](values)
            .then((response) => {
                this.props.ajaxStatusComplete();
                this.props.onSaveComplete();
                this.setState({
                    showComplete: true,
                });
                toast.success("Save Complete");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.error) {
                    this.props.ajaxStatusError(error.response.data.error.message);
                    return;
                }
                this.props.ajaxStatusError(error.message);
            });
    };
    onCancel = (e) => {
        e.preventDefault();
        this.props.reset();
        this.props.history.goBack();
    };

    render() {
        if (this.state.showComplete) return <Redirect to="/admin/middle-high/" />;
        const { match } = this.props;
        const { id } = match.params;
        const label = id === "0" ? "Add" : "Edit";

        return (
            <div className="admin-school-edit">
                <h1>
                    {label}
                    &nbsp;School
                </h1>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Field
                        name="name"
                        component={renderField}
                        label="School Name"
                        validate={[required]}
                        maxlength="200"
                        className="admin-school-edit-input is-active-field"
                    />
                    <div className="admin-school-edit-input is-active-field">
                        <span className="form-label" title="Active">
                            Active?
                        </span>
                        <Field name="isActive" component={Checkbox} label="Active" defaultValue={true} />
                    </div>
                </div>

                <div className="admin-school-edit-row">
                    <Field
                        name="districtId"
                        component={renderSelectList}
                        data={this.props.districts.map((d) => ({ label: d.name, value: d.id }))}
                        label="District"
                    />
                    <span style={{ flex: 1 }}>&nbsp;</span>
                </div>

                <div className="admin-school-edit-row">
                    <Field name="type" component={renderSelectList} data={TYPES} label="Type" />
                    <Field name="code" component={renderField} label="Code " maxlength="10" validate={[required]} />
                </div>

                <div className="admin-school-edit-buttons">
                    <Button onClick={this.props.handleSubmit(this.onSave)} id="Save" defaultMessage="Save" />

                    <a href="/admin/middle-high/" onClick={(e) => this.onCancel(e)}>
                        Cancel
                    </a>
                </div>
            </div>
        );
    }
}

Edit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    onSaveComplete: PropTypes.func.isRequired,
};

Edit = reduxForm({
    form: FORM_NAME,
})(Edit);

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset(FORM_NAME)),
    };
};

Edit = connect(null, mapDispatchToProps)(Edit);

Edit = withRouter(Edit);
Edit = withAjaxStatus(Edit);
Edit = withHistory(Edit);

export default Edit;
