import axios from "axios";

export function getSendPaymentInstitutions(scholarshipId) {
  return axios.get("/api/services/app/Scholarship/GetSendPaymentInstitutions", {
    params: { scholarshipId }
  });
}

export function sendPayment(requestPaymentId, date, checkNum, notes) {
  return axios.post("/api/services/app/Scholarship/SendPayment", {
    requestPaymentId,
    date,
    checkNum,
    notes
  });
}

export function getSendPaymentStudents(requestPaymentId) {
  return axios.get("/api/services/app/Scholarship/GetSendPaymentStudents", {
    params: { id: requestPaymentId }
  });
}

export function getPaymentRequests(scholarshipId) {
  return axios.get(
    "/api/services/app/ScholarshipTransaction/GetPaymentRequests",
    {
      params: { scholarshipId }
    }
  );
}

export function getStudentPaymentsByTransactionRequest(transactionRequestId) {
  return axios.get(
    "/api/services/app/ScholarshipTransaction/GetStudentPaymentsByTransactionRequest",
    {
      params: { transactionRequestId }
    }
  );
}
