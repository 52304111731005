import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reset } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { ajaxStatusLoadingAction, ajaxStatusCompleteAction, ajaxStatusErrorAction } from "../../../redux/ajax";
import {
    required,
    renderRadioField,
    renderField,
    renderSelectList,
    requiredDateParts,
    dateOfBirthFromFormToString,
    DropdownDatePicker,
    stringToDateOfBirth,
    renderSelectIdNameMaterialField,
} from "../../../support/forms";
import {
    getApplicationSiblings,
    updateApplicationSibling,
    addApplicationSibling,
    deleteApplicationSibling,
    getApplicationSiblingsByApplicationId,
} from "../../../api/applications";
import Button from "../../../support/components/Button";
import Crusader from "./Crusader";

import "./CurrentCrusaders.css";

const FORM_NAME = "currentCrusader";

export const otherGenderValid = (data) => {
    const errors = {};

    if (data.gender === "2" && !data.genderDescription) {
        errors.genderDescription = (
            <FormattedMessage
                id="household.otherRequired"
                defaultMessage="[Other description]"
                children={(formattedMessage) => {
                    return <span style={{ color: "red" }}>{formattedMessage}</span>;
                }}
            />
        );
    }
    return errors;
};

export const noSelect = (value) => {
    return value !== "0" && value !== undefined ? undefined : (
        <FormattedMessage
            id="ThisFieldIsRequired"
            defaultMessage="required"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};
class CurrentCrusaders extends Component {
    constructor(props) {
        super(props);
        this.yes = this.props.intl.formatMessage({ id: "Yes" });
        this.no = this.props.intl.formatMessage({ id: "No" });
        this.male = this.props.intl.formatMessage({ id: "app.male" });
        this.female = this.props.intl.formatMessage({ id: "app.female" });
        this.other = this.props.intl.formatMessage({ id: "app.other" });

        this.state = {
            crusaders: [],
            hasCurrentCrusaders: false,
            showDeleteConfirm: false,
            crusaderGrades: [
                {
                    value: "0",
                    label: `-- ${this.props.intl.formatMessage({ id: "app.select", defaultMessage: "[select]" })} --`,
                },
                { label: "6", value: "6" },
                { label: "7", value: "7" },
                { label: "8", value: "8" },
                { label: "9", value: "9" },
                { label: "10", value: "10" },
                { label: "11", value: "11" },
                { label: "12", value: "12" },
                { label: "13", value: "13" },
                { label: "14", value: "14" },
                { label: "15", value: "15" },
                { label: "16", value: "16" },
                { label: "17", value: "17" },
                { label: "18", value: "18" },
            ],
        };
    }
    componentDidMount = () => {
        const { match } = this.props;
        if (match.params && match.params.id) {
            this.id = match.params.id;
            this.getSiblings = getApplicationSiblingsByApplicationId;
        } else {
            this.getSiblings = getApplicationSiblings;
        }
        this.doLoadCrusaders(true);
    };
    mapSchools = () => {
        const schools = this.props.schools
            .filter((item) => item.isActive)
            .map((item) => ({ name: item.name, id: item.id }))
            .map((i) =>
                i.name === "Other"
                    ? { ...i, name: this.props.intl.formatMessage({ id: "app.other" }) }
                    : i.name === "College"
                    ? { ...i, name: this.props.intl.formatMessage({ id: "household.college" }) }
                    : i
            );

        const selectMessage = this.props.intl.formatMessage({ id: "app.school.select", defaultMessage: "[student.school.select]" });

        schools.unshift({ name: `-- ${selectMessage} --`, id: "" });

        return schools;
    };
    onSave = (values) => {
        this.props.ajaxStatusLoading();

        let method = addApplicationSibling;
        if (values.id) {
            method = updateApplicationSibling;
        }
        if (this.props.match.params && this.props.match.params.id) {
            values.applicationId = this.props.match.params.id;
        }

        method({ ...values, dateOfBirth: dateOfBirthFromFormToString(values.dateOfBirth, this.props.intl.locale) })
            .then(async (results) => {
                await this.doLoadCrusaders();
                this.currentCrusader = null;
                this.doLoadEmpty();
                this.props.ajaxStatusComplete();
                toast.success("Save Complete");
            })
            .catch((error) => {
                this.props.ajaxStatusError(`status: ${error.response.status} message: ${error.response.statusText}`);
            });
    };
    onCancel = (e) => {
        e.preventDefault();
        if (this.currentCrusader) {
            this.props.reset(FORM_NAME);
            return;
        }
        this.doLoadEmpty();
    };
    onDelete = (crusader) => {
        this.crusaderToDelete = crusader;
        this.setState({
            showDeleteConfirm: true,
        });
    };
    onEdit = (crusader) => {
        crusader = { ...crusader };
        crusader.gender = crusader.gender.toString();
        crusader.haveCurrentCrusader = "1";
        crusader.dateOfBirth = stringToDateOfBirth(crusader.dateOfBirth);
        this.props.initialize(crusader);
        this.currentCrusader = crusader;
    };
    onAddAnother = () => {
        this.currentCrusader = null;
        this.doLoadEmpty();
    };
    onCancelDelete = () => {
        this.setState({
            showDeleteConfirm: false,
        });
    };
    onConfirmDelete = async () => {
        this.setState({
            showDeleteConfirm: false,
        });

        this.props.ajaxStatusLoading();
        await deleteApplicationSibling(this.crusaderToDelete);
        await this.doLoadCrusaders();

        if (this.currentCrusader && this.currentCrusader.id === this.crusaderToDelete.id) {
            this.doLoadEmpty();
            this.currentCrusader = null;
        }

        this.props.ajaxStatusComplete();
        this.crusaderToDelete = null;
    };
    doLoadCrusaders = async (isInitialLoad) => {
        const crusaders = (await this.getSiblings(this.id)).data.result;
        const state = { crusaders };
        if (isInitialLoad) {
            state.hasCurrentCrusaders = crusaders.length > 0 ? true : false;
            this.doLoadEmpty();
        }
        this.setState(state);
    };
    doLoadEmpty = () => {
        this.props.initialize(null);
    };
    render() {
        const { yes, no, male, female, other } = this;
        const clsName = this.state.hasCurrentCrusaders ? "" : "disable-div hide-div";
        const { readonly } = this.props;

        return (
            <div>
                <FormattedMessage
                    id="household.siblingQuestion"
                    defaultMessage="[Do you have a child currently enrolled in the Onward We Learn program?]"
                    children={(formattedMessage) => <span className="form-label">{formattedMessage}</span>}
                />
                <div className="form-radio-div" style={{ justifyContent: "flex-start" }}>
                    <label htmlFor="ccYes" style={{ marginRight: 15 }}>
                        <input
                            id="ccYes"
                            type="radio"
                            checked={this.state.hasCurrentCrusaders}
                            value={true}
                            onChange={() => this.setState({ hasCurrentCrusaders: true })}
                            disabled={readonly}
                        />
                        {yes}
                    </label>

                    <label htmlFor="ccNo" style={{ marginRight: 15 }}>
                        <input
                            id="ccNo"
                            type="radio"
                            checked={!this.state.hasCurrentCrusaders}
                            value={false}
                            onChange={() => this.setState({ hasCurrentCrusaders: false })}
                            disabled={readonly}
                        />
                        {no}
                    </label>
                </div>
                <div className={clsName}>
                    <FormattedMessage
                        id="app.firstName"
                        defaultMessage="[First Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="firstName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                skipContextCheck
                                disabled={readonly}
                            />
                        )}
                    />
                    <FormattedMessage
                        id="app.lastName"
                        defaultMessage="[Last Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="lastName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                skipContextCheck
                                disabled={readonly}
                            />
                        )}
                    />
                    <FormattedMessage
                        id="app.dob"
                        defaultMessage="[DOB]"
                        children={(formattedMessage) => (
                            <React.Fragment>
                                <span className="form-label">{formattedMessage}</span>
                                <Field
                                    name="dateOfBirth"
                                    label={formattedMessage}
                                    component={DropdownDatePicker}
                                    validate={[requiredDateParts]}
                                    disabled={readonly}
                                    intl={this.props.intl}
                                />
                            </React.Fragment>
                        )}
                    />
                    <div className="household-form-input-row">
                        <div>
                            <FormattedMessage
                                id="app.gender"
                                defaultMessage="[Gender]"
                                children={(formattedMessage) => <span className="form-label household-form-label-spacer">{formattedMessage}</span>}
                            />

                            <Field
                                id="hhGender"
                                name="gender"
                                component={renderRadioField}
                                className="household-form--gender-radio"
                                items={[
                                    { value: "0", text: male },
                                    { value: "1", text: female },
                                    { value: "2", text: other },
                                ]}
                                skipContextCheck
                                validate={[required]}
                                disabled={readonly}
                            />
                        </div>
                        {this.props.gender === "2" ? (
                            <FormattedMessage
                                id="app.genderDescription"
                                defaultMessage="[other]"
                                children={(formattedMessage) => (
                                    <Field
                                        name="genderDescription"
                                        className="household-form-other-field"
                                        type="text"
                                        component={renderField}
                                        placeholder={formattedMessage}
                                        skipContextCheck
                                        label={formattedMessage}
                                        disabled={readonly}
                                    />
                                )}
                            />
                        ) : null}
                    </div>
                    <div className="household-form-input-row">
                        <FormattedMessage
                            id="app.grade"
                            defaultMessage="[grade]"
                            children={(formattedMessage) => (
                                <Field
                                    name="grade"
                                    className="small-select"
                                    component={renderSelectList}
                                    label={formattedMessage}
                                    data={this.state.crusaderGrades}
                                    skipContextCheck
                                    validate={[noSelect]}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <FormattedMessage
                            id="app.currentSchool"
                            defaultMessage="[Current School]"
                            children={(formattedMessage) => (
                                <Field
                                    name="schoolId"
                                    className="household-form-school-list"
                                    component={renderSelectIdNameMaterialField}
                                    hideDefault
                                    label={formattedMessage}
                                    data={this.mapSchools()}
                                    skipContextCheck
                                    validate={[required]}
                                    disabled={readonly}
                                />
                            )}
                        />
                    </div>
                    <div className="hr-line" />
                    <div className="household-form-right-buttons">
                        <FormattedMessage
                            id="household.siblingSave"
                            defaultMessage="[Save]"
                            children={(formattedMessage) => (
                                <Button
                                    onClick={this.props.handleSubmit(this.onSave)}
                                    id="household.siblingSave"
                                    defaultMessage={formattedMessage}
                                    invalid={readonly}
                                />
                            )}
                        />
                        <a href="#/admissions/household" onClick={this.onCancel.bind(this)}>
                            <FormattedMessage id="app.cancel" defaultMessage="[Cancel]" children={(formattedMessage) => <span>{formattedMessage}</span>} />
                        </a>
                        <div style={{ flex: 1 }} />
                        <FormattedMessage
                            id="app.addAnother"
                            defaultMessage="[Add Another]"
                            children={(formattedMessage) => (
                                <Button onClick={this.onAddAnother} id="app.addAnother" defaultMessage={formattedMessage} invalid={readonly} />
                            )}
                        />
                    </div>

                    {this.state.crusaders.map((item) => (
                        <Crusader
                            crusader={item}
                            key={item.id}
                            schools={this.props.schools}
                            onEdit={this.onEdit.bind(this, item)}
                            onDelete={this.onDelete.bind(this, item)}
                            readonly={readonly}
                        />
                    ))}
                </div>
                <SweetAlert
                    show={this.state.showDeleteConfirm}
                    title={this.props.intl.formatMessage({ id: "household.deleteTitle" })}
                    showCancel
                    onConfirm={this.onConfirmDelete}
                    onCancel={this.onCancelDelete}
                    confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
                    cancelBtnText={this.props.intl.formatMessage({ id: "app.cancel" })}
                >
                    {this.props.intl.formatMessage({ id: "household.deleteText" })}
                </SweetAlert>
            </div>
        );
    }
}

CurrentCrusaders.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    readonly: PropTypes.bool,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

const ReduxForm = reduxForm({
    form: FORM_NAME,
    fields: ["gender", "genderDescription"],
    validate: otherGenderValid,
})(CurrentCrusaders);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
    const gender = selector(state, "gender");
    return {
        gender,
        schools: state.schools,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajaxStatusLoading: bindActionCreators(ajaxStatusLoadingAction, dispatch),
        ajaxStatusComplete: bindActionCreators(ajaxStatusCompleteAction, dispatch),
        ajaxStatusError: bindActionCreators(ajaxStatusErrorAction, dispatch),
        reset: bindActionCreators(reset, dispatch),
    };
};

const CrusaderForm = connect(mapStateToProps, mapDispatchToProps)(ReduxForm);

export default injectIntl(CrusaderForm);
