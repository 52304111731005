import { alpha } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    hoverDate: {
        color: theme.palette.lightGray.main,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: alpha(theme.palette.secondary.main, 0.25),
        },
    },
    badge: {
        "& .MuiBadge-badge:not(.MuiBadge-invisible)": {
            transform: "scale(1) translate(100%, 50%)",
        },
    },
    loadingButtonWrapper: {
        display: "inherit",
    },
}));
