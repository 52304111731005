const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_LOGOUT = 'LOGIN_LOGOUT';

//TODO:
// export const checkLoginAction = () => {
//     let user = null;
//     const persistedUser = sessionStorage.getItem('user');
//     if (persistedUser !== null) {
//         user = JSON.parse(persistedUser);
//     }

//     return { type: LOGIN_SUCCESS, user };
// };

export const logoutAction = () => {
    return {
        type: LOGIN_LOGOUT
    };
};

export const loginAction = user => {
    return { type: LOGIN_SUCCESS, user };
};

export const userReducer = (state = null, action) => {
    if (action.type === LOGIN_SUCCESS) {
        return action.user;
    }
    if (action.type === LOGIN_LOGOUT) {
        sessionStorage.removeItem('user');
        return null;
    }

    return state;
};
