import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { toast } from 'react-toastify';
import { withRouter, Redirect } from 'react-router-dom';

import withAjaxStatus from '../../support/hoc/withAjaxStatus';
import { password, passwordMatch, required, renderField } from '../../support/forms/index';
import * as apis from '../../api/login';

import '../index.css';
import './index.css';

const FORM_NAME = 'staffemail';

class StaffEmail extends Component {
    state = {
        showLogin: false
    };

    componentDidMount = async () => {
        const query = this.props.location.search.replace('?', '');
        const params = query.split('&');
        this.email = params[0].split('=')[1];
        this.code = params[1].split('=')[1];
        this.id = params[2].split('=')[1];
        this.props.initialize({ password: '', confirm: '' });
    };

    onSave = values => {
        const { id, code, email } = this;
        const { password, confirm } = values;
        const request = {
            id,
            code,
            email,
            password,
            confirm
        };

        this.props.ajaxStatusLoading();

        apis.updateStaffPassword(request)
            .then(response => {
                toast.success('Password saved. redirecting to login');
                this.props.ajaxStatusComplete();
                setTimeout(() => {
                    this.setState({ showLogin: true });
                }, 1000);
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.error) {
                    this.props.ajaxStatusError(error.response.data.error.message);
                    return;
                }
                this.props.ajaxStatusError(error.message);
            });
    };

    onCancel = () => {
        this.props.reset(FORM_NAME);
    };

    render() {
        if (this.state.showLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <div className="staff-email">
                <div className="staff-email-details">
                    <div className="logo" alt="logo" />
                    <h1 className="screen-title">Staff Account Setup</h1>
                    <Field name="password" type="password" component={renderField} label="Password" validate={[required, password]} />
                    <span className="login-password-requirements">
                        Passwords must be at least 8 characters long, and must contain a lowercase and uppercase letter, a number, and a special
                        character ($&amp;*@! etc)
                    </span>
                    <Field name="confirm" type="password" component={renderField} label="Confirm Password" validate={[required, password]} />
                    <div className="login-new-account-buttons">
                        <button className="btn" onClick={this.props.handleSubmit(this.onSave)}>
                            Save
                        </button>

                        <button className="link-button staff-email-cancel" onClick={this.onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

StaffEmail.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    onShowLogin: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        reset: bindActionCreators(reset, dispatch)
    };
};

StaffEmail = reduxForm({
    form: FORM_NAME,
    fields: ['password', 'confirm'],
    validate: passwordMatch
})(StaffEmail);

StaffEmail = connect(
    null,
    mapDispatchToProps
)(StaffEmail);

StaffEmail = withAjaxStatus(StaffEmail);
StaffEmail = withRouter(StaffEmail);

export default StaffEmail;
