import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import ListHeader from "./components/header";
import AddEventDialog from "./dialogs/add";
import { getAllFiltered } from "../api/programEvents";
import { getAll as getAllSeasons } from "../api/seasons";
import EventListCard from "./components/eventListCard";
import LoadingIndicator from "../../common/loadingIndicator";
import useDebounce from "../../common/useDebounce";
import { Badge } from "@material-ui/core";
import { useStyles } from "./styles";
import { EventStatus } from "../enums"

const EventsList = ({ isActive, isComplete }) => {
    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [reloadData, reload] = useState(true);
    const [department, setDepartment] = useState("");
    const [status, setStatus] = useState("");
    const [seasonId, setSeasonId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [eligibilityCriteria, setEligibilityCriteria] = useState(null)

    const debouncedSearchValue = useDebounce(searchText, 350);
    const maxItems = 2;

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const seasonsTokenSource = axios.CancelToken.source();
        //console.log(status);
        let stat = status;
        if (isComplete) {
            setStatus(EventStatus.Complete);
            stat = EventStatus.Complete;
            //console.log("Complete");
        }
        (async () => {
            try {
                setLoading(true);
                const listResponse = await getAllFiltered(isActive, debouncedSearchValue, seasonId, department, stat, eligibilityCriteria, tokenSource.token);
                const seasonsResponse = await getAllSeasons(null, seasonsTokenSource.token);
                let res = [];
                if (isActive && !isComplete) {
                    res = listResponse.data.result.filter(flt => flt.status != EventStatus.Complete);
                } else {
                    res = listResponse.data.result;
                }
                setData(res);
                setSeasons(seasonsResponse.data.result);
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            tokenSource.cancel();
            seasonsTokenSource.cancel();
        };
    }, [debouncedSearchValue, department, isActive, reloadData, seasonId, status, eligibilityCriteria]);

    const handleCloseAddDialog = (shouldReload, id) => {
        setShowAddDialog(false);
        if (id) {

            const getNavLink = () => {

                if (isActive) {
                    if (isComplete) {
                        return `/events/complete/event/${id}`
                    } else {
                        return `/events/active/event/${id}`
                    }

                } else {
                    return `/events/inactive/event/${id}`
                }

            }

            history.push(getNavLink());
            return;
        }

        if (shouldReload) {
            reload((prev) => !prev);
        }
    };

    return (
        <div className={classes.eventsList}>
            <LoadingIndicator loading={loading} />
            <ListHeader
                onAdd={() => setShowAddDialog(true)}
                seasons={seasons}
                isActive={isActive}
                seasonId={seasonId}
                onSeasonIdChange={setSeasonId}
                department={department}
                onDepartmentChange={setDepartment}
                status={status}
                onStatusChange={setStatus}
                searchText={searchText}
                onSearchTextChange={setSearchText}
                eligibilityCriteria={eligibilityCriteria}
                setEligibilityCriteria={setEligibilityCriteria}
            />
            {data.length !== 0 && (
                <div className={classes.list}>
                    {data.map((item, idx) => (
                        <Badge
                            badgeContent={`+${item.eventDates.length - maxItems}`}
                            invisible={maxItems >= item.eventDates.length}
                            color="primary"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            key={idx}
                        >
                            <EventListCard event={item} isActive={isActive} isComplete={isComplete} />
                        </Badge>
                    ))}
                </div>
            )}
            {data.length === 0 && !loading && <Alert severity="warning">No Events Found!</Alert>}

            {showAddDialog && <AddEventDialog open={showAddDialog} onClose={handleCloseAddDialog} />}
        </div>
    );
};

export default EventsList;
