import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import UploadStatus from "../uploadStatus";
import IconButton from "../iconButton";
import Button from "../Button";

import "./index.css";

const DropArea = ({ className, onClose, onBrowse, dragging, uploadingFiles, disabled }) => (
    <div className={`drop-area ${className ? className : ""} ${dragging ? "drop-hover" : ""}`}>
        {onClose && <IconButton icon="times" onClick={onClose} />}
        <UploadStatus files={uploadingFiles} disabled={ disabled} />
        <div>
            <FormattedMessage id="documents.droparea" defaultMessage="[Drag and drop files here or]" tagName="div" />{" "}
            <Button id="documents.browse" disabled={disabled} defaultMessage="[Browse for file]" onClick={onBrowse} />
        </div>
    </div>
);

DropArea.propTypes = {
    onClose: PropTypes.func,
};

export default DropArea;
