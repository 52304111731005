import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { required } from "../../../../common/formValidators";
import LoadingButton from "../../../../common/loadingButton";
import { FinalFormCheckbox, FinalFormTextField } from "../../../../common/formWrappers";
import Subtag from "./subtag";
import Link from "@material-ui/core/Link";
class TagItem extends Component {

    state = {
        tags: [],
        subTagClass: "hideSubtags",
        btnShowClass: "showSubtags",
        btnHideClass: "hideSubtags",
        addSubTagClass: "hideAddSubTag",
        newSubTag: { tagId: this.props.tag.id, isActive: true, id: "00000000-0000-0000-0000-000000000000", ipedRequired: false, descriptorRequired: false, creationTime: this.getDate, creatorUserId: "", name: "", isDeleted: false, lastModificationTime: "", lastModifierUserId: ""  }
    };


    handleSubmit = (values, form) => {
        
        const classification = this.props.classifications.find((element) => {
            return element.id === values.classification;
        })
        let input = {
            id: values.id,
            name: values.name,
            descriptorRequired: values.descriptorRequired,
            iPEDRequired: values.ipedRequired,
            isActive: values.isActive,
            useEvents: values.useEvents,
            useAdvisors: values.useAdvisors,
            classificationId: values.classificationId,
            classification: classification,
            description: values.description
        }


        this.props.onUpdate(input);
    }
    getDate = () => {
        let separator = '';
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
    }
    addSubTag = () => {
        this.setState({ addSubTagClass: "showAddSubTag"})
        //this.props.onAddSubtag(this.props.tag.id);
        
       
    }
    showSubTags = () => {
        this.setState({ subTagClass: "showSubtags", btnShowClass: "hideSubtags", btnHideClass: "showSubtags" });
    }
    hideSubTags = () => {
        this.setState({ subTagClass: "hideSubtags", btnShowClass: "showSubtags", btnHideClass: "hideSubtags" });

    }
    render() {
        
        return (<div style={{ borderColor: this.props.tag.classification.color }}><span style={{ color: this.props.tag.classification.color, fontWeight: '800' }} >{this.props.tag.name}</span>
            <Form onSubmit={this.handleSubmit} initialValues={this.props.tag}>
                {({ handleSubmit, values, dirty, errors }) => {
                    
                    return (
                        <form onSubmit={handleSubmit} FormValues={this.props.tag} noValidate className="test">
                          
                            <div className={"test"}>
                                <Field name="id" component="input" type="hidden" />
                                <table>
                                    <tr>
                                        <td>
                                            <span className={"MuiFormControlLabel-label formlabel"}>Classification</span><br/>
                                            <Field name="classificationId" label="Classification" component="select" required validate={required}>
                                                
                                                {this.props.classifications.map((option) => {
                                                    return (
                                                        <option style={{ background: option.color }} key={option.id} value={option.id}>
                                                            {option.classification}
                                                        </option>
                                                    );

                                                })}

                                            </Field>
                                        </td>
                                        <td><br />
                                            <Field
                                                component={FinalFormTextField}
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                validate={required}
                                                wrapperClassName={"formlabel"}
                                            />
                                        </td>
                                    </tr><tr>
                                        <td colSpan="2">
                                            <Field
                                                component={FinalFormTextField}
                                                name="description"
                                                label="Description (Optional)"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                multiline
                                                maxLength={5000}
                                                minRows={2}
                                                maxRows={4}
                                                wrapperClassName={"formlabel"}
                                            />
                                        </td>
                                        </tr><tr>
                                        <td>
                                            <span className={"MuiFormControlLabel-label formlabel"}>Usages</span><br />
                                            <Field component={FinalFormCheckbox} name="useAdvisors" label="Use for Advisors" />&nbsp;
                                            <Field component={FinalFormCheckbox} name="useEvents" label="Use for Events" />&nbsp;
                                        </td>
                                        <td><span className={"MuiFormControlLabel-label formlabel"}>Behavior</span><br />
                                            <Field component={FinalFormCheckbox} name="ipedRequired" label="IPED Number Required" />&nbsp;
                                            <Field component={FinalFormCheckbox} name="descriptorRequired" label="Descriptor Required" />
                                        </td>
                                    </tr>
                                    <tr>
                                        
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr><tr>
                                        <td style={{ textAlign: 'right' }}>
                                        </td>
                                        <td style={{ textAlign: 'right' }} >
                                            
                                            <table style={{ float: 'right' }}>
                                                <tr><td>
                                                    <Field component={FinalFormCheckbox} name="isActive" label="Is Active" />
                                                </td>
                                                    <td>
                                                        <LoadingButton
                                                            wrapperClassName={"test"}
                                                            className={"test"}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleSubmit}
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                    </td>
                                                    <td>
                                                        <LoadingButton
                                                            wrapperClassName={"test"}
                                                            className={"test"}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.cancelEdit}
                                                        >
                                                            Cancel
                                                        </LoadingButton>
                                                    </td>
                                                </tr>
                                            </table>

                                            
                                            
                                        </td>
                                    </tr>
                                </table>


                            </div>
                            <div >
                                <Link id={this.props.tag.id} onClick={this.showSubTags} className={this.state.btnShowClass} style={{ color: this.props.tag.classification.color, fontWeight: '800', width: '100%', textAlign: 'center' }} >
                                Show Subtags +
                                </Link>
                                <Link id={this.props.tag.id} onClick={this.hideSubTags} className={this.state.btnHideClass} style={{ color: this.props.tag.classification.color, fontWeight: '800', width: '100%', textAlign: 'center' }} >
                                    Hide Subtags -
                                </Link>
        
                                <div className={this.state.subTagClass}>
                                    <LoadingButton
                                        wrapperClassName={"test"}
                                        className={"test"}
                                        variant="contained"
                                        color="primary"
                                        onClick={this.addSubTag}
                                    >
                                        Add Subtag
                                    </LoadingButton>
                            {this.props.tag.subtags.map((subtag) => {
                                
                                return (
                                    
                                    <Subtag  subTag={subtag} onUpdate={this.props.onSubTagUpdate} />
                                    
                                    
                                );

                            })}
                                    <div className={this.state.addSubTagClass} >
                                        <Subtag subTag={this.state.newSubTag} onUpdate={this.props.onSubTagUpdate} /> 
                                    </div>
                                    
                            </div></div>
                        </form>
                    );
                }}
            </Form>
            

        </div>);
    };
}

TagItem.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};


export default TagItem;