import axios from "axios";

export const addObjective = (objective) => {
    return axios.post("/api/services/app/objectives/AddObjective", objective);
};
export const updateObjective = (objective) => {
    return axios.post("/api/services/app/objectives/UpdateObjective", objective);
};
export const deleteObjective = (objective) => {
    return axios.post("/api/services/app/objectives/DeleteObjective", objective);
};
export const getObjectives = () => {
    return axios.get("/api/services/app/objectives/GetAllObjective");
};
export const getObjectiveTags = () => {
    return axios.get("/api/services/app/objectives/GetObjectiveTags");
};
export const getAdvisorList = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetAdvisorList", { params: { userProfileId } });
};

export const sendStaffEmail = (objective) => {
    return axios.post("/api/services/app/Advisors/SendStaffEmail", objective);
};