import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";

import "./List.css";

const formatType = (value) => {
    return value === "1" ? "Two Year" : "Four year";
};
const formatTerms = (value) => {
    return value === "1" ? "Trimester" : "Semester";
};

class List extends Component {
    static permission = "Admin.Institutions";
    render() {
        return (
            <div className="admin-institution-list">
                <ReactTable
                    data={this.props.institutions}
                    minRows={0}
                    columns={[
                        {
                            Header: "School Name",
                            accessor: "name",
                            minWidth: 150,
                        },
                        {
                            Header: "Address",
                            accessor: "address1",
                            minWidth: 150,
                            Cell: (data) => (
                                <div className="admin-institution-address-cell">
                                    <span>{data.original.address1}</span>
                                    <br />
                                    {data.original.address2 ? <span>{data.original.address2}</span> : null}
                                    {data.original.address2 ? <br /> : null}
                                    <span>{`${data.original.city} ${data.original.state}${data.original.state ? "," : ""} ${data.original.zipCode}`}</span>
                                </div>
                            ),
                        },
                        {
                            Header: "Payment Address",
                            accessor: "paymentAddress1",
                            minWidth: 150,
                            Cell: (data) => (
                                <div className="admin-institution-address-cell">
                                    <span>{data.original.paymentAddress1}</span>
                                    <br />
                                    {data.original.paymentAddress2 ? <span>{data.original.paymentAddress2}</span> : null}
                                    {data.original.paymentAddress2 ? <br /> : null}
                                    <span>{`${data.original.paymentCity} ${data.original.paymentState}${data.original.paymentState ? "," : ""} ${
                                        data.original.paymentZipCode
                                    }`}</span>
                                </div>
                            ),
                        },
                        {
                            Header: "Main Phone",
                            accessor: "phone",
                            className: "admin-list-cell-center",
                        },
                        {
                            Header: "Fax",
                            accessor: "fax",
                            className: "admin-list-cell-center",
                        },
                        {
                            Header: "School Type",
                            accessor: "type",
                            className: "admin-list-cell-center",
                            Cell: (data) => <span>{formatType(data.row.type)}</span>,
                        },
                        {
                            Header: "Term Structure",
                            accessor: "termStructure",
                            className: "admin-list-cell-center",
                            Cell: (data) => <span>{formatTerms(data.row.termStructure)}</span>,
                        },
                        {
                            Header: "Notes",
                            accessor: "note",
                            minWidth: 150,
                            Cell: (data) => <span title={data.row.note}>{data.row.note}</span>,
                        },
                        {
                            Header: "Active",
                            accessor: "isActive",
                            minWidth: 75,
                            Cell: (data) => <span>{data.row.isActive ? "Yes" : "No"}</span>,
                        },
                        {
                            Header: "",
                            accessor: "id",
                            minWidth: 30,
                            Cell: (row) => {
                                const url = `/admin/institution/${row.value}`;
                                return <Link to={url}>Edit</Link>;
                            },
                        },
                    ]}
                    defaultPageSize={50}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

List.propTypes = {
    institutions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            address1: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            paymentAddress: PropTypes.string,
            paymentCity: PropTypes.string,
            paymentState: PropTypes.string,
            paymentZipCode: PropTypes.string,
            phone: PropTypes.string,
            fax: PropTypes.string,
            type: PropTypes.string,
            termStructure: PropTypes.string,
            note: PropTypes.string,
        })
    ),
    readonly: PropTypes.bool.isRequired,
};

List = withAjaxStatus(List);
export default List;
