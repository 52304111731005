import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import LoadingButton from "../loadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({
    open,
    handleClose,
    title,
    body,
    confirmAction,
    confirmButtonDisabled,
    isSaving,
    isSubmit,
    disablePortal,
    confirmActionText = null,
    cancelActionText = null,
    maxWidth,
    fullWidth,
}) {
    const localOnClose = (evnt, reason) => {
        if (reason) {
            return;
        }

        handleClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={localOnClose}
            disablePortal={disablePortal}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
                <Button disabled={isSaving} onClick={handleClose} color="primary" variant="text">
                    {cancelActionText ? cancelActionText : "Cancel"}
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    loading={isSaving}
                    type={isSubmit ? "submit" : undefined}
                    onClick={confirmAction}
                    disabled={confirmButtonDisabled}
                >
                    {confirmActionText ? confirmActionText : "OK"}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
