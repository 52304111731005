import React, { Component } from 'react';
import { Button, TextField, Grid, Field } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


class ObjectivesForm extends Component {
    state = {
        tagsId: '',
        subtagId: '',
        title: '',
        description: '',
        baseline: 0,
        y1: 0,
        y2: 0,
        y3: 0,
        y4: 0,
        y5: 0,
        y6: 0,
        y7: 0,
        tags: [],
        subTags: []
    };

    componentDidMount = async () => {
        
        // Load tags as before
        const tags = [];
        const classifications = this.props.tags.filter(flt => flt.classification.advisorGoals == true);
        classifications.forEach((option) => {
            tags.push(...option.dropList);
        });
        this.setState({ tags: tags });

        // Initialize form values if activeObjective is provided
        if (this.props.activeObjective) {
            const { tagsId, subtagId, title, description, baseline, y1, y2, y3, y4, y5, y6, y7 } = this.props.activeObjective;
            this.setState({
                tagsId: tagsId || '',
                subtagId: subtagId || '',
                title: title || '',
                description: description || '',
                baseline: baseline || 0,
                y1: y1 || 0,
                y2: y2 || 0,
                y3: y3 || 0,
                y4: y4 || 0,
                y5: y5 || 0,
                y6: y6 || 0,
                y7: y7 || 0,
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.tags != this.props.tags) {
            const tags = [];
            const classifications = this.props.tags.filter(flt => flt.classification.advisorGoals == true);
            classifications.forEach((option) => {

                tags.push(...option.dropList);


            });

            this.setState({ tags: tags })
        }

    }
    cancel = () => {
        if (this.props.activeObjective) {
            const { tagsId, subtagId, title, description, baseline, y1, y2, y3, y4, y5, y6, y7 } = this.props.activeObjective;
            this.setState({
                tagsId: tagsId || '',
                subtagId: subtagId || '',
                title: title || '',
                description: description || '',
                baseline: baseline || 0,
                y1: y1 || 0,
                y2: y2 || 0,
                y3: y3 || 0,
                y4: y4 || 0,
                y5: y5 || 0,
                y6: y6 || 0,
                y7: y7 || 0,
            });
        } else {
            this.props.onClose();
        }
    }
    delete = () => {
        this.props.onDelete(this.props.activeObjective);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        
        let input = {};
        if (this.props.activeObjective) {
            input = this.props.activeObjective;
            input.tagsId = this.state.tagsId;
            input.subtagId = this.state.subtagId;
            input.title = this.state.title;
            input.description = this.state.description;
            input.baseline = this.state.baseline;
            input.y1 = this.state.y1;
            input.y2 = this.state.y2;
            input.y3 = this.state.y3;
            input.y4 = this.state.y4;
            input.y5 = this.state.y5;
            input.y6 = this.state.y6;
            input.y7 = this.state.y7;
            this.props.onUpdate(input);
        } else {
                input = {
                    tagsId: this.state.tagsId,
                    subtagId: this.state.subtagId,
                    title: this.state.title,
                    description: this.state.description,
                    baseline: this.state.baseline,
                    y1: this.state.y1,
                    y2: this.state.y2,
                    y3: this.state.y3,
                    y4: this.state.y4,
                    y5: this.state.y5,
                    y6: this.state.y6,
                    y7: this.state.y7,
            }
            this.props.onAdd(input);
         
        };

        
    };


    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Title"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                        />
                    </Grid>
                   
                    <Grid item xs={12}>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Objective Tag</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="tagsId"
                                value={this.state.tagsId}
                                onChange={this.handleChange}
                            >
                                {this.state.tags.map((option) => {
                                    return (
                                        <MenuItem value={option.id}>{option.label}</MenuItem>
                                       
                                    );

                                })}
                                
                               
                            </Select>
                        </FormControl>

                       
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Baseline"
                            name="baseline"
                            type="number"
                            value={this.state.baseline}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    
                    {Array.from({ length: 7 }, (_, i) => (
                        <Grid item xs={3} key={i}>
                            <TextField
                                fullWidth
                                label={`Year ${i + 1}`}
                                name={`y${i + 1}`}
                                type="number"
                                value={this.state[`y${i + 1}`]}
                                onChange={this.handleChange}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={4}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={this.cancel }>
                            Cancel
                        </Button>
                    </Grid>
                    { this.props.activeObjective ?
                        <Grid item xs={4}>
                            <Button type="submit" variant="contained" color="primary" onClick={this.delete}>
                                Delete
                            </Button>
                        </Grid>
                   : null }
                    
                </Grid>
            </form>
        );
    }
}

export default ObjectivesForm;
