import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { maxLength } from "../../../common/formValidators";
import { Condition, FinalFormRadioGroup, FinalFormTextField } from "../../../common/formWrappers";
import BusStopFormItem from "./busStop";
import enumHelper from "../../../support/logic/enumHelper";
import { AmPm } from "../../enums";

const Transportation = ({ form, disabled }) => {
    const amPmOptions = useMemo(() => {
        return enumHelper.getSelectOptionsList(AmPm, false);
    }, []);

    return (
        <Box width="80%" display="flex" flexDirection="column" alignItems="flex-start">
            <Field
                component={FinalFormRadioGroup}
                name="transportation.provided"
                row
                label="Transportation Provided?"
                choices={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                ]}
                format={(value) => value ? value.toString() : "false"}
                parse={(value) => value === "true"}
                defaultValue={false}
            />
            <Condition when="transportation.provided" is={true}>
                <Field
                    component={FinalFormTextField}
                    placeholder="Company Name"
                    name="transportation.companyName"
                    label="Company Name"
                    variant="outlined"
                    maxRows={4}
                    multiline
                    margin="dense"
                    maxLength="5000"
                    validate={maxLength(5000)}
                    disabled={disabled }
                />
                <Typography variant="h6">Scheduled Bus Stops</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Pick Up Time</TableCell>
                            <TableCell>Drop Off Time</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <FieldArray name="transportation.busStops">
                            {({ fields }) => {
                                if (fields.length === 0) {
                                    return (
                                        <TableRow>
                                            <TableCell style={{ border: "none" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={disabled}
                                                    onClick={() => fields.push({ pickupTimeAmPm: 1, dropoffTimeAmPm: 1 })}
                                                >
                                                    Add Bus Stop
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                                return (
                                    <>
                                        {fields.map((field, index) => (
                                            <BusStopFormItem
                                                key={field}
                                                field={field}
                                                amPmOptions={amPmOptions}
                                                disabled={disabled}
                                                onDelete={() => {
                                                    fields.remove(index);
                                                }}
                                            />
                                        ))}
                                        <TableRow>
                                            <TableCell style={{ border: "none" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={disabled}
                                                    onClick={() => fields.push({ pickupTimeAmPm: 1, dropoffTimeAmPm: 1 })}
                                                >
                                                    Add Bus Stop
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            }}
                        </FieldArray>
                    </TableBody>
                </Table>
            </Condition>
        </Box>
    );
};

export default Transportation;
