import axios from "axios";

export const getAllProgramCodes = (cancelToken) => {
    return axios.get("/api/services/app/ProgramCode/GetAllProgramCodes", { cancelToken });
};

export const getAllCoreOfferings = (cancelToken) => {
    return axios.get("/api/services/app/ProgramCode/GetAllCoreOfferings", { cancelToken });
};

export const getAllSubCodes = (cancelToken) => {
    return axios.get("/api/services/app/ProgramCode/GetAllSubCodes", { cancelToken });
};

export const createProgramCode = (input) => {
    return axios.post("/api/services/app/ProgramCode/CreateProgramCode", input);
};

export const createSubCode = (input) => {
    return axios.post("/api/services/app/ProgramCode/CreateSubCode", input);
};

export const updateSubCode = (input) => {
    return axios.put("/api/services/app/ProgramCode/UpdateSubCode", input);
};
