import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    warning: {
        textAlign: "center",
        backgroundColor: "#fffde7",
        border: "1px solid #fff59d",
        borderRadius: "5px",
    },
    cropMargin: {
        marginBottom: 0,
    },
}));
