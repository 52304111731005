import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Field } from "redux-form";

import { ApplicationStatus } from "../../../../admissions/logic/enums";

import "./CompleteReject.css";

const CompleteReject = ({
    onComplete,
    onInvite,
    onReject,
    onRejectApplicant,
    onResubmit,
    canComplete,
    initialSubmissionDate,
    submissionDate,
    onSelectEvent,
    events,
    inviteEnabled,
    locale,
    orientationEnabled,
    onSaveNotes,
    onCancelNotes,
    attendedEvent,
    canRejectApplicant,
    virtualOrientation,
    applicationStatus,
    canResubmit,
    readonly,
    onDelete
}) => {
    return (
        <div className="complete-reject">
            <div className={`complete-container ${canComplete ? "" : "disabled"}`}>
                <button className="btn" onClick={onComplete} disabled={!canComplete || readonly}>
                    {virtualOrientation ? "INVITE TO FAMILY ORIENTATION" : "MARK COMPLETE"}
                </button>
            </div>
            <div className="application-info-container">
                <div className="preferred-language">Preferred language: {locale}</div>
                {initialSubmissionDate && <div className="submit-status">{`Initially Submitted on ${initialSubmissionDate} (Recruitment Cycle)`}</div>}
                <div className="submit-status">
                    {submissionDate !== null ? `Application was completed and submitted on ${submissionDate}` : "Not submitted"}
                </div>
            </div>
            <div className="reject-container">
                {applicationStatus == ApplicationStatus.Submitted &&
                    <button className="btn resubmit" disabled={!canResubmit || readonly} onClick={onResubmit}>
                    Resubmit Application
                </button>
                }
                <button className="btn reject-application" disabled={!canRejectApplicant || readonly} onClick={onReject}>
                    Reject Application
                </button>
                <button className="btn reject-applicant" disabled={!canRejectApplicant || readonly} onClick={onRejectApplicant}>
                    Reject Applicant
                </button>

                {applicationStatus == ApplicationStatus.NotSubmitted &&
                    <button className="btn resubmit" disabled={readonly} onClick={onDelete}>
                        Delete Application
                    </button>
                }
            </div>
            {!virtualOrientation && (
                <div className={orientationEnabled ? "orientation-container" : "orientation-container disabled"}>
                    <span>Assign Orientation Options</span>
                    <div className="event-list">
                        {!attendedEvent
                            ? events.map((event) => (
                                  <div className="event-item" key={event.id}>
                                      <label data-tip data-for={event.id}>
                                          <input
                                              type="checkbox"
                                              checked={event.checked}
                                              onChange={() => onSelectEvent(event.id)}
                                            disabled={!orientationEnabled || readonly}
                                          />
                                          <span className="event-date">{moment(event.startTime).format("M/D/YY")}</span> {event.name}
                                      </label>
                                      <ReactTooltip id={event.id}>
                                          <div>
                                              <div>{event.name}</div>
                                              <div>Max Attendees: {event.maxAttendees}</div>
                                              <div>
                                                  {moment(event.startTime).format("M/D/YY")} {moment.utc(event.startTime).format("h:mm a")} -{" "}
                                                  {moment.utc(event.endTime).format("h:mm a")}
                                              </div>
                                              <div>{event.address1}</div>
                                              {event.address2 ? <div>{event.address2}</div> : null}
                                              <div>
                                                  {event.city}, {event.state} {event.zipCode}
                                              </div>
                                          </div>
                                      </ReactTooltip>
                                  </div>
                              ))
                            : null}
                    </div>
                    <button className="btn" disabled={!orientationEnabled || !inviteEnabled || readonly} onClick={onInvite}>
                        Invite
                    </button>
                </div>
            )}
            <div className="notes-container">
                <span>NOTES</span>
                <Field name="notes" component="textarea" />
                <div className="notes-buttons">
                    <button className="btn" onClick={onSaveNotes} disabled={readonly }>
                        Save
                    </button>
                    <button className="link-button" onClick={onCancelNotes}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

CompleteReject.propTypes = {
    onComplete: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
};

export default CompleteReject;
