import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import moment from "moment";

import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

import "./CrusaderList.css";

class CrusaderList extends Component {
  render() {
    return (
      <div className="bottom">
        <ReactTable
          data={this.props.refund.details}
          minRows={0}
          columns={[
            {
              Header: "Last Name",
              accessor: "lastName",
              minWidth: 150
            },
            {
              Header: "First Name",
              accessor: "firstName",
              minWidth: 150
            },
            {
              Header: "Term",
              accessor: "termInt",
              minWidth: 150,
              Cell: row => {
                return <div>{row.original.term}</div>;
              }
            },
            {
              Header: "Certified",
              accessor: "status",
              minWidth: 150,
              Cell: row => {
                return <div>{row.original.certified}</div>;
              }
            },
            {
              Header: "Date",
              accessor: "date",
              Cell: row => {
                const date = moment.utc(row.original.date).format("M/D/YY");
                return <div>{date}</div>;
              }
            },
            {
              Header: "Reason",
              accessor: "reason",
              minWidth: 350,
              sortable: false
            },
            {
              Header: "Amount",
              accessor: "amount",
              Cell: row => {
                return <div style={{ textAlign: "right" }}>{formatCurrencyNoDecimalWithNegativeStyle(row.original.amount)}</div>;
              }
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight financials-table"
        />
      </div>
    );
  }
}

CrusaderList.propTypes = {
  refund: PropTypes.shape({
    total: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        term: PropTypes.string,
        certified: PropTypes.string,
        date: PropTypes.string,
        totalBilled: PropTypes.string,
        refund: PropTypes.string,
        reason: PropTypes.string,
        totalDue: PropTypes.string
      })
    )
  })
};

export default CrusaderList;
