import React, { Component } from 'react';

class Institutions extends Component {
    static permission = ['Institution.Main', 'Finance.Main'];
    render() {
        return <div>Coming Soon</div>;
    }
}

export default Institutions;
