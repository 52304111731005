import React, { Component, Fragment } from "react";
import AdvisorContext from "../context/context";
import ReactTable from "react-table";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import Link from "@material-ui/core/Link";


class APRReportPage extends Component {
    static contextType = AdvisorContext;

constructor(props) {
        super(props);

        this.state = {



        };
    }



componentDidMount() {
        //console.log(this.props);
    }
    componentDidUpdate(prevProps, prevState) {



    }
    render() {
    
        return (
            <>
         <br />
            <Link
                className={"dashboardLink"} onClick={() => this.props.gotoLocation("dashboard")}
            >
                &lt; Return to Dashboard
            </Link> <br /><br />
                <div>Coming Soon</div></>)
    
    }

     }


export default APRReportPage;