import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

class ErrorMessage extends Component {
  render() {
    const title = this.props.intl.formatMessage({ id: "app.errorTitle" });
    const ok = this.props.intl.formatMessage({ id: "app.ok" });
    return (
      <SweetAlert show={this.props.show} title={title} onConfirm={this.props.onClose} confirmBtnText={ok}>
        {this.props.message}
      </SweetAlert>
    );
  }
}

ErrorMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default injectIntl(ErrorMessage);
