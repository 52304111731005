import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";

import enumHelper from "../../../support/logic/enumHelper";
import { EventStatus } from "../../enums";
import EventDateTooltip from "../../common/eventDateTooltip/eventDateTooltip";

const useStyles = makeStyles((theme) => ({
    eventListCard: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: theme.spacing(1),
        borderRadius: 4,
        height: 110,
        width: 350,
        overflow: "hidden",
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(1.8),
    },
    eventName: {
        color: theme.palette.tertiary.main,
        fontSize: 20,
        fontWeight: 600,
        maxWidth: "64%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        '&:hover': {
            color: "#e2a331",
        }
    },
    status: {
        fontSize: 14,
        color: theme.palette.lightGray.main,
    },
}));


const EventListCard = ({ event, isActive, isComplete }) => {
    const classes = useStyles();
    const getNavLink = () => {

        if (isActive) {
            if (isComplete) {
                return `/events/complete/event/${event.id}`
            } else {
                return `/events/active/event/${event.id}`
            }

        } else {
            return `/events/inactive/event/${event.id}`
        }

    }
    return (
        <div className={classes.eventListCard}>
            <Box display="flex" justifyContent="space-between" flex="1" mb={1}>
                <Link className={classes.eventName} underline="none" component={NavLink} to={getNavLink} title={event.name}>
                    {event.name}
                </Link>
                <span className={classes.status}>{enumHelper.getDisplayName(EventStatus, event.status)}</span>
            </Box>

            <EventDateTooltip eventDates={event.eventDates} maxItems={2} />
        </div>
    );
};

export default EventListCard;
