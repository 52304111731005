import React, { useState, useEffect, useMemo } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Form, Field } from "react-final-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import RemoveIcon from "@material-ui/icons/Remove";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

import SimpleDialog from "../../common/simpleDialog";
import { FinalFormDropdown, FinalFormRadioGroup, FinalFormTextField } from "../../common/formWrappers";
import { required } from "../../common/formValidators";
import { DateAndTimeType, EventDepartmentType, EventLocationType } from "../enums";
import enumHelper from "../../support/logic/enumHelper";
import LoadingButton from "../../common/loadingButton";
import { getAll as getAllSeasons } from "../api/seasons";
import { createEvent, CreateProgramFromTemplate } from "../api/programEvents";
import ConfirmDialog from "../../common/confirmDialog";
import { FormHelperText, useTheme } from "@material-ui/core";
import useAjaxStatus from "../../common/useAjaxStatus";
import { useHistory } from "react-router";

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
    addEventDialog: {},
    form: {
        
        display: "flex",
        flexDirection: "column",
        padding: 1,
    },
    margin: {
        marginBottom: 2,
    },
    label: {
        marginBottom: 1,
    },
    checked: {
        "&.Mui-checked .MuiIconButton-label": {
            color: `#123225!important`,
        },
    },
    datePicker: {
        "& .MuiOutlinedInput-input": {
            paddingTop: 1,
            paddingBottom: 1,
        },
        "& .MuiPickersDay-day .MuiIconButton-label": {
            color: "rgba(0, 0, 0, 0.87)",
        },
        "& .MuiPickersDay-dayDisabled .MuiIconButton-label": {
            color: "rgba(245, 232, 209, 0.87)",
        },
    },
    datePickerAdornedEnd: {
        paddingRight: 1,
    },
    datePickerAdornment: {
        padding: 1,
    },
    error: {
        border: `1px solid ${theme.palette.error.main}`,
        marginBottom: 1,
        padding: 1,
    },
    eventDatesContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "75%",
        gap: 2,
        maxHeight: "40vh",
    },
}));

const NewTemplateForm = ({ open, onClose, selectedTemplate }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const [loading, setLoading] = useState(true);
    const [seasons, setSeasons] = useState([]);
    const history = useHistory();
    const [singleSelection, setSingleSelection] = useState({
        eventDate: moment(),
        startTime: moment().add(1, "hours").startOf("hour"),
        endTime: moment().add(2, "hours").startOf("hour"),
    });
    const [multipleSelection, setMultipleSelection] = useState([
        {
            eventDate: moment(),
            startTime: moment().add(1, "hours").startOf("hour"),
            endTime: moment().add(2, "hours").startOf("hour"),
        },
    ]);
    const [rangeSelection, setRangeSelection] = useState({
        startDate: moment(),
        endDate: moment().add(1, "days"),
        startTime: moment().add(1, "hours").startOf("hour"),
        endTime: moment().add(2, "hours").startOf("hour"),
        days: [1, 2, 3, 4, 5],
    });
    
    const [payload, setPayload] = useState(null);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        if (open) {
            (async () => {
                try {
                    setLoading(true);
                    
                    const seasonsResponse = await getAllSeasons(true, tokenSource.token);
                    setSeasons(seasonsResponse.data.result.map((s) => ({ label: s.name, value: s.id })));
                } catch (ex) {
                } finally {
                    setLoading(false);
                }
            })();
        }
        return () => {
            tokenSource.cancel();
        };
    }, [open]);

    const eventDepartmentOptions = useMemo(() => enumHelper.getSelectOptionsList(EventDepartmentType), []);
    const eventLocationOptions = useMemo(() => enumHelper.getSelectOptionsList(EventLocationType), []);
    const dateTimeTypeOptions = useMemo(() => enumHelper.getSelectOptionsList(DateAndTimeType), []);

    const locationTypeValue = (locType)=>{

        
        switch (locType) {
            case EventLocationType.InPerson:
                return EventLocationType.InPerson.toString();
                break;
            case EventLocationType.Virtual:
                return EventLocationType.Virtual.toString();
                break;
            case EventLocationType.Both:
                return EventLocationType.Both.toString();
                break;
            default:
                return EventLocationType.InPerson.toString();
                break;

        }

    }

    const bool2String = (bool) => {

        if (bool) {
            return 'true';
        } else {
            return 'false';
        }

    }

    const dateAndTimeTypeValue = () => {
        

        if (selectedTemplate.isSingle) {
            return DateAndTimeType.Single.toString();
        } else {

            return DateAndTimeType.MultipleEvents.toString();
        }


    //    if (!selectedTemplate.isSeries && selectedTemplate.canAttendMultipleEvents) {
    //        return DateAndTimeType.MultipleEvents.toString();
    //    }
    //    if (selectedTemplate.isSeries && !selectedTemplate.canAttendMultipleEvents) {
    //        return DateAndTimeType.MultipleEvents.toString();
    //    }
    //    if (selectedTemplate.isSeries && selectedTemplate.canAttendMultipleEvents) {
    //        return DateAndTimeType.MultipleEvents.toString();
    //    }
        
    }

  

    const handleDayChange = (checked, day) => {
        setRangeSelection((prev) => {
            if (checked && !prev.days.includes(day)) {
                const days = [...prev.days];
                days.push(day);
                return { ...prev, days };
            } else if (!checked && prev.days.includes(day)) {
                const days = [...prev.days];
                days.splice(days.indexOf(day), 1);
                return { ...prev, days };
            }

            return prev;
        });
    };

    const handleMultipleSelection = (date, field, index) => {
        setMultipleSelection((prev) => {
            const arr = [...prev];
            const item = arr[index];
            item[field] = date;
            return arr;
        });
    };

    const handleRemoveMultipleSelection = (index) => {
        setMultipleSelection((prev) => {
            const arr = [...prev];
            arr.splice(index, 1);
            return arr;
        });
    };

    const handleRemoveDate = (index) => {
        setPayload((prev) => {
            const newPayload = { ...prev };
            newPayload.eventDates.splice(index, 1);

            return newPayload;
        });
    };

    const handleDateValidation = (values) => {
        const input = { ...values };
        input.eventDates = [];
        const errors = [];

        switch (parseInt(values.dateAndTimeType, 10)) {
            case DateAndTimeType.Single:
                const startTime =
                    !singleSelection.eventDate || !singleSelection.startTime || !singleSelection.endTime
                        ? null
                        : singleSelection.startTime
                            .clone()
                            .hour(singleSelection.startTime.hour())
                            .minute(singleSelection.startTime.minute())
                            .second(0)
                            .millisecond(0);
                const endTime =
                    !singleSelection.eventDate || !singleSelection.startTime || !singleSelection.endTime
                        ? null
                        : singleSelection.endTime
                            .clone()
                            .hour(singleSelection.endTime.hour())
                            .minute(singleSelection.endTime.minute())
                            .second(0)
                            .millisecond(0);
                if (startTime && endTime) {
                    input.eventDates.push({ startTime, endTime });
                }
                break;
            case DateAndTimeType.MultipleEvents:
                multipleSelection.forEach((e) => {
                    const startTime =
                        !e.eventDate || !e.startTime
                            ? null
                            : e.eventDate.clone().hour(e.startTime.hour()).minute(e.startTime.minute()).second(0).millisecond(0);
                    const endTime =
                        !e.eventDate || !e.endTime ? null : e.eventDate.clone().hour(e.endTime.hour()).minute(e.endTime.minute()).second(0).millisecond(0);
                    input.eventDates.push({ startTime, endTime });
                });
                break;
            case DateAndTimeType.DateRange:
                const start = rangeSelection.startDate ? rangeSelection.startDate.clone() : null;
                const end = rangeSelection.endDate ? rangeSelection.endDate.clone() : null;

                const sTime = rangeSelection.startTime ? rangeSelection.startTime : null;
                const eTime = rangeSelection.endTime ? rangeSelection.endTime : null;

                if (!start || !end || !sTime || !eTime) {
                    errors.push({ index: 0, message: "Required!" });
                } else {
                    start.startOf("day");
                    end.startOf("day");
                    for (start; start.isSameOrBefore(end.startOf("day")); start.add(1, "days")) {
                        if (rangeSelection.days.includes(start.day())) {
                            const startTime = start.clone().hour(sTime.hour()).minute(sTime.minute()).second(0).millisecond(0);
                            const endTime = start.clone().hour(eTime.hour()).minute(eTime.minute()).second(0).millisecond(0);
                            input.eventDates.push({ startTime, endTime });
                        }
                    }
                }
                break;
            default:
                break;
        }

        for (let i = 0; i < input.eventDates.length; i++) {
            const start = input.eventDates[i].startTime;
            const end = input.eventDates[i].endTime;
            if (!start || !end) {
                errors.push({ index: i, message: "Required!" });
                continue;
            }

            if (end.isBefore(start) || end.isSame(start)) {
                errors.push({ index: i, message: "End time must occur AFTER start time" });
            }

            for (let j = 0; j < input.eventDates.length; j++) {
                if (i === j || input.eventDates.length === 1) {
                    continue;
                }

                if (!input.eventDates[i].startTime || !input.eventDates[i].endTime) {
                    errors.push({ index: i, message: "Required!" });
                } else if (!input.eventDates[j].startTime || !input.eventDates[j].endTime) {
                    errors.push({ index: j, message: "Required!" });
                } else if (input.eventDates.length > 1) {
                    const start1 = input.eventDates[i].startTime;
                    const end1 = input.eventDates[i].endTime;
                    const start2 = input.eventDates[j].startTime;
                    const end2 = input.eventDates[j].endTime;

                    const range1 = moment.range(start1, end1);
                    const range2 = moment.range(start2, end2);

                    if (range1.overlaps(range2)) {
                        if (!errors.map((e) => e.index).includes(j)) {
                            errors.push({ index: j, message: "Event date overlap!" });
                        }
                    }
                }
            }
        }

        if (input.eventDates.length === 0) {
            errors.push({ index: 0, message: "Required!" });
        }

        if (errors.length !== 0) {
            const error = { dateAndTimeType: "Error" };
            errors.forEach((e) => {
                error[e.index] = e.message;
            });
            return error;
        }

        return undefined;
    };

    const handleSave = (values) => {





        setLoading(true);

        //console.log(values);
        




        const input = { ...selectedTemplate };
        input.name = values.name;
        input.spanishName = values.spanishName;
        input.dateAndTimeType = values.dateAndTimeType;
        input.department = values.department;
        input.isSeries = values.isSeries;
        input.canAttendMultipleEvents = values.canAttendMultipleEvents;
        input.locationType = values.locationType;
        input.seasonId = values.seasonId;
        
        input.eventDates = [];
        
        switch (parseInt(values.dateAndTimeType, 10)) {
            case DateAndTimeType.Single:
                const startTime =
                    !singleSelection.eventDate || !singleSelection.startTime || !singleSelection.endTime
                        ? null
                        : singleSelection.eventDate
                            .clone()
                            .hour(singleSelection.startTime.hour())
                            .minute(singleSelection.startTime.minute())
                            .second(0)
                            .millisecond(0);
                const endTime =
                    !singleSelection.eventDate || !singleSelection.startTime || !singleSelection.endTime
                        ? null
                        : singleSelection.eventDate
                            .clone()
                            .hour(singleSelection.endTime.hour())
                            .minute(singleSelection.endTime.minute())
                            .second(0)
                            .millisecond(0);
                if (startTime && endTime) {
                    input.eventDates.push({ startTime, endTime });
                }
                break;
            case DateAndTimeType.MultipleEvents:
                multipleSelection.forEach((e) => {
                    const startTime = e.eventDate.clone().hour(e.startTime.hour()).minute(e.startTime.minute()).second(0).millisecond(0);
                    const endTime = e.eventDate.clone().hour(e.endTime.hour()).minute(e.endTime.minute()).second(0).millisecond(0);
                    input.eventDates.push({ startTime, endTime });
                });
                break;
            case DateAndTimeType.DateRange:
                const day = rangeSelection.startDate.clone();
                day.startOf("day");
                const end = rangeSelection.endDate.clone();
                end.startOf("day");
                for (day; day.isSameOrBefore(end.startOf("day")); day.add(1, "days")) {
                    if (rangeSelection.days.includes(day.day())) {
                        const startTime = day.clone().hour(rangeSelection.startTime.hour()).minute(rangeSelection.startTime.minute()).second(0).millisecond(0);
                        const endTime = day.clone().hour(rangeSelection.endTime.hour()).minute(rangeSelection.endTime.minute()).second(0).millisecond(0);
                        input.eventDates.push({ startTime, endTime });
                    }
                }
                break;
            default:
                break;
        }

        const errors = [];

        for (let i = 0; i < input.eventDates.length; i++) {
            const start = input.eventDates[i].startTime;
            const end = input.eventDates[i].endTime;
            if (!start || !end) {
                errors.push({ index: i, message: "Required!" });
                continue;
            }

            if (end.isBefore(start) || end.isSame(start)) {
                errors.push({ index: i, message: "End time must occur AFTER start time" });
            }

            for (let j = 0; j < input.eventDates.length; j++) {
                if (i === j || input.eventDates.length === 1) {
                    continue;
                }
                const start1 = input.eventDates[i].startTime;
                const end1 = input.eventDates[i].endTime;
                const start2 = input.eventDates[j].startTime;
                const end2 = input.eventDates[j].endTime;

                const range1 = moment.range(start1, end1);
                const range2 = moment.range(start2, end2);

                if (range1.overlaps(range2)) {
                    if (!errors.includes(j)) {
                        errors.push({ index: j, message: "Event date overlap!" });
                    }
                }
            }
        }

        if (input.eventDates.length === 0) {
            errors.push({ index: 0, message: "Required!" });
        }

        if (errors.length !== 0) {
            console.error("Form invalid", errors);
            setLoading(false);
            return;
        }
        //console.log(input);
        setPayload(input);
        setLoading(false);
    };

    const submitPayload = async () => {
        let newEvent = [];
        try {
            setLoading(true);

            
            const newEventC = await CreateProgramFromTemplate(payload);
            newEvent = newEventC.data.result
            //console.log(newEvent);
            onClose(true);
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoading(false);
            history.push({ pathname: `/events/active/event/${newEvent.id}`, state: { programName: newEvent.name } });
        }
    };

    const renderIsSeries = (values) => {
        
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Field
                            required
                            name="isSeries"
                            row
                            label="Is this Event a Series?"
                            fullWidth
                            component={FinalFormRadioGroup}
                            choices={[
                                { label: "Yes (Participants must attend all dates for the event)", value: true },
                                { label: "No (Each date is a separate event)", value: false },
                            ]}
                            
                            validate={required}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    {(values.isSeries === false || values.isSeries === 'false') && (
                        <Grid item xs={11}>
                            <Field
                                required
                                name="canAttendMultipleEvents"
                                row
                                label="Can participants attend multiple dates of this event?"
                                fullWidth
                                component={FinalFormRadioGroup}
                                choices={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                validate={required}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    const renderSingleEvent = (errors) => {
        const error = errors[0];
        return (
            <>
                <Grid container spacing={2} className={error ? classes.error : undefined}>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>Event Date</FormLabel>
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                disablePast
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="MM/DD/YY"
                                value={singleSelection.eventDate}
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                onChange={(date) => setSingleSelection((prev) => ({ ...prev, eventDate: date }))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>Start Time</FormLabel>
                            <KeyboardTimePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="hh:mm a"
                                orientation="landscape"
                                value={singleSelection.startTime}
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                keyboardIcon={<ScheduleIcon />}
                                minutesStep={15}
                                onChange={(date) => setSingleSelection((prev) => ({ ...prev, startTime: date }))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="auto" className={classes.dash} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <RemoveIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>End Time</FormLabel>
                            <KeyboardTimePicker
                                className={classes.datePicker}
                                variant="inline"
                                inputVariant="outlined"
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="hh:mm a"
                                orientation="landscape"
                                value={singleSelection.endTime}
                                minDateMessage="End time should not be before the start time"
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                keyboardIcon={<ScheduleIcon />}
                                minutesStep={15}
                                onChange={(date) => setSingleSelection((prev) => ({ ...prev, endTime: date }))}
                            />
                        </FormControl>
                    </Grid>
                    {error && (
                        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                            <FormHelperText style={{ color: theme.palette.error.main }}>{error}</FormHelperText>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    const renderMultipleEvents = (errors, values) => {
        return (
            <>
                {multipleSelection.map((item, index) => {
                    const error = errors[index];
                    return (
                        <React.Fragment key={index}>
                            <Grid container spacing={0} className={error ? classes.error : undefined}>
                                <Grid item xs={2}>
                                    <FormControl className={classes.margin} fullWidth style={{ paddingRight: 10 }}>
                                        <FormLabel className={classes.label}>Event Date</FormLabel>
                                        <KeyboardDatePicker
                                            className={classes.datePicker}
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            disablePast
                                            PopoverProps={{ className: classes.datePicker }}
                                            InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                            format="MM/DD/YY"
                                            value={item.eventDate}
                                            KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                            onChange={(date) => handleMultipleSelection(date, "eventDate", index)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl className={classes.margin} fullWidth>
                                        <FormLabel className={classes.label}>Start Time</FormLabel>
                                        <KeyboardTimePicker
                                            className={classes.datePicker}
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            PopoverProps={{ className: classes.datePicker }}
                                            InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                            format="hh:mm a"
                                            orientation="landscape"
                                            value={item.startTime}
                                            KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                            keyboardIcon={<ScheduleIcon />}
                                            minutesStep={15}
                                            onChange={(date) => handleMultipleSelection(date, "startTime", index)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto" className={classes.dash} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <RemoveIcon />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl className={classes.margin} fullWidth>
                                        <FormLabel className={classes.label}>End Time</FormLabel>
                                        <KeyboardTimePicker
                                            className={classes.datePicker}
                                            variant="inline"
                                            inputVariant="outlined"
                                            PopoverProps={{ className: classes.datePicker }}
                                            InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                            format="hh:mm a"
                                            orientation="landscape"
                                            value={item.endTime}
                                            minDateMessage="End time should not be before the start time"
                                            KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                            keyboardIcon={<ScheduleIcon />}
                                            minutesStep={15}
                                            onChange={(date) => handleMultipleSelection(date, "endTime", index)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    {multipleSelection.length > 1 && (
                                        <IconButton onClick={() => handleRemoveMultipleSelection(index)} style={{ marginTop: 20 }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                                {error && (
                                    <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                                        <FormHelperText style={{ color: theme.palette.error.main }}>{error}</FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    );
                })}
                <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                    <Grid item xs={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() =>
                                setMultipleSelection((prev) => {
                                    const arr = [...prev];
                                    arr.sort((a1, a2) => a1.eventDate.diff(a2.eventDate));
                                    const newDate = arr[arr.length - 1].eventDate.clone().add(1, "days");
                                    arr.push({
                                        eventDate: newDate,
                                        startTime: newDate.clone().add(1, "hours").startOf("hour"),
                                        endTime: newDate.clone().add(2, "hours").startOf("hour"),
                                    });
                                    return arr;
                                })
                            }
                        >
                            Add Date
                        </Button>
                    </Grid>
                </Grid>
                {renderIsSeries(values)}
            </>
        );
    };

    const renderRangeSelector = (errors, values) => {
        const error = errors[0];
        return (
            <>
                <Grid container spacing={2} className={error ? classes.error : undefined}>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>Start Date</FormLabel>
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                disablePast
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="MM/DD/YY"
                                value={rangeSelection.startDate}
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                onChange={(date) => setRangeSelection((prev) => ({ ...prev, startDate: date }))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="auto" className={classes.dash} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <RemoveIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>End Date</FormLabel>
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                disablePast
                                PopoverProps={{ className: classes.datePicker }}
                                minDate={rangeSelection.startDate}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="MM/DD/YY"
                                value={rangeSelection.endDate}
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                onChange={(date) => setRangeSelection((prev) => ({ ...prev, endDate: date }))}
                                minDateMessage={"End date should not be before start date"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>Start Time</FormLabel>
                            <KeyboardTimePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="hh:mm a"
                                orientation="landscape"
                                value={rangeSelection.startTime}
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                keyboardIcon={<ScheduleIcon />}
                                minutesStep={15}
                                onChange={(date) => setRangeSelection((prev) => ({ ...prev, startTime: date }))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs="auto" className={classes.dash} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <RemoveIcon />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={classes.margin} fullWidth>
                            <FormLabel className={classes.label}>End Time</FormLabel>
                            <KeyboardTimePicker
                                className={classes.datePicker}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                PopoverProps={{ className: classes.datePicker }}
                                InputProps={{ classes: { adornedEnd: classes.datePickerAdornedEnd } }}
                                format="hh:mm a"
                                orientation="landscape"
                                value={rangeSelection.endTime}
                                minDateMessage="End time should not be before the start time"
                                KeyboardButtonProps={{ className: classes.datePickerAdornment }}
                                keyboardIcon={<ScheduleIcon />}
                                minutesStep={15}
                                onChange={(date) => setRangeSelection((prev) => ({ ...prev, endTime: date }))}
                            />
                        </FormControl>
                    </Grid>
                    {error && (
                        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                            <FormHelperText style={{ color: theme.palette.error.main }}>{error}</FormHelperText>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2}>
                    {moment.weekdays().map((day, index) => (
                        <Grid item xs="auto" key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        classes={{ checked: classes.checked }}
                                        onChange={(e) => handleDayChange(e.target.checked, index)}
                                        checked={rangeSelection.days.includes(index)}
                                    />
                                }
                                label={day}
                            />
                        </Grid>
                    ))}
                </Grid>
                {renderIsSeries(values)}
            </>
        );
    };

    return (
        <SimpleDialog className={classes.addEventDialog} open={open} onClose={() => onClose(false)} title="Add Event" loading={loading}>
            <Form onSubmit={handleSave} initialValues={{ dateAndTimeType: dateAndTimeTypeValue(), name: selectedTemplate.name, spanishName: selectedTemplate.spanishName, isSeries: bool2String(selectedTemplate.isSeries), department: selectedTemplate.department, locationType: locationTypeValue(selectedTemplate.locationType), canAttendMultipleEvents: bool2String(selectedTemplate.canAttendMultipleEvents), seasonId: selectedTemplate.seasonId }} validate={handleDateValidation}>
                {({ handleSubmit, values, errors }) => {
                    
                    return (
                        <form className="addEventForm" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="name"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Event Name"
                                        id="name"
                                        maxLength={100}
                                        placeholder="Event Name"
                                        validate={required}
                                        
                                        
                                    />
                                    <Field
                                        component={FinalFormTextField}
                                        className={classes.sNameTextField}
                                        name="spanishName"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        label="Event Name (Spanish)"
                                        maxLength={100}
                                        placeholder="Event Name (Spanish)"
                                        
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Field
                                        component={FinalFormDropdown}
                                        name="department"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Department"
                                        id="name"
                                        choices={eventDepartmentOptions}
                                        placeholder="Select a Department..."
                                        validate={required}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FinalFormDropdown}
                                        name="seasonId"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        fullWidth
                                        label="Season"
                                        id="name"
                                        choices={seasons}
                                        placeholder="Select a Season..."
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        required
                                        name="locationType"
                                        row
                                        label="Location"
                                        fullWidth
                                        id="locationType"
                                        component={FinalFormRadioGroup}
                                        choices={eventLocationOptions}
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        row
                                        component={FinalFormRadioGroup}
                                        label="Date &amp; Time"
                                        name="dateAndTimeType"
                                        choices={dateTimeTypeOptions}
                                        required
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                            {values.dateAndTimeType === DateAndTimeType.DateRange.toString() && renderRangeSelector(errors, values)}
                            {values.dateAndTimeType === DateAndTimeType.MultipleEvents.toString() && renderMultipleEvents(errors, values)}
                            {values.dateAndTimeType === DateAndTimeType.Single.toString() && renderSingleEvent(errors)}
                            <DialogActions>
                                <LoadingButton color="primary" variant="contained" type="submit">
                                    Add Event
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
            {payload && (
                <ConfirmDialog
                    open={Boolean(payload)}
                    disablePortal
                    handleClose={() => setPayload(null)}
                    confirmAction={submitPayload}
                    confirmActionText="Confirm"
                    confirmButtonDisabled={payload.eventDates.length === 0}
                    maxWidth="md"
                    fullWidth
                    isSaving={loading}
                    title="Date Confirmation"
                    body={
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography
                                style={{ marginBottom: 10 }}
                                variant="h6"
                            >{`You are creating an event for the following ${payload.eventDates.length} date(s):`}</Typography>
                            <Box className={classes.eventDatesContainer}>
                                {payload.eventDates.map((date, index) => {
                                    return (
                                        <Box key={index} display="flex" alignItems="flex-start">
                                            <Typography variant="body1">
                                                {`${date.startTime.format("M/D/YYYY | h:mm a ")} - ${date.endTime.format(" h:mm a")}`}
                                            </Typography>
                                            <IconButton size="small" color="primary" onClick={() => handleRemoveDate(index)}>
                                                <CloseIcon color="primary" />
                                            </IconButton>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    }
                />
            )}
        </SimpleDialog>
    );
};

export default NewTemplateForm;
