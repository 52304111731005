import React, { Component } from "react";
import { ObjectivesForm, StaffMessage } from "./components";
import SimpleDialog from "../../common/simpleDialog";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Checkbox,  Button, Paper } from '@material-ui/core';

import { addObjective, getObjectives, updateObjective, deleteObjective, getObjectiveTags, getAdvisorList, sendStaffEmail } from "./api"
import Link from "@material-ui/core/Link";

import AdvisorContext from "../context/context";
import { Tabs, Tab } from '@mui/material';
import ReactTable from "react-table";
import "./index.css";

import moment from "moment";




const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});

function containsAll(arr, target) {
    return target.every(element => arr.includes(element));
}


function calculateAgeInMonths(dateString) {
    const contDate = new Date(dateString);
    const currentDate = new Date();

    let ageInMonths = (currentDate.getFullYear() - contDate.getFullYear()) * 12;
    ageInMonths -= contDate.getMonth();
    ageInMonths += currentDate.getMonth();


    if (currentDate.getDate() < contDate.getDate()) {
        ageInMonths--;
    }

    return ageInMonths;
}

function getDateColor(dateString) {
    if (dateString == null || dateString == '') {
        return '';
    }
    const age = calculateAgeInMonths(dateString);

    switch (age) {
        case 0:
            return 'black';
            break;
        case 1:
            return 'gold';
            break;
        case 2:
            return 'darkorange';
            break;
        case 3:
            return 'red';
            break;
        default:
            return 'red';
            break;

    }

}
class Objectives extends Component {
    static contextType = AdvisorContext;
    constructor(props) {
        super(props);

        this.state = {
            objectives: [],
            showAdd: false,
            tags: [],
            showTabs: 'hidden',
            showLanding: 'visible',
            showAdmin: 'hidden',
            tab: 0,
            selected: [],
            advisors: [],
            recCount: 0,
            showMessage: false
        };
    }



    componentDidMount= async() => {
        const obj = await getObjectives();
        const tags = await getObjectiveTags();
        
        let showTabs = 'hidden';
        if (this.context.myBasics.isAdmin) {
            showTabs = 'visible';
        }


        this.setState({ objectives: obj.data.result, tags: tags.data.result, showTabs: showTabs });
        
    }

    toggleMessage = (newRecipient) => {



        // Check if the recipient is already in the array
        const isRecipientPresent = this.state.selected.includes(newRecipient);

        // If the recipient is present, filter it out; if not, add it
        const updatedRecipients = isRecipientPresent
            ? this.state.selected.filter(recipient => recipient !== newRecipient)
            : [...this.state.selected, newRecipient];

        this.setState({ selected: updatedRecipients, recCount: updatedRecipients.length })

    }


    componentDidUpdate = async (prevProps, prevState) => {
        if (this.context.loaded && this.state.advisors.length == 0) {

            const subs = await getAdvisorList(this.context.myBasics.userProfile.id);
            
            this.setState({ advisors: subs.data.result })


        }


    }
    closeMessage = () => {
        this.setState({ showMessage: false });
    }
    closeDialog = () => {
        this.setState({ showAdd: false });
    }
    openDialog = () => {
        this.setState({ showAdd: true });
    }
    updateObjective = async (objective) => {
        await updateObjective(objective);
        const obj = await getObjectives();
        this.setState({ objectives: obj.data.result });
    }
   
    addObjective = async (objective) => {
        await addObjective(objective);
        const obj = await getObjectives();
        this.setState({ objectives: obj.data.result, showAdd: false });
    }
    deleteObjective = async (objective) => {
        await deleteObjective(objective);
        const obj = await getObjectives();
        this.setState({ objectives: obj.data.result });
    }

    setTab = (event, newVal) => {
        let showAdmin = "hidden";
        let showLanding = "visible";

        switch (newVal) {
            case 0:
                showLanding = "visible";
                showAdmin = "hidden";

                break;
            case 1:
                showLanding = "hidden";
                showAdmin = "visible";

                break;

            default:
                showLanding = "visible";
                showAdmin = "hidden";
                break;


        }

        this.setState({ tab: newVal, showLanding: showLanding, showAdmin: showAdmin });
    }
    handleMessaging = async () => {
        this.setState({ showMessage: true });
    }

    sendMessage = async (message) => {
      //  //console.log(message);
        await sendStaffEmail(message);
        this.setState({ showMessage: false });

    }

    handleSelectAll = async () => {
        let recip = this.state.selected;

        if (containsAll(recip, this.state.advisors)) {

/*            this.state.advisors.forEach(flt => {

                const tmp = recip.filter(element => !this.context.filteredCases.includes(element));
                recip = tmp;


            }) */
            recip = [];

        } else {

           /*  this.state.advisors.forEach(flt => {
                if (recip.indexOf(flt) == -1) {

                    recip.push(flt);
                }

            }) */

            recip = this.state.advisors;
        }

        
        this.setState({ recCount: recip.length, selected: recip })
    }



    render() {

        if (!this.context.loaded) {
            return <div></div>;
        }


        let columns = [
            {
                Header: () => (
                    <Checkbox
                        checked={containsAll(this.state.selected, this.state.advisors)}
                        onChange={this.handleSelectAll}
                    />
                ),
                accessor: "userProfileId",
                width: 50,
                Cell: row => (
                    <Checkbox
                        onChange={() => this.toggleMessage(row.original)}
                        checked={this.state.selected.includes(row.original)}
                    />
                ),

            },
            {
                Header: "ID",
                accessor: "userId",
                width: 100,
                Cell: row => (
                    <Link className="dataCell" style={{ textAlign: "center" }} onClick={() => this.props.openAdvisorObjectives(row.value)}>{row.original.userId}</Link>
                ),

            },

            {
                Header: "Advisor",
                accessor: "displayName",
                Cell: row => (
                    <Link className="dataCell" style={{ textAlign: "center" }} onClick={() => this.props.openAdvisorObjectives(row.original.userId)}>{row.original.displayName}</Link>
                ),

            },
            
            {
                Header: "Caseload",
                accessor: "userId",
                Cell: (row) => {

                    let lCont = "Caseload";
                    return <Link className="dataCell" style={{ display: "block", textAlign: "center" }} onClick={() => this.props.openCaseload(row.value)}>{lCont}</Link>;
                },
            },
                {
                    Header: "Service Log",
                accessor: "userId",
                Cell: (row) => {

                    let lCont = "Service Log";
                    return <Link className="dataCell" style={{ display: "block", textAlign: "center" }} onClick={() => this.props.openLog(row.value)}>{lCont}</Link>;
                },

            },
            {
                Header: "Milestones",
                accessor: "userId",
                width: 100,
                Cell: row => (
                    <Link className="dataCell" style={{ textAlign: "center" }} onClick={() => this.props.openAdvisorMilestones(row.value)}>Milestones</Link>
                ),

            },
            {
                Header: "Weekly Activity",
                accessor: "weeklyEntries",
                Cell: (row) => {

                    
                    return <div className="dataCell" style={{ display: "block", textAlign: "center" }} >{row.original.weeklyEntries}</div>;
                },

            },
            {
                Header: "Monthly Activity",
                accessor: "monthlyEntries",
                Cell: (row) => {

                  
                    return <div className="dataCell" style={{ display: "block", textAlign: "center" }}>{row.original.monthlyEntries}</div>;
                },

            },
            {
                Header: "Last Recorded Service Date",
                accessor: "lastService",
                Cell: (row) => {
                    const lContDate = row.value ? new Date(row.value) : null;
                    let lContDisplay = lContDate ? moment(lContDate).format("M/D/YY h:mm a") : "";
                    if (lContDate && lContDate < new Date("1/1/2005")) {
                        lContDisplay = "No Entry";
                    }
                    return <div className="dataCell" style={{ textAlign: "center", color: getDateColor(lContDate), fontWeight: 'bold' }}>{lContDisplay}</div>;
                },
            },


        ];



        return (<ThemeProvider theme={theme}>
            <br />
            <Link
                className={"dashboardLink"} onClick={() => this.props.gotoLocation("dashboard")}
            >
                &lt; Return to Dashboard
            </Link> <br /><br />
            <Grid container spacing={2}>
                <Grid item xs={12} className={this.state.showTabs}>
                    
                        
                    <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Search" variant="fullWidth"
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-evenly',
                                    },
                                    '.MuiTab-root': {
                                        border: '1px solid #000',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        marginRight: '4px',
                                        '&:last-child': {
                                            marginRight: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Tab label="Overview" />
                                <Tab label="Admin" />


                            </Tabs>
                </Grid>

                <Grid container spacing={2} className={this.state.showLanding} style={{ margin: "0px" }}>

                    <Grid item xs={2} >
                        {this.context.myBasics.userProfile.firstName} {this.context.myBasics.userProfile.lastName}
                        <br />
                        Advisor List

                    </Grid>
                    <Grid item xs={4} >


                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>

                        {this.state.recCount} <br />
                        Selected
                    </Grid>
                    <Grid item xs={2} >
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={this.handleMessaging}

                            aria-label="move selected up"
                            className="headerActionButton"
                        >
                            Message
                        </Button>

                    </Grid>
                    <Grid item xs={2} >


                    </Grid>

                    <Grid item xs={12}>



                        {
                            this.state.advisors.length > 0 ? (
                                <ReactTable
                                    data={this.state.advisors}
                                    minRows={0}
                                    columns={columns}
                                    defaultPageSize={50}
                                    className="-striped -highlight tableWrapper"
                                />) : <span className="tableWrapper">Loading...</span>}


                    </Grid>











                        </Grid>


                <Grid item xs={12} className={this.state.showAdmin}>
            <Button onClick={this.openDialog} variant="contained" color="primary">Add Objective</Button> <br/> 
            <Grid container spacing={2}>
                {this.state.objectives.map((option, index) => (
                    <Grid item xs={12} key={index}>
                        {/* Use Paper component to outline each item and provide elevation */}
                        <Paper elevation={2} style={{ padding: '20px', marginBottom: '20px' }}>
                            <ObjectivesForm
                                onUpdate={this.updateObjective}
                                tags={this.state.tags}
                                activeObjective={option}
                                onDelete={this.deleteObjective }
                            />
                        </Paper>
                    </Grid>
                ))}
                </Grid>
                </Grid>

            </Grid>
            <SimpleDialog open={this.state.showAdd} onClose={this.closeDialog} title="Objective" >
                
                <ObjectivesForm onAdd={this.addObjective} tags={this.context.taggingOptions} onClose={this.closeDialog} />
            </SimpleDialog>
            <SimpleDialog open={this.state.showMessage} onClose={this.closeMessage} title="Message" >

                <StaffMessage onSend={this.sendMessage} recips={this.state.selected} onClose={this.closeMessage} />
            </SimpleDialog>
        </ThemeProvider>

        );
    }

}

export default Objectives;