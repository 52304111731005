import React, { Component } from "react";

import { Form, Field } from "react-final-form";
import moment from 'moment-timezone';

import { FinalFormCheckbox, FinalFormTextField, FinalFormRadioGroup, FinalFormKeyboardTimePicker, FinalFormDropdown } from "../../../common/formWrappers";
import { toast } from "react-toastify";
import { FormattedMessage, injectIntl } from "react-intl";

import { required, renderField, renderRadioField, renderSelectList, email, phone, zip } from "../../../support/forms";
import Link from "@material-ui/core/Link";
import Button from "../../../support/components/Button";
import TimePicker from 'react-time-picker';
import * as api from '../apis';
import normalizePhone from "../../../support/logic/normalizePhone";

import { Gender } from "../../../admissions/logic/enums";
import enumHelper from "../../../support/logic/enumHelper";
import { languages } from "../../../support/languages";
import { loadCities } from "../../../redux/codeTables";
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { bindActionCreators } from "redux";
import { getList as getCities } from "../../../api/city";
import { getList as getSchools } from "../../../api/school";
import { states } from "../../../support/states";
import './index.css';
import 'react-time-picker/dist/TimePicker.css';




class GeneralInfoForm extends Component {


    state = {
        activeUser: [],
        cities: [],
        states: [],
        schools: []
    };
    componentDidMount = async () => {
        

        
        const convertISOToTimePickerFormat = (isoValue) => {
            if (!isoValue) return '';

            // Convert the ISO string to a moment object in UTC
            const momentDate = moment.utc(isoValue);

            // Convert the moment object to Eastern Time
            const momentDateEt = momentDate.tz('America/New_York');

            // Format the date to 24-hour "HH:mm" format
            return momentDateEt.format('HH:mm');
        };


        var users = await api.getProfile(this.props.id);
        const user = users.data.result
        // Convert the bestTimeCallStart and bestTimeCallEnd to the TimePicker format
        if (user.bestTimeCallStart) {
            user.bestTimeCallStart = convertISOToTimePickerFormat(users.data.result.bestTimeCallStart);
        }
        if (user.bestTimeCallEnd) {
            user.bestTimeCallEnd = convertISOToTimePickerFormat(users.data.result.bestTimeCallEnd);
        }
        if (user.languagePreference == null) {
            user.languagePreference = user.primaryLocale;
        }
        var cities = await getCities();
        var schools = await getSchools();
        this.setState({
            activeUser: user,
            schools: schools.data.result,
            cities: cities.data.result,
            states: states
        });
    }

    handleSubmit = async (values, form) => {
        // Function to check if the value already contains a date
        const appendDateToTime = (timeValue) => {
            const isoDateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})$/;
            const simpleTimePattern = /^(\d{2}:\d{2})$/;

            if (isoDateTimePattern.test(timeValue)) {
                // If the value is already in ISO 8601 format, return it as is
                return timeValue;
            } else if (simpleTimePattern.test(timeValue)) {
                // If the value is in simple time format, append today's date in EST and convert to UTC
                const timeWithTodayDateEst = moment.tz(`${moment().format('YYYY-MM-DD')}T${timeValue}:00`, 'America/New_York');
                return timeWithTodayDateEst.utc().format(); // Convert to UTC and format to ISO 8601 string
            } else {
                // If the value does not match any expected format, return it as is
                return timeValue;
            }
        };

        const bttcs = values.bestTimeCallStart;
        const bttce = values.bestTimeCallEnd;


        // Attach today's date to bestTimeCallStart and bestTimeCallEnd if not present
        if (values.bestTimeCallStart) {
            values.bestTimeCallStart = appendDateToTime(values.bestTimeCallStart);
        }
        if (values.bestTimeCallEnd) {
            values.bestTimeCallEnd = appendDateToTime(values.bestTimeCallEnd);
        }

        // Rest of your existing code...
        if (values.cityId) {
            values.city = this.state.cities.find(city => city.id === values.cityId).name;
        }
        values.primaryLocale = values.languagePreference;
        let fail = false;
        try {
            const res = await api.updateProfile(values);
            //console.log(res);

        } catch (ex) {
            //console.log(ex);

            // Check if ex.response and ex.response.data exist and access the error message
            const errorMessage = ex.response && ex.response.data && ex.response.data.error ? ex.response.data.error.message : 'An unexpected error occurred';

            fail = true;
            toast.error(errorMessage);
        }
        
        values.bestTimeCallStart = bttcs;
        values.bestTimeCallEnd = bttce;
        if (!fail) {
            toast.success("Update Saved");
        }
        
    }


    handleCityChange = (event) => {

        // Find the selected city using the event.target.value which is cityId
        const selectedCityId = event.target.value;
        const selectedCity = this.state.cities.find(city => city.id === selectedCityId);

        // Dispatch the change action to update the "city" field
        if (selectedCity) {

            var selectedUser = this.state.activeUser;
            selectedUser.city = selectedCity;
            this.setState({ activeUser: selectedUser });
            return true;
        }
    };
    genderOptions = enumHelper.getSelectOptionsList(Gender);
    render() {
        const verified = true;
        const schoolOptions = this.state.schools.map((school) => ({ value: school.id, label: school.name }));
        const noUser = this.state.activeUser.userName == null;
        return (
            <Form onSubmit={this.handleSubmit} initialValues={this.state.activeUser}>
                {({ handleSubmit, values, dirty, errors }) => {
                    
                    return (
                        <form onSubmit={handleSubmit} FormValues={this.state.activeUser} noValidate className="test">

                            <div className="genInfoFormWrapper">

                                <FormattedMessage id="events.profile" defaultMessage="[events.profile]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage}</span>)} />

                                {/* {isParent && <div className="parent-note">For parent users, only password edits will be saved.</div>} */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.firstName"
                                            defaultMessage="[First Name]"
                                            children={(formattedMessage) => (
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    component={renderField}
                                                    label={formattedMessage}
                                                    maxlength="50"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.middleName"
                                            defaultMessage="[Middle Name]"
                                            children={(formattedMessage) => (
                                                <Field name="middleName" type="text" component={renderField} label={formattedMessage} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormattedMessage
                                            id="app.lastName"
                                            defaultMessage="[Last Name]"
                                            children={(formattedMessage) => (
                                                <Field name="lastName" type="text" component={renderField} label={formattedMessage} />
                                            )}
                                        />
                                    </Grid>
                                
                                    <Grid item xs={12} sm={12}>
                                <FormattedMessage id="app.gender" defaultMessage="[app.gender]" tagName="span" />
                                <Field
                                    row
                                    name="gender"
                                    fullWidth
                                    format={(value) => {
                                        return value ? value.toString() : "0";
                                    }}
                                    parse={(value) => {
                                        return parseInt(value, 0);
                                    }}
                                    component={FinalFormRadioGroup}
                                    choices={this.genderOptions}
                                    required

                                />
                                {values.gender === Gender.Other && (
                                    <div>  <Field
                                        component={renderField}
                                        name="genderDescription"
                                        label="Description"
                                        placeholder="Description"
                                        margin="dense"

                                    /><br /></div>
                                )}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">Address</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.address1"
                                    defaultMessage="[Address Line 1]"
                                    children={(formattedMessage) => (
                                        <Field
                                            name="address1"
                                            type="text"
                                            component={renderField}
                                            label={formattedMessage}

                                            maxlength="100"

                                        />
                                    )}
                                        />
                                    </Grid><Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.address2"
                                    defaultMessage="[Address Line 2]"
                                    children={(formattedMessage) => (
                                        <Field name="address2" type="text" component={renderField} label={formattedMessage} maxlength="100" />
                                    )}
                                />
                                    </Grid><Grid item xs={12} sm={4}>
                                {(values.state != 'RI' || values.cityId == null) && (
                                    <FormattedMessage
                                        id="app.city"
                                                defaultMessage="[City]"
                                                fullWidth
                                        children={(formattedMessage) => (
                                            <Field name="city" component={renderField} label={formattedMessage} />
                                        )}
                                    />
                                    
                                )}
                                {(values.state == 'RI' && values.cityId != null) && (
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="app.city"
                                                    defaultMessage="[City]"
                                                    fullWidth
                                            children={(formattedMessage) => (
                                                <span className={"form-label"}>{formattedMessage}</span>
                                            )}
                                                />
                                        <Field
                                            name="cityId"
                                            component="select"
                                            label="City"

                                        >
                                            {this.state.cities.map((option) => {
                                                return (
                                                    <option key={option.cityCode} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                );

                                            })}
                                                </Field>
                                                <Field name="city" component="input" type="hidden" />
                                                
                                    </React.Fragment>

                                        )}
                                    </Grid><Grid item xs={12} sm={4}>
                                <span className={"form-label"}>State</span>
                                <Field name="state" label="State" component="select" >

                                    {this.state.states.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        );

                                    })}

                                </Field>
                                    </Grid><Grid item xs={12} sm={4}>



                                <Field name="zipCode" component={renderField} label="Zip Code" />
                                <br />

                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        {this.props.type == "Student" ? <Field
                                            component={FinalFormDropdown}
                                            name="schoolId"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            label="School"
                                            choices={schoolOptions}
                                            placeholder="Select a School..."
                                            required
                                            validate={required}
                                        /> : null}
                                

                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">Authentication</span>
                                </Grid>
                                        <Grid item xs={12} sm={4}>
                                <Field
                                    name="phoneNumber"
                                    component={renderField}
                                    label="Mobile Phone"
                                    placeholder="(xxx) xxx-xxxx"
                                    normalize={normalizePhone}

                                    disabled={false}
                                />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="userName" component={renderField} label="User Name" disabled={noUser} />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="email" component={renderField} label="Email" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}><span className="FormHeading">Password Reset</span></Grid>
                                        <Grid item xs={12} sm={6}>
                                
                                
                                    
                                        <Field name="password" disabled={noUser} component={renderField} type="password" label="Password" />
                                    </Grid><Grid item xs={12} sm={6}>
                                        <Field
                                            name="confirmPassword"
                                            disabled={noUser}
                                            component={renderField}
                                            type="password"

                                            label="Confirm Password"
                                        />
                                   </Grid>
                                    <Grid item xs={12} sm={12}> <div className="password-help">
                                        Leave blank to keep current password. Email must be verified first.
                                        <br />
                                        Passwords must be at least 8 characters long, and must contain a lowercase and uppercase letter, a number, and a special character
                                        ($&amp;*@! etc)
                                    </div>
                                        
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <span className="FormHeading">Medical</span>
                                        </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field name="allergies" component={renderField} label="Allergies" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="medications" component={renderField} label="Medications" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="medicalConditions" component={renderField} label="Medical Conditions" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="dietaryRestrictions" component={renderField} label="Dietary Restrictions" />
                                    </Grid><Grid item xs={12} sm={4}>
                                        <Field name="specialAccommodations" component={renderField} label="Special Accommodations" />
                                    </Grid>
                                <br />
                                        <Grid item xs={12} sm={12}>
                                <FormattedMessage id="events.contactPreferences.name" defaultMessage="[events.contactPreferences.name]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage}</span>)} />
                                </Grid>
                                        <Grid item xs={12} sm={4}> 
                                <FormattedMessage
                                    id="profile.languagePreference"
                                    defaultMessage="[All communication will be sent in the following language]"
                                    children={(formattedMessage) => (
                                        <div>
                                            <span class="form-label" title="Language Preference">{formattedMessage}</span>
                                            <Field
                                                name="languagePreference"
                                                component="select"
                                                label={formattedMessage}
                                                data={languages}
                                                className="medium-select"


                                            >
                                                <FormattedMessage
                                                    id="login.english"
                                                    defaultMessage="English"
                                                    children={(formattedMessage) => <option value="en">{formattedMessage}</option>}
                                                />
                                                <FormattedMessage
                                                    id="login.spanish"
                                                    defaultMessage="Spanish"
                                                    children={(formattedMessage) => <option value="es-MX">{formattedMessage}</option>}
                                                />
                                            </Field>
                                        </div>
                                    )}
                                />
                                    </Grid><Grid item xs={12} sm={4}>
                                <FormattedMessage id="profile.communicationPreference" defaultMessage="[profile.communicationPreference]" children={(formattedMessage) => (<span class="form-label" title="Language Preference">{formattedMessage}</span>)} />
                                        <Field name="communicationPreference" label="Contact Preference" component="select" disabled={ noUser} >
                                    <option value="">Select an option</option>

                                    <option key={'EMAIL'} value={'EMAIL'}>
                                        EMAIL
                                    </option>
                                    <option key={'TEXT'} value={'TEXT'}>
                                        TEXT
                                    </option>
                                    <option key={'TEXT AND EMAIL'} value={'TEXT AND EMAIL'}>
                                        TEXT AND EMAIL
                                    </option>


                                            </Field>
                                        </Grid><Grid item xs={12} sm={4}>
                                <FormattedMessage id="parent.bestTime" defaultMessage="[parent.bestTime]" children={(formattedMessage) => (<span class="form-label" title="Best Time to Call">{formattedMessage}</span>)} />
                                <div className="bestTimeToCallWrapper">
                                    <Field name="bestTimeCallStart" className="profileTimePicker">
                                        {props => (
                                            <div className="profileTimePicker">
                                                        <TimePicker key={props.input.value} name="bestTimeCallStart" value={props.input.value} onChange={props.input.onChange} format="h:mm a"/>
                                            </div>
                                        )}
                                                </Field>

                                    <span class="form-label" title="-">&nbsp; - &nbsp;</span>
                                    <Field name="bestTimeCallEnd" className="profileTimePicker">
                                        {props => (
                                            <div className="profileTimePicker">
                                                        
                                                        <TimePicker key={props.input.value} name="bestTimeCallEnd"  value={props.input.value} onChange={props.input.onChange} />

                                            </div>
                                        )}
                                                </Field>

                                            </div>
                                        </Grid>
                                <br />
                                <div className="admin-user-edit-buttons">

                                    <FormattedMessage
                                        id="Save"
                                        defaultMessage="[Save]"
                                        children={(formattedMessage) => (
                                            <Button
                                                className="margin-right"
                                                onClick={handleSubmit}
                                                id="Save"
                                                defaultMessage={formattedMessage}

                                            />
                                        )}
                                    />



                                    <Link id={'Cancel'} onClick={(e) => this.props.onClose(e)}  >
                                        Cancel
                                    </Link>



                                        </div>
                                
                                    </Grid>
                                
                                </div>
                        </form>
                    );
                }}
            </Form>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        loadCities: bindActionCreators(loadCities, dispatch),

    };
};

export default GeneralInfoForm;