import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import normalizePhone from "../../support/logic/normalizePhone";
import normalizeZip from "../../support/logic/normalizeZip";
import { required, renderField, renderRadioField, renderSelectList, email, phone, zip } from "../../support/forms";
import Button from "../../support/components/Button";
//import { isIE } from '../../support/browserSupport';
import { states } from "../../support/states";
import { grades } from "../../support/grades";
import { languages } from "../../support/languages";
import { educationLevels } from "../../support/education";
import { ApplicationStatusContext } from "../../support/context";

import ContactInfo from "../common/contactInfo";

import "./Form.css";
import { composeValidators } from "../../common/formValidators";

const radioStyle = { marginTop: "40px", minWidth: "57px" };
const primaryRadioStyle = { marginTop: "17px", minWidth: "57px" };

const inputField = ({ input, label, disabled, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <div className="parent-form-phone-field">
                    <span className="form-label" title={label}>
                        {label}
                    </span>

                    <input className="form-input" {...input} placeholder={label} type="text" disabled={disabled} />
                    {(touched || (context && context.isApplicationSubmitted && !context.isApplicationValid)) && error && (
                        <span className="form-input-error" style={{ maxWidth: 180 }}>
                            {error}
                        </span>
                    )}
                </div>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

export const requiredCity = (value) => {
    return value && value !== "Select City" ? undefined : (
        <FormattedMessage
            id="ThisFieldIsRequired"
            defaultMessage="required"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );
};

export const Form = ({
    handleSubmit,
    intl,
    onSave,
    onCancel,
    primaryPhone,
    secondaryPhone,
    primaryLevelEducation,
    cities,
    readonly,
    pristine,
    primaryHomeDisabled,
    primaryWorkDisabled,
    primaryMobileDisabled,
    secondaryHomeDisabled,
    secondaryWorkDisabled,
    secondaryMobileDisabled,
    isStaff,
    isMobileUser,
    secondaryGuardian,
    secMode
}) => {
    const yes = intl.formatMessage({ id: "Yes" });
    const no = intl.formatMessage({ id: "No" });
    const parsedPrimaryLevelEducation = primaryLevelEducation ? parseInt(primaryLevelEducation, 10) : 0;

    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);
    if (isMobileUser === undefined) {
        return null;
    }
    //console.log(secMode);
    return (
        <div className="parent-form" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && (
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <FormattedMessage
                                id="app.instruction"
                                defaultMessage="[Instructions]"
                                children={(formattedMessage) => <h5>{formattedMessage}</h5>}
                            />
                            <FormattedMessage id="parent.help" children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>} />
                            <ContactInfo />
                        </div>
                    )}
                    <div className="hr-line-medium" />
                    <FormattedMessage
                        id="app.primaryContact"
                        defaultMessage="[Primary contact]"
                        children={(formattedMessage) => (
                            <h2 className="text-color" style={{ marginBottom: 0 }}>
                                {formattedMessage}
                            </h2>
                        )}
                    />
                </Grid>
                <Grid item xs={12} className={`${isDesktop ? "desktop" : ""}`}>
                    <Field
                        id="primaryGuardian"
                        name="primaryGuardian"
                        component={renderRadioField}
                        items={[
                            { value: "1", text: "app.mother" },
                            { value: "2", text: "app.father" },
                            { value: "3", text: "app.legalGuardian" },
                        ]}
                        validate={[required]}
                        disabled={readonly}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={`${isDesktop ? "desktop" : ""}`}>
                    <FormattedMessage
                        id="app.firstName"
                        defaultMessage="[First Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryFirstName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                maxlength="50"
                                disabled={readonly}
                            />
                        )}
                    />

                    <FormattedMessage
                        id="app.middleName"
                        defaultMessage="[Middle Name]"
                        children={(formattedMessage) => (
                            <Field name="primaryMiddleName" type="text" component={renderField} label={formattedMessage} maxlength="50" disabled={readonly} />
                        )}
                    />
                    <FormattedMessage
                        id="app.lastName"
                        defaultMessage="[Last Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryLastName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                maxlength="50"
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className={`${isDesktop ? "desktop" : ""}`}>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.mobilePhone"
                            defaultMessage="[Mobile Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="primaryMobilePhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly || isMobileUser}
                                />
                            )}
                        />
                        <FormattedMessage
                            id="app.primary"
                            defaultMessage="[Primary]"
                            children={(formattedMessage) => (
                                <Box display="flex" flexDirection="column">
                                    <Box display="flex" flexDirection="column" ml={2}>
                                        <span style={{ fontWeight: "bold" }} title={formattedMessage}>
                                            {formattedMessage}
                                        </span>
                                        <Field
                                            name="primaryPhonePrimary"
                                            value="2"
                                            component="input"
                                            style={primaryRadioStyle}
                                            type="radio"
                                            disabled={readonly || primaryMobileDisabled}
                                        />
                                    </Box>
                                </Box>
                            )}
                        />
                    </Box>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.homePhone"
                            defaultMessage="[Home Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="primaryHomePhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <Field
                            name="primaryPhonePrimary"
                            value="1"
                            component="input"
                            style={radioStyle}
                            type="radio"
                            disabled={readonly || primaryHomeDisabled}
                            className="extra-left-margin"
                        />
                    </Box>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.workPhone"
                            defaultMessage="[Work Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="primaryWorkPhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <Field
                            name="primaryPhonePrimary"
                            value="3"
                            component="input"
                            style={radioStyle}
                            type="radio"
                            disabled={readonly || primaryWorkDisabled}
                            className="extra-left-margin"
                        />
                    </Box>
                </Grid>
                <Hidden only="xs">
                    <Grid item sm={5} style={isDesktop ? { display: "flex", justifyContent: "flex-end" } : {}}>
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <FormattedMessage
                                id="app.instruction"
                                defaultMessage="[Instructions]"
                                children={(formattedMessage) => <h5>{formattedMessage}</h5>}
                            />
                            <FormattedMessage id="parent.help" children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>} />
                            <ContactInfo />
                        </div>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={5}>
                    <FormattedMessage
                        id="app.address1"
                        defaultMessage="[Address Line 1]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryAddress1"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                maxlength="100"
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormattedMessage
                        id="app.address2"
                        defaultMessage="[Address Line 2]"
                        children={(formattedMessage) => (
                            <Field name="primaryAddress2" type="text" component={renderField} label={formattedMessage} maxlength="100" disabled={readonly} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormattedMessage
                        id="app.city"
                        defaultMessage="[City]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryCityId"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={cities}
                                validate={[requiredCity]}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormattedMessage
                        id="app.state"
                        defaultMessage="[State]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryState"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={states}
                                validate={[required]}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <FormattedMessage
                        id="app.zip"
                        defaultMessage="[Zip]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryZipCode"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required, zip]}
                                disabled={readonly}
                                normalize={normalizeZip}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormattedMessage
                        id="parent.defaultCommunicationMobile"
                        defaultMessage="[By default, the Primary contact will be sent all communications from Onward We Learn by Text Message to the mobile phone number provided above.]"
                        children={(formattedMessage) => (
                            <span className="parent-form-span-text " title={formattedMessage}>
                                {formattedMessage}
                            </span>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormattedMessage
                        id="login.email"
                        defaultMessage="[Email]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryEmail"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={isMobileUser ? email : composeValidators(required, email)}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormattedMessage
                        id="parent.communicationLanguage"
                        defaultMessage="[All communication will be sent in the following language]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryLocale"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={languages}
                                className="medium-select"
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormattedMessage
                        id="parent.levelEducation"
                        defaultMessage="[What is the highest level of education you have achieved]"
                        children={(formattedMessage) => (
                            <Field
                                name="primaryLevelEducation"
                                className="parent-form-margin-bottom medium-select"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={educationLevels}
                                validate={[required]}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    {parsedPrimaryLevelEducation === 1 ? (
                        <FormattedMessage
                            id="parent.levelEducationGrade"
                            defaultMessage="[Please enter up to which grade]"
                            children={(formattedMessage) => (
                                <Field
                                    name="primaryLevelEducationGrade"
                                    className="parent-form-margin-bottom small-select"
                                    component={renderSelectList}
                                    label={formattedMessage}
                                    data={grades}
                                    disabled={readonly}
                                />
                            )}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <FormattedMessage
                        id="parent.levelEducationGradeUS"
                        defaultMessage="[Was the highest level of education earned in the United States?]"
                        children={(formattedMessage) => (
                            <span className="parent-form-span" title={formattedMessage}>
                                {formattedMessage}
                            </span>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        id="parentPrimLevelEdUS"
                        name="primaryLevelEducationInUS"
                        className="parent-form-yes-no"
                        component={renderRadioField}
                        items={[
                            { value: "1", text: yes },
                            { value: "2", text: no },
                        ]}
                        validate={[required]}
                        disabled={readonly}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div className="hr-line-medium" />
                    <FormattedMessage
                        id="app.secondaryContact"
                        defaultMessage="[Secondary contact]"
                        children={(formattedMessage) => <h2 className="text-color">{formattedMessage}</h2>}
                    />
                </Grid>
                <Grid item xs={12} className={`${isDesktop ? "desktop" : ""}`}>
                    <Field
                        id="secondaryGuardian"
                        name="secondaryGuardian"
                        component={renderRadioField}
                        items={[
                            { value: "1", text: "app.mother" },
                            { value: "2", text: "app.father" },
                            { value: "3", text: "app.legalGuardian" },
                        ]}
                        disabled={readonly}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormattedMessage
                        id="app.firstName"
                        defaultMessage="[First Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="secondaryFirstName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                maxlength="50"
                                disabled={readonly}
                                validate={secondaryGuardian ? required : undefined}
                            />
                        )}
                    />
                    <FormattedMessage
                        id="app.middleName"
                        defaultMessage="[Middle Name]"
                        children={(formattedMessage) => (
                            <Field name="secondaryMiddleName" type="text" component={renderField} label={formattedMessage} maxlength="50" disabled={readonly} />
                        )}
                    />
                    <FormattedMessage
                        id="app.lastName"
                        defaultMessage="[Last Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="secondaryLastName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                maxlength="50"
                                disabled={readonly}
                                validate={secondaryGuardian ? required : undefined}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={5} className={`${isDesktop ? "desktop" : ""}`}>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.mobilePhone"
                            defaultMessage="[Mobile Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="secondaryMobilePhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <FormattedMessage
                            id="app.primary"
                            defaultMessage="[Primary]"
                            children={(formattedMessage) => (
                                <Box display="flex" flexDirection="column">
                                    <Box display="flex" flexDirection="column" ml={2}>
                                        <span style={{ fontWeight: "bold" }} title={formattedMessage}>
                                            {formattedMessage}
                                        </span>
                                        <Field
                                            name="secondaryPhonePrimary"
                                            value="2"
                                            component="input"
                                            style={primaryRadioStyle}
                                            type="radio"
                                            disabled={readonly || secondaryMobileDisabled}
                                        />
                                    </Box>
                                </Box>
                            )}
                        />
                    </Box>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.homePhone"
                            defaultMessage="[Home Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="secondaryHomePhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <Field
                            name="secondaryPhonePrimary"
                            value="1"
                            component="input"
                            style={radioStyle}
                            type="radio"
                            disabled={readonly || secondaryHomeDisabled}
                            className="extra-left-margin"
                        />
                    </Box>
                    <Box display="flex">
                        <FormattedMessage
                            id="app.workPhone"
                            defaultMessage="[Work Phone]"
                            children={(formattedMessage) => (
                                <Field
                                    name="secondaryWorkPhone"
                                    component={inputField}
                                    label={formattedMessage}
                                    validate={[phone]}
                                    normalize={normalizePhone}
                                    disabled={readonly}
                                />
                            )}
                        />
                        <Field
                            name="secondaryPhonePrimary"
                            value="3"
                            component="input"
                            style={radioStyle}
                            type="radio"
                            disabled={readonly || secondaryWorkDisabled}
                            className="extra-left-margin"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormattedMessage
                        id="app.address1"
                        defaultMessage="[Address Line 1]"
                        children={(formattedMessage) => (
                            <Field name="secondaryAddress1" type="text" component={renderField} label={formattedMessage} maxlength="100" disabled={readonly} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormattedMessage
                        id="app.address2"
                        defaultMessage="[Address Line 2]"
                        children={(formattedMessage) => (
                            <Field name="secondaryAddress2" type="text" component={renderField} label={formattedMessage} maxlength="100" disabled={readonly} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormattedMessage
                        id="app.city"
                        defaultMessage="[City]"
                        children={(formattedMessage) => (
                            <Field name="secondaryCityId" component={renderSelectList} label={formattedMessage} data={cities} disabled={readonly} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormattedMessage
                        id="app.state"
                        defaultMessage="[State]"
                        children={(formattedMessage) => (
                            <Field
                                name="secondaryState"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={states}
                                validate={[required]}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <FormattedMessage
                        id="app.zip"
                        defaultMessage="[Zip]"
                        children={(formattedMessage) => (
                            <Field
                                name="secondaryZipCode"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[zip]}
                                disabled={readonly}
                                normalize={normalizeZip}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div className="hr-line-medium" />

                    <div className="parent-form-buttons parent-form-top-space">
                        <FormattedMessage
                            id="Save"
                            defaultMessage="[Save]"
                            children={(formattedMessage) => (
                                <Button
                                    onClick={handleSubmit(onSave)}
                                    id="Save"
                                    defaultMessage={formattedMessage}
                                    invalid={readonly}
                                    className="margin-right"
                                />
                            )}
                        />
                        <Button className="link-button" id="Cancel" defaultMessage="[Cancel]" onClick={onCancel} invalid={pristine} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
    primaryPhone: PropTypes.string,
    secondaryPhone: PropTypes.string,
};

export default injectIntl(Form);
