import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector, Field, change } from "redux-form";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FontAwesome from "react-fontawesome";
import UserConfirmation from "../../../support/components/userConfirm";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { states } from "../../../support/states";
import normalizePhone from "../../../support/logic/normalizePhone";
import normalizeZip from "../../../support/logic/normalizeZip";
import IPEDLookup from "../../../common/ipedLookup";
import {
    renderField,
    required,
    renderRadioField,
    renderSelectList,
    email,
    phone,
    zip,
    DropdownDatePicker,
    requiredDateParts,
    stringToDateOfBirth,
    dateOfBirthFromFormToString,
    alphaNumeric,
} from "../../../support/forms";
import { UserType } from "../../../support/enums";

import "./StudentEdit.css";
import { injectIntl } from "react-intl";
import { getFerpaFormByUser } from "../../../api/form";
import { getAllActive } from "../../../api/institutions";
import { getList } from "../../../api/city";
import { getIpeds } from "../../../api/iped";
import { getData } from "../../../api/documents";
import b64toBlob from "../../../support/logic/b64toBlob";
import FileSaver from "file-saver";
import Spinner from "react-spinkit";
import { OTHER_INSTITUTION_ID } from "../../../support/constants";
import enumHelper from "../../../support/logic/enumHelper";
import { scholarshipLevel } from "../../enums";

const FORM_NAME = "STUDENT_EDIT";

const Checkbox = (props) => {
    return <input {...props.input} type="checkbox" checked={props.input.value} disabled={props.disabled} />;
};
const ssnAlienValidator = (value) => {
    const ssnPattern1 = /^\d{3}-\d{2}-\d{4}$/;
    const ssnPattern2 = /^\d{9}$/;
    const alienPattern1 = /^[aA]{1}\d{9}$/;
    const alienPattern2 = /^[aA]{1}\d{8}$/;

    return !value ||
        (value &&
            typeof value === "string" &&
            (ssnPattern1.test(value) || ssnPattern2.test(value) || alienPattern1.test(value) || alienPattern2.test(value))) ? undefined : (
        <span style={{ color: "red" }} title="Invalid SSN ###-##-#### or Alien A123456789">
            Invalid SSN ###-##-#### or Alien A123456789
        </span>
    );
};
const otherGenderValid = (data) => {
    const errors = {};

    if (data.gender === "2" && !data.genderDescription) {
        errors.genderDescription = "Required";
    }
    return errors;
};

class StudentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            ferpaDocumentId: null,
            ferpaFileName: this.formatStudentFullNameForFERPAFilename(),
            institutions: [],
            ipedList: [],
            cityList: []
        };
        this.stateOptions = [...states];
        this.stateOptions.unshift({ label: "-- STATE --", value: "" });
    }
    componentDidMount = async () => {
        this.props.initialize({ ...this.props.student, dateOfBirth: stringToDateOfBirth(this.props.student.dateOfBirth) });
        try {
            this.props.ajaxStatusLoading();
            const ferpaResponse = await getFerpaFormByUser(this.props.student.id);
            const institutionsResponse = await getAllActive();
            const cityResp = await getList();
            const ipedResp = await getIpeds();
            const institutions = institutionsResponse.data.result.map((i) => ({ label: i.name, value: i.id }));
            institutions.unshift({ label: "Select an institution", value: "" });
            this.setState({
                ferpaDocumentId: ferpaResponse.data.result?.documentId,
                institutions,
                ipedList: ipedResp.data.result,
                cityList: cityResp.data.result
            });
        } catch (error) {
            this.props.ajaxStatusError(error);
        } finally {
            this.props.ajaxStatusComplete();
        }
    };
    onSave = (values) => {
        this.props.onSave({ ...values, dateOfBirth: dateOfBirthFromFormToString(values.dateOfBirth, this.props.intl.locale) });
    };
    onDeceasedChange = (e) => {
        const value = e.target.checked;
        if (value === true) {
            this.setState({
                showConfirm: true,
            });
        }
    };
    onCancelDeceasedChange = () => {
        this.props.change("deceased", false);
        this.setState({ showConfirm: false });
    };

    handleDocumentClick = async () => {
        try {
            this.props.ajaxStatusLoading();
            const documentDataResponse = await getData(this.state.ferpaDocumentId);
            const blob = b64toBlob(documentDataResponse.data.result.data, documentDataResponse.data.result.mimeType);
            FileSaver.saveAs(blob, this.state.ferpaFileName);
        } catch (err) {
            this.props.ajaxStatusError(err);
        } finally {
            this.props.ajaxStatusComplete();
        }
    };

    formatStudentFullNameForFERPAFilename = () => {
        try {
            if (this.props.student) {
                return `${this.props.student.firstName.replace(/[^\w]/gi, "")}_${this.props.student.lastName.replace(/[^\w]/gi, "")}_ferpa.pdf`;
            }
        } catch (ex) {
            return "ferpa.pdf";
        }
    };

    render() {
        const { user, yearOfGraduation } = this.props;
        const type = user && user.userProfile ? user.userProfile.userType : null;
        const isStaff = type && (type === UserType.Admin || type === UserType.Finance) ? true : false;
        const readonly = !isStaff;
        const levelDisabled = yearOfGraduation < 2016;
        //console.log(this);
        return (
            <div className="scholarship-certify-student-edit">
                <div className="scholarship-certify-student-edit-row">
                    <h2>STUDENT INFORMATION</h2>
                    <div className="scholarship-spacer" />
                    <button className="close-button" onClick={this.props.onClose}>
                        <FontAwesome name="times" className="application-close" size="2x" />
                    </button>
                </div>
                <div className="scholarship-certify-student-edit-row">
                    <Field name="firstName" type="text" component={renderField} label="First Name" validate={[required]} disabled={readonly} maxlength="50" />
                    <Field name="middleName" type="text" component={renderField} label="Middle Name" disabled={readonly} maxlength="50" />
                    <Field name="lastName" type="text" component={renderField} label="Last Name" validate={[required]} disabled={readonly} maxlength="50" />
                    <div>
                        <span className="form-label">Date of Birth</span>
                        <Field
                            name="dateOfBirth"
                            label="Date of Birth"
                            component={DropdownDatePicker}
                            validate={[requiredDateParts]}
                            disabled={readonly}
                            intl={null}
                        />
                    </div>
                </div>
                <div className="scholarship-certify-student-edit-row">
                    <Field
                        name="ssnAlien"
                        type="text"
                        component={renderField}
                        label="SSN or Alien ID"
                        validate={[required, ssnAlienValidator]}
                        disabled={readonly}
                    />
                    <div className="gender-enclosure">
                        <span>Gender</span>
                        <Field
                            id="certifyGender"
                            name="gender"
                            label="Gender"
                            component={renderRadioField}
                            className="household-form--gender-radio"
                            items={[
                                { value: "0", text: "Male" },
                                { value: "1", text: "Female" },
                                { value: "2", text: "Other" },
                            ]}
                            skipContextCheck
                            validate={[required]}
                            disabled={readonly}
                        />
                    </div>

                    {this.props.gender === "2" ? (
                        <Field
                            name="genderDescription"
                            className="small-top-spacer"
                            type="text"
                            component={renderField}
                            placeholder="Gender (text entry)"
                            skipContextCheck
                            label="Gender (text entry)"
                            disabled={readonly}
                            maxlength="50"
                        />
                    ) : null}
                    <div className="deceased">
                        <Field name="deceased" component={Checkbox} onChange={this.onDeceasedChange} disabled={readonly} />
                        <span>Deceased</span>
                    </div>
                </div>
                <Field
                    className="scholarship-certify-student-edit-row level-type"
                    name="level"
                    component={renderSelectList}
                    label="Level"
                    data={enumHelper.getSelectOptionsList(scholarshipLevel)}
                    validate={[required]}
                    disabled={readonly || levelDisabled}
                />
                <p className="certify-edit--info">Level cannot be changed if year of graduation is before 2016</p>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", flex: 1 }}>
                        <div className="certification-status-container">
                            <h2>{this.props.student.certificationStatus}</h2>
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: 10 }}>
                                    <Field
                                        name="selectedInstitutionId"
                                        component={renderSelectList}
                                        data={this.state.institutions}
                                        label="Selected Institution"
                                        disabled={readonly}
                                    />
                                    {this.props.selectedInstitutionId === OTHER_INSTITUTION_ID && (
                                        <div style={{ marginRight: 10 }}>
                                            <div className="studentEditDropdownContainer">
                                                <span class="form-label studentEditDropdownLabel" title="Other Institution">Other Institution</span>
                                                <Field
                                                    name="institutionIPEDNo"
                                                    component="select"
                                                    label="Other Institution"
                                                    className="parent-form-city-state-state-field"
                                                    disabled={readonly}
                                                >
                                                    {this.state.ipedList.map((option) => {
                                                        return (
                                                            <option key={option.ipedNo} value={option.ipedNo}>
                                                                {option.name}
                                                            </option>
                                                        );

                                                    })}
                                                </Field>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <Field name="collegeId" type="text" component={renderField} label="College ID" maxlength="50" validate={[alphaNumeric]} disabled={readonly} />
                            </div>
                        </div>

                        <div className="scholarship-certify-student-edit-row">
                            <Field
                                name="address1"
                                type="text"
                                component={renderField}
                                label="Address 1"
                                validate={[required]}
                                disabled={readonly}
                                maxlength="100"
                            />
                            <Field name="address2" type="text" component={renderField} label="Address 2" disabled={readonly} maxlength="100" />
                        </div>
                        <div className="scholarship-certify-student-edit-row">
                            

                            {(this.props.student.state != 'RI' || this.props.student.cityId == null) && (
                                <Field
                                    className="parent-form-city-state-city-field"
                                    name="city"
                                    type="text"
                                    component={renderField}
                                    label="City"
                                    validate={[required]}
                                    maxlength="50"
                                    disabled={readonly}
                                />
                            )}

                            {(this.props.student.state == 'RI' && this.props.student.cityId != null) && (
                                <React.Fragment>
                                    <div className="studentEditDropdownContainer">
                                    <span class="form-label studentEditDropdownLabel" title="City">City</span>
                                    <Field
                                        name="cityId"
                                        component="select"
                                        label="City"
                                            className="parent-form-city-state-state-field"
                                            disabled={readonly}
                                    >
                                        {this.state.cityList.map((option) => {
                                            return (
                                                <option key={option.cityCode} value={option.id}>
                                                    {option.name}
                                                </option>
                                            );

                                        })}
                                        </Field>
                                    </div>
                                    <Field name="city" component="input" type="hidden" />

                                </React.Fragment>

                            )}
                            
                            <Field
                                className="parent-form-city-state-state-field"
                                name="state"
                                component={renderSelectList}
                                label="State"
                                data={this.stateOptions}
                                validate={[required]}
                                disabled={readonly}
                            />

                            <Field
                                className="parent-form-city-state-zip-field"
                                name="zipCode"
                                type="text"
                                component={renderField}
                                label="Zip Code"
                                validate={[required, zip]}
                                disabled={readonly}
                                normalize={normalizeZip}
                            />
                        </div>
                        <div className="scholarship-certify-student-edit-row ">
                            <Field name="email" type="text" component={renderField} label="Email" validate={[email]} disabled={readonly} />
                            <Field
                                name="phone"
                                component={renderField}
                                label="Primary Phone"
                                validate={[phone]}
                                normalize={normalizePhone}
                                disabled={readonly}
                                type="text"
                            />
                            <Field
                                className="phone-type"
                                name="phoneType"
                                component={renderSelectList}
                                label="Phone Type"
                                data={[
                                    { label: "Mobile", value: "1" },
                                    { label: "Home", value: "2" },
                                    { label: "Work", value: "3" },
                                ]}
                                validate={[required]}
                                disabled={readonly}
                            />
                        </div>
                    </div>

                    <div className="ferpa-form" style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <h2>Signed FERPA Form</h2>
                        {this.props.ajaxCallsInProgress === 0 && this.state.ferpaDocumentId ? (
                            <div
                                style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                role="button"
                                onClick={() => this.handleDocumentClick()}
                                onKeyPress={() => this.handleDocumentClick()}
                                tabIndex="0"
                                title={this.state.ferpaFileName}
                            >
                                <FontAwesome style={{ fontSize: 40, cursor: "pointer" }} name="file-text" />
                                <button className="link-button" href="#">
                                    Download
                                </button>
                            </div>
                        ) : this.props.ajaxCallsInProgress === 0 ? (
                            <span>
                                <em>Not Provided</em>
                            </span>
                        ) : (
                            <Spinner
                                name="line-scale"
                                color="#3cc6e8"
                                fadeIn="none"
                                style={{ position: "unset", top: "unset", left: "unset", transform: "unset" }}
                            />
                        )}
                    </div>
                </div>

                <div className="scholarship-certify-student-edit-row">
                    <button className="btn" onClick={this.props.handleSubmit(this.onSave)}>
                        Save
                    </button>

                    <button className="link-button" onClick={this.props.onClose}>
                        cancel
                    </button>
                </div>
                {this.state.showConfirm ? (
                    <UserConfirmation
                        message="Are you certain you would like to mark this student as deceased?"
                        title="Mark Student as Deceased"
                        yes="Yes"
                        cancel="No"
                        onCancel={this.onCancelDeceasedChange}
                        onConfirm={() => {
                            this.setState({ showConfirm: false });
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

StudentEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    student: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
    }),
    scholarshipId: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
};

StudentEdit = reduxForm({
    form: FORM_NAME,
    fields: ["gender", "genderDescription"],
    validate: otherGenderValid,
})(StudentEdit);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
    const gender = selector(state, "gender");
    const yearOfGraduation = selector(state, "yearOfGraduation");
    const selectedInstitutionId = selector(state, "selectedInstitutionId");
    return {
        gender,
        yearOfGraduation,
        selectedInstitutionId,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        change: bindActionCreators(change, dispatch),
    };
};

StudentEdit = connect(mapStateToProps, mapDispatchToProps)(StudentEdit);

StudentEdit = withAjaxStatus(StudentEdit);
StudentEdit = injectIntl(StudentEdit);

export default StudentEdit;
