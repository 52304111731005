import React from "react";
import { useStyles } from "./styles";

const ApprovedCard = ({ approvalDate }) => {
    const classes = useStyles();

    return (
        <div className={classes.card}>
            <span className={classes.greenLabel}>Approved</span>
            <small className={classes.italic}>{approvalDate}</small>
        </div>
    );
};

export default ApprovedCard;
