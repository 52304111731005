const useProgramCodeHelpers = () => {
    return {
        sortProgramCodesAsc: (codes) => {
            const sortedCodes = [...codes];
            return sortedCodes.sort((firstItem, secondItem) => firstItem.code - secondItem.code);
        },
        sortActivityCodesAsc: (codes) => {
            const sortedCodes = [...codes];
            return sortedCodes.sort((firstItem, secondItem) => firstItem.activityCode - secondItem.activityCode);
        },
        sortCoreOfferingsAsc: (codes) => {
            const sortedCodes = [...codes];
            return sortedCodes.sort((firstItem, secondItem) => firstItem.abbreviation - secondItem.abbreviation);
        },
    };
};
export default useProgramCodeHelpers;
