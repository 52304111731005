import React, { Component } from 'react';

class Roles extends Component {

    render(){
        return (
        <div>
            Coming Soon
        </div>
        );
    }
}

export default Roles;