import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ajaxStatusLoadingAction, ajaxStatusCompleteAction, ajaxStatusErrorAction, ajaxClearAll } from '../../../redux/ajax';

const withAjaxStatus = WrappedComponent => {
    class AjaxStatus extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapDispatchToProps = dispatch => ({
        ajaxStatusLoading: bindActionCreators(ajaxStatusLoadingAction, dispatch),
        ajaxStatusComplete: bindActionCreators(ajaxStatusCompleteAction, dispatch),
        ajaxStatusError: bindActionCreators(ajaxStatusErrorAction, dispatch),
        ajaxClearAll: bindActionCreators(ajaxClearAll, dispatch)
    });
    const mapStateToProps = state => ({
        ajaxCallsInProgress: state.ajaxCallsInProgress
    });
    AjaxStatus = connect(
        mapStateToProps,
        mapDispatchToProps
    )(AjaxStatus);

    return AjaxStatus;
};

export default withAjaxStatus;
