import React from "react";
import MaskedInput, { conformToMask } from "react-text-mask";
import { createNumberMask } from "text-mask-addons";

const DateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

const TimeMask = (val) => {
    if (val.length === 4) {
        return [/\d/, ":", /\d/, /\d/];
    }

    return [/\d/, /\d/, ":", /\d/, /\d/];
};
const ZipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
const EnrollmentCapMask = [/\d/, /\d/, /\d/, /\d/];
const WaitlistCapMask = [/\d/, /\d/];
const PhoneMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
const conformedPhone = (phoneNumber) => conformToMask(phoneNumber, PhoneMask, { guide: false }).conformedValue;
const DecimalNumberMask = createNumberMask({
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 2,
    allowLeadingZeroes: true,
    prefix: "",
});

const DateMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={DateMask}
            guide={false}
            placeholder="02/23/2010"
        />
    );
};

const TimeMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={TimeMask}
            guide={false}
            placeholder="HH:MM"
        />
    );
};

const ZipCodeMask = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={ZipMask}
            guide={false}
        />
    );
};

const EnrollmentCapMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={EnrollmentCapMask}
            guide={false}
        />
    );
};

const DecimalNumberMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={DecimalNumberMask}
            guide={false}
        />
    );
};

const WaitlistCapMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={WaitlistCapMask}
            guide={false}
        />
    );
};

const PhoneMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={PhoneMask}
            guide={false}
        />
    );
};

const NumberMaskInput = (props) => {
    const { inputRef, maxLength, ...other } = props;
    const numberMask = [];
    for (let i = 0; i < maxLength; i++) {
        numberMask.push(/\d/);
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={numberMask}
            guide={false}
        />
    );
};

export {
    DateMaskInput,
    TimeMaskInput,
    ZipCodeMask,
    EnrollmentCapMaskInput,
    PhoneMaskInput,
    conformedPhone,
    NumberMaskInput,
    WaitlistCapMaskInput,
    DecimalNumberMaskInput,
};
