import React, { useState } from "react";
import DocumentSection from "./documentSection";
import { injectIntl, FormattedMessage } from "react-intl";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";

import ContactInfo from "../common/contactInfo";
import { GroupType } from "../logic/enums";

import "./documents.css";

const Documents = ({ intl, admissions, readonly, isStaff }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);

    return (
        <div className="documents" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && (
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <div>
                                <FormattedMessage id="app.instruction" tagName="h5" />
                                <FormattedMessage id="documents.help" />
                            </div>
                            <ContactInfo />
                        </div>
                    )}
                    <div className="hr-line-medium" />
                    <FormattedMessage id="documents.header" defaultMessage="[Common Documents]" tagName="h2" />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormattedMessage id="documents.subHeader" defaultMessage="[Document]" tagName="h3" />
                    <DocumentSection
                        applicationId={admissions.id}
                        groupId={admissions.id}
                        groupType={GroupType.ProofOfResidency}
                        isStaff={isStaff}
                        description={() => (
                            <div>
                                <FormattedMessage id="documents.residency.description1" defaultMessage="[documents.residency.description1]" tagName="div" />
                                <div>
                                    <FormattedMessage id="documents.residency.description2" defaultMessage="documents.residency.description2">
                                        {(txt) => <div className="sub-item">{txt}</div>}
                                    </FormattedMessage>
                                    <FormattedMessage id="documents.residency.description3" defaultMessage="documents.residency.description3">
                                        {(txt) => <div className="sub-item">{txt}</div>}
                                    </FormattedMessage>
                                    <FormattedMessage id="documents.residency.description4" defaultMessage="documents.residency.description4">
                                        {(txt) => <div className="sub-item">{txt}</div>}
                                    </FormattedMessage>
                                </div>
                            </div>
                        )}
                        readonly={readonly}
                    />
                    <DocumentSection
                        applicationId={admissions.id}
                        groupId={admissions.id}
                        groupType={GroupType.ReducedLunch}
                        isStaff={isStaff}
                        description={() => (
                            <div>
                                <FormattedMessage id="documents.lunch.description1" defaultMessage="[documents.lunch.description1]" tagName="div" />
                            </div>
                        )}
                        readonly={readonly}
                    />
                </Grid>
                <Hidden only="xs">
                    <Grid item sm={4} style={{ display: "flex", flexDirection: "column" }}>
                        <div className="instructions" style={{ alignSelf: "flex-end" }}>
                            <div>
                                <FormattedMessage id="app.instruction" tagName="h5" />
                                <FormattedMessage id="documents.help" />
                            </div>
                            <ContactInfo />
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
};

export default injectIntl(Documents);
