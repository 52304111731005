import React, { Component, Fragment } from "react";
import { Form, Field } from 'react-final-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { FormattedMessage, injectIntl } from "react-intl";
import { scholarshipLevel } from "../../../scholarship/enums";
import * as api from './apis';
import { FinalFormCheckbox, FinalFormDropdown, FinalFormKeyboardDatePicker, FinalFormRadioGroup, FinalFormTextField } from "../../../common/formWrappers";
import { languages } from "../../../support/languages";
import { toast } from "react-toastify";
import { required, renderField, renderRadioField, renderSelectList, email, phone, zip } from "../../../support/forms";
import { Gender } from "../../../admissions/logic/enums";
import enumHelper from "../../../support/logic/enumHelper";
import { getList as getCities } from "../../../api/city";
import { getList as getSchools } from "../../../api/school";
import { states } from "../../../support/states";
import normalizePhone from "../../../support/logic/normalizePhone";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";



const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});



const renderTextField = ({ input, label, meta, multiline, rows, ...custom }) => (
    <TextField
        label={label}
        {...input}
        multiline={multiline}
        rows={rows}
        {...custom}
    />
);

const renderCheckbox = ({ input, label }) => (
    <FormControlLabel
        control={<Checkbox {...input} />}
        label={label}
        disabled
    />
);
class NonProgramStudent extends Component {
    

    state = {
        activeUser: [],
        cities: [],
        states: [],
        schools: []
    };
    componentDidMount = async () => {

       
        var cities = await getCities();
        var schools = await getSchools();
        this.setState({  schools: schools.data.result, cities: cities.data.result, states: states });

    }
    handleSubmit = async (values, form) => {
        if (values.cityId) {
            values.city = this.state.cities.find(city => city.id === values.cityId).name;
        }
        values.languagePreference = values.primaryLocale;
        await api.addNonProgram(values);
        toast.success("Student Saved");

    }
    handleCityChange = (event) => {

        // Find the selected city using the event.target.value which is cityId
        const selectedCityId = event.target.value;
        const selectedCity = this.state.cities.find(city => city.id === selectedCityId);

        // Dispatch the change action to update the "city" field
        if (selectedCity) {

            var selectedUser = this.state.activeUser;
            selectedUser.city = selectedCity;
            this.setState({ activeUser: selectedUser });
            return true;
        }
    };
    genderOptions = enumHelper.getSelectOptionsList(Gender);

    render() {
        const schoolOptions = this.state.schools.map((school) => ({ value: school.id, label: school.name }));


        return <ThemeProvider theme={theme}>
            <Form
                onSubmit={this.handleSubmit}
               
                render={({ handleSubmit, form, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <span className="FormHeading">Non Program Student</span>
                            </Grid>
                            <Field name="userProfileId" component="input" type="hidden" />
                            <Field name="id" component="input" type="hidden" />
                            <Field name="nonOnwardStudent" component="input" type="hidden" value={true}/>
                            <Field name="ssn" component="input" type="hidden" />
                            <Field name="alien" component="input" type="hidden" />
                            <Field name="sasId" component="input" type="hidden" />
                            <Field name="yearOfGraduation" component="input" type="hidden" />
                            <Field name="scholarshipLevel" component="input" type="hidden" />
                            <Field name="selectedInstitutionId" component="input" type="hidden" />
                            <Field name="otherInstitution" component="input" type="hidden" />
                            <Field name="collegeId" component="input" type="hidden" />
                            <Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.firstName"
                                    defaultMessage="[First Name]"
                                    children={(formattedMessage) => (
                                        <Field
                                            name="firstName"
                                            type="text"
                                            component={renderField}
                                            label={formattedMessage}
                                            maxlength="50"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.middleName"
                                    defaultMessage="[Middle Name]"
                                    children={(formattedMessage) => (
                                        <Field name="middleName" type="text" component={renderField} label={formattedMessage} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.lastName"
                                    defaultMessage="[Last Name]"
                                    children={(formattedMessage) => (
                                        <Field name="lastName" type="text" component={renderField} label={formattedMessage} />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormattedMessage id="app.gender" defaultMessage="[app.gender]" tagName="span" />
                                <Field
                                    row
                                    name="gender"
                                    fullWidth
                                    format={(value) => {
                                        return value ? value.toString() : "0";
                                    }}
                                    parse={(value) => {
                                        return parseInt(value, 0);
                                    }}
                                    component={FinalFormRadioGroup}
                                    choices={this.genderOptions}
                                    required

                                />
                                {form.gender === Gender.Other && (
                                    <div>  <Field
                                        component={renderField}
                                        name="genderDescription"
                                        label="Description"
                                        placeholder="Description"
                                        margin="dense"

                                    /><br /></div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <span className="FormHeading">Address</span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.address1"
                                    defaultMessage="[Address Line 1]"
                                    children={(formattedMessage) => (
                                        <Field
                                            name="address1"
                                            type="text"
                                            component={renderField}
                                            label={formattedMessage}

                                            maxlength="100"

                                        />
                                    )}
                                />
                            </Grid><Grid item xs={12} sm={4}>
                                <FormattedMessage
                                    id="app.address2"
                                    defaultMessage="[Address Line 2]"
                                    children={(formattedMessage) => (
                                        <Field name="address2" type="text" component={renderField} label={formattedMessage} maxlength="100" />
                                    )}
                                />
                            </Grid><Grid item xs={12} sm={4}>
                                {(form.state != 'RI' || form.cityId == null) && (
                                    <FormattedMessage
                                        id="app.city"
                                        defaultMessage="[City]"
                                        children={(formattedMessage) => (
                                            <Field name="city" component={renderField} label={formattedMessage} />
                                        )}
                                    />

                                )}
                                {(form.state == 'RI' && form.cityId != null) && (
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="app.city"
                                            defaultMessage="[City]"
                                            children={(formattedMessage) => (
                                                <span className={"form-label"}>{formattedMessage}</span>
                                            )}
                                        />
                                        <Field
                                            name="cityId"
                                            component="select"
                                            label="City"

                                        >
                                            {this.state.cities.map((option) => {
                                                return (
                                                    <option key={option.cityCode} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                );

                                            })}
                                        </Field>
                                        <Field name="city" component="input" type="hidden" />

                                    </React.Fragment>

                                )}
                            </Grid><Grid item xs={12} sm={4}>
                                <span className={"form-label"}>State</span>
                                <Field name="state" label="State" component="select" >

                                    {this.state.states.map((option) => {
                                        return (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        );

                                    })}

                                </Field>
                            </Grid><Grid item xs={12} sm={4}>



                                <Field name="zipCode" component={renderField} label="Zip Code" />
                                <br />

                            </Grid>

                            
                            
                            <Grid item xs={12} sm={4}>
                                <Field
                                    name="phoneNumber"
                                    component={renderField}
                                    label="Mobile Phone"
                                    placeholder="(xxx) xxx-xxxx"
                                    normalize={normalizePhone}

                                    disabled={false}
                                />
                            </Grid><Grid item xs={12} sm={4}>
                                <Field name="email" component={renderField} label="Email" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="en">
                                <Field component={FinalFormKeyboardDatePicker} name="dateOfBirth" label="DOB" validate={required} />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <span className="FormHeading">School</span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field
                                    component={FinalFormDropdown}
                                    name="schoolId"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="School"
                                    choices={schoolOptions}
                                    placeholder="Select a School..."
                                    required
                                    validate={required}
                                />


                            </Grid>
                            <Grid item xs={12} sm={4}>

                                <Field name="currentGrade" component={renderTextField} label="Grade" fullWidth />

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field name="studentId" component={renderTextField} label="Student ID" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <span className="FormHeading">Medical</span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field name="allergies" component={renderField} label="Allergies" />
                            </Grid><Grid item xs={12} sm={4}>
                                <Field name="medications" component={renderField} label="Medications" />
                            </Grid><Grid item xs={12} sm={4}>
                                <Field name="medicalConditions" component={renderField} label="Medical Conditions" />
                            </Grid><Grid item xs={12} sm={4}>
                                <Field name="dietaryRestrictions" component={renderField} label="Dietary Restrictions" />
                            </Grid><Grid item xs={12} sm={4}>
                                <Field name="specialAccommodations" component={renderField} label="Special Accommodations" />
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={12}>
                                <FormattedMessage id="events.contactPreferences.name" defaultMessage="[events.contactPreferences.name]" children={(formattedMessage) => (<span className="FormHeading">{formattedMessage}</span>)} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormattedMessage
                                    id="profile.languagePreference"
                                    defaultMessage="[All communication will be sent in the following language]"
                                    children={(formattedMessage) => (
                                        <div>
                                            <span class="form-label" title="Language Preference">{formattedMessage}</span>
                                            <Field
                                                name="primaryLocale"
                                                component="select"
                                                label={formattedMessage}
                                                data={languages}
                                                className="medium-select"


                                            >
                                                <FormattedMessage
                                                    id="login.english"
                                                    defaultMessage="English"
                                                    children={(formattedMessage) => <option value="en">{formattedMessage}</option>}
                                                />
                                                <FormattedMessage
                                                    id="login.spanish"
                                                    defaultMessage="Spanish"
                                                    children={(formattedMessage) => <option value="es-MX">{formattedMessage}</option>}
                                                />
                                            </Field>
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field name="gap" component={FinalFormCheckbox} label="GAP" type="checkbox"  />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field name="honorific" component={FinalFormCheckbox} label="Honorific" type="checkbox"  />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Field name="accelerate" component={FinalFormCheckbox} label="Accelerate" type="checkbox"  />
                            </Grid>



                            <Grid item xs={12}>
                                <Button type="submit" disabled={submitting || pristine} variant="contained" color="primary">
                                    Submit
                                </Button>
                                <Button type="button" onClick={form.reset} disabled={submitting || pristine} variant="contained" color="secondary">
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                    </form>
                )}
            />
        </ThemeProvider>;
    }
}

export default NonProgramStudent;