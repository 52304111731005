import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { useHistory, useParams } from "react-router-dom";
import createDecorator from "final-form-focus";
import { GradeCalculationType } from "../enums";
import { useProgramEvent, useInstitution, useSchool, useDistrict } from "../hooks";
import { saveEligibilityCriteria } from "../api/programEvents";
import LoadingIndicator from "../../common/loadingIndicator";
import useAjaxStatus from "../../common/useAjaxStatus";
import EligibilityCriteriaUi from "./eligibilityCriteriaUi"

const focusOnError = createDecorator();

const useStyles = makeStyles((theme) => ({

}));

const EligibilityCriteria = ({ setEligibleCriteriaMissing, isActive, topUser }) => {
    const classes = useStyles();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const { programEvent, loading: loadingProgramEvent, setProgramEvent } = useProgramEvent(id);
    const { institutionList, loading: loadingInstitutionList } = useInstitution();
    const { schoolList, loading: loadingSchoolList } = useSchool();
    const { districtList, loading: loadingDistrictList } = useDistrict();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const history = useHistory();
    const institutionNameById = institutionList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});
    const schoolNameById = schoolList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});
    const districtNameById = districtList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    const handleSave = async (values) => {
        const payload = {
            ...values,
            programEventId: id,
            gradeNumbers: values.gradeNumbers?.reduce(
                (total, curr) => (curr.selected ? [...total, { programEventId: id, gradeNumber: curr.value }] : total),
                []
            ),
            mathMin: parseInt(values.mathMin),
            mathMax: parseInt(values.mathMax),
            scienceMin: parseInt(values.scienceMin),
            scienceMax: parseInt(values.scienceMax),
            englishMin: parseInt(values.englishMin),
            englishMax: parseInt(values.englishMax),
            institutions: values.institutions?.reduce((total, curr) => (curr?.id ? [...total, { programEventId: id, institutionId: curr.id }] : total), []),
            schools: values.schools?.reduce((total, curr) => (curr?.id ? [...total, { programEventId: id, schoolId: curr.id }] : total), []),
            districts: values.districts?.reduce((total, curr) => (curr?.id ? [...total, { programEventId: id, districtId: curr.id }] : total), []),
            cohortNumbers: values.cohortNumbers.reduce(
                (total, curr) => (!!curr ? [...total, { programEventId: id, cohortNumber: parseInt(curr) }] : total),
                []
            ),
        };
        try {
            setLoading(true);
            const response = await saveEligibilityCriteria(payload);
            setProgramEvent((prev) => ({ ...prev, criteria: response.data.result }));
            toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoading(false);
            setEligibleCriteriaMissing(false);
            history.goBack();
        }
    };

    if (loading || loadingProgramEvent || loadingInstitutionList || loadingSchoolList || loadingDistrictList) {
        return <LoadingIndicator loading />;
    }

    if (programEvent == null) {
        return null;
    }

    return (
        <EligibilityCriteriaUi
            isLoading={loading || loadingProgramEvent || loadingInstitutionList || loadingSchoolList || loadingDistrictList}
            criteria={programEvent.criteria}
            name={programEvent.name}
            handleSave={handleSave}
            institutionNameById={institutionNameById}
            schoolNameById={schoolNameById}
            districtNameById={districtNameById}
            institutionList={institutionList}
            schoolList={schoolList}
            districtList={districtList}
            disabled={readOnly}
        />
    );
};

export default EligibilityCriteria;
