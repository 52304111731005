import React from "react";
import { EventDepartmentType } from "../../../enums";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import enumHelper from "../../../../support/logic/enumHelper";
import ApproveButton from "./approveButton";
import DepartmentButton from "./departmentButton";
import SubmittedCard from "./submittedCard";
import DateComponent from "../../../common/dateComponent/dateComponent";
import moment from "moment";
import { useStyles } from "./styles";
import ApprovedCard from "./approvedCard";

const NonSeriesTable = ({ rows, handleOnClickDepartment, openApprovingDialog, disabled }) => {
    const classes = useStyles();

    rows.forEach((row) => {
        row.date = `${moment(row.startTime).format("M/D/YYYY")}`;
        row.time = `${moment(row.startTime).format("h:mm a")} - ${moment(row.endTime).format("h:mm a")}`;
        row.submittedData = {
            submitted: row.advisorSubmitted,
            submittedBy: row.submittingUser?.fullName,
            submittedDate: `${moment(row.advisorSubmittedDate).format("M/D/YYYY")}`,
        };
    });

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.bold}>
                    <TableCell style={{ width: "25%" }}>Date</TableCell>
                    <TableCell>Location Type</TableCell>
                    <TableCell>Department</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Submitted?</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows
                    .sort((firstEl, secondEl) => new Date(firstEl.date) - new Date(secondEl.date))
                    .map((row, idx) => {
                        const { date, startTime, endTime, departments, missingProgramCodes, approved, approvalDate, submittedData, id, locationType } = row;
                        const _departments = [...departments];
                        const firstDepartment = departments[0];
                        _departments.shift();
                        return (
                            <React.Fragment key={idx}>
                                <TableRow>
                                    <TableCell rowSpan={_departments.length + 1}>
                                        <DateComponent date={{ startTime, endTime }} />
                                    </TableCell>
                                    <TableCell rowSpan={_departments.length + 1}>
                                        {locationType}
                                    </TableCell>
                                    <TableCell>
                                        {firstDepartment ? (
                                            <DepartmentButton
                                                department={enumHelper.getDisplayName(EventDepartmentType, firstDepartment.department)}
                                                greenCheck={firstDepartment.subcodeCreditHours.length === 0}
                                                handleOnClick={() => handleOnClickDepartment({ ...row, department: firstDepartment })}
                                            />
                                        ) : (
                                            <i>Attendance lists will display when at least 1 participant enrolls.</i>
                                        )}
                                    </TableCell>
                                    <TableCell align="center" rowSpan={_departments.length + 1}>
                                        {approved ? (
                                            <ApprovedCard approvalDate={moment(approvalDate).format("M/D/YYYY")} />
                                        ) : (
                                            <ApproveButton
                                                disabled={missingProgramCodes || departments.length === 0 || disabled}
                                                handleOnClick={() => openApprovingDialog(date, id)}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell rowSpan={_departments.length + 1}>
                                        <SubmittedCard submittedData={submittedData} />
                                    </TableCell>
                                </TableRow>

                                {_departments.map((department, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <TableRow>
                                                <TableCell>
                                                    <DepartmentButton
                                                        department={enumHelper.getDisplayName(EventDepartmentType, department.department)}
                                                        greenCheck={department.subcodeCreditHours.length === 0}
                                                        handleOnClick={() => handleOnClickDepartment({ ...row, department })}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default NonSeriesTable;
