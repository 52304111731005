import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import  TagItem from "./tag";

import withAjaxStatus from "../../../../support/hoc/withAjaxStatus";


class TagList extends Component {
    static permission = "Admin.Schools";
    

    render() {
        
        return (
            <div className="admin-institution-list">
                <ReactTable
                    data={this.props.tags}
                    minRows={0}
                    columns={[
                       {
                            Header: "",
                            accessor: "id",
                            minWidth: 30,
                            Cell: (row) => {
                                const tag = this.props.tags.find((element) => {
                                    return element.id === row.value;
                                });
                                
                                if (tag) {
                                    return <TagItem tag={tag} classifications={this.props.classifications} onUpdate={this.props.onUpdate} onSubTagUpdate={this.props.onSubTagUpdate} onAddSubtag={this.props.onAddSubtag} />;
                                } else {
                                    return <span></span>;
                                }
                                
                            },
                        },
                    ]}
                    defaultPageSize={50}
                    className="-striped -highlight"
                />
            </div>
        );
    }
}

TagList.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};

TagList = withAjaxStatus(TagList);
export default TagList;
