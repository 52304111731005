import React, { useEffect, useState, useRef } from "react";
import ProgramCodeList from "../programCodeDialog/programCodeList";
import { Form, Field } from "react-final-form";
import { FinalFormTextField } from "../../../../common/formWrappers";
import { toast } from "react-toastify";
import { required } from "../../../../common/formValidators";
import { DecimalNumberMaskInput } from "../../../../common/masks";
import { assignUserCreditHours, getSubcodeCreditHours } from "../../../api/programEvents";
import { getAttendanceBreakdown } from "../../../../api/tagging";
import { rawIt } from "../programCodeDialog/programCodeDialogHelpers";
import LoadingButton from "../../../../common/loadingButton";
import useAjaxStatus from "../../../../common/useAjaxStatus";
import { useStyles } from "./styles";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

const ServiceHoursForm = ({ closeServiceHoursDialog, userCreditHours, assigmentId, department, attendanceId, totalHours, setReloadAttendance, rowData, selectedUserId, tagWeights, id }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const [ recalculate, setRecalculate ] = useState(false);
    const [listProgramCodesData, setListProgramCodesData] = useState([]);
    const [initValues, setInitValues] = useState([]);

    const formApiRef = useRef();

    useEffect(() => {
        if (formApiRef.current) {
            formApiRef.current.change('totalHours', totalHours);
        }
    }, [totalHours]);
 

    useEffect( () => {

        //console.log(rowData);
        
        getAttendanceBreakdown(selectedUserId, rowData.department.programEventId, id ).then((resp) => {
           
            setInitValues(resp.data.result);
        }).catch((err) => {
            ajaxErrorWrapper(err);
        });
            getSubcodeCreditHours(assigmentId, department)
                .then((resp) => {

                    const _rawData = resp.data.result;
                   
                    _rawData.forEach((programCodeItem) => {
                        //console.log(_rawData)
                        
                        
                        const result = userCreditHours.filter((uch) => uch.id === programCodeItem.programSubCodeId);
                        programCodeItem.creditHours = result[0]?.creditHours || 0;
                    });
                    
                 
                    setListProgramCodesData(_rawData);
                })
                .catch((err) => {
                    ajaxErrorWrapper(err);
                });
       
       
    }, [ajaxErrorWrapper, assigmentId, department, userCreditHours]);

    const handleUpdate = async (values) => {
        const _values = { ...values };
        const totalAvailableCreditHours = _values.totalHours;
        delete _values.listProgramCodesData;
        delete _values.totalHours;
        const creditHours = Object.keys(_values).map((key) => ({
            programSubCodeId: key,
            creditHours: _values[key],
        }));

        const payload = { creditHours, totalAvailableCreditHours, id: attendanceId };

        try {
            const resp = await assignUserCreditHours(payload);


            setReloadAttendance((prev) => !prev);
            resp.data.success && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        closeServiceHoursDialog();
    };

    return (
        <Form
            mutators={{
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            onSubmit={handleUpdate}
            initialValues={{ totalHours, listProgramCodesData, ...initValues }}
            render={({ handleSubmit, form, values }) => {
               
                formApiRef.current = form;

                return (
                    <>
                        <FieldArray name="listProgramCodesData">
                            {({ fields }) => {
                              
                                
                                return (
                                    <>
                                        <ProgramCodeList form={form} programCodesRecord={fields.value} showEdit totalHours={totalHours} form={form} fields={fields} tagWeights={tagWeights} recalculate={recalculate} setRecalculate={setRecalculate} />

                                        <form noValidate onSubmit={handleSubmit}>
                                            {listProgramCodesData.length > 0 && (
                                                <div className={classes.footer}>
                                                    <div>
                                                        <Field
                                                            required
                                                            component={FinalFormTextField}
                                                            name="totalHours"
                                                            label="Attendance Credit Hours"
                                                            variant="outlined"
                                                            margin="dense"
                                                            validate={required}
                                                            inputComponent={DecimalNumberMaskInput}
                                                        />
                                                    </div>
                                                    <div className={classes.total}>
                                                        <span className={classes.formTotalHours}>
                                                            {totalHours} Hour(s)
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className={classes.saveButton}>
                                                <LoadingButton
                                                    
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={()=> setRecalculate(true) }
                                                >
                                                    Auto Calculate
                                                </LoadingButton>
                                            </div><div className={classes.saveButton}>
                                                <LoadingButton
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"

                                                >
                                                    Save
                                                </LoadingButton>
                                            </div>
                                        </form>
                                    </>
                                );
                            }}
                        </FieldArray>
                    </>
                );
            }}
        />
    );
};

export default ServiceHoursForm;
