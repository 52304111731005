import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { EMPTY_GUID } from "../../support/constants";

import { Form, Field } from "react-final-form";

import { FinalFormTextField } from "../../common/formWrappers";
import { DialogActions, Grid } from "@material-ui/core";
import { required } from "../../common/formValidators";
import LoadingButton from "../../common/loadingButton";
import { NumberMaskInput } from "../../common/masks";

const useStyles = makeStyles((theme) => ({
    activityCodeForm: {},
}));

const ActivityCodeForm = ({ activityCode, saveButtonText, activityCodeEditEnabled, onSubmit }) => {
    if (activityCode === null) {
        activityCode = {
            activityCode: "",
            description: "",
            shortDescription: "",
            id: EMPTY_GUID,
        };
    }

    const classes = useStyles();

    const handleFormSave = (values) => {
        onSubmit(values);
    };

    return (
        <Form onSubmit={handleFormSave} initialValues={activityCode}>
            {({ handleSubmit, values, errors }) => {
                return (
                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Field
                                    component={FinalFormTextField}
                                    name="activityCode"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Activity Code"
                                    id="activityCode"
                                    maxLength={4}
                                    placeholder="Activity Code"
                                    validate={required}
                                    disabled={!activityCodeEditEnabled}
                                    inputComponent={NumberMaskInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={FinalFormTextField}
                                    name="shortDescription"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Short Description"
                                    id="shortDescription"
                                    placeholder="Short Description"
                                    validate={required}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={FinalFormTextField}
                                    name="description"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label="Description"
                                    id="description"
                                    placeholder="Description"
                                    validate={required}
                                    multiline={true}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <LoadingButton color="primary" variant="contained" type="submit">
                                {saveButtonText}
                            </LoadingButton>
                        </DialogActions>
                    </form>
                );
            }}
        </Form>
    );
};
export default ActivityCodeForm;
