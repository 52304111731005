import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    footer: {
        marginTop: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    total: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    formTotalHours: {
        fontSize: "large",
        fontWeight: "bold",
    },
    saveButton: {
        marginTop: "16px",
        display: "flex",
        flexDirection: "row-reverse",
    },
    alert: {
        color: "red",
    },
});
