import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import moment from "moment";

import * as ApplicationApi from "../../../api/applications";
import { AdmissionsTabs, ApplicationStatus } from "../../../admissions/logic/enums";
import withAdmissions from "../../../support/hoc/withAdmissions";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import Button from "../../../support/components/Button";
import withAdmissionSettings from "../../../support/hoc/withAdmissionSettings";

class NavMenu extends React.Component {
    componentDidMount = () => {
        const { admissions, loadApplication } = this.props;

        if (admissions === null) {
            const id = this.getAppId();
            loadApplication(id);
        }
    };

    isTabComplete(tabName) {
        if (this.props.admissions === null) {
            return false;
        }

        return this.props.admissions.progress.tabProgress[tabName] === true;
    }

    renderStatus(tabName) {
        const complete = this.isTabComplete(tabName);

        if (complete) return <FontAwesome name="circle" className="complete" />;

        return <FontAwesome name="circle" />;
    }

    getAppId() {
        const parts = this.props.location.pathname.split("/");
        return parts.length >= 5 ? parts[4] : "";
    }

    onSubmitClick = async () => {
        if (this.props.admissions === null || this.props.readonly == true) {
            return;
        }

        if (this.props.admissions.progress.complete) {
            this.props.ajaxStatusLoading();
            try {
                await ApplicationApi.submitApplication(this.props.admissions.id);
                this.props.refreshApplication();
                this.props.ajaxStatusComplete();
            } catch (err) {
                this.props.ajaxStatusError(err);
            }
        } else {
            this.setState({ showInComplete: true });
        }
    };

    render() {
        const { admissions } = this.props;

        const isSubmitted =
            admissions !== null &&
            (admissions.status === ApplicationStatus.Submitted ||
                admissions.status === ApplicationStatus.VirtualOrientation ||
                admissions.status === ApplicationStatus.Complete ||
                admissions.status === ApplicationStatus.Accepted ||
                admissions.status === ApplicationStatus.ApplicantRejected);

        const submitDate = admissions !== null && admissions.submissionDate !== null ? moment(admissions.submissionDate).format("MM/DD/YYYY h:mm a") : "";
        const submitEnabled = admissions !== null && admissions.progress.complete;
        const id = this.getAppId();

        const showVirtualOrientationNav =
            admissions !== null &&
            admissions.didVirtualOrientation &&
            admissions.status !== ApplicationStatus.NotSubmitted &&
            admissions.status !== ApplicationStatus.Submitted &&
            admissions.status !== ApplicationStatus.Rejected;
        //console.log(this);
        return (
            <div className="nav-menu" style={{ marginTop: "5px" }}>
                <div>
                    <NavLink to={`/applications/application/student/${id}/`} activeClassName="selected">
                        <div className="nav-menu__nav-item">
                            <span>Student Information</span>
                        </div>
                        {this.renderStatus(AdmissionsTabs.Student)}
                    </NavLink>
                    <NavLink to={`/applications/application/parent/${id}`} activeClassName="selected">
                        <div className="nav-menu__nav-item">
                            <span>Parent/Guardian Information</span>
                        </div>
                        {this.renderStatus(AdmissionsTabs.Parent)}
                    </NavLink>
                    <NavLink to={`/applications/application/household/${id}`} activeClassName="selected">
                        <div className="nav-menu__nav-item">
                            <span>Household Information</span>
                        </div>
                        {this.renderStatus(AdmissionsTabs.Household)}
                    </NavLink>
                    <NavLink to={`/applications/application/documents/${id}`} activeClassName="selected">
                        <div className="nav-menu__nav-item">
                            <span>Common Documents</span>
                        </div>
                        {this.renderStatus(AdmissionsTabs.CommonDocs)}
                    </NavLink>
                    {!this.props.admissionSettings.orientationVideoEnabled && (
                        <NavLink to={`/applications/application/contracts/${id}`} activeClassName="selected">
                            <div className="nav-menu__nav-item">
                                <span>Contracts</span>
                            </div>
                            {this.renderStatus(AdmissionsTabs.Contracts)}
                        </NavLink>
                    )}
                    {showVirtualOrientationNav && (
                        <>
                            <NavLink to={`/applications/application/orientationvideo/${id}`} className="nav-menu__extra" activeClassName="selected">
                                <div className="nav-menu__nav-item">
                                    <span>Orientation Video</span>
                                </div>
                                {this.renderStatus(AdmissionsTabs.OrientationVideo)}
                            </NavLink>
                            <NavLink to={`/applications/application/contracts/${id}`} className="nav-menu__extra" activeClassName="selected">
                                <div className="nav-menu__nav-item">
                                    <span>Contracts</span>
                                </div>
                                {this.renderStatus(AdmissionsTabs.Contracts)}
                            </NavLink>
                        </>
                    )}
                </div>
                <div>
                    {isSubmitted && (
                        <div className="submit-message">
                            <span>Submitted on</span>
                            <span>: {submitDate}</span>
                        </div>
                    )}
                    {!isSubmitted && (
                        <Button
                            id="student.submit"
                            defaultMessage="Submit"
                            className={`submit-button ${submitEnabled && !this.props.readonly ? "" : "disabled"}`}
                            onClick={this.onSubmitClick}
                            disabled={!submitEnabled || this.props.readonly }
                        />
                    )}
                </div>
            </div>
        );
    }
}

NavMenu = withAdmissions(NavMenu);
NavMenu = withAdmissionSettings(NavMenu);
NavMenu = withAjaxStatus(NavMenu);
NavMenu = withRouter(NavMenu);

export default NavMenu;
