import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Left from "./components/Left";
import CurrentCrusaders from "./components/CurrentCrusaders";
import Instructions from "./components/Instructions";

import Button from "../../support/components/Button";

import "./Form.css";

const Form = ({ handleSubmit, change, onSave, onCancel, primaryLanguage, readonly, match, pristine }) => {
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const [showInstructions, setShowInstructions] = useState(false);
    const intl = useIntl();

    return (
        <div className="household-container" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            <div className="hr-line-medium" />
            <FormattedMessage id="nav.householdOnly" defaultMessage="[Household]" tagName="h2" />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && <Instructions isDesktop={isDesktop} />}
                    <Left primaryLanguage={primaryLanguage} change={change} readonly={readonly} />
                </Grid>
                <Grid item xs={12} sm={4}>
                   
                </Grid>
                <Hidden only="xs">
                    <Grid item sm={4} style={isDesktop ? { display: "flex", justifyContent: "flex-end" } : {}}>
                        <Instructions isDesktop={isDesktop} />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <div className="hr-line-medium" />

                    <div className="parent-form-buttons">
                        <FormattedMessage
                            id="Save"
                            defaultMessage="[Save]"
                            children={(formattedMessage) => (
                                <Button
                                    className="margin-right"
                                    onClick={handleSubmit(onSave)}
                                    id="Save"
                                    defaultMessage={formattedMessage}
                                    invalid={readonly}
                                />
                            )}
                        />
                        <Button className="link-button" id="Cancel" defaultMessage="[Cancel]" onClick={onCancel} invalid={pristine} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    primaryLanguage: PropTypes.number,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }),
};

export default Form;
