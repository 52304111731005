import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import * as propTypes from "../propTypes";

import withUser from "../../support/hoc/withUser";
import NavigationHeader from "./components/NavigationHeader";
import { SecurityContext } from "../../support/security";

import Summary from "./summary";
import PayHistory from "./payhistory";
import NotCertified from "./notcertified";
import Certification from "./certification";

class Reports extends Component {
  render() {
    return (
      <SecurityContext.Consumer>
        {context => {
          return (
            <div className="scholarship-reports">
              <NavigationHeader />
              <Route
                exact
                path="/scholarship/reports"
                render={() =>
                  context.hasReadPermission("Scholarship.Reports") ? (
                    <Redirect to="/scholarship/reports/summary" />
                  ) : (
                    <Redirect to="/scholarship/reports/certification" />
                  )
                }
              />
              <Route path="/scholarship/reports/summary" render={() => <Summary scholarship={this.props.scholarship} />} />
              <Route path="/scholarship/reports/payhistory" render={() => <PayHistory scholarship={this.props.scholarship} />} />
              <Route path="/scholarship/reports/certification" render={() => <Certification scholarship={this.props.scholarship} />} />
              <Route path="/scholarship/reports/not-certified" render={() => <NotCertified scholarship={this.props.scholarship} />} />
            </div>
          );
        }}
      </SecurityContext.Consumer>
    );
  }
}

Reports.propTypes = {
  scholarship: propTypes.scholarship,
  user: PropTypes.shape({
    userProfile: PropTypes.shape({
      userType: PropTypes.number
    })
  })
};

Reports = withUser(Reports);
Reports = withRouter(Reports);
export default Reports;
