import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCodeTablesAction } from '../../../redux/codeTables';

const withCodeTables = WrappedComponent => {
    class CodeTables extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        schools: state.schools
    });

    const mapDispatchToProps = dispatch => ({
        loadCodeTables: bindActionCreators(loadCodeTablesAction, dispatch)
    });

    CodeTables = connect(
        mapStateToProps,
        mapDispatchToProps
    )(CodeTables);
    return CodeTables;
};

export default withCodeTables;
