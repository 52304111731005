import React from "react";
import { FormattedMessage } from "react-intl";

export const grades = [
  {
    value: "0",
    label: (
      <FormattedMessage
        id="app.select"
        defaultMessage="[-select-]"
        children={(formattedMessage) => {
          return `-${formattedMessage}-`;
        }}
      />
    ),
  },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
];

export const crusaderGrades = [
  {
    value: "0",
    label: (
      <FormattedMessage
        id="app.select"
        key="app.select.grade"
        defaultMessage="[-select-]"
        children={(formattedMessage) => {
          return `-${formattedMessage}-`;
        }}
      />
    ),
  },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
];
