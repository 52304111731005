import axios from "axios";

export function getCompactFormHtml(locale) {
  return axios.get("/api/services/app/Contract/GetCompactFormHtml", { params: { locale } });
}

export function getMembershipFormHtml(locale) {
  return axios.get("/api/services/app/Contract/GetMembershipFormHtml", { params: { locale } });
}

export function signCompactAndMembershipForm(input) {
  return axios.post("/api/services/app/Contract/SignCompactAndMembershipForm", input);
}
