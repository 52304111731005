const enumHelper = {
    getDisplayName(enumDefinition, value) {
        const valueInt = parseInt(value, 10);
        let associatedKey = '';

        for (const name of Object.keys(enumDefinition)) {
            if (enumDefinition[name] === valueInt) {
                associatedKey = name;
                break;
            }
        }

        return enumDefinition._displayNames && enumDefinition._displayNames[value] ? enumDefinition._displayNames[value] : associatedKey;
    },

    getLocalizationId(enumDefinition, value) {
        const valueInt = parseInt(value, 10);
        let associatedKey = '';

        for (const name of Object.keys(enumDefinition)) {
            if (enumDefinition[name] === valueInt) {
                associatedKey = name;
                break;
            }
        }

        return enumDefinition._localizationIds && enumDefinition._localizationIds[value] ? enumDefinition._localizationIds[value] : associatedKey;
    },

    getSelectOptionsList(enumDefinition, getLocalizationId, defaultValue) {
        let output = [];

        for (let property in enumDefinition) {
            if (enumDefinition.hasOwnProperty(property) && !property.startsWith('_')) {
                output.push({
                    value: enumDefinition[property],
                    label: getLocalizationId
                        ? this.getLocalizationId(enumDefinition, enumDefinition[property])
                        : this.getDisplayName(enumDefinition, enumDefinition[property])
                });
            }
        }

        if (defaultValue) {
            output.unshift({ value: '', label: defaultValue });
        }

        return output;
    }
};

export default enumHelper;
