import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import {  Typography} from '@material-ui/core';

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';



class ObjectivesGraph extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false,
            barData: []


        };
    }



    componentDidMount() {
        if (this.context.loaded && !this.state.loaded) {

            this.calculateData();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.context.loaded && !this.state.loaded ) {
           
            this.calculateData();
        }
    }


    calculateData = () => {
        let data = [];
        let barData = [];
        
        let matched = 0;
        let unmatched = 0;

        const obj = [];
        const uniqueIdentifiers = new Set();

        const addUniqueObjective = (objective) => {
            const identifier = `${objective.tagsId}|${objective.subtagId}|${objective.objectiveId}`;
            if (!uniqueIdentifiers.has(identifier)) {
                uniqueIdentifiers.add(identifier);
                obj.push(objective);
            }
        };
        
        
        
        this.context.myBasics.objectives.forEach(ob => {
            const eligible = this.context.myCases.filter((flt) => flt.studentProfile.currentGrade == ob.minGrade && flt.allAdvisors != null && flt.allAdvisors.some(advisor => advisor.userId == this.context.myBasics.userProfile.id) );
            const inProgram = eligible.filter((flt) =>
                flt.studentContactInfo.tags.some(tag =>
                    tag.tagId === ob.tagsId && tag.subTagId === ob.subtagId)
            );
            barData.push({
                name: ob.displayName,
                Meeting: inProgram.length,
                Short: eligible.length - inProgram.length,
                amt: eligible.length
            });


            matched = matched + inProgram.length;
            unmatched = unmatched + (eligible.length - inProgram.length)

        });

        this.context.myBasics.subordinates.forEach(sub => {
            sub.objectives.forEach(ob => {
                
                const eligible = this.context.myCases.filter((flt) => flt.studentProfile.currentGrade == ob.minGrade && flt.allAdvisors != null &&  flt.allAdvisors.some(advisor => advisor.userId == sub.userProfileId) );
                const inProgram = eligible.filter((flt) =>
                    flt.studentContactInfo.tags.some(tag =>
                        tag.tagId === ob.tagsId && tag.subTagId === ob.subtagId)
                );
                barData.push({
                    name: ob.displayName,
                    Meeting: inProgram.length,
                    Short: eligible.length - inProgram.length,
                    amt: eligible.length
                });


                matched = matched + inProgram.length;
                unmatched = unmatched + (eligible.length - inProgram.length)

            });
        });
    

        

        


        data.push({
            name: 'Meeting Objectives',
            value: matched,
            fill: '#123225'
        });

        data.push({
            name: 'Not Meeting Objectives',
            value: unmatched,
            fill: '#e2a331'
        });
        
        /*
        this.context.gradeList.forEach((gra) => {
            const total = this.context.myCases.filter((flt) => flt.studentProfile.currentGrade == gra.value);
            if (total.length > 0) {
                const contacted = total.filter((flt) => flt.studentContactInfo.contacted == true);
                data.push({
                    name: 'Grade ' + gra.name,
                    Contacted: contacted.length,
                    Uncontacted: total.length - contacted.length,
                    amt: total.length,
                    fill:
                });
            }
           
        })
        */
        this.setState({ data: data, loaded: true, barData: barData });
    }

    renderBarChart = () =>
    {

        return (
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={150}
                height={150}
                data={this.state.barData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                
                <Bar dataKey="Meeting" fill="#123225"  />
                <Bar dataKey="Short" fill="#e2a331"  />
            </BarChart>
            </ResponsiveContainer>
        );
    }


    renderPieChart = () => {
        const renderCustomLabel = ({ name, percent }) => {
            return `${name}: ${(percent * 100).toFixed(0)}%`;
        };
        return (
            <ResponsiveContainer width="100%" height="100%">
            <PieChart >
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={this.state.data}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#123225"
                    label={renderCustomLabel} // Using the custom label function
                    labelLine={false} // Optional: if you don't want the lines from sectors to labels
                />
                <Tooltip />
            </PieChart>
                </ResponsiveContainer>
        );
    }

    renderPercentage = () => {
        let total = 0;
        let achieved = 0;
        if (this.state.data.length > 1) { // Corrected typo here from `lenght` to `length`
            total = this.state.data[0].value + this.state.data[1].value;
            achieved = this.state.data[0].value;
        }
       ;
        let percentage = 0;
        try {
            if (total != 0) {
                percentage = ((achieved / total) * 100).toFixed(2);
            }
            
        } catch {

        }
        
        if (!this.state.loaded) {
            return (
                <div></div>
            );
        }
        return (
            <Typography variant="h5" color="primary" gutterBottom>
                {percentage}%
            </Typography>
        );
    }


    render() {


        return (
            <div style={{
                display: 'flex', // Use flexbox layout
                flexDirection: 'column', // Stack children vertically
                height: '100%', // Take the full height available
                justifyContent: 'flex-start', // Start alignment at the top for the first child
            }}>
                <div style={{
                    alignSelf: 'flex-start', // Align to the start of the flex container
                    width: '100%', // Ensure the div takes the full width
                    textAlign: 'center', // Center text horizontally
                    marginBottom: '0', // Remove any default bottom margin
                    paddingTop: '10px', // Space from the top of the container
                }}>Objectives Met</div>
                <div style={{
                    flex: 1, // Take up all available space
                    display: 'flex', // Use flexbox layout
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'center', // Center vertically in the remaining space
                    textAlign: 'center', // Center text horizontally
                }}>
                    {this.renderPercentage()}
                </div>
            </div>
        );



    }

}

export default ObjectivesGraph;
