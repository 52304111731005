import React, { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import FontAwesome from "react-fontawesome";
import Spinner from "react-spinkit";
import moment from "moment";
import { formatSSN } from "../../support/formatStrings";

import "./index.css";
import OutsideAlerter from "../../../support/components/outsideAlerter";

export const searchType = {
  institution: 1,
  student: 2
};

const formatAlien = alien => {
  return `A${alien}`;
};
const getIdNumber = student => {
  if (student.ssn) return formatSSN(student.ssn);
  if (student.alien) return formatAlien(student.alien);
  return "unknown id";
};
const formatDob = dob => {
  const dt = moment.utc(dob);
  if (!dt.isValid()) return "unk dob";
  return dt.format("M/D/YYYY");
};
const formatSchoolName = name => {
  if (!name) return "(Not Certified)";
  return name;
};

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: "" };
  }

  handleResultClick = value => {
    this.props.onResultClick(value);
  };

  handleChange = e => {
    if (this.state.searchTerm !== e.target.value) {
      this.setState({ searchTerm: e.target.value }, () => this.props.onClear());
    }
  };

  handleClear = e => {
    this.props.onClear();
    this.setState({ searchTerm: "" });
  };

  replaceAt = (str, lengthToReplace, index, replacement) => {
    return (
      str.substr(0, index) + replacement + str.substr(index + lengthToReplace)
    );
  };

  highlightSearchedText = (text, occurences) => {
    let result = text;
    for (let i = occurences.length - 1; i >= 0; i--) {
      result = this.replaceAt(
        result,
        occurences[i][0].length,
        occurences[i].index,
        `<span>${occurences[i][0]}</span>`
      );
    }
    return result;
  };

  createInstitutionResults = () => {
    const { results } = this.props;
    const { searchTerm } = this.state;
    return (
      <div className="results">
        <ul>
          {results.map((item, index) => {
            const result = this.highlightSearchedText(item.name, [
              ...item.name.matchAll(new RegExp(searchTerm, "gi"))
            ]);
            return (
              <li key={item.id} className="search-result">
                <div
                  onKeyPress={() => this.handleResultClick(item)}
                  role="button"
                  tabIndex={index}
                  onClick={() => this.handleResultClick(item)}
                  dangerouslySetInnerHTML={{ __html: result }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  createStudentResults = () => {
    const { results } = this.props;
    const { searchTerm } = this.state;
    return (
      <div className="results student-results">
        <ul>
          {results.map((item, index) => {
            const lastName = this.highlightSearchedText(item.lastName, [
              ...item.lastName.matchAll(new RegExp(searchTerm, "gi"))
            ]);
            const firstName = this.highlightSearchedText(item.firstName, [
              ...item.firstName.matchAll(new RegExp(searchTerm, "gi"))
            ]);
            const tempSSNAlien = getIdNumber(item);
            const ssnAlien = this.highlightSearchedText(tempSSNAlien, [
              ...tempSSNAlien.matchAll(new RegExp(searchTerm, "gi"))
            ]);
            const tempDOB = formatDob(item.dob);
            const dob = this.highlightSearchedText(tempDOB, [
              ...tempDOB.matchAll(new RegExp(searchTerm, "gi"))
            ]);
            return (
              <li key={item.id} className="search-result">
                <div
                  className="student-result"
                  onKeyPress={() => this.handleResultClick(item)}
                  role="button"
                  tabIndex={index}
                  onClick={() => this.handleResultClick(item)}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${lastName}, ${firstName}`
                    }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: ssnAlien }} />
                  <div dangerouslySetInnerHTML={{ __html: dob }} />
                  <div>{formatSchoolName(item.schoolName)}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderResults = () => {
    if (this.props.noResultsFound === true) {
      return (
        <div className="results" key={1}>
          <div style={{ padding: 10 }}>No results found...</div>
        </div>
      );
    }

    const type = this.props.type;

    switch (type) {
      case searchType.institution:
        return this.createInstitutionResults();
      case searchType.student:
        return this.createStudentResults();
      default:
        return null;
    }
  };

  render() {
    const { onSearch, resultsLoading, showResults, placeholder } = this.props;
    const { searchTerm } = this.state;
    return (
      <OutsideAlerter onOutsideClick={this.handleClear}>
        <div className="searchbox">
          <input
            autoComplete="off"
            type="text"
            className="input-with-icon"
            id="search-box"
            onChange={e => this.handleChange(e)}
            onKeyPress={e => (e.key === "Enter" ? onSearch(searchTerm) : null)}
            value={searchTerm}
            placeholder={placeholder}
          />
          <span className="input-loading-icon">
            {resultsLoading && (
              <Spinner name="ball-clip-rotate" color="#3cc6e8" fadeIn="none" />
            )}
          </span>
          <button className="input-icon" onClick={() => onSearch(searchTerm)}>
            <FontAwesome name="search" className="icon" />
          </button>
          <button className="input-icon clear" onClick={this.handleClear}>
            <FontAwesome name="close" className="icon" />
          </button>

            {showResults && this.renderResults()}
          
        </div>
      </OutsideAlerter>
    );
  }
}

export default SearchBox;
