import React, { useEffect, useState } from "react";
import { OnChange } from "react-final-form-listeners";
import { Form, Field } from "react-final-form";
import { required } from "../../../common/formValidators";
import { FinalFormSwitch, FinalFormTextField } from "../../../common/formWrappers";
import { getEventDates, updateEnrollementListSettings } from "../../api/programEvents";
import { EnrollmentCapMaskInput } from "../../../common/masks";
import LoadingButton from "../../../common/loadingButton";
import { toast } from "react-toastify";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router";

const useStyles = makeStyles({
    enrollmentSettingsForm: {
        display: "flex",
        flexDirection: "column",
    },
    saveButton: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    enrollmentAlert: {
        marginBottom: "8px",
        color: "red",
    },
});

const EnrollmentSettings = ({ closeSettingsDialog, programEventDateId, registeredUsersCount, setEnrollmentCapacityAux, disabled }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const [enrollmentCapacity, setEnrollmentCapacity] = useState(0);
    const [enrollmentOpen, setEnrollmentOpen] = useState(false);
    const { id: eventId } = useParams();

    useEffect(() => {
        getEventDates(eventId)
            .then((resp) => {
                const eventData = resp.data.result.dates.find((date) => date.id === programEventDateId);
                setEnrollmentCapacity(eventData.enrollmentCapacity);
                setEnrollmentOpen(eventData.enrollmentOpen);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, eventId, programEventDateId]);

    const handleOnSubmitSettings = async ({ enrollmentCapacity: newEnrollmentCap, enrollmentOpen: newEnrollmentStatus }) => {
        const payload = {
            programEventId: eventId,
            programEventDateId: programEventDateId,
            isOpen: newEnrollmentStatus,
            capacity: newEnrollmentCap,
        };

        try {
            const resp = await updateEnrollementListSettings(payload);
            setEnrollmentCapacityAux(newEnrollmentCap);
            resp.data.success === true && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        closeSettingsDialog();
    };
    //console.log(disabled);
    return (
        <Form onSubmit={handleOnSubmitSettings} initialValues={{ enrollmentOpen, enrollmentCapacity }}>
            {({ handleSubmit, values, errors, form }) => {
                return (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className={classes.enrollmentSettingsForm}>
                            <Field component={FinalFormSwitch} name="enrollmentOpen" label="Open Enrollment" disabled={disabled } />

                            {!values.enrollmentOpen && <small className={classes.enrollmentAlert}>Participants can no longer enroll</small>}
                            <Field
                                component={FinalFormTextField}
                                name="enrollmentCapacity"
                                label="Enrollment Cap"
                                variant="outlined"
                                margin="dense"
                                required
                                validate={required}
                                inputComponent={EnrollmentCapMaskInput}
                                disabled={!values.enrollmentOpen || disabled}
                            />

                            {values.enrollmentCapacity < registeredUsersCount && (
                                <small className={classes.enrollmentAlert}>Must be greater than current enrollment: {registeredUsersCount}</small>
                            )}

                            <OnChange name="enrollmentOpen">
                                {(value, previous) => {
                                    if (!value || !values.enrollmentCapacity) {
                                        form.change("enrollmentCapacity", enrollmentCapacity);
                                    }
                                }}
                            </OnChange>
                        </div>
                        <div className={classes.saveButton}>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={values.enrollmentCapacity < registeredUsersCount || !values.enrollmentCapacity || disabled}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default EnrollmentSettings;
