import React, { Component, } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import AdvisorContext from "./context/context";
import { ThemeProvider } from "@material-ui/core/styles";
import { createCCMUITheme } from "../support/muiTheme";
import AdvisorsDashboard from "./dashboard";
import Caseload from "./caseload";
import AdvisorMessage from "./message";
import LogEntry from "./entry";
import SimpleDialog from "../common/simpleDialog";
import Objectives from "./objectives";
import { v4 as uuidv4 } from 'uuid';
import AdvisorEdit from "./users/advisor";
import LegacyEvent from "./users/LegacyEvent";
import AdvisorStudent from "./users/Student";
import AdvisorParent from "./users/Parent";
import { eventType } from "./enums.js";
import AdminLog from "./AdminLog";
import MilestonesPage from "./milestones";
import AdvisorObjectives from "./objectives/advisorObjectives";
import AdvisorMilestones from "./milestones/advisorMilestones";

import APRReportPage from "./aprreport";

import "./index.css";

class Advisor extends Component {


    static contextType = AdvisorContext;
    constructor(props) {
        super(props);
        // Create a ref
        this.casesRef = React.createRef();
        this.state = {
            advReadOnly: false,
            showEntry: false,
            activeEntry: [],
            showProfile: false,
            activeProfile: [],
            activeEvent: [],
            profileType: "",
            profileTitle: "",
            showMessage: false,
            mode: "New",
            entType: "single",
            edited: false,
            showLegacyEvent: false
        };
    }


    componentDidMount = async () => {
        //console.log("Advisor Component Refreshed")



    }

    cancelEntry = () => {
        this.setState({ showEntry: false, activeEntry: [] })
    }



    openMessage = async (messageId) => {
        this.setState({ showMessage: true });

    }
    newMessage = async (profile) => {

        if (profile != null) {
            this.setState({ showMessage: true, activeProfile: profile });
        } else {
            this.setState({ showMessage: true, activeProfile: [] });
        }


    }
    openEvent = (event) => {

        if (event.eventLink != "Legacy") {
            window.location.href = event.eventLink
        } else {
            this.setState({ showLegacyEvent: true, activeEvent: event });
        }
        
    }
    openEntry = (entry) => {



        this.setState({ activeEntry: entry, mode: "Edit" });
        const { history } = this.props;
        URL = '/advisor/entry/' + entry.id + "/";
        history.push(URL);
    }

    newEntry = (profile) => {
        const newId = uuidv4(); // Assuming uuidv4() is imported or defined elsewhere to generate UUIDs
        const startTime = new Date(); // Current date and time
        const endTime = new Date(startTime.getTime() + 15 * 60000); // 15 minutes in the future

        const newEnt = {
            advisorId: this.context.myBasics.userProfileId,
            id: newId,
            totalAvailableCreditHours: 0,
            lastContacted: true,
            description: "",
            type: eventType.ContactLog,
            startTime: startTime,
            endTime: endTime,
            creatorUserId: this.context.myBasics.userProfile.userId,
            creationTime: new Date(),
            lastModificationTime: null,
            lastModifierUserId: null
        };

        if (profile == null) {
            this.setState({ activeEntry: newEnt, mode: "New", activeProfile: null, entType: "multiple" });
        } else {
            this.setState({ activeEntry: newEnt, activeProfile: profile, mode: "New", entType: "single" });
        }

        const { history } = this.props;
        const URL = '/advisor/entry/' + newId + "/";
        history.push(URL);
    };


    newContactAttempt = (profile) => {


        const newId = uuidv4();
        const startTime = new Date();
        const newEnt = {
            advisorId: this.context.myBasics.userProfileId,
            id: newId,
            totalAvailableCreditHours: 0,
            lastContacted: false,
            description: "",
            type: eventType.OneWayCommunication,
            startTime: startTime,
            endTime: startTime,
            creatorUserId: this.context.myBasics.userProfile.userId,
            creationTime: new Date(),
            lastModificationTime: null,
            lastModifierUserId: null

        }
        if (profile == null) {
            this.setState({ activeEntry: newEnt, mode: "New", activeProfile: null, entType: "multiple" });
        } else {
            this.setState({ activeEntry: newEnt, activeProfile: profile, mode: "New", entType: "single" });
        }


        const { history } = this.props;
        URL = '/advisor/entry/' + newId + "/";
        history.push(URL);
    }

    openNote = (entry) => {



        this.setState({ activeEntry: entry, mode: "Edit" });
        const { history } = this.props;
        URL = '/advisor/note/' + entry.id + "/";
        history.push(URL);
    }

    newNote = (profile) => {


        const newId = uuidv4();
        const newEnt = {
            advisorId: this.context.myBasics.userProfileId,
            id: newId,
            totalAvailableCreditHours: 0,
            lastContacted: false,
            description: "",
            type: eventType.StudentNote,
            creatorUserId: this.context.myBasics.userProfile.userId,
            creationTime: new Date(),
            lastModificationTime: null,
            lastModifierUserId: null

        }
        if (profile == null) {
            this.setState({ activeEntry: newEnt, mode: "New", activeProfile: [] });
        } else {
            this.setState({ activeEntry: newEnt, activeProfile: profile, mode: "New" });
        }

        this.setState({ activeEntry: newEnt });
        const { history } = this.props;
        URL = '/advisor/note/' + newId + "/";
        history.push(URL);
    }
    cancelMessage = () => {
        this.setState({ showMessage: false, activeProfile: [] });
    }
    cancelLegacyEvent = () => {
        this.setState({ showLegacyEvent: false, activeEvent: [] });
    }
    cancelEntry = () => {
        this.setState({ showEntry: false, activeEntry: null });
    }
    gotoLocation = (dest) => {
        this.context.setSelectedAdvisor(0);

        let URL = "/advisor/dashboard"
        switch (dest) {
            case "dashboard":
                URL = "/advisor/dashboard"
                break;
            case "cases":
                URL = "/advisor/cases"
                break;
            case "adminLog":
                URL = "/advisor/adminlogs"
                break;
            case "directorlog":
                URL = "/advisor/directorlogs"
                break;
            case "objectives":
                URL = "/advisor/objectives"
                break;
            case "milestones":
                URL = "/advisor/milestones"
                break;

            case "aprreport":
                URL = "/advisor/aprreport"
                break;
            default:
                URL = "/advisor/dashboard"
                break;

        }
        if (URL != "") {
            const { history } = this.props;
            history.push(URL);
        }

    }
    openCaseload = (id) => {
        // //console.log(id);
        this.context.setSelectedAdvisor(id);

        const url = '/advisor/cases/' + id + "/";
        const { history } = this.props;
        history.push(url);
    }

    openLog = (id) => {
        //   //console.log(id);
        this.context.setSelectedAdvisor(id);

        const url = '/advisor/directorlogs/' + id + "/";
        const { history } = this.props;
        history.push(url);
    }
    openAdvisorObjectives = (id) => {
        //   //console.log(id);
        this.context.setSelectedAdvisor(id);

        const url = '/advisor/objectives/' + id + "/";
        const { history } = this.props;
        history.push(url);
    }

    openAdvisorMilestones = (id) => {
        //   //console.log(id);
        this.context.setSelectedAdvisor(id);

        const url = '/advisor/milestones/' + id + "/";
        const { history } = this.props;
        history.push(url);
    }

    openSearchProfile = async (value, type) => {

        let URL = "";
        switch (type) {
            case 'Student':
                this.setState({ activeProfile: { userProfile: value }, profileType: type, profileTitle: value.firstName + " " + value.lastName });
                URL = '/advisor/student/' + value.id + "/";
                break;

            case 'PrimaryParent':
                this.setState({ activeProfile: { userProfile: value }, profileType: type, profileTitle: value.firstName + " " + value.lastName });
                URL = '/advisor/parent/' + value.id + "/";
                break;

            default:
                //console.log(`User Type not found ${type}.`);
        }
        if (URL != "") {
            const { history } = this.props;
            history.push(URL);
        }
    }
    openProfile = async (value, type) => {

        let URL = "";
        switch (type) {
            case 'Student':
                this.setState({ activeProfile: value, profileType: type, profileTitle: value.userProfile.firstName + " " + value.userProfile.lastName });
                URL = '/advisor/student/' + value.userProfile.id + "/";
                break;

            case 'PrimaryParent':
                this.setState({ activeProfile: { userProfile: value.family.primaryParent, student: { userProfile: value.userProfile, studentProfile: value.studentProfile } }, profileType: type, profileTitle: value.family.primaryParent.firstName + " " + value.family.primaryParent.lastName });
                URL = '/advisor/parent/' + value.family.primaryParent.id + "/";
                break;
            case 'SecondaryParent':
                this.setState({ activeProfile: { userProfile: value.family.secondaryParent, student: { userProfile: value.userProfile, studentProfile: value.studentProfile } }, profileType: type, profileTitle: value.family.secondaryParent.firstName + " " + value.family.secondaryParent.lastName });
                URL = '/advisor/parent/' + value.family.secondaryParent.id + "/";
                break;

            case 'Advisor':
                let advReadOnly = false;
                const { myBasics } = this.context;

                if (!myBasics?.subordinates?.some(subordinate => subordinate.userProfile?.id === value?.advisor?.id)) {
                    advReadOnly = true;
                }

                this.setState({ showProfile: true, activeProfile: value.advisor, profileType: type, profileTitle: value.advisor.firstName + " " + value.advisor.lastName, advReadOnly: advReadOnly });
                break;
            default:
                //console.log(`User Type not found ${type}.`);
        }
        if (URL != "") {
            const { history } = this.props;
            history.push(URL);
        }




    }

    goBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    cancelProfile = async () => {



        
        if (this.state.edited) {
            await this.context.updateState();
        }

        this.setState({ showProfile: false, activeProfile: [], profileType: '', profileTitle: '', edited: false});
        
    }


    cancelMessage = () => {
        this.setState({ showMessage: false })
    }

    setEdited = (val) => {
        this.setState({ edited: val });
        if (val = true) {
            this.context.setMyCases([]);
        }
    }
    render() {

        return (

            <ThemeProvider theme={createCCMUITheme()}>

                <Switch>
                    <Redirect exact from="/advisor" to="/advisor/dashboard" />

                    <Route path="/advisor/dashboard" exact render={(props) => <AdvisorsDashboard
                        gotoLocation={this.gotoLocation}
                        openProfile={this.openProfile}
                        newMessage={this.newMessage}
                        newEntry={this.newEntry}
                        openSearchProfile={this.openSearchProfile}
                        openAdvisorMilestones={this.openAdvisorMilestones}
                        openAdvisorObjectives={this.openAdvisorObjectives}
                        isActive />} />


                    <Route path="/advisor/objectives/:id/" exact render={(props) => <AdvisorObjectives
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        openProfile={this.openProfile}
                        newMessage={this.newMessage}
                        isActive
                        openCaseload={this.openCaseload}
                        openLog={this.openLog}
                        match={props.match}
                    />} />

                    <Route path="/advisor/objectives" exact render={(props) => <Objectives
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        openProfile={this.openProfile}
                        isActive
                        openCaseload={this.openCaseload}
                        openLog={this.openLog}
                        openAdvisorObjectives={this.openAdvisorObjectives}
                        openAdvisorMilestones={this.openAdvisorMilestones}
                    />} />

                    <Route path="/advisor/milestones/:id/" exact render={(props) => <AdvisorMilestones
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        openProfile={this.openProfile}
                        newMessage={this.newMessage}
                        isActive
                        openCaseload={this.openCaseload}
                        openLog={this.openLog}
                        match={props.match}
                    />} />
                    <Route path="/advisor/milestones" exact render={(props) => <MilestonesPage
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        openProfile={this.openProfile}
                        isActive />} />

                    <Route path="/advisor/aprreport" exact render={(props) => <APRReportPage
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        openProfile={this.openProfile}
                        isActive />} />


                    <Route path="/advisor/cases/:id/" exact render={(props) => <Caseload
                        ref={this.casesRef}
                        openMessage={this.openMessage}
                        newMessage={this.newMessage}
                        openEntry={this.openEntry}
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        isActive
                        newEntry={this.newEntry}
                        openProfile={this.openProfile}
                        match={props.match}
                        onContactAttempt={this.newContactAttempt}
                    />} />

                    <Route path="/advisor/cases" exact render={(props) => <Caseload
                        ref={this.casesRef}
                        openMessage={this.openMessage}
                        newMessage={this.newMessage}
                        openEntry={this.openEntry}
                        gotoLocation={this.gotoLocation}
                        goBack={this.goBack}
                        isActive
                        newEntry={this.newEntry}
                        openProfile={this.openProfile}
                        onContactAttempt={this.newContactAttempt}
                    />} />

                    <Route path="/advisor/adminlogs" exact render={(props) => <AdminLog
                        goBack={this.goBack}
                        openEntry={this.openEntry}
                        openNote={this.openNote}
                        gotoLocation={this.gotoLocation}
                        type="Admin"

                    />} />

                    <Route path="/advisor/directorlogs/:id/" exact render={(props) => <AdminLog
                        goBack={this.goBack}
                        openEntry={this.openEntry}
                        openNote={this.openNote}
                        gotoLocation={this.gotoLocation}
                        type="Director"
                        match={props.match}
                    />} />

                    <Route path="/advisor/directorlogs" exact render={(props) => <AdminLog
                        goBack={this.goBack}
                        openEntry={this.openEntry}
                        openNote={this.openNote}
                        gotoLocation={this.gotoLocation}
                        type="Director"
                    />} />

                    <Route path="/advisor/entry/:id/" exact render={(props) => <LogEntry
                        goBack={this.goBack}
                        onClose={this.cancelMessage}
                        activeProfile={this.state.activeProfile}
                        activeEntry={this.state.activeEntry}
                        gotoLocation={this.gotoLocation}
                        mode={this.state.mode}
                        match={props.match}
                        type="log"
                        entType={this.state.entType}
                    />} />
                    <Route path="/advisor/note/:id/" exact render={(props) => <LogEntry
                        goBack={this.goBack}
                        onClose={this.cancelMessage}
                        activeProfile={this.state.activeProfile}
                        activeEntry={this.state.activeEntry}
                        gotoLocation={this.gotoLocation}
                        mode={this.state.mode}
                        type="note"
                        match={props.match}
                        entType={this.state.entType}
                    />} />
                    <Route path="/advisor/student/:id/" exact render={(props) => <AdvisorStudent
                        goBack={this.goBack}
                        currentRoleId={this._currentRoleId}
                        roles={this.state.roles}
                        onSaveComplete={this.onUserSaveComplete}
                        institutions={this.state.institutions}
                        readonly={false}
                        profile={this.state.activeProfile}
                        gotoLocation={this.gotoLocation}
                        newEntry={this.newEntry}
                        openProfile={this.openProfile}
                        newMessage={this.newMessage}
                        openEntry={this.openEntry}
                        match={props.match}
                        openNote={this.openNote}
                        newNote={this.newNote}
                        openEvent={this.openEvent}
                        onContactAttempt={this.newContactAttempt}

                    />} />
                    <Route path="/advisor/parent/:id/" exact render={(props) => <AdvisorParent
                        goBack={this.goBack}
                        currentRoleId={this._currentRoleId}
                        roles={this.state.roles}
                        onSaveComplete={this.onUserSaveComplete}
                        institutions={this.state.institutions}
                        readonly={false}
                        profile={this.state.activeProfile}
                        gotoLocation={this.gotoLocation}
                        newEntry={this.newEntry}
                        openProfile={this.openProfile}
                        newMessage={this.newMessage}
                        openEntry={this.openEntry}
                        match={props.match}
                        openNote={this.openNote}
                        newNote={this.newNote}
                        openEvent={this.openEvent}
                        onContactAttempt={this.newContactAttempt}
                    />} />
                </Switch>

                <SimpleDialog open={this.state.showMessage} onClose={this.cancelMessage} title="Message" >
                    <AdvisorMessage onClose={this.cancelMessage} activeProfile={this.state.activeProfile} />

                </SimpleDialog>



                <SimpleDialog open={this.state.showProfile} onClose={this.cancelProfile} title={this.state.profileTitle} showCloseButton={true} className="ProfileEditDialog">
                    {this.state.profileType == "Advisor" ? (
                        <AdvisorEdit
                            prof={this.state.activeProfile}
                            currentRoleId={this._currentRoleId}
                            roles={this.state.roles}
                            onSaveComplete={this.onUserSaveComplete}
                            institutions={this.state.institutions}
                            readonly={this.state.advReadOnly}
                            profile={this.state.activeprofile}
                            onClose={this.cancelProfile}
                            gotoLocation={this.gotoLocation}
                            openProfile={this.openProfile}
                            setEdited={this.setEdited}
                        />
                    ) : null}

                </SimpleDialog>



                <SimpleDialog open={this.state.showLegacyEvent} onClose={this.cancelLegacyEvent } title={this.state.activeEvent.description} showCloseButton={true} className="ProfileEditDialog">
                    
                        <LegacyEvent
                            event={ this.state.activeEvent}
                        />
                    

                </SimpleDialog>

            </ThemeProvider>

        );
    }
}
export default withRouter(Advisor)