import React, { Component } from "react";
import PropTypes from "prop-types";
import TagList from "./components/taglist";
import { Button, TextField, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { Prompt, Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Tabs, Tab } from '@mui/material';
import SimpleDialog from "../../../common/simpleDialog";
import { FinalFormCheckbox, FinalFormTextField } from "../../../common/formWrappers";

import { required } from "../../../common/formValidators";
import LoadingButton from "../../../common/loadingButton";

class TagsAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTag: [],
            editMode: 0,
            showForm: false,
            tags: props.tags,
            errorClass: 'hideAddSubTag',
            selectedClass: 0,
            search: { classificationId: '0' }
        }
       
    };
  
    componentDidMount = async () => {

        
    };
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        
        if (prevProps.tags != this.props.tags) {
            if (this.state.search.classificationId == "0") {
                this.setState({ tags: this.props.tags })
            } else {
               this.handleFilter(this.state.search);

                
            }
            
        }
    };
    handleFilter = (values, form) => {
        //console.log(values);
        const filtered = this.props.tags.filter(flt => flt.classification.id == values.classificationId);

        this.setState({ tags: filtered, search: values });

    };
    cancelEdit = () => {
        this.setState({
            activeTag: [],
            editMode: 0,
            showForm: false,
            errorClass: 'hideAddSubTag'
        });
    }
    onAddSubtag = (id) => {
        this.props.onAddSubtag(id, this.props.title)

    }
    addNew = () => {
        this.setState({
            activeTag: [],
            editMode: 1,
            showForm: true,
            errorClass: 'hideAddSubTag'
        });

    }
    validateRequired = (value) => {
        
        if (!value || value.trim() === '') {
            
            this.setState({ errorClass: 'showAddSubTag' });
            return 'This field is required';
        }
        
        this.setState({ errorClass: 'hideAddSubTag' });
        return undefined; // Return undefined if the field is valid
    };

    handleSubmit = (values, form) => {
        if (!values.classificationId || values.classificationId == '') {
            this.setState({ errorClass: 'showAddSubTag' });
        } else {
            let input = {
                name: values.name,
                descriptorRequired: values.descriptorRequired,
                iPEDRequired: values.iPEDRequired,
                isActive: values.isActive,
                useEvents: values.useEvents,
                useAdvisors: values.useAdvisors,
                classificationId: values.classificationId,
                description: values.description
            }


            this.props.onAdd(input);
            this.setState({
                activeTag: [],
                editMode: 0,
                showForm: false
            });
        }
       
    }
 



    render() {
        

        return (<div className="taggingAdmin"><span className="TaggingTitle">{this.props.title}</span>
            <div className="tagFilter">
                
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} style={{ textAlign: 'left' }}>
                        <div className="newButton">
                            <LoadingButton
                                wrapperClassName={"newButton"}
                                className={"test"}
                                variant="contained"
                                color="primary"
                                onClick={this.addNew}
                            >
                                Add Tag
                            </LoadingButton>
                        </div>
                    </Grid>

                
                    <Grid xs={12} sm={10}>
                <Form onSubmit={this.handleFilter} >
                    {({ handleSubmit, values, dirty, errors }) => {

                        return (
                            <form onSubmit={handleSubmit} noValidate className="search" style={{margin: "15px"} }>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3 }>
                <Field name="classificationId" label="Classification" component="select" >
                                    <option  key={'0'} value={'0'}>
                                        Select a Classification
                                    </option>
                    {this.props.classifications.map((option) => {
                        return (
                            <option style={{ background: option.color }} key={option.id} value={option.id}>
                                {option.classification}
                            </option>
                        );

                    })}

                                </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                <LoadingButton
                                    wrapperClassName={"test"}
                                    className={"test"}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Filter
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Form>
                    </Grid>
                   </Grid>       

           </div>
            <br />


            <TagList tags={this.state.tags} classifications={this.props.classifications} onUpdate={this.props.onUpdate} onSubTagUpdate={this.props.onSubTagUpdate} onAddSubtag={this.onAddSubtag}></TagList>


            
            <SimpleDialog open={this.state.showForm} onClose={this.cancelEdit} title="New Tag" >

            
                <div className="MessagingVisible" >
                    
                    <Form onSubmit={this.handleSubmit} initialValues={this.state.activeClassification}>
                        {({ handleSubmit, values, dirty, errors }) => {
                            
                            return (
                                <form onSubmit={handleSubmit} FormValues={this.state.activeClassification} noValidate className="test">
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <div className={"test"}>
                                        <Field name="id" component="input" type="hidden" />

                                        <table>
                                            <tr>
                                                <td>
                                                    <span className={"MuiFormLabel-root MuiFormControlLabel-label formlabel"}>Classification</span><span aria-hidden="true" class="MuiFormLabel-asterisk jss75">*</span><br />
                                                    <Field name="classificationId" label="Classification" component="select" >
                                                        <option value="">Select an option</option>
                                                        {this.props.classifications.map((option) => {
                                                            return (
                                                                <option style={{ background: option.color }} key={option.id} value={option.id}>
                                                                    {option.classification}
                                                                </option>
                                                            );

                                                        })}

                                                    </Field><br/>
                                                    <span className={this.state.errorClass} style={{ color: 'red' }} >This Field is Required</span><br />
                                                </td>
                                                <td><br />
                                                    <Field
                                                        component={FinalFormTextField}
                                                        name="name"
                                                        label="Name"
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        validate={required}
                                                        wrapperClassName={"formlabel"}
                                                    />
                                                </td></tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <Field
                                                        component={FinalFormTextField}
                                                        name="description"
                                                        label="Description (Optional)"
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        multiline
                                                        maxLength={5000}
                                                        minRows={2}
                                                        maxRows={4}
                                                        wrapperClassName={"formlabel"}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className={"MuiFormControlLabel-label formlabel"}>Usages</span><br />
                                                    <Field component={FinalFormCheckbox} name="useAdvisors" label="Use for Advisors" />&nbsp;
                                                    <Field component={FinalFormCheckbox} name="useEvents" label="Use for Events" />&nbsp;
                                                </td>
                                                <td><span className={"MuiFormControlLabel-label formlabel"}>Behavior</span><br />
                                                    <Field component={FinalFormCheckbox} name="ipedRequired" label="IPED Number Required" />&nbsp;
                                                    <Field component={FinalFormCheckbox} name="descriptorRequired" label="Descriptor Required" />
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                </td>
                                                <td>
                                                </td></tr><tr>
                                                <td style={{ textAlign: 'right' }}>
                                                </td>
                                                <td style={{ textAlign: 'right' }} >

                                                    <table style={{ float: 'right', width: '100%' }}>
                                                        <tr><td style={{ margin: '2px' }}>
                                                            <Field component={FinalFormCheckbox} name="isActive" label="Is Active" />
                                                        </td>
                                                            <td style={{ margin: '2px' }}>
                                                                <LoadingButton
                                                                    wrapperClassName={"test"}
                                                                    className={"test"}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleSubmit}
                                                                >
                                                                    &nbsp;   Save &nbsp;
                                                                </LoadingButton>
                                                            </td>
                                                            <td style={{ margin: '2px' }}>
                                                                <LoadingButton
                                                                    wrapperClassName={"test"}
                                                                    className={"test"}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.cancelEdit}
                                                                >
                                                                    Cancel
                                                                </LoadingButton>
                                                            </td>
                                                        </tr>
                                                    </table>



                                                </td>
                                            </tr>
                                        </table>

                                       


                                    </div>
                                </form>
                            );
                        }}
                    </Form>
                </div>
               
            </SimpleDialog>

        </div>);

    };

}

TagsAdmin.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};


export default TagsAdmin;