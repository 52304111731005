import React from "react";
import { reduxForm, Field } from "redux-form";
import moment from "moment";
import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";
import {
  required,
  renderField,
  renderDateField
} from "../../../../support/forms";

import "./refund.css";

const validateCheckNumber = value => {
  if (
    !value ||
    (value &&
      (value.length > 10 ||
        value === "0" ||
        isNaN(value) ||
        parseInt(value, 10) === 0 ||
        (typeof value === "string" && value.indexOf(".") !== -1)))
  ) {
    return "Valid check number required";
  }
};

const isDate = value => {
  if (!value) {
    return undefined;
  }

  const dt = moment(value);

  return dt.isValid() ? (
    undefined
  ) : (
    <span style={{ color: "red" }} title="Invalid date">
      Invalid date
    </span>
  );
};

let Refund = ({ refund, onSave, handleSubmit }) => {
  return (
    <div className="refund-info">
      <div className="data-container">
        <div className="top-row">
          <Field
            component={renderField}
            label="Check #"
            name="checkNumber"
            type="number"
            min="0"
            className="payment-field"
            validate={[validateCheckNumber]}
            disabled={refund.checkNumber}
          />
          <Field
            component={renderDateField}
            label="Check Date"
            name="checkDate"
            outerClassName="payment-field"
            validate={[required, isDate]}
            disabled={refund.checkNumber}
          />
          <Field
            component={renderDateField}
            label="Received On"
            name="receivedOn"
            outerClassName="payment-field"
            validate={[required, isDate]}
            disabled={refund.receivedOn}
          />
          <div className="payment-total">
            <span className="form-label">Total</span>
            <div className="form-inner-div">
              <input
                className="form-input negative-value"
                disabled
                value={`(${formatCurrencyNoDecimal(Math.abs(refund.amount))})`}
              />
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <Field
            component={renderField}
            className=""
            label="Notes"
            name="receivedNotes"
            type="text"
            hideLabel
            disabled={refund.receivedOn}
          />
        </div>
      </div>
      <div className="button-container">
        <button
          className="btn"
          onClick={handleSubmit(onSave)}
          disabled={refund.receivedOn}
        >
          Refund Received
        </button>
      </div>
    </div>
  );
};

Refund = reduxForm({ form: "ScholarshipReceiveRefund" })(Refund);

export default Refund;
