import React, { Component, Fragment } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import { SubNavigation } from "../../support/security";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";

import UserHeader from "./users/Header";
import Users from "./users/List";
import UserEdit from "./users/Edit";
import Roles from "./roles";
import Institutions from "./institutions/List";
import InstitutionHeader from "./institutions/Header";
import InstitutionEdit from "./institutions/Edit";

import TertiaryNav from "../../common/tertiaryNav";

import Schools from "./schools/List";
import SchoolHeader from "./schools/Header";
import SchoolEdit from "./schools/Edit";

import Scholarship from "./Scholarship";
import AdminAdmissionsPage from "./admissions";
import AdminTaggingPage from "./tagging"
import * as userApis from "./users/apis";
import * as institutionApis from "./institutions/apis";
import * as schoolApis from "./schools/apis";
import "./index.css";
import Districts from "./schools/districts";
import DistrictEdit from "./schools/districts/edit";
import { createCCMUITheme } from "../../support/muiTheme";
import Edit from "./users/Edit";
import CrusaderUserForm from "./users/CrusaderUserForm";
import { ROLE_NAME_CRUSADER, ROLE_NAME_PARENT } from "../../support/constants";
import ParentUserForm from "./users/ParentUserForm";
import FileDownload from "./test/filedownload";
import NonProgramStudent from "./users/nonProgramStudent";

const links = [
    {
        label: "Schools",
        to: "/admin/middle-high/schools",
        permission: "Admin.Schools",
    },
    {
        label: "Districts",
        to: "/admin/middle-high/districts",
        permission: "Admin.Schools",
    },
];

class Admin extends Component {
    state = {
        roles: [],
        users: [],
        institutions: [],
        schools: [],
        districts: [],
        tags: [],
    };
    componentDidMount = async () => {
        this._isMounted = true;
        this.doLoadRoles();
        await this.doLoadInstitutions();
        await this.doLoadSchools();
        await this.doLoadDistricts();
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    onUserSearch = async (text) => {
        //TODO: if no role is selected
        await this.doSearchUser(text);
    };
    onUserSaveComplete = async () => {
        if (this._currentRoleId) {
            await this.doLoadUsersByRole(this._currentRoleId);
        }
    };
    onAddUser = () => {
        if (this._currentRoleId && this._currentRoleId != 8 ) {
            //console.log(this._currentRoleId);
            this.props.history.push(`/admin/users/0/role/${this._currentRoleId}`);
            return;
        }
        if (this._currentRoleId && this._currentRoleId == 8) {
            this.props.history.push("/admin/nonprogramstudent/");
            return;
        }
        this.props.history.push("/admin/users/0");
    };
    onRoleChange = async (roleId) => {
        this._currentRoleId = roleId;
        this.doLoadUsersByRole(roleId);
    };
    doLoadUsersByRole = async (roleId) => {
        this.props.ajaxStatusLoading();
        const role = this.state.roles.find((r) => r.value === roleId);
        this.roleId = role.value;
        this.roleName = role.label;
        try {
            let users;
            switch (role.label) {
                case ROLE_NAME_CRUSADER:
                    users = (await userApis.getAllCrusaders()).data.result;
                    break;
                case ROLE_NAME_PARENT:
                    users = (await userApis.getAllParents()).data.result;
                    break;
                default:
                    users = (await userApis.getAllUsersByRole(roleId)).data.result;
            }

            this.setState(
                {
                    users,
                },
                () => {
                    this.props.ajaxStatusComplete();
                }
            );
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };
    onAddInstitution = () => {
        this.props.history.push("/admin/institution/0");
    };
    onInstitutionSaveComplete = async () => {
        await this.doLoadInstitutions();
    };

    onAddSchool = () => {
        this.props.history.push("/admin/middle-high/schools/0");
    };

    onAddDistrict = () => {
        this.props.history.push("/admin/middle-high/districts/0");
    };

    onSchoolSaveComplete = async () => {
        await this.doLoadSchools();
    };

    onDistrictSaveComplete = async () => {
        await this.doLoadDistricts();
    };

    doSearchUser = async (text) => {
        this.props.ajaxStatusLoading();

        try {
            const role = this.state.roles.find((r) => r.value === this._currentRoleId);
            let users;
            switch (role.label) {
                case ROLE_NAME_CRUSADER:
                    users = (await userApis.getAllCrusaders(text)).data.result;
                    break;
                case ROLE_NAME_PARENT:
                    users = (await userApis.getAllParents(text)).data.result;
                    break;
                default:
                    users = (await userApis.searchForUser(text, this._currentRoleId)).data.result;
            }

            this.setState({
                users,
            });

            this.props.ajaxStatusComplete();
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };
    doLoadRoles = async () => {
        this.props.ajaxStatusLoading();

        try {
            const roles = (await userApis.getAllRoles()).data.result;
            if (this._isMounted) {
                this.setState({
                    roles: roles.map((role) => {
                        return {
                            value: role.id,
                            label: role.name,
                        };
                    }),
                });
            }

            this.props.ajaxStatusComplete();
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };
    doLoadInstitutions = async () => {
        this.props.ajaxStatusLoading();

        try {
            const institutions = (await institutionApis.getAll()).data.result;
            if (this._isMounted) {
                this.setState({
                    institutions,
                });
            }

            this.props.ajaxStatusComplete();
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };
    doLoadSchools = async () => {
        this.props.ajaxStatusLoading();

        try {
            const schools = (await schoolApis.getAll()).data.result;
            if (this._isMounted) {
                this.setState({
                    schools,
                });
            }

            this.props.ajaxStatusComplete();
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };

    doLoadDistricts = async () => {
        this.props.ajaxStatusLoading();

        try {
            const districts = (await schoolApis.getAllDistricts()).data.result;
            if (this._isMounted) {
                this.setState({
                    districts,
                });
            }

            this.props.ajaxStatusComplete();
        } catch (error) {
            this.props.ajaxStatusError(error);
        }
    };

    render() {
        return (
            <ThemeProvider theme={createCCMUITheme()}>
                <div className="admin">
                    <CssBaseline />
                    <SubNavigation sectionName="Admin" />
                    <Route path="/admin" exact render={() => <Redirect to="/admin/users" />} />
                    <Route path="/admin/testfiledownload" exact render={() => <FileDownload />} />
                    <Route path="/admin/middle-high" exact render={() => <Redirect to="/admin/middle-high/schools" />} />

                    <Route
                        exact
                        path="/admin/users"
                        render={() => (
                            <Fragment>
                                <UserHeader
                                    onSearch={this.onUserSearch}
                                    onAdd={this.onAddUser}
                                    onRoleChange={this.onRoleChange}
                                    roles={this.state.roles}
                                    currentRoleId={this._currentRoleId}
                                />
                                <Users users={this.state.users} currentRoleId={this._currentRoleId} />
                            </Fragment>
                        )}
                    />
                    <Route
                        exact
                        path="/admin/nonprogramstudent/"
                        render={() => (
                            <NonProgramStudent
                                currentRoleId={this._currentRoleId}
                                roles={this.state.roles}
                                onSaveComplete={this.onUserSaveComplete}
                                institutions={this.state.institutions}
                                readonly={false}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/users/:id/"
                        render={() => (
                            <Edit
                                currentRoleId={this._currentRoleId}
                                roles={this.state.roles}
                                onSaveComplete={this.onUserSaveComplete}
                                institutions={this.state.institutions}
                                readonly={false}
                            />
                        )}
                    />
                    
                    <Route exact path="/admin/users/crusader/:id/" component={CrusaderUserForm} />
                    <Route exact path="/admin/users/parent/:id/" component={ParentUserForm} />
                    <Route
                        exact
                        path="/admin/users/:id/role/:roleId"
                        render={() => (
                            <UserEdit
                                roles={this.state.roles}
                                onSaveComplete={this.onUserSaveComplete}
                                institutions={this.state.institutions}
                                readonly={false}
                            />
                        )}
                    />
                    <Route path="/admin/roles" component={Roles} />

                    <Route
                        path="/admin/institutions"
                        render={() => (
                            <Fragment>
                                <InstitutionHeader onAdd={this.onAddInstitution} readonly={false} />
                                <Institutions institutions={this.state.institutions} readonly={false} />
                            </Fragment>
                        )}
                    />
                    <Route
                        exact
                        path="/admin/institution/:id/"
                        render={() => <InstitutionEdit onSaveComplete={this.onInstitutionSaveComplete} readonly={false} />}
                    />

                    <Route
                        exact
                        path="/admin/middle-high/schools"
                        render={() => (
                            <Fragment>
                                <TertiaryNav links={links} />
                                <SchoolHeader onAdd={this.onAddSchool} readonly={false} />
                                <Schools schools={this.state.schools} districts={this.state.districts} />
                            </Fragment>
                        )}
                    />
                    <Route
                        exact
                        path="/admin/middle-high/districts"
                        render={() => (
                            <Fragment>
                                <TertiaryNav links={links} />
                                <Districts data={this.state.districts} onAdd={this.onAddDistrict} readOnly={false} />
                            </Fragment>
                        )}
                    />
                    <Route
                        exact
                        path="/admin/middle-high/schools/:id/"
                        render={() => (
                            <Fragment>
                                <TertiaryNav links={links} />
                                <SchoolEdit onSaveComplete={this.onSchoolSaveComplete} districts={this.state.districts} />
                            </Fragment>
                        )}
                    />
                    <Route
                        exact
                        path="/admin/middle-high/districts/:id/"
                        render={() => (
                            <Fragment>
                                <TertiaryNav links={links} />
                                <DistrictEdit onSaveComplete={this.onDistrictSaveComplete} />
                            </Fragment>
                        )}
                    />

                    <Route path="/admin/scholarship" component={Scholarship} />

                    <Route path="/admin/admissions" component={AdminAdmissionsPage} />
                    <Route path="/admin/tagging" component={AdminTaggingPage} />
                </div>
            </ThemeProvider>
        );
    }
}

Admin = withRouter(Admin);
Admin = withAjaxStatus(Admin);

export default Admin;
