import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./NavigationHeader.css";

import withUser from "../../../support/hoc/withUser";

let NavigationHeader = ({ onNavigate, user }) => {
  const isStaff = user && (user.userProfile.userType === 6 || user.userProfile.userType === 99);

  return (
    <div className="scholarship-financial-header">
      {!isStaff && (
        <React.Fragment>
          <NavLink to="/scholarship/financials/request-payment" activeClassName="active-link">
            <div className="option">Request Payment</div>
          </NavLink>
          <NavLink to="/scholarship/financials/submit-refund" activeClassName="active-link">
            <div className="option">Submit Refund</div>
          </NavLink>
        </React.Fragment>
      )}
      {isStaff && (
        <React.Fragment>
          <NavLink to="/scholarship/financials/send-payment" activeClassName="active-link">
            <div className="option">Send Payment</div>
          </NavLink>
          <NavLink to="/scholarship/financials/receive-refund" activeClassName="active-link">
            <div className="option">Receive Refund</div>
          </NavLink>
        </React.Fragment>
      )}
    </div>
  );
};

NavigationHeader.propTypes = {
  onNavigate: PropTypes.func.isRequired
};

NavigationHeader = withUser(NavigationHeader);
NavigationHeader = withRouter(NavigationHeader);

export default NavigationHeader;
