import React, { Component } from "react";
import PropTypes from "prop-types";

import { EMPTY_GUID } from "../../../support/constants";
import ReactTable from "react-table";
import { Prompt, Link } from "react-router-dom";
import { PhotoshopPicker, SketchPicker, CirclePicker, CompactPicker } from 'react-color';
import Modal from "react-modal";
import SimpleDialog from "../../../common/simpleDialog";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { Form, Field } from "react-final-form";

import { FinalFormCheckbox, FinalFormTextField } from "../../../common/formWrappers";
import { DialogActions, Grid } from "@material-ui/core";
import { required } from "../../../common/formValidators";
import LoadingButton from "../../../common/loadingButton";
import { NumberMaskInput } from "../../../common/masks";




class TagClassifications extends Component {

    state = {
        activeClassification: [],
        editMode: 0,
        showForm: false,
        warningClass: "",
        classifications: []
    };
    componentDidMount = async () => {
        this.setState({ classifications: this.props.classifications });
        

    };
    handleSubmit = async (values, form) => {
        //console.log(values);
        switch (this.state.editMode) {
            case 0:
                this.setState({
                    activeClassification: [],
                    editMode: 0,
                    showForm: false
                });
                break;
            case 1:
                let isActive = true;
                if (this.props.title.indexOf('Inactive') > -1) {
                    isActive = false;
                }
                let input = {
                    classification: values.classification,
                    color: values.color,
                    isActive: isActive,
                    advisorGoals: values.advisorGoals,
                    accelerateGoals: values.accelerateGoals,
                    familyEngagementGoals: values.familyEngagementGoals,
                    gapGoals: values.gapGoals,
                    highSchoolGoals: values.highSchoolGoals,
                    honorificGoals: values.honorificGoals,
                    middleSchoolGoals: values.middleSchoolGoals,
                    postSecondaryGoals: values.postSecondaryGoals

                }
                
                await this.props.onAdd(input);
                this.setState({
                    activeClassification: [],
                    editMode: 0,
                    showForm: false
                });
                break;
            case 2:
                await this.props.onUpdate(values);
                this.setState({
                    activeClassification: [],
                    editMode: 0,
                    showForm: false
                });
                break;
            default:
                this.setState({
                    activeClassification: [],
                    editMode: 0,
                    showForm: false
                });
                break;

        }
        
    }
    cancelEdit = () =>{
        this.setState({
            activeClassification: [],
            editMode: 0,
            showForm: false
        });
    }

    addNew = () => {
        this.setState({
            activeClassification: [],
            editMode: 1,
            showForm: true
        });

        }
    handleColorChangeComplete = (color) => {
        const ac = this.state.activeClassification;
        ac.color = color.hex;
        this.setState({ activeClassification: ac});

    }
    onEdit = (data) => {
        
        const ac = this.props.classifications.find((element) => {
            return element.id === data;
        });
        this.setState({
            activeClassification: ac,
            editMode: 2,
            showForm: true
        });
    }
    render() {
        
        return (<div><span className="TaggingTitle">{this.props.title}</span>
            <div className="tagFilter">
            <div className="newButton">
            <LoadingButton
                wrapperClassName={"test"}
                className={"test"}
                variant="contained"
                color="primary"
                onClick={this.addNew}
            >
                Add Classification
            </LoadingButton>
                </div>
            </div>
            <br /> 
            <ReactTable
                data={this.props.classifications}
                minRows={0}
                columns={[
                    {
                        Header: "Name",
                        accessor: "classification",
                        minWidth: 150,
                    },
                    {
                        Header: "Color",
                        accessor: "color",
                        maxWidth: 200,
                        Cell: (row) => {
                            
                            return <div style={{ background: row.value, height: '100%' }}>
                                
                            </div>;
                        },

                    },
                    {
                        Header: "",
                        accessor: "id",
                        minWidth: 30,
                        Cell: (row) => {
                            
                            return <LoadingButton
                                wrapperClassName={"test"}
                                className={"test"}
                                variant="contained"
                                color="primary"
                                onClick={()=> this.onEdit(row.value)}
                            >
                                Edit
                            </LoadingButton>;
                        },
                    },
                ]}
                defaultPageSize={50}
                className="-striped -highlight"
            />

            <SimpleDialog open={this.state.showForm} onClose={this.cancelEdit} title="Classification" >
            
                <div className="MessagingVisible" >
                    
                    <Form onSubmit={this.handleSubmit} initialValues={this.state.activeClassification}>
                        {({ handleSubmit, values, dirty, errors }) => {
                           
                            return (
                                <form onSubmit={handleSubmit} FormValues={this.state.activeClassification } noValidate className="test">
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <div className={"test"}>
                                        <table style={{  height: '100%', width: '100%' }}>
                                            <tr><td colSpan="2">
                                                <Field name="id" component="input" type="hidden" />

                                                <Field
                                                    component={FinalFormTextField}
                                                    name="classification"
                                                    label="Name"
                                                    variant="outlined"
                                                    margin="dense"
                                                    required
                                                    validate={required}
                                                />
                                            </td>
                                            </tr>
                                            <tr><td>
                                                <Field
                                                    name="color"
                                                    label="Color"
                                                    render={({ input }) => (<table style={{ height: '100%', width: '100%' }}>
                                                        <tr>
                                                            <td style={{ height: '100%', width: '25%' }}>
                                                                <div style={{ background: input.value, height: '40px', width: '40px' }}></div>&nbsp;&nbsp;
                                                            </td>
                                                            <td>
                                                                <SketchPicker
                                                                    color={input.value}
                                                                    onChange={(color) => input.onChange(color.hex)}
                                                                    showButtons={false}
                                                                    onAccept={handleSubmit}
                                                                    onCancel={this.cancelEdit}
                                                                />
                                                            </td>
                                                    </tr>


                                                    </table>
                                                    )}
                                                />
                                            </td>
                                                <td>
                                                    <Field component={FinalFormCheckbox} name="isActive" label="Is Active" /><br />
                                                    <Field component={FinalFormCheckbox} name="advisorGoals" label="Advisor Objectives" /><br />
                                                    <Field component={FinalFormCheckbox} name="middleSchoolGoals" label="Middle School Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="highSchoolGoals" label="High School Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="postsecondaryGoals" label="Post-secondary Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="gapGoals" label="Gap Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="honorificGoals" label="Honorific Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="accelerateGoals" label="Accelerate Goals" /><br />
                                                    <Field component={FinalFormCheckbox} name="familyEngagementGoals" label="Family Engagement Goals" /><br />
                                                </td>
                                                <td>
                                                    <Field component={FinalFormCheckbox} name="middleSchoolMilestones" label="Middle School Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="highSchoolMilestones" label="High School Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="postsecondaryMilestones" label="Post-secondary Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="gapMilestones" label="Gap Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="honorificMilestones" label="Honorific Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="accelerateMilestones" label="Accelerate Milestones" /><br />
                                                    <Field component={FinalFormCheckbox} name="familyEngagementMilestones" label="Family Engagement Milestones" /><br />
                                                    <br />
                                                    <LoadingButton
                                                    wrapperClassName={"test"}
                                                    className={"test"}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    &nbsp; Save &nbsp;
                                                </LoadingButton>
                                                    <br />
                                                <br/>
                                                    <LoadingButton
                                                        wrapperClassName={"test"}
                                                        className={"test"}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.cancelEdit}
                                                    >
                                                        Cancel
                                                    </LoadingButton>
                                                </td>
                                            </tr>
                                        </table>
                                         <br />
                                        
                                       
                                       
                                                   
                                                


                                        
                                    </div>
                                </form>
                            );
                        }}
                    </Form>
                </div>
                
            </SimpleDialog>
        </div>);

    };

}

TagClassifications.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};


export default TagClassifications;