import axios from 'axios';

export function getProfile(id) {
    return axios.get('/api/services/app/UserProfile/GetProfile', { params: { id } });
}

export function updateStudentInfo(studentInfo) {
    return axios.put('/api/services/app/UserProfile/UpdateStudentInfo', { ...studentInfo });
}

export function getExistingCrusaders() {
    return axios.get('/api/services/app/UserProfile/GetExistingCrusaders');
}
export const getExistingCrusadersByApplicationId = applicationId => {
    return axios.get('/api/services/app/UserProfile/GetExistingCrusadersByApplication', { params: { applicationId } });
};
export function createExistingCrusader(data) {
    return axios.post('/api/services/app/UserProfile/CreateExistingCrusader', data);
}
export function updateExistingCrusader(data) {
    return axios.put('/api/services/app/UserProfile/UpdateExistingCrusader', data);
}
export function deleteExistingCursader(data) {
    return axios.post('/api/services/app/UserProfile/ExistingCrusaderDelete', data);
}
