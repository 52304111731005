import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel, Tabs, Tab
} from '@mui/material';

import * as api from "../../api"
import "./index.css";
class Progress2Exp extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            msObj: [],
            hsObj: [],
            psObj: [],
            userTags: [],
            loaded: false,


        };
    }



    componentDidMount = async ()=> {
        

        let input = [];
		input.push({ userProfileId: this.props.profile.userProfile.id });
		
       const recs = await api.retrieveAdditionInfo(input);

        const msObj = this.context.taggingOptions.filter(flt => flt.classification.middleSchoolGoals == true);
        const hsObj = this.context.taggingOptions.filter(flt => flt.classification.highSchoolGoals == true);
        const psObj = this.context.taggingOptions.filter(flt => flt.classification.postsecondaryGoals == true);
        

        this.setState({ msObj, hsObj, psObj, userTags: recs.data.result[0].contactInfo.tags });
    }
    componentDidUpdate(prevProps, prevState) {



    }

    isChecked = (tag, subtag) => {


        let ret = false;
        const tags = this.state.userTags.filter(flt => flt.tagId == tag && flt.subTagId == subtag);
       
        if (tags.length > 0) {
            ret = true;
        }

        return ret;

    }

    

        


    renderDepartment = (department) => {
        return (
            <Grid container spacing={2} className="progPrintSection">
                {department.map((dep, depIndex) => {
                    let tagname = "";

                    return (
                        <React.Fragment key={depIndex}>
                            {dep.tags.map((tag, tagIndex) => {
                                if (tag.subtagId === "00000000-0000-0000-0000-000000000000") {
                                    tagname = tag.tagName + " : "; 
                                    return (
                                        <Grid item xs={12} className="progPrintHeading" key={tagIndex}>
                                            {tag.tagName}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid item xs={12} key={tagIndex} className="progPrintEntry">
                                            <Checkbox checked={ this.isChecked(tag.tagId, tag.subtagId ) } disabled></Checkbox> {tag.tagName.replace(tagname, '')} 
                                        </Grid>
                                    );
                                }
                            })}
                        </React.Fragment>
                    );
                })}
            </Grid>
        );
    }




    render() {
        // Get the last two items from the history stack


        return (<Grid container spacing={2} className="printContainer">

           

            <Grid item xs={12} sm={12} >
                <div className="printSectionHeader">Middle School</div>
                {this.renderDepartment(this.state.msObj) }

            </Grid>

            <Grid item xs={12} sm={12} >
                <div className="printSectionHeader">High School</div>
                {this.renderDepartment(this.state.hsObj)}
            </Grid>


            <Grid item xs={12} sm={12} >
                <div className="printSectionHeader">Post-secondary</div>
                {this.renderDepartment(this.state.psObj)}

            </Grid>

        </Grid>);
    }
}

export default Progress2Exp;