import { ajaxStatusLoadingAction, ajaxStatusErrorAction, ajaxStatusCompleteAction } from "../../redux/ajax";
import { getByUser, createByUser, getById, getAllByUser } from "../../api/applications";

const LOAD_IDENTIFIERS = "cc/admissions/LOAD_IDENTIFIERS";
const CLEAR_IDENTIFIERS = "cc/admissions/CLEAR_IDENTIFIERS";
const CHANGE_IDENTIFIERS = "cc/admissions/CHANGE_IDENTIFIERS";
export default function reducer(state = null, action = {}) {
  switch (action.type) {
    case LOAD_IDENTIFIERS:
      return {
        ...state,
        cipher: secretCipher(), // secret
        ...action.payload,
      };
    case CLEAR_IDENTIFIERS:
          return null;
    case CHANGE_IDENTIFIERS:
          return {
              ...state,
              cipher: secretCipher(), // secret
              ...action.payload,
          }
    default:
      return state;
  }
}

export function refreshApplication() {
  return (dispatch, getState) => {
      const applicationId = getState().admissions.id;
      
    if (!applicationId) {
      return;
    }

    dispatch(ajaxStatusLoadingAction());
    try {
      getById(applicationId)
        .then((response) => {
          dispatch({
            type: LOAD_IDENTIFIERS,
            payload: response.data.result,
          });
          dispatch(ajaxStatusCompleteAction());
        })
        .catch((err) => {
          dispatch(ajaxStatusErrorAction(err));
        });
    } catch (error) {
      dispatch(ajaxStatusErrorAction(error));
    }
  };
}

export function loadApplication(applicationId) {
    return (dispatch) => {
        
    dispatch(ajaxStatusLoadingAction());
    try {
      getById(applicationId)
        .then((response) => {
          dispatch({
            type: LOAD_IDENTIFIERS,
            payload: response.data.result,
          });
          dispatch(ajaxStatusCompleteAction());
        })
          .catch((err) => {
              //console.log(err);
          dispatch(ajaxStatusErrorAction(err));
        });
    } catch (error) {
        //console.log(error);
      dispatch(ajaxStatusErrorAction(error));
    }
  };
}
export async function loadApplicationAsync(applicationId) {
    return (dispatch) => {
        
        dispatch(ajaxStatusLoadingAction());
        try {
          getById(applicationId)
                .then((response) => {
                    dispatch({
                        type: LOAD_IDENTIFIERS,
                        payload: response.data.result,
                    });
                    dispatch(ajaxStatusCompleteAction());
                })
                .catch((err) => {
                    //console.log(err);
                    dispatch(ajaxStatusErrorAction(err));
                });
        } catch (error) {
            //console.log(error);
            dispatch(ajaxStatusErrorAction(error));
        }
    };
}

export function loadApplicationFromValues(app) {
    return (dispatch) => {
        
        dispatch(ajaxStatusLoadingAction());
        try {
            
                    dispatch({
                        type: LOAD_IDENTIFIERS,
                        payload: app,
                    });
                    dispatch(ajaxStatusCompleteAction());
                }
                catch(err)  {
                    //console.log(err);
                    dispatch(ajaxStatusErrorAction(err));
                };
        
    };
}

export function loadIdentifiers() {
  return (dispatch) => {
    dispatch(ajaxStatusLoadingAction());
      
    try {
        getAllByUser()
        .then((response) => {
            if (response.data.result !== null) {
                
            dispatch({
              type: LOAD_IDENTIFIERS,
                payload: response.data.result[response.data.result.length - 1],
            });
            dispatch(ajaxStatusCompleteAction());
            return;
          }
          createByUser()
            .then((app) => {
              dispatch({
                type: LOAD_IDENTIFIERS,
                payload: app.data.result,
              });
              dispatch(ajaxStatusCompleteAction());
            })
              .catch((err) => {
                  //console.log(err);
              dispatch(ajaxStatusErrorAction(err));
            });
        })
            .catch((err) => {
                //console.log(err);
          dispatch(ajaxStatusErrorAction(err));
        });
    } catch (error) {
        //console.log(error);
      dispatch(ajaxStatusErrorAction(error));
    }
  };
}

export function clearIdentifiers() {
  return { type: CLEAR_IDENTIFIERS, payload: null };
}

function secretCipher() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
