import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { reduxForm } from "redux-form";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import Link from "@material-ui/core/Link";
import withAdmissions from "../../../support/hoc/withAdmissions";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import withAdmissionSettings from "../../../support/hoc/withAdmissionSettings";
import b64toBlob from "../../../support/logic/b64toBlob";

import { getVerifiedDocuments, toggleVerifyDocumentGroup } from "../../../api/documents";
import {
    markComplete,
    rejectApplication,
    toggleLunchPending,
    clearLunchPending,
    addEventInvite,
    getNotes,
    updateNotes,
    rejectApplicant,
    markResubmit,
    deleteApplication,
    getApplicationIdForStudent
} from "../../../api/applications";
import { createCrusaderRecord } from "../../../api/applicationStudentProfiles";
import { getUpcomingEvents } from "../../../api/admissionEvents";
import Student from "./components/Student";
import Header from "./components/Header";
import RejectApplicationModal from "./components/RejectApplicationModal";
import RejectApplicantModal from "./components/RejectApplicantModal";
import ApplicationStatus from "./components/ApplicationStatus";
import DocumentSection from "../../../admissions/documents/documentSection";
import { GroupType, AdmissionsTabs, ApplicationStatus as AppStatus } from "../../../admissions/logic/enums";
// import Footer from './components/Footer';
import CompleteReject from "./components/CompleteReject";
import { languages } from "../../../support/languages";
import StatusIndicator from "./components/StatusIndicator";

import "./index.css";

class Application extends Component {
    state = {
        validList: [],
        students: [],
        showCompleteAlert: false,
        showInviteAlert: false,
        showResubmitAlert: false,
        progress: null,
        studentId: null,
        initialSubmissionDate: null,
        submissionDate: null,
        events: [],
        inviteEnabled: false,
        showRejectApplicationModal: false,
        showRejectApplicantModal: false,
        rejectReason: "1",
        showLunchPending: true,
        isLunchPending: false,
        attendedEvent: false,
        reloadContracts: false,
        showDeleteAlert: false,
    };

    static permission = "Applications.Application";

    componentDidMount = () => {
        this.applicationId = this.props.match.params.id;
        this.props.loadApplication(this.applicationId);
        this.loadVerifications();
        this.loadEvents();
        this.loadNotes();
    };

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.students.length === 0 && this.state.students.length > 0) || prevProps.admissions !== this.props.admissions) {
            this.loadVerifications();
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        const { admissions, match } = props;

        const studentProfileId = match.params.studentId;
        const appStudent = admissions !== null ? admissions.userProfiles.find((item) => item.userProfileId === studentProfileId) : null;
        const primaryLocale = admissions !== null && admissions.primaryLocale !== null ? admissions.primaryLocale : "en";
        const status = admissions ? admissions.status : null;

        return {
            attendedEvent: admissions ? admissions.attendedEvent : false,
            initialSubmissionDate: admissions ? admissions.initialSubmissionDate : null,
            submissionDate: admissions ? admissions.submissionDate : null,
            students: admissions ? admissions.userProfiles : [],
            progress: admissions ? admissions.progress : null,
            studentId: appStudent ? appStudent.id : null,
            primaryLocale: languages.find((l) => l.value === primaryLocale).message,
            showLunchPending: status !== 2 && status !== 3 && status !== 6,
            isLunchPending: status === 5,
        };
    };

    async loadNotes() {
        try {
            this.props.ajaxStatusLoading();
            const result = (await getNotes(this.applicationId)).data.result;
            this.props.ajaxStatusComplete();
            this.props.initialize({ notes: result.notes });
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    saveNotes = async (values) => {
        try {
            this.props.ajaxStatusLoading();
            await updateNotes(this.applicationId, values.notes);
            this.props.ajaxStatusComplete();
            toast.success("Note Saved");
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    async loadEvents() {
        this.props.ajaxStatusLoading();
        let events = (await getUpcomingEvents()).data.result;
        this.props.ajaxStatusComplete();

        events = events.map((event) => ({ ...event, checked: false }));

        this.setState({ events });
    }

    async loadVerifications() {
        const studentIds = this.state.students.map((s) => s.id);

        if (studentIds.length === 0 || this.applicationId === undefined) {
            return;
        }

        this.props.ajaxStatusLoading();
        const result = (await getVerifiedDocuments(this.applicationId, studentIds)).data.result;
        this.props.ajaxStatusComplete();

        this.setState({ validList: result });
    }

    onBackToList = () => {
        if (window.previousLocation === "inreview") {
            this.props.history.push("/applications/inreview");
        } else if (window.previousLocation === "complete") {
            this.props.history.push("/applications/completed");
        } else if (window.previousLocation === "quotas") {
            this.props.history.push("/applications/quotas");
        } else if (window.previousLocation === "reports") {
            this.props.history.push("/applications/reports");
        } else {
            this.props.history.push("/applications/started");
        }
    };

    onCreateCrusader = async () => {
        try {
            this.props.ajaxStatusLoading();
            const response = (await createCrusaderRecord(this.props.match.params.id)).data.result;
            this.props.ajaxStatusComplete();

            const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            FileSaver.saveAs(blob, "crusader.xlsx");
            this.props.loadApplication(this.applicationId);
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    onComplete = () => {
        this.setState({ showCompleteAlert: true });
    };

    onResubmit = () => {
        this.setState({ showResubmitAlert: true });
    };

    onInvite = async () => {
        this.props.ajaxStatusLoading();
        const selectedEvents = this.state.events.reduce((acc, event) => {
            if (event.checked) {
                acc.push(event.id);
            }
            return acc;
        }, []);
        await addEventInvite(this.applicationId, selectedEvents);
        const events = this.state.events.slice();
        events.forEach((event) => (event.checked = false));
        this.setState({ showInviteAlert: true, events, inviteEnabled: false });
        this.loadNotes();
        this.props.ajaxStatusComplete();
    };

    onReject = () => {
        this.setState({ showRejectApplicationModal: true });
    };

    onLunchPendingChange = async () => {
        this.props.ajaxStatusLoading();
        await toggleLunchPending(this.applicationId, !this.state.isLunchPending);
        this.props.loadApplication(this.applicationId);
        this.props.ajaxStatusComplete();
    };

    toggleValidItem = (groupId, groupType) => async (element) => {
        let validList = [];

        if (this.isItemValid(groupId, groupType)) {
            validList = this.state.validList.filter((item) => item.groupingId !== groupId || item.groupType !== groupType);
        } else {
            validList = this.state.validList.slice();
            validList.push({ groupingId: groupId, groupType });
        }

        if (groupType === 2 && this.state.isLunchPending) {
            this.props.ajaxStatusLoading();
            await clearLunchPending(this.applicationId);
            this.props.loadApplication(this.applicationId);
            this.props.ajaxStatusComplete();
        }
        toggleVerifyDocumentGroup(groupId, groupType);
        this.setState({ validList });
    };

    isItemValid(groupId, groupType) {
        return this.state.validList.find((item) => item.groupingId === groupId && item.groupType === groupType) !== undefined;
    }

    isApplicationValid() {
        if (!this.props.admissions || !this.props.admissions.progress.complete) {
            return false;
        }

        if (this.props.admissions.status === AppStatus.VirtualOrientation) {
            return false;
        }

        if (this.props.admissions.status === AppStatus.Accepted || this.props.admissions.status === AppStatus.ApplicantRejected) {
            return false;
        }

        const requiredValidItems = this.state.students.length * 2 + 1; // Free lunch is optional
        const isValid = this.state.validList.length >= requiredValidItems;

        return isValid;
    }

    canCreateCrusader() {
        if (
            this.props.admissions === null ||
            (this.props.admissions.status !== AppStatus.Submitted )||
            !this.props.admissions.progress.tabProgress[AdmissionsTabs.Contracts]
        ) {
            return false;
        }

        const requiredValidItems = this.state.students.length * 2 + 2;
        return this.state.validList.length >= requiredValidItems;
    }
    handleConfirmCompleteAlert = () => {



        this.setState({ showCompleteAlert: false }, async () => {
            this.props.ajaxStatusLoading();
            await markComplete(this.applicationId);
            await this.onCreateCrusader();
            this.props.loadApplication(this.applicationId);
            this.props.ajaxStatusComplete();
        });
    };

    handleCancelCompleteAlert = () => {
        this.setState({ showCompleteAlert: false });
    };

    handleConfirmResubmitAlert = () => {
        this.setState({ showResubmitAlert: false }, async () => {
            this.props.ajaxStatusLoading();
            await markResubmit(this.applicationId);
            this.props.loadApplication(this.applicationId);
            this.props.ajaxStatusComplete();
        });
    };

    handleCancelResubmitAlert = () => {
        this.setState({ showResubmitAlert: false });
    };

    handleSelectEvent = (eventId) => {
        const events = this.state.events.slice();
        const foundEvent = events.find((event) => event.id === eventId);
        foundEvent.checked = !foundEvent.checked;

        const inviteEnabled = events.find((event) => event.checked) !== undefined;

        this.setState({ events, inviteEnabled });
    };

    handleRejectApplicationClose = () => {
        this.setState({ showRejectApplicationModal: false });
    };

    handleRejectReasonChange = (element) => {
        this.setState({ rejectReason: element.target.value });
    };

    handleRejectApplicantClose = () => {
        this.setState({ showRejectApplicantModal: false });
    };

    onRejectApplicant = () => {
        this.setState({ showRejectApplicantModal: true });
    };

    handleRejectApplicantSave = ({ note }) => {
        this.setState({ showRejectApplicantModal: false }, async () => {
            try {
                this.props.ajaxStatusLoading();
                await rejectApplicant(this.applicationId, note);
                this.props.loadApplication(this.applicationId);
                this.props.ajaxStatusComplete();
                toast.success("Applicant rejected");
                this.loadNotes();
            } catch (err) {
                this.props.ajaxStatusError(err);
            }
        });
    };

    handleRejectSave = () => {
        this.setState({ showRejectApplicationModal: false }, async () => {
            const rejectReason = Number.parseInt(this.state.rejectReason, 10);

            this.props.ajaxStatusLoading();
            await rejectApplication(this.applicationId, rejectReason);
            this.setState({ reloadContracts: !this.state.reloadContracts });
            this.props.loadApplication(this.applicationId);
            this.props.ajaxStatusComplete();
        });
    };

    handleCloseInviteAlert = () => {
        this.setState({ showInviteAlert: false });
    };

    handleDeleteClick = () => {
        this.setState({ showDeleteAlert : true});
    }
    handleDeleteCancel = () => {
        this.setState({ showDeleteAlert: false });
    }
    handleDelete = async () => {
        await deleteApplication(this.applicationId);
        this.setState({ showDeleteAlert: false });
        this.onBackToList();
    }
    handleDupeClick = async (id, userType, readonly) => {
        //console.log(id);
        //console.log(userType);
        if (readonly) {
            return;
        }
        let url = '/admin/users/crusader/' + id;
        const { history } = this.props;
        if (userType == 3) {
            const resp = await getApplicationIdForStudent(id);

            url = '/applications/application/' + resp.data.result + '/s/' + id;
        }
        window.location.href = url;
       // history.push(url);
        
    }
    renderDupes = (admissions, readonly) => {
        if (admissions !== null && admissions.duplicateUserProfiles != null && admissions.duplicateUserProfiles.length > 0) {

            return (
                <div>
                    <span className="goldWarn">Potential Duplicate Students Detected</span><br />
                    {admissions.duplicateUserProfiles.map((user) => {

                        
                        
                        // Format the date to only display the date portion
                        const formattedDate = new Date(user.dateOfBirth).toLocaleDateString();

                        return (
                            <> <Link className="goldWarn" disabled={readonly} onClick={() => this.handleDupeClick(user.id, user.userType, readonly)}>
                                User: {user.firstName + ' ' + user.lastName} DoB: {formattedDate}
                            </Link><br /></>
                        );
                    })}
                    
                </div>
            );

        } else {

            return null;
        }
    }

    render() {
        const {
            studentId,
            progress,
            initialSubmissionDate,
            submissionDate,
            showCompleteAlert,
            showInviteAlert,
            showResubmitAlert,
            events,
            inviteEnabled,
            showRejectApplicationModal,
            showRejectApplicantModal,
            rejectReason,
            primaryLocale,
            attendedEvent,
            showDeleteAlert
        } = this.state;
        const { match, admissions, handleSubmit } = this.props;

        if (studentId === null || progress === null) {
            return null;
        }

        // const readonly = admissions !== null ? admissions.status === AppStatus.Complete : false;
        const readonly = this.props.readOnly;
        const canComplete = admissions !== null ? admissions.status !== AppStatus.Complete : false;
        const canResubmit = admissions !== null ? admissions.status == AppStatus.Submitted : false;
        const isAppValid = this.isApplicationValid();
        const studentInfoLink = `/applications/application/student/${this.applicationId}/s/${match.params.studentId}`;
        const parentInfoLink = `/applications/application/parent/${this.applicationId}`;
        const householdInfoLink = `/applications/application/household/${this.applicationId}`;
        const commonDocsLink = `/applications/application/documents/${this.applicationId}`;
        const initialSubmissionDateStr = initialSubmissionDate === null ? null : moment(initialSubmissionDate).format("M/D/YY h:mm a");
        const submissionDateStr = submissionDate === null ? null : `${moment(submissionDate).format("M/D/YY")} at ${moment(submissionDate).format("h:mm a")}`;
        // const orientationEnabled =
        //     admissions !== null ? admissions.status === AppStatus.Complete || admissions.status === AppStatus.LunchPending : false;
        const orientationEnabled = true;
        const enableCreateCrusader = this.canCreateCrusader();
        const canRejectApplicant = admissions !== null && admissions.status !== AppStatus.Accepted && admissions.status !== AppStatus.ApplicantRejected;
        
        return (
            <div className="application">
                <div className="application-top">
                    <div className="application-left">
                        <div className="application__actions">
                            <button className="btn" onClick={this.onBackToList}>
                                &lt; Back to Student List
                            </button>
                            <StatusIndicator status={admissions !== null ? admissions.status : 0} />
                        </div>
                        <div className="application-student-list">
                            {this.state.students.map((student) => {
                                if (this.applicationId) {
                                    return <Student key={student.id} student={student} applicationId={this.applicationId} />;
                                }
                                return null;
                            })}
                            {this.renderDupes(admissions, readonly)}
                        </div>
                        
                        <div className="hr-line-medium" />
                        <Header title="Application" />
                        <ApplicationStatus description="Student Information" isComplete={progress.tabProgress["Admissions.Student"]} url={studentInfoLink} />
                        <DocumentSection
                            applicationId={this.props.match.params.id}
                            groupId={studentId}
                            groupType={GroupType.BirthCertificate}
                            staffValid={this.isItemValid(studentId, GroupType.BirthCertificate)}
                            onStaffValidChange={this.toggleValidItem(studentId, GroupType.BirthCertificate)}
                            description={() => (
                                <div>
                                    <FormattedMessage id="student.citizenship" />
                                </div>
                            )}
                            readonly={readonly}
                            isStaff={true}
                        />
                        <DocumentSection
                            applicationId={this.props.match.params.id}
                            groupId={studentId}
                            groupType={GroupType.PersonalStatement}
                            staffValid={this.isItemValid(studentId, GroupType.PersonalStatement)}
                            onStaffValidChange={this.toggleValidItem(studentId, GroupType.PersonalStatement)}
                            description={() => (
                                <div>
                                    <FormattedMessage id="student.personal.personalstatement" />
                                </div>
                            )}
                            readonly={readonly}
                            isStaff={true}
                        />
                        <DocumentSection
                            applicationId={this.props.match.params.id}
                            groupId={studentId}
                            groupType={GroupType.ReportCard}
                            staffValid={this.isItemValid(studentId, GroupType.ReportCard)}
                            onStaffValidChange={this.toggleValidItem(studentId, GroupType.ReportCard)}
                            description={() => (
                                <div>
                                    <FormattedMessage id="student.personal.reportcard" />
                                </div>
                            )}
                            readonly={readonly}
                            isStaff={true}
                        />
                        <div className="hr-line" />
                        <ApplicationStatus
                            description="Parent/Guardian Information"
                            isComplete={progress.tabProgress["Admissions.Parent"]}
                            url={parentInfoLink}
                        />
                        <div className="hr-line" />
                        <ApplicationStatus
                            description="Household Information"
                            isComplete={progress.tabProgress["Admissions.Household"]}
                            url={householdInfoLink}
                        />
                        <div className="hr-line" />
                        <ApplicationStatus description="Common Documents" isComplete={progress.tabProgress["Admissions.CommonDocs"]} url={commonDocsLink} />
                        <DocumentSection
                            applicationId={this.props.match.params.id}
                            groupId={this.props.match.params.id}
                            groupType={GroupType.ProofOfResidency}
                            staffValid={this.isItemValid(this.props.match.params.id, GroupType.ProofOfResidency)}
                            onStaffValidChange={this.toggleValidItem(this.props.match.params.id, GroupType.ProofOfResidency)}
                            description={() => (
                                <div>
                                    <FormattedMessage id="documents.residency.description1" defaultMessage="[documents.residency.description1]" tagName="div" />
                                    <ul>
                                        <FormattedMessage
                                            id="documents.residency.description2"
                                            defaultMessage="documents.residency.description2"
                                            tagName="li"
                                        />
                                        <FormattedMessage
                                            id="documents.residency.description3"
                                            defaultMessage="documents.residency.description3"
                                            tagName="li"
                                        />
                                        <FormattedMessage
                                            id="documents.residency.description4"
                                            defaultMessage="documents.residency.description4"
                                            tagName="li"
                                        />
                                    </ul>
                                </div>
                            )}
                            readonly={readonly}
                            isStaff={true}
                        />
                        <DocumentSection
                            applicationId={this.props.match.params.id}
                            groupId={this.props.match.params.id}
                            groupType={GroupType.ReducedLunch}
                            description={() => (
                                <div>
                                    <FormattedMessage id="documents.lunch.description1" defaultMessage="[documents.lunch.description1]" tagName="div" />
                                </div>
                            )}
                            readonly={readonly}
                            isStaff={true}
                            hideValid
                        />
                    </div>
                    <div className="application-right">
                        <CompleteReject
                            attendedEvent={attendedEvent}
                            initialSubmissionDate={initialSubmissionDateStr}
                            submissionDate={submissionDateStr}
                            canComplete={enableCreateCrusader && isAppValid}
                            onComplete={this.onComplete}
                            onInvite={this.onInvite}
                            onReject={this.onReject}
                            onResubmit={this.onResubmit}
                            events={events}
                            virtualOrientation={this.props.admissionSettings.orientationVideoEnabled}
                            onSelectEvent={this.handleSelectEvent}
                            inviteEnabled={inviteEnabled}
                            locale={primaryLocale}
                            orientationEnabled={orientationEnabled}
                            onSaveNotes={handleSubmit(this.saveNotes)}
                            onCancelNotes={() => this.props.reset()}
                            onRejectApplicant={this.onRejectApplicant}
                            canRejectApplicant={canRejectApplicant}
                            applicationStatus={this.props.admissions.status}
                            canResubmit={canResubmit}
                            readonly={readonly}
                            onDelete={this.handleDeleteClick }
                        />
                    </div>
                </div>
                <div className="hr-line-medium" />

                <div className="application-bottom">
                    <div className="application-left">
                        <div className="orientation-contracts-container">
                            <Header title="Orientation" />

                            <DocumentSection
                                groupId={this.props.match.params.id}
                                groupType={GroupType.Contracts}
                                staffValid={this.isItemValid(this.props.match.params.id, GroupType.Contracts)}
                                onStaffValidChange={this.toggleValidItem(this.props.match.params.id, GroupType.Contracts)}
                                description={() => <div>Contracts</div>}
                                readonly={!orientationEnabled || readonly}
                                isStaff={true}
                                reloadData={this.state.reloadContracts}
                            />
                        </div>
                    </div>
                    <div className="application-right">
                        <div className="crusader-container">
                            
                        </div>
                    </div>
                </div>

                <SweetAlert
                    show={showCompleteAlert}
                    title="Mark Complete"
                    type="warning"
                    showCancel
                    onConfirm={this.handleConfirmCompleteAlert}
                    onCancel={this.handleCancelCompleteAlert}
                    confirmBtnText="OK"
                    cancelBtnText="CANCEL"
                >
                    {this.props.admissionSettings.orientationVideoEnabled
                        ? "Mark this application complete, create an Onward Record and invite to family orientation?"
                        : "Would you like to mark this application as complete and create an Onward Record?"}
                </SweetAlert>

                <SweetAlert show={showInviteAlert} title="Invite Sent" type="info" onConfirm={this.handleCloseInviteAlert} confirmBtnText="OK">
                    The parent has been sent an email or text with the list of orientation events.
                </SweetAlert>

                <SweetAlert
                    show={showResubmitAlert}
                    title="Mark Resubmit"
                    type="warning"
                    showCancel
                    onConfirm={this.handleConfirmResubmitAlert}
                    onCancel={this.handleCancelResubmitAlert}
                    confirmBtnText="OK"
                    cancelBtnText="CANCEL"
                >
                    Would you like to mark this application to resubmit?
                </SweetAlert>

                <SweetAlert
                    show={showDeleteAlert}
                    title="Delete Application"
                    type="warning"
                    showCancel
                    onConfirm={this.handleDelete}
                    onCancel={this.handleDeleteCancel}
                    confirmBtnText="OK"
                    cancelBtnText="CANCEL"
                >
                    Are you sure you want to delete this application?
                </SweetAlert>


                {showRejectApplicantModal && (
                    <RejectApplicantModal
                        isOpen={showRejectApplicantModal}
                        onRequestClose={this.handleRejectApplicantClose}
                        onSave={this.handleRejectApplicantSave}
                    />
                )}

                <RejectApplicationModal
                    isOpen={showRejectApplicationModal}
                    onRequestClose={this.handleRejectApplicationClose}
                    onSave={this.handleRejectSave}
                    onRejectReasonChange={this.handleRejectReasonChange}
                    rejectReason={rejectReason}
                    applicationStatus={this.props.admissions.status}
                    didVirtualOrientation={this.props.admissions.didVirtualOrientation}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ admissions }) => {
    return { admissions };
};

Application = reduxForm({ form: "ApplicationNotes" })(Application);

Application = connect(mapStateToProps)(Application);
Application = withAdmissions(Application);
Application = withAdmissionSettings(Application);
Application = withRouter(Application);
Application = withAjaxStatus(Application);

export default Application;
