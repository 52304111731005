import axios from 'axios';

export function sendReminders(eventId) {
    return axios.post('/api/services/app/ApplicationEvent/SendReminders', { id: eventId });
}

export function acceptEvent(applicationId, eventId) {
    return axios.post('/api/services/app/ApplicationEvent/AcceptEvent', { applicationId, eventId });
}

export function getInvites(applicationId) {
    return axios.get('/api/services/app/ApplicationEvent/GetInvites', { params: { id: applicationId } });
}
