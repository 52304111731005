import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";

import "./Donated.css";

const Donated = ({ data, isActive, onSelect }) => {
  const date = moment.utc(data.date).format("M/D/YY");
  const clsName = isActive ? "donated donated-active" : "donated";
  return (
    <div className={clsName} onClick={onSelect} onKeyDown={() => {}} tabIndex="0" role="button">
      <div className="rowHeader">
        <span>DONATED SCHOLARSHIPS</span>
        <div className="spacer" /> <span>As of {date}</span>
      </div>
      <div className="row">
        <span />
        <span>Scholarships</span>
        <span>Current Term</span>
        <span>Annual</span>
      </div>
      <div className="line" />
      <div className="row">
        <span>Promised</span>
        <span>{data.promisedCount}</span>
        <span>{formatCurrencyNoDecimal(data.promisedTerm)}</span>
        <span>{formatCurrencyNoDecimal(data.promisedAnnual)}</span>
      </div>
      <div className="row">
        <span>Donated</span>
        <span>{data.donatedCount ? data.donatedCount : ""}</span>
        <span>{data.donatedTerm ? formatCurrencyNoDecimal(data.donatedTerm) : ""}</span>
        <span>{formatCurrencyNoDecimal(data.donatedAnnual)}</span>
      </div>
      <div className="row-spacer" />
      <div className="line" />
      <div className="row">
        <span>Remaining Awards</span>
        <span>{data.remainingCount ? data.remainingCount : ""}</span>
        <span>{data.remainingTerm ? formatCurrencyNoDecimal(data.remainingTerm) : ""}</span>
        <span>{formatCurrencyNoDecimal(data.remainingAnnual)}</span>
      </div>
    </div>
  );
};

Donated.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    promisedCount: PropTypes.number,
    promisedTerm: PropTypes.number,
    promisedAnnual: PropTypes.number,
    donatedCount: PropTypes.number,
    donatedTerm: PropTypes.number,
    donatedAnnual: PropTypes.number,
    remainingCount: PropTypes.number,
    remainingTerm: PropTypes.number,
    remainingAnnual: PropTypes.number
  })
};
export default Donated;
