import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { verifyEmail } from '../../api/login';

class VerifyEmail extends Component {
    state = {
        status: 1,
        showLogin: false
    };

    componentDidMount = async () => {
        const query = this.props.location.search.replace('?', '');
        const params = query.split('&');
        const email = params[0].split('=')[1];
        const code = params[1].split('=')[1];
        try {
            const results = await verifyEmail(email, code);

            if (!results.data.success) {
                this.setState({
                    status: 3
                });
                return;
            }

            this.setState({
                status: 2
            });

            setTimeout(() => {
                this.setState({ showLogin: true });
            }, 1000);
        } catch (err) {
            this.setState({
                status: 3
            });
        }
    };

    render() {
        if (this.state.showLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <div>
                {this.state.status === 1 ? (
                    <FormattedMessage
                        id="verify.checking"
                        defaultMessage="Verifying email and code. Please wait..."
                        children={formattedMessage => <h1>{formattedMessage}</h1>}
                    />
                ) : null}
                {this.state.status === 2 ? (
                    <FormattedMessage
                        id="verify.complete"
                        defaultMessage="Email and Code verified successfully..."
                        children={formattedMessage => <h1>{formattedMessage}</h1>}
                    />
                ) : null}
                {this.state.status === 3 ? (
                    <FormattedMessage
                        id="verify.error"
                        defaultMessage="cannot verify email or code..."
                        children={formattedMessage => <h1>{formattedMessage}</h1>}
                    />
                ) : null}
            </div>
        );
    }
}

VerifyEmail.propTypes = {
    onShowLogin: PropTypes.func.isRequired
};

export default withRouter(VerifyEmail);
