import React, { useEffect, useReducer } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import * as activityCodeApi from "../api/activityCodes";
import ActivityCodeForm from "./activityCodeForm";
import LoadingIndicator from "../../common/loadingIndicator";
import SimpleDialog from "../../common/simpleDialog";
import useAjaxStatus from "../../common/useAjaxStatus";
import ActivityCodeGrid from "./activityCodeGrid";

import { EMPTY_GUID } from "../../support/constants";

const useStyles = makeStyles((theme) => ({
    activityCodes: {},
    search: {
        marginBottom: theme.spacing(1),
    },
}));

const defaultState = {
    activityCodes: [],
    editRowItem: null,
    isAdding: false,
    isLoading: true,
    filter: () => {
        return true;
    },
};

const activityCodeReducer = (state, action) => {
    const newValue = { ...state };
    if (action.type === "SET_CODES") {
        newValue.activityCodes = action.codes;

        return newValue;
    }

    if (action.type === "SET_LOADING") {
        newValue.isLoading = action.isLoading;
        return newValue;
    }

    if (action.type === "SET_ISADDING") {
        newValue.isAdding = action.isAdding;
        return newValue;
    }

    if (action.type === "ADD_CODE") {
        newValue.activityCodes.push(action.item);
        return newValue;
    }

    if (action.type === "EDIT") {
        const index = newValue.activityCodes.findIndex((i) => i.id === action.item.id);
        newValue.activityCodes[index] = action.item;
        return newValue;
    }

    if (action.type === "SET_FILTER") {
        const lowerCaseSearchText = action.searchText.toLowerCase();

        if (action.searchText === "") {
            newValue.filter = () => {
                return true;
            };
            return newValue;
        }

        newValue.filter = (item) => {
            return item.activityCode.toString().includes(lowerCaseSearchText) || item.description.toLowerCase().includes(lowerCaseSearchText);
        };

        return newValue;
    }

    return defaultState;
};

const ActivityCodes = () => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const [codeState, dispatchCodeAction] = useReducer(activityCodeReducer, defaultState);

    const handleSave = async (activityCode) => {
        try {
            dispatchCodeAction({ type: "SET_LOADING", isLoading: true });

            if (activityCode.id === EMPTY_GUID) {
                const s = (await activityCodeApi.create(activityCode)).data.result;
                dispatchCodeAction({ type: "ADD_CODE", item: s });
                dispatchCodeAction({ type: "SET_ISADDING", isAdding: false });
            } else {
                await activityCodeApi.update(activityCode);
                dispatchCodeAction({ type: "EDIT", item: activityCode });
            }

            toast.success("Changes saved successfully");
            dispatchCodeAction({ type: "SET_ISADDING", isAdding: false });
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            dispatchCodeAction({ type: "SET_LOADING", isLoading: false });
        }
    };

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        (async () => {
            try {
                dispatchCodeAction({ type: "SET_LOADING", isLoading: true });
                dispatchCodeAction({ type: "SET_ISADDING", isAdding: false });
                const response = activityCodeApi.getAll(tokenSource.token);
                const data = (await response).data.result;
                dispatchCodeAction({ type: "SET_CODES", codes: data });
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
                dispatchCodeAction({ type: "SET_LOADING", isLoading: false });
            }
        })();

        return () => {
            tokenSource.cancel();
        };
    }, [ajaxErrorWrapper]);

    return (
        <div className={classes.ActivityCodes}>
            <LoadingIndicator loading={codeState.isLoading} />

            <Box pb={1} display="flex">
                <Typography variant="h5"> Event Gear-Up Activity Codes</Typography>
                <Box flex={1} display="flex" ml={1}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            dispatchCodeAction({ type: "SET_ISADDING", isAdding: true });
                        }}
                        className={classes.addButton}
                        startIcon={<AddIcon />}
                    >
                        Add
                    </Button>
                </Box>
            </Box>

            <OutlinedInput
                name="search"
                margin="dense"
                startAdornment={<SearchIcon />}
                onChange={(event) => {
                    dispatchCodeAction({ type: "SET_FILTER", searchText: event.target.value });
                }}
                className={classes.search}
                placeholder="Search Codes"
            />
            <ActivityCodeGrid data={codeState.activityCodes.filter(codeState.filter)} onEditSubmit={handleSave} />

            {codeState.isAdding && (
                <SimpleDialog
                    className={classes.activityCodeDialog}
                    open={codeState.isAdding}
                    onClose={() => dispatchCodeAction({ type: "SET_ISADDING", isAdding: false })}
                    title="Add Gear-Up Activity Code"
                >
                    <ActivityCodeForm activityCode={null} saveButtonText="Add" activityCodeEditEnabled onSubmit={handleSave} />
                </SimpleDialog>
            )}
        </div>
    );
};
export default ActivityCodes;
