import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";

import moment from "moment";
import Button from "../../support/components/Button";

import Compact from "./Compact";

import "./Form.css";
import Membership from "./Membership";
import Instructions from "./Instructions";

const Form = ({ handleSubmit, onSave, formData, students, match, readonly }) => {
    const todaysDate = moment().format("M/D/YYYY");
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);
    const intl = useIntl();

    return (
        <div className="contracts-container" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            {!isDesktop && (
                <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                    {showInstructions
                        ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                        : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                </Link>
            )}
            {!isDesktop && showInstructions && <Instructions isDesktop={isDesktop} />}
            <div className="content">
                <div className="hr-line-medium" />
                <FormattedMessage id="nav.contracts" defaultMessage="[Contracts]" tagName="h2" />
                <Compact todaysDate={todaysDate} students={students} data={formData.compactForm} readonly={match.params.id || readonly} />
                <div className="hr-line extra-margin" />
                <Membership todaysDate={todaysDate} data={formData.membershipForm} readonly={match.params.id || readonly} />
                {!match.params.id && (
                    <>
                        <div className="hr-line-medium" />
                        <div className="row">
                            <div className="parent-form-buttons">
                                <FormattedMessage
                                    id="contracts.sign"
                                    children={(formattedMessage) => (
                                        <Button className="margin-right" onClick={handleSubmit(onSave)} id="contracts.sign" defaultMessage={formattedMessage} disabled={readonly } />
                                    )}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Hidden only="xs">
                <Instructions isDesktop={isDesktop} />
            </Hidden>
        </div>
    );
};

export default Form;
