import React from "react";
import { injectIntl } from "react-intl";

let IntlOption = ({ intl, id, defaultMessage, value }) => {
  return <option value={value}>{intl.formatMessage({ id, defaultMessage })}</option>;
};

let IntlMessage = ({ intl, id, defaultMessage }) => {
  return intl.formatMessage({ id, defaultMessage });
};

IntlOption = injectIntl(IntlOption);
IntlMessage = injectIntl(IntlMessage);

export const languages = [
  {
    value: "en",
    label: <IntlOption key="en" value="en" id="login.english" defaultMessage="[English]" />,
    message: <IntlMessage key="en" value="en" id="login.english" defaultMessage="[English]" />,
  },
  {
    value: "es-MX",
    label: <IntlOption key="es-MX" value="es-MX" id="login.spanish" defaultMessage="[spanish]" />,
    message: <IntlMessage key="es-MX" value="es-MX" id="login.spanish" defaultMessage="[spanish]" />,
  },
];
