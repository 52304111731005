import axios from "axios";

export function addTag(input) {
    return axios.post("/api/services/app/Tagging/AddTag",  input );
}

export function updateTag(input) {
    return axios.post("/api/services/app/Tagging/UpdateTag", input);
}

export function updateSubTag(input) {
    return axios.post("/api/services/app/Tagging/UpdateSubTag", input);
}

export function addSubTag(input) {
    return axios.post("/api/services/app/Tagging/AddSubTag", input);
}

export function addClassification(input) {
    return axios.post("/api/services/app/Tagging/AddClassification",  input );
}

export function updateClassification(input) {
    return axios.put("/api/services/app/Tagging/UpdateClassification", input);
}

export function searchTags(input) {
    return axios.put("/api/services/app/Tagging/SearchTags", input);
}

export function getAllTags(input) {
    return axios.post("/api/services/app/Tagging/ListTags",  input );
}

export function getActiveClassifications() {
    return axios.get("/api/services/app/Tagging/GetActiveClassifications");
}

export function getInactiveClassifications() {
    return axios.get("/api/services/app/Tagging/GetInactiveClassifications");
}

export function getActiveTags() {
    return axios.get("/api/services/app/Tagging/GetActiveTags");
}

export function getInactiveTags() {
    return axios.get("/api/services/app/Tagging/GetInactiveTags");
}

export function returnChoices(input) {
    return axios.post("/api/services/app/Tagging/ReturnChoices", input);
}

export function returnEventTags(input) {
    
    return axios.post("/api/services/app/Tagging/ReturnEventTags",  input );
}

export function removeEventTag(input) {

    return axios.post("/api/services/app/Tagging/RemoveEventTag", input);
}


export function addEventTag(input) {

    return axios.post("/api/services/app/Tagging/AddEventTag", input);
}

export function updateEventTag(input) {

    return axios.post("/api/services/app/Tagging/UpdateEventTag", input);
}

export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}

export function getAttendanceBreakdown(userProfileId, programEventId, programEventDateId) {
    return axios.get("/api/services/app/Tagging/GetAttendanceBreakdown", { params: { userProfileId, programEventId, programEventDateId } });
}

export function returnAdvisorTags(input) {

    return axios.post("/api/services/app/Tagging/ReturnAdvisorTags", input);
}

export function removeAdvisorTag(input) {

    return axios.post("/api/services/app/Tagging/RemoveAdvisorTag", input);
}


export function addAdvisorTag(input) {

    return axios.post("/api/services/app/Tagging/AddAdvisorTag", input);
}

export function updateAdvisorTag(input) {

    return axios.post("/api/services/app/Tagging/UpdateAdvisorTag", input);
}