import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getAllSchools } from "../api/programEvents";

const useSchool = () => {
    const [schoolList, setSchoolList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getSchoolListAsync = useCallback(async (tokenSource) => {
        try {
            const response = await getAllSchools(tokenSource.token);
            setSchoolList(response.data.result);
        } catch (ex) {
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        getSchoolListAsync(tokenSource);
        return () => {
            tokenSource.cancel();
        };
    }, [getSchoolListAsync]);

    return {
        schoolList,
        loading,
    };
};

export default useSchool;
