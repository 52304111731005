import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { UserType } from "../../../support/enums";
import enumHelper from "../../../support/logic/enumHelper";

export const OverrideType = {
    Removed: 0,
    Added: 1,
};

const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
        display: "grid",
        gridTemplateColumns: "auto 100px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title actionButton" "subtitle actionButton"`,
        borderBottom: `3px solid ${theme.palette.secondary.main}`,
        paddingBottom: theme.spacing(1),
    },
    title: {
        gridArea: "title",
        fontWeight: 600,
        textTransform: "uppercase",
    },
    subtitle: {
        gridArea: "subtitle",
    },
    actionButton: {
        gridArea: "actionButton",
        height: 50,
    },
    participantContainer: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"fullName deleteBtn" "crusaderId deleteBtn"`,
        borderBottom: `1px solid ${theme.palette.lightGray.main}`,
        marginTop: theme.spacing(1),
    },
    fullName: {
        gridArea: "fullName",
    },
    crusaderId: {
        gridArea: "crusaderId",
    },
    deleteBtn: {
        gridArea: "deleteBtn",
        justifySelf: "center",
        alignSelf: "center",
    },
}));

const OverrideParticipantList = ({ type, status, title, subtitle, participants, onActionBtnClick, onRemove, disabled }) => {
    const classes = useStyles();

    const renderSubtitle = (dto) => {
        switch (dto.participant.userType) {
            case UserType.Crusader:
                return dto.participant.crusaderId;
            case UserType.Parent:
                return dto.participant.email;
            default:
                return "";
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography className={classes.title} variant="h6" color="textPrimary">
                    {title}
                </Typography>
                <Typography className={classes.subtitle} variant="body1">
                    {subtitle}
                </Typography>
                <Button
                    className={classes.actionButton}
                    variant="outlined"
                    color="primary"
                    disabled={disabled }
                    startIcon={<AddIcon />}
                    onClick={onActionBtnClick}
                    //disabled={type === OverrideType.Removed ? !statusList.includes(status) : statusList.includes(status)}
                >
                    Add
                </Button>
            </div>
            <div>
                {participants.map((dto) => (
                    <div key={dto.userProfileId} className={classes.participantContainer}>
                        <Typography className={classes.fullName} variant="h6" color="textPrimary" gutterBottom>
                            {`${dto.participant.firstName} ${dto.participant.lastName} | ${enumHelper.getDisplayName(UserType, dto.participant.userType)}`}
                        </Typography>
                        <Typography className={classes.crusaderId} variant="subtitle2" gutterBottom>
                            {renderSubtitle(dto)}
                        </Typography>
                        <IconButton
                            disabled={disabled}
                            aria-label="remove"
                            className={classes.deleteBtn}
                            onClick={(e) => {
                                e.preventDefault();
                                onRemove(dto);
                            }}
                            //disabled={type === OverrideType.Added ? !statusList.includes(status) : statusList.includes(status)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OverrideParticipantList;
