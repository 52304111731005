import axios from "axios";

export function getCurrent(recruitmentCycleId) {
    return axios.get("/api/services/app/ApplicationQuota/GetCurrent", { params: { recruitmentCycleId } });
}

export function getOverallStats(recruitmentCycleId) {
    return axios.get("/api/services/app/ApplicationQuota/GetOverallStats", { params: { recruitmentCycleId } });
}

export function update(recruitmentCycleId, count) {
    return axios.put("/api/services/app/ApplicationQuota/Update", { recruitmentCycleId, count });
}

export function updateSchool(recruitmentCycleId, schoolId, count) {
    return axios.put("/api/services/app/ApplicationQuota/UpdateSchool", { schoolId, count, recruitmentCycleId });
}

export function updateGrade(recruitmentCycleId, grade, count) {
    return axios.put("/api/services/app/ApplicationQuota/UpdateGrade", { grade, count, recruitmentCycleId });
}

export function exportCrusaders(recruitmentCycleId) {
    return axios.post("/api/services/app/ApplicationQuota/ExportCrusaders", { recruitmentCycleId });
}

export function getSchoolQuotas(recruitmentCycleId, schoolId, searchText, gradeNumber) {
    return axios.get("/api/services/app/ApplicationQuota/GetSchoolQuotas", { params: { recruitmentCycleId, schoolId, searchText, gradeNumber } });
}
