import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field } from "react-final-form";
import Box from "@material-ui/core/Box";
import { FinalFormTextField, FinalFormMultiSelectChipDropdown } from "../../../common/formWrappers";
import { EventLocationType } from "../../enums";
import { composeValidators, maxLength, required } from "../../../common/formValidators";

const useStyles = makeStyles((theme) => ({
    chip: {
        "& .MuiChip-label": {
            color: "white",
        },
        "& .MuiChip-deleteIconColorPrimary": {
            color: "white",
        },
    },
    advisorName: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    advisorDetail: {
        color: theme.palette.text.secondary,
    },
    advisorInfo: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

const Logistics = ({ advisors, locationType, values }) => {
    const classes = useStyles();


    const renderAdvisors = () => {
        if (!values.logistics?.advisors || values.logistics.advisors.length === 0) {
            return null
        }

        return values.logistics.advisors.map((selectedAdvisor, index) => {
            const advisorDetails = advisors.find(a => a.userId === selectedAdvisor.userId);

            return (
                <div key={index} className={classes.advisorInfo}>
                    <span className={classes.advisorName}>{selectedAdvisor.label}</span>
                    {advisorDetails?.phoneNumber && (
                        <span className={classes.advisorDetail}>Phone: {advisorDetails.phoneNumber}</span>
                    )}
                    {advisorDetails?.emailAddress && (
                        <span className={classes.advisorDetail}>Email: {advisorDetails.emailAddress}</span>
                    )}
                </div>
            );
        });
    };

    return (
        <Box width="80%" display="flex" flexDirection="column" alignItems="flex-start">
            <Field
                chipClassName={classes.chip}
                component={FinalFormMultiSelectChipDropdown}
                name="logistics.advisors"
                label="Advisors"
                placeholder="Select advisor(s)..."
                margin="dense"
                className={classes.advisors}
                choices={advisors}
                filterSelectedOptions
                getOptionSelected={(option, value) => {
                    return option.userId === value.userId;
                }}
                fullWidth
                ChipProps={{ color: "primary" }}
            />
            <div>{renderAdvisors()}</div>
            <Field
                component={FinalFormTextField}
                name="logistics.targetParticipantsDescription"
                label="Target Participants"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                maxLength={5000}
                minRows={2}
                maxRows={4}
            />
            <Field
                component={FinalFormTextField}
                name="logistics.programDescription"
                label="Program Description"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                maxLength={5000}
                minRows={2}
                maxRows={4}
            />
            <Field
                component={FinalFormTextField}
                name="logistics.requiredAttachmentsDescription"
                label="Required Attachments"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                maxLength={5000}
                minRows={2}
                maxRows={4}
            />
            {[EventLocationType.Both, null, EventLocationType.Virtual].includes(locationType) && (
                <Field
                    component={FinalFormTextField}
                    name="logistics.virtualLink"
                    label="Zoom Link"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    multiline
                    maxLength={5000}
                    minRows={2}
                    maxRows={4}
                    validate={composeValidators(required, maxLength(5000))}
                />
            )}
            <Field
                component={FinalFormTextField}
                name="logistics.eventTaskList"
                label="Event Task List"
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                maxLength={5000}
                minRows={2}
                maxRows={4}
            />
        </Box>
    );
};

export default Logistics;
