import * as enums from "./enums";

const getStatusValue = statusWord => {
  let results = 5;
  switch (statusWord) {
    case "Full Time":
      results = 1;
      break;
    case "3/4 Time":
      results = 2;
      break;
    case "1/2 Time":
      results = 3;
      break;
    case "1/4 Time":
      results = 4;
      break;
    default:
      results = 5;
      break;
  }

  return results;
};

const buildTermOption = (period, scholarship, termStructure) => {
  return { id: period, startYear: scholarship.startYear, endYear: scholarship.endYear, termStructure, period };
};

const buildCompleteTermOptions = (scholarship, termStructure) => {
  const max = termStructure === enums.termType.semester ? 2 : 3;
  const options = [];
  for (let period = 1; period <= max; period++) {
    options.push(buildTermOption(period, scholarship, termStructure));
  }
  return options;
};

const determinePeriodsNeeded = (scholarship, maxPeriod, existingDetails) => {
  const details = [...existingDetails].reverse();
  const currentPeriods = details.map(detail => (scholarship.id === detail.scholarshipId ? detail.period : 0));
  const periods = [];

  if (currentPeriods.indexOf(1) === -1) periods.push(1);
  if (currentPeriods.indexOf(2) === -1) periods.push(2);
  if (maxPeriod === 3 && currentPeriods.indexOf(3) === -1) periods.push(3);
  return periods;
};
const buildTermOptions = (scholarship, termStructure, existingDetails) => {
  const maxPeriod = termStructure === enums.termType.semester ? 2 : 3;
  const periods = determinePeriodsNeeded(scholarship, maxPeriod, existingDetails);
  const options = [];
  for (let index = 0; index < periods.length; index++) {
    options.push(buildTermOption(periods[index], scholarship, termStructure));
  }
  return options;
};

export const doCreateNewTermOptions = (scholarship, schools, student) => {
  if (student.isAt8Semesters === true || scholarship.status !== enums.scholarshipStatus.started) return [];
  const userInstitution = schools && schools.length > 0 ? schools.find(item => item.userInstitutionId === item.id) : null;
  const school = userInstitution || null;
  const schoolTermStructure = school ? school.userInstitutionTermStructure : null;
  const termDetails = school ? school.termDetails : null;
  const termDetail = termDetails && termDetails.length > 0 ? termDetails[0] : null;

  //CC-533
  /*
    const termsToOmit = [];
    for (let i = 0; i < schools.length; i++) {
        const school = schools[i];
        for (let j = 0; j < school.termDetails.length; j++) {
            const termDetail = school.termDetails[j];
            termsToOmit.push({ termStructure: termDetail.termStructure, period: termDetail.period, endYear: termDetail.endYear });
        }
    }

    //console.log(termsToOmit);
    */

  if (!termDetail || termDetail.endYear <= scholarship.startYear) {
    if (!schoolTermStructure) return [];
    return buildCompleteTermOptions(scholarship, schoolTermStructure);
  }

  return buildTermOptions(scholarship, schoolTermStructure, termDetails);
};

export const certifiedSortMethod = (a, b) => {
  const aValue = getStatusValue(a);
  const bValue = getStatusValue(b);
  if (aValue === bValue) return 0;
  if (aValue > bValue) return 1;
  if (aValue < bValue) return -1;
};
