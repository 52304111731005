import React, { useState, useEffect, props, useImperativeHandle, forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const SelectAllTransferList = forwardRef((props, ref)=> {
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = useState([]);
    


    useEffect(() => {
        //console.log(props);
        let lOps = props.optFields;
        if (props.selected && props.selected.length > 0) {
            props.selected.forEach(sel => {
                lOps = lOps.filter(element => element.value != sel.value);

            })
            
        }

        setLeft(lOps || []);
        
    }, [props.optFields]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, props.selected);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    
    const handleCheckedRight = () => {
        const selection = props.selected;
        props.selectionCallback(selection.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        props.selectionCallback(not(props.selected, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const selectedItems = () => {
        return props.selected;
    }


    const moveMultipleElementsUp = () => {
        
        // Create a copy of the array to avoid direct mutation
        let newArray = [...props.selected];

        rightChecked.forEach(element => {
            const index = newArray.indexOf(element);
            if (index > 0 && newArray[index - 1] !== element) {
                // Swap the element with the one before it
                [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]];
            }
        });
        props.selectionCallback(newArray);
       
    };

    const moveMultipleElementsDown = () => {
        // Create a copy of the array to avoid direct mutation
        let newArray = [...props.selected];

        rightChecked.forEach(element => {
            const index = newArray.indexOf(element);
            if (index >= 0 && index < newArray.length - 1 && newArray[index + 1] !== element) {
                // Swap the element with the one after it
                [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]];
            }
        });
        props.selectionCallback(newArray);
    };

    const moveToTop = () => {
        let newArray = [...props.selected];
        let selectedElements = rightChecked.slice(); // Copy of the selected elements
        let nonSelectedElements = newArray.filter(item => !rightChecked.includes(item));

        // Concatenating selected elements at the beginning
        newArray = [...selectedElements, ...nonSelectedElements];
        props.selectionCallback(newArray);
    };

    const moveToBottom = () => {
        let newArray = [...props.selected];
        let selectedElements = rightChecked.slice(); // Copy of the selected elements
        let nonSelectedElements = newArray.filter(item => !rightChecked.includes(item));

        // Concatenating selected elements at the end
        newArray = [...nonSelectedElements, ...selectedElements];
        props.selectionCallback(newArray);
    };


    useImperativeHandle(ref, () => ({
        selectedItems,
    }));
    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: '100%',
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.value}-label`;

                    return (
                        <ListItem
                            key={value.value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={` ${value.name }`} />
                        </ListItem>
                    );
                })}
            </List>
        </Card>
    );
   
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={5}>{customList('Available Fields', left)}</Grid>
            <Grid item xs={1}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                        className="fieldSelectorButton"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                        className="fieldSelectorButton"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={5}>{customList('Chosen Fields', props.selected)}</Grid> 
            <Grid item xs={1}>
            <Grid container direction="column" alignItems="center">
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                        onClick={moveToTop}
                    disabled={rightChecked.length === 0}
                        aria-label="move selected to top"
                        className="fieldSelectorButton"
                >
                   Top
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={moveMultipleElementsUp}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected up"
                        className="fieldSelectorButton"
                    >
                        Up
                    </Button>
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={moveMultipleElementsDown}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected down"
                   className="fieldSelectorButton"
                >
                    Down
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={moveToBottom}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected to bottom"
                        className="fieldSelectorButton"
                    >
                        Bottom
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
});
export default SelectAllTransferList;