import React, { Component, Fragment } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationStatusContext } from "../../support/context";

import { loadStaffMiddleSchools } from "../../redux/codeTables";
import * as StaffApi from "../../api/staffQueries";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";

import Started from "./started";
import InReview from "./inreview";
import Resubmit from "./resubmit";
import Rejected from "./rejected";
import Complete from "./complete";
import Events from "./events";
import Quotas from "./quotas";
import Reports from "./reports";
import Application from "./application";
import AppHeader from "./application/AppHeader";
import AppNavMenu from "./application/AppNavMenu";
import { getAppReport } from "../../api/applicationReports";

import Student from "../../admissions/student";
import Household from "../../admissions/household";
import Documents from "../../admissions/documents";
import Parent from "../../admissions/parent";
import Contracts from "../../admissions/contracts";
import OrientationVideo from "../../admissions/orientationVideo";
import Search from "../common/search";
import RecruitmentCycleSelector from "../../support/components/recruitmentCycleSelector";

import StaffNavMenu from '../admissions/common/staffNavMenu';

import "./index.css";

const GlobalOptions = ({ onSearch, selectedCycle, onSelectedCycleChange, navigationStatus }) => {
    return (
        <div>
            <div style={{ marginTop: "15px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Search onClick={onSearch} />
                <div style={{ padding: 10 }}>
                    <RecruitmentCycleSelector value={selectedCycle} onChange={onSelectedCycleChange} />
                </div>
            </div>
            <div style={{ padding: 10 }}>
                <StaffNavMenu navigationStatus={navigationStatus} />
            </div>
        </div>
    );
};

class Admissions extends Component {
    state = {
        isApplicationSubmitted: false,
        isApplicationValid: false,
        updateApplicationStatus: (isApplicationSubmitted, isApplicationValid) => {},
        data: [],
        showAll: false,
        searchText: "",
        selectedCycle: "",
        navigationStatus: {}, 
        readOnly: false,
    };

    componentDidMount = () => {
        this._isMounted = true;
        this.props.loadStaffMiddleSchools();
        //console.log(this.props);
        if (this.props.topUser.advProf.isAdmin == false && this.props.topUser.advProf.isStaff == false) {
            this.setState({readOnly: true});
        }
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    onCloseAppDetails = () => {
        this.props.history.push(`/applications/application/${this.appId}/s/${this.studentId}`);
    };

    componentDidUpdate(prevProps, prevState) {
        if ( (prevState.selectedCycle !== this.state.selectedCycle)) {
            this.doLoadData();
            this.loadNavigationStatus();
        } else if (prevProps.location !== this.props.location) {
            this.doLoadData();
            if(this.state.selectedCycle) {
                this.loadNavigationStatus();
            }
        } else if( prevState.showAll !== this.state.showAll ){
            this.loadNavigationStatus();
        }
        //console.log(this);
    }

    isAppDetails = () => {
        const { pathname } = this.props.location;
        if (pathname.indexOf("/applications/") !== -1) {
            const regex = /\b(?:student|parent|household|documents|orientationvideo|contracts)\b/g;
            const parts = pathname.split("/");
            if (parts.length > 3 && parts[3].indexOf("-") !== -1 && parts[5].indexOf("-") !== -1) {
                this.appId = parts[3];
                this.studentId = parts[5];
            }

            if (pathname.match(regex)) return true;
        }

        return false;
    };
    onSearch = (searchText) => {
        
        this.setState({ searchText }, async () => {
            if (this.isStartedView()) {
                
                await this.loadStarted(searchText);
                return
            } else if (this.isInReviewView()) {
                
                await this.loadInReview(searchText);
                return
            } else if (this.isResubmitView()) {
                
                await this.loadResubmit(searchText);
                return
            } else if (this.isCompleteView()) {
                
                await this.loadCompleted(searchText);
                return
            } else if (this.isRejectedView()) {
                
                await this.loadRejected(searchText);
                return
            } else if (this.isReportsView()) {
                
                await this.loadReports(searchText);
                return
            }
        });
    };
    onShowAllClick = () => {
        this.setState({ showAll: !this.state.showAll }, () => this.doLoadData());
    };

    doLoadData = () => {
        this.setState({ data: [] }, async () => {
            if (this.isStartedView()) {
                await this.loadStarted();
            } else if (this.isInReviewView()) {
                await this.loadInReview();
            } else if (this.isResubmitView()) {
                await this.loadResubmit();
            } else if (this.isCompleteView()) {
                await this.loadCompleted();
            } else if (this.isRejectedView()) {
                await this.loadRejected();
            }else if (this.isReportsView()) {
                await this.loadReports();
            }
        });
    };
    isStartedView = () => {
        return this.getView() === "started";
    };
    isInReviewView = () => {
        //console.log("Review");
        return this.getView() === "inreview";
    };
    isResubmitView = () => {
        //console.log("Resubmit");
        return this.getView() === "resubmit";
    };
    isRejectedView = () => {
        //console.log("Rejected");
        return this.getView() === "rejected";
    };
    isCompleteView = () => {
        //console.log("Complete");
        return this.getView() === "completed";
    };

    isQuotasView = () => {
        //console.log("Reports");
        return this.getView() === "quotas";
    };

    isReportsView = () => {
        return this.getView() === "reports";
    };

    getView() {
        const hash = this.props.location.pathname;
        switch (hash) {
            case "/applications/started":
                return "started";
            case "/applications/inreview":
                return "inreview";
            case "/applications/resubmit":
                return "resubmit";
            case "/applications/rejected":
                return "rejected";
            case "/applications/completed":
                return "completed";
            case "/applications/quotas":
                return "quotas";
            case "/applications/reports":
                return "reports";
            default:
                return null;
        }
    }

    async loadNavigationStatus() {
        const navStatus = ((await StaffApi.getNavigationStatus(this.state.selectedCycle, this.state.showAll)).data.result);
        this.setState({ navigationStatus: navStatus });
    }

    async loadStarted(search) {
        //console.log("Started");
        if (!this.state.selectedCycle) {
            return;
        }

        this.props.ajaxStatusLoading();
        const data = (await StaffApi.getStartedApplications(this.state.selectedCycle, this.state.showAll, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    async loadInReview(search) {
        if (!this.state.selectedCycle) {
            return;
        }

        this.props.ajaxStatusLoading();
        const data = (await StaffApi.getInReviewApplications(this.state.selectedCycle, this.state.showAll, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    async loadResubmit(search) {
        if (!this.state.selectedCycle) {
            return;
        }

        this.props.ajaxStatusLoading();
        const data = (await StaffApi.getResubmitApplications(this.state.selectedCycle, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    async loadRejected(search) {
        if (!this.state.selectedCycle) {
            return;
        }

        this.props.ajaxStatusLoading();
        const data = (await StaffApi.getRejectedApplications(this.state.selectedCycle, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    async loadCompleted(search) {
        if (!this.state.selectedCycle) {
            return;
        }

        this.props.ajaxStatusLoading();
        const data = (await StaffApi.getCompletedApplications(this.state.selectedCycle, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    async loadReports(search) {
        if (!this.state.selectedCycle) {
            return;
        }
        this.props.ajaxStatusLoading();
        const data = (await getAppReport(this.state.selectedCycle, search)).data.result;
        this.setState({ data });
        this.props.ajaxStatusComplete();
    }

    onCycleChange = (val) => {
        this.setState({ selectedCycle: val });
    };

    render() {
        const { selectedCycle } = this.state;
        //console.log(this);
        return (
            <ApplicationStatusContext.Provider value={this.state}>
                <div className="staff-admissions">
                    {this.isAppDetails() ? (
                        <div>
                            <AppHeader onClose={this.onCloseAppDetails} />
                            <AppNavMenu readonly={this.state.readOnly} />
                        </div>
                    ) : (
                        <Fragment>
                            <Route
                                path="/applications/started"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/inreview"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/resubmit"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/rejected"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/completed"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/events"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/quotas"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                            <Route
                                path="/applications/reports"
                                render={() => (
                                    <GlobalOptions onSearch={this.onSearch} selectedCycle={selectedCycle} onSelectedCycleChange={this.onCycleChange} navigationStatus={this.state.navigationStatus} />
                                )}
                            />
                        </Fragment>
                    )}
                    <Route exact path="/applications" render={() => <Redirect to="/applications/started" />} />

                    <Route
                        extact
                        path="/applications/started"
                        render={() => (
                            <Started
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                showAll={this.state.showAll}
                                loadData={this.doLoadData}
                                onShowAllClick={this.onShowAllClick}
                            />
                        )}
                    />
                    <Route
                        extact
                        path="/applications/inreview"
                        render={() => (
                            <InReview
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                loadData={this.doLoadData}
                            />
                        )}
                    />
                    <Route
                        extact
                        path="/applications/resubmit"
                        render={() => (
                            <Resubmit
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                loadData={this.doLoadData}
                            />
                        )}
                    />
                    <Route
                        extact
                        path="/applications/rejected"
                        render={() => (
                            <Rejected
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                loadData={this.doLoadData}
                            />
                        )}
                    />
                    <Route
                        path="/applications/completed"
                        render={() => (
                            <Complete 
                                selectedCycle={selectedCycle} 
                                data={this.state.data} 
                                loadData={this.doLoadData} 
                            />
                        )}
                    />
                    <Route path="/applications/events" render={() => (
                        <Events readonly={this.state.readOnly} />)}
                    />
                    <Route
                        path="/applications/quotas"
                        render={() => (
                            <Quotas
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                searchText={this.state.searchText}
                                loadData={this.doLoadData}
                                onShowAllClick={this.onShowAllClick}
                                readonly={this.state.readOnly }
                            />
                        )}
                    />
                    <Route 
                        path="/applications/reports" 
                        render={() => (
                            <Reports
                                selectedCycle={selectedCycle}
                                data={this.state.data}
                                loadData={this.doLoadData}
                                search={this.state.searchText}
                            />
                        )}
                    />

                    <Route exact path="/applications/application/:id" render={() => <Application readOnly={ this.state.readOnly} />} />
                    <Route exact path="/applications/application/:id/s/:studentId" render={() => <Application readOnly={this.state.readOnly} />} />
                    <Route exact path="/applications/application/student/:id" render={() => <Student readonly={this.state.readOnly} />} />
                    <Route exact path="/applications/application/student/:id/s/:studentId" render={() => <Student readonly={this.state.readOnly} />} />
                    <Route path="/applications/application/parent/:id" render={() => <Parent readOnly={this.state.readOnly} />} />
                    <Route path="/applications/application/household/:id" render={() => <Household readOnly={this.state.readOnly} />} />
                    <Route path="/applications/application/documents/:id" render={() => <Documents readOnly={this.state.readOnly} />} />
                    <Route path="/applications/application/orientationvideo/:id" render={() => <OrientationVideo readOnly={this.state.readOnly} />} />
                    <Route path="/applications/application/contracts/:id" render={() => <Contracts readOnly={this.state.readOnly} />} />
                </div>
            </ApplicationStatusContext.Provider>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadStaffMiddleSchools: bindActionCreators(loadStaffMiddleSchools, dispatch),
    };
};

Admissions = connect(null, mapDispatchToProps)(Admissions);

Admissions = withRouter(Admissions);

Admissions = withAjaxStatus(Admissions);

export default Admissions;
