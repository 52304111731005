import React from "react";
import ReactTable from "react-table";
import moment from "moment";

import "./crusaderList.css";

class CrusaderList extends React.Component {
    renderSchoolList() {
        const data = this.props.crusaderData;
        return (
            <React.Fragment>
                <div className="crusader-list-header">
                    <div>
                        {this.props.selectedSchoolQuotaData.crusaders} / {this.props.selectedSchoolQuotaData.count}
                        <span className="crusader-list-title">{this.props.selectedSchoolName}</span>
                    </div>
                    <div className="crusader-list-stats quota-stats">
                        <div className="quota-stats-top-area">
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.males}</span> Males
                                    </li>
                                    <li>
                                        <span>{data.females}</span> Females
                                    </li>
                                    <li>
                                        <span>{data.other}</span> Other
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.nonLatino}</span> Non-Hispanic or Latino
                                    </li>
                                    <li>
                                        <span>{data.latino}</span> Hispanic or Latino
                                    </li>
                                    <li>
                                        <span>{data.latinoNoAnswer}</span> No Response
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.grade5}</span> Grade 5
                                    </li>
                                    <li>
                                        <span>{data.grade6}</span> Grade 6
                                    </li>
                                    <li>
                                        <span>{data.grade7}</span> Grade 7
                                    </li>
                                    <li>
                                        <span>{data.grade8}</span> Grade 8
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <span>{data.white}</span> White
                                </li>
                                <li>
                                    <span>{data.black}</span> Black or African American
                                </li>
                                <li>
                                    <span>{data.asian}</span> Asian
                                </li>
                                <li>
                                    <span>{data.indian}</span> American Indian or Alaska Native
                                </li>
                                <li>
                                    <span>{data.hawaiian}</span> Native Hawaiian or Other Pacific Islander
                                </li>
                                <li>
                                    <span>{data.raceTwoOrMore}</span> Two or more
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="crusader-list-table">
                    <ReactTable
                        data={this.props.crusaderData.crusaders}
                        minRows={0}
                        className="-highlight"
                        defaultPageSize={10}
                        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                        getTrProps={this.props.getTrProps}
                        columns={[
                            {
                                Header: "Last Name",
                                accessor: "lastName",
                            },
                            {
                                Header: "First Name",
                                accessor: "firstName",
                            },
                            {
                                Header: "DOB",
                                accessor: "dateOfBirth",
                                Cell: (props) => moment(props.value).utc().format("M/D/YYYY"),
                            },
                            {
                                Header: "Gender",
                                accessor: "gender",
                            },
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderGradeList() {
        const data = this.props.crusaderData;
        return (
            <React.Fragment>
                <div className="crusader-list-header">
                    <div>
                        {this.props.selectedGradeQuotaData.crusaders} / {this.props.selectedGradeQuotaData.count}
                        <span className="crusader-list-title">{this.props.selectedSchoolName}</span>
                    </div>
                    <div className="crusader-list-stats quota-stats">
                        <div className="quota-stats-top-area">
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.males}</span> Males
                                    </li>
                                    <li>
                                        <span>{data.females}</span> Females
                                    </li>
                                    <li>
                                        <span>{data.other}</span> Other
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.nonLatino}</span> Non-Hispanic or Latino
                                    </li>
                                    <li>
                                        <span>{data.latino}</span> Hispanic or Latino
                                    </li>
                                    <li>
                                        <span>{data.latinoNoAnswer}</span> No Response
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <span>{data.grade5}</span> Grade 5
                                    </li>
                                    <li>
                                        <span>{data.grade6}</span> Grade 6
                                    </li>
                                    <li>
                                        <span>{data.grade7}</span> Grade 7
                                    </li>
                                    <li>
                                        <span>{data.grade8}</span> Grade 8
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <span>{data.white}</span> White
                                </li>
                                <li>
                                    <span>{data.black}</span> Black or African American
                                </li>
                                <li>
                                    <span>{data.asian}</span> Asian
                                </li>
                                <li>
                                    <span>{data.indian}</span> American Indian or Alaska Native
                                </li>
                                <li>
                                    <span>{data.hawaiian}</span> Native Hawaiian or Other Pacific Islander
                                </li>
                                <li>
                                    <span>{data.raceTwoOrMore}</span> Two or more
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="crusader-list-table">
                    <ReactTable
                        data={this.props.crusaderData.crusaders}
                        minRows={0}
                        className="-highlight"
                        defaultPageSize={10}
                        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                        getTrProps={this.props.getTrProps}
                        columns={[
                            {
                                Header: "Last Name",
                                accessor: "lastName",
                            },
                            {
                                Header: "First Name",
                                accessor: "firstName",
                            },
                            {
                                Header: "DOB",
                                accessor: "dateOfBirth",
                                Cell: (props) => moment(props.value).utc().format("M/D/YYYY"),
                            },
                            {
                                Header: "Gender",
                                accessor: "gender",
                            },
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderViewAllList() {
        return (
            <React.Fragment>
                <div className="crusader-list-header">
                    {this.props.crusaderData.crusaders.length} / {this.props.overallQuotaValue}
                    <span className="crusader-list-title">All Schools</span>
                </div>
                <div className="crusader-list-table">
                    <ReactTable
                        data={this.props.crusaderData.crusaders}
                        minRows={0}
                        className="-highlight"
                        defaultPageSize={10}
                        pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                        getTrProps={this.props.getTrProps}
                        columns={[
                            {
                                Header: "Last Name",
                                accessor: "lastName",
                            },
                            {
                                Header: "First Name",
                                accessor: "firstName",
                            },
                            {
                                Header: "DOB",
                                accessor: "dateOfBirth",
                                Cell: (props) => moment(props.value).utc().format("M/D/YYYY"),
                            },
                            {
                                Header: "Gender",
                                accessor: "gender",
                            },
                            {
                                Header: "School",
                                accessor: "school",
                            },
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }

    render() {
        if (this.props.crusaderData == null) {
            return null;
        }

        return (
            <div className="crusader-list">
                {this.props.selectedSchool === null && this.props.selectedGrade === null
                    ? this.renderViewAllList()
                    : this.props.selectedGrade === null
                    ? this.renderSchoolList()
                    : this.renderGradeList()}
            </div>
        );
    }
}

export default CrusaderList;
