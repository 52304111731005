import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { FinalFormAutocomplete } from "../../../common/formWrappers";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    selectedParticipantsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(4, 0, 4, 0),
        gap: theme.spacing(1),
    },
    inputContainer: {
        display: "flex",
        alignSelf: "stretch",
        gap: theme.spacing(1),
    },
    addBtn: {
        color: "#fff",
        fontWeight: "bold",
        padding: theme.spacing(1, 2),
    },
}));

const RemoveParticipantsModal = ({ currentOverrides, options, onClose, onSubmit, ...rest }) => {
    const classes = useStyles();

    const handleFormSubmit = (values) => onSubmit(values.selectedParticipants);

    return (
        <Form onSubmit={handleFormSubmit} mutators={{ ...arrayMutators }} initialValues={{ selectedParticipants: [{ name: "" }] }}>
            {({ handleSubmit, form, values }) => {
                const filteredOptions = options.filter((participant) => {
                    const isSelected = values.selectedParticipants.some((selected) => selected?.id === participant.id);
                    const isInCurrentOverrides = currentOverrides.some((override) => override.participant.id === participant.id);
                    return !isSelected && !isInCurrentOverrides;
                });
                const handleClose = () => {
                    form.initialize({ selectedParticipants: [{ name: "" }] });
                    onClose();
                };

                return (
                    <form onSubmit={(e) => e.preventDefault()} noValidate>
                        <Dialog onClose={handleClose} {...rest}>
                            <DialogTitle>
                                Add to Removed Eligible Participants
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1">Participants removed from the eligible list and override eligibility rules.</Typography>
                                <div className={classes.selectedParticipantsContainer}>
                                    <FieldArray name="selectedParticipants">
                                        {({ fields }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <div key={field} className={classes.inputContainer}>
                                                        <Field
                                                            component={FinalFormAutocomplete}
                                                            name={field}
                                                            options={filteredOptions}
                                                            getOptionLabel={(option) =>
                                                                (option?.id &&
                                                                    `${option.firstName} ${option.lastName} (${option.crusaderId || option.email})`) ||
                                                                ""
                                                            }
                                                            placeholder="Participant name"
                                                        />
                                                        <IconButton
                                                            aria-label="remove"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                form.mutators.remove("selectedParticipants", index);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                ))}
                                                <Button
                                                    className={classes.addBtn}
                                                    startIcon={<AddIcon />}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        form.mutators.push("selectedParticipants", {});
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleSubmit();
                                        handleClose();
                                    }}
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                );
            }}
        </Form>
    );
};

export default RemoveParticipantsModal;
