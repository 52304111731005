import React, { Component } from "react";
import Tag from "./tag";
import LoadingButton from "../../common/loadingButton";

class Classification extends Component {
    state = {
        activeTags: [],
        cat: []
        
    };
    constructor(props) {
        super(props);

        // Create an array to store references to Tag components
        this.tagRefs = [];
        
    }
    componentDidMount() {
        this.setState({ activeTags: this.props.cat.tags, cat: this.props.cat });
       
        

    }
    componentDidUpdate(prevProps) {
        
        if (prevProps != this.props) {

            this.setState({ activeTags: this.props.cat.tags, cat: this.props.cat });
            
        }
        
    }
  
    onRemove = async (input) => {
        await this.props.onTagRemove(input);
        
    }


    handleSubmitAll = async () => {
        // Loop through the array of Tag component references and submit each one
        for (let tagRef of this.tagRefs) {
            await tagRef.handleFormSubmit();
        }
        
    };

    onAutoWeight = async () => {
        let cnt = 0;
        let val = 1 / this.props.cat.tags.length;
        let i = 0;
        for (i = 0; i < this.props.cat.tags.length; i++) {
            if (i < this.props.cat.tags.length - 1) {
                this.props.cat.tags[i].weight = val;
                cnt = cnt + val;
               await this.props.updateTag(this.props.cat.tags[i]);

            } else {
                this.props.cat.tags[i].weight = 1 - cnt;
                
                await this.props.updateTag(this.props.cat.tags[i]);
            }
        

        }
        
    }

    
    render() {
   
        return (<div style={{ border: '3px solid ' + this.props.cat.classification.color, borderRadius: '10px', margin: "2px", padding: "2px" }} >
            <table style={{ width: '100%' }}>
                <tr>
                    <td><span style={{ color: this.props.cat.classification.color, fontWeight: '800' }} >{this.props.cat.classification.classification}</span>
                    </td>
                    <td>
                        <LoadingButton
                            wrapperClassName={"test"}
                            className={"test"}
                            variant="contained"
                            color="primary"
                            onClick={this.onAutoWeight}
                            disabled={this.props.disabled}
                        >
                            Auto Weight
                        </LoadingButton>  
                    </td>
                    
                </tr>
            </table>
            



            {this.props.cat.tags.map((tag, index) => {

                return (

                    <Tag tag={tag} color={this.props.cat.classification.color} disabled={this.props.disabled} onRemove={this.onRemove}  ipeds={this.props.ipeds} updateTag={this.props.updateTag } />


                );

            })}


        </div>


        );
    }

}
export default Classification;