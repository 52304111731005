import axios from 'axios';

export const get = () => {
    return axios.get('/api/services/app/Scholarship/Get');
};

export const start = scholarship => {
    return axios.post('/api/services/app/Scholarship/Start', scholarship);
};

export const close = scholarship => {
    return axios.post('/api/services/app/Scholarship/Close', scholarship);
};

export const getScholarshipBreakdown = scholarshipId => {
    return axios.get('/api/services/app/Scholarship/GetScholarshipBreakdown', { params: { scholarshipId } });
};

export const updateScholarshipBreakdowns = breakdowns => {
    return axios.put('/api/services/app/Scholarship/UpdateScholarshipBreakdowns', breakdowns);
};
