import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { setLocaleAction } from "../../language/redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

import { acceptEvent, getInvites } from "../../api/applicationEvent";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import Button from "../../support/components/Button";

import Logo from "../../images/OWL_Logos_FInal-02.png";

import "./index.css";

class AcceptInvite extends React.Component {
    state = {
        events: [],
        showSubmitMessage: false,
        selectedEvent: null,
        showFullModal: false,
    };

    componentDidMount = () => {
        const query = this.props.location.search.replace("?", "");
        const params = query.split("&");
        this.id = params[0].split("=")[1];
        this.locale = params[1].split("=")[1];
        this.props.setLocale(this.locale);
        this.loadEventOptions();
    };

    async loadEventOptions() {
        try {
            this.props.ajaxStatusLoading();
            const events = (await getInvites(this.id)).data.result;
            this.props.ajaxStatusComplete();
            this.setState({ events });
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    onSubmit = async (values) => {
        try {
            this.props.ajaxStatusLoading();
            await acceptEvent(this.id, values.eventId);
            this.props.ajaxStatusComplete();
            const selectedEvent = this.state.events.find((e) => e.id === values.eventId);
            this.setState({ selectedEvent, showSubmitMessage: true });
        } catch (err) {
            if (err.response && err.response.data.error.message === "EVENT_FULL") {
                this.setState({ showFullModal: true });
                this.props.ajaxStatusComplete();
                return;
            }
            this.props.ajaxStatusError(err);
        }
    };

    getEventLabel(event) {
        const dateFormat = this.props.locale === 'es-MX' ? 'DD/MM/YYYY' : 'M/DD/YY';
        return (
            <React.Fragment>
                <span className="event-date">{moment(event.startTime).format(dateFormat)}</span>
                <span className="event-time">
                    {moment.utc(event.startTime).format("h:mm a")} - {moment.utc(event.endTime).format("h:mm a")}
                </span>
                <span className="event-name">{event.name}</span>
            </React.Fragment>
        );
    }

    renderSubmitMessage() {
        const { selectedEvent } = this.state;

        const directionsText = this.props.intl.formatMessage({ id: "orientation.rsvp.directions" });
        const directionsQuery = encodeURIComponent(
            `${selectedEvent.address1}, ${selectedEvent.address2}, ${selectedEvent.city}, ${selectedEvent.state}, ${selectedEvent.zipCode}`
        );

        return (
            <React.Fragment>
                <div className="event-submitted">
                    <FormattedMessage
                        id="orientation.rsvp.confirm"
                        values={{
                            eventDate: <strong>{moment(selectedEvent.startTime).format("M/DD/YY")}</strong>,
                            eventTime: (
                                <strong>{`${moment.utc(selectedEvent.startTime).format("h:mm a")} - ${moment
                                    .utc(selectedEvent.endTime)
                                    .format("h:mm a")}`}</strong>
                            ),
                            eventLocation: <strong>{selectedEvent.name}</strong>,
                        }}
                    />
                </div>
                <br />
                {selectedEvent.address1 && (
                    <div>
                        <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${directionsQuery}`}>
                            {directionsText}
                        </a>
                    </div>
                )}
                <br />
                <div className="event-notes">{selectedEvent.notes}</div>
            </React.Fragment>
        );
    }

    renderEventList() {
        const { handleSubmit, eventId } = this.props;
        const { events } = this.state;

        return (
            <React.Fragment>
                <FormattedMessage id="orientation.rsvp.subtitle" children={(msg) => <h3>{msg}</h3>} />

                <div className="event-list">
                    {events.map((i) => {
                        return (
                            <div key={i.id} className="event-item">
                                <Field name="eventId" component="input" type="radio" id={i.id} value={i.id} disabled={i.isFull} />
                                <label htmlFor={i.id} className={i.isFull ? "disabled" : ""}>
                                    {i.isFull ? "(FULL)" : ""} {this.getEventLabel(i)}
                                </label>
                            </div>
                        );
                    })}
                </div>

                <Button id="login.submit" defaultMessage="[Submit]" onClick={handleSubmit(this.onSubmit)} invalid={eventId === undefined} />
            </React.Fragment>
        );
    }

    onConfirmFull = () => {
        this.props.reset();
        this.setState({ showFullModal: false }, () => this.loadEventOptions());
    };

    render() {
        const { showSubmitMessage } = this.state;
        const fullTitle = this.props.intl.formatMessage({ id: "event.full.title" });
        const fullText = this.props.intl.formatMessage({ id: "event.full.text" });

        return (
            <div className="accept-invite">
                <div className="accept-invite__logo-container">
                    <img src={Logo} className="accept-invite__logo" alt="logo" />
                </div>
                <FormattedMessage id="orientation.rsvp.title" children={(formattedMessage) => <h1 className="screen-title">{formattedMessage}</h1>} />

                {showSubmitMessage ? this.renderSubmitMessage() : this.renderEventList()}

                <SweetAlert show={this.state.showFullModal} title={fullTitle} onConfirm={this.onConfirmFull}>
                    {fullText}
                </SweetAlert>
            </div>
        );
    }
}

const selector = formValueSelector("AcceptInvite");

const mapDispatchToProps = (dispatch) => ({
    setLocale: bindActionCreators(setLocaleAction, dispatch),
});

const mapStateToProps = (state) => ({
    locale: state.locale,
    eventId: selector(state, "eventId"),
});

AcceptInvite = reduxForm({
    form: "AcceptInvite",
})(AcceptInvite);

AcceptInvite = withAjaxStatus(AcceptInvite);
AcceptInvite = withRouter(AcceptInvite);
AcceptInvite = injectIntl(AcceptInvite);

AcceptInvite = connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);

export default AcceptInvite;
