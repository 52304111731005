import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Line } from "rc-progress";

import "./index.css";

const UploadStatus = ({ files }) => {
    return ReactDOM.createPortal(
        <Fragment>
            {files && files.length > 0 && (
                <div className="upload-status">
                    {files.map((file) => (
                        <div key={file.index} className="upload-status-item">
                            <div className="upload-status-progress">
                                <Line percent={file.percentComplete} strokeColor="#00b5e2" strokeWidth="8" trailWidth="8" />
                            </div>
                            <div className="upload-status-filename">{file.fileName}</div>
                        </div>
                    ))}
                </div>
            )}
        </Fragment>,
        document.body
    );
};

export default UploadStatus;
