import React, { Component } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatCurrencyNoDecimal } from "../../../support/numberSupport";
import * as propTypes from "../../propTypes";
import * as enums from "../../enums";
import "./TermPayoutEntry.css";
import { AllocationType } from "../../enums";
import Checkbox from "@material-ui/core/Checkbox";
const BTN_NOT_SELECTED_CLS = "term-payout-btn term-payout-btn-reverse";
const BTN_SELECTED_CLS = "term-payout-btn";

const eligibleList = [
    { value: 1, name: "Review Pending" },
    { value: 2, name: "Certified" },
    { value: 3, name: "Certified - Lower Award" },
    { value: 4, name: "Certified - Withdrew" },
];
const ineligibleList = [
    { value: 1, name: "Review Pending" },
    { value: 5, name: "No High School Diploma/GED" },
    { value: 6, name: "No Unmet Need" },
    { value: 7, name: "Program Ineligible" },
    { value: 8, name: "Not Matriculated" },
    { value: 9, name: "Leave of Absense" },
    { value: 10, name: "Loan Default" },
    { value: 11, name: "Refund Owed" },
    { value: 12, name: "Selective Service" },
    { value: 13, name: "Drug Conviction" },
    { value: 14, name: "SAP" },
];
const CASH_ENABLED = 1;
const DONATION_ENABLED = 2;
class TermPayoutEntry extends Component {
    state = {
        certReason: eligibleList,
        term: null,
        donationCashEnabled: 0,
        showDonationLimitNotReached: false,
        showChooseCertificationReason: false,
        showNotEnrolled: false,
        isDonated: false,
        isCashAward: false,
        isDonationFocused: false,
        isCashFocused: false,
    };
    static getDerivedStateFromProps(nextProps, state) {
        const propTerm = nextProps.term;
        const stateTerm = state.term;
        const term = !stateTerm ? propTerm : stateTerm;
        const isEligible = term.isEligible;
        let cash = term.cash;
        term.cash = isEligible && cash ? formatCurrencyNoDecimal(cash) : "";
        let donation = term.donation;
        term.donation = isEligible && donation ? formatCurrencyNoDecimal(donation) : "";
        // let donationCashEnabled = isEligible && donation ? DONATION_ENABLED : isEligible && cash ? CASH_ENABLED : 0;

        return {
            term: { ...term },
            //   donationCashEnabled,
            certReason: isEligible ? eligibleList : ineligibleList,
            isCashAward: isEligible && term.cash !== "",
            isDonated: isEligible && term.donation !== "",
        };
    }
    onEligibleChange = (isEligible) => {
        const term = { ...this.state.term };
        term.isEligible = isEligible;
        term.cash = isEligible ? term.cash : null;
        term.donation = isEligible ? term.donation : null;

        this.setState({
            term: term,
            certReason: isEligible ? eligibleList : ineligibleList,
            isCashAward: false,
            isDonated: false,
        });
    };
    onDonationChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const term = { ...this.state.term };
        term.donation = parseInt(value, 10);
        this.setState({ term });
    };
    onCashChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        const term = { ...this.state.term };
        const max = this.doGetCrusadeAmount();
        let amount = parseInt(value, 10);
        if (amount > max) {
            amount = max;
        }
        term.cash = amount;
        this.setState({ term });
    };

    getInitialPerStudentDonationAmounts = (amount, termStructure, termStatus) => {
        const values = { donation: 0, cash: 0 };

        const divider = termStructure === enums.termType.semester ? 2 : 3;
        const multiplier = this.doGetMultiplier(termStatus);
        const crusadeAmount = this.doGetCrusadeAmount();
        values.donation = Math.floor((amount / divider) * multiplier);
        values.cash = Math.floor(crusadeAmount * multiplier - values.donation);

        return values;
    };

    onSelectChange = (field, e) => {
        let isDonated = false;
        let isCashAward = false;
        const term = { ...this.state.term };
        term[field] = parseInt(e.target.value, 10);
        this.setState({ term }, () => {
            //TODO: rework to prevent the need to setState twice
            if (field === "certificationReason" && term[field] === enums.certificationReason.reviewPending) {
                term.donation = 0;
                term.cash = 0;
            } else {
                if (
                    !term.donated &&
                    !term.cash &&
                    term.allocationType === AllocationType.CashAndDonated &&
                    term.certificationReason !== enums.certificationReason.reviewPending
                ) {
                    const values = this.getInitialPerStudentDonationAmounts(term.perStudentDonationAmount, term.termStructure, term.status);
                    term.donation = values.donation;
                    term.cash = values.cash;
                }
            }
            if (field === "status" || field === "grade") {
                if (field === "status" && term[field] === enums.crusaderStatus.notEnrolled) {
                    term.donation = 0;
                    term.cash = 0;
                } else if (term.allocationType === AllocationType.CashAndDonated && term.certificationReason !== enums.certificationReason.reviewPending) {
                    const values = this.getInitialPerStudentDonationAmounts(term.perStudentDonationAmount, term.termStructure, term.status);
                    term.donation = values.donation;
                    term.cash = values.cash;
                } else if (this.state.isDonated) {
                    term.donation = this.doGetAmounts(true, term.status);
                    isDonated = true;
                } else if (this.state.isCashAward) {
                    term.cash = this.doGetAmounts(false, term.status);
                    isCashAward = true;
                }
            }
            this.setState({ term, isDonated, isCashAward });
        });
    };
    onDonationClick = () => {
        const term = { ...this.state.term };
        if (term.certificationReason === enums.certificationReason.reviewPending) {
            this.setState({ showChooseCertificationReason: true });
            return;
        }
        if (term.status === enums.crusaderStatus.notEnrolled) {
            this.setState({ showNotEnrolled: true });
            return;
        }

        if (term.allocationType === AllocationType.CashAndDonated) {
        } else {
            term.cash = null;
            term.donation = this.doGetAmounts(true);
            this.setState({
                term,
                donationCashEnabled: DONATION_ENABLED,
                isCashAward: false,
                isDonated: true,
            });
        }
    };
    onCashClick = () => {
        const term = { ...this.state.term };
        if (term.certificationReason === enums.certificationReason.reviewPending) {
            this.setState({ showChooseCertificationReason: true });
            return;
        }
        if (term.status === enums.crusaderStatus.notEnrolled) {
            this.setState({ showNotEnrolled: true });
            return;
        }
        if (!this.doCheckReachedDonationLimits()) {
            this.setState({ showDonationLimitNotReached: true });
            return;
        }

        if (term.allocationType === AllocationType.CashAndDonated) {
        } else {
            term.donation = null;
            term.cash = this.doGetAmounts(false);
            this.setState({
                term,
                donationCashEnabled: CASH_ENABLED,
                isCashAward: true,
                isDonated: false,
            });
        }
    };
    onSave = () => {
        const term = { ...this.state.term };

        // if (term.certificationReason === enums.certificationReason.certified && !term.cash && !term.donation) {
        //     this.setState({ showChooseCertificationReason: true });
        //     return;
        // }
        // if (term.status === enums.crusaderStatus.notEnrolled) {
        //     this.setState({ showNotEnrolled: true });
        //     return;
        // }

        this.props.onSave(term, (id) => {
            term.id = id;
            this.setState({ term });
        });
    };
    onCancel = () => {
        this.props.onCancel();
        this.setState({ term: null });
    };
    doCheckReachedDonationLimits = () => {
        const { amounts, term } = this.props;
        if (this.state.term.institutionId === "dae0515e-acc2-4e3c-9bf1-08d62967b1ce") {
            //URI
            return true;
        }
        const termDetail = amounts.terms.find((detail) => detail.period === term.period);
        const { freshmanAmount, sophomoreAmount, juniorAmount, seniorAmount } = amounts;
        if (freshmanAmount || sophomoreAmount || juniorAmount || seniorAmount) {
            //focus on amount
            return termDetail.amountUsed >= amounts.amountAvailable;
        } else if (amounts.scholarshipsAvailable === 0 && amounts.amountAvailable !== 0) {
            //focus on total money amount
            return termDetail.amountUsed >= amounts.amountAvailable;
        }
        //focus on numbers
        return termDetail.scholarshipsUsed >= amounts.scholarshipsAvailable;
    };
    doGetAmounts = (isDonation, allocationType, selStatus) => {
        let amount;
        
        if (isDonation) amount = this.doGetSchoolAmount();
        if (!isDonation || !amount) amount = this.doGetCrusadeAmount();
        return parseInt(amount * this.doGetMultiplier(selStatus), 10);
    };
    doGetMultiplier = (selStatus) => {
        const status = selStatus ? selStatus : this.state.term.status;
        const cStatus = enums.crusaderStatus;
        let multiplier = 1;
        switch (status) {
            case cStatus.fullTime:
                multiplier = 1;
                break;
            case cStatus.threeQuarterTime:
                multiplier = 0.75;
                break;
            case cStatus.halfTime:
                multiplier = 0.5;
                break;
            case cStatus.lessThanHalfTime:
                multiplier = 0.25;
                break;
            default:
                multiplier = 1;
                break;
        }
        return multiplier;
    };
    doGetSchoolAmount = () => {
        const { term } = this.state;
        const { amounts } = this.props;
        const grades = enums.crusaderGrade;
        let amount = null;
        switch (term.grade) {
            case grades.sophomore:
                amount = amounts.sophomoreAmount;
                break;
            case grades.junior:
                amount = amounts.juniorAmount;
                break;
            case grades.senior:
                amount = amounts.seniorAmount;
                break;
            case grades.trade:
                amount = amounts.freshmanAmount;
                break;
            default:
                amount = amounts.freshmanAmount;
                break;
        }
        return amount;
    };
    doGetCrusadeAmount = () => {
        const { term } = this.state;
        const { amounts, yearOfGraduation } = this.props;
        const levels = enums.scholarshipLevel;
        let amount = null;

        if (yearOfGraduation < 2016) {
            const termDivider = amounts.termStructure === 1 ? 3 : 2;
            if (amounts.institutionType === 1) {
                // Two Year
                return Math.round(1725 / termDivider);
            } else {
                // Four Year
                return Math.round(3000 / termDivider);
            }
        }

        switch (term.level) {
            case levels.Gold:
                amount = amounts.gold;
                break;
            case levels.Silver:
                amount = amounts.silver;
                break;
            case levels.Bronze:
                amount = amounts.bronze;
                break;
            case levels.Pewter:
                amount = amounts.pewter;
                break;
            case levels.OneTimeGrant:
                amount = 1000;
                break;
            default:
                amount = 0;
                break;
        }
        return amount;
    };

    handleFocus = (type) => {
        let isDonationFocused = false,
            isCashFocused = false;
        if (type === "donation") {
            isDonationFocused = true;
        } else {
            isCashFocused = true;
        }
        this.setState({ isDonationFocused, isCashFocused });
    };

    handleBlur = (type) => {
        this.setState({ isDonationFocused: false, isCashFocused: false });
    };

    doRenderCol5 = (term) => {
        return (
            <div className="term-payout-edit-column term-payout-center term-payout-edit-box-veritcal-line">
                <div className="term-payout-edit-row-button">
                    <button className="btn" onClick={this.onSave}>
                        Save
                    </button>
                    <button className="link-button" onClick={this.onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        );
    };
    doRenderCol4 = () => {
        const { isCashAward, isDonated, isDonationFocused, isCashFocused, term } = this.state;
        const cls = term.cash || isCashAward || isCashFocused ? BTN_SELECTED_CLS : BTN_NOT_SELECTED_CLS;
        const btnReadOnly = !term.isEligible || this.props.maxAllotment;
        const inputReadOnly =
            (this.props.maxAllotment || btnReadOnly)  ||
            (!isDonated && !isCashAward && !isDonationFocused && !isCashFocused && term.allocationType === AllocationType.CashOrDonated) ||
            (isDonated && term.allocationType === AllocationType.CashOrDonated) ||
            (isDonationFocused && !isDonated && term.allocationType === AllocationType.CashOrDonated) || this.props.maxAllotment;

        return (
            <div className={`term-payout-edit-column ${term.allocationType === AllocationType.CashOrDonated && "term-payout-edit-box-veritcal-line"}`}>
                <div className="term-payout-edit-box center">
                    {term.allocationType === AllocationType.CashOrDonated && (
                        <button className={cls} disabled={btnReadOnly} onClick={this.onCashClick}>
                            Apply Cash Award
                        </button>
                    )}
                </div>
                <div className="term-payout-edit-box">
                    <input
                        type="text"
                        disabled={inputReadOnly}
                        value={term.cash}
                        onChange={this.onCashChange}
                        onFocus={() => this.handleFocus("cash")}
                        onBlur={() => this.handleBlur("cash")}
                    />
                    {term.allocationType !== AllocationType.CashOrDonated && <span>Cash Award</span>}
                </div>
            </div>
        );
    };
    doRenderCol3 = () => {
        const { isCashAward, isDonated, isDonationFocused, isCashFocused, term } = this.state;
        const cls = term.donation || isDonated || isDonationFocused ? BTN_SELECTED_CLS : BTN_NOT_SELECTED_CLS;
        const btnReadOnly = !term.isEligible;
        const inputReadOnly =
            btnReadOnly ||
            (!isCashAward && !isDonated && !isDonationFocused && !isCashFocused && term.allocationType === AllocationType.CashOrDonated) ||
            (isCashAward && term.allocationType === AllocationType.CashOrDonated) ||
            (isCashFocused && !isCashAward && term.allocationType === AllocationType.CashOrDonated);

        return (
            <div className="term-payout-edit-column term-payout-edit-box-veritcal-line">
                <div className="term-payout-edit-box">
                    {term.allocationType === AllocationType.CashOrDonated ? (
                        <button className={cls} disabled={btnReadOnly} onClick={this.onDonationClick}>
                            Award Donated Scholarship
                        </button>
                    ) : (
                        <span className="term-readonly-label max">Enter Donated Scholarship and Cash Award Amount</span>
                    )}
                </div>
                <div className="term-payout-edit-box">
                    <input
                        type="text"
                        disabled={inputReadOnly}
                        value={term.donation}
                        onChange={this.onDonationChange}
                        onFocus={() => this.handleFocus("donation")}
                        onBlur={() => this.handleBlur("donation")}
                    />
                    {term.allocationType !== AllocationType.CashOrDonated && <span>Donated Scholarship</span>}
                </div>
            </div>
        );
    };
    doRenderCol2 = (term) => {
        
        if (term.period < 4) {
            return (
                <div className="term-payout-edit-column">
                    <div className="term-payout-edit-box-select">
                        <select value={term.status} onChange={this.onSelectChange.bind(this, "status")}>
                            <option value="1">Full Time</option>
                            <option value="2">3/4 Time</option>
                            <option value="3">1/2 Time</option>
                            <option value="4">Less than 1/2 Time</option>
                            <option value="5">Not Enrolled</option>
                        </select>
                        <select value={term.grade} onChange={this.onSelectChange.bind(this, "grade")}>
                            <option value="1">Freshman</option>
                            <option value="2">Sophomore</option>
                            <option value="3">Junior</option>
                            <option value="4">Senior</option>
                        </select>
                    </div>
                    <div className="term-payout-edit-box">
                        <select
                            className="term-payout-edit-box-fix-select-width"
                            value={term.certificationReason}
                            onChange={this.onSelectChange.bind(this, "certificationReason")}
                        >
                            {this.state.certReason.map((reason) => {
                                return (
                                    <option key={reason.value} value={reason.value}>
                                        {reason.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="term-payout-edit-column">
                    <div className="term-payout-edit-box-select">
                        <select value={term.status} onChange={this.onSelectChange.bind(this, "status")}>
                            <option value="1">Full Time</option>
                            <option value="2">3/4 Time</option>
                            <option value="3">1/2 Time</option>
                            <option value="4">Less than 1/2 Time</option>
                            <option value="5">Not Enrolled</option>
                        </select>
                        <select value={5} onChange={this.onSelectChange.bind(this, "grade")}>
                            <option value="5">Trade Program</option>
                        </select>
                    </div>
                    <div className="term-payout-edit-box">
                        <select
                            className="term-payout-edit-box-fix-select-width"
                            value={term.certificationReason}
                            onChange={this.onSelectChange.bind(this, "certificationReason")}
                        >
                            {this.state.certReason.map((reason) => {
                                return (
                                    <option key={reason.value} value={reason.value}>
                                        {reason.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            );

        }
        
    };
    doRenderCol1 = (term) => {
        const btnEligibleCls = term.isEligible ? BTN_SELECTED_CLS : BTN_NOT_SELECTED_CLS;
        const btnNotEligibleCls = !term.isEligible ? BTN_SELECTED_CLS : BTN_NOT_SELECTED_CLS;
        
        let typeName = term.certificationTermStructure === 1 ? "Trimester" : "Semester";
        if (term.period === 4) {
            typeName = "Trade Program";
        }
        const per = term.period === 4 ? "" : term.period;
        return (
            <div className="term-payout-edit-column">
                <div className="term-payout-edit-box">
                    <span className="term-readonly-label">
                        {term.startYear}-{term.endYear} {typeName} {per}
                    </span>
                </div>
                <div className="term-payout-edit-box ">
                    <div className="term-payout-edit-row-button">
                        <button className={btnEligibleCls} onClick={this.onEligibleChange.bind(this, true)}>
                            Eligible
                        </button>
                        <button className={btnNotEligibleCls} onClick={this.onEligibleChange.bind(this, false)}>
                            Not Eligible
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    render() {
        const { term } = this.state;

        return (
            <div key={term.id} className="term-payout-edit-row">
                {this.doRenderCol1(term)}
                {this.doRenderCol2(term)}
                {this.doRenderCol3(term)}
                {this.doRenderCol4(term)}
                {this.doRenderCol5(term)}
                <SweetAlert
                    show={this.state.showNotEnrolled}
                    title="Not Enrolled"
                    onConfirm={() => {
                        this.setState({ showNotEnrolled: false });
                    }}
                    confirmBtnText="OK"
                >
                    Donation and Cash awards can not be applied to a student that is not enrolled
                </SweetAlert>
                <SweetAlert
                    show={this.state.showChooseCertificationReason}
                    title="Certification Reason Required"
                    onConfirm={() => {
                        this.setState({ showChooseCertificationReason: false });
                    }}
                    confirmBtnText="OK"
                >
                    A Certification Reason, other than 'Review Pending', is required before awarding donation or cash.
                </SweetAlert>
                <SweetAlert
                    show={this.state.showDonationLimitNotReached}
                    title="Donation Amount Not Met"
                    onConfirm={() => {
                        this.setState({ showDonationLimitNotReached: false });
                    }}
                    confirmBtnText="OK"
                >
                    You can not add an Onward We Learn scholarship amount until you have met your contract obligation for donations.
                </SweetAlert>
            </div>
        );
    }
}

TermPayoutEntry.propTypes = {
    term: PropTypes.shape({
        startYear: PropTypes.number.isRequired,
        endYear: PropTypes.number.isRequired,
        period: PropTypes.number.isRequired,
        isEligible: PropTypes.bool.isRequired,
    }),
    amounts: propTypes.amounts,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
export default TermPayoutEntry;
