import axios from "axios";
import { useEffect, useState } from "react";
import { get } from "../api/programEvents";

const useProgramEvent = (id) => {
    const [programEvent, setProgramEvent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const tokenSource = axios.CancelToken.source();
        const getProgramEvent = async () => {
            try {
                const eventResponse = await get(id, tokenSource.token);
                setProgramEvent(eventResponse.data.result);
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        };
        getProgramEvent();
        return () => {
            tokenSource.cancel();
        };
    }, [id]);

    return {
        programEvent,
        loading,
        setProgramEvent,
    };
};

export default useProgramEvent;
