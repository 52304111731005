import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import ActivityCodeForm from "./activityCodeForm";
import SimpleDialog from "../../common/simpleDialog";
import ReactTable from "react-table";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    activityCodeGrid: {},
}));

const ActivityCodeGrid = ({ data, onEditSubmit }) => {
    const classes = useStyles();
    const [editRowItem, setEditItem] = useState(null);

    const handleEditSubmit = (updatedActivityCode) => {
        setEditItem(null);
        onEditSubmit(updatedActivityCode);
    };

    return (
        <React.Fragment>
            <ReactTable
                data={data}
                minRows={0}
                columns={[
                    {
                        Header: "Gear-Up ID #",
                        accessor: (d) => Number(d.activityCode),
                        id: "activityCode",
                    },
                    {
                        Header: "Activity Description",
                        accessor: "description",
                        style: { whiteSpace: "unset" },
                    },
                    {
                        Header: "Short Description",
                        accessor: "shortDescription",
                        style: { whiteSpace: "unset" },
                    },
                    {
                        Header: "",
                        accessor: "id",
                        id: "edit",
                        sortable: false,
                        Cell: (row) => {
                            return (
                                <React.Fragment>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditItem(row.original);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    {/* <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditItem(row.original);
                                        }}
                                    >
                                        Edit
                                    </button> */}
                                </React.Fragment>
                            );
                        },
                    },
                ]}
                defaultSorted={[
                    {
                        id: "activityCode",
                        desc: false,
                    },
                ]}
                defaultPageSize={50}
                className="-striped -highlight"
            />
            {Boolean(editRowItem) && (
                <SimpleDialog
                    className={classes.addEventDialog}
                    open={Boolean(editRowItem)}
                    onClose={() => setEditItem(null)}
                    title="Edit Gear-Up Activity Code"
                >
                    <ActivityCodeForm activityCode={editRowItem} saveButtonText="Save" activityCodeEditEnabled={false} onSubmit={handleEditSubmit} />
                </SimpleDialog>
            )}
        </React.Fragment>
    );
};
export default ActivityCodeGrid;
