import React, { Component } from "react";
import { connect } from "react-redux";

import { ApplicationStatus } from "../logic/enums";

import Documents from "./documents";
import { SecurityContext } from "../../support/security";

class DocumentsContainer extends Component {
  render() {
    if (this.props.admissions === null) {
      return null;
    }

    const { match, admissions } = this.props;
      let isStaffReview = false;
      try {
          isStaffReview = match.params && match.params.id !== undefined;
      } catch {

      }

    let readonly =
      admissions === null ||
      (admissions.status !== ApplicationStatus.NotSubmitted &&
            admissions.status !== ApplicationStatus.Rejected &&
        admissions.status !== ApplicationStatus.LunchPending &&
        this.props.admissions.status !== ApplicationStatus.Resubmit);
      if (this.props.readOnly || admissions.status === ApplicationStatus.Submitted) {
          readonly = true;
      }
      //console.log(readonly);

    return (
      <SecurityContext.Consumer>
        {context => {
          return (
            <Documents
              isStaff={isStaffReview} 
              admissions={this.props.admissions} 
              readonly={context.hasEditPermission("Admin.Admissions") ? false : readonly} 
            />
          );
        }}
      </SecurityContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  admissions: state.admissions,
});

DocumentsContainer = connect(mapStateToProps)(DocumentsContainer);

export default DocumentsContainer;
