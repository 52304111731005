import React from "react";

import "./StatusIndicator.css";

const StatusIndicator = ({ status }) => {
  let statusValue = "";
  let statusClass = "";

  switch (status) {
    case 0:
      statusValue = "Not Submitted";
      statusClass = "status-indicator--not-submitted";
      break;
    case 1:
      statusValue = "Submitted";
      statusClass = "status-indicator--submitted";
      break;
    case 2:
      statusValue = "Complete";
      statusClass = "status-indicator--complete";
      break;
    case 3:
      statusValue = "Accepted";
      statusClass = "status-indicator--accepted";
      break;
    case 4:
      statusValue = "Rejected";
      statusClass = "status-indicator--rejected";
      break;
    case 5:
      statusValue = "Lunch Pending";
      statusClass = "status-indicator--lunch-pending";
      break;
    case 6:
      statusValue = "Applicant Rejected";
      statusClass = "status-indicator--applicant-rejected";
      break;
    case 7:
      statusValue = "Pending Family Orientation";
      statusClass = "status-indicator--virtual-orientation";
      break;
    case 8:
      statusValue = "Resubmit";
      statusClass = "status-indicator--resubmit";
      break;
    default:
      statusValue = "Unknown";
      break; // Not Submitted
  }

  return <div className="status-indicator">Status: <span className={`status-indicator-value ${statusClass}`}>{statusValue}</span></div>;
};

export default StatusIndicator;
