import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import InstitutionSearch from "./components/institutionSearch";
import CashScholarship from "./components/CashScholarship";
import AllocatedByGrade from "./components/AllocatedByGrade";
import { formatProgramDataForUI } from "./logic";
import { formatCurrencyNoDecimal } from "../../support/numberSupport";
import { AllocationType } from "../enums";
import * as apis from "../apis";
import "./index.css";

const STATUS_CLOSE = 2;

class Programs extends Component {
    static permission = "Scholarship.Programs";
    state = {
        institutions: [],
        institution: null,
        data: null,
        autoCalculateOn: true,
        unevenNumber: null,
        showAllocationWarning: false,
    };
    componentDidMount = async () => {
        try {
            this.props.ajaxStatusLoading();
            var results = await apis.getInstitutionByUser();

            var institutions = results.data.result;

            this.props.ajaxStatusComplete();

            this.setState({
                institutions,
            });

            if (institutions && institutions.length === 1) {
                this.doLoadForInstitutionUser(institutions[0]);
            }
            this.isLoaded = true;
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving scholarships");
        }
    };
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.isLoaded && nextProps.scholarship) {
            if (!this.scholarship || this.scholarship.id !== nextProps.scholarship.id) {
                //if here, user changes scholarship in context
                this.scholarship = nextProps.scholarship;
                this.onInstitutionSelected(this.state.institution, this.scholarship);
            }
        }
    };

    onInstitutionSelected = async (institution, scholarship) => {
        if (!institution) return;
        this.props.ajaxStatusLoading();
        this.setState({
            institution,
        });
        const activeScholarship = scholarship ? scholarship : this.props.scholarship;
        if (!activeScholarship) {
            this.props.ajaxStatusError("No Academic Year started.  Please start an Academic Year before updating programs.");
            return;
        }
        if (institution) {
            try {
                this.props.ajaxStatusLoading();
                var results = await apis.getAllData(institution.id, activeScholarship.id);
                this.currentData = results.data.result;
                this.initialAllocationType = results.data.result.scholarshipInstitution.allocationType;
                this.doSetState();
                this.props.ajaxStatusComplete();
            } catch (err) {
                this.props.ajaxStatusError("Error retrieving scholarships and institution data");
            }
        }
        this.props.ajaxStatusComplete();
    };
    onInstitutionClear = () => {
        this.setState({
            formattedData: null,
        });
    };
    onSave = async () => {
        try {
            this.props.ajaxStatusLoading();
            await apis.updateScholarshipInsitutionData(this.currentData);
            toast.success("Save Complete");
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error saving scholarships and institution data");
        }
    };
    onCancel = async () => {
        try {
            this.props.ajaxStatusLoading();
            var results = await apis.getAllData(this.state.institution.id, this.props.scholarship.id);
            this.currentData = results.data.result;
            this.initialAllocationType = results.data.result.scholarshipInstitution.allocationType;
            this.doSetState();
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving scholarships and institution data");
        }
    };
    onAllocateNumberChange = (breakdown, e) => {
        const data = this.doFindOriginalBreakdown(breakdown.id);
        data.numberOfScholarships = this.doFormatNumber(e);
        this.doSetState(2);
        this.doCalculateAnnualsFrombreakdown();
    };
    onAllocateAmountChange = (breakdown, e) => {
        const data = this.doFindOriginalBreakdown(breakdown.id);
        data.amountPerScholarship = this.doFormatNumber(e);
        this.doSetState(2);
        this.doCalculateAnnualsFrombreakdown();
    };
    onAnnualAmountChange = (e) => {
        this.currentData.scholarshipInstitution.totalAmount = this.doFormatNumber(e);
        this.doSetState(1);
    };
    onAnnualNumberChange = (e) => {
        const number = this.doFormatNumber(e);
        let unevenNumber = false;
        if (number % 4 !== 0) {
            unevenNumber = true;
        }
        this.setState({
            unevenNumber,
        });
        this.currentData.scholarshipInstitution.numberOfScholarships = number;
        this.doSetState(1);
    };
    onToggleAutoCalculate = () => {
        const autoCalculateOn = !this.state.autoCalculateOn;
        this.currentData.scholarshipInstitution.isAutoCalculateOn = autoCalculateOn;
        if (!autoCalculateOn) {
            this.currentData.scholarshipInstitutionBreakdowns.forEach((breakdown) => {
                breakdown.amountPerScholarship = 0;
                breakdown.numberOfScholarships = 0;
            });
            this.doSetState();
        } else {
            this.doSetState(1);
        }
        this.setState({
            autoCalculateOn,
        });
    };
    doLoadForInstitutionUser = (institution) => {
        const self = this;
        if (!self.props.scholarship) {
            self.interval = setInterval(() => {
                if (self.props.scholarship) {
                    clearInterval(self.interval);
                    self.onInstitutionSelected(institution);
                }
            }, 500);
            return;
        }
        self.onInstitutionSelected(institution);
    };
    doFindOriginalBreakdown = (id) => {
        return this.currentData.scholarshipInstitutionBreakdowns.find((breakdown) => {
            return breakdown.id === id;
        });
    };
    doFormatNumber = (e) => {
        let number = e.target.value;
        number = parseInt(number.replace(/\D/g, ""), 10);
        if (isNaN(number)) return 0;
        return Math.floor(number);
    };
    doCalculateAnnualsFrombreakdown = () => {
        const { autoCalculateOn } = this.state;
        if (!autoCalculateOn) return;
        let amount = 0;
        let number = 0;
        this.currentData.scholarshipInstitutionBreakdowns.forEach((breakdown) => {
            amount += breakdown.amountPerScholarship * breakdown.numberOfScholarships;
            number += breakdown.numberOfScholarships;
        });

        this.currentData.scholarshipInstitution.totalAmount = amount;
        this.currentData.scholarshipInstitution.numberOfScholarships = number;
    };
    doSetState = (priority) => {
        const autoCalculateOn = this.currentData.scholarshipInstitution.isAutoCalculateOn;
        const option = autoCalculateOn ? priority : null;
        this.setState({
            formattedData: formatProgramDataForUI(this.currentData, option),
            autoCalculateOn,
        });
    };

    handleAllocationTypeChange = (e) => {
        const allocationType = parseInt(e.target.value, 10);
        this.setState({
            ...this.state,
            formattedData: {
                ...this.state.formattedData,
                allocationType,
            },
            showAllocationWarning: this.initialAllocationType !== allocationType,
        });

        this.currentData.scholarshipInstitution.allocationType = allocationType;
    };

    handlePerStudentDonationAmountChange = (e) => {
        this.setState({
            ...this.state,
            formattedData: {
                ...this.state.formattedData,
                perStudentDonationAmount: e.target.value,
            },
        });
        this.currentData.scholarshipInstitution.perStudentDonationAmount = this.doFormatNumber(e);
    };

    renderCashScholarships = () => {
        if (!this.currentData) return;
        const { scholarshipBreakdowns } = this.currentData;
        const year = this.state.institution.type === "TwoYear" ? "2" : "4";
        const pewter = scholarshipBreakdowns.find((b) => b.level === 4);
       
        return (
            <div className="scholarship-programs-cash-container">
                {scholarshipBreakdowns.map((breakdown) => {
                    const yearType = breakdown.type === 1 ? "4" : "2";
                    if (year !== yearType) return null;
                    if (breakdown.level === 4) return null;

                    const type = breakdown.level === 1 ? "Gold" : breakdown.level === 2 ? "Silver" : breakdown.level === 3 ? "Bronze" : "Pewter";
                    const name = `${year}-Year Award (${type})`;
                    const amount = formatCurrencyNoDecimal(breakdown.amount);
                    return <CashScholarship key={breakdown.id} name={name} amount={amount} />;
                })}
                {pewter && <CashScholarship name={`${year}-Year Award (Pewter)`} amount={formatCurrencyNoDecimal(pewter.amount)} />}
            </div>
        );
    };

    renderAllocatedText = () => {
        return (
            <div className="scholarship-programs-allocated-text">
                <div className="scholarship-programs-allocated-spacer" />
                <div className="row-header">
                    <span>HS Graduation Year</span>
                </div>
                <div className="row-header">
                    <span>Total # of Scholarships</span>
                </div>
                <div className="row-header">
                    <span>Amount $ Per Scholarship</span>
                </div>
                <div className="row-header">
                    <span>Donated Per Class $</span>
                </div>
            </div>
        );
    };
    renderAllocated = (data) => {
        if (!data) return;
        const { years } = data;
        let isReadonly = this.props.readonly;
        if (this.props.scholarship && this.props.scholarship.status === STATUS_CLOSE) {
            isReadonly = true;
        }
        return (
            <div className="scholarship-programs-allocated-container">
                {this.renderAllocatedText()}
                {years.map((breakdown) => {
                    return (
                        <AllocatedByGrade
                            key={breakdown.id}
                            yearName={breakdown.yearName}
                            year={breakdown.year}
                            number={breakdown.number}
                            amount={breakdown.amount}
                            donation={breakdown.donation}
                            onNumberChange={this.onAllocateNumberChange.bind(this, breakdown)}
                            onAmountChange={this.onAllocateAmountChange.bind(this, breakdown)}
                            isReadonly={isReadonly}
                        />
                    );
                })}
            </div>
        );
    };
    render() {
        const { autoCalculateOn, institution, formattedData } = this.state;
        const btnText = autoCalculateOn ? "Turn OFF Auto Calculate & Clear values" : "Turn ON Auto Calculate";
        let isReadonly = this.props.readonly;
        if (this.props.scholarship && this.props.scholarship.status === STATUS_CLOSE) {
            isReadonly = true;
        }
        //console.log(this);
        return (
            <div className="scholarship-programs">
                {this.state.institutions.length > 1 ? (
                    <InstitutionSearch
                        institutions={this.state.institutions}
                        onInstitutionSelected={this.onInstitutionSelected}
                        onInstitutionClear={this.onInstitutionClear}
                    />
                ) : null}
                {formattedData ? (
                    <>
                        <h1>{institution.name}</h1>
                        <h2>Donated Scholarships (all amounts are ANNUAL)</h2>

                        <div className="scholarship-programs-row allocation-type">
                            <label htmlFor="allocationType">Scholarship Allocation Type</label>
                            <select
                                id="allocationType"
                                style={{ marginLeft: 10 }}
                                onChange={this.handleAllocationTypeChange}
                                value={this.state.formattedData.allocationType}
                                disabled={isReadonly}
                            >
                                <option value="0">Cash OR Donated (default)</option>
                                <option value="1">Cash AND Donated</option>
                            </select>
                        </div>

                        {formattedData.allocationType === AllocationType.CashAndDonated ? (
                            <div className="scholarship-programs-amount">
                                <span>Per Student Donation Amount $</span>
                                <input
                                    type="text"
                                    value={formattedData.perStudentDonationAmount}
                                    onChange={this.handlePerStudentDonationAmountChange}
                                    disabled={isReadonly}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="scholarship-programs-row">
                                    <div className="scholarship-programs-amount">
                                        <span>Total ANNUAL Amount $</span>
                                        <input type="text" value={formattedData.amount} onChange={this.onAnnualAmountChange} disabled={isReadonly} />
                                    </div>
                                    <div className="scholarship-programs-amount">
                                        <span>Total ANNUAL # of Scholarships</span>
                                        <input type="text" value={formattedData.number} onChange={this.onAnnualNumberChange} disabled={isReadonly} />
                                    </div>
                                    {this.state.unevenNumber ? (
                                        <div className="scholarship-programs-uneven-number">
                                            <span>
                                                The "Total Annual" number entered is not divisible by 4. Allocated values cannot be properly distributed and
                                                errors may occur.
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                <h3>Onward We Learn Cash Scholarships</h3>
                                {this.renderCashScholarships()}
                                <div className="scholarship-programs-row">
                                    <h3>Allocated By Grade (if applicable)</h3>
                                    {isReadonly ? null : (
                                        <button className="btn" onClick={this.onToggleAutoCalculate}>
                                            {btnText}
                                        </button>
                                    )}
                                </div>
                                {this.renderAllocated(formattedData)}
                            </>
                        )}

                        {isReadonly ? null : (
                            <div className="scholarships-programs-btns">
                                <button className="btn" onClick={this.onSave}>
                                    SAVE
                                </button>
                                <button className="link-button" onClick={this.onCancel}>
                                    cancel
                                </button>
                            </div>
                        )}
                    </>
                ) : null}
                <SweetAlert
                    show={this.state.showAllocationWarning}
                    title="Warning!"
                    onConfirm={() => this.setState({ showAllocationWarning: false })}
                    confirmBtnText="OK"
                >
                    Updating this field after certification has started may cause previous certifications to display incorrectly.
                </SweetAlert>
            </div>
        );
    }
}

Programs.propTypes = {
    scholarship: PropTypes.shape({
        id: PropTypes.string,
        startYear: PropTypes.number,
        endYear: PropTypes.number,
        status: PropTypes.number,
    }),
};

Programs = withAjaxStatus(Programs);

export default Programs;
