/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import Spinner from "react-spinkit";
import Button from "../../support/components/Button";
import * as apis from "../../api/login";

import "./ForgotUserName.css";
import normalizePhone from "../../support/logic/normalizePhone";
import withLocale from "../../support/hoc/withLocale";

class ForgotUserName extends Component {
    state = {
        phone: "",
        previousPhone: "",
        showLoader: false,
        showDialog: false,
        dialogTitle: "",
        dialogText: "",
        hasError: false,
        showLogin: false,
    };
    onUpdateInputValue = (e) => {
        var newValue = normalizePhone(e.target.value, this.state.previousPhone);

        this.setState({
            previousPhone: this.state.phone,
            phone: newValue,
        });
    };
    onSubmit = () => {
        const { locale } = this.props;
        const { phone } = this.state;

        this.setState({ showLoader: true });
        apis.forgotUserName(phone, locale)
            .then((result) => {
                this.setState({ showLoader: false });
                this.setState({
                    showLoader: false,
                    showDialog: true,
                    hasError: false,
                    dialogTitle: this.props.intl.formatMessage({ id: "app.success" }),
                    dialogText: this.props.intl.formatMessage({
                        id: "forgot.instructions",
                    }),
                });
            })
            .catch((error) => {
                this.setState({
                    showLoader: false,
                    showDialog: true,
                    hasError: true,
                    dialogTitle: this.props.intl.formatMessage({ id: "app.errorTitle" }),
                    dialogText: error.response.data.error.message,
                });
            });
    };

    onCloseDialog = () => {
        this.setState(
            {
                showDialog: false,
            },
            () => {
                this.setState({
                    showLogin: !this.state.hasError,
                });
            }
        );
    };

    render() {
        if (this.state.showLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <Fragment>
                <div className="forgot-user-name">
                    <div className="logo" alt="logo" />
                    <div style={{ padding: 10 }}>
                        <FormattedMessage
                            id="forgot.userName"
                            defaultMessage="[Forgot User Name]"
                            children={(formatedMessage) => <h1 className="screen-title">{formatedMessage}</h1>}
                        />
                        <FormattedMessage id="forgot.phone" defaultMessage="[phone]" children={(formatedMessage) => <p>{formatedMessage}</p>} />

                        <FormattedMessage
                            id="app.mobilePhone"
                            defaultMessage="[mobile phone]"
                            children={(formattedMessage) => (
                                <input type="text" value={this.state.phone} placeholder="(XXX) XXX-XXX" onChange={this.onUpdateInputValue} />
                            )}
                        />

                        <div className="forgot-user-name-buttons">
                            <Button id="login.submit" defaultMessage="[Submit]" onClick={this.onSubmit} />
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showLogin: true });
                                }}
                            >
                                <FormattedMessage
                                    id="app.cancel"
                                    defaultMessage="Instructions"
                                    children={(formattedMessage) => <span className="link-button">{formattedMessage}</span>}
                                />
                            </a>
                        </div>
                    </div>

                    <SweetAlert
                        show={this.state.showDialog}
                        title={this.state.dialogTitle}
                        onConfirm={this.onCloseDialog}
                        confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
                    >
                        {this.state.dialogText}
                    </SweetAlert>
                </div>
                {this.state.showLoader ? (
                    <React.Fragment>
                        <Spinner name="line-scale" color="#3cc6e8" fadeIn="none" />
                        <div className="Loading"></div>
                    </React.Fragment>
                ) : null}
            </Fragment>
        );
    }
}

ForgotUserName.propTypes = {
    locale: PropTypes.string.isRequired,
};

ForgotUserName = withLocale(ForgotUserName);

export default injectIntl(ForgotUserName);
