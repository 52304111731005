import axios from "axios";
import qs from "qs";

export function getData(documentId) {
    return axios.get("/api/services/app/Documents/GetData", { params: { id: documentId } });
}

export function get(documentId) {
    return axios.get("/api/services/app/Documents/Get", { params: { id: documentId } });
}

export function getListByGrouping(groupId, groupType, cancelToken) {
    return axios.get("/api/services/app/Documents/GetListByGrouping", { params: { groupId, groupType }, cancelToken });
}

export function remove(documentId) {
    return axios.delete("/api/services/app/Documents/Remove", { params: { id: documentId } });
}

export function getVerifiedDocuments(applicationId, applicationStudentIdList) {
    return axios.get("/api/services/app/Documents/GetVerifiedDocuments", {
        params: { applicationId, applicationStudentIdList },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
}

export function toggleVerifyDocumentGroup(groupingId, groupType) {
    return axios.post("/api/services/app/Documents/ToggleVerifyDocumentGroup", { groupingId, groupType });
}
