import React, { Component } from 'react';
import { Button, TextField, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SweetAlert from "react-bootstrap-sweetalert";

const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});
const GoalTypes = Object.freeze({
    Baseline: 10,
    Year1: 20,
    Year2: 30,
    Year3: 40,
    Year4: 50,
    Year5: 60,
    Year6: 70,
    Year7: 80,
    Other: 90,
    _displayNames: {
        10: "Baseline",
        20: "Year 1",
        30: "Year 2",
        40: "Year 3",
        50: "Year 4",
        60: "Year 5",
        70: "Year 6",
        80: "Year 7",
        90: "Other",
    },
});

class AdvisorGoalsManager extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // Initialize with a selected goal for adding/updating
            selectedGoal: {
                advisorId: this.props.advisorId, // since it's passed as prop and hidden
                tagsId: '',
                subtagId: '',
                objectiveId: '',
                goaltype: '',
                minGradeToCount: 0,
                amountOther: 0,
                
            },
            goals: this.props.existingObjectives || [], // Load existing goals from props
            loaded: false,
            tags: [],
            subTags: [],
            deleteGoal: [],
            showAlert: false
        };
    }
    




    componentDidMount = async () => {
        if (this.props.taggingOptions.length > 0 ) {
            const tags = [];
            this.props.taggingOptions.forEach((classification) => {
                tags.push({ label: classification.classification.classification + ': ' + classification.name, id: classification.id });

            })
            this.setState({ tags: tags, goals: this.props.existingObjectives || [] });
        }
        if (this.props.availableObjectives.length > 0) {
            this.setState({ loaded: true });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        //console.log(this);
        if (this.props.taggingOptions.length > 0 && prevProps.taggingOptions != this.props.taggingOptions && !this.state.loaded) {
            const tags = [];
            this.props.taggingOptions.forEach((classification) => {
                tags.push({ label: classification.classification.classification + ': ' + classification.name, id: classification.id });

            })
            this.setState({ loaded: true, tags: tags, goals: this.props.existingObjectives || [] });
        }
        if (this.props.availableObjectives.length > 0 && !this.state.loaded) {
            this.setState({ loaded: true, goals: this.props.existingObjectives || [] });
        }
    }
    getGoalDisplay = (goal) => {
        let ret = '';
        const gl = this.props.availableObjectives.filter(flt => flt.id == goal.objectiveId);
        let amt = 0;
        

        if (gl.length > 0) {
            
            switch (goal.goaltype) {
                case GoalTypes.Baseline:
                    amt = gl[0].baseline;
                    break;
                case GoalTypes.Year1:
                    amt = gl[0].y1;
                    break;
                case GoalTypes.Year2:
                    amt = gl[0].y2;
                    break;
                case GoalTypes.Year3:
                    amt = gl[0].y3;
                    break;
                case GoalTypes.Year4:
                    amt = gl[0].y4;
                    break;
                case GoalTypes.Year5:
                    amt = gl[0].y5;
                    break;
                case GoalTypes.Year6:
                    amt = gl[0].y6;
                    break;
                case GoalTypes.Year7:
                    amt = gl[0].y7;
                    break;
                case GoalTypes.Other:
                    amt = goal.amountOther;
                    break;
                default:
                    break;
            }


            ret = gl[0].title + ': ' + amt + '%';
        }
        return ret;
    }
    handleFieldChange = (event) => {
        const { name, value } = event.target;
        if (name == "tagsId") {
            const sel = this.props.taggingOptions.filter(flt => flt.id == value);

            this.setState({ subTags: sel[0].subtags });


        }
        this.setState(prevState => ({
            selectedGoal: {
                ...prevState.selectedGoal,
                [name]: value,
            },
        }));
    };

    handleAddOrUpdateGoal = async (event) => {
        event.preventDefault();

        const { selectedGoal, goals } = this.state;
        if (selectedGoal) {
            selectedGoal.advisorId = this.props.advisorId;
            const index = this.props.selectedValues.findIndex(goal => goal.id === selectedGoal.id);
            if (index > -1) {
                await this.props.onUpdate(selectedGoal);
               // this.props.selectedValues[index] = selectedGoal; // Update existing goal
            } else {
                await this.props.onAdd(selectedGoal);
               // goals.push({ ...selectedGoal, id: Date.now() }); // Add new goal with temporary id for frontend
            }

            this.setState({ selectedGoal: [] });
        }
    };

    handleSelectGoal = (goal) => {
        const sel = this.props.taggingOptions.filter(flt => flt.id == goal.tagsId);

        
        this.setState({ selectedGoal: goal, subTags: sel[0].subtags });
    };

    handleDeleteGoal = async (goal) => {
        this.setState({
            deleteGoal: goal,
            showAlert: true
});
    };

    onConfirm = async () => {
        await this.props.onDelete(this.state.deleteGoal);
        this.setState(prevState => ({
            goals: prevState.goals.filter(gl => gl.id !== this.state.deleteGoal.id),
            showAlert: false, // Set showAlert to false
        }));
    };
    onCancel = () => {
        this.setState({
            deleteGoal: [],
            showAlert: false
        });
    }
    render() {
        const { availableObjectives, taggingOptions } = this.props;
        const { selectedGoal, goals } = this.state;
        
        //console.log(this.state);
        
        if (!this.state.loaded) {
            return <div></div>
        }
        return (
             <ThemeProvider theme={theme}>
            <form onSubmit={this.handleAddOrUpdateGoal}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                required
                                label="Objective ID"
                                name="objectiveId"
                                value={selectedGoal?.objectiveId}
                                onChange={this.handleFieldChange}
                                select
                            >
                                {availableObjectives.map(option => (
                                    <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    
                        <Grid item xs={12} sm={6}>
                            
                            <TextField
                                required
                            fullWidth
                            label="Tags ID"
                            name="tagsId"
                            value={selectedGoal?.tagsId}
                            onChange={this.handleFieldChange}
                            select
                        >
                            {this.state.tags.map(option => (
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <TextField
                                required
                            fullWidth
                            label="Subtags ID"
                            name="subtagId"
                            value={selectedGoal?.subtagId}
                            onChange={this.handleFieldChange}
                            select
                        >
                                {this.state.subTags.map(option => (
                                    <MenuItem key={option.id} value={option.id} selected={option.id == selectedGoal?.subtagId}>{option.name}</MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                            <TextField
                                required
                            fullWidth
                            label="Grade To Count"
                            name="minGradeToCount"
                            type="number"
                                value={selectedGoal?.minGradeToCount || ''}
                            onChange={this.handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="goal-type-select-label">Goal Type</InputLabel>
                                <Select
                                    required
                                labelId="goal-type-select-label"
                                id="goal-type-select"
                                name="goaltype"
                                value={selectedGoal?.goaltype || ''}
                                onChange={this.handleFieldChange}
                                label="Goal Type"
                            >
                                {Object.entries(GoalTypes._displayNames).map(([value, label]) => (
                                    <MenuItem key={value} value={value} >{label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {this.state.selectedGoal.goaltype == GoalTypes.Other && (
                        <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                fullWidth
                                label="Amount Other"
                                name="amountOther"
                                type="number"
                                    value={selectedGoal?.amountOther || ''}
                                onChange={this.handleFieldChange}
                            />
                        </Grid>
                        )}
                        <input
                            type="hidden"
                            name="AdvisorId"
                            value={this.props.userProfileId}
                        />

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            {selectedGoal?.id ? 'Update Goal' : 'Add Goal'}
                        </Button>
                    </Grid>
                    {/* List existing goals with edit and delete options */}
                        {this.props.selectedValues.map(goal => (
                        <Grid item xs={12} key={goal.id}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>{` ${  this.getGoalDisplay(goal)}`}</span>
                                <div>
                                    <Button size="small" onClick={() => this.handleSelectGoal(goal)}>Edit</Button>
                                    <Button size="small" onClick={() => this.handleDeleteGoal(goal)}>Delete</Button>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                </form>
                {this.state.showAlert ? (
                    <SweetAlert
                        title={"Confirm Delete"}
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel} // Ensure this method is defined to handle cancellation
                        showCancel={true} // This prop might be necessary to explicitly show the cancel button, depending on the version of SweetAlert you're using
                        confirmButtonText="Yes, delete it" // Optional: customize confirm button text
                        cancelButtonText="No, cancel" // Optional: customize cancel button text
                        dangerMode={true} // Optional: some versions of SweetAlert use this for styling confirmation dialogs for destructive actions
                    >
                        Confirm Delete {this.getGoalDisplay(this.state.deleteGoal) }
                    </SweetAlert>
                ) : null}

                
            </ThemeProvider>
        );
    }
}

export default AdvisorGoalsManager;
