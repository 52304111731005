import React from "react";
import { injectIntl } from "react-intl";
import withAdmissionSettings from "../../../support/hoc/withAdmissionSettings";

import "./index.css";

const ContactInfo = ({ intl, admissionSettings }) => {
    const title = intl.formatMessage({ id: "app.question" });
    const phone = intl.formatMessage({ id: "app.phone" });
    const hours = intl.formatMessage({ id: "app.hours" });
    const admissionPhone = intl.formatMessage({ id: "admissions.phone" });

    return (
        <div className="contact-info">
            <h5 style={{ marginBottom: 3 }}>{title}</h5>
            <div className="contact-name">Admissions</div>
            <div className="">Onward We Learn</div>
            <div>134 Thurbers Avenue, #111</div>
            <div>Providence, RI 02905</div>
            <div>
                <a href="mailto:admissions@onwardwelearn.org" className="link-button">
                    admissions@onwardwelearn.org
                </a>
            </div>
            <div>
                {phone}: {admissionPhone}
            </div>
            <div>{hours}: 8:30am - 4:30pm</div>
        </div>
    );
};

export default withAdmissionSettings(injectIntl(ContactInfo));
