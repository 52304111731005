import axios from "axios";

export const getRequestPayments = scholarshipId => {
  return axios.get("/api/services/app/ScholarshipTransaction/GetRequestPayments", { params: { scholarshipId } });
};

export const getRequestPaymentsForExistinRequest = (scholarshipId, transactionRequestId) => {
  return axios.get("/api/services/app/ScholarshipTransaction/GetRequestPayments", { params: { scholarshipId, transactionRequestId } });
};

export const getStatus = scholarshipId => {
  return axios.get("/api/services/app/ScholarshipTransaction/GetPaymentRequestStatuses", { params: { id: scholarshipId } });
};
