import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    datesList: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        alignSelf: "flex-start",
    },
}));
