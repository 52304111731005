import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import { Grid } from '@material-ui/core';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';



class ContactedGraph extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
           loaded: false


        };
    }



    componentDidMount() {
        if (this.context.loaded && !this.state.loaded) {

            this.calculateData();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.context.loaded && !this.state.loaded ) {
           
            this.calculateData();
        }
    }


    calculateData = () => {
        let data = [];

        const contacted = this.context.myCases.filter((flt) => flt.studentContactInfo.contacted == true);

        data.push({
            name: 'Contacted',
            value: contacted.length,
            fill: '#123225'
        });

        data.push({
            name: 'Uncontacted',
            value: this.context.myCases.length - contacted.length,
            fill: '#e2a331'
        });

        this.setState({data: data, loaded: true});
    }



    renderBarChart = () =>
    {

        return (
            
            <BarChart
                width={250}
                height={150}
                data={this.state.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Contacted" fill="#123225" activeBar={<Rectangle fill="#2c6648" stroke="blue" />} />
                <Bar dataKey="Uncontacted" fill="#e2a331" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                    </BarChart>
            
        
        );
    }

    render() {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };
        return (
            <Grid container spacing={2} style={{
                display: 'flex', // Use flexbox layout
                flexDirection: 'row', // Stack children vertically
                height: '100%', // Take the full height available
                justifyContent: 'flex-start', // Start alignment at the top for the first child
                minHeight: '255px'
            }}>
                <Grid item xs={4} style={{
                    flex: 1, // Take up all available space
                    display: 'flex', // Use flexbox layout
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'center', // Center vertically in the remaining space
                    textAlign: 'center', // Center text horizontally
                }}>
                    <span>Students Contacted</span><br/>
                    {this.state.data.length == 2 ? <span>{this.state.data[0].value} / {this.state.data[0].value + this.state.data[1].value}</span> : null }
                    

                </Grid>
                <Grid item xs={8}>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={300} height={300}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={this.state.data}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#123225"
                                label={renderCustomizedLabel} // Using the custom label function
                        labelLine={false} // Optional: if you don't want the lines from sectors to labels
                            />
                            <Legend verticalAlign="bottom" height={36} />
                    <Tooltip />
                </PieChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        );
    }

}

export default ContactedGraph;
