import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import { addLocaleData } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
//import en from "react-intl/locale-data/en";
//import es from "react-intl/locale-data/es";
import Grid from "@material-ui/core/Grid";
import store from "./redux/store";
import App from "./App";
import { loadMessagesAction } from "./language/redux";
import { getLocaleAction } from "./language/redux";
import AxiosInterceptor from "./support/components/axiosInterceptor";
import UserConfirmation from "./support/components/userConfirm";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

import "./index.css";
import ErrorBoundary from "./support/components/errorBoundary";

unregisterServiceWorker();

if (!String.prototype.startsWith) {
    // eslint-disable-next-line
    String.prototype.startsWith = function (search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
}

store.dispatch(loadMessagesAction());
store.dispatch(getLocaleAction());

//addLocaleData(en);
//addLocaleData(es);

const getUserConfirmation = (message, callback) => {
    const modal = document.createElement("div");
    document.body.appendChild(modal);

    const withCleanup = (answer) => {
        ReactDOM.unmountComponentAtNode(modal);
        document.body.removeChild(modal);
        callback(answer);
    };

    const parts = message.split("||"); //`${this.message}||${this.title}||${this.yes}||${this.cancel}`;

    ReactDOM.render(
        <UserConfirmation
            message={parts[0]}
            title={parts[1]}
            yes={parts[2]}
            cancel={parts[3]}
            onCancel={() => withCleanup(false)}
            onConfirm={() => withCleanup(true)}
        />,
        modal
    );
};

const Wrapper = () => {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <Router getUserConfirmation={getUserConfirmation} onUpdate={() => window.scrollTo(0, 0)}>
                    <Fragment>
                        <AxiosInterceptor />
                        <Grid container>
                            <Grid item xs={12} className="app-wrapper">
                                <App />
                            </Grid>
                        </Grid>
                    </Fragment>
                </Router>
            </Provider>
        </ErrorBoundary>
    );
};

ReactDOM.render(<Wrapper />, document.getElementById("root"));
