import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { getWaitlistEventDates } from "../api/programEvents";
import { ProgramEventDateRegistrationStatus } from "../enums";
import EventDateSeriesTable from "../common/eventDateTable/eventDateSeriesTable";
import EventDateNonSeriesTable from "../common/eventDateTable/eventDateNonSeriesTable";
import enumHelper from "../../support/logic/enumHelper";
import LoadingIndicator from "../../common/loadingIndicator";
import useAjaxStatus from "../../common/useAjaxStatus";
import EventHeader from "../common/eventHeader/eventHeader";
import moment from "moment";
import { EventLocationType } from "../enums";

const EventDateWaitlist = () => {
    const maxItemsToShow = 3;
    const history = useHistory();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const {
        state: { programName },
    } = useLocation();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isSeries, setIsSeries] = useState(true);
    const [rows, setRows] = useState([]);

    const handleOnClickDate = (rowData) => {
        rowData.programName = programName;
        history.push({
            pathname: `/events/active/event/${rowData.programEventId}/waitlist`,
            state: { rowData },
        });
    };

    useEffect(() => {
        getWaitlistEventDates(id)
            .then((resp) => {
                const _isSeries = resp.data.result.isSeries;
                const _rows = resp.data.result.dates.map((data) => ({
                    ...data,
                    dateTime: `${moment(data.startTime).format("M/D/YYYY")} ${moment(data.startTime).format("h:mm a")} - ${moment(data.endTime).format(
                        "h:mm a"
                    )}`,
                    date: `${moment(data.startTime).format("M/D/YYYY")}`,
                    time: `${moment(data.startTime).format("h:mm a")} - ${moment(data.endTime).format("h:mm a")}`,
                    status: enumHelper.getDisplayName(ProgramEventDateRegistrationStatus, data.registrationStatus),
                    participants: `${data.waitlistTotal}/${data.waitlistCapacity}`,
                    isSeries: _isSeries,
                    id: data.id,
                    programEventId: data.programEventId,
                    locationType: enumHelper.getDisplayName(EventLocationType, data.locationType),
                }));
                setRows(_rows);
                setIsSeries(_isSeries);
                setIsLoading(false);
            })
            .catch((err) => ajaxErrorWrapper(err));
    }, [ajaxErrorWrapper, id]);

    if (isLoading) return <LoadingIndicator loading={isLoading} />;

    return (
        <>
            <EventHeader mainTitle={programName} subTitle="Waitlist" />
            <div style={{ height: "100%", width: "100%" }}>
                {isSeries ? (
                    <EventDateSeriesTable rows={rows} maxItemsToShow={maxItemsToShow} handleOnClickDate={handleOnClickDate} waitlist />
                ) : (
                    <EventDateNonSeriesTable rows={rows} handleOnClickDate={handleOnClickDate} waitlist />
                )}
            </div>
        </>
    );
};

export default EventDateWaitlist;
