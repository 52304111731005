import React, { Component } from 'react';
import { Grid, Checkbox, TextField, Button, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AdvisorContext from "../context/context";
import Multiselect from 'multiselect-react-dropdown';
import enumHelper from "../../support/logic/enumHelper";
import { Language } from "../../admissions/logic/enums";
import { sendAdvisorMessage } from "../api";
import { toast } from "react-toastify";
import ConfirmDialog from "../../common/confirmDialog";
import "./index.css";

const languageOptions = enumHelper
    .getSelectOptionsList(Language);

class AdvisorMessage extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            subject: "",
            message: "",
            mode: true,
            count: 0,
            confirmOpen: false,
            confirmMessage: ""

        };
        //console.log(this.props); 
    }

    componentDidMount() {
        
        if (this.props.activeProfile != [] && this.props.activeProfile.length != 0) {
            this.setState({ mode: false, count: 1 });
        } else {
            this.setState({ count: this.context.recipients.length });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        
        
    }
    closeConfirm = () => {
        this.setState({ confirmOpen: false })
    }
    getConfirmation = async () => {
        this.setState({ confirmMessage: "Send this message to (" + this.state.count + ") Student" + (this.state.count > 1 ? "s" : "")  + " and their Parents/Guardians?", confirmOpen: true  });
    }

    send = async () => {

        let recsPri = [];
            
        
        if (this.state.mode ) {

            let recs = this.context.recipients.map(item => ({
                userProfileId: item.userProfile.id,
                firstName: item.userProfile.firstName,
                lastName: item.userProfile.lastName,
                UserId: item.userProfile.userId,
                email: item.userProfile.email,
                mobilePhone: item.userProfile.mobilePhone

            }));
            try {

            } catch (ex) {
                //console.log(ex.toString());
            }
            recsPri = this.context.recipients.map(item => ({
                userProfileId: item.family.primaryParent.id,
                firstName: item.family.primaryParent.firstName,
                lastName: item.family.primaryParent.lastName,
                UserId: item.family.primaryParent.userId,
                email: item.family.primaryParent.email,
                mobilePhone: item.family.primaryParent.mobilePhone
            }));
            let recips = [...recs, ...recsPri.filter(flt => flt.userProfileId != '00000000-0000-0000-0000-000000000000')];




            const payload = {
                subject: this.state.subject,
                body: this.state.message,
                recip: recips
            }

            


            await sendAdvisorMessage(payload);


        } else {


            let rec = {
                userProfileId: this.props.activeProfile.userProfile.id,
                firstName: this.props.activeProfile.userProfile.firstName,
                lastName: this.props.activeProfile.userProfile.lastName,
                UserId: this.props.activeProfile.userProfile.userId,
                email: this.props.activeProfile.userProfile.email,
                mobilePhone: this.props.activeProfile.userProfile.mobilePhone

            };
            let recPri = {
                userProfileId: this.props.activeProfile.family.primaryParent.id,
                firstName: this.props.activeProfile.family.primaryParent.firstName,
                lastName: this.props.activeProfile.family.primaryParent.lastName,
                UserId: this.props.activeProfile.family.primaryParent.userId,
                email: this.props.activeProfile.family.primaryParent.email,
                mobilePhone: this.props.activeProfile.family.primaryParent.mobilePhone
            };
            let recips = [rec, recPri];




            const payload = {
                subject: this.state.subject,
                body: this.state.message,
                recip: recips
            }

            


            await sendAdvisorMessage(payload);
        }
        toast.success("Message Sent");

        this.props.onClose();
       

    }

    cancel = () => {
        toast.success("Message Canceled");
        this.props.onClose();
    }

    toggleMessage = (list, newRec) => {
        
        
        const newRecipient = this.context.myCases.filter(flt => flt.userProfile.id == newRec.userProfileId)
        
            // Check if the recipient is already in the array
            const isRecipientPresent = this.context.recipients.includes(newRecipient[0]);

            // If the recipient is present, filter it out; if not, add it
            const updatedRecipients = isRecipientPresent
                ? this.context.recipients.filter(recipient => recipient !== newRecipient[0])
                : [...this.context.recipients, newRecipient[0]];
        
            this.context.setRecipients(updatedRecipients);

        this.setState({ count: updatedRecipients.length });

        

    }

    getLanguage = (input) => {

        const lan = languageOptions.filter(l => l.value == input);
        return lan.length > 0 ? lan[0].label : "English";
    }

    customRecipientOptionRenderer = (option, val, test) => {
        
        


        return (
            <div className="filterSelection" >

                {val.lastName}, {val.firstName}  <span className="recipLanList">({this.getLanguage(val.primaryLanguage) == "Other" ? val.primaryLanguageOther : this.getLanguage(val.primaryLanguage) })</span>               

            </div>
        );
    };


    customRecipientChipRenderer = (option, val, test) => {
        



        return (
            <div className="filterSelection" >

                {val.lastName}, {val.firstName}  <span className="recipLan">({this.getLanguage(val.primaryLanguage) == "Other" ? val.primaryLanguageOther : this.getLanguage(val.primaryLanguage)})</span>

            </div>
        );
    };

    updateSubject = (event) => {
        
        this.setState({ subject: event.target.value });
    };
    updateMessage = (event) => {

        this.setState({ message: event.target.value });
    };

    render() {
        //console.log(this.state);
        return (
            <div style={{ padding: '20px', backgroundColor: 'white', width: '100%' }}> 
                <Grid container spacing={2}>
                    
                    <Grid item xs={12}>
                        <Typography variant="h6">Compose Message to Send to {this.state.count} Student{this.state.count > 1 ? "s" : null} and cc their Parents/Guardians</Typography>
                    </Grid>

                    
                    <Grid item xs={12}>
                        

                        {this.state.mode ? (
                            <Multiselect
                    options={this.context.myCases}
                    showCheckbox={true}
                     onSelect={this.toggleMessage}
                     onRemove={this.toggleMessage}
                    selectedValues={this.context.recipients}
                    displayValue="display"
                    className="AdvisorLogsStudents"
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    name="recipientSel"
                    placeholder="Select Participants"
                   
                />
                            
                        ) : (
                                this.props.activeProfile && (
                                <div className="chip">
                                        {this.props.activeProfile.userProfile.lastName}, {this.props.activeProfile.userProfile.firstName} &nbsp;<span className="recipLan">({this.getLanguage(this.props.activeProfile.family.primaryLanguage) === "Other" ? this.props.activeProfile.family.otherLanguage : this.getLanguage(this.props.activeProfile.family.primaryLanguage)})</span>
                                </div>
                            )
                        )}

                        
                        
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Subject: Great Job!"
                            name="subject"
                            onBlur={this.updateSubject }
                        />
                    </Grid>
                    {/* Message Body */}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="You are so close to meeting your goals! Reach out to me if you need help!"
                            name="body"
                            onBlur={this.updateMessage}
                        />
                    </Grid>

                    {/* Action Buttons */}
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={ this.getConfirmation}>Send</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={ this.cancel }>Cancel</Button>
                    </Grid>
                </Grid>
                <ConfirmDialog
                    open={this.state.confirmOpen}
                    disablePortal
                    handleClose={this.closeConfirm}
                    confirmAction={this.send}
                    confirmActionText="Send Message"
                    fullWidth
                    maxWidth="sm"
                    title="Send Message"
                    body={this.state.confirmMessage}
                />
            </div>
        );
    }
}

export default AdvisorMessage;
