import * as React from 'react';
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Button, OutlinedInput } from "@material-ui/core";
import { useStyles } from "./styles";
import { getAllTemplates, CreateProgramFromTemplate, deleteTemplate } from "../api/programEvents";
import { toast } from "react-toastify";
import Modal from "react-modal";
import NewTemplateForm from "./newTemplateForm.jsx"


export default function Templates() {
    const [rows, setRows] = React.useState(getTemplatesRows);
    const [loading, setLoading] = React.useState(true);
    const [showNew, setShowNew] = React.useState(false);
    const [selectedTemplate, setSelectedTemplate] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            const rows = await getTemplatesRows();
            setRows(rows.result);
            setLoading(false);

        };
        fetchData();

    }, []);

    async function createEvent(params) {


        setSelectedTemplate(params.row);
        setShowNew(true);
        
        if (!loading) {
            
                
            
              
              //  await CreateProgramFromTemplate(params);

            //toast.success("Success! New Event Created.");
                
            
            
        }

        


    };
    const doNothing = async (org) => {
        //console.log("doing nothing:" + org);
        return false;
    };
    const handleCloseAddDialog = () => {
        setShowNew(false);
    }

    const deleteEventTemplate = async (params) => {
        setLoading(true);
        
        await deleteTemplate(params.row);
        const trows = await getTemplatesRows();
        setRows(trows.result);
        setLoading(false);
        
        
    };
    const getTemplatesRows = async () => {

        const result = await getAllTemplates();
     
        return result.data;
    };

    const columns = React.useMemo(
        () => [
            {
                field: "name",
                headerName: "Template Name",
                flex: 1,
            },
            {
                field: "department",
                headerName: "Department",
                flex: 1,
            },
            {
                field: "descriptionEnglish",
                headerName: "Description",
                flex: 1,
            },
            {
                field: "Actions",
                type: "actions",
                headerName: "Actions",
                flex: 1,
                getActions: (params) => [
                   
                    <GridActionsCellItem
                        label="Create Event"
                        onClick={() => { createEvent(params) }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        label="Delete Template"
                        onClick={() => { deleteEventTemplate(params) }}
                        showInMenu
                    />,
                ],
            },
        ],
        [deleteTemplate, createEvent],
    );

    return (
        <div>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid columns={columns} rows={rows} loading={loading} />
        </div>
            

            {showNew && <NewTemplateForm open={showNew} onClose={handleCloseAddDialog} selectedTemplate={selectedTemplate} />}
        </div>
    );
}