export const scholarshipStatus = Object.freeze({
    notStarted: 0,
    started: 1,
    close: 2,
});
export const certificationReason = Object.freeze({
    reviewPending: 1,
    certified: 2,
    lowerAward: 3,
    withdrew: 4,
    noDiplomaGen: 5,
    noUnmetNeed: 6,
    programIneligible: 7,
    notMatriculated: 8,
    leaveOfAbsense: 9,
    loanDefault: 10,
    refundOwed: 11,
    selectiveService: 12,
    drugConviction: 13,
});
export const crusaderStatus = Object.freeze({
    fullTime: 1,
    threeQuarterTime: 2,
    halfTime: 3,
    lessThanHalfTime: 4,
    notEnrolled: 5,
    unknown: 999,
});

export const scholarshipLevel = Object.freeze({
    Gold: 1,
    Silver: 2,
    Bronze: 3,
    Pewter: 4,
    OneTimeGrant: 5,
    Unknown: 999,
    _displayNames: {
        1: "Gold",
        2: "Silver",
        3: "Bronze",
        4: "Pewter",
        5: "One Time Grant",
        999: "Unknown"
    }
});
export const crusaderGrade = Object.freeze({
    freshman: 1,
    sophomore: 2,
    junior: 3,
    senior: 4,
    unknown: 999,
});
export const scholarshipBreakdownType = Object.freeze({
    FourYear: 1,
    TwoYear: 2,
    TradeSchool: 3,
});
export const phoneType = Object.freeze({
    mobile: 1,
    home: 2,
    work: 3,
});

export const institutionType = Object.freeze({
    twoYear: 1,
    fourYear: 2,
    TradeSchool: 3,
});
export const termType = Object.freeze({
    trimester: 1,
    semester: 2,
    tradeSchool: 3,
});
export const transactionRequestType = Object.freeze({
    payment: 1,
    refund: 2,
});

export const AllocationType = Object.freeze({
    CashOrDonated: 0,
    CashAndDonated: 1,
});
export const searchType = Object.freeze({
    institution: 1,
    student: 2
});