import React, { useState, useEffect } from 'react';
import AdvisorContext from './context';
import * as api from "../api";

import * as taggingApi from "../../api/tagging";
import { toast } from "react-toastify";

const AdvisorProvider = ({ children }) => {

    const [statusMessage, setStatusMessage] = useState("Initializing Data...");
    const [myCases, setMyCases] = useState([]);
    const [myCasesLoaded, setMyCasesLoaded] = useState(false);
    const [myInfo, setMyInfo] = useState([]);
    const [myBasics, setMyBasics] = useState([]);
    const [ipedList, setIpedList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolInstList, setSchoolInstList] = useState([]);
    const [studentMailList, setStudentMailList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [tagList, setTaglist] = useState([]);
    const [filteredCases, setFilteredCases] = useState([]);
    const [taggingOptions, setTaggingOptions] = useState([]);
    const grList = [{ name: "5", value: "5" }, { name: "6", value: "6" }, { name: "7", value: "7" }, { name: "8", value: "8" }, { name: "9", value: "9" }, { name: "10", value: "10" }, { name: "11", value: "11" }, { name: "12", value: "12" }, { name: "13", value: "13" }, { name: "14", value: "14" }, { name: "15", value: "15" }, { name: "16", value: "16" }, { name: "17", value: "17" }, { name: "18", value: "18" }];
    const tfList = [{ name: "Yes", value: true }, { name: "No", value: false }];
    
    const [studentList, setStudentList] = useState([]);
    const [subTagList, setSubTagList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [inProgramList, setInProgramList] = useState([]);
    const [contactedList, setContactedList] = useState([]);
    const [datesList, setDatesList] = useState([]);
    const [institutionList, setInstitutionList] = useState([]);
    const [milestoneTags, setMilestoneTags] = useState([])
    const [recipients, setRecipients] = useState([]);
    
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedSubTags, setSelectedSubTags] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedContacted, setSelectedContacted] = useState([]);
    const [selectedInProgram, setSelectedInProgram] = useState([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState(0);


    useEffect(() => {
        if (myCases.length == 0  && !loaded) {
            fetchData();
            // //console.log("Advisor Context Initialized");
        }
    }, [myCases]);


    useEffect(() => {
        if (studentList.length > 0) {
            fetchStudentData();
            // //console.log("Advisor Context Initialized");
        }
    }, [studentList]);

    useEffect(() => {
        if (filteredCases.length == 0) {
            setStatusMessage(filteredCases.length + " Cases");
        }
    }, [filteredCases]);
    

    const fetchData = async () => {
        try {
            setInProgramList(tfList);
            setContactedList(tfList);
            setGradeList(grList);
            setStatusMessage("Requesting Profile Data...");
            let si = [];
            // Start all fetch requests simultaneously
            const [
                basicsResponse,
                meResponse,
                citiesResponse,
                ipedResponse,
                milestonesResponse,
                schoolsResponse,
                instsResponse,
                tagoptionsResponse
            ] = await Promise.all([
                api.getMyAdvisorBasics(),
                api.getMyProfile(),
                api.getCityList(),
                api.getIpeds(),
                api.getMilestoneTags(),
                api.getAllSchools(),
                api.getAllInstitutions(),
                taggingApi.returnChoices(tagFilter)
            ]);

            // Update state with the fetched data
            setMyBasics(basicsResponse.data.result);
            setMyInfo(meResponse.data.result);
            setCityList(citiesResponse.data.result);
            setIpedList(ipedResponse.data.result);
            setMilestoneTags(milestonesResponse.data.result);
            setSchoolList(schoolsResponse.data.result);
            setInstitutionList(instsResponse.data.result);
            setTaggingOptions(tagoptionsResponse.data.result);
            si.push(...schoolsResponse.data.result);
            si.push(...instsResponse.data.result);
            const sortedSi = si.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0; // names must be equal
            });
            setSchoolInstList(sortedSi);
            // Process tag list options
            let tl = [];
            for (const tos of tagoptionsResponse.data.result) {
                for (const to of tos.dropList) {
                    if (!to.id.includes("|")) {
                        tl.push({ color: tos.classification.color, id: to.id, label: to.label });
                    }
                }
            }
            setTaglist(tl);

            setStatusMessage("Loading Cases...");
            const response = await api.getMyCrusadersByAdvisor();
            const result = response.data.result;
            setMyCases(result);
            if (result.length === 0) {
                setLoaded(true);
                setStatusMessage("No Cases Found");
            } else {
                const stList = result.map(item => ({
                    userProfileId: item.userProfile.id
                }));
                setStudentList(stList);
                setFilteredCases(result);
                setStatusMessage("Loading Tags ...");
                setStudentMailList(getMailList(result));
                setMyCasesLoaded(true);
                setLoaded(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
       
        
        refreshCaseFilters();
       
            
        
    }, [selectedTags, selectedSubTags, selectedSchools, selectedGrades, selectedContacted, selectedInProgram, selectedDates, selectedAdvisor]);

    const refreshCaseFilters = () => {
        const ncases = applyFilters();


        setFilteredCases(ncases);
        setStatusMessage(ncases.length + " Cases");
    }

    const fetchStudentData = async () => {
        setStatusMessage("Loading Additional Data");
        const batchSize = 1000; // Size of each batch
        const batches = Math.ceil(studentList.length / batchSize); // Number of batches
        let promises = [];

        try {
            for (let i = 0; i < batches; i++) {
                // Get the current batch
                const currentBatch = studentList.slice(i * batchSize, (i + 1) * batchSize);

                // Prepare the API call for the current batch
                if (currentBatch.length > 0) {
                    promises.push(api.retrieveAdditionInfo(currentBatch));
                } else {
                    break;
                }
            }

            // Execute all promises simultaneously
            const results = await Promise.all(promises);

            // Process results for each batch
            results.forEach(result => {
                const rslt = result.data.result;
                applyMoreInfo(rslt);
            });

            const ncases = applyFilters();

            setFilteredCases(ncases);
            setStatusMessage(ncases.length + " Cases");
            setLoaded(true);

        } catch (error) {
            console.error('Error fetching student data:', error);
        }
    };

    const refreshStudentInfo = async (list) => {
        var res = await api.retrieveAdditionInfo(list);
        var rslt = res.data.result;

        // Process the results for the current batch
        applyMoreInfo(rslt);

    }
    const applyMoreInfo = async (info) => {
        const mCases = myCases;

        // Iterate over the info array
        info.forEach(adInf => {
            
            const item = mCases.find(mCase => mCase.userProfile.id === adInf.userProfileId);

            
            if (item) {
                try {
                    item.studentContactInfo = adInf.contactInfo;
                    item.allAdvisors = adInf.allAdvisors;
                } catch (ex) {
                    //console.log(ex);
                }
                try {
                    item.family = adInf.family;
                } catch (ex) {
                    //console.log(ex);
                }
                try {
                    item.passcode = adInf.passcode;
                } catch (ex) {
                    //console.log(ex);
                }
                
                try {
                    if (item.studentProfile.selectedInstitutionId != null) {
                        
                        item.schoolInst = item.institution;
                        
                    } else {
                        item.schoolInst = item.school;
                    }
                } catch (ex) {
                    //console.log(ex);
                }
            }
        });

        setMyCases(mCases);
        const filtered = applyFilters();
        setFilteredCases(filtered);
    };


    const tagFilter = {
        searchText: "",
        IsActive: true,
        UseEvents: true,
        UseAdvisors: true,
    }
    const refreshCases = async () => {
        setLoaded(false);
        setStatusMessage("Loading Cases...");
        //console.log("refreshing cases");
        const response = await api.getMyCrusadersByAdvisor({
            headers: {
                'Cache-Control': 'no-cache'
            }
        });
        //console.log(response);
        const result = response.data.result;
        //console.log(result.length);
        setMyCases(result);
        const filtered = applyFilters();
        

        setFilteredCases(filtered);
        setStatusMessage(filtered.length + " Cases");
        
        const stList = result.map(item => ({
            userProfileId: item.userProfile.id
        }));
        setStudentList(stList);

        setStudentMailList(getMailList(result));
        await fetchStudentData()
        setLoaded(true);
    }


    const getMailList = (selected) => {

        return selected.map(item => ({
            userProfileId: item.userProfile.id,
            firstName: item.userProfile.firstName,
            lastName: item.userProfile.lastName,
            primaryLanguage: item.family.primaryLanguage,
            primaryLanguageOther: item.family.otherLanguage || null
        }));
    }


   

    const updateState = async () => {
        // If you want to refresh data from the API
        fetchData();
    };







    const upDateFilteredCases = async (newCases) => {

        


         setFilteredCases(newCases);
         setStatusMessage(newCases.length + " Cases");
        

    }

    const applyFilters =  (skip) => {

        const skipped = skip ? skip : "";
        let newCases = myCases;
        
        

        if (skipped != "contacted") {
           
            const cases =  applyContactedFilter(newCases);
      //      //console.log(`Contacted ${cases.length}`);
            
            newCases = cases;
        }

        
        if (skipped != "inProgram") {
            
            const cases =  applyInProgramFilter(newCases);
       //     //console.log(`In Program ${cases.length}`);
           
            newCases = cases;
        }

        
        if (skipped != "schools") {
            
            const cases =  applySchoolsFilter(newCases);
       //     //console.log(`Schools ${cases.length}`);
           
            newCases = cases;
        }

        
        if (skipped != "grades") {
            
            const cases =  applyGradesFilter(newCases);
        //    //console.log(`Grades ${cases.length}`);
            
            newCases = cases;
        }

        
        if (skipped != "tags") {
            
            const cases =  applyTagsFilter(newCases);


            
            newCases = cases;
        }

        
        if (skipped != "subTags") {
            
            const cases =  applySubTagsFilter(newCases);

           
            newCases = cases;
        }
        if (skipped != "dates") {

            const cases = applyDatesFilter(newCases);
            newCases = cases;
        }

        const cases = applyAdvisorFilter(newCases);
        newCases = cases;

        return newCases;
    }

    const applyAdvisorFilter = (cases) => {
        const beg = cases;
        let nA = [];

        if (selectedAdvisor != 0) {
            const tmp = beg.filter(myCase =>
                Array.isArray(myCase.allAdvisors) && // Check if allAdvisors is an array
                myCase.allAdvisors.some(advisor => advisor.userId == selectedAdvisor)
            );

            tmp.forEach(cf => {
                if (nA.indexOf(cf) === -1) {
                    nA.push(cf);
                }
            });

            return nA;
        } else {
            return cases;
        }
    };


    const applyContactedFilter =  (cases) => {

        const beg = cases;
        let nA = [];

        if (selectedContacted.length > 0) {
            selectedContacted.forEach(ea => {
                const tmp = beg.filter(flt => flt.studentContactInfo.contacted == ea.value);

                tmp.forEach(cf => {
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
                });
            });

            return nA;

        } else {

            return cases;
        }
       
    }


    const applyInProgramFilter =  (cases) => {
        if (selectedInProgram.length > 0) {
            let nA = [];
            selectedInProgram.forEach(ea => {
                const tmp = cases.filter(flt => flt.studentContactInfo.inProgram == ea.value);
                tmp.forEach(cf => {
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
                });
               
            });
            return nA;
        } else {
            return cases;
        }
    };


    const applySchoolsFilter = (cases) => {
        // //console.log(selectedSchools);
        if (selectedSchools.length > 0) {
            let nA = [];
            
            selectedSchools.forEach(ea => {
                
                const tmp = cases.filter(flt => flt.schoolInst != null && flt.schoolInst.id.toLowerCase() == ea.id.toLowerCase());
                tmp.forEach(cf => {
                    
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
                });
            });
            return nA;
        } else {
            return cases;
        }
    };


    const applyGradesFilter =  (cases) => {

        if (selectedGrades.length > 0) {
            let nA = [];
            
            selectedGrades.forEach(ea => {
                
                const tmp = cases.filter(flt => flt.studentProfile.currentGrade == ea.value);
                tmp.forEach(cf => {
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
                });
            });
            return nA;
        } else {
            return cases;
        }
    };


    const applyTagsFilter =  (cases) => {
        if (selectedTags.length > 0) {
            let nA = [];
           
            selectedTags.forEach(ea => {
                // //console.log(ea)
                const tmp = cases.filter(flt =>
                    flt.studentContactInfo.tags.some(tag => tag.tagId.toLowerCase() == ea.id.toLowerCase())
                );
                // //console.log(tmp)
                tmp.forEach(cf => {
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
                
            });
 
            });
            return nA;
        } else {
            return cases;
        }
    };


    const applySubTagsFilter = (cases) => {
        
        if (selectedSubTags.length > 0) {



            let nA = [];
            selectedSubTags.forEach(ea => {
               
                const tmp = cases.filter(flt =>
                    flt.studentContactInfo.tags.some(tag => tag.idMatch.toLowerCase() == ea.id.toLowerCase())
                );
                
                tmp.forEach(cf => {
                    if (nA.indexOf(cf) == -1) {
                        nA.push(cf);
                    }
            });
            });
            return nA;
        } else {
            return cases;
        }
    };


    const applyDatesFilter = (cases) => {
        if (selectedDates.length > 0) {
            let nA = [];

            selectedDates.forEach(ea => {
                // Set beginDate to start of the day
                const beginDate = new Date(ea.beginDate);
                beginDate.setHours(0, 0, 0, 0);
                const cutoff = new Date('1/2/2000');
                // Set endDate to end of the day
                const endDate = new Date(ea.endDate);
                endDate.setHours(23, 59, 59, 999);

                // Check if beginDate is January 1, 2000
                const isBeginDate2000 = beginDate < cutoff;
                if (isBeginDate2000) {
          //          //console.log(ea);
                }
                cases.forEach(flt => {
                    const lastContactedDate = flt.studentContactInfo.lastContacted;
                    let lastContacted;

                    // Convert to date only if lastContacted is not null
                    if (lastContactedDate) {
                        lastContacted = new Date(lastContactedDate);
                    }

                    // Include if lastContacted is within range or (is null and beginDate is Jan 1, 2000)
                    if ((lastContacted && lastContacted >= beginDate && lastContacted <= endDate) ||
                        (isBeginDate2000 && !lastContactedDate)) {
                        if (!nA.includes(flt)) {
                            nA.push(flt);
                        }
                    }
                });
            });

            return nA;
        } else {
            return cases;
        }
    };





    const onTagSelect =  (selectedList, selectedItem) => {
        
        let nList = [];

        taggingOptions.forEach(cl => {
            const stl = cl.dropList.filter(dd => dd.id.includes(selectedItem.id));
            
            stl.forEach(to => {
                nList.push({ color: cl.classification.color, id: to.id, label: to.label });
            })
           
        });
         
        
        


        setSubTagList([...subTagList, ...nList]);
         setSelectedTags([...selectedTags, selectedItem]);
        
            

       
        
    }

    const onTagRemove =  (selectedList, removedItem) => {

        let nList = [];

        taggingOptions.forEach(cl => {
            const tl = cl.dropList.filter(dd => dd.id.includes(removedItem.id));
            nList.push(...tl);
        });

    
        let prSt = subTagList;
        let prSst = selectedSubTags;
        nList.forEach(st => {
            let nSt = prSt.filter(item => item.id.toLowerCase() != st.id.toLowerCase());
            prSt = nSt;
            let nSst = prSst.filter(item => item.id.toLowerCase() != st.id.toLowerCase());
            prSst = nSst;
        });

        setSelectedSubTags(prSst);
        setSubTagList(prSt);
        setSelectedTags(selectedTags.filter(item => item.id.toLowerCase() != removedItem.id.toLowerCase()));
         

        
    }

    const onSubTagSelect =  (selectedList, selectedItem) => {
       
         setSelectedSubTags([...selectedSubTags, selectedItem]);
        
    }

    const onSubTagRemove =  (selectedList, removedItem) => {
        
        setSelectedSubTags(selectedSubTags.filter(item => item.id.toLowerCase() != removedItem.id.toLowerCase()));

    }

    const onSchoolSelect =  (selectedList, selectedItem) => {
        const selSchools = selectedSchools;
         setSelectedSchools([...selSchools, selectedItem]);

    }
    const onSchoolRemove =  (selectedList, removedItem) => {
        //console.log(removedItem);
        const selSchools = selectedSchools;
        setSelectedSchools(selSchools.filter(item => item.id.toLowerCase() != removedItem.id.toLowerCase()));

    }
    const onGradeSelect =  (selectedList, selectedItem) => {
        const sGrade =  [...selectedGrades, selectedItem];

        setSelectedGrades(sGrade);
        
    }
    const onGradeRemove =  (selectedList, removedItem) => {
      
        const sGrade = selectedGrades;
         setSelectedGrades(sGrade.filter(item => item.value != removedItem.value));
       
    }

    const onContactedSelect =  (selectedList, selectedItem) => {
        const selCont = selectedContacted;
         setSelectedContacted([...selCont, selectedItem]);


    }
    const onContactedRemove =  (selectedList, removedItem) => {
        const selCont = selectedContacted;
         setSelectedContacted(selCont.filter(item => item.value != removedItem.value));

    }

    const onInProgramSelect =  (selectedList, selectedItem) => {
        const selInPro = selectedInProgram;
        setSelectedInProgram([...selInPro, selectedItem]);

    }
    const onInProgramRemove =  (selectedList, removedItem) => {
        const selInPro = selectedInProgram;
        setSelectedInProgram(selInPro.filter(item => item.value != removedItem.value));

    }


    const onDateRangeSelect = (selectedItem) => {
        ////console.log("DRS");
       // //console.log(selectedItem);

        if (selectedItem.label) {
            
            setSelectedDates([...selectedDates, selectedItem]);
        } else {
            
        }
    };

    const onDateRangeRemove = (removedItem) => {
     //   //console.log("DRR");
      //  //console.log(removedItem);
        const selDates = selectedDates;
        setSelectedDates(selDates.filter(item => item.label != removedItem.label));

    }
    const onCustomDateRangeSelect = (selectedItem) => {
      //  //console.log(selectedItem);

        if (selectedItem.label) {

            setSelectedDates([...selectedDates, selectedItem]);
            setDatesList([...datesList, selectedItem])
        } else {
            
            setDatesList([...datesList, selectedItem])
            setSelectedDates([...selectedDates, selectedItem]);
            
        }
    };

    const onCustomDateRangeRemove = (removedItem) => {
       // //console.log("CDRR");
       // //console.log(removedItem);
        const selDates = selectedDates;
        const dl = datesList;
        setDatesList(dl.filter(item => item.label != removedItem.label));
        setSelectedDates(selDates.filter(item => item.label != removedItem.label));
        
    }

    const searchArray = (array, searchString) => {
        const lowerSearchString = searchString.toLowerCase();

        return array.filter(obj => {
            // Check each property of the object
            return Object.values(obj).some(val => {
                // If the value is a string, check if it includes the searchString
                if (typeof val === 'string') {
                    return val.toLowerCase().includes(lowerSearchString);
                }
                // If the value is a number or boolean, convert it to string and then check
                if (typeof val === 'number' || typeof val === 'boolean') {
                    return val.toString().toLowerCase().includes(lowerSearchString);
                }
                // If the value is an object (nested), recursively search its properties
                if (typeof val === 'object' && val !== null) {
                    return searchArray([val], searchString).length > 0;
                }
                return false;
            });
        });
    }
    const uniqueArray = (array) => {
        const uniqueArray = array.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.value === obj.value
            ))
        );
        return uniqueArray;
    }

    const getTagColorCode = (tagId) => {
        const item = taggingOptions.find(item =>
            item.tags.some(tag => tag.tagId === tagId)
        );

        // If an item is found, return its classification color
        return item ? item.classification.color : null; 

    }

    return (
        <AdvisorContext.Provider value={{ schoolInstList, ipedList, refreshStudentInfo, selectedAdvisor, setSelectedAdvisor, milestoneTags, studentList, institutionList, fetchStudentData, setDatesList, datesList, onCustomDateRangeSelect, onCustomDateRangeRemove, setSelectedDates, selectedDates, onDateRangeRemove, onDateRangeSelect, loaded, getMailList, studentMailList, getTagColorCode, uniqueArray, refreshCases, searchArray, onInProgramRemove, onInProgramSelect, onContactedRemove, onContactedSelect, onGradeRemove, onGradeSelect, onSchoolRemove, onSchoolSelect, onSubTagRemove, onSubTagSelect, onTagRemove, onTagSelect, myInfo, myBasics, myCases, myCasesLoaded, updateState, cityList, schoolList, gradeList, tfList, taggingOptions, statusMessage, filteredCases, upDateFilteredCases, tagList, subTagList, inProgramList, contactedList, recipients, setRecipients, selectedTags, setSelectedTags, selectedSubTags, setSelectedSubTags, selectedSchools, setSelectedSchools, selectedGrades, setSelectedGrades, selectedContacted, setSelectedContacted, selectedInProgram, setSelectedInProgram, applyFilters, setLoaded, setMyCases }}>
            {children}
        </AdvisorContext.Provider>
    );
};

export default AdvisorProvider;