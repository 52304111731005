import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import * as propTypes from "../propTypes";
import StudentSearch from "./components/StudentSearch";
import TotalCounts from "./components/TotalCounts";
import Student from "./components/Student";
import StudentEdit from "./components/StudentEdit";
import TermPayout from "./components/TermPayout";

import * as apis from "../apis";
import { getPayoutCounts } from "../../api/scholarshipCertification";

class Certify extends Component {
    static permission = "Scholarship.Certify-Students";
    state = {
        student: null,
        studentDetail: null,
        scholarship: null,
        showStudentEdit: false,
        amounts: null,
        counts: null,
        maxAllot: false
    };

    componentDidMount() {
        this.loadStudent();
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.scholarship !== this.state.scholarship) {
            this.loadStudent();
        }
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadStudent();
        }
    }

    async loadStudent() {
        if (!this.state.scholarship || !this.props.match.params || !this.props.match.params.id) {
            this.setState({ student: null });
            return;
        }

        let student = (await apis.getStudent(this.state.scholarship.id, this.props.match.params.id)).data.result;
        student = this.doFormatStudentData(student);
        this.setState({ student, maxAllot: student.maxAllotmentReached }, async () => await this.doLoadHeaderCounts());
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.scholarship !== prevState.scholarship) {
            return {
                scholarship: nextProps.scholarship,
                student: null,
            };
        }
        return {
            scholarship: nextProps.scholarship,
        };
    }
    onStudentSelected = (student) => {

        
        this.props.history.push(`/scholarship/certify-students/${student.id}`);
    };

    onStudentCertSaved = (data) => {
        
        this.setState({ maxAllot: data.maxAllotmentReached})
    }
    handleStudentClear = () => {
        this.props.history.push(`/scholarship/certify-students`);
    };

    onOpenStudentEdit = async () => {
        try {
            this.props.ajaxStatusLoading();
            const results = await apis.getStudent(this.state.scholarship.id, this.state.student.id);
            const studentDetail = results.data.result;
            this.setState({
                showStudentEdit: true,
                studentDetail,
                maxAllot: studentDetail.maxAllotmentReached
            });

            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving student data");
        }
    };
    onCloseStudentEdit = () => {
        this.setState({
            showStudentEdit: false,
        });
    };
    onSaveStudentEdit = (values) => {
        this.setState(
            {
                showStudentEdit: false,
                student: null,
            },
            async () => {
                try {
                    this.props.ajaxStatusLoading();
                    await apis.updateStudent(values);
                    const student = { ...values };
                    this.setState({
                        student: this.doFormatStudentData(student),
                        maxAllot: student.maxAllotmentReached
                    });

                    this.doLoadHeaderCounts();
                    toast.success("Save Complete");

                    this.props.ajaxStatusComplete();
                } catch (err) {
                    this.props.ajaxStatusError("Error saving student data");
                }
            }
        );
    };
    onTermAddRemoved = (isAdd, period) => {
        if (isAdd) {
            this.doLoadHeaderCounts(period);
            return;
        }
        this.doLoadHeaderCounts();
    };
    doFormatStudentData = (values) => {
        const student = { ...this.state.student, ...values };

        student.gender = parseInt(student.gender, 10);
        student.gender = student.gender === 1 ? "Female" : student.gender === 2 ? "Other" : "Male";
        student.level = parseInt(student.level, 10);
        student.level = student.level === 1 ? "Gold" : student.level === 2 ? "Silver" : student.level === 3 ? "Bronze" : student.level === 4? "Pewter" : "One Time Grant";
        student.deceased = student.deceased === true ? "(Deceased)" : student.deceased;
        return student;
    };

    doLoadHeaderCounts = async (period) => {
        try {
            this.props.ajaxStatusLoading();
            const { student, scholarship } = this.state;

            const results = await getPayoutCounts(scholarship.id, student.id);

            const data = results.data.result;
            if (data) {
                this.doFormatHeaderCountData(data);
            }
            this.props.ajaxStatusComplete();
        } catch (err) {
            //console.log(err);
            this.props.ajaxStatusError("Error getting counts");
        }
    };

    doFormatHeaderCountData = (data) => {
        this.setState({
            amounts: data,
        });
    };

    render() {
        const { student, scholarship, amounts, showStudentEdit, studentDetail } = this.state;
        const scholarshipId = scholarship ? scholarship.id : "";

        return (
            <div className="scholarship-certify">
                <StudentSearch onStudentSelected={this.onStudentSelected} scholarship={scholarship} onStudentClear={this.handleStudentClear} />
                {student ? (
                    <Fragment>
                        {amounts ? <TotalCounts data={amounts} /> : null}
                        <Student student={student} onClick={this.onOpenStudentEdit} maxAllot={this.state.maxAllot } />
                        <TermPayout
                            onLoadCounts={this.doLoadHeaderCounts.bind(this)}
                            student={student}
                            amounts={amounts}
                            scholarship={scholarship}
                            onTermAddRemoved={this.onTermAddRemoved}
                            maxAllot={this.state.maxAllot}
                            afterSave={this.onStudentCertSaved }
                        />
                    </Fragment>
                ) : null}

                <ReactModal isOpen={showStudentEdit}>
                    <StudentEdit onClose={this.onCloseStudentEdit} onSave={this.onSaveStudentEdit} student={studentDetail} scholarshipId={scholarshipId} />
                </ReactModal>
            </div>
        );
    }
}

Certify.propTypes = {
    scholarship: propTypes.scholarship,
};

Certify = withAjaxStatus(Certify);
Certify = withRouter(Certify);

export default Certify;
