import React, { useEffect, Fragment, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ApplicationStatusContext } from "../../support/context";

import normalizePhone from "../../support/logic/normalizePhone";
import { required, renderField, email, phone, renderRadioField, renderSelectList, requiredDateParts, DropdownDatePicker } from "../../support/forms";
import DocumentSection from "../documents/documentSection";

import "./student.css";
import { getRaceValue } from "./getRaceValue";
import { GroupType, Gender, CitizenshipType, Race } from "../logic/enums";
import { Button } from "../../support/components/Button";
import StudentSelector from "./studentSelector";
import ContactInfo from "../common/contactInfo";
import enumHelper from "../../support/logic/enumHelper";
import { getListByGrouping } from "../../api/documents";
import { Grades } from "../../support/enums";

export const renderSingleRadioField = ({ disabled, input, label, meta: { touched, error } }) => {
    return (
        <ApplicationStatusContext.Consumer>
            {(context) => (
                <Fragment>
                    <input type="radio" disabled={disabled} {...input} />
                    {label}
                    {(touched || (context.isApplicationSubmitted && !context.isApplicationValid)) && error && <span className="form-input-error">{error}</span>}
                </Fragment>
            )}
        </ApplicationStatusContext.Consumer>
    );
};

const gradeFilter = (recruitmentCycle, isStaff) => (grade) => {
    if (recruitmentCycle === null || isStaff) {
        return true;
    }

    if (grade.value === 5 && recruitmentCycle.grade5.isEligible) {
        return true;
    }

    if (grade.value === 6 && recruitmentCycle.grade6.isEligible) {
        return true;
    }

    if (grade.value === 7 && recruitmentCycle.grade7.isEligible) {
        return true;
    }

    if (grade.value === 8 && recruitmentCycle.grade8.isEligible) {
        return true;
    }

    if (grade.value === "") {
        return true;
    }

    return false;
};

const validate = (values) => {
    const errors = {};
    const raceValue = getRaceValue(values);
    if (raceValue === 0 || (raceValue & (raceValue - 1)) === 0) {
        // At least 2 must be selected
        errors.otherRace = <FormattedMessage id="ThisFieldIsRequired" />;
    }

    return errors;
};

const OtherRace = ({ meta: { touched, error } }) => {
    if (!touched || !error) {
        return null;
    }

    return (
        <div>
            <span className="form-input-error">{error}</span>
        </div>
    );
};

let Student = ({
    intl,
    recruitmentCycle,
    error,
    admissions,
    studentAppId,
    selectedGender,
    race,
    schools,
    handleSubmit,
    handleSave,
    handleCancel,
    handleAddStudent,
    handleDeleteStudent,
    handleChangeStudent,
    showConfirmRemoveStudent,
    handleConfirmRemoveStudent,
    handleCancelRemoveStudent,
    readonly,
    pristine,
    isStaff,
    topUser,
    checkForDuplicateApp
}) => {
    const male = intl.formatMessage({ id: "app.male", defaultMessage: "[Male]" });
    const female = intl.formatMessage({ id: "app.female", defaultMessage: "[Female]" });
    const other = intl.formatMessage({ id: "app.other", defaultMessage: "[Other]" });
    const yes = intl.formatMessage({ id: "Yes", defaultMessage: "[Yes]" });
    const no = intl.formatMessage({ id: "No", defaultMessage: "[No]" });
    const [isPersonalStatement, setIsPersonalStatement] = useState(null);
    const gradeValues = enumHelper.getSelectOptionsList(Grades, null, `-- ${intl.formatMessage({ id: "app.grade" }).toUpperCase()} --`);
    const isDesktop = useMediaQuery("(min-width: 600px)");

    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const response = await getListByGrouping(studentAppId, GroupType.PersonalStatement);
                setIsPersonalStatement(response.data.result.length !== 0);

            }
            catch (e) {
                //console.log(e);
            }
           
        })();
    }, [studentAppId]);

    return (
        <div style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            <div className="hr-line-medium" />
            <FormattedMessage id="nav.studentOnly" defaultMessage="[Student]" tagName="h2" />
            <Grid container spacing={2}>
                {admissions.userProfiles.length > 1 && (
                    <Grid item xs={12}>
                        <StudentSelector onChange={handleChangeStudent} />
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && (
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <div>
                                <FormattedMessage id="app.instruction" tagName="h5" />
                                <FormattedMessage id="student.help" tagName="div" />
                            </div>

                           

                            <ContactInfo />
                        </div>
                    )}
                    <FormattedMessage
                        id="student.firstName"
                        defaultMessage="[First Name]"
                        children={(formattedMessage) => (
                            <Field
                                name="firstName"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[required]}
                                maxlength="50"
                                disabled={readonly}
                            />
                        )}
                    />
                    <FormattedMessage
                        id="student.middleName"
                        defaultMessage="[Middle Name]"
                        children={(formattedMessage) => (
                            <Field name="middleName" type="text" component={renderField} label={formattedMessage} disabled={readonly} />
                        )}
                    />
                    <FormattedMessage
                        id="student.lastName"
                        defaultMessage="[Last Name]"
                        children={(formattedMessage) => (
                            <Field name="lastName" type="text" component={renderField} label={formattedMessage} validate={[required]} disabled={readonly} />
                        )}
                    />
                    <FormattedMessage id="app.dob" defaultMessage="[Date of Birth]" children={(message) => <span className="form-label">{message}</span>} />
                    <Field name="dateOfBirth" component={DropdownDatePicker} validate={[requiredDateParts]} disabled={readonly} intl={intl} />
                    <FormattedMessage
                        id="app.gender"
                        defaultMessage="[Gender]"
                        children={(formattedMessage) => <span className="form-label">{formattedMessage}</span>}
                    />
                    <Field
                        id="student-gender"
                        name="gender"
                        className="student-gender"
                        component={renderRadioField}
                        items={[
                            { value: Gender.Male.toString(), text: male },
                            { value: Gender.Female.toString(), text: female },
                            { value: Gender.Other.toString(), text: other },
                        ]}
                        disabled={readonly}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormattedMessage
                        id="student.mobilePhone"
                        defaultMessage="[Mobile Phone (if exists)]"
                        children={(formattedMessage) => (
                            <Field
                                name="mobilePhone"
                                type="text"
                                component={renderField}
                                label={formattedMessage}
                                validate={[phone]}
                                normalize={normalizePhone}
                                disabled={readonly}
                            />
                        )}
                    />
                
                    <FormattedMessage
                        id="app.school"
                        defaultMessage="[School]"
                        children={(formattedMessage) => (
                            <Field
                                name="schoolId"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={schools}
                                validate={[required]}
                                disabled={readonly}
                            />
                        )}
                    />
                    <FormattedMessage
                        id="app.gradeLabel"
                        values={{
                            year: recruitmentCycle?.uiYear ?? new Date().getFullYear(),
                        }}
                        children={(formattedMessage) => (
                            <Field
                                name="grade"
                                component={renderSelectList}
                                label={formattedMessage}
                                data={gradeValues.filter(gradeFilter(recruitmentCycle, isStaff))}
                                validate={[required]}
                                disabled={readonly}
                            />
                        )}
                    />
                </Grid>
                <Hidden only="xs">
                    <Grid item sm={4} style={isDesktop ? { display: "flex", justifyContent: "flex-end" } : {}}>
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <div>
                                <FormattedMessage id="app.instruction" tagName="h5" />
                                <FormattedMessage id="student.help" tagName="div" />
                            </div>

                          

                            <ContactInfo />
                        </div>
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <div className="hr-line-medium" />

                    <FormattedMessage id="student.citizenship.header" defaultMessage="[Student's Citizenship / Permanent Residency Status]" tagName="h2" />

                    <FormattedMessage
                        id="student.citizenship.description"
                        defaultMessage="[The questions below are for informational purposes only, to help us better serve your child. The Onward We Learn accepts students regardless of citizenship/ immigration status.]"
                        tagName="p"
                    />

                    <DocumentSection
                        groupId={studentAppId}
                        groupType={GroupType.BirthCertificate}
                        isStaff={isStaff}
                        description={() => (
                            <div>
                                <div>
                                    <span className="citizenship-label">
                                        <FormattedMessage id="student.citizenship" defaultMessage="[Student Citizenship / Immigration Status]">
                                            {(text) => text}
                                        </FormattedMessage>
                                    </span>
                                </div>
                                <div>
                                    <FormattedMessage id="student.citizenship.text" />
                                </div>
                                <div>
                                    <Field
                                        name="citizenshipType"
                                        component={renderSelectList}
                                        data={enumHelper
                                            .getSelectOptionsList(CitizenshipType, true, `-- ${intl.formatMessage({ id: "student.citizenship.select" })} --`)
                                            .filter((i) => i.value !== CitizenshipType.NoAnswer)
                                            .map((i, index) => {
                                                return { value: i.value, label: index !== 0 ? intl.formatMessage({ id: i.label }) : i.label };
                                            })}
                                        validate={[required]}
                                        disabled={readonly}
                                    />
                                </div>
                            </div>
                        )}
                        readonly={readonly}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormattedMessage
                        id="student.citizenship.hispanic"
                        defaultMessage="[Does this student identify as Hispanic or Latino]"
                        children={(formattedMessage) => <span className="parent-form-span">{formattedMessage}</span>}
                    />
                    <Field
                        id="studentHispanic"
                        name="hispanic"
                        className="hispanic"
                        component={renderRadioField}
                        items={[
                            { value: "1", text: yes },
                            { value: "0", text: no },
                        ]}
                        disabled={readonly}
                    />
                    <span className="form-label">
                        <FormattedMessage id="student.race">{(text) => text}</FormattedMessage>:
                    </span>
                    <Field
                        name="race"
                        component={renderSelectList}
                        data={enumHelper.getSelectOptionsList(Race, true, `-- ${intl.formatMessage({ id: "student.race.select" })} --`).map((i, index) => {
                            return { value: i.value, label: index !== 0 ? intl.formatMessage({ id: i.label }) : i.label };
                        })}
                        validate={[required]}
                        disabled={readonly}
                    />
                    {race?.toString() === Race.TwoOrMoreRaces.toString() && (
                        <Box display="flex" flexDirection="column" className="two-more-races">
                            <span className="form-label">
                                <FormattedMessage id="student.allApplicable">{(text) => text}</FormattedMessage>:
                            </span>

                            <label>
                                <Field name="raceWhite" component="input" type="checkbox" disabled={readonly} />
                                <FormattedMessage id="student.race.white" defaultMessage="[White]">
                                    {(text) => text}
                                </FormattedMessage>
                            </label>

                            <label>
                                <Field name="raceBlack" component="input" type="checkbox" disabled={readonly} />
                                <FormattedMessage id="student.race.black" defaultMessage="[Black or African American]">
                                    {(text) => text}
                                </FormattedMessage>
                            </label>

                            <label>
                                <Field name="raceAsian" component="input" type="checkbox" disabled={readonly} />
                                <FormattedMessage id="student.race.asian" defaultMessage="[Asian]">
                                    {(text) => text}
                                </FormattedMessage>
                            </label>

                            <label>
                                <Field name="raceIndian" component="input" type="checkbox" disabled={readonly} />
                                <FormattedMessage id="student.race.indian" defaultMessage="[American Indian or Alaska Native]">
                                    {(text) => text}
                                </FormattedMessage>
                            </label>

                            <label>
                                <Field name="raceHawaiian" component="input" type="checkbox" disabled={readonly} />
                                <FormattedMessage id="student.race.hawaiian" defaultMessage="[Native Hawaiian or Other Pacific Islander]">
                                    {(text) => text}
                                </FormattedMessage>
                            </label>

                            <Field name="otherRace" component={OtherRace} />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {isPersonalStatement === null ? null : isPersonalStatement === false ? (
                        <>
                            <div style={{ marginTop: 10 }} />

                            <DocumentSection
                                groupId={studentAppId}
                                groupType={GroupType.ReportCard}
                                isStaff={isStaff}
                                description={() => (
                                    <div>
                                        <FormattedMessage id="student.personal.reportcard" />
                                    </div>
                                )}
                                readonly={readonly}
                            />
                        </>
                    ) : (
                        <>
                            <div className="hr-line-medium" />

                            <FormattedMessage
                                id="student.personal"
                                defaultMessage="[Personal Statement | Write a paragraph (three to four sentences) that answers the following questions:]"
                                tagName="h2"
                            />

                            <FormattedMessage
                                id="student.personal.crusader"
                                defaultMessage="[Why do you want to be a Onward We Learn student? Why is doing well in school important to you?]"
                                tagName="h3"
                            />

                            <DocumentSection
                                groupId={studentAppId}
                                groupType={GroupType.PersonalStatement}
                                isStaff={isStaff}
                                description={() => (
                                    <div>
                                        <FormattedMessage id="student.personal.personalstatement" />
                                    </div>
                                )}
                                readonly={readonly}
                            />

                            <DocumentSection
                                groupId={studentAppId}
                                groupType={GroupType.ReportCard}
                                isStaff={isStaff}
                                description={() => (
                                    <div>
                                        <FormattedMessage id="student.personal.reportcard" />
                                    </div>
                                )}
                                readonly={readonly}
                            />
                        </>
                    )}
                    <div className="hr-line-medium" />

                    <div className="button-container student">
                        <div className="left-buttons">
                            <Button id="Save" defaultMessage="[Save]" onClick={handleSubmit(handleSave)} invalid={readonly} />
                            <Button className="link-button" id="Cancel" defaultMessage="[Cancel]" onClick={handleCancel} invalid={pristine} />
                        </div>
                        
                    </div>
                    <SweetAlert
                        show={showConfirmRemoveStudent}
                        title={intl.formatMessage({ id: "student.remove.title", defaultMessage: "[student.remove.title]" })}
                        showCancel
                        onConfirm={handleConfirmRemoveStudent}
                        onCancel={handleCancelRemoveStudent}
                        confirmBtnText={intl.formatMessage({ id: "app.ok" })}
                        cancelBtnText={intl.formatMessage({ id: "app.cancel" })}
                    >
                        {intl.formatMessage({ id: "student.remove.text", defaultMessage: "[student.remove.text]" })}
                    </SweetAlert>
                </Grid>
            </Grid>
        </div>
    );
};

Student = reduxForm({ form: "AdmissionsStudent", validate })(Student);

export default injectIntl(Student);
