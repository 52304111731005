import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    header: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title saveButton" "subtitle saveButton"`,
        marginBottom: theme.spacing(1),
    },
    title: {
        gridArea: "title",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    subtitle: {
        fontWeight: "bold",
        gridArea: "subtitle",
    },
}));
