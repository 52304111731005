export const EventDepartmentType = Object.freeze({
    FamilyEngagement: 10,
    HighSchool: 20,
    MiddleSchool: 30,
    PostSecondary: 40,
    _displayNames: {
        10: "Family Engagement",
        20: "High School",
        30: "Middle School",
        40: "Post-Secondary",
    },
});

export const EventLocationType = Object.freeze({
    Both: 10,
    InPerson: 20,
    Virtual: 30,
    _displayNames: {
        20: "In Person",
    },
});

export const DateAndTimeType = Object.freeze({
    Single: 0,
    MultipleEvents: 1,
    DateRange: 2,
    _displayNames: {
        0: "Single Event",
        1: "Multiple Events",
        2: "Date Range",
    },
});

export const DayOfWeek = Object.freeze({
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
});

export const EventStatus = Object.freeze({
    Planning: 0,
    Recruitment: 1,
    OpenEnrollment: 2,
    NeedsApproval: 3,
    Closed: 4,
    Cancelled: 5,
    Review: 6,
    Complete: 7,
    _displayNames: {
        0: "Plan",
        1: "Recruit",
        2: "Open Enrollment",
        4: "Approve",
        6: "Review",
    },
});

export const AmPm = Object.freeze({
    Am: 1,
    Pm: 2,
    _displayNames: {
        1: "AM",
        2: "PM",
    },
});

export const Language = Object.freeze({
    English: 1,
    Spanish: 2,
    _displayNames: {
        1: "English",
        2: "Spanish",
    },
});

export const GradeCalculationType = Object.freeze({
    Average: 10,
    Latest: 20,
    _displayNames: {
        10: "Average",
        20: "Latest",
    },
});

export const ProgramEventDateRegistrationStatus = Object.freeze({
    Closed: 10,
    Full: 20,
    FullWaitlist: 30,
    FullWaitlistFull: 40,
    Open: 50,
    _displayNames: {
        20: "Enrollment Full",
        30: "Enrollment Full and Waitlist Available",
        40: "Enrollment List and Waitlist Full",
    },
});

export const ProgramEventDateWaitlistResponseDuration = Object.freeze({
    Days: 1,
    Hours: 2,
    _displayNames: {
        1: "Days",
        2: "Hours",
    },
});
