import React from "react";
import PropTypes from "prop-types";

import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";

import "./CashOptions.css";

const GOLD = 1;
const SILVER = 2;
const BRONZE = 3;
const PEWTER = 4;

const CashOptions = ({ amounts, onAmountChange, isReadonly }) => {
    if (!amounts || amounts.length === 0) return null;
    return (
        <div className="admin-scholarship-cash">
            <div className="admin-scholarship-cash-labels">
                <span>Full Time</span>
                <span>3/4 Time (75%)</span>
                <span>Half Time (50%)</span>
                <span>Less Than 1/2 Time (25%)</span>
            </div>
            <div className="admin-scholarship-cash-amounts">
                <span>Gold</span>
                <input
                    type="text"
                    defaultValue={formatCurrencyNoDecimal(amounts[0].fullAmount)}
                    onBlur={onAmountChange.bind(null, GOLD)}
                    disabled={isReadonly}
                />
                <span>{formatCurrencyNoDecimal(amounts[0].threeQuarterAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[0].halfAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[0].lessThanHalf)}</span>
            </div>
            <div className="admin-scholarship-cash-amounts">
                <span>Silver</span>
                <input
                    type="text"
                    defaultValue={formatCurrencyNoDecimal(amounts[1].fullAmount)}
                    onBlur={onAmountChange.bind(null, SILVER)}
                    disabled={isReadonly}
                />
                <span>{formatCurrencyNoDecimal(amounts[1].threeQuarterAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[1].halfAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[1].lessThanHalf)}</span>
            </div>
            <div className="admin-scholarship-cash-amounts">
                <span>Bronze</span>
                <input
                    type="text"
                    defaultValue={formatCurrencyNoDecimal(amounts[2].fullAmount)}
                    onBlur={onAmountChange.bind(null, BRONZE)}
                    disabled={isReadonly}
                />
                <span>{formatCurrencyNoDecimal(amounts[2].threeQuarterAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[2].halfAmount)}</span>
                <span>{formatCurrencyNoDecimal(amounts[2].lessThanHalf)}</span>
            </div>
            {amounts.length > 3 && (
                <div className="admin-scholarship-cash-amounts">
                    <span>Pewter</span>
                    <input
                        type="text"
                        defaultValue={formatCurrencyNoDecimal(amounts[3].fullAmount)}
                        onBlur={onAmountChange.bind(null, PEWTER)}
                        disabled={isReadonly}
                    />
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            )}
        </div>
    );
};

CashOptions.propTypes = {
    amounts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            scholarshipId: PropTypes.string.isRequired,
            level: PropTypes.number.isRequired,
            type: PropTypes.number.isRequired,
            fullAmount: PropTypes.number.isRequired,
            threeQuarterAmount: PropTypes.number.isRequired,
            halfAmount: PropTypes.number.isRequired,
            lessThanHalf: PropTypes.number.isRequired,
        })
    ),
    onAmountChange: PropTypes.func.isRequired,
    isReadonly: PropTypes.bool.isRequired,
};
export default CashOptions;
