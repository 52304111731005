import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { formatCurrencyNoDecimal } from '../../../../support/numberSupport';

import './DonatedList.css';

const DonatedList = ({ data, getTdProps }) => {
    return (
        <div className="donated-list">
            <ReactTable
                data={data}
                minRows={0}
                className="financials-table -highlight"
                getTdProps={getTdProps}
                columns={[
                    {
                        Header: 'Institution',
                        accessor: 'institutionName',
                        minWidth: 200
                    },
                    {
                        Header: 'Promised',
                        accessor: 'promised',
                        Cell: row => formatCurrencyNoDecimal(row.value)
                    },
                    {
                        Header: 'Donated',
                        accessor: 'donated',
                        Cell: row => formatCurrencyNoDecimal(row.value)
                    }
                ]}
                showPagination
            />
        </div>
    );
};

DonatedList.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            donated: PropTypes.number,
            promised: PropTypes.number,
            institutionName: PropTypes.string
        })
    )
};

export default DonatedList;
