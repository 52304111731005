import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_USERS_ROLEID_KEY, EVENTS_STEP_KEY } from "../../constants";

const RouteListener = () => {
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            if (!location.pathname.toLowerCase().includes("/event/")) {
                localStorage.removeItem(EVENTS_STEP_KEY);
            }
            if (!location.pathname.toLowerCase().includes("/admin/users")) {
                localStorage.removeItem(ADMIN_USERS_ROLEID_KEY);
            }
        });
    }, [history]);

    return null;
};

export default RouteListener;
