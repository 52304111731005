import React from "react";
import { useHistory } from "react-router";

const withHistory = (Component) => {
    return (props) => {
        const history = useHistory();

        return <Component history={history} {...props} />;
    };
};

export default withHistory;
