import React, { Component } from "react";
import * as propTypes from "../../propTypes";
import { reduxForm } from "redux-form";

import Payment from "./components/payment";
import Payments from "./components/payments";
import StudentList from "./components/studentList";

import { getPaymentRequests, getStudentPaymentsByTransactionRequest } from "../../../api/sendPayments";

import * as TransactionApi from "../../../api/scholarshipTransactions";

import withAjaxStatus from "../../../support/hoc/withAjaxStatus";

import "./index.css";
import moment from "moment";

class SendPayment extends Component {
  state = {
    selectedPaymentRequestId: null,
    payments: [],
    studentList: []
  };

  componentDidMount() {
    this.loadPayments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scholarship !== prevProps.scholarship) {
      this.setState({ selectedPaymentRequestId: null, studentList: [] }, () => {
        this.loadPayments();
      });
    }
  }

  async loadPayments() {
    if (!this.props.scholarship) {
      return;
    }

    try {
      this.props.ajaxStatusLoading();
      const payments = (await getPaymentRequests(this.props.scholarship.id)).data.result;
      this.setState({ payments });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  }

  async loadStudents() {
    try {
      this.props.ajaxStatusLoading();
      const studentList = (await getStudentPaymentsByTransactionRequest(this.state.selectedPaymentRequestId)).data.result;
      this.setState({ studentList });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  }

  onSavePayment = async values => {
    try {
      this.props.ajaxStatusLoading();
      await TransactionApi.sendPayment(this.state.selectedPaymentRequestId, values.date, values.checkNum, values.notes);
      this.loadPayments();
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  };

  handlePaymentsClick = item => {
    this.setState({ selectedPaymentRequestId: item.id }, () => {
      const paymentRequest = this.getSelectedPaymentRequest();
      if (paymentRequest.checkNum) {
        this.props.initialize({
          date: moment.utc(paymentRequest.paidOn).format("MM/DD/YYYY"),
          checkNum: paymentRequest.checkNum,
          notes: paymentRequest.notes
        });
      } else {
        this.props.initialize({});
      }

      this.loadStudents();
    });
  };

  getPaymentsTdProps = (state, rowInfo, column, instance) => {
    if (!rowInfo) {
      return {};
    }

    return {
      onClick: e => this.handlePaymentsClick(rowInfo.original),
      style: {
        backgroundColor: rowInfo.original.id === this.state.selectedPaymentRequestId ? "#f0f2cc" : undefined
      }
    };
  };

  getSelectedPaymentRequest() {
    if (this.state.selectedPaymentRequestId === null) {
      return {};
    }

    return this.state.payments.find(item => item.id === this.state.selectedPaymentRequestId);
  }

  render() {
    const { selectedPaymentRequestId, payments, studentList } = this.state;
    const selectedPaymentRequest = this.getSelectedPaymentRequest();

    return (
      <div className="scholarship-send-payment">
        <div className="scholarship-send-payment-top">
                <Payments data={payments} getTdProps={this.getPaymentsTdProps} readonly={this.props.readonly} />
          {selectedPaymentRequestId === null ? (
            <div className="noInstitutionSelected">Select an Institution</div>
          ) : (
                        <Payment onSave={this.onSavePayment} paymentRequest={selectedPaymentRequest} readonly={this.props.readonly} />
          )}
        </div>
        <div className="scholarship-send-payment-bottom">
          <h2 className="selected-institution">{selectedPaymentRequest.institution}</h2>
          <StudentList data={studentList} />
        </div>
      </div>
    );
  }
}

SendPayment.propTypes = {
  scholarship: propTypes.scholarship
};

SendPayment = reduxForm({ form: "ScholarshipSendPayment" })(SendPayment);

SendPayment = withAjaxStatus(SendPayment);

export default SendPayment;
