import React, { Component } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";

import withAjaxStatus from "../support/hoc/withAjaxStatus";

import * as apis from "./apis";

import "./YearPicker.css";
import OutsideAlerter from "../support/components/outsideAlerter";

class YearPicker extends Component {
  state = {
    scholarships: [],
    open: false,
    value: null
  };
  componentDidMount = async () => {
    try {
      this.props.ajaxStatusLoading();
      const scholarships = (await apis.getAll()).data.result;
      this.props.ajaxStatusComplete();
      this.setState({
        scholarships
      });
      if (scholarships && scholarships.length > 0) {
        this.props.onSelectChange(scholarships[0]);
        this.setState({ value: scholarships[0] });
      }
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving scholarship list");
    }
  };
  onSelect = e => {
    var id = e.target.value;
    var scholarship = this.state.scholarships.find(item => {
      return item.id === id;
    });

    this.props.onSelectChange(scholarship);
  };

  handleClick = e => {
    if (!this.state.scholarships || this.state.scholarships.length < 2) {
      return;
    }
    this.setState({ open: !this.state.open });
  };

  handleSelect = scholarship => {
    this.setState({ open: false, value: scholarship });
    this.props.onSelectChange(scholarship);
  };

  handleClear = () => this.setState({ open: false });

  render() {
    const { scholarships, value } = this.state;
    const displayValue = value
      ? `${value.startYear} - ${value.endYear}`
      : "Unknown";

    return (
      <OutsideAlerter onOutsideClick={this.handleClear}>
        <div
          role="button"
          tabIndex={0}
          onClick={this.handleClick}
          onKeyDown={this.handleClick}
          className={`scholarship-year-picker ${
            scholarships.length > 1 ? "scholarship-year-picker--can-open" : ""
          } ${this.state.open ? "scholarship-year-picker--open" : ""}`}
        >
          <span>{displayValue}</span>
          {scholarships.length > 1 && (
            <FontAwesome
              name="chevron-down"
              className="scholarship-year-picker__down"
            />
          )}
          <div className="scholarship-year-picker__list">
            <ul>
              {this.state.scholarships.map(scholarship => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  onKeyDown={() => this.handleSelectscholarship}
                  onClick={() => this.handleSelect(scholarship)}
                  key={scholarship.id}
                >{`${scholarship.startYear} - ${scholarship.endYear}`}</li>
              ))}
            </ul>
          </div>
        </div>
      </OutsideAlerter>
    );
  }
}

YearPicker = withAjaxStatus(YearPicker);

YearPicker.propTypes = {
  onSelectChange: PropTypes.func.isRequired
};

export default YearPicker;
