import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useParams } from "react-router";
import { Prompt, useHistory } from "react-router-dom";
import SimpleDialog from "../../common/simpleDialog";
import ServiceHoursForm from "./components/serviceHoursForm/serviceHoursForm";
import ProgramCodeDialog from "./components/programCodeDialog/programCodeDialog";
import EventHeader from "../common/eventHeader/eventHeader";
import { exportAttendance, getAttendanceUsers } from "../api/programEvents";
import useProgramEvent from "../hooks/useProgramEvent";
import { returnEventTags } from "../../api/tagging";
import useAjaxStatus from "../../common/useAjaxStatus";
import { UserType } from "../../support/enums";
import enumHelper from "../../support/logic/enumHelper";
import LoadingButton from "../../common/loadingButton";
import b64toBlob from "../../support/logic/b64toBlob";
import FileSaver from "file-saver";
import { EventDepartmentType } from "../enums";
import { useStyles } from "./styles";
import Link from "@material-ui/core/Link";
import axios from "axios";
import FontAwesome from "react-fontawesome";
import { Tabs, Tab } from '@mui/material';
import Reporting from "../../common/reporting";

import { Grid, TextField,  Typography, IconButton } from '@material-ui/core';
import Modal from "react-modal";


const preSelectedReportFields = ["userProfile.firstName", "userProfile.lastName", "userProfile.mobilePhone", "userProfile.email", "userProfile.dateOfBirth","studentProfile.grade","studentProfile.crusaderId"];
const skippedFields = ["user", "userProfile.grade"];


function advisorSearch(input) {

    return axios.post("/api/services/app/Advisors/AdvisorSearch", input);
};

function addAttendance(input) {
    return axios.post("/api/services/app/ProgramEventAttendance/AddAttendance", input);
}

const _columns = [
    {
        field: "firstName",
        headerName: "First name",
        flex: 1,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 1,
    },
    {
        field: "userType",
        headerName: "User Type",
        flex: 1,
        renderCell: ({ row: { userType } }) => enumHelper.getDisplayName(UserType, userType),
    },
    {
        field: "grade",
        headerName: "Grade",
        flex: 1,
    },
    {
        field: "crusaderId",
        headerName: "Crusader ID",
        flex: 1,
    },
    {
        field: "serviceHours",
        headerName: "Credit Hours",
        flex: 1,
    },
    {
        field: "userProfileId",
        headerName: "Profile",
        width: 150,
        renderCell: ({ id, row: { userType } }) => {
            return (
                <Button
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={() => {
                        window.open(userType === UserType.Crusader ? `/admin/users/crusader/${id}` : `/admin/users/parent/${id}`, "_blank");
                    }}
                >
                    Profile
                </Button>
            );
        },
    },
];

const AttendanceList = ({ isActive, isComplete, match, topUser }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();
    const {
        state: { rowData, programEventId },
    } = useLocation();
    const {
        programName,
        date,
        enrollmentCapacity,
        creditHours: eventCreditHours,
        time,
        department: { department },
        id: programEventDateId,
    } = rowData;
    const { id } = useParams();
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedUserId, setSelectedUserId] = useState();
    const [isServiceHoursDialogOpen, setIsServiceHoursDialogOpen] = useState(false);
    const [isProgramCodeDialogOpen, setIsProgramCodeDialogOpen] = useState(false);
    const [userCreditHours, setUserCreditHours] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [tagWeights, setTagWeights] = useState([]);
    const [attendanceId, setAttendanceId] = useState();
    const [exporting, setExporting] = useState(false);
    const [reloadAttendance, setReloadAttendance] = useState(false);
    const reviewedCount = 0;
    const [fullData, setFullData] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [searchTab, setSearchTab] = useState(0);
    const [searchStudents, setSearchStudents] = useState([]);
    const [searchPriParents, setSearchPriParents] = useState([]);
    const [searchSecParents, setSearchSecParents] = useState([]);
    const [studVis, setStudVis] = useState("tabHidden");
    const [parVis, setParVis] = useState("tabHidden");
    const [searchVal, setSearchVal] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const { programEvent, loading: loadingProgramEvent, setProgramEvent } = useProgramEvent(programEventId);
    //const reviewedCount = rows.filter((item) => !item.reviewed).length;
    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);
    const openServiceHoursDialog = ({ firstName, lastName, attendanceId, serviceHours: _serviceHours, totalAvailableCreditHours, id}) => {
        setSelectedUser(`${firstName} ${lastName} `);
        //console.log(match);
        setSelectedUserId(id);
        if (userCreditHours.length === 0) {
            let uch = [];
            tagWeights.forEach((cla) => {

                cla.tags.forEach((tag) => {
                    uch.push({ id: tag.id, creditHours: totalAvailableCreditHours * tag.weight });
                })
            })
            setUserCreditHours(uch);
        }
       // setTotalHours(totalAvailableCreditHours);
        setIsServiceHoursDialogOpen(true);
    };
    const closeServiceHoursDialog = () => setIsServiceHoursDialogOpen(false);
    const openProgramCodeDialog = () => setIsProgramCodeDialogOpen(true);
    const closeProgramCodeDialog = () => setIsProgramCodeDialogOpen(false);

    const columns = _columns.map((col) => {
        if (col.field === "serviceHours") {
            return {
                ...col,
                renderCell: (params) => {
          //          //console.log(params);
                    return (
                        <Button
                            color={params.row.reviewed ? "primary" : "secondary"}
                            size="small"
                            style={{ marginLeft: 16 }}
                            disabled={readOnly }
                            onClick={() => {
                                const _userCreditHours = params.row.creditHours.map(({ creditHours: userCreditHours, id }) => ({
                                    userCreditHours,
                                    id,
                                }));
                                setUserCreditHours(_userCreditHours);
                        ;
                                setAttendanceId(params.row.attendanceId);
                                setTotalHours(params.row.totalAvailableCreditHours);
                                openServiceHoursDialog(params.row);
                            }}
                        >
                            {params.row.totalAvailableCreditHours}
                        </Button>
                    );
                },
            };
        }

        return col;
    });

    useEffect(() => {
        (async () => {
            try {

                setIsLoading(true);
                const input = { Id: programEventId };
                const activeTags = await returnEventTags(input);
             
                setTagWeights(activeTags.data.result);
                const resp = await getAttendanceUsers(id, department);
                //console.log(resp);
                let _rows = [];
                setFullData(resp.data.result);
                resp.data.result.forEach((row) => {
                   
                    let sh = [];
                    if (row.creditHours.length === 0) {
              
                        tagWeights.forEach((cl) => {
                            cl.tags.forEach((tag) => {
                                const st = {
                                    creditHours: row.totalAvailableCreditHours * tag.weight,
                                    grade: row.userProfile.grade,
                                    id: tag.id,
                                    
                                }
                                sh.push(st);
                            });
                        });
                    } else {
               
                        row.creditHours.forEach((ch) => {
                     
                            const st = {
                                creditHours: ch.creditHours,
                                grade: ch.grade,
                                id: ch.id,

                            }
                            sh.push(st);
                        })
                        ;
                    }
                    const item = {
                        id: row.userProfile.id,
                        attendanceId: row.id,
                        firstName: row.userProfile.firstName,
                       lastName: row.userProfile.lastName,
                        userType: row.userProfile.userType,
                        grade: row.userProfile.grade,
                        crusaderId: row.userProfile.crusaderId,
                        totalAvailableCreditHours: row.totalAvailableCreditHours,
                        serviceHours: sh,
                        profile: {
                            firstName: row.userProfile.firstName,
                            lastName: row.userProfile.lastName,
                            userType: row.userProfile.userType,
                            grade: row.userProfile.grade,
                            Email: row.userProfile.email,
                            MobilePhone: row.userProfile.mobilePhone
                        },

                        reviewd: (!rowData.approved && row.totalAvailableCreditHours === eventCreditHours && row.creditHours.length === 0) ||
                            row.creditHours.reduce((prev, current) => prev + current.creditHours, 0) === row.totalAvailableCreditHours,
                        creditHours: sh
                    }
                    _rows.push(item);
                    
                });
                
       /*         _rows = resp.data.result.map(
                    ({
                        userProfileId,
                        id: attendanceId,
                        crusaderId,
                        creditHours,
                        totalAvailableCreditHours,
                        userProfile: { userType, firstName, lastName, grade },
                    }) => ({
                        id: userProfileId,
                        attendanceId,
                        firstName,
                        lastName,
                        userType,
                        grade,
                        crusaderId,
                        totalAvailableCreditHours: totalAvailableCreditHours,
                        serviceHours: creditHours.map((item) => item.creditHours).reduce((prev, current) => prev + current, 0),
                        profile: userProfileId,
                        reviewed: 
                            (!rowData.approved && totalAvailableCreditHours === eventCreditHours && creditHours.length === 0) ||
                            creditHours.reduce((prev, current) => prev + current.creditHours, 0) === totalAvailableCreditHours,
                        creditHours,
                    })
                ); */
                setRows(_rows);
                //console.log(_rows);
            } catch (ex) {
                ajaxErrorWrapper(ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [ajaxErrorWrapper, department, eventCreditHours, id, reloadAttendance, rowData.approved]);

    const handleExport = async () => {
        try {
            setExporting(true);
            const resp = await exportAttendance(department, programEventDateId);
            const blob = b64toBlob(resp.data.result.data, resp.data.result.mimeType);
            FileSaver.saveAs(blob, resp.data.result.fileName);
            setExporting(false);
        } catch (err) {
            setExporting(false);
            ajaxErrorWrapper(err);
        }
    };


    const runSearch = async () => {
        const res = await advisorSearch({ text: searchVal });
        setSearchStudents(res.data.result.students);
        setSearchPriParents(res.data.result.parents);
        
    }

   const updateSearch = async (event) => {
        const searchValue = event.target.value.toLowerCase();

       setSearchVal(event.target.value.toLowerCase());
        


    }

    const closeSearch = () => {
        setShowSearch(false);
        setSearchTab(0);
        setSearchStudents([]);
        setSearchPriParents([]);
        setSearchSecParents([]);
        setStudVis("tabHidden");
        setParVis("tabHidden");
        setSearchVal("");
    };

   const setTab = (event, newVal) => {
        let studVis = "tabHidden";
        let parVis = "tabHidden";
       //console.log(newVal);
        switch (newVal) {
            case 0:
                studVis = "tabVisible";
                parVis = "tabHidden";

                break;
            case 1:
                studVis = "tabHidden";
                parVis = "tabVisible";

                break;

            default:
                studVis = "tabVisible";
                parVis = "tabHidden";
                break;


       }

       setParVis(parVis);
       setStudVis(studVis);
       setSearchTab(newVal);

        
    }

    const showAdd = () => {
        setShowSearch(true);
        setSearchTab(0);
        setStudVis("tabVisible");


    }
    const addAttendee = async (prof) => {
        //console.log(prof);
        const input = {
            programEventId: programEventId,
            userProfileId: prof.id,
            programEventDateId: programEventDateId
        }
        await addAttendance(input);
        setReloadAttendance(true);
        setShowSearch(false);
    }
    //console.log(readOnly);
    return (
        <>
            <EventHeader mainTitle={programName} subTitle={enumHelper.getDisplayName(EventDepartmentType, department)} date={date} />

            <div className={classes.subHeader}>
                <div className={classes.headerCounters}>
                    <span className={classes.participantsCounter}>
                        {rows.length}/{enrollmentCapacity}
                    </span>
                    {reviewedCount > 0 && (
                        <span className={classes.alert}>
                            <b>{reviewedCount}</b> | Participants Need Tags
                        </span>
                    )}
                </div>
                <div className={classes.buttonContainer}>
                    
                            
                        
                    <LoadingButton
                        className="programEventInviteExportButton"
                        variant="outlined"
                        color="primary"
                        onClick={showAdd}
                        disabled={readOnly }
                        >
                            Add 
                    </LoadingButton>
                        &nbsp;
                    <Reporting data={fullData}
                        preSelected={preSelectedReportFields}
                        label="Export" doNotExport={skippedFields} className="programEventInviteExportButton" />
                    
                </div>
            </div>
            <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                    getRowClassName={({ row }) => row.reviewed && classes.notReviewedUser}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableColumnMenu
                    disableColumnFilter
                    autoHeight
                    loading={isLoading}
                />
            </div>

            <SimpleDialog title={selectedUser} maxWidth="sm" open={isServiceHoursDialogOpen} onClose={closeServiceHoursDialog}>
                <ServiceHoursForm
                    closeServiceHoursDialog={closeServiceHoursDialog}
                    userCreditHours={userCreditHours}
                    assigmentId={rowData.department.id}
                    department={rowData.department.department}
                    attendanceId={attendanceId}
                    totalHours={totalHours}
                    setReloadAttendance={setReloadAttendance}
                    rowData={rowData}
                    selectedUserId={selectedUserId}
                    tagWeights={tagWeights}
                    id={match.params.id }

                />
            </SimpleDialog>

            <SimpleDialog open={isProgramCodeDialogOpen} onClose={closeProgramCodeDialog}>
                <ProgramCodeDialog
                    closeProgramCodeDialog={closeProgramCodeDialog}
                    assignmentId={rowData.department.id}
                    department={rowData.department.department}
                    eventCreditHours={eventCreditHours}
                    eventTime={time}
                    setReloadAttendance={setReloadAttendance}
                />
            </SimpleDialog>
            <Modal isOpen={showSearch} ariaHideApp={false} >
                <div style={{ display: 'flex', padding: '20px' }}> {/* Add padding to the top */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Search</Typography>
                        </Grid>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter first or last name to search"
                                    name="title"
                                    onBlur={updateSearch}
                                    onChange={updateSearch}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="search" onClick={() => runSearch()}>
                                    <FontAwesome name="search" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <div className="actionButtonWrapper">
                            <Button onClick={closeSearch} className="fieldSelectorButton">Cancel</Button>
                        </div>
                        <Grid item xs={12}>
                            <Tabs value={searchTab} onChange={setTab} aria-label="Search" variant="fullWidth"
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-evenly',
                                    },
                                    '.MuiTab-root': {
                                        border: '1px solid #000',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        marginRight: '4px',
                                        '&:last-child': {
                                            marginRight: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Tab label="Students" />
                                <Tab label="Guardians" />


                            </Tabs>
                        </Grid>
                        <Grid item xs={12} className={parVis}>
                            <ul>
                                {searchPriParents.map((prof) => (
                                    <li>
                                        <Link key={prof.id} onClick={() => addAttendee(prof)}>
                                            {prof.lastName}, {prof.firstName}
                                        </Link></li>
                                ))}
                            </ul>
                        </Grid>


                        <Grid item xs={12} className={studVis}>
                            <ul>
                                {searchStudents.map((prof) => (

                                    <li>
                                        <Link key={prof.id} onClick={() => addAttendee(prof)}>
                                            {prof.lastName}, {prof.firstName}
                                        </Link></li>
                                ))}

                            </ul>
                        </Grid>

                    </Grid>
                </div>


            </Modal>
        </>
    );
};

export default AttendanceList;
