import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    eventRecruitment: {
        padding: theme.spacing(1, 0),
    },
    navButton: {
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.warning.main,
        borderRadius: "50%",
    },
    iconComplete: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
    },
    recruitButton: {
        padding: theme.spacing(1, 8),
    },
}));

const EventOpenEnrollmentStep = ({ programEventId, programName }) => {
    const classes = useStyles();
    const history = useHistory();

    const goToEventDateEnrollmentList = () =>
        history.push({ pathname: `/events/active/event/${programEventId}/event-date-enrollment-list`, state: { programName } });

    const goToEventDateWaitlist = () => history.push({ pathname: `/events/active/event/${programEventId}/event-date-waitlist`, state: { programName } });

    return (
        <Box className={classes.eventRecruitment} display="flex" flexDirection="column" alignItems="flex-start">
            <Button onClick={goToEventDateEnrollmentList} className={classes.navButton} startIcon={<CheckRoundedIcon className={classes.iconComplete} />}>
                Enrollment List
            </Button>
            <Button onClick={goToEventDateWaitlist} className={classes.navButton} startIcon={<CheckRoundedIcon className={classes.iconComplete} />}>
                Waitlist
            </Button>
        </Box>
    );
};

export default EventOpenEnrollmentStep;
