import React from "react";
import Box from "@material-ui/core/Box";
import { Field } from "react-final-form";
import { FinalFormDropdown, FinalFormTextField, FinalFormRadioGroup } from "../../../common/formWrappers";
import { composeValidators, maxLength, required, zipCode } from "../../../common/formValidators";
import PriorityHighRounded from "@material-ui/icons/PriorityHighRounded";
import { Language } from "../../enums";
import { states } from "../../../support/states";
import { ZipCodeMask } from "../../../common/masks";
import { Button, ButtonGroup, Paper, Typography } from "@material-ui/core";
import enumHelper from "../../../support/logic/enumHelper";
import { useStyles } from "./venueStyles";

const Venue = ({ language }) => {
    const classes = useStyles();

    return (
        <Paper variant="outlined" classes={{ root: classes.outlinedSection }} name="venue">
            <Typography className={classes.sectionTitleWithIcon} variant="h5" color="secondary">
                Venue
                <PriorityHighRounded className={classes.icon} />
                <Field name="venue.language">
                    {({ input }) => {
                        return (
                            <ButtonGroup className={classes.languageToggle} aria-label="language">
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color={input.value === "" || input.value === Language.English ? "primary" : "default"}
                                    onClick={() => input.onChange(Language.English)}
                                >
                                    {enumHelper.getDisplayName(Language, Language.English)}
                                </Button>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    color={input.value === Language.Spanish ? "primary" : "default"}
                                    onClick={() => input.onChange(Language.Spanish)}
                                >
                                    {enumHelper.getDisplayName(Language, Language.Spanish)}
                                </Button>
                            </ButtonGroup>
                        );
                    }}
                </Field>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                This Information Will be Viewed by Participants
            </Typography>
            <Box width="80%" display="flex" flexDirection="column" alignItems="flex-start">
                {language === Language.English && (
                    <>
                        <Box width="50%">
                            <Field
                                component={FinalFormTextField}
                                name="venue.name"
                                label="Venue Name"
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                maxLength={500}
                                validate={composeValidators(required, maxLength(500))}
                            />
                        </Box>
                        <Field
                            component={FinalFormTextField}
                            name="venue.addressLine1"
                            label="Address Line 1"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required
                            maxLength={50}
                            validate={composeValidators(required, maxLength(50))}
                        />
                        <Field
                            component={FinalFormTextField}
                            name="venue.addressLine2"
                            label="Address Line 2"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            maxLength={50}
                            validate={maxLength(50)}
                        />
                        <Box width="50%" display="flex" justifyContent="space-between">
                            <Box display="inline-block" width="60%" mr={1}>
                                <Field
                                    component={FinalFormTextField}
                                    name="venue.city"
                                    label="City"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    required
                                    maxLength={50}
                                    validate={composeValidators(required, maxLength(50))}
                                />
                            </Box>
                            <Box display="inline-block" width="40%">
                                <Field
                                    component={FinalFormDropdown}
                                    name="venue.state"
                                    label="State"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    choices={states}
                                    placeholder="Select a State..."
                                    defaultValue="RI"
                                    validate={required}
                                />
                            </Box>
                        </Box>
                        <Box width="20%">
                            <Field
                                component={FinalFormTextField}
                                name="venue.zipCode"
                                label="Zip Code"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required
                                validate={composeValidators(required, zipCode)}
                                inputComponent={ZipCodeMask}
                            />
                        </Box>
                        <Field
                            component={FinalFormRadioGroup}
                            name="venue.overnight"
                            row
                            label="Overnight"
                            choices={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                            ]}
                            defaultValue={false}
                            format={(value) => (value ? value.toString() : "false")}
                            parse={(value) => value === "true"}
                        />
                        <Field
                            component={FinalFormTextField}
                            name="venue.recommendedAttire"
                            label="Recommended Attire"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required
                            multiline
                            maxLength={5000}
                            minRows={2}
                            maxRows={4}
                            validate={composeValidators(required, maxLength(5000))}
                        />
                        <Field
                            component={FinalFormTextField}
                            name="venue.mealsProvided"
                            label="Meals Provided"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            required
                            multiline
                            maxLength={5000}
                            minRows={2}
                            maxRows={4}
                            validate={composeValidators(required, maxLength(5000))}
                        />
                        <Field
                            component={FinalFormRadioGroup}
                            name="venue.childcareAvailable"
                            row
                            label="Childcare Available?"
                            choices={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                            ]}
                            defaultValue={false}
                            format={(value) => (value ? value.toString() : "false")}
                            parse={(value) => value === "true"}
                        />
                        <Field
                            component={FinalFormRadioGroup}
                            name="venue.translationServicesAvailable"
                            row
                            label="Translation Services Available?"
                            choices={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                            ]}
                            defaultValue={false}
                            format={(value) => (value ? value.toString() : "false")}
                            parse={(value) => value === "true"}
                        />
                    </>
                )}
                {language === Language.Spanish && (
                    <>
                        <Field
                            component={FinalFormTextField}
                            name="venue.nameSpanish"
                            label="Venue Name"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            maxLength={500}
                            validate={maxLength(500)}
                        />
                        <Field
                            component={FinalFormTextField}
                            name="venue.recommendedAttireSpanish"
                            label="Recommended Attire"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            multiline
                            maxLength={5000}
                            minRows={2}
                            maxRows={4}
                            validate={maxLength(5000)}
                        />
                        <Field
                            component={FinalFormTextField}
                            name="venue.mealsProvidedSpanish"
                            label="Meals Provided"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            multiline
                            maxLength={5000}
                            minRows={2}
                            maxRows={4}
                            validate={maxLength(5000)}
                        />
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default Venue;
