import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field } from "react-final-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LoadingButton from "../../common/loadingButton";
import { required } from "../../common/formValidators";
import { FinalFormCheckbox, FinalFormTextField } from "../../common/formWrappers";
import { BasicTooltipText } from "../../common/basicTooltipText";

const useStyles = makeStyles((theme) => ({
    seasonItem: {
        backgroundColor: "#ffffff",
    },
    disabled: {
        cursor: "not-allowed",
        color: theme.palette.lightGray.main,
    },
    checkbox: {
        color: theme.palette.success.main,
    },
    noMargin: {
        margin: 0,
    },
    textfield: {
        width: 400,
    },
}));

const SeasonItem = React.forwardRef(({ season, editRowId, onCancel, onEdit, saving, isDragging, DragHandleProps, DraggableProps }, ref) => {
    const classes = useStyles();

    return (
        <TableRow
            className={classes.seasonItem}
            ref={ref}
            {...DraggableProps}
            data-id={season.id}
            style={isDragging ? { ...DraggableProps.style, display: "table" } : { ...DraggableProps.style }}
        >
            <TableCell style={{ width: "5%" }}>
                <div {...DragHandleProps}>
                    {season.isActive && <DragHandleIcon className={!season.isActive || editRowId !== null ? classes.disabled : undefined} />}
                </div>
            </TableCell>
            <TableCell style={{ width: "35%" }}>
                {editRowId === season.id ? (
                    <Field
                        component={FinalFormTextField}
                        className={classes.noMargin}
                        // inputClassName={classes.textfield}
                        fullWidth
                        name="name"
                        placeholder="Name"
                        variant="outlined"
                        margin="dense"
                        maxLength="100"
                        required
                        validate={required}
                    />
                ) : (
                    <span>{season.name}</span>
                )}
            </TableCell>
            <TableCell style={{ width: "35%" }}>
                {editRowId === season.id ? (
                    <Field
                        component={FinalFormTextField}
                        className={classes.noMargin}
                        // inputClassName={classes.textfield}
                        fullWidth
                        name="spanishName"
                        placeholder="Spanish Translation"
                        variant="outlined"
                        margin="dense"
                        maxLength="100"
                        required
                        validate={required}
                    />
                ) : (
                    <span>{season.spanishName}</span>
                )}
            </TableCell>
            <TableCell style={{ width: "5%" }}>
                {editRowId === season.id ? (
                    <Field
                        component={FinalFormCheckbox}
                        checkboxClassName={classes.checkbox}
                        name="isActive"
                        tooltipContent={<BasicTooltipText>Only active seasons will display as filter options for Parents and Students</BasicTooltipText>}
                        tooltipPlacement="left"
                    />
                ) : season.isActive ? (
                    <Tooltip
                        arrow
                        placement="left"
                        title={<BasicTooltipText>Only active seasons will display as filter options for Parents and Students</BasicTooltipText>}
                    >
                        <CheckBoxIcon className={classes.checkbox} />
                    </Tooltip>
                ) : editRowId === season.id && !season.isActive ? (
                    <Tooltip
                        arrow
                        placement="left"
                        title={<BasicTooltipText>Only active seasons will display as filter options for Parents and Students</BasicTooltipText>}
                    >
                        <CheckBoxOutlineBlankIcon />
                    </Tooltip>
                ) : null}
            </TableCell>
            <TableCell style={{ width: "20%" }}>
                {editRowId === null && (
                    <Button size="small" variant="outlined" color="primary" onClick={() => onEdit(season)}>
                        Edit
                    </Button>
                )}
                {editRowId === season.id && (
                    <Box display="flex">
                        <LoadingButton size="small" variant="contained" color="primary" type="submit" loading={saving} style={{ marginRight: 5 }}>
                            Save
                        </LoadingButton>
                        <Button size="small" variant="outlined" color="primary" onClick={() => onCancel(season)}>
                            Cancel
                        </Button>
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
});

export default SeasonItem;
