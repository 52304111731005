import React from "react";
import Modal from "react-modal";

import "./RejectApplicationModal.css";
import { ApplicationStatus } from "../../../../admissions/logic/enums";

const RejectApplicationModal = ({ isOpen, onRequestClose, onSave, onRejectReasonChange, rejectReason, applicationStatus, didVirtualOrientation }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal reject-application-modal" shouldCloseOnOverlayClick={false}>
    <div>
      <h2>Reject Application</h2>
      <p>Would you like to reject this application? If so, please enter a reason.</p>
      <div>
        <span className="form-label">Rejection Reason:</span>
      </div>
      <select onChange={onRejectReasonChange} value={rejectReason}>
        <option value="1">Issue with proof of residency</option>
        <option value="2">Issue with proof of free/reduced lunch</option>
        <option value="3">Issue with student proof of age and identification</option>
        <option value="4">Issue with report card</option>
        <option value="5">Issue with personal statement</option>
        <option value="6">Student isn't in 6th grade</option>
        {applicationStatus === ApplicationStatus.Complete && didVirtualOrientation && <option value="7">Issue with Contract(s)</option>}
      </select>
      <div className="button-container">
        <button className="btn" onClick={onSave}>
          OK
        </button>
        <button className="link-button" onClick={onRequestClose}>
          Cancel
        </button>
      </div>
    </div>
  </Modal>
);

export default RejectApplicationModal;
