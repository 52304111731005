import React from 'react';
import PropTypes from 'prop-types';

import './School.css';

const School = ({ school, onClick }) => {
    const clsName = school.isSelected ? 'school selected' : 'school unselected';

    return (
        <button className={clsName} onClick={onClick}>
            {school.schoolName}
        </button>
    );
};

School.propTypes = {
    school: PropTypes.shape({
        schoolId: PropTypes.string.isRequired,
        schoolName: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired
    }),
    onClick: PropTypes.func.isRequired
};

export default School;
