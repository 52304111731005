import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import { rawIt } from "./programCodeDialogHelpers";
import { getProgramCodes, getSubcodeCreditHours, updateProgramSubCodeAssignment } from "../../../api/programEvents";
import ProgramCodeData from "./programCodeData";
import ProgramCodeForm from "./programCodeForm";
import ProgramCodeList from "./programCodeList";
import useAjaxStatus from "../../../../common/useAjaxStatus";
import { useStyles } from "./styles";

const ProgramCodeDialog = ({ assignmentId, department, eventCreditHours, eventTime, closeProgramCodeDialog, setReloadAttendance }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const [listProgramCodes, setListProgramCodes] = useState([]);
    const [programCodes, setProgramCodes] = useState([]);
    const [programCodesAux, setProgramCodesAux] = useState([]);
    const [listProgramCodesAux, setListProgramCodesAux] = useState([]);

    useEffect(() => {
        getSubcodeCreditHours(assignmentId, department)
            .then((resp) => {
                const _rawData = rawIt(resp.data.result);
                setListProgramCodesAux(_rawData);
                setListProgramCodes(_rawData);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });

        getProgramCodes()
            .then((resp) => {
                setProgramCodesAux(resp.data.result.map((item) => ({ ...item, programSubCodeId: item.id })));
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, assignmentId, department]);

    useEffect(() => {
        const cleanProgramCodes = () => {
            const programCodeIds = programCodesAux.map((item) => item.id);
            const listProgramCodeIds = listProgramCodesAux.map((item) => item.programSubCodeId);
            const innerJoin = programCodeIds.filter((item) => listProgramCodeIds.includes(item));
            const formProgramCodes = programCodesAux.filter((item) => !innerJoin.includes(item.id));

            setProgramCodes(formProgramCodes);
        };

        cleanProgramCodes();
    }, [listProgramCodesAux, programCodesAux]);

    const addEventProgramCode = (newEventProgramCode) => {
        //console.log("add");
        setListProgramCodes((prev) => [...prev, newEventProgramCode]);
    };

    const deleteEventProgramCode = (eventProgramCode) => {
        const { programCodeId, subCode } = eventProgramCode;
        const newEventProgramCodes = listProgramCodes.filter((item) => !(item.programCodeId === programCodeId && item.subCode === subCode));

        setListProgramCodes(newEventProgramCodes);
        setProgramCodesAux((prev) => [...prev, eventProgramCode]);
    };

    const handleOnSave = async () => {
        const arr = listProgramCodes.map(({ programSubCodeId, creditHours }) => ({
            programSubCodeId,
            creditHours,
        }));

        const payload = {
            subCodeCreditHours: arr,
            id: assignmentId,
        };

        try {
            const resp = await updateProgramSubCodeAssignment(payload);
            setReloadAttendance((prev) => !prev);
            resp.data.success && toast.success("Changes saved successfully");
            closeProgramCodeDialog();
        } catch (err) {
            ajaxErrorWrapper(err);
        }
    };

    return (
        <div>
            <ProgramCodeData eventCreditHours={eventCreditHours} eventTime={eventTime} />
            <ProgramCodeForm addEventProgramCode={addEventProgramCode} programCodes={programCodes} setAux={setProgramCodesAux} />
            <ProgramCodeList programCodesRecord={listProgramCodes} onDelete={deleteEventProgramCode} showDelete />
            <div className={classes.footer}>
                <div className={classes.saveButton}>
                    <Button
                        disabled={listProgramCodes.reduce((prev, current) => Number(prev) + Number(current.creditHours), 0) !== eventCreditHours}
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleOnSave()}
                    >
                        Save
                    </Button>
                    {listProgramCodes.reduce((prev, current) => Number(prev) + Number(current.creditHours), 0) !== eventCreditHours && (
                        <small className={classes.enrollmentAlert}>
                            Must be equal to current event hours: <b>{eventCreditHours}</b>
                        </small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProgramCodeDialog;
