import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import './index.css';

class StaffNavMenu extends React.Component {
    render() {
        const { navigationStatus } = this.props;

        return (
            <div className="staff-nav-menu">
                <NavLink to="/applications/started" activeClassName="selected">
                    <span>Started</span>
                    <span>{navigationStatus.started}</span>
                </NavLink>
                <NavLink to="/applications/inreview" activeClassName="selected">
                    <span>In Review</span>
                    <span>{navigationStatus.inreview}</span>
                </NavLink>
                <NavLink to="/applications/resubmit" activeClassName="selected">
                    <span>Resubmit</span>
                    <span>{navigationStatus.resubmit}</span>
                </NavLink>
                <NavLink to="/applications/rejected" activeClassName="selected">
                    <span>Rejected</span>
                    <span>{navigationStatus.rejected}</span>
                </NavLink>
                <NavLink to="/applications/quotas" className="admissions-btn" activeClassName="selected">
                    <span>Completed</span>
                    <span>{navigationStatus.quotas}</span>
                </NavLink>
                <NavLink to="/applications/events" className="admissions-btn" activeClassName="selected">
                    <span>Events</span>
                </NavLink>
                
                <NavLink to="/applications/reports" className="admissions-btn" activeClassName="selected">
                    <span>Reports</span>
                </NavLink>
            </div>
        );
    }
}

StaffNavMenu.propTypes = {
    navigationStatus: PropTypes.object.isRequired
};

export default StaffNavMenu;
