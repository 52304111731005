import axios from "axios";

export const resendEmail = (id) => {
    return axios.post("/api/services/app/Admin/ResendEmail", id);
};
export const resendEmailCode = (id) => {
    return axios.post("/api/services/app/Admin/ResendEmailCode", id);
};
export const resendTextCode = (id) => {
    return axios.post("/api/services/app/Admin/ResendTextCode", id);
};

export const assignAdvisorInstitution = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisorInstitution", payload);
};

export const unassignAdvisorInstitution = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignAdvisorInstitution", payload);
};

//Create Update
export const createUser = (user) => {
    return axios.post("/api/services/app/Admin/Create", user);
};
export const updateUser = (user) => {
    return axios.put("/api/services/app/Admin/Update", user);
};
export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}
//Get Users
export const getUser = (id) => {
    return axios.get("/api/services/app/Admin/GetUser", { params: { id } });
};

export const getPotentialDupes = (id) => {
    return axios.get("/api/services/app/Admin/GetPotentialDupes", { params: { id } });
};

export const getAllUsersByRole = (id) => {
    return axios.get("/api/services/app/Admin/GetUsersByRole", { params: { id } });
};

export const searchForUser = (text, roleId) => {
    return axios.post("/api/services/app/Admin/SearchForUser", { text, roleId });
};
export const exportUser = async (text, roleId) => {
    return await axios.post("/api/services/app/Admin/ExportUser", { text, roleId });
};
//Role Methods
export const getAllRoles = () => {
    return axios.get("/api/services/app/Admin/GetAllRoles");
};

export const getAllRolesByUser = (id) => {
    return axios.get("/api/services/app/Admin/GetRolesByUser", { params: { id } });
};

//Assign Unassign roles
export const assign = (userId, roleId) => {
    return axios.post("/api/services/app/Admin/Assign", { userId, roleId });
};

export const unassign = (userId, roleId) => {
    return axios.post("/api/services/app/Admin/unassign", { userId, roleId });
};

export const adminForgotPassword = (userId) => {
    return axios.post("/api/services/app/accountManagement/AdminForgotPassword", { id: userId });
};

export const getAllCrusaders = (text) => {
    return axios.get("/api/services/app/Admin/GetAllCrusaders", { params: { text } });
};

export const getCrusader = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetCrusader", { params: { userProfileId } });
};

export const updateCrusader = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateCrusaderUser", payload);
};

export const getAllParents = (text) => {
    return axios.get("/api/services/app/Admin/GetAllParents", { params: { text } });
};

export const getParent = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetParent", { params: { userProfileId } });
};

export const updateParent = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateParentUser", payload);
};

export const updateUsername = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateUserName", payload);
};

export const updatePassword = (payload) => {
    return axios.put("/api/services/app/Admin/UpdatePassword", payload);
};

export const updateActiveStatus = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateActiveStatus", payload);
};

export const checkUsernameExists = (username) => {
    return axios.post("/api/services/app/Admin/CheckUsernameExists", null, { params: { username } });
};
export const createUserForProfile = (payload) => {
    return axios.post("/api/services/app/accountManagement/CreateUserForProfile", payload);
};
export const getAdvisorBasics = (userId) => {
    return axios.get("/api/services/app/Admin/GetAdvisorBasics", { params: { userId } });
};
export const getCode = (userId) => {
    return axios.get("/api/services/app/Admin/GetCode", { params: { userId } });
};
export const getPotentialAdvisorSubordinates = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetPotentialAdvisorSubordinates", { params: { userProfileId } });
};
export const returnAdvisorBasicsAsync = ( payload) => {
    return axios.post("/api/services/app/Admin/ReturnAdvisorBasics", payload);
};
export const assignAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisor", payload);
};

export const assignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisorSchool", payload);
};

export const unassignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignAdvisorSchool", payload);
};

export const assignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignDirectorAdvisor", payload);
};
export const setDirector = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDirector", payload);
};
export const setGap = (payload) => {
    return axios.post("/api/services/app/Advisors/SetGap", payload);
};
export const setHonorific = (payload) => {
    return axios.post("/api/services/app/Advisors/SetHonorific", payload);
};
export const setAccelerate = (payload) => {
    return axios.post("/api/services/app/Advisors/SetAccelerate", payload);
};
export const unassignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignDirectorAdvisor", payload);
};

export const setDepPostSecondary = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDepPostSecondary", payload);
};

export const setDepHighSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDepHighSchool", payload);
};

export const setDepMiddleSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDepMiddleSchool", payload);
};

export const setDepFamilyEngagement = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDepFamilyEngagement", payload);
};

export const getAdvisorGoals = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetAdvisorGoals", { params: { userProfileId } });
};

export const setAdvisorGoal = (payload) => {
    return axios.post("/api/services/app/Advisors/SetAdvisorGoal", payload);
};

export const removeAdvisorGoal = (payload) => {
    return axios.post("/api/services/app/Advisors/RemoveAdvisorGoal", payload);
};
export const deDupe = (input) => {
    return axios.post('/api/services/app/accountManagement/DeDupe', input);
};

export const getStudentProfile = (userProfileId) => {
    return axios.get('/api/services/app/accountManagement/GetStudentProfileAsync', {params: { userProfileId } });
};

export const updateProfile = (input) => {
    return axios.post('/api/services/app/accountManagement/UpdateProfile', input);
};
export const getProfile = (userProfileId) => {
    return axios.get('/api/services/app/accountManagement/GetProfile', { params: { userProfileId } });
};

export const updateMyProfile = (input) => {
    return axios.post('/api/services/app/accountManagement/UpdateMyProfile', input);
};

export const addNonProgram = (input) => {
    return axios.post('/api/services/app/Admin/AddNonProgramStudent', input);
};
export const getObjectives = () => {
    return axios.get("/api/services/app/objectives/GetAllObjective");
};
export const addAdvisorGoal = (objective) => {
    return axios.post("/api/services/app/objectives/AddAdvisorGoal", objective);
};
export const updateAdvisorGoal = (objective) => {
    return axios.post("/api/services/app/objectives/UpdateAdvisorGoal", objective);
};
export const deleteAdvisorGoal = (objective) => {
    return axios.post("/api/services/app/objectives/DeleteAdvisorGoal", objective);
};

export const getUserNameSuggestion = (userProfileId) => {
    return axios.get('/api/services/app/accountManagement/GetUserNameSuggestion', { params: { userProfileId } });
};