import axios from "axios";

export const getAllRefunds = scholarshipId => {
  return axios.get("/api/services/app/ScholarshipTransaction/GetRefunds", {
    params: { scholarshipId }
  });
};

export const getStudentRefunds = (scholarshipId, transactionRequestId, institutionId, isComplete) => {
  return axios.get("/api/services/app/ScholarshipTransaction/GetReceivedRefundDetails", {
    params: {
      scholarshipId,
      transactionRequestId,
      institutionId,
      isComplete,
      type: 2
    }
  });
};

export const createRefundTransactionRequest = refund => {
  return axios.post("/api/services/app/ScholarshipTransaction/CreateRefundTransactionRequest", refund);
};

export const receiveRefund = refund => {
  return axios.post("/api/services/app/ScholarshipTransaction/ReceiveRefund", refund);
};
