import axios from 'axios';

export const verifyEmail = (email, code) => {
    return axios.post('/api/services/app/accountManagement/verifyEmail', {
        email,
        code
    });
};

export const verifyTextCode = (userName, locale, code) => {
    return axios.post('/api/services/app/accountManagement/verifyTextCode', {
        userName,
        code,
        locale
    });
};

export const resendTextCode = (userName, locale) => {
    return axios.post('/api/services/app/accountManagement/resendTextCode', {
        userName,
        locale
    });
};

export const createAccount = (email, password, confirm, firstName, middleName, lastName, guardian, locale) => {
    return axios.post('/api/services/app/accountManagement/registerparent', {
        email,
        password,
        confirm,
        firstName,
        middleName,
        lastName,
        guardian,
        locale
    });
};

export const createAccountByUserName = (email, password, confirm, mobilePhone, userName, firstName, middleName, lastName, guardian, locale) => {
    return axios.post('/api/services/app/accountManagement/registerparentByUserName', {
        email,
        password,
        confirm,
        firstName,
        middleName,
        lastName,
        userName,
        mobilePhone,
        guardian,
        locale
    });
};

export const login = (userNameEmail, password, locale) => {
    return axios.post('/api/TokenAuth/Authenticate', {
        userNameOrEmailAddress: userNameEmail,
        password,
        locale
    });
};

export const updateStaffPassword = request => {
    return axios.put('/api/services/app/accountManagement/UpdateStaffPassword', request);
};

export const forgotPassword = (email, locale) => {
    return axios.post('/api/services/app/accountManagement/ForgotPassword', {
        email,
        locale
    });
};

export const forgotUserName = (phone, locale) => {
    return axios.post('/api/services/app/accountManagement/ForgotUserName', {
        phone,
        locale
    });
};

export const forgotPasswordByUserName = (userName, locale) => {
    return axios.post('/api/services/app/accountManagement/ForgotPasswordByUserName', {
        userName,
        locale
    });
};

export const resetPassword = (email, password, confirm, code, id, locale) => {
    return axios.post('/api/services/app/accountManagement/ResetPassword', {
        email,
        password,
        confirm,
        code,
        id,
        locale
    });
};

export const resetPasswordByUserName = (userName, password, confirm, code, locale) => {
    return axios.post('/api/services/app/accountManagement/ResetPasswordByUserName', {
        userName,
        password,
        confirm,
        code,
        locale
    });
};

export const getCode = (userId) => {
    return axios.get("/api/services/app/Admin/GetCode", { params: { userId } });
};