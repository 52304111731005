import React, { Component } from 'react';
import { Button, TextField, Grid, MenuItem, Select, InputLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SweetAlert from "react-bootstrap-sweetalert";
import FontAwesome from "react-fontawesome";
import { updatePassword, updateUsername, updateActiveStatus, updateCrusader, updateParent } from "./apis";
import SimpleDialog from "../../../common/simpleDialog";
import moment from "moment";
import Link from "@material-ui/core/Link";

const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});
class DeDupeToggle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            origD: "",
           dupeD: ""
        };
    }





    componentDidMount = async () => {
        this.updateSchoolNames();
    }

    componentDidUpdate(prevProps, prevState) {
       
        if (
            prevProps.val1 !== this.props.val1 ||
            prevProps.val2 !== this.props.val2 ||
            prevProps.schools !== this.props.schools ||
            prevProps.label !== this.props.label
        ) {
            this.updateSchoolNames();
        }
    }

    updateSchoolNames = () => {
        
        if (this.props.name == "schoolId" && Array.isArray(this.props.schools)) {
            const origSchool = this.props.schools.find(
                (school) => school.id == this.props.val1
            );
            const dupeSchool = this.props.schools.find(
                (school) => school.id == this.props.val2
            );
    
            this.setState({
                origD: origSchool ? origSchool.name : "",
                dupeD: dupeSchool ? dupeSchool.name : "",
            });
        }

        if (this.props.name == "cityId" && Array.isArray(this.props.cities)) {
            const origSchool = this.props.cities.find(
                (school) => school.id == this.props.val1
            );
            const dupeSchool = this.props.cities.find(
                (school) => school.id == this.props.val2
            );
          
            this.setState({
                origD: origSchool ? origSchool.name : "",
                dupeD: dupeSchool ? dupeSchool.name : "",
            });
        }

        if (this.props.name == "selectedInstitutionId" && Array.isArray(this.props.institutions)) {
            const origSchool = this.props.institutions.find(
                (school) => school.id == this.props.val1
            );
            const dupeSchool = this.props.institutions.find(
                (school) => school.id == this.props.val2
            );
            
            this.setState({
                origD: origSchool ? origSchool.name : "",
                dupeD: dupeSchool ? dupeSchool.name : "",
            });
        }
        
    }
    handleChange = async (v1, v2) => {

        
        this.props.onChange(v1.target.name, v2)

    };
   


    render() {


        return (
            <ThemeProvider theme={theme}>
                <Grid container xs={8} sm={12}>
                    <Grid item xs={12} style={{ textAlign: "center", color: "primary" }}>
                        <b>{this.props.label}</b>
                    </Grid>
                    <Grid item xs={5} style={{ textAlign: "right" }}>
                        {this.state.origD !== "" && `(${this.state.origD})`} {" "} {this.props.val1}
                        
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                        <Switch
                            onChange={this.handleChange}
                            name={this.props.name}
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        {this.props.val2}{" "}
                        {this.state.dupeD !== "" && `(${this.state.dupeD})`}
                    </Grid>
                </Grid>
            </ThemeProvider>
        );

    }
}

export default DeDupeToggle;
