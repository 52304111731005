import axios from "axios";

export function getVideoSettings() {
    return axios.get("/api/services/app/Admin/GetVideoSettings");
}

export function updateVideoSettings(orientationVideoEnabled, orientationVideoEnglish, orientationVideoSpanish) {
    return axios.put("/api/services/app/Admin/UpdateVideoSettings", { orientationVideoEnabled, orientationVideoEnglish, orientationVideoSpanish });
}

export function getAdmissionSettings() {
    return axios.get("/api/services/app/Admin/GetAdmissionSettings");
}

export function updateAdmissionSettings(admissionPhone) {
    return axios.put("/api/services/app/Admin/UpdateAdmissionSettings", { admissionPhone });
}
