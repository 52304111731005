import React from "react";

import FontAwesome from "react-fontawesome";
import { FormattedMessage } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { ThemeProvider } from "@material-ui/core/styles";
import Appsummary from "./appsummary";
import * as ApplicationApi from "../../api/applications";
import { createCCMUITheme } from "../../support/muiTheme";
import Button from "../../support/components/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from '@mui/material/DialogContentText';
import "./index.css";
import SimpleDialog from "../../common/simpleDialog";
const msgKey = "app.OnlyPrimary";
const ttlKey = "app.errorTitle";

class Applications extends React.Component {
    state = {
        applications: [],
        primaryParent: false,
        secOptions: [],
        selSec: [],
        secMode: 0,
        showSec: false
    };
   

    componentDidMount = async () => {
        const res = await ApplicationApi.getAllByUser();
        const prim = await ApplicationApi.getParentStatusByUser();
        const allapps = res.data.result;
        //console.log(allapps);
        if (allapps.length > 0) {
            const secList = await ApplicationApi.getSecondaryParentsForFamily(allapps[0].familyId);

            //console.log(secList);
            if (allapps.length == 1 && !allapps[0].progress.tabProgress["Admissions.Student"] && !allapps[0].progress.tabProgress["Admissions.Parent"]) {

                this.props.setActiveApp(allapps[0]);

            }
            this.props.setAllApps(allapps);
            this.setState({ applications: allapps, primaryParent: prim.data.result.primaryParent, secOptions: secList.data.result });
        } else {
            this.setState({  primaryParent: prim.data.result.primaryParent});
        }
        
    };

    formatDate = (date) => {
        const ret = moment(date);
        return ret;
    };
    newApp = async (sec, mode)=>{
        //console.log(this.state);
        switch (mode) {
            case 0:
                await this.newEmptyApp();
                break;
            case 1:
                await this.newParentApp(sec.id);
                break;
            case 2:
                await this.newEmptyApp();
                break;
            default:
                await this.newEmptyApp();
                break;
        }
           
        
        

    };

    newParentApp = async (parentId) => {
        const newAppraw = await ApplicationApi.createWithParent(parentId);
        const newAppId = newAppraw.data.result.id;

        const res = await ApplicationApi.getAllByUser();


        res.data.result.forEach((newApp) => {
            if (newApp.id == newAppId) {
                //console.log(newApp);

                //newApp.progress.tabProgress.Admissions.Parent = false;
                this.props.setActiveApp(newApp);
            }

        });

    }


    newEmptyApp = async () => {
        const newAppraw = await ApplicationApi.createByUser();
        const newAppId = newAppraw.data.result.id;

        const res = await ApplicationApi.getAllByUser();


        res.data.result.forEach((newApp) => {
            if (newApp.id == newAppId) {
                //console.log(newApp);

                //newApp.progress.tabProgress.Admissions.Parent = false;
                this.props.setActiveApp(newApp);
            }

        });

    }

    showSec = () => {
        this.setState({ showSec: true });
    }
    hideSec = () => {
        this.setState({ showSec: false });
    }
    setSec = async (sec, mode) => {

        this.setState({ selSec: sec, secMode: mode, showSec: false });
        this.props.setSecondary(sec, mode);

        await this.newApp(sec, mode)

    }
    setActiveApplication = (app) => {
        

        this.props.setActiveApp(app);
    };
    appDelete = async (app) => {

        await ApplicationApi.deleteApplication(app.id);
        const res = await ApplicationApi.getAllByUser();
        const allapps = res.data.result;
        this.setState({
            applications: allapps
        });
        //console.log("delete");
    }
    render() {
        //console.log(this);
        if (!this.state.primaryParent) {
            return (
                <FormattedMessage id="app.OnlyPrimary" defaultMessage="[student.submit.newapp]" tagName="div" />
            );
        } else {


            return (
                <ThemeProvider theme={createCCMUITheme()}>
                    {this.state.applications.map(app => {
                        const subDate = this.formatDate(app.submissionDate);
                        return (

                            <Appsummary app={app} setActiveApplication={this.setActiveApplication} openProfile={this.props.openProfile} onDelete={this.appDelete } />


                        );
                    }
                    )
                    }
                    <div></div>
                    <div className="add-container">
                        <FormattedMessage id="student.submit.newapp" defaultMessage="[student.submit.newapp]" tagName="div" /> &nbsp;&nbsp;
                        <Button
                            id="application.view"
                            defaultMessage="View Application"
                            className={`new-app-button circle`}
                            onClick={() => this.showSec()}

                        ><FontAwesome name="plus" /></Button>
                    </div>


                    <FormattedMessage
                        id="app.ChooseSecondaryParent"
                        defaultMessage="[Choose Secondary Parent or Guardian]"
                        children={(formattedMessage) => (
                            <SimpleDialog open={this.state.showSec} onClose={this.hideSec} title={formattedMessage} showCloseButton={true}>
                                <div>
                                    {this.state.secOptions.map(sec => {
                                        //console.log(sec);
                                        //const subDate = this.formatDate(app.submissionDate);
                                        return (
                                            <span>
                                                {sec.firstName} {sec.lastName}
                                                &nbsp; <Button
                                                    id="application.view"
                                                    defaultMessage="View Application"
                                                    className={`new-app-button`
                                                    }
                                                    onClick={() => this.setSec(sec, 1)}

                                                >Use</Button><br /><br />

                                            </span>
                                        );
                                    }
                                    )
                                    }

                                    <Button
                                        id="application.view"
                                        defaultMessage="View Application"
                                        className={`new-app-button`
                                        }
                                        onClick={() => this.setSec([], 2)}

                                    >New&nbsp;</Button> <br /><br />
                                    <Button
                                        id="application.view"
                                        defaultMessage="View Application"
                                        className={`new-app-button`
                                        }
                                        onClick={() => this.setSec(null, 0)}

                                    >None</Button>
                                </div>
                            </SimpleDialog>
                        )}
                    />


                   

                </ThemeProvider>
            );
        }
    }
}
export default Applications;
