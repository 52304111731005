import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { SubNavigation } from "../support/security";
import Crusaders from "./components/crusaders";
import YearPicker from "../scholarship/YearPicker";

import "./index.css";

const Messaging = ({ location }) => {
    const [scholarship, setScholarship] = useState(null);
    if (location.pathname === "/messages") {
        return <Redirect to="/messages/crusaders" />;
    }
    return (
        <div className="messaging-main">
            <div className="scholarship-year-container">
                <YearPicker
                    onSelectChange={(s) => {
                        setScholarship(s);
                    }}
                />
            </div>
            <div className="messaging-main-nav">
                <SubNavigation sectionName="Messages" />
            </div>
            <Route path="/messages/crusaders" render={() => <Crusaders scholarship={scholarship} />} />
        </div>
    );
};

export default Messaging;
