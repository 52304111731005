import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    header: {
        display: "grid",
        gridTemplateColumns: "auto 200px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"title saveButton" "subtitle saveButton"`,
    },
    title: {
        gridArea: "title",
        alignSelf: "center",
        textTransform: "uppercase",
    },
    subtitle: {
        fontWeight: "bold",
        gridArea: "subtitle",
    },
    button: {
        margin: theme.spacing(1),
    },
    subHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    headerCounters: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    participantsCounter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "large",
    },
    notReviewedCounter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
    },
    link: {
        color: theme.palette.lightGray.main,
        "&:hover": {
            cursor: "pointer",
            color: "rgba(181, 189, 0, 1)",
        },
    },
    notReviewedUser: {
        "& .MuiButton-textSecondary:not(.Mui-disabled) .MuiButton-label": {
            color: "red",
            fontWeight: "600",
        },
        color: "red",
    },
    buttonContainer: {
        display: "flex",
    },
    alert: {
        textAlign: "center",
        backgroundColor: "#ffebee",
        border: "1px solid #ef9a9a",
        borderRadius: "5px",
        padding: theme.spacing(0, 0.5),
        marginLeft: theme.spacing(1),
    },
}));
