import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLocaleAction } from '../../../language/redux';

const withLocale = WrappedComponent => {
    class Locale extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        locale: state.locale
    });

    const mapDispatchToProps = dispatch => ({
        setLocale: bindActionCreators(setLocaleAction, dispatch)
    });

    Locale = connect(
        mapStateToProps,
        mapDispatchToProps
    )(Locale);
    return Locale;
};

export default withLocale;
