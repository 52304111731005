import React from "react";
import { Route, Redirect } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import Seasons from "../seasons";
import { Events } from "../../support/security/permissionNames";
import TertiaryNav from "../../common/tertiaryNav";
import GeneralConfiguration from "../generalConfiguration";
import ActivityCodes from "../activityCodes";
import ProgramCodes from "../programCodes";
import Templates from "../templates/templates";

const useStyles = makeStyles((theme) => ({
    settings: {
        flexGrow: 1,
        height: "100%",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
        backgroundColor: theme.palette.background.paper,
        "& .MuiTab-wrapper": {
            fontSize: "0.75 rem",
            fontWeight: 600,
            color: theme.palette.primary.main,
        },
    },
}));

const links = [
    {
        label: "Seasons",
        to: "/events/settings/seasons",
        permission: Events,
    },
    {
        label: "General Configuration",
        to: "/events/settings/general-config",
        permission: Events,
    },
    {
        label: "Templates",
        to: "/events/settings/templates",
        permission: Events,
    },
];

const Settings = () => {
    const classes = useStyles();

    return (
        <div className={classes.settings}>
            <TertiaryNav links={links} />
            <div className="hr-line-medium margin-top" />
            <Route path="/events/settings/general-config" exact component={GeneralConfiguration} />
            <Route path="/events/settings/seasons" exact component={Seasons} />
            <Route path="/events/settings/activity-codes" exact component={ActivityCodes} />
            <Route path="/events/settings/program-codes" exact component={ProgramCodes} />
            <Route path="/events/settings/templates" exact component={Templates} />
            <Route path="/events/settings" exact render={() => <Redirect exact path="/events/settings" to="/events/settings/seasons" />} />
        </div>
    );
};

export default Settings;
