import React from 'react';
import { Button, TextField, Grid, Paper, Typography } from '@mui/material';

class StaffMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            body: '',
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    };

    customRecipientChipRenderer = ( val) => {




        return (
            <div className="filterSelection" >

                {val.lastName}, {val.firstName}  

            </div>
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            recip: this.props.recips,
            subject: this.state.subject,
            body: this.state.body
        }
        this.props.onSend(data);
        // Here you would handle the form submission, for example, sending the email
        //console.log('Form submitted with:', this.state);
    };

    render() {
        //console.log(this.props);
        return (
            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <Paper style={{ padding: 20, marginTop: 20 }}>
                        <Grid item xs={12} sm={8} md={6}>
                            {this.props.recips.map(re => {
                                return( <span className="chip">
                                    {this.customRecipientChipRenderer(re)}
                            </span>)
                        })}
                        </Grid>
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Subject"
                                        name="subject"
                                        value={this.state.subject}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Body"
                                        name="body"
                                        multiline
                                        rows={4}
                                        value={this.state.body}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default StaffMessage;
