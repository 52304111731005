/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import Spinner from "react-spinkit";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import Button from "../../support/components/Button";
import * as apis from "../../api/login";

import "./index.css";
import withLocale from "../../support/hoc/withLocale";

class Verify extends Component {
    state = {
        code: "",
        showLoader: false,
        showDialog: false,
        showLogin: false,
        dialogTitle: "",
        dialogText: "",
        shouldRedirectToLogin: false,
    };

    componentDidMount() {
        const { userName } = this.props.match.params;
        this.userName = userName;
    }

    onUpdateInputValue = (e) => {
        this.setState({
            code: e.target.value,
        });
    };

    onSubmit = () => {
        const { locale } = this.props;
        const { code } = this.state;

        const dialogText = `${this.props.intl.formatMessage({ id: "verify.thankyou" })} ${this.props.intl.formatMessage({
            id: "verify.numberVerified",
        })} ${this.props.intl.formatMessage({ id: "verify.nowLogin" })}`;

        this.setState({ showLoader: true });
        apis.verifyTextCode(this.userName, locale, code)
            .then((result) => {
                this.setState({
                    showLoader: false,
                    shouldRedirectToLogin: true,
                    showDialog: true,
                    dialogTitle: this.props.intl.formatMessage({ id: "app.success" }),
                    dialogText: dialogText,
                });
            })
            .catch((error) => {
                this.setState({
                    showLoader: false,
                    showDialog: true,
                    dialogTitle: this.props.intl.formatMessage({ id: "app.errorTitle" }),
                    dialogText: error.response.data.error.message,
                });
            });
    };

    onResend = (e) => {
        e.preventDefault();
        const { locale } = this.props;
        this.setState({ showLoader: true });
        apis.resendTextCode(this.userName, locale)
            .then((result) => {
                this.setState({
                    showLoader: false,
                    showDialog: true,
                    dialogTitle: "",
                    dialogText: this.props.intl.formatMessage({
                        id: "verify.instructions2",
                    }),
                });
            })
            .catch((error) => {
                this.setState({
                    showLoader: false,
                    showDialog: true,
                    dialogTitle: this.props.intl.formatMessage({ id: "app.errorTitle" }),
                    dialogText: error.response.data.error.message,
                });
            });
    };

    onConfirm = () => {
        this.setState({ showDialog: false }, () => {
            this.setState({ showLogin: this.state.shouldRedirectToLogin });
        });
    };

    render() {
        if (this.state.showLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <Fragment>
                <div className="create-account-verify">
                    <div>
                        <div className="logo" alt="logo" />
                        <FormattedMessage
                            id="verify.accountNotVerified"
                            defaultMessage="[Enter Verification Code]"
                            children={(formatedMessage) => <h1 className="screen-title">{formatedMessage}</h1>}
                        />
                        <FormattedMessage id="verify.instructions" defaultMessage="[instructions]" children={(formatedMessage) => <p>{formatedMessage}</p>} />
                        <FormattedMessage id="verify.tempCode" defaultMessage="[tempCode]" children={(formattedMessage) => <h3>{formattedMessage}</h3>} />
                        <FormattedMessage
                            id="verify.tempCode"
                            defaultMessage="[tempCode]"
                            children={(formattedMessage) => (
                                <input type="text" value={this.state.code} placeholder={formattedMessage} onChange={this.onUpdateInputValue} />
                            )}
                        />

                        <Button id="login.submit" defaultMessage="[Submit]" onClick={this.onSubmit} />
                    </div>
                    <div className="verify-resend-row">
                        <FormattedMessage
                            id="verify.resendPart1"
                            defaultMessage="[resend part 1]"
                            children={(formattedMessage) => <span>{formattedMessage}</span>}
                        />
                        <a href="" onClick={this.onResend} style={{ marginTop: "1px" }}>
                            <FormattedMessage
                                id="verify.resendPart2"
                                defaultMessage="[resend part2]"
                                children={(formattedMessage) => <span className="link-button">{formattedMessage}</span>}
                            />
                        </a>
                    </div>
                    <SweetAlert
                        show={this.state.showDialog}
                        title={this.state.dialogTitle}
                        onConfirm={this.onConfirm}
                        confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
                    >
                        {this.state.dialogText}
                    </SweetAlert>
                </div>
                {this.state.showLoader ? (
                    <React.Fragment>
                        <Spinner name="line-scale" color="#3cc6e8" fadeIn="none" />
                        <div className="Loading"></div>
                    </React.Fragment>
                ) : null}
            </Fragment>
        );
    }
}

Verify.propTypes = {
    locale: PropTypes.string.isRequired,
};

Verify = withLocale(Verify);
Verify = withRouter(Verify);

export default injectIntl(Verify);
