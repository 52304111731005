import { createTheme } from "@material-ui/core/styles";

const primary = "#123225";
const secondary = "#b5bd00";
const tertiary = "#00205b";

export const createCCMUITheme = () => {
    window.theme = createTheme({
        spacing: 10,
        typography: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
        },
        palette: {
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary,
            },
            tertiary: {
                main: tertiary,
            },
            success: {
                main: "#2ac458",
            },
            error: {
                main: "#f44336",
            },
            warning: {
                main: "#f4a936",
            },
            info: {
                main: "#1d98c9",
            },
            lightGray: {
                main: "#ababab",
            },
            inputDisabled: {
                main: "#efefef",
            },
        },
        overrides: {
            MuiButton: {
                containedPrimary: {
                    "&:not(.Mui-disabled) .MuiButton-label": {
                        color: "#fff",
                        fontWeight: 600,
                    },
                    "&:not(.Mui-disabled) .MuiButton-startIcon": {
                        color: "#fff",
                    },
                    "&:not(.Mui-disabled) .MuiTouchRipple-child": {
                        backgroundColor: primary,
                    },
                },
                containedSecondary: {
                    "&:not(.Mui-disabled) .MuiButton-label": {
                        fontWeight: 600,
                    },
                    "&:not(.Mui-disabled) .MuiButton-startIcon": {
                        color: "#fff",
                    },
                    "& .MuiTouchRipple-child": {
                        backgroundColor: secondary,
                    },
                },
                outlinedPrimary: {
                    "&:not(.Mui-disabled) .MuiButton-label": {
                        color: primary,
                        fontWeight: 600,
                    },
                    "&:not(.Mui-disabled) .MuiButton-startIcon": {
                        color: primary,
                    },
                    "&:not(.Mui-disabled) .MuiTouchRipple-child": {
                        backgroundColor: primary,
                    },
                },
                textPrimary: {
                    "&:not(.Mui-disabled) .MuiButton-label": {
                        color: primary,
                        fontWeight: 600,
                    },
                    "&:not(.Mui-disabled) .MuiButton-startIcon": {
                        color: primary,
                    },
                    "&:not(.Mui-disabled) .MuiTouchRipple-child": {
                        backgroundColor: primary,
                    },
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: "#ffffff",
                    fontSize: "1rem",
                    border: "1px solid #ababab",
                    color: "#ababab",
                },
                tooltipPlacementTop: {
                    "& .MuiTooltip-arrow": {
                        color: "#ffffff",
                        "&:before": {
                            borderBottom: "1px solid #ababab",
                            borderRight: "1px solid #ababab",
                        },
                    },
                },
                tooltipPlacementBottom: {
                    "& .MuiTooltip-arrow": {
                        color: "#ffffff",
                        "&:before": {
                            borderTop: "1px solid #ababab",
                            borderLeft: "1px solid #ababab",
                        },
                    },
                },
                tooltipPlacementLeft: {
                    "& .MuiTooltip-arrow": {
                        color: "#ffffff",
                        "&:before": {
                            borderTop: "1px solid #ababab",
                            borderRight: "1px solid #ababab",
                        },
                    },
                },
                tooltipPlacementRight: {
                    "& .MuiTooltip-arrow": {
                        color: "#ffffff",
                        "&:before": {
                            borderBottom: "1px solid #ababab",
                            borderLeft: "1px solid #ababab",
                        },
                    },
                },
                // arrow: {
                //     color: "#ffffff",
                //     "&:before": {
                //         borderBottom: "1px solid #ababab",
                //         borderRight: "1px solid #ababab",
                //     },
                // },
            },
            MuiBadge: {
                colorPrimary: {
                    color: "#ffffff",
                },
            },
            MuiTableCell: {
                head: {
                    padding: "2px 16px",
                    borderBottom: `2px solid ${secondary}`,
                    fontWeight: 600,
                },
            },
            MuiSwitch: {
                colorPrimary: {
                    "& span": {
                        color: "#ffffff",
                    },
                    "&.Mui-checked span": {
                        color: primary,
                    },
                },
            },
            MuiPaper: {
                root: {
                    backgroundColor: "#ffffff",
                },
            },
        },
    });
    return window.theme;
};
