import React, { Component } from 'react';
import { Checkbox, Grid, Tabs, Tab, Box } from '@mui/material';
import * as api from "../../api"
import Progress from "./progress";
import AdvisorContext from "../../context/context";



class ParentProgress extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            feObj: [],
        };
    }
    componentDidMount = async () => {


        let input = [];
        input.push({ userProfileId: this.props.profile.userProfile.id });

        const recs = await api.retrieveAdditionInfo(input);

       
        const feObj = this.context.taggingOptions.filter(flt => flt.classification.familyEngagementGoals == true);


        this.setState({ feObj, userTags: recs.data.result[0].contactInfo.tags });
    }
    componentDidUpdate(prevProps, prevState) {



    }


    isChecked = (tag, subtag) => {


        let ret = false;
        const tags = this.state.userTags.filter(flt => flt.tagId == tag && flt.subTagId == subtag);

        if (tags.length > 0) {
            ret = true;
        }

        return ret;

    }

    handleTabChange = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    };
    renderDepartment = (department) => {
        return (
            <Grid container spacing={2}>
                {department.map((dep, depIndex) => {
                    let tagname = "";

                    return (
                        <React.Fragment key={depIndex}>
                            {dep.tags.map((tag, tagIndex) => {
                                if (tag.subtagId === "00000000-0000-0000-0000-000000000000") {
                                    tagname = tag.tagName + " : ";
                                    return (
                                        <Grid item xs={12} className="progHeading" key={tagIndex}>
                                            {tag.tagName}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid item xs={4} key={tagIndex} className="progEntry">
                                            <Checkbox checked={this.isChecked(tag.tagId, tag.subtagId)} disabled></Checkbox> {tag.tagName.replace(tagname, '')}
                                        </Grid>
                                    );
                                }
                            })}
                        </React.Fragment>
                    );
                })}
            </Grid>
        );
    }
    renderTabContent = () => {
        const { selectedTab } = this.state;
        const { children } = this.props.profile;
        if (selectedTab === children.length) {
            return (
                <Grid container spacing = { 2} >
                    <Grid item xs={12}>
                        <Box sx={{ border: '1px solid #ddd', padding: 2 }}>
                            {this.renderDepartment(this.state.feObj) }
                        </Box>
                    </Grid>
            </Grid >
            
            
            );
        }
        const child = children[selectedTab];
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ border: '1px solid #ddd', padding: 2 }}>
                        <Progress key={selectedTab} profile={child} />
                    </Box>
                </Grid>
            </Grid>
        );
    };

    render() {
        const { children } = this.props.profile;
        const { selectedTab } = this.state;

        return (
            <div>
                <Tabs
                    value={selectedTab}
                    onChange={this.handleTabChange}
                    aria-label="Parent Progress Tabs"
                    variant="fullWidth"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-evenly',
                        },
                        '.MuiTab-root': {
                            border: '1px solid #000',
                            borderBottom: 'none',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            marginRight: '4px',
                            '&:last-child': {
                                marginRight: 0,
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#123225',
                                color: '#fff',
                            },
                            '&:hover': {
                                backgroundColor: '#123225',
                                color: '#fff',
                                opacity: 1,
                            },
                        },
                    }}
                >
                    {children.map((child, index) => (
                        <Tab key={index} label={child.userProfile.firstName} />
                    ))}
                    <Tab label="Family Engagement" />
                </Tabs>
                {this.renderTabContent()}
            </div>
        );
    }
}

export default ParentProgress;
