import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { required, renderRadioField, renderSelectList, renderField } from "../../../support/forms";
import enumHelper from "../../../support/logic/enumHelper";
import { Language } from "../../logic/enums";

import "./Left.css";

class Left extends Component {
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.primaryLanguage && !isNaN(prevProps.primaryLanguage) && prevProps.primaryLanguage !== this.props.primaryLanguage) {
            this.props.change("otherLanguage", null);
        }
    }

    render() {
        const { intl, primaryLanguage, readonly } = this.props;
        const yes = intl.formatMessage({ id: "Yes" });
        const no = intl.formatMessage({ id: "No" });
        const languageOptions = enumHelper
            .getSelectOptionsList(Language)
            .map((i) =>
                i.label === "Other"
                    ? { ...i, label: intl.formatMessage({ id: "app.other" }) }
                    : i.label === "English"
                    ? { ...i, label: intl.formatMessage({ id: "login.english" }) }
                    : i.label === "Spanish"
                    ? { ...i, label: intl.formatMessage({ id: "login.spanish" }) }
                    : i
            );
        return (
            <div className="household-form-left-container">
                <FormattedMessage id="household.primaryLanguage" children={(formattedMessage) => <span className="form-label">{formattedMessage}</span>} />
                <div className="household-form-left-row">
                    <Field name="primaryLanguage" component={renderSelectList} data={languageOptions} validate={[required]} disabled={readonly} />
                    {primaryLanguage === Language.Other ? (
                        <Field
                            name="otherLanguage"
                            className="household-form-other-field"
                            type="text"
                            component={renderField}
                            placeholder={intl.formatMessage({ id: "household.otherLanguage" })}
                            validate={[required]}
                            disabled={readonly}
                        />
                    ) : null}
                </div>
                <FormattedMessage
                    id="household.hasHomeComputer"
                    defaultMessage="[Do you have a home computer?]"
                    children={(formattedMessage) => <span className="form-label">{formattedMessage}</span>}
                />
                <Field
                    id="hhHasComputer"
                    name="hasHomeComputer"
                    component={renderRadioField}
                    className="household-form-radio"
                    items={[
                        { value: "1", text: yes },
                        { value: "2", text: no },
                    ]}
                    validate={[required]}
                    disabled={readonly}
                />
                <FormattedMessage
                    id="household.hasInternetAccess"
                    defaultMessage="[Do you have internet access?]"
                    children={(formattedMessage) => <span className="form-label">{formattedMessage}</span>}
                />
                <Field
                    id="hhHasInternet"
                    name="hasInternet"
                    component={renderRadioField}
                    className="household-form-radio"
                    items={[
                        { value: "1", text: yes },
                        { value: "2", text: no },
                    ]}
                    validate={[required]}
                    disabled={readonly}
                />
                <div style={{ flex: 1 }} />
            </div>
        );
    }
}
Left.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
    primaryLanguage: PropTypes.number,
};

export default injectIntl(Left);
