import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import FontAwesome from "react-fontawesome";
import { toast } from "react-toastify";
import { sendCollegeIDReminderText, sendFerpaReminderText } from "../../api/message";
import Spinner from "react-spinkit";

import { searchEligibleCrusaders } from "../../api/scholarshipCertification";
import { getTemplateBody } from "../../api/template";
import useAjaxStatus from "../../common/useAjaxStatus";
import { formatSSN } from "../../scholarship/support/formatStrings";
import { COLLEGE_ID_REMINDER_TEXT, FERPA_REMINDER_TEXT } from "../../support/constants";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";

import "./crusaders.css";

const CrusaderSearchResult = ({ data, isHeader }) => {
    return (
        <div className={`row ${isHeader ? "crusader-header" : undefined} ${!data.mobilePhone ? "error" : undefined}`}>
            <span className="col lastname">{data.lastName}</span>
            <span className="col firstname">{data.firstName}</span>
            <span className="col ssn">{!isHeader ? formatSSN(data.ssn) : data.ssn}</span>
            <span className="col crusaderid">{data.crusaderId}</span>
            <span className="col collegeid">{data.collegeId || "-"}</span>
            <span className="col ferpa">{!isHeader ? (data.hasSignedFerpa ? "Yes" : "No") : data.hasSignedFerpa}</span>
            <span className="col mobilephone">{data.mobilePhone || "Unknown"}</span>
        </div>
    );
};

const Crusaders = ({ ajaxStatusComplete, ajaxStatusLoading, scholarship }) => {
    const [crusaders, setCrusaders] = useState([]);
    const [query, setQuery] = useState(null);
    const [sending, setSending] = useState(false);
    const [showCreateTextMessage, setShowCreateTextMessage] = useState(false);
    const [messageTemplate, setMessageTemplate] = useState("");
    const { ajaxLoad, ajaxComplete } = useAjaxStatus();

    useEffect(() => {
        (async () => {
            if (!scholarship) {
                setQuery(null);
                return;
            }
            if (query) {
                try {
                    ajaxStatusLoading();
                    const response = await searchEligibleCrusaders({ ...query, scholarshipId: scholarship.id });
                    const templateResponse = await getTemplateBody(query.hasCollegeId === false ? COLLEGE_ID_REMINDER_TEXT : FERPA_REMINDER_TEXT);
                    setCrusaders(response.data.result);
                    setMessageTemplate(templateResponse.data.result ? templateResponse.data.result.replace(/\n/g, "<br />") : "");
                } finally {
                    ajaxStatusComplete();
                }
            } else {
                setCrusaders([]);
            }
        })();
    }, [ajaxStatusComplete, ajaxStatusLoading, query, scholarship]);

    const handleSendCollegeId = async () => {
        try {
            setSending(true);
            ajaxLoad();
            if (query.hasCollegeId === false) {
                await sendCollegeIDReminderText(scholarship.id);
            } else {
                await sendFerpaReminderText(scholarship.id);
            }
            toast.success("Text message(s) sent successfully");
            setCrusaders([]);
            setQuery(null);
        } catch (ex) {
            console.error(ex);
            toast.error("Failed to send");
        } finally {
            setShowCreateTextMessage(false);
            setSending(false);
            ajaxComplete();
        }
    };

    return (
        <div className="crusaders">
            <div className="button-container">
                <button className="btn btn-small" onClick={() => setQuery({ hasCollegeId: false })}>
                    View Students Missing College ID
                </button>
                <button className="btn btn-small ml" onClick={() => setQuery({ hasSignedFerpaForm: false })}>
                    View Students Missing FERPA
                </button>
            </div>
            {crusaders.length > 0 && (
                <div className="results-wrapper">
                    <div>
                        <strong>Total Crusaders:&nbsp;</strong>
                        {crusaders.length}
                    </div>
                    {query && (
                        <div style={{ fontStyle: "italic", fontSize: 15, marginBottom: 10 }}>
                            Includes Eligible Students with a Selected Institution and {query.hasCollegeId === false ? "Missing College ID" : "Missing FERPA"}
                        </div>
                    )}
                    <CrusaderSearchResult
                        isHeader
                        data={{
                            lastName: "Last Name",
                            firstName: "First Name",
                            ssn: "SSN",
                            crusaderId: "Onward ID",
                            collegeId: "College ID",
                            hasSignedFerpa: "Signed Ferpa?",
                            mobilePhone: "Mobile Phone #",
                        }}
                    />
                    <div className="scrollable">
                        {crusaders.map((row, index) => {
                            return <CrusaderSearchResult key={row.ssn + index} data={row} />;
                        })}
                    </div>
                    <div className="action-container">
                        <button className="btn btn-small" onClick={() => setShowCreateTextMessage(true)}>
                            <FontAwesome name="mobile" size="2x" className="btn-icon" color="white" />
                            Send Reminder Text
                        </button>
                    </div>
                </div>
            )}
            <SweetAlert
                show={showCreateTextMessage}
                title="Send Text"
                showCancel
                allowEscape={false}
                closeOnClickOutside={false}
                onConfirm={handleSendCollegeId}
                customButtons={
                    <>
                        {sending && <Spinner name="line-scale" color="#3cc6e8" fadeIn="none" />}
                        <button className="btn btn-small" style={{ marginRight: 5 }} onClick={() => setShowCreateTextMessage(false)} disabled={sending}>
                            No, cancel
                        </button>
                        <button className="btn btn-small" onClick={handleSendCollegeId} disabled={sending}>
                            Yes, send text
                        </button>
                    </>
                }
            >
                <div>
                    <h3>{`Are you sure you wish to send a text message to ${crusaders.filter((r) => r.mobilePhone).length} student(s)`}</h3>
                    <div style={{ fontSize: 15 }}>
                        <div style={{ marginTop: 10, fontWeight: 600 }}>Message Text:</div>
                        <div style={{ fontStyle: "italic", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: messageTemplate }} />
                    </div>
                </div>
            </SweetAlert>
        </div>
    );
};

export default withAjaxStatus(Crusaders);
