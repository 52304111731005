import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import School from './components/School';
import * as apis from './apis';
import { setStaffMiddleSchools } from '../../../redux/codeTables';

import withAjaxStatus from '../../../support/hoc/withAjaxStatus';

import './index.css';

class Settings extends Component {
    state = {
        ViewAll: false,
        schools: []
    };
    componentDidMount = async () => {
        this.props.ajaxStatusLoading();
        const schools = (await apis.GetSchools()).data.result;
        const viewAll = this.doShowAll(schools);
        this.setState({
            schools,
            viewAll
        });
        this.props.ajaxStatusComplete();
    };
    componentWillUnmount = () => {
        this.doUpdateRedux(this.state.schools);
    };
    onSchoolClick = async (school, index) => {
        const newSchool = { ...school };
        const method = !school.isSelected ? 'addSchool' : 'removeSchool';
        const schools = [...this.state.schools];
        newSchool.isSelected = !newSchool.isSelected;
        schools[index] = newSchool;
        const viewAll = this.doShowAll(schools);
        this.setState({
            schools,
            viewAll
        });
        await apis[method](school.schoolId);
    };
    onToggleViewAll = async () => {
        const isShow = true;
        const schools = [...this.state.schools];
        for (let index = 0, len = schools.length; index < len; index++) {
            schools[index].isSelected = isShow;
        }
        this.setState({
            viewAll: isShow,
            schools
        });
        await apis.toggleAllSchools(isShow);
    };
    doShowAll = schools => {
        const showAll = schools.find(school => {
            return !school.isSelected;
        });

        return !showAll;
    };
    doUpdateRedux = schools => {
        const selected = schools.filter(school => school.isSelected);
        const staffSchools = selected.map(school => {
            return {
                id: school.id,
                name: school.schoolName
            };
        });

        this.props.setStaffMiddleSchools(staffSchools);
    };
    render() {
        const clsName = this.state.viewAll ? 'view-all school selected' : 'view-all school unselected';
        return (
            <div className="settings">
                <div className="top">
                    <h3>SETTINGS</h3>
                    <div style={{ flex: 1 }} />
                    <button onClick={this.props.onClose}>
                        <FontAwesome name="times" size="2x" onClick={this.props.onClose} />
                    </button>
                </div>
                <span className="form-label">Select the schools that you would like to see in all administrative tables and grids.</span>
                <button className={clsName} onClick={this.onToggleViewAll}>
                    View All Schools
                </button>
                <div className="schools">
                    {this.state.schools.map((school, index) => {
                        return <School key={school.schoolId} school={school} onClick={this.onSchoolClick.bind(this, school, index)} />;
                    })}
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    onClose: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        setStaffMiddleSchools: bindActionCreators(setStaffMiddleSchools, dispatch)
    };
};

Settings = withAjaxStatus(Settings);
Settings = connect(
    null,
    mapDispatchToProps
)(Settings);

export default Settings;
