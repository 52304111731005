import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import * as ApplicationApi from "../../api/applications";
import withAdmissionSettings from "../../support/hoc/withAdmissionSettings";
import withLocale from "../../support/hoc/withLocale";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import withAdmissions from "../../support/hoc/withAdmissions";
import ContactInfo from "../common/contactInfo";
import { AdmissionsTabs, ApplicationStatus } from "../logic/enums";
import OrientatioCompleteAlert from "../common/orientationCompleteAlert";
import VideoCompleteAlert from "../common/videoCompleteAlert";

import "./index.css";

const OrientationVideo = ({ intl, locale, admissionSettings, match, ajaxStatusLoading, ajaxStatusComplete, admissions, loadApplication, loadIdentifiers }) => {
    const [showOrientationComplete, setShowOrientationComplete] = useState(false);
    const [showVideoComplete, setShowVideoComplete] = useState(false);

    const buildVideoLink = (vimeoLink) => {
        if (vimeoLink === null || vimeoLink === "") {
            return "";
        }
        const videoId = vimeoLink.trim().split("https://vimeo.com/")[1];
        return `https://player.vimeo.com/video/${videoId}?autoplay=1`;
    };

    const onCheckChange = async (event) => {
        try {
            const checked = event.target.checked;
            ajaxStatusLoading();
            const orientationComplete = (await ApplicationApi.confirmOrientationVideo(admissions.id, checked)).data.result;
            if (match && match.params.id) {
                loadApplication(admissions.id);
            } else {
                loadIdentifiers();
            }
            if (orientationComplete) {
                setShowOrientationComplete(true);
            } else {
                if (checked) {
                    setShowVideoComplete(true);
                }
            }
        } finally {
            ajaxStatusComplete();
        }
    };

    const videoLink = buildVideoLink(locale === "en" ? admissionSettings.orientationVideoEnglish : admissionSettings.orientationVideoSpanish);
    const isDesktop = useMediaQuery("(min-width: 600px)");
    const [showInstructions, setShowInstructions] = useState(false);

    return (
        <div className="orientation-video" style={{ padding: isDesktop ? "0 10px 0 0" : 10, marginTop: isDesktop ? 0 : 160 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!isDesktop && (
                        <Link className="show-instructions-link" component="button" onClick={() => setShowInstructions((prev) => !prev)}>
                            {showInstructions
                                ? intl.formatMessage({ id: "app.hideInstructions", defaultMessage: "[Hide Instructions]" })
                                : intl.formatMessage({ id: "app.showInstructions", defaultMessage: "[Show Instructions]" })}
                        </Link>
                    )}
                    {!isDesktop && showInstructions && (
                        <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                            <ContactInfo />
                        </div>
                    )}
                    <div className="hr-line-medium" />
                    <FormattedMessage id="nav.orientationvideo" defaultMessage="[Orientation Video]" tagName="h2" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="column">
                        <iframe
                            title="orientation video"
                            width="100%"
                            height={isDesktop ? 360 : undefined}
                            frameBorder={0}
                            allowFullScreen
                            allow="autoplay; fullscreen;"
                            src={videoLink}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="orientation_video__instructions">
                        <FormattedMessage id="orientationvideo.subhead" defaultMessage="[Orientation Video Subhead]" />
                        <div className="orientation-video__check-container">
                            <label>
                                <input
                                    type="checkbox"
                                    className="orientation-video__checkbox"
                                    onChange={onCheckChange}
                                    disabled={admissions && admissions.status !== ApplicationStatus.VirtualOrientation}
                                    defaultChecked={admissions && admissions.progress.tabProgress[AdmissionsTabs.OrientationVideo]}
                                />
                                <span>
                                    <FormattedMessage id="orientationvideo.checkbox" defaultMessage="[orientationvideo.checkbox]" />
                                </span>
                            </label>
                        </div>
                    </div>
                </Grid>
                <Hidden only="xs">
                    <Grid item xs={12} sm={3}>
                        <div className="instructions">
                            <ContactInfo />
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
            <OrientatioCompleteAlert show={showOrientationComplete} onConfirm={() => setShowOrientationComplete(false)} />
            <VideoCompleteAlert show={showVideoComplete} onConfirm={() => setShowVideoComplete(false)} />
        </div>
    );
};

export default withAdmissions(withAjaxStatus(withAdmissionSettings(withLocale(OrientationVideo))));
