import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";

import "./Cash.css";

const Cash = ({ data, isActive, onSelect }) => {
  const date = moment.utc(data.date).format("M/D/YY");
  const clsName = isActive ? "cash cash-active" : "cash";
  return (
    <div className={clsName} onClick={onSelect} onKeyDown={() => {}} tabIndex="0" role="button">
      <div className="row">
        <span>CASH REQUESTS</span>
        <div className="spacer" /> <span>As of {date}</span>
      </div>
      <div className="row-spacer" />
      <div className="line" />
      <div className="row">
        <span>Requested</span>
        <div className="spacer" />
        <span>{formatCurrencyNoDecimal(data.requested)}</span>
      </div>
      <div className="row">
        <span>Refunded</span>
        <div className="spacer" />
        <span>{formatCurrencyNoDecimal(data.refunded)}</span>
      </div>
      <div className="row">
        <span>Paid</span>
        <div className="spacer" />
        <span>{formatCurrencyNoDecimal(data.paid)}</span>
      </div>

      <div className="line" />
      <div className="row">
        <span>Outstanding Due</span>
        <div className="spacer" />
        <span>{formatCurrencyNoDecimal(data.outstanding)}</span>
      </div>
    </div>
  );
};

Cash.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    requested: PropTypes.number,
    refunded: PropTypes.number,
    paid: PropTypes.number,
    outstanding: PropTypes.number
  })
};

export default Cash;
