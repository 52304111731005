import React from "react";
import { injectIntl } from "react-intl";

let IntlOption = ({ intl, id, defaultMessage, value }) => {
  return <option value={value}>{intl.formatMessage({ id, defaultMessage })}</option>;
};

IntlOption = injectIntl(IntlOption);

export const educationLevels = [
  {
    value: "",
    label: <IntlOption key="app.select-educationlevel" id="app.select" defaultMessage="[-select-]" value="" />,
  },
  {
    value: "1",
    label: <IntlOption key="parent.educationLevel1" id="parent.educationLevel1" defaultMessage="[one]" value="1" />,
  },
  {
    value: "2",
    label: <IntlOption key="parent.educationLevel2" id="parent.educationLevel2" defaultMessage="[two]" value="2" />,
  },
  {
    value: "3",
    label: <IntlOption key="parent.educationLevel3" id="parent.educationLevel3" defaultMessage="[three]" value="3" />,
  },
  {
    value: "4",
    label: <IntlOption key="parent.educationLevel4" id="parent.educationLevel4" defaultMessage="[four]" value="4" />,
  },
  {
    value: "5",
    label: <IntlOption key="parent.educationLevel5" id="parent.educationLevel5" defaultMessage="[five]" value="5" />,
  },
  {
    value: "6",
    label: <IntlOption key="parent.educationLevel6" id="parent.educationLevel6" defaultMessage="[six]" value="6" />,
  },
  {
    value: "7",
    label: <IntlOption key="parent.educationLevel7" id="parent.educationLevel7" defaultMessage="[seven]" value="7" />,
  },
];
