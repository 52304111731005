import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { searchAllPossibleParticipants } from "../../api/programEvents";
import useDebounce from "../../../common/useDebounce";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    selectedParticipantsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(4, 0, 4, 0),
        gap: theme.spacing(1),
    },
    inputContainer: {
        display: "flex",
        alignSelf: "stretch",
        gap: theme.spacing(1),
    },
    addBtn: {
        color: "#fff",
        fontWeight: "bold",
        padding: theme.spacing(1, 2),
    },
}));

const AddParticipantsModal = ({ currentOverrides, currentEligibleParticipants, onClose, onSubmit, ...rest }) => {
    const classes = useStyles();
    const [allPossibleParticipants, setAllPossibleParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState([{}]);
    const [inputText, setInputText] = useState("");
    const debouncedInputText = useDebounce(inputText, 200);

    const getAllPossibleParticipantsAsync = useCallback(
        async (searchText) => {
            setLoading(true);
            try {
                const response = await searchAllPossibleParticipants(searchText);
                const filteredPossibleParticipants = response.data.result.filter((participant) => {
                    const isSelected = selectedParticipants.some((selectedParticipant) => selectedParticipant?.id === participant.id);
                    const isInCurrentEligibleParticipants = currentEligibleParticipants.some((c) => c.id === participant.id);
                    const isCurrentOverride = currentOverrides.some((c) => c.participant.id === participant.id);
                    return !isSelected && !isInCurrentEligibleParticipants && !isCurrentOverride;
                });
                setAllPossibleParticipants(filteredPossibleParticipants);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [selectedParticipants, currentEligibleParticipants, currentOverrides]
    );

    useEffect(() => {
        if (debouncedInputText?.trim()?.length > 3) {
            getAllPossibleParticipantsAsync(debouncedInputText);
        }
    }, [debouncedInputText, getAllPossibleParticipantsAsync]);

    const handleClose = () => {
        setSelectedParticipants([{ name: "" }]);
        onClose();
    };

    const removeSelectedParticipant = (index) => {
        setSelectedParticipants([...selectedParticipants.slice(0, index), ...selectedParticipants.slice(index + 1)]);
    };

    const handleSubmit = () => onSubmit(selectedParticipants);

    return (
        <Dialog onClose={handleClose} {...rest}>
            <DialogTitle>
                Add Eligible Participants
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">Participants added to the eligible list and override eligibility rules.</Typography>
                <div className={classes.selectedParticipantsContainer}>
                    {selectedParticipants.map((participant, index) => (
                        <div key={`participant-${index}`} className={classes.inputContainer}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={allPossibleParticipants}
                                    loading={loading}
                                    fullWidth
                                    getOptionLabel={(option) =>
                                        (option?.id && `${option.firstName} ${option.lastName} (${option.crusaderId || option.email})`) || ""
                                    }
                                    autoComplete
                                    freeSolo
                                    value={selectedParticipants[index]}
                                    onInputChange={(event, nextValue, reason) => {
                                        if (reason === "input") setInputText(nextValue);
                                    }}
                                    onChange={(event, newValue) => {
                                        const newParticipants = [...selectedParticipants];
                                        newParticipants[index] = newValue;
                                        setSelectedParticipants(newParticipants);
                                    }}
                                    renderInput={(params) => <TextField {...params} placeholder="Participant name" variant="outlined" margin="dense" />}
                                />
                            </FormControl>
                            <IconButton
                                aria-label="remove"
                                onClick={(e) => {
                                    e.preventDefault();
                                    removeSelectedParticipant(index);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                    <Button
                        className={classes.addBtn}
                        startIcon={<AddIcon />}
                        onClick={(e) => {
                            e.preventDefault();
                            setAllPossibleParticipants([]);
                            setSelectedParticipants([...selectedParticipants, { name: "" }]);
                        }}
                    >
                        Add
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        handleSubmit();
                        handleClose();
                    }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddParticipantsModal;
