import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import enumHelper from "../../../support/logic/enumHelper";
import { UserType } from "../../../support/enums";
import Link from "@material-ui/core/Link";

const EligibleParticipantTable = ({ participantList, profBase }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getProfileLink = (participant) => {
        let base = profBase;
        if (participant.crusaderId == null) {
            base = base.replace("/crusader/", "/parent/").replace("/student/", "/parent/");
        }
        return `${base}${participant.id}`;
    };

    return (
        <div>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>User Type</TableCell>
                        <TableCell>School</TableCell>
                        <TableCell>Grade</TableCell>
                        <TableCell>Service Hours</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Profile</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {participantList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((participant, index) => (
                        <TableRow hover key={participant.crusaderId || `participant-${index}`}>
                            <TableCell>{participant.firstName}</TableCell>
                            <TableCell>{participant.lastName}</TableCell>
                            <TableCell>{enumHelper.getDisplayName(UserType, participant.userType)}</TableCell>
                            <TableCell>{participant.school}</TableCell>
                            <TableCell>{participant.grade}</TableCell>
                            <TableCell>{participant.serviceHours}</TableCell>
                            <TableCell>{participant.crusaderId}</TableCell>
                            <TableCell>
                                <Link className={"dashboardLink"} href={getProfileLink(participant)}>
                                    Profile
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={participantList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default EligibleParticipantTable;
