import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Field } from "react-final-form";
import { composeValidators, isTime, maxLength, required } from "../../../common/formValidators";
import { FinalFormDropdown, FinalFormTextField } from "../../../common/formWrappers";
import { TimeMaskInput } from "../../../common/masks";

const useStyles = makeStyles((theme) => ({
    busStopFormItem: {},
    formControl: {
        margin: 0,
    },
    timeField: {
        width: 85,
        marginRight: theme.spacing(0.5),
    },
}));

const BusStopFormItem = ({ field, amPmOptions, onDelete }) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell>
                <Field
                    className={classes.formControl}
                    component={FinalFormTextField}
                    name={`${field}.name`}
                    placeholder="Name"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    validate={composeValidators(required, maxLength(500))}
                />
            </TableCell>
            <TableCell>
                <Box display="flex">
                    <Field
                        className={classes.formControl}
                        inputClassName={classes.timeField}
                        component={FinalFormTextField}
                        name={`${field}.pickupTime`}
                        variant="outlined"
                        margin="dense"
                        validate={composeValidators(required, isTime)}
                        inputComponent={TimeMaskInput}
                    />
                    <Field
                        className={classes.formControl}
                        component={FinalFormDropdown}
                        name={`${field}.pickupTimeAmPm`}
                        variant="outlined"
                        margin="dense"
                        choices={amPmOptions}
                        validate={required}
                    />
                </Box>
            </TableCell>
            <TableCell>
                <Box>
                    <Field
                        className={classes.formControl}
                        inputClassName={classes.timeField}
                        component={FinalFormTextField}
                        name={`${field}.dropoffTime`}
                        variant="outlined"
                        margin="dense"
                        validate={composeValidators(required, isTime)}
                        inputComponent={TimeMaskInput}
                    />
                    <Field
                        className={classes.formControl}
                        component={FinalFormDropdown}
                        name={`${field}.dropoffTimeAmPm`}
                        variant="outlined"
                        margin="dense"
                        choices={amPmOptions}
                        validate={required}
                    />
                </Box>
            </TableCell>
            <TableCell>
                <Field
                    className={classes.formControl}
                    component={FinalFormTextField}
                    name={`${field}.address`}
                    placeholder="Address"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    validate={composeValidators(required, maxLength(500))}
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={onDelete} size="small">
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default BusStopFormItem;
