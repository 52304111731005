import React, { Component } from "react";
import * as propTypes from "../../propTypes";
import { reduxForm } from "redux-form";
import moment from "moment";

import Refund from "./components/refund";
import Refunds from "./components/refunds";
import StudentList from "./components/studentList";

import { getAllRefunds, getStudentRefunds, createRefundTransactionRequest, receiveRefund } from "./apis";

import withAjaxStatus from "../../../support/hoc/withAjaxStatus";

import "./index.css";
import { transactionRequestType } from "../../enums";

class ReceiveRefund extends Component {
  state = {
    selectedRefundId: null,
    selectedInstitutionId: null,
    refunds: [],
    studentList: []
  };

  componentDidMount() {
    this.loadRefunds();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scholarship !== prevProps.scholarship) {
      this.setState(
        {
          selectedRefundId: null,
          selectedInstitutionId: null,
          studentList: []
        },
        () => {
          this.loadRefunds();
        }
      );
    }
  }

  async loadRefunds() {
    if (!this.props.scholarship) {
      return;
    }

    try {
      this.props.ajaxStatusLoading();
      const refunds = (await getAllRefunds(this.props.scholarship.id)).data.result;
      this.setState({ refunds });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  }

  async loadStudents() {
    const refund = this.getSelectedRefund();
    const id = refund.isUnassigned ? null : refund.id;
    try {
      this.props.ajaxStatusLoading();
      const data = (await getStudentRefunds(this.props.scholarship.id, id, this.state.selectedInstitutionId, !refund.isUnassigned)).data.result;
      this.setState({ studentList: data });
      this.props.ajaxStatusComplete();
    } catch (err) {
      this.props.ajaxStatusError(err);
    }
  }

  onSaveRefund = async values => {
    const refund = this.getSelectedRefund();
    const input = {
      institutionId: refund.institutionId,
      scholarshipId: this.props.scholarship.id,
      checkNumber: values.checkNumber,
      checkDate: values.checkDate.format("MM/DD/YYYY"),
      refundRecievedDate: values.receivedOn.format("MM/DD/YYYY"),
      receiptNote: values.receivedNotes,
      type: transactionRequestType.refund
    };
    try {
      if (refund.isUnassigned) {
        this.props.ajaxStatusLoading();
        await createRefundTransactionRequest(input);
      } else {
        input.id = refund.id;
        await receiveRefund(input);
      }
    } catch (err) {
      this.props.ajaxStatusError(err);
    } finally {
      this.props.ajaxStatusComplete();
    }
    this.setState(
      {
        selectedRefundId: null,
        selectedInstitutionId: null,
        studentList: []
      },
      () => this.loadRefunds()
    );
  };

  handleRefundClick = item => {
    const id = item.id;

    this.setState({ selectedRefundId: id, selectedInstitutionId: item.institutionId }, () => {
      const refund = this.getSelectedRefund();
      if (refund.checkNumber) {
        this.props.initialize({
          checkNumber: refund.checkNumber,
          checkDate: moment.utc(refund.checkDate),
          receivedOn: moment.utc(refund.receivedOn),
          receivedNotes: refund.receivedNotes
        });
      } else {
        this.props.initialize({
          checkNumber: null,
          checkDate: null,
          receivedOn: null,
          receivedNotes: null
        });
      }

      this.loadStudents();
    });
  };

  getRefundsTdProps = (state, rowInfo, column, instance) => {
    if (!rowInfo) {
      return {};
    }

    return {
      onClick: e => this.handleRefundClick(rowInfo.original),
      style: {
        backgroundColor: rowInfo.original.id === this.state.selectedRefundId ? "#f0f2cc" : undefined
      }
    };
  };

  getSelectedRefund() {
    return this.state.refunds.find(item => item.id === this.state.selectedRefundId);
  }

  render() {
    const { selectedRefundId, refunds, studentList } = this.state;
    const selectedRefund = this.getSelectedRefund();

    return (
      <div className="scholarship-recieve-refund">
        <div className="scholarship-recieve-refund-top">
          <Refunds data={refunds} getTdProps={this.getRefundsTdProps} />
          {selectedRefundId === null ? (
            <div className="noInstitutionSelected">Select a refund</div>
          ) : (
            <Refund onSave={this.onSaveRefund} refund={selectedRefund} />
          )}
        </div>
        <div className="scholarship-recieve-refund-bottom">
          {selectedRefund ? <h2 className="selected-institution">{selectedRefund.schoolName}</h2> : null}
          <StudentList data={studentList} />
        </div>
      </div>
    );
  }
}

ReceiveRefund.propTypes = {
  scholarship: propTypes.scholarship
};

ReceiveRefund = reduxForm({ form: "ScholarshipReceiveRefund" })(ReceiveRefund);

ReceiveRefund = withAjaxStatus(ReceiveRefund);

export default ReceiveRefund;
