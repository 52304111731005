import React, { Component, Fragment } from "react";
import { Form, Field } from "react-final-form";
import LoadingButton from "../../common/loadingButton";
import { Prompt} from "react-router-dom";
import { FinalFormCheckbox, FinalFormTextField, FinalFormMultiSelectChipDropdown } from "../../common/formWrappers";
import * as api from "../../api/tagging";
import "./index.css";
import Modal from "react-modal";
import SimpleDialog from "../../common/simpleDialog";
import Classification from "./classification";
import { required } from "../../common/formValidators";
import Button from "../../support/components/Button";



class Tagging extends Component {
    state = {
        availableTags: [],
        showAddForm: false,
        showDistributeForm: false,
        acTags: [],
        activeClass: [],
        activeAdd: [], 
        iPeds: []
    };
    componentDidMount = async () => {
        let avTags = await api.returnChoices(this.props.tagFilter);
        let ipeds = await api.getIpeds();
        let acTags = await this.getSelected();

        if (this.props.onTagLoad && typeof this.props.onTagLoad === 'function') {
            this.props.onTagLoad(acTags);
            //this.setState({ acTags: this.tagsFromProps() });
        }
       
        this.setState({ availableTags: avTags.data.result, acTags: acTags, iPeds: ipeds.data.result });
    };

    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps.activeTags != this.props.activeTags) {
            const acTags = this.tagsFromProps();
            this.setState({ acTags: acTags });
            if (this.props.onTagLoad && typeof this.props.onTagLoad === 'function') {
                this.props.onTagLoad(acTags);
                //this.setState({ acTags: this.tagsFromProps() });
            }
        }

    }


    showAdd = () => {
        this.setState({ showAddForm: true });
    }

    hideAdd = () => {
        this.setState({ showAddForm: false, activeClass: [], activeAdd: [] });

        
    }

    showDist = () => {
        this.setState({ showDistributeForm: true });
    }

    hideDist = () => {
        this.setState({ showDistributeForm: false });
    }

    getSelected = async () => {
        if (this.props.activeTags && this.props.activeTags.length) {
            return this.tagsFromProps();

        } else {
            if (this.props.mode == "Event") {
                const input = { Id: this.props.id };
                const activeTags = await api.returnEventTags(input);
                return activeTags.data.result;
            }
            if (this.props.mode == "Advisor") {
                const input = { Id: this.props.id };
                const activeTags = await api.returnAdvisorTags(input);

                return activeTags.data.result;
            }
        }

    }
    removeTag = async (input) => {
        if (this.props.onRemoveTag && typeof this.props.onRemoveTag === 'function') {
            this.props.onRemoveTag(input);
          //  this.setState({ acTags: this.tagsFromProps() });
        } else {
            if (this.props.mode == "Event") {
                await api.removeEventTag(input);

                const data = { Id: this.props.id };
                const activeTags = await api.returnEventTags(data);
                if (this.props.onTagLoad && typeof this.props.onTagLoad === 'function') {
                    this.props.onTagLoad(activeTags.data.result);
                    //this.setState({ acTags: this.tagsFromProps() });
                }
                this.setState({ acTags: activeTags.data.result });

            }
            if (this.props.mode == "Advisor") {
                await api.removeAdvisorTag(input);

                const data = { Id: this.props.id };
                const activeTags = await api.returnAdvisorTags(data);
                if (this.props.onTagLoad && typeof this.props.onTagLoad === 'function') {
                    this.props.onTagLoad(activeTags.data.result);
                    //this.setState({ acTags: this.tagsFromProps() });
                }
                this.setState({ acTags: activeTags.data.result });

            }

        }
        if (this.props.onChange && typeof this.props.onChange === 'function') {
            this.props.onChange(true);

        }
        
    }
    setCat = (cat) => {
        
        this.setState({activeClass: cat.classification, activeAdd: cat.dropList});
    }
    updateTag = async (values) => {
        
        const input = {
            itemId: values.id,
            TagsId: values.tagId,
            SubtagId: values.subtagId,
            weight: values.weight,
            IPEDNo: values.ipedNo,
            Descriptor: values.descriptor

        }
        if (this.props.onUpdateTag && typeof this.props.onUpdateTag === 'function') {
            this.props.onUpdateTag(input);
           // this.setState({ acTags: this.tagsFromProps() });
        } else {
            if (this.props.mode == "Event") {
                await api.updateEventTag(input);
            }
            if (this.props.mode == "Advisor") {
                await api.updateAdvisorTag(input);
            }
            var acTags = await this.getSelected();
            if (this.props.onTagLoad && typeof this.props.onTagLoad === 'function') {
                this.props.onTagLoad(acTags);
                //this.setState({ acTags: this.tagsFromProps() });
            }
            if (this.props.onChange && typeof this.props.onChange === 'function') {
                this.props.onChange(true);

            }
            this.setState({ acTags: acTags });
        }

    }
    
    handleSubmit = async (values, form) =>{
        
        
        let tId = '';
        let stId = '';
        let itemId = this.props.id;
        let wt = 0;
        try {
            const acc = this.state.acTags.filter((e) => e.classification.id === this.state.activeClass.id);
            
            if (acc.length === 0) {
                wt = 1;
            }
        } catch (ex) {
            wt = 1;
        }
        
        if (values.tag.indexOf("|")) {
            const vs = values.tag.split("|");
            tId = vs[0];
            stId = vs[1];

        } else {
            tId = values.tag;
        }
       const input = {
           itemId: itemId,
           tagsId: tId,
           subtagId: stId,
           weight: wt
        }
       
        if (this.props.onAddTag && typeof this.props.onAddTag === 'function') {
            this.props.onAddTag(input);
            //this.setState({ acTags: this.tagsFromProps() });
        } else {
            if (this.props.mode == "Event") {
                await api.addEventTag(input);
            }
            if (this.props.mode == "Advisor") {
                await api.addAdvisorTag(input);
            }

            var acTags = await this.getSelected();


            if (this.props.onChange && typeof this.props.onChange === 'function') {
                this.props.onChange(true);
               
            }
            this.setState({ acTags: acTags });
        }
    }

    tagsFromProps = () => {
        
        // Get the activeTags from props
        const activeTags = this.props.activeTags;
        
        // Get the availableTags from state
        const availableTags = this.state.availableTags;

        // Map through availableTags to filter the tags sub-array
        const filteredTags = availableTags.map(classification => {

            let nTags = [];
            
            activeTags.forEach(at => {
                
                let tag = [];
                if (at.subtagId == null || at.subtagId == '00000000-0000-0000-0000-000000000000') {
                    tag = classification.tags.find(fnd => fnd.tagId == at.tagsId);
                } else {
                    tag = classification.tags.find(fnd => fnd.tagId == at.tagsId && fnd.subtagId == at.subtagId);
                }
                
                if (tag) {
                    nTags.push({ ...tag, weight: at.weight, Descriptor: at.Descriptor, IPEDNo: at.IPEDNo });
                }

            
            });


            

            
            return {
                ...classification,
                tags: nTags
            };
        }).filter(availableTag => availableTag.tags.length > 0); 
       



        return filteredTags;
    }
    render() {
        
        return (
            <div>
                <div className="taggingControl"> <LoadingButton
                    wrapperClassName={"test"}
                    className={"test"}
                    variant="contained"
                    color="primary"
                    onClick={this.showAdd}
                    disabled={this.props.disabled }
                >
                    Add Tag
                </LoadingButton>
                    &nbsp;<br /><br />
                    
                </div>


                {this.state.acTags.map((cat) => {

                    return (

                        <Classification cat={cat} onTagRemove={this.removeTag} ipeds={this.state.iPeds} disabled={this.props.disabled} updateTag={this.updateTag} />
                        

                    );

                })}
                <SimpleDialog open={this.state.showAddForm} onClose={ this.hideAdd} title="Add Tag" >
                
                 <div className="MessagingVisible" >
                            
                    <Form onSubmit={this.handleSubmit} initialValues={this.state.activeClassification}>
                        {({ handleSubmit, values, dirty, errors }) => {
                           
                            return (
                                <form onSubmit={handleSubmit} FormValues={this.state.activeClassification } noValidate className="test">
                                    <Prompt
                                        when={dirty}
                                        message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"}
                                    />
                                    <div className={"test"}>
                                        <Field name="id" component="input" type="hidden" />
                                        <span className={"dialogLabel" }>Choose Classification</span>
                                        {this.state.availableTags.map((cat) => {
                                            
                                            const classification = cat.classification;
                                            return (

                                                <button type={"button"} style={{ background: classification.color, color: "white", fontWeight: '800', margin: '10px'}} onClick={() => this.setCat(cat)} > &nbsp; {classification.classification} &nbsp;
               
                                                </button> 

                                                


                                            );

                                        })}<br />


                                        
                                        

                                        
                                        <table>
                                            <tr>
                                                <td>
                                                    <Field disabled={this.props.disabled} name="tag" component="select" style={{ background: this.state.activeClass.color, color: "white" }}>
                                                        <option value="">Select an option</option>
                                                        {this.state.activeAdd.map((option) => {
                                                            return (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.label}
                                                                </option>
                                                            );

                                                        })}

                                                    </Field>
                                                </td>
                                                <td>
                                                    <LoadingButton
                                                        wrapperClassName={"test"}
                                                        className={"test"}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        disabled={this.props.disabled}
                                                    >
                                                        Add
                                                    </LoadingButton>
                                                </td>
                                            </tr>
                                        </table>
                                          
                                                   
                                          

                                        
                                        
                                    </div>
                                </form>
                            );
                        }}
                    </Form>
                </div>
                    {this.state.acTags.map((cat) => {

                        return (

                            <Classification cat={cat} onTagRemove={this.removeTag} ipeds={this.state.iPeds} updateTag={this.updateTag} disabled={this.props.disabled} />


                        );

                    })}
                </SimpleDialog>

                
            </div>
        );
    }

}
export default Tagging;