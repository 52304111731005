import PropTypes from "prop-types";

export const student = PropTypes.shape({
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.Date,
  gender: PropTypes.string.isRequired,
  ssnAlien: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired
});

export const scholarshipCrusaderDto = PropTypes.shape({
  id: PropTypes.string.isRequired,
  institutionId: PropTypes.string.isRequired,
  scholarshipId: PropTypes.string.isRequired,
  userProfileId: PropTypes.string.isRequired,
  isScholarshipOpen: PropTypes.bool.isRequired,
  institutionName: PropTypes.string.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  period: PropTypes.number.isRequired,
  scholarshipLevel: PropTypes.number.isRequired,
  isEligible: PropTypes.bool.isRequired,
  grade: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  termStructure: PropTypes.number.isRequired,
  certificationReason: PropTypes.number.isRequired,
  cash: PropTypes.number.isRequired,
  donation: PropTypes.isRequired
});

export const amounts = PropTypes.shape({
  gold: PropTypes.number.isRequired,
  silver: PropTypes.number.isRequired,
  bronze: PropTypes.number.isRequired,
  freshmanAmount: PropTypes.number,
  sophomoreAmount: PropTypes.number,
  juniorAmount: PropTypes.number,
  seniorAmount: PropTypes.number
});

export const scholarship = PropTypes.shape({
  id: PropTypes.string.isRequired,
  endYear: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired
});
