import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Started from "./started";
import StaffLayout from "../common/staffLayout";

class StartedContainer extends React.Component {
    static permission = "Applications.Started";

    componentDidMount() {
        this.props.loadData();
    }

    getTrProps = (state, rowInfo) => {
        return {
            onClick: (e) => {
                this.rowClick(rowInfo.original);
            },
        }; 
    };

    rowClick = (row) => {
        window.previousLocation = "started";
        this.props.history.push(`/applications/application/${row.applicationId}/s/${row.userProfileId}`);
    };

    getSchoolData(schoolName) {
        return this.props.data.filter((d) => d.school === schoolName);
    }

    renderSchools() {
        return this.props.schools.map((school, index) => (
            <Started school={school.name} data={this.getSchoolData(school.name)} key={index} getTrProps={this.getTrProps} />
        ));
    }

    render() {
        const { showAll } = this.props;
        const totalStarted = this.props.data.length;
        const unassociatedData = this.getSchoolData("");

        return (
            <StaffLayout>
                <div className="filter-area">
                    <button onClick={this.props.onShowAllClick}>{showAll ? "Show ONLY Started Applications" : "Show All Applications"}</button>
                    <h3>Total Started: {totalStarted}</h3>
                </div>
                <br />
                {this.renderSchools()}
                {unassociatedData.length > 0 && <Started school="Unassociated" data={unassociatedData} getTrProps={this.getTrProps} />}
            </StaffLayout>
        );
    }
}

StartedContainer.propTypes = {
    showAll: PropTypes.bool.isRequired,
    onShowAllClick: PropTypes.func.isRequired,
    data: PropTypes.array,
    schools: PropTypes.array,
};
const mapStateToProps = (state) => ({
    schools: state.staffMiddleSchools,
});

StartedContainer = connect(mapStateToProps)(StartedContainer);
StartedContainer = withRouter(StartedContainer);

export default StartedContainer;
