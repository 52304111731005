import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box } from "@material-ui/core";
import { useLocation, useParams } from "react-router";
import { exportRegisteredUsers, getRegisteredUsers } from "../api/programEvents";
import SimpleDialog from "../../common/simpleDialog";
import EnrollmentSettings from "./components/enrollmentSettings";
import UnenrollmentDialog from "./components/unenrollmentDialog";
import LoadingIndicator from "../../common/loadingIndicator";
import { UserType } from "../../support/enums";
import enumHelper from "../../support/logic/enumHelper";
import useAjaxStatus from "../../common/useAjaxStatus";
import b64toBlob from "../../support/logic/b64toBlob";
import LoadingButton from "../../common/loadingButton";
import EventHeader from "../common/eventHeader/eventHeader";
import moment from "moment";
import useProgramEvent from "../hooks/useProgramEvent";
import { makeStyles } from "@material-ui/styles";
import { getData } from "../../api/documents";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    subHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    participantsCounter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "large",
    },
    unenrollAction: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "large",
        color: "blue",
        cursor: "pointer",
    },
}));

const EnrollmentList = ({topUser }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const {
        state: {
            rowData: { enrollmentCapacity, id, programName, date },
        },
    } = useLocation();
    const { id: eventId } = useParams();
    const [readOnly, setReadOnly] = useState(false);
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
    const [isUnenrollDialogOpen, setIsUnenrollDialogOpen] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [downloading, setDownloading] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [enrollmentCapacityAux, setEnrollmentCapacityAux] = useState(enrollmentCapacity);
    const { programEvent, loading: loadingProgramEvent } = useProgramEvent(eventId);
    

  

    const enrollmentListColumns = [
        {
            field: "firstName",
            headerName: "First name",
            flex: 1,
        },
        {
            field: "lastName",
            headerName: "Last name",
            flex: 1,
        },
        {
            field: "userType",
            headerName: "User Type",
            flex: 1,
            valueFormatter: ({ value }) => {
                return enumHelper.getDisplayName(UserType, value);
            },
        },
        {
            field: "crusaderId",
            headerName: "Crusader Id",
            flex: 1,
        },
        {
            field: "currentGrade",
            headerName: "Grade",
            flex: 1,
        },
        {
            field: "dateAdded",
            headerName: "Sign-up Time",
            width: 250,
        },
        {
            field: "permissionSlipDocumentId",
            headerName: "Permission Slip",
            width: 250,
            renderCell: ({ value }) => {
                return (
                    <LoadingButton
                        loading={downloading !== null && downloading === value}
                        disabled={value === null}
                        color="primary"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            handlePermissionSlipDownload(value);
                        }}
                    >
                        Download
                    </LoadingButton>
                );
            },
        },
        {
            field: "userProfileId",
            headerName: "Profile",
            width: 150,
            renderCell: ({ id, row }) => (
                <Button
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(row.userType === UserType.Crusader ? `/admin/users/crusader/${id}` : `/admin/users/parent/${id}`, "_blank");
                    }}
                >
                    Profile
                </Button>
            ),
        },
    ];

    const handlePermissionSlipDownload = async (id) => {
        try {
            setDownloading(id);
            const result = (await getData(id)).data.result;
            const blob = b64toBlob(result.data, result.mimeType);
            FileSaver.saveAs(blob, result.fileName);
        } catch (ex) {
        } finally {
            setDownloading(null);
        }
    };

    const openSettingsDialog = () => setIsSettingsDialogOpen(true);
    const closeSettingsDialog = () => setIsSettingsDialogOpen(false);
    const openUnenrollDialog = () => setIsUnenrollDialogOpen(true);
    const closeUnenrollDialog = () => setIsUnenrollDialogOpen(false);

    const getRows = () => {
        return registeredUsers.map((item) => {
            return {
                ...item,
                id: item.userProfileId,
                dateAdded: `${moment(item.dateAdded).format("M/D/YYYY")} ${moment(item.dateAdded).format("h:mm a")}`,
            };
        });
    };

    const handleExport = async () => {
        try {
            setExporting(true);
            const resp = await exportRegisteredUsers(eventId, id);
            const blob = b64toBlob(resp.data.result.data, resp.data.result.mimeType);
            FileSaver.saveAs(blob, resp.data.result.fileName);
            setExporting(false);
        } catch (err) {
            setExporting(false);
            ajaxErrorWrapper(err);
        }
    };
    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    useEffect(() => {
        getRegisteredUsers(eventId, id)
            .then((resp) => {
                setRegisteredUsers(resp.data.result);
                setIsLoading(false);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, eventId, id]);

    if (isLoading) {
        return <LoadingIndicator loading={isLoading} />;
    }
    //console.log(id);
    return (
        <>
            <EventHeader mainTitle={programName} subTitle="Enrollment List" date={date} />
            <div className={classes.subHeader}>
                {selectedUsers.length > 0 ? (
                    <Button color="primary" onClick={openUnenrollDialog} disabled={readOnly }>
                        Unenroll
                    </Button>
                ) : (
                    <span className={classes.participantsCounter}>
                        {registeredUsers.length}/{enrollmentCapacityAux || 0}
                    </span>
                )}
                <Box display="flex">
                    <Button onClick={openSettingsDialog} className={classes.button} variant="outlined" color="primary">
                        Settings
                    </Button>
                    <LoadingButton className={classes.button} variant="outlined" color="primary" onClick={handleExport} loading={exporting}>
                        Export
                    </LoadingButton>
                </Box>
            </div>
            <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                    rows={getRows()}
                    columns={enrollmentListColumns}
                    checkboxSelection
                    onSelectionModelChange={(values) => {
                        const _selectedUsers = registeredUsers.filter((user) => values.includes(user.userProfileId));
                        setSelectedUsers(_selectedUsers);
                    }}
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    disableColumnMenu
                    disableColumnFilter
                    autoHeight
                />
            </div>

            <SimpleDialog maxWidth="sm" open={isSettingsDialogOpen} onClose={closeSettingsDialog} title="Enrollment Settings">
                <EnrollmentSettings
                    closeSettingsDialog={closeSettingsDialog}
                    programEventDateId={id}
                    registeredUsersCount={registeredUsers.length}
                    setEnrollmentCapacityAux={setEnrollmentCapacityAux}
                    disabled={readOnly }
                />
            </SimpleDialog>

            <UnenrollmentDialog
                isOpen={isUnenrollDialogOpen}
                closeUnenrollmentDialog={closeUnenrollDialog}
                selectedUsers={selectedUsers}
                setRegisteredUsers={setRegisteredUsers}
            />
        </>
    );
};

export default EnrollmentList;
