import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { formatCurrencyNoDecimal } from "../../../support/numberSupport";
import * as enums from "../../enums";
import * as propTypes from "../../propTypes";
import * as apis from "../../apis";
import TermPayoutEntry from "./TermPayoutEntry";
import UserConfirmation from "../../../support/components/userConfirm";

import "./TermPayout.css";
import { AllocationType } from "../../enums";

const getTermName = (id) => {
    const terms = enums.termType;
    if (id === terms.trimester) {
        return "Trimester";
    }
    if (id === terms.tradeSchool) {
        return "Trade Program";
    }
    return "Semester";
};

const getStatusName = (id) => {
    const statusCodes = enums.crusaderStatus;
    let value = "";
    switch (id) {
        case statusCodes.fullTime:
            value = "Full Time";
            break;
        case statusCodes.threeQuarterTime:
            value = "Three Quarter Time";
            break;
        case statusCodes.halfTime:
            value = "Half Time";
            break;
        case statusCodes.lessThanHalfTime:
            value = "Less than Half Time";
            break;
        case statusCodes.notEnrolled:
            value = "Not Enrolled";
            break;
        default:
            value = "unknown";
    }
    return value;
};

const getGradeName = (id) => {
    const grades = enums.crusaderGrade;
    let value = "";
    switch (id) {
        case grades.freshman:
            value = "Freshman";
            break;
        case grades.sophomore:
            value = "Sophomore";
            break;
        case grades.junior:
            value = "Junior";
            break;
        case grades.senior:
            value = "Senior";
            break;
        default:
            value = "unknown";
    }
    return value;
};

class TermPayout extends Component {
    index = 0;
    state = {
        schools: [],
        showConfirm: false,
        confirmMessage: "",
        showDropdown: true,
    };

    componentDidMount = async () => {
        await this.doLoadData();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.student !== prevProps.student) {
            this.doLoadData();
        }
    }

    onNewTermSelected = (e) => {
        const id = parseInt(e.target.value, 10);
        this.newTermOption = this.newTermOptions.find((term) => {
            return term.id === id;
        });
        this.index = this.newTermOptions.indexOf(this.newTermOption);
    };

    onAddTerm = () => {
        if (this.index !== 0 && this.newTermOption.termStructure != 3) {
            const termName = getTermName(this.newTermOption.termStructure);
            const text1 = `${termName} ${this.newTermOption.period}`;
            const text2 = `${termName} ${this.newTermOptions[0].period}`;
            const message = `Are you sure you wish to add ${text1} before ${text2}?`;
            this.setState({
                showConfirm: true,
                confirmMessage: message,
            });
            return;
        }
        this.doAddNewTerm();
    };

    onSave = async (term, complete) => {
        const record = { ...term };
        const student = this.props.student;
        record.id = typeof record.id === "number" ? null : record.id;
        record.grade = record.grade === 999 ? 1 : record.grade;
        record.userProfileId = this.props.student.id;
        record.cash = this.doFormatAmountForSave(record.cash);
        record.donation = this.doFormatAmountForSave(record.donation);

        try {
            this.props.ajaxStatusLoading();
            const results = await apis.saveStudentCertifyRecord(record);
            const id = results.data.result;
            complete(id);
            this.props.onLoadCounts();
            await this.doLoadData();
            toast.success("Save Complete");
            this.props.ajaxStatusComplete();
            if (term.period == 4) {
                this.setState({ showDropdown: false });
            } else {
                this.setState({ showDropdown: true });
            }
            
            
        } catch (err) {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                this.props.ajaxStatusError(err.response.data.error.message);
            } else {
                this.props.ajaxStatusError("Error saving student certify data");
            }
        }
    };

    onCancel = async () => {
        await this.doLoadData(true);
        this.props.onTermAddRemoved(false);
    };

    doFormatAmountForSave = (amount) => {
        if (!amount) return 0;
        const num = amount.replace(/\D/g, "");
        if (!num) return 0;
        return parseInt(num, 10);
    };

    doLoadData = async (isCancel) => {
        if (!this.props.student) return;

        try {
            this.props.ajaxStatusLoading();
            const results = (await apis.getStudentCertificationStatus(this.props.student.id, this.props.scholarship.id)).data.result;

            this.setState({
                certifyData: results,
                schools: results.institutionTerms,
                showDropdown: !results.isAt8Semesters,
                
            });
            this.props.afterSave(results);
            this.props.ajaxStatusComplete();
            
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving student certify data");
        }
    };

    doAddNewTerm = () => {
        const certifyData = this.state.certifyData;
        const schools = [...this.state.schools];
        const { scholarship } = this.props;
        const termOption = this.newTermOption;
        const term = {
            cash: 0,
            certificationReason: 1,
            donation: 0,
            endYear: scholarship.endYear,
            grade: 999,
            id: new Date().getMilliseconds(), //for react keys
            institutionId: certifyData.assignedInstitutionId,
            institutionName: certifyData.assignedInstitutionName,
            isEligible: true,
            isScholarshipOpen: true,
            level: certifyData.crusaderScholarshipLevel,
            period: termOption.period,
            scholarshipId: scholarship.id,
            startYear: scholarship.startYear,
            status: 1,
            certificationTermStructure: certifyData.assignedInstitutionTermStructure,
            termStructure: certifyData.assignedInstitutionTermStructure,
            allocationType: certifyData.assignedInstitutionScholarshipAllocationType,
            perStudentDonationAmount: certifyData.perStudentDonationAmount,
            isNewAdd: true,
        };

        if (schools.length > 0 && schools[0].institutionId === certifyData.assignedInstitutionId) {
            schools[0] = { ...schools[0] };
            schools[0].institutionTermDetails.splice(0, 0, term);
        } else {
            schools.splice(0, 0, {
                institutionId: certifyData.assignedInstitutionId,
                institutionName: certifyData.assignedInstitutionName,
                institutionTermStructure: certifyData.assignedInstitutionTermStructure,
                institutionTermDetails: [term],
                institutionScholarshipAllocationType: certifyData.assignedInstitutionScholarshipAllocationType,
            });
        }
        this.newTermOptions = null;
        this.newTermOption = null;
        this.setState({ schools, showDropdown: false });

        if (termOption.period > 1) {
            this.props.onTermAddRemoved(true, termOption.period);
        }

    };

    doRenderHeader = () => {
        this.newTermOptions = this.doCreateTerms();

        if (!this.newTermOption) {
            this.newTermOption = this.newTermOptions.length > 0 ? this.newTermOptions[0] : null;
        }

        const { certifyData } = this.state;
        const name = certifyData ? certifyData.assignedInstitutionName : "";

        if (!name || this.newTermOptions.length === 0) return null;

        if (this.newTermOptions.length === 0) {
            return (
                <div className="term-payout-header">
                    <h2>{name}</h2>
                </div>
            );
        }

        return (
            <div className="term-payout-header">
                {this.state.showDropdown ? (
                    <Fragment>
                        <h2>{name}</h2>

                        <div className="term-payout-header-sub">
                            <span>Term or Trade Program</span>
                            <select onChange={this.onNewTermSelected.bind(this)}>
                                {this.newTermOptions.map((term, index) => {
                                    const termName = getTermName(term.termStructure);
                                    let text = `${term.startYear}-${term.endYear}: ${termName} ${term.period}`;
                                    if (term.period == 4) {
                                        text = `${term.startYear}-${term.endYear}: ${termName}`;
                                    }
                                    return (
                                        <option key={term.id} value={term.id}>
                                            {text}
                                        </option>
                                    );
                                })}
                            </select>
                            <button className="btn" onClick={this.onAddTerm.bind(this)}>
                                Add Term
                            </button>
                        </div>
                    </Fragment>
                ) : null}
            </div>
        );
    };

    doCreateTerms = () => {
        const { scholarship } = this.props;
        const { certifyData } = this.state;

        if (!certifyData) {
            return [];
        }
        
        let retVal =  certifyData.availablePeriodsForCertification.map((item) => ({
            id: item,
            startYear: scholarship.startYear,
            endYear: scholarship.endYear,
            termStructure: certifyData.assignedInstitutionTermStructure,
            period: item,
        }));
        retVal.push({
            id: 4,
            startYear: scholarship.startYear,
            endYear: scholarship.endYear,
            termStructure: 3,
            period: 4,
        });
        
        return retVal;
    };

    doRender = (term) => {
        const { certifyData } = this.state;
        if (term.isNewAdd || (term.isScholarshipOpen && certifyData.assignedInstitutionId === term.institutionId)) {
            return this.doRenderEdit(term);
        }
        return this.doRenderReadonly(term);
    };

    doRenderReadonly = (term) => {
       
        let typeName = term.certificationTermStructure === 1 ? "Trimester" : "Semester";
        if (term.certificationTermStructure === 3) {
            typeName = "Trade Program";
        }
        return (
            <div key={term.id} className="term-payout-readonly-row">
                <div className="term-readonly-entry">
                    <span className="term-readonly-label">Term</span>
                    <span>
                        {term.startYear}-{term.endYear} {typeName} {term.period}
                    </span>
                </div>
                <div className="term-readonly-entry">
                    <span className="term-readonly-label">Status</span>
                    <span>{getStatusName(term.status)}</span>
                </div>
                <div className="term-readonly-entry">
                    <span>{getGradeName(term.grade)}</span>
                </div>
                <div className="term-readonly-entry">
                    {term.allocationType === AllocationType.CashAndDonated ? (
                        <>
                            <span className="term-readonly-label">Donated</span>
                            <span>{formatCurrencyNoDecimal(term.donation)}</span>
                            <span className="term-readonly-label second">Cash</span>
                            <span>{formatCurrencyNoDecimal(term.cash)}</span>
                        </>
                    ) : (
                        <>
                            <span className="term-readonly-label">{term.donation ? "Donated" : "Cash"}</span>
                            <span>{formatCurrencyNoDecimal(term.donation ? term.donation : term.cash)}</span>
                        </>
                    )}
                </div>
            </div>
        );
    };

    doRenderEdit = (term) => {
        return (
            <TermPayoutEntry
                key={term.id}
                term={term}
                amounts={this.props.amounts}
                onSave={this.onSave.bind(this)}
                onCancel={this.onCancel.bind(this)}
                yearOfGraduation={this.props.student.yearOfGraduation}
                maxAllotment={this.props.maxAllot }
            />
        );
    };

    render() {
        return (
            <div className="term-payout-container">
                {this.doRenderHeader()}
                {this.state.schools.map((school, index) => {
                    return (
                        <div key={index} className="term-payout-entry-container">
                            <h2>{school.institutionName}</h2>

                            {school.institutionTermDetails &&
                                school.institutionTermDetails.map((term) => {
                                    return this.doRender(term);
                                })}
                        </div>
                    );
                })}
                {this.state.showConfirm ? (
                    <UserConfirmation
                        message={this.state.confirmMessage}
                        title="Confirm"
                        yes="Yes"
                        cancel="No"
                        onCancel={() => this.setState({ showConfirm: false })}
                        onConfirm={this.doAddNewTerm}
                    />
                ) : null}
            </div>
        );
    }
}

TermPayout.propTypes = {
    student: propTypes.student,
    amounts: propTypes.amounts,
    scholarship: propTypes.scholarship,
    onLoadCounts: PropTypes.func.isRequired,
    onTermAddRemoved: PropTypes.func.isRequired,
};

TermPayout = withAjaxStatus(TermPayout);

export default TermPayout;
