import axios from 'axios';

export function removeSchool(schoolId) {
    return axios.delete('/api/services/app/setting/RemoveSchool', { params: { schoolId } });
}
export function addSchool(schoolId) {
    return axios.post('/api/services/app/setting/AddSchool', { schoolId });
}
export function toggleAllSchools(isShow) {
    return axios.post('/api/services/app/setting/ToggleAllSchools', { isShow });
}
export function GetSchools() {
    return axios.get('/api/services/app/setting/GetSchools');
}
