import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { toast } from "react-toastify";

import DescriptionDialog from "../components/descriptionDialog";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { createOrUpdateInvitation, updateEventDescription, updateEventPermissionSlip } from "../../api/programEvents";
import PermissionSlipDialog from "../components/permissionSlipDialog";
import InvitationDialog from "../components/invitationDialog";
import OpenEnrollmentConfirmationDialog from "../components/openEnrollmentConfirmationDialog";
import { EventStatus, ProgramEventDateWaitlistResponseDuration } from "../../enums";

const useStyles = makeStyles((theme) => ({
    eventRecruitment: {
        padding: theme.spacing(1, 0),
    },
    navButton: {
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.warning.main,
        borderRadius: "50%",
    },
    iconComplete: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
    },
    recruitButton: {
        padding: theme.spacing(1, 8),
    },
}));

const EventRecruitmentStep = ({ programEvent, setProgramEvent, isDescriptionMissing, isInvitationMissing, isPermissionSlipLanguageMissing, onNextStep, disabled}) => {
    const classes = useStyles();
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [permissionSlipOpen, setPermissionSlipOpen] = useState(false);
    const [invitationOpen, setInvitationOpen] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [enrollCap, setEnrollCap] = useState(1000);
    const [waitCap, setWaitCap] = useState(1000);
    const [waitDur, setWaitDur] = useState(ProgramEventDateWaitlistResponseDuration.Days);
    const [waitTime, setWaitTime] = useState(5);
    const { ajaxLoad, ajaxComplete, ajaxErrorWrapper } = useAjaxStatus();
    //console.log(programEvent);


   

    if (programEvent.eventDates.length > 0) {
        if (programEvent.eventDates[0].enrollmentCapacity > 0 && programEvent.eventDates[0].enrollmentCapacity != enrollCap) {
            setEnrollCap(programEvent.eventDates[0].enrollmentCapacity);
        }
        if (programEvent.eventDates[0].waitlistCapacity > 0 && programEvent.eventDates[0].waitlistCapacity != waitCap ) {
            setWaitCap(programEvent.eventDates[0].waitlistCapacity);
        }
        if (programEvent.eventDates[0].waitlistResponseDuration > 0 && programEvent.eventDates[0].waitlistResponseDuration != waitDur) {
            setWaitDur(programEvent.eventDates[0].waitlistResponseDuration);
        }
        if (programEvent.eventDates[0].waitlistResponseTime > 0 && programEvent.eventDates[0].waitlistResponseTime != waitTime) {
           setWaitTime(programEvent.eventDates[0].waitlistResponseTime);
        }
    }
    //console.log(waitCap)

    const handleSaveDescription = async (values) => {
        try {
            ajaxLoad();
            await updateEventDescription({ programEventId: programEvent.id, ...values });
            setProgramEvent((prev) => ({ ...prev, ...values }));
            setDescriptionOpen(false);
            toast.success("Changes saved successfully");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };

    const handleSavePermissionSlip = async (values) => {
        try {
            ajaxLoad();
            await updateEventPermissionSlip({ programEventId: programEvent.id, ...values });
            setProgramEvent((prev) => ({ ...prev, ...values }));
            setPermissionSlipOpen(false);
            toast.success("Changes saved successfully");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };

    const handleSaveInvitation = async (values) => {
        try {
            ajaxLoad();
            const response = await createOrUpdateInvitation({ programEventId: programEvent.id, ...values });
            setProgramEvent((prev) => ({ ...prev, invitation: response.data.result }));
            setInvitationOpen(false);
            toast.success("Changes saved successfully");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            ajaxComplete();
        }
    };

    return (
        <Box className={classes.eventRecruitment} display="flex" flexDirection="column" alignItems="flex-start">
            <Button
                onClick={() => setDescriptionOpen(true)}
                className={classes.navButton}
                startIcon={isDescriptionMissing ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />}
            >
                Event Description
            </Button>
            <Button
                onClick={() => setInvitationOpen(true)}
                className={classes.navButton}
                startIcon={isInvitationMissing ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />}
            >
                Invitation Message
            </Button>
            <Button
                onClick={() => setPermissionSlipOpen(true)}
                className={classes.navButton}
                startIcon={
                    isPermissionSlipLanguageMissing ? (
                        <PriorityHighRoundedIcon className={classes.icon} />
                    ) : (
                        <CheckRoundedIcon className={classes.iconComplete} />
                    )
                }
            >
                Permission Slip Language
            </Button>
            {programEvent && programEvent.status === EventStatus.Recruitment && (
                <Button
                    className={classes.recruitButton}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setShowConfirm(true)}
                    disabled={isDescriptionMissing || isInvitationMissing || isPermissionSlipLanguageMissing}
                >
                    Open Enrollment
                </Button>
            )}

            <DescriptionDialog programEvent={programEvent} open={descriptionOpen} onClose={() => setDescriptionOpen(false)} onSave={handleSaveDescription} disabled={disabled} />
            <PermissionSlipDialog
                programEvent={programEvent}
                open={permissionSlipOpen}
                onClose={() => setPermissionSlipOpen(false)}
                onSave={handleSavePermissionSlip}
                disabled={disabled} 
            />
            <InvitationDialog programEvent={programEvent} open={invitationOpen} onClose={() => setInvitationOpen(false)} onSave={handleSaveInvitation} disabled={disabled} />
            <OpenEnrollmentConfirmationDialog
                onSave={(values) => onNextStep(values)}
                onClose={() => setShowConfirm(false)}
                open={showConfirm}
                programEvent={programEvent}
                enrollmentCap={enrollCap}
                waitlistCap={waitCap}
                waitlistResponseTime={waitTime}
                waitlistResponseDuration={waitDur}
                disabled={disabled} 
            />
        </Box>
    );
};

export default EventRecruitmentStep;
