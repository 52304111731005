import axios from 'axios';

export const get = () => {
    return axios.get('/api/services/app/Family/Get');
};

export const getByApplicationId = applicationId => {
    return axios.get('/api/services/app/Family/GetByApplication', { params: { applicationId } });
};

export const update = data => {
    return axios.put('/api/services/app/Family/Update', data);
};
