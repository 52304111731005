import React from "react";
import ReactTable from "react-table";
import moment from "moment";

import Checkbox from "../../../support/components/Checkbox";

import enumHelper from "../../../support/logic/enumHelper";
import { Gender } from "../../../admissions/logic/enums";

import "./Complete.css";

const Complete = ({
  data,
  getTrProps,
  getHeaderProps,
  school,
  onUnregister,
  onAttended
}) => (
  <div className="school-table complete-school-table">
    <h2>{school}</h2>
    {data && data.length === 0 && (
      <div>No currently complete applications for this school.</div>
    )}
    {data && data.length > 0 && (
      <ReactTable
        getTdProps={getTrProps}
        data={data}
        minRows={0}
        columns={[
          {
            Header: "Last",
            accessor: "lastName",
            minWidth: 150,
            Cell: props => {
              const d = props.original;
              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              const text = d.lastName;
              return <span className={style}>{text}</span>;
            },
            id: "lastName"
          },
          {
            Header: "First",
            accessor: "firstName",
            minWidth: 200,
            Cell: props => {
              const d = props.original;
              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              const text = `${d.firstName} ${d.middleName}`;
              return <span className={style}>{text}</span>;
            }
          },
          {
            Header: "DOB",
            accessor: "dateOfBirth",
            headerClassName: "complete-row-header",
            minWidth: 85,
            maxWidth: 85,
            Cell: props => {
              const d = props.original;
              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              const dob = moment(d.dateOfBirth)
                .utc()
                .format("M/D/YYYY");
              return <span className={style}>{dob}</span>;
            }
          },
          {
            Header: "Gender",
            accessor: "gender",
            headerClassName: "complete-row-header",
            minWidth: 100,
            maxWidth: 100,
            Cell: props => {
              const d = props.original;
              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              const gender = enumHelper.getDisplayName(Gender, d.gender);
              return <span className={style}>{gender}</span>;
            },
            sortMethod: (a, b) => {
              if (a === b) return 0;
              if (a === 0 && b === 1) return 1;
              if (a === 1 && b === 0) return -1;
              if (a === null) return -1;
              if (b === null) return 1;
              return a > b ? 1 : -1;
            }
          },
          {
            Header: "Lunch",
            headerClassName: "complete-row-header",
            minWidth: 85,
            maxWidth: 85,
            id: "isLunchPending",
            accessor: d => d.isLunchPending,
            Cell: props => {
              const d = props.original;
              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              const data =
                props.original.isLunchPending === true ? "Pending" : "";

              return (
                <div className="complete-column">
                  <span className={style}>{data}</span>
                </div>
              );
            }
          },
          {
            Header: "Invited",
            accessor: "invited",
            resizable: false,
            headerClassName: "complete-row-header",
            minWidth: 65,
            maxWidth: 65,
            Cell: props => {
              if (!props.value) return null;
              return (
                <div className="complete-column">
                  <Checkbox isChecked />
                </div>
              );
            }
          },

          {
            Header: "Accepted",
            accessor: "accepted",
            resizable: false,
            headerClassName: "complete-row-header",
            minWidth: 85,
            maxWidth: 85,
            Cell: props => {
              if (!props.value) return null;
              return (
                <div className="complete-column">
                  <Checkbox isChecked />
                </div>
              );
            }
          },
          {
            Header: "Attended",
            accessor: "attended",
            resizable: false,
            headerClassName: "complete-row-header",
            minWidth: 85,
            maxWidth: 85,
            Cell: props => {
              const record = props.original;
              if (!record.accepted) return null;

              //if (!props.value) {
              return (
                <div className="complete-column">
                  <Checkbox
                    isChecked={props.value}
                    showUnchecked
                    onClick={evt => {
                      evt.stopPropagation();
                      onAttended(record.applicationId);
                    }}
                  />
                </div>
              );
              //}

              // return (
              //     <div className="complete-column">
              //         <Checkbox isChecked />
              //     </div>
              // );
            }
          },
          {
            Header: "Rejected",
            accessor: "isRejected",
            resizable: false,
            headerClassName: "complete-row-header",
            minWidth: 85,
            maxWidth: 85,
            Cell: props => {
              if (props.original.isRejected !== true) return null;
              return (
                <div className="complete-column">
                  <Checkbox isChecked />
                </div>
              );
            }
          },
          {
            Header: "Orientation",
            id: "startDate",
            accessor: "startDate",
            minWidth: 250,
            maxWidth: 350,
            Cell: props => {
              const d = props.original;
              const venue = d.venue;
              const address = venue ? venue.address : "";
              let displayText = "";
              if (venue && venue.startDateTime) {
                const startDateTime = moment(venue.startDateTime)
                  .utc()
                  .format("M/D/YY hh:mm a");
                const endTime = moment(venue.endDateTime)
                  .utc()
                  .format("hh:mm a");
                displayText = venue.startDateTime
                  ? `${startDateTime} - ${endTime}`
                  : "";
              }

              const style = d.updateLast24Hours
                ? "complete-school-table-row-24hours"
                : "";
              return (
                <div className={style}>
                  <div>{displayText}</div>
                  <div>{address}</div>
                </div>
              );
            }
          },
          {
            Header: "",
            id: "unregister",
            accessor: "id",
            sortable: false,
            minWidth: 157,
            maxWidth: 157,
            Cell: props => {
              const d = props.original;
              const venue = d.venue;

              if (!venue || !venue.startDateTime || d.attended) {
                return "";
              }

              return (
                <button
                  className="btn"
                  onClick={evt => {
                    evt.stopPropagation();
                    onUnregister(d.applicationId);
                  }}
                >
                  Unregister
                </button>
              );
            }
          }
        ]}
        showPagination={false}
        defaultPageSize={500}
        className="-highlight"
      />
    )}
  </div>
);

export default Complete;
