import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";

const Districts = ({ data, onAdd, readOnly }) => {
    return (
        <>
            <div className="admin-school-header">
                <button onClick={onAdd} disabled={readOnly}>
                    <FontAwesome name="plus" />
                    <span className="title">ADD DISTRICT</span>
                </button>
            </div>

            <div className={`admin-institution-list`}>
                <ReactTable
                    data={data}
                    minRows={0}
                    columns={[
                        {
                            Header: "District Name",
                            accessor: "name",
                            minWidth: 150,
                        },
                        {
                            Header: "",
                            accessor: "id",
                            minWidth: 30,
                            Cell: (row) => {
                                const url = `/admin/middle-high/districts/${row.value}`;
                                return <Link to={url}>Edit</Link>;
                            },
                        },
                    ]}
                    defaultPageSize={50}
                    className="-striped -highlight"
                />
            </div>
        </>
    );
};

export default Districts;
