import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import FileSaver from "file-saver";
import { injectIntl, FormattedMessage } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";

import { ApplicationStatusContext } from "../../support/context";
import b64toBlob from "../../support/logic/b64toBlob";
import * as DocumentsApi from "../../api/documents";
import Button from "../../support/components/Button";
import DropArea from "../../support/components/dropArea";
import Dropzonify from "../../support/components/dropzonify";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import withAdmissions from "../../support/hoc/withAdmissions";
import ExifImage from "../../support/components/exifImage";

import "./documentSection.css";
import { GroupType } from "../logic/enums";

class DocumentSection extends Component {
    state = {
        showDropZone: false,
        documents: [],
        showConfirmDelete: false,
        documentToDelete: null,
    };

    componentDidMount() {
        this.loadDocuments(this.props.groupId);
        
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.groupId !== nextProps.groupId || this.props.reloadData !== nextProps.reloadData) {
            this.loadDocuments(nextProps.groupId);
        }
    }

    async loadDocuments(groupId) {
        if (groupId === undefined || groupId === "") {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const documents = (await DocumentsApi.getListByGrouping(groupId, this.props.groupType)).data.result;
            this.setState({ documents }, () => {
                this.props.ajaxStatusComplete();
            });
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    onDocumentChange() {
        
            this.props.loadApplication(this.props.admissions.id);
        
    }

    handleClose = () => {
        this.setState({ showDropZone: false });
    };

    handleAddClick = () => {
        this.setState({ showDropZone: !this.state.showDropZone });
    };

    onUploadComplete = () => {
        this.loadDocuments(this.props.groupId);
        this.onDocumentChange();
    };

    onUploadStarted = () => {};

    handleDocumentClick = (document) => {
        if (document.thumbnail === "") {
            this.downloadDocument(document);
            return;
        }

        this.downloadImage(document);
    };

    async downloadImage(document) {
        window.open(`/viewimage/${document.id}`, "_blank");
    }

    async downloadDocument(document) {
        try {
            this.props.ajaxStatusLoading();
            const response = (await DocumentsApi.getData(document.id)).data.result;
            this.props.ajaxStatusComplete();
            const blob = b64toBlob(response.data, response.mimeType);
            FileSaver.saveAs(blob, document.fileName);
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    handleConfirmDelete = () => {
        this.setState({ showConfirmDelete: false }, async () => {
            try {
                this.props.ajaxStatusLoading();
                DocumentsApi.remove(this.state.documentToDelete).then(() => {
                    this.loadDocuments(this.props.groupId);
                    this.onDocumentChange();
                    this.props.ajaxStatusComplete();
                });
            } catch (err) {
                this.props.ajaxStatusError(err);
            }
        });
    };

    handleCancelDelete = () => {
        this.setState({ showConfirmDelete: false });
    };

    handleRemoveDocument = (document) => {
        this.setState({ showConfirmDelete: true, documentToDelete: document.id });
    };

    renderDocument(document) {
        return (
            <div key={document.id} className="document">
                <div role="button" onClick={() => this.handleDocumentClick(document)} onKeyPress={() => this.handleDocumentClick(document)} tabIndex="0">
                    {document.thumbnail !== "" && <ExifImage base64Data={document.thumbnail} mimeType="image/jpeg" title={document.fileName} />}
                    {document.thumbnail === "" && <FontAwesome name="file-text" title={document.fileName} />}
                </div>
                {!this.props.readonly && (
                    <span role="button" onClick={() => this.handleRemoveDocument(document)} onKeyPress={() => this.handleRemoveDocument(document)} tabIndex="0">
                        <FormattedMessage id="documents.remove" defaultMessage="[Remove]" />
                    </span>
                )}
            </div>
        );
    }

    renderDocuments() {
        return <div className="image-container">{this.state.documents.map((document) => this.renderDocument(document))}</div>;
    }

    renderActionAreaStaff() {
        const { description, staffValid, onStaffValidChange, readonly, hideValid } = this.props;
        return (
            <div className="document-table">
                <div className="document-cell-1 document-description">{description()}</div>
                <div className="document-cell-2">
                    {this.state.documents.length > 0 ? <input type="checkbox" checked={true} disabled /> : <input type="checkbox" disabled checked={false} />}
                </div>
                <div className="document-cell-3">
                    <div className="document-cell-pending">
                        <Button id="documents.add" defaultMessage="[Add]" onClick={this.handleAddClick} invalid={readonly} />
                    </div>
                    {this.renderDocuments()}
                </div>
                <div className="document-cell-4">
                    {!hideValid && <input type="checkbox" checked={staffValid} onChange={onStaffValidChange} disabled={readonly} />}
                </div>
            </div>
        );
    }

    renderActionArea() {
        const { description, readonly } = this.props;

        return (
            <div>
                <div className="document-description">{description()}</div>
                <div className="documents-right">
                    <Button id="documents.add" defaultMessage="[Add]" onClick={this.handleAddClick} invalid={readonly} />
                    {this.renderDocuments()}
                </div>
            </div>
        );
    }

    closeImageModal = () => {
        this.setState({
            showImageModal: false,
        });
    };

    render() {
        const { groupId, groupType, accessToken, readonly, isStaff, applicationId } = this.props;
        const { showConfirmDelete, documents } = this.state;

        if (groupType === GroupType.PersonalStatement && documents.length === 0) {
            return null;
        }

        return (
            <ApplicationStatusContext.Consumer>
                {(context) => (
                    <div className="document-section">
                        {isStaff ? this.renderActionAreaStaff() : this.renderActionArea()}
                        <div>
                            {this.state.showDropZone && !readonly && (
                                <Dropzonify
                                    accessToken={accessToken}
                                    accept=""
                                    uploadUrl="/api/services/app/documents/upload"
                                    disabled={readonly}
                                    onUploadComplete={this.onUploadComplete}
                                    onUploadStarted={this.onUploadStarted}
                                    additionalUploadParameters={{ groupId, groupType, applicationId }}
                                >
                                    {({ uploadingFiles, onBrowse, dragging }) => (
                                        <DropArea onClose={this.handleClose} onBrowse={onBrowse} dragging={dragging} uploadingFiles={uploadingFiles} />
                                    )}
                                </Dropzonify>
                            )}
                        </div>
                        {documents.length < 1 && context.isApplicationSubmitted && !context.isApplicationValid && (
                            <FormattedMessage
                                id="documents.required"
                                defaultMessage="[The document is required]"
                                children={(formattedMessage) => <span className="form-input-error">{formattedMessage}</span>}
                            />
                        )}

                        <SweetAlert
                            show={showConfirmDelete}
                            title={this.props.intl.formatMessage({ id: "documents.confirmDelete.title" })}
                            type="warning"
                            showCancel
                            onConfirm={this.handleConfirmDelete}
                            onCancel={this.handleCancelDelete}
                            confirmBtnText="OK"
                            cancelBtnText="CANCEL"
                        >
                            {this.props.intl.formatMessage({ id: "documents.confirmDelete.text" })}
                        </SweetAlert>
                    </div>
                )}
            </ApplicationStatusContext.Consumer>
        );
    }
}

DocumentSection.propTypes = {
    applicationId: PropTypes.string,
    groupId: PropTypes.string.isRequired,
    groupType: PropTypes.number.isRequired,
    description: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    isStaff: PropTypes.bool,
    staffValid: PropTypes.bool,
    onStaffValidChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
    accessToken: state.user.accessToken,
});

DocumentSection = withAdmissions(DocumentSection);
DocumentSection = withAjaxStatus(DocumentSection);
DocumentSection = connect(mapStateToProps)(DocumentSection);
DocumentSection = injectIntl(DocumentSection);

export default DocumentSection;
