import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    eventReview: {
        padding: theme.spacing(1, 0),
    },
    navButton: {
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.warning.main,
        borderRadius: "50%",
    },
    iconComplete: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "50%",
    },
}));

const EventReviewStep = ({ programEventId, programName }) => {
    const classes = useStyles();
    const history = useHistory();

    const goToEventAttendanceList = () => {
        history.push({
            pathname: `/events/active/event/${programEventId}/event-attendance-list`,
            state: { programName },
        });
    };

    return (
        <Box className={classes.eventReview} display="flex" flexDirection="column" alignItems="flex-start">
            <Button
                onClick={goToEventAttendanceList}
                className={classes.navButton}
                startIcon={true ? <PriorityHighRoundedIcon className={classes.icon} /> : <CheckRoundedIcon className={classes.iconComplete} />}
            >
                Attendance List
            </Button>
        </Box>
    );
};

export default EventReviewStep;
