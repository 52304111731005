import React from "react";
import PropTypes from "prop-types";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";
import "./RequestForm.css";
import moment from "moment";

const RequestForm = ({ mDate, totalAmount, note, onClick, onNoteChange, onDateChange, onDateFocus, onDateBlur, isDisabled }) => {
  return (
    <div className="scholarship-request-payment-form">
      <div>
        <div className="row">
          <span>Date</span>
          <div className="spacer" />
          <span>Total</span>
        </div>
        <div className="row">
          <span>{moment().format("M/D/YY")}</span>
          <div className="spacer" />
                  <span>{formatCurrencyNoDecimalWithNegativeStyle(totalAmount)}</span>
        </div>
        <input type="text" placeholder="Notes" value={note} onChange={onNoteChange} disabled={isDisabled} maxLength={500} />
      </div>
      <div className="right">
        <button className="btn" onClick={onClick} disabled={isDisabled}>
          REQUEST PAYMENT
        </button>
      </div>
    </div>
  );
};

RequestForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDateFocus: PropTypes.func.isRequired,
  onDateBlur: PropTypes.func.isRequired,
  totalAmount: PropTypes.string,
  note: PropTypes.string,
  mDate: PropTypes.object,
  isDisabled: PropTypes.bool
};
export default RequestForm;
