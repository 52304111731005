import React from 'react';
import PropTypes from 'prop-types';

const Scholarship = ({ scholarship, onClick, isReadonly }) => {
    const msg = scholarship.status === 0 ? 'START NEW ACADEMIC YEAR' : 'CLOSE ACADEMIC YEAR';
    const years = `${scholarship.startYear}-${scholarship.endYear}`;

    return (
        <button onClick={onClick.bind(null, scholarship)} disabled={isReadonly}>
            <div className="admin-scholarhips-scholarship">
                <span>{msg}</span>
                <span>{years}</span>
            </div>
        </button>
    );
};

Scholarship.propTypes = {
    scholarship: PropTypes.shape({
        startYear: PropTypes.number.isRequired,
        endYear: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    isReadonly: PropTypes.bool.isRequired
};
const StartCloseYear = ({ scholarships, onClick, isReadonly }) => {
    return (
        <div className="admin-scholarhips-startcloseyear">
            {scholarships.map((scholarship, index) => {
                return <Scholarship key={scholarship.id} scholarship={scholarship} onClick={onClick} isReadonly={isReadonly} />;
            })}
        </div>
    );
};

StartCloseYear.propTypes = {
    scholarships: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            startYear: PropTypes.number,
            endYear: PropTypes.number,
            status: PropTypes.number
        })
    ),
    onClick: PropTypes.func.isRequired,
    isReadonly: PropTypes.bool.isRequired
};

export default StartCloseYear;
