import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import moment from "moment";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";
import "./CashList.css";

const buildColumns = isStaff => {
  const columns = [
    {
      Header: "Request Date",
      accessor: "date",
      Cell: row => moment.utc(row.value).format("M/D/YY")
    },
    {
      Header: "Requested",
      accessor: "requested",
      Cell: row => (row.value ? formatCurrencyNoDecimalWithNegativeStyle(row.value) : "")
    },
    {
      Header: "Paid",
      accessor: "paid",
      Cell: row => (row.value ? formatCurrencyNoDecimalWithNegativeStyle(row.value) : "")
    },
    {
      Header: "Refunded",
      accessor: "refunded",
      Cell: row => (row.value ? formatCurrencyNoDecimalWithNegativeStyle(row.value) : "")
    }
  ];

  if (isStaff) {
    columns.splice(1, 0, {
      Header: "School Name",
      accessor: "institutionName"
    });
  }

  return columns;
};
const CashList = ({ isStaff, data, getTdProps }) => {
  return (
    <div className="cash-list">
      <ReactTable data={data} minRows={0} className="financials-table -highlight" getTdProps={getTdProps} columns={buildColumns(isStaff)} showPagination />
    </div>
  );
};

CashList.propTypes = {
  isStaff: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.date,
      requested: PropTypes.number,
      refunded: PropTypes.number,
      paid: PropTypes.number
    })
  )
};

export default CashList;
