import React, { Component } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import ReactTable from "react-table";
import moment from "moment";

import * as propTypes from "../../propTypes";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../support/numberSupport";

import StudentSearch from "../../certify/components/StudentSearch";
import StudentEdit from "../../certify/components/StudentEdit";
import Student from "../../certify/components/Student";

import * as apis from "../../apis";
import * as ScholarshipTransactionApis from "../../../api/scholarshipTransactions";

import "./index.css";

const dateCell = (props) => {
    if (!props.value) {
        return "";
    }
    return moment(props.value).utc().format("M/D/YY");
};

const payHistoryTypeCell = (props) => {
    return props.value === 0 ? "Cash Payment" : props.value === 1 ? "Cash Refund" : "Donation";
};

class PayHistory extends Component {
    state = {
        student: null,
        payHistory: [],
        showStudentEdit: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.scholarship !== prevProps.scholarship) {
            this.setState({ student: null, payHistory: [] });
        }
    }

    async loadStudent(studentId) {
        if (!this.props.scholarship || !studentId) {
            this.setState({ student: null });
            return;
        }

        let student = (await apis.getStudent(this.props.scholarship.id, studentId)).data.result;
        student = this.doFormatStudentData(student);
        this.setState({ student }, () => this.doLoadPayHistory());
    }

    onStudentClear = () => {
        this.setState({ student: null });
    };

    onStudentSelected = (student) => {
        this.loadStudent(student.id);
    };

    async doLoadPayHistory() {
        try {
            this.props.ajaxStatusLoading();
            const payHistory = (await ScholarshipTransactionApis.getPayHistory(this.props.scholarship.id, this.state.student.id)).data.result;
            this.setState({ payHistory });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error getting pay history");
        }
    }

    onOpenStudentEdit = async () => {
        try {
            this.props.ajaxStatusLoading();
            const results = await apis.getStudent(this.props.scholarship.id, this.state.student.id);
            const studentDetail = results.data.result;
            this.setState({
                showStudentEdit: true,
                studentDetail,
            });

            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving student data");
        }
    };

    onCloseStudentEdit = () => {
        this.setState({
            showStudentEdit: false,
        });
    };

    onSaveStudentEdit = (values) => {
        this.setState(
            {
                showStudentEdit: false,
                student: null,
            },
            async () => {
                try {
                    this.props.ajaxStatusLoading();

                    await apis.updateStudent(values);

                    const student = { ...values };
                    this.setState({
                        student: this.doFormatStudentData(student),
                    });

                    toast.success("Save Complete");

                    this.props.ajaxStatusComplete();
                } catch (err) {
                    this.props.ajaxStatusError("Error saving student data");
                }
            }
        );
    };
    doFormatStudentData = (values) => {
        const student = { ...this.state.student, ...values };

        student.gender = parseInt(student.gender, 10);
        student.gender = student.gender === 1 ? "Female" : student.gender === 2 ? "Other" : "Male";
        student.level = parseInt(student.level, 10);
        student.level = student.level === 1 ? "Gold" : student.level === 2 ? "Silver" : student.level === 3 ? "Bronze" : "Pewter";
        student.deceased = student.deceased === true ? "(Deceased)" : student.deceased;
        return student;
    };

    render() {
        const { student, studentDetail } = this.state;
        const { scholarship } = this.props;
        const scholarshipId = scholarship ? scholarship.id : "";

        const donationTotal = this.state.payHistory.reduce((a, b) => (b.type === 2 ? b.amount + a : a), 0);
        const cashTotal = this.state.payHistory.reduce((a, b) => (b.type !== 2 ? b.amount + a : a), 0);

        return (
            <div className="student-pay-history">
                <StudentSearch
                    hideExport
                    onStudentClear={this.onStudentClear}
                    onStudentSelected={this.onStudentSelected}
                    scholarship={this.props.scholarship}
                    fullSearch={true}
                />
                {student ? (
                    <React.Fragment>
                        <div className="student-pay-history__row">
                            <Student student={student} onClick={this.onOpenStudentEdit} />
                            <div className="student-pay-history__totals-area">
                                <div className="student-pay-history__totals-table">
                                    <div className="student-pay-history__totals-column student-pay-history__totals-column--header">Total Donation</div>
                                    <div className="student-pay-history__totals-column student-pay-history__totals-column--header">Total Cash</div>
                                    <div className="student-pay-history__totals-column student-pay-history__totals-column--header">Grand Total</div>
                                    <div className="student-pay-history__totals-column">{formatCurrencyNoDecimalWithNegativeStyle(donationTotal)}</div>
                                    <div className="student-pay-history__totals-column">{formatCurrencyNoDecimalWithNegativeStyle(cashTotal)}</div>
                                    <div className="student-pay-history__totals-column">
                                        {formatCurrencyNoDecimalWithNegativeStyle(donationTotal + cashTotal)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="report-date">As of {moment().format("M/D/YY")}</div>
                        <ReactTable
                            className="financials-table"
                            data={this.state.payHistory}
                            minRows={0}
                            showPagination={true}
                            columns={[
                                {
                                    Header: "Institution",
                                    accessor: "institution",
                                },
                                {
                                    Header: "Term",
                                    accessor: "term",
                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                },
                                {
                                    Header: "Transaction Date",
                                    accessor: "transactionDate",
                                    Cell: dateCell,
                                },
                                {
                                    Header: "Check Date",
                                    accessor: "checkDate",
                                    Cell: dateCell,
                                },
                                {
                                    Header: "Check #",
                                    accessor: "checkNum",
                                },
                                {
                                    Header: "Type",
                                    accessor: "type",
                                    Cell: payHistoryTypeCell,
                                },
                                {
                                    Header: "Description",
                                    accessor: "description",
                                },
                                {
                                    Header: "Amount",
                                    accessor: "amount",
                                    Cell: (props) => (props.value ? formatCurrencyNoDecimalWithNegativeStyle(props.value) : ""),
                                },
                            ]}
                        />
                    </React.Fragment>
                ) : null}

                <ReactModal isOpen={this.state.showStudentEdit}>
                    <StudentEdit
                        onClose={this.onCloseStudentEdit}
                        onSave={this.onSaveStudentEdit}
                        student={studentDetail}
                        scholarshipId={scholarshipId}
                        readonly={true}
                    />
                </ReactModal>
            </div>
        );
    }
}

PayHistory.propTypes = {
    scholarship: propTypes.scholarship,
};

PayHistory = withAjaxStatus(PayHistory);

export default PayHistory;
