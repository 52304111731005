import axios from 'axios';

export const get = () => {
    return axios.get('/api/services/app/UserProfile/GetParentInfo');
};
export const getByApplicationId = applicationId => {
    return axios.get('/api/services/app/UserProfile/GetParentInfoByApplication', { params: { applicationId } });
};
export const update = data => {
    return axios.put('/api/services/app/UserProfile/UpdateParentInfo', data);
};

export const updateParentConfirmation = data => {
    return axios.post('/api/services/app/Application/UpdateParentConfirmation', data);
};

export const setApplicationSecondaryParent = data => {
    return axios.post('/api/services/app/Application/SetApplicationSecondaryParent', data);
};

