import React, { Component } from "react";
import { toast } from "react-toastify";
import withAjaxStatus from "../../../../support/hoc/withAjaxStatus";

import { createStructure } from "../logic";

import CashOptions from "./CashOptions";
import YearPicker from "../../../../scholarship/YearPicker";
import * as apis from "../apis";

import "./Programs.css";

const STARTED = 1;
const FOUR_YEAR = 1;
const TWO_YEAR = 2;
const TRADE_SCHOOL = 3;

class Programs extends Component {
    state = {
        breakdowns: null,
    };
    onSave = async () => {
        if (!this.originalBreakdowns) return;
        if (!this.scholarship || this.scholarship.status !== STARTED) return;
        try {
            this.props.ajaxStatusLoading();

            await apis.updateScholarshipBreakdowns(this.originalBreakdowns);

            this.props.ajaxStatusComplete();
            toast.success("Save Complete");
        } catch (err) {
            this.props.ajaxStatusError("Error saving updated scholarship breakdowns");
        }
    };
    onCancel = async () => {
        this.setState({
            breakdowns: null,
        });
        await this.onScholarshipChange(this.scholarship);
    };
    onScholarshipChange = async (scholarship) => {
        this.scholarship = scholarship;
        this.setState({
            breakdowns: null,
        });
        try {
            this.props.ajaxStatusLoading();

            var results = await apis.getScholarshipBreakdown(scholarship.id);

            this.originalBreakdowns = results.data.result;

            this.setState({
                breakdowns: createStructure(this.originalBreakdowns),
            });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error getting scholarship breakdowns");
        }
    };
    onAmountChange = (type, level, e) => {
        let amount = e.target.value;
        const record = this.originalBreakdowns.find((item, index) => {
            return item.type === type && item.level === level;
        });

        record.amount = parseInt(amount.replace(/\D/g, ""), 10);

        this.setState(
            {
                breakdowns: null,
            },
            () => {
                this.setState({
                    breakdowns: createStructure(this.originalBreakdowns),
                });
            }
        );
    };
    render() {
        let fourYear = null;
        let twoYear = null;
        let trade = null;
        const isReadonly = !this.scholarship || this.scholarship.status !== STARTED;
        const { breakdowns } = this.state;
        if (breakdowns !== null) {
            fourYear = breakdowns.four;
            twoYear = breakdowns.two;
            trade = breakdowns.trade;
        }
        return (
            <div className="admin-scholarhips-programs">
                <div className="admin-scholarships-programs-header">
                    <h2>Onward We Learn Cash SCholarships</h2>
                    <div style={{ flex: 1 }} />
                    <YearPicker onSelectChange={this.onScholarshipChange} />
                </div>
                <div className="hr-line" />
                <h4>4-Year Institution or 1-Year Trade School (Per Award Annually)</h4>
                {fourYear ? <CashOptions amounts={fourYear} onAmountChange={this.onAmountChange.bind(this, FOUR_YEAR)} isReadonly={isReadonly} /> : null}
                <div className="hr-line" />
                <h4>2-Year Institution (Per Award Annually)</h4>
                {twoYear ? <CashOptions amounts={twoYear} onAmountChange={this.onAmountChange.bind(this, TWO_YEAR)} isReadonly={isReadonly} /> : null}
                <div className="admin-scholarships-programs-btns">
                    <button className="btn" onClick={this.onSave}>
                        SAVE
                    </button>
                    <button className="link-button" onClick={this.onCancel}>
                        Reset
                    </button>
                </div>
            </div>
        );
    }
}

export default withAjaxStatus(Programs);
