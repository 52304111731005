import React, { Component } from 'react';
import { Button, TextField, Grid, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';

class AdvisorGoalsManager extends Component {
    state = {
        selectedGoal: null, // For adding or editing an existing goal
        goals: this.props.existingObjectives || [], // Initial goals from props
    };

    handleGoalChange = (event, goalProperty) => {
        const { selectedGoal } = this.state;
        this.setState({
            selectedGoal: {
                ...selectedGoal,
                [goalProperty]: event.target.value,
            }
        });
    };

    handleAddOrUpdateGoal = () => {
        const { selectedGoal, goals } = this.state;
        if (selectedGoal) {
            // Check if we're updating an existing goal
            const index = goals.findIndex(goal => goal.id === selectedGoal.id);
            if (index > -1) {
                goals[index] = selectedGoal;
            } else {
                // Otherwise, add a new goal
                goals.push({ ...selectedGoal, id: Date.now() }); // Simplified ID generation
            }

            this.setState({ goals, selectedGoal: null });
        }
    };

    handleDeleteGoal = (goalId) => {
        const { goals } = this.state;
        this.setState({
            goals: goals.filter(goal => goal.id !== goalId),
        });
    };

    handleSelectGoal = (goal) => {
        this.setState({ selectedGoal: goal });
    };

    renderGoalTypeSelect = () => {
        const goalTypeOptions = Object.entries({
            Baseline: 10,
            Year1: 20,
            Year2: 30,
            Year3: 40,
            Year4: 50,
            Year5: 60,
            Year6: 70,
            Year7: 80,
            Other: 90,
        }).map(([label, value]) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
        ));

        return (
            <FormControl fullWidth>
                <InputLabel>Goal Type</InputLabel>
                <Select
                    value={this.state.selectedGoal?.Goaltype || ''}
                    onChange={(event) => this.handleGoalChange(event, 'Goaltype')}
                    label="Goal Type"
                >
                    {goalTypeOptions}
                </Select>
            </FormControl>
        );
    };

    render() {
        const { selectedGoal } = this.state;
        const showAmountOtherField = selectedGoal?.Goaltype === 90; // GoalType Other

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {this.renderGoalTypeSelect()}
                </Grid>
                {showAmountOtherField && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Amount Other"
                            type="number"
                            value={selectedGoal?.AmountOther || ''}
                            onChange={(event) => this.handleGoalChange(event, 'AmountOther')}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Button onClick={this.handleAddOrUpdateGoal} variant="contained" color="primary">
                        {selectedGoal?.id ? 'Update Goal' : 'Add Goal'}
                    </Button>
                </Grid>
                {this.state.goals.map((goal) => (
                    <Grid item xs={12} key={goal.id}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{`Goal ID: ${goal.id} - Goal Type: ${goal.Goaltype}`}</span>
                            <div>
                                <Button size="small" onClick={() => this.handleSelectGoal(goal)}>Edit</Button>
                                <Button size="small" onClick={() => this.handleDeleteGoal(goal.id)}>Delete</Button>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export default AdvisorGoalsManager;
