import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    InputLabel, Tabs, Tab
} from '@mui/material';

import * as api from "../../api"
import "./index.css";
class Progress extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {
            msObj: [],
            hsObj: [],
            psObj: [],
            userTags: [],
            loaded: false,
            tab: 0,
            msVis: "tabVisible",
            hsVis: "tabHidden",
            psVis: "tabHidden",


        };
    }



    componentDidMount = async ()=> {
        

        let input = [];
		input.push({ userProfileId: this.props.profile.userProfile.id });
		
       const recs = await api.retrieveAdditionInfo(input);

        const msObj = this.context.taggingOptions.filter(flt => flt.classification.middleSchoolGoals == true);
        const hsObj = this.context.taggingOptions.filter(flt => flt.classification.highSchoolGoals == true);
        const psObj = this.context.taggingOptions.filter(flt => flt.classification.postsecondaryGoals == true);
        

        this.setState({ msObj, hsObj, psObj, userTags: recs.data.result[0].contactInfo.tags });
    }
    componentDidUpdate(prevProps, prevState) {



    }

    isChecked = (tag, subtag) => {


        let ret = false;
        const tags = this.state.userTags.filter(flt => flt.tagId == tag && flt.subTagId == subtag);
       
        if (tags.length > 0) {
            ret = true;
        }

        return ret;

    }

    setTab = async (event, newVal) => {
        let msVis = "tabHidden";
        let hsVis = "tabHidden";
        let psVis = "tabHidden";
        switch (newVal) {
            case 0:
                msVis = "tabVisible";
                hsVis = "tabHidden";
                psVis = "tabHidden";
                break;
            case 1:
                msVis = "tabHidden";
                hsVis = "tabVisible";
                psVis = "tabHidden"
                break;
            case 2:
                msVis = "tabHidden";
                hsVis = "tabHidden";
                psVis = "tabVisible";
                
                break;
            default:
                msVis = "tabVisible";
                hsVis = "tabHidden";
                psVis = "tabHidden";
                break;


        }

        this.setState({ tab: newVal, msVis: msVis, hsVis: hsVis, psVis: psVis });
    }


    renderDepartment = (department) => {
        return (
            <Grid container spacing={2}>
                {department.map((dep, depIndex) => {
                    let tagname = "";

                    return (
                        <React.Fragment key={depIndex}>
                            {dep.tags.map((tag, tagIndex) => {
                                if (tag.subtagId === "00000000-0000-0000-0000-000000000000") {
                                    tagname = tag.tagName + " : "; 
                                    return (
                                        <Grid item xs={12} className="progHeading" key={tagIndex}>
                                            {tag.tagName}
                                        </Grid>
                                    );
                                } else {
                                    return (
                                        <Grid item xs={4} key={tagIndex} className="progEntry">
                                            <Checkbox checked={ this.isChecked(tag.tagId, tag.subtagId ) } disabled></Checkbox> {tag.tagName.replace(tagname, '')} 
                                        </Grid>
                                    );
                                }
                            })}
                        </React.Fragment>
                    );
                })}
            </Grid>
        );
    }




    render() {
        // Get the last two items from the history stack


        return (<Grid container spacing={2}>

            <Grid item xs={12}>
                <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Progress Sections" variant="fullWidth"
                    sx={{
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-evenly',
                        },
                        '.MuiTab-root': {
                            border: '1px solid #000',
                            borderBottom: 'none',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            marginRight: '4px',
                            '&:last-child': {
                                marginRight: 0,
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#123225',
                                color: '#fff',
                            },
                            '&:hover': {
                                backgroundColor: '#123225',
                                color: '#fff',
                                opacity: 1,
                            },
                        },
                    }}
                >
                    <Tab label="Middle School" />
                    <Tab label="High School" />
                    <Tab label="Post-secondary"/>

                </Tabs>
            </Grid>

            <Grid item xs={12} sm={12} className={this.state.msVis}>
                {this.renderDepartment(this.state.msObj) }

            </Grid>

            <Grid item xs={12} sm={12} className={this.state.hsVis}>

                {this.renderDepartment(this.state.hsObj)}
            </Grid>


            <Grid item xs={12} sm={12} className={this.state.psVis}>
                {this.renderDepartment(this.state.psObj)}

            </Grid>

        </Grid>);
    }
}

export default Progress;