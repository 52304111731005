import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, reset, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import { toast } from "react-toastify";

import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { required, password, email, renderRadioField, renderField, phone } from "../../../support/forms";
import Button from "../../../support/components/Button";
import { renderSelectList, renderSelectIdNameField } from "../../../support/forms";
import { getUser, createUser, updateUser, getPotentialDupes } from "./apis";
import normalizePhone from "../../../support/logic/normalizePhone";
import "./Edit.css";
import withHistory from "../../../support/hoc/withHistory";
import { getList } from "../../../api/school";
import Advisor from './advisor';
const FORM_NAME = "user";

class Edit extends Component {
    static permission = "Admin.Users";
    constructor(props) {
        super(props);
        this.state = {
            showComplete: false,
            disableRoles: false,
            roles: props.roles,
            role: null,
            verified: true,
            schools: [],
            institutions: [],
            dupes: []
        };
    }
    static getDerivedStateFromProps = (props, state) => {
        let role = state.role;
        let roles = state.roles;
        let disableRoles = props.readonly;

        if (props.roleId) {
            const id = parseInt(props.roleId, 10);
            if (!state.role || state.role.value !== props.roleId) {
                role = state.roles.find((r) => {
                    return r.value === id;
                });
            }

            if (props.roles.length !== 0) {
                const role = props.roles.find((role) => {
                    return role.value === props.roleId;
                });
                if (role && (role.label === "Parent" || role.label === "Crusader")) {
                    disableRoles = true;
                } else {
                    roles = [...props.roles.filter((role) => role.label !== "Parent" && role.label !== "Crusader")];
                }
            }
        }

        return { ...state, role, roles, disableRoles };
    };
    componentDidMount = () => {
        this._isMounted = true;
        const ids = this.doGetIds();

        getList()
            .then((response) => {
                this.setState({ schools: response.data.result });
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });

        if (ids.id === 0) {
            this.props.initialize({ firstName: "", lastName: "", email: "", isActive: "true", roleId: ids.roleId });
            if (this._isMounted) {
                this.setState({
                    roles: [...this.props.roles.filter((role) => role.label !== "Parent" && role.label !== "Crusader")],
                });
            }
            return;
        }

        this.props.ajaxStatusLoading();

        getUser(ids.id)
            .then((response) => {
                if (!this._isMounted) return;
                const user = response.data.result;
                this.setState({ verified: user.verified });
                this.doCheckParentRole(user);
                this.props.initialize(user);
                this.props.ajaxStatusComplete();
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });
       
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };
    onSave = (values) => {
        const ids = this.doGetIds();
        values.id = ids.id;

        if (ids.id) {
            this.doUpdate(values);
            return;
        }
        this.doCreate(values);
    };
    onCancel = (e) => {
        e.preventDefault();
        this.props.reset();
        this.props.history.goBack();
    };
    doCreate = (values) => {
        this.props.ajaxStatusLoading();
        createUser(values)
            .then((response) => {
                this.props.ajaxStatusComplete();
                this.props.onSaveComplete();
                this.setState({
                    showComplete: true,
                });
                toast.success("Save Complete");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.error) {
                    this.props.ajaxStatusError(error.response.data.error.message);
                    return;
                }
                this.props.ajaxStatusError(error.message);
            });
    };
    doUpdate = (values) => {
        this.props.ajaxStatusLoading();
        updateUser(values)
            .then((response) => {
                this.props.ajaxStatusComplete();
                this.props.onSaveComplete();
                this.setState({
                    showComplete: true,
                });
                toast.success("Save Complete");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.error) {
                    this.props.ajaxStatusError(error.response.data.error.message);
                    return;
                }
                this.props.ajaxStatusError(error.message);
            });
    };
    doGetIds = () => {
        const { match } = this.props;
        const { id, roleId } = match.params;

        return {
            id: parseInt(id, 10),
            roleId: roleId ? parseInt(roleId, 10) : null,
        };
    };
    doCheckParentRole = (user) => {
        const { readonly, roles } = this.props;
        let newRoles = roles;
        let disableRoles = readonly;
        const role = roles.find((role) => {
            return role.value === user.roleId;
        });
        if (role && (role.label === "Parent" || role.label === "Crusader")) {
            disableRoles = true;
            
        } else {
            newRoles = [...this.props.roles.filter((role) => role.label !== "Parent" && role.label !== "Crusader")];
        }

        this.setState({ disableRoles, roles: newRoles });
    };
    render() {
        if (this.state.showComplete) return <Redirect to="/admin/users" />;
        const { readonly } = this.props;
        const rolereadonly = this.state.disableRoles || readonly;
        const { verified } = this.state;
        const isParent = this.state.role && this.state.role.label === "Parent";
        const isCrusader = this.state.role && this.state.role.label === "Crusader";
        const isAdvisor = this.state.role && this.state.role.label === "Advisor";
      
        return (
            <div className="admin-user-edit">
                {/* {isParent && <div className="parent-note">For parent users, only password edits will be saved.</div>} */}
                <Field name="firstName" component={renderField} label="First Name" validate={[required]} disabled={readonly} />
                <Field name="lastName" component={renderField} label="Last Name" validate={[required]} disabled={readonly} />
                {isParent || isCrusader || isAdvisor ? (
                    <Field
                        name="phoneNumber"
                        component={renderField}
                        label="Mobile Phone"
                        placeholder="(xxx) xxx-xxxx"
                        normalize={normalizePhone}
                        validate={[required, phone]}
                        disabled={readonly}
                    />
                ) : null}
                {isParent ? <Field name="userName" component={renderField} label="User Name" disabled={readonly} /> : null}
                <Field name="email" component={renderField} label="Email" validate={[email]} disabled={readonly} />
                <span>Active</span>
                <Field
                    id="usersActive"
                    name="isActive"
                    component={renderRadioField}
                    label="Active"
                    items={[
                        { value: true, text: "Yes" },
                        { value: false, text: "No" },
                    ]}
                    validate={[required]}
                    disabled={readonly}
                />
                {this.state.role && this.state.role.label !== "Admin" ? (
                    <React.Fragment>
                        <div className="user-row">
                            <Field name="password" disabled={!verified} component={renderField} type="password" validate={[password]} label="Password" />
                            <div />
                            <Field
                                name="confirmPassword"
                                disabled={!verified}
                                component={renderField}
                                type="password"
                                validate={[password]}
                                label="Confirm Password"
                            />
                        </div>
                        <div className="password-help">
                            Leave blank to keep current password. Email must be verified first.
                            <br />
                            Passwords must be at least 8 characters long, and must contain a lowercase and uppercase letter, a number, and a special character
                            ($&amp;*@! etc)
                        </div>
                    </React.Fragment>
                ) : null}
                <Field name="roleId" component={renderSelectList} label="Role" data={this.state.roles} validate={[required]} disabled={rolereadonly} />
                {this.state.role && this.state.role.label === "Institution" ? (
                    <Field
                        name="institutionId"
                        component={renderSelectIdNameField}
                        label="Institution"
                        data={this.props.institutions}
                        validate={[required]}
                        disabled={rolereadonly}
                    />
                ) : null}

                {this.state.role && this.state.role.label === "Advisor" && this.props.match.params.id != 0? (
                   
                 

                        <Advisor readOnly={false} schools={this.state.schools} userId={this.props.match.params.id} institutions={ this.props.institutions} />
                        
                ) : null}

                {this.state.role && this.state.role.label === "Advisor" && this.props.match.params.id == 0 ? (
                    <div className="password-help">
                        Advisor Profiles must be saved before schools or direct reports can be assigned.
                <br/>
                </div>
                ) : null}

                <div className="admin-user-edit-buttons">
                    <Button onClick={this.props.handleSubmit(this.onSave)} id="Save" defaultMessage="Save" disabled={readonly} />

                    <a href="/admin/users" onClick={(e) => this.onCancel(e)}>
                        Cancel
                    </a>
                </div>
            </div>
        );
    }
}

Edit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    onSaveComplete: PropTypes.func.isRequired,
    readonly: PropTypes.bool.isRequired,
    institutions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
};

const validate = (values) => {
    const errors = {};

    if (values.password && values.password !== values.confirmPassword) {
        errors.confirmPassword = "Please verify that the passwords match";
    }

    return errors;
};

Edit = reduxForm({
    form: FORM_NAME,
    validate,
})(Edit);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
    const roleId = selector(state, "roleId");
    return {
        roleId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(reset(FORM_NAME)),
    };
};

Edit = connect(mapStateToProps, mapDispatchToProps)(Edit);

Edit = withRouter(Edit);
Edit = withAjaxStatus(Edit);
Edit = withHistory(Edit);

export default Edit;
