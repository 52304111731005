import React, { Component } from "react";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

import deepCopyObject from "../../logic/deepCopyObject";

class Dropzonify extends Component {
    static propTypes = {
        className: PropTypes.string,
        accessToken: PropTypes.string.isRequired,
        children: PropTypes.func.isRequired,
        accept: PropTypes.string.isRequired,
        uploadUrl: PropTypes.string.isRequired,
        onUploadComplete: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        onUploadStarted: PropTypes.func,
        additionalUploadParameters: PropTypes.object,
    };

    fileIndex = 1;

    state = {
        uploadingFiles: [],
        dragging: false,
        showAlert: false,
        showError: false,
    };

    onDrop = (files, rejected) => {
        if (rejected.length) {
            this.setState({ showAlert: true });
            return;
        }

        const uploadingFiles = deepCopyObject(this.state.uploadingFiles);

        files.forEach((file) => {
            const fileIndex = this.fileIndex++;
            uploadingFiles.push({ index: fileIndex, percentComplete: 0, fileName: file.name });
            this.uploadFile(file, fileIndex);
        });

        if (this.props.onUploadStarted) {
            this.props.onUploadStarted(files);
        }

        this.setState({ uploadingFiles, dragging: false });
    };

    onDragEnter = () => {
        this.setState({ dragging: true });
    };

    onDragLeave = () => {
        this.setState({ dragging: false });
    };

    removeUploadingFile = (index) => {
        let uploadingFiles = deepCopyObject(this.state.uploadingFiles);
        uploadingFiles = uploadingFiles.filter((file) => file.index !== index);
        this.setState({ uploadingFiles });
    };

    uploadFile(file, fileIndex) {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = ((event.loaded / event.total) * 100).toFixed(0);
                const uploadingFiles = deepCopyObject(this.state.uploadingFiles);
                const item = uploadingFiles.find((item) => item.index === fileIndex);
                item.percentComplete = percentComplete;
                this.setState({ uploadingFiles });
            }
        });

        xhr.onreadystatechange = (event) => {
            if (xhr.readyState === 4) {
                const response = JSON.parse(xhr.response);

                if (xhr.status === 500) {
                    const errorMessage = response.error.message;

                    this.setState({ showError: true, errorMessage });

                    this.removeUploadingFile(fileIndex);
                    return;
                }

                this.removeUploadingFile(fileIndex);

                this.props.onUploadComplete(response.result);
            }
        };

        xhr.withCredentials = true;
        xhr.open("POST", this.props.uploadUrl, true);
        xhr.setRequestHeader("Authorization", `Bearer ${this.props.accessToken}`);
        let formData = new FormData();
        formData.append("_file", file);

        if (this.props.additionalUploadParameters) {
            var parameters = Object.entries(this.props.additionalUploadParameters);

            for (let i = 0; i < parameters.length; i++) {
                if (parameters[i][1] === undefined) {
                    continue;
                }
                formData.append(parameters[i][0], parameters[i][1]);
            }
        }

        xhr.send(formData);
    }

    onBrowse = (event) => {
        event.preventDefault();
        this.dropZoneRef.open();
    };

    onConfirmAlert = () => {
        this.setState({ showAlert: false });
    };

    render() {
        return (
            <Dropzone
                accept={this.props.accept}
                className={`dropzonify ${this.props.className ? this.props.className : ""} ${this.state.dragging ? "dragging" : ""}`}
                disableClick
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                ref={(dropZone) => {
                    this.dropZoneRef = dropZone;
                }}
                disabled={this.props.disabled}
            >
                {this.props.children({ uploadingFiles: this.state.uploadingFiles, onBrowse: this.onBrowse, dragging: this.state.dragging })}
                <SweetAlert show={this.state.showAlert} title="Invalid File Type" type="error" onConfirm={this.onConfirmAlert}>
                    {`File must be one of the following types: ${this.props.accept}`}
                </SweetAlert>
                <SweetAlert show={this.state.showError} title="Error Uploading File" type="error" onConfirm={() => this.setState({ showError: false })}>
                    {this.state.errorMessage}
                </SweetAlert>
            </Dropzone>
        );
    }
}

export default Dropzonify;
