import React, { useState } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import BackButton from "../../common/backButton";
import Button from "@material-ui/core/Button";
import LoadingIndicator from "../../common/loadingIndicator";
import { useEligibleParticipants, useEligibilityOverrides, useProgramEvent } from "../hooks";

import RemoveAttendeesModal from "./components/removeAttendeesModal";
import AddAttendeesModal from "./components/addAttendeesModal";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    formHeader: {
        display: "grid",
        gridTemplateColumns: "auto 150px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"name saveButton" "title ." "subtitle ."`,
        marginBottom: theme.spacing(3),
    },
    name: {
        gridArea: "name",
        textTransform: "uppercase",
    },
    title: {
        gridArea: "title",
        fontWeight: "bold",
    },
    saveButton: {
        gridArea: "saveButton",
        height: 50,
    },
    formBody: {
        paddingLeft: theme.spacing(0),
        paddingBottom: theme.spacing(5),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(5),
    },
}));

const AddRemoveAttendees = ({ isActive }) => {
    const classes = useStyles();
    const { id } = useParams();
    const [dirty, setDirty] = useState(false);
    const { programEvent, loading: loadingProgramEvent } = useProgramEvent(id);
    const { eligibleParticipants, loading: loadingEligibleParticipants, setEligibleParticipants } = useEligibleParticipants(id);
    const {
        getEligibilityOverridesAsync,
        addedParticipants,
        removedParticipants,
        loading: loadingOverrides,
        setEligibilityOverrides,
        updateEligibilityOverridesAsync,
    } = useEligibilityOverrides(id);
    const [showAddParticipantsModal, setShowAddParticipantsModal] = useState(false);
    const [showRemoveParticipantsModal, setShowRemoveParticipantsModal] = useState(false);
    const history = useHistory();
    const handleAddedParticipants = (selectedParticipants) => {
        const addedParticipantsOverrides = selectedParticipants.reduce((total, selected) => {
            if (!selected?.id || addedParticipants.find((p) => p.userProfileId === selected?.id)) return total;
            return [
                ...total,
                {
                    userProfileId: selected.id,
                    participant: selected,
                    programEventId: id,
                    eligible: true,
                },
            ];
        }, []);
        setEligibilityOverrides([...removedParticipants, ...addedParticipants, ...addedParticipantsOverrides]);
        if (!dirty) setDirty(true);
    };

    const handleRemoveParticipants = (selectedParticipants) => {
        const removedParticipantsOverrides = selectedParticipants.reduce((total, selected) => {
            if (!selected?.id || removedParticipants.find((p) => p.userProfileId === selected?.id)) return total;
            return [
                ...total,
                {
                    userProfileId: selected.id,
                    participant: selected,
                    programEventId: id,
                    eligible: false,
                },
            ];
        }, []);
        setEligibilityOverrides([...removedParticipants, ...addedParticipants, ...removedParticipantsOverrides]);
        if (!dirty) setDirty(true);
    };

    const handleRemoveFromParticipantList = (dto) => {
        const newEligibilityOverrides = [...removedParticipants, ...addedParticipants].filter((participant) => participant.userProfileId !== dto.userProfileId);
        setEligibilityOverrides(newEligibilityOverrides);
        if (!dirty) setDirty(true);
    };

    const handleRemoveFromAddedParticipants = (dto) => {
        handleRemoveFromParticipantList(dto);
    };

    const handleRemoveFromRemovedParticipants = (dto) => {
        handleRemoveFromParticipantList(dto);
        setEligibleParticipants([...eligibleParticipants, dto.participant]);
    };

    const handleSave = async () => {
        try {
            await updateEligibilityOverridesAsync();
            toast.success("Changes saved successfully");
            if (dirty) setDirty(false);
            history.push(`/events/${isActive ? "active" : "inactive"}/event/${id}/eligible-participant-list`);
        } catch (ex) {
            await getEligibilityOverridesAsync();
        }
    };

    if (loadingProgramEvent || loadingEligibleParticipants || loadingOverrides) {
        return <LoadingIndicator loading />;
    }

    if (programEvent == null) {
        return null;
    }

    return (
        <div>
            <Prompt when={dirty} message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, Stay Here"} />
            <div className={classes.formHeader}>
                <div className={classes.name}>
                    <BackButton />
                    <Typography variant="h5" color="secondary">
                        {programEvent.name}
                    </Typography>
                </div>
                <Typography className={classes.title} variant="h6">
                    Attendance List - Add/Remove
                </Typography>
                <Button className={classes.saveButton} type="submit" variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
            </div>
            
            <AddAttendeesModal
                open={showAddParticipantsModal}
                currentOverrides={addedParticipants}
                currentEligibleParticipants={eligibleParticipants}
                onClose={() => setShowAddParticipantsModal(false)}
                onSubmit={handleAddedParticipants}
            />
            <RemoveAttendeesModal
                open={showRemoveParticipantsModal}
                currentOverrides={[...addedParticipants, ...removedParticipants]}
                options={eligibleParticipants}
                onClose={() => setShowRemoveParticipantsModal(false)}
                onSubmit={handleRemoveParticipants}
                keepMounted
            />
        </div>
    );
};

export default AddRemoveAttendees;
