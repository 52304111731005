import React from "react";
import { useHistory } from "react-router";
import { Typography } from "@material-ui/core";
import BackButton from "../../../common/backButton";
import { useStyles } from "./styles";

const EventHeader = ({ mainTitle = "No title", subTitle = "No sub-title", date, onBackButtonClick }) => {
    const classes = useStyles();
    const history = useHistory();

    const goBack = () => history.goBack();
    const _date = date ? "- " + date : "";

    return (
        <div className={classes.header}>
            <div className={classes.title}>
                <BackButton onClick={onBackButtonClick || goBack} />

                <Typography variant="h5" color="secondary">
                    {mainTitle}
                </Typography>
            </div>
            <Typography className={classes.subtitle} variant="h6">
                {`${subTitle} ${_date}`}
            </Typography>
        </div>
    );
};

export default EventHeader;
