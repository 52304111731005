import axios from "axios";

export const getAll = () => {
    return axios.get("/api/services/app/Scholarship/GetAll");
};

export const getInstitutionByUser = () => {
    return axios.get("/api/services/app/Institution/GetByUser");
};

export const searchInstitutions = (searchTerm) => {
    return axios.post("/api/services/app/Institution/SearchInstitutions", null, {
        params: { searchTerm },
    });
};

export const getAllData = (institutionId, scholarshipId) => {
    return axios.get("/api/services/app/Scholarship/GetScholarshipInstitutionData", { params: { institutionId, scholarshipId } });
};

export const updateScholarshipInsitutionData = (input) => {
    return axios.put("/api/services/app/Scholarship/UpdateScholarshipInsitutionData", input);
};

export const getStudent = (scholarshipId, studentId) => {
    return axios.get("/api/services/app/Scholarship/GetStudent", {
        params: { studentId, scholarshipId },
    });
};

export const updateStudent = (student) => {
    return axios.put("/api/services/app/Scholarship/UpdateStudent", student);
};

export const getStudentCertificationStatus = (userProfileId, scholarshipId) => {
    return axios.get("/api/services/app/ScholarshipCertification/GetAllByCrusader", { params: { userProfileId, scholarshipId } });
};

export const saveStudentCertifyRecord = (record) => {
    return axios.post("/api/services/app/ScholarshipCertification/SaveCrusaderCertifyRecord", record);
};

export const exportEligibleCrusaders = (scholarshipId, startDate) => {
    return axios.get("/api/services/app/ScholarshipCertification/GetEligibleCrusaders", { params: { scholarshipId, startDate } });
};

export const downloadFerpaForms = (startDate) => {
    return axios.post("/api/services/app/Form/DownloadFerpaForms", { startDate });
};
