import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    backButton: {
        alignSelf: "flex-start",
    },
    startIcon: {
        marginRight: 0,
    },
}));

const BackButton = ({ className, onClick }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        if (!onClick) {
            history.goBack();
            return;
        }
        onClick(history.goBack);
    };

    if (history.length <= 1) {
        return null;
    }

    return (
        <Button
            className={clsx(classes.backButton, className)}
            classes={{ startIcon: classes.startIcon }}
            onClick={handleClick}
            variant="text"
            startIcon={<ArrowBackIosIcon color="primary" />}
        >
            Back
        </Button>
    );
};

export default BackButton;
