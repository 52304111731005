import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { getCrusader, getAllCrusaders, updateCrusader } from "../users/apis";

const useCrusaderUser = () => {
    const [crusaderUser, setCrusaderUser] = useState(null);
    const [allCrusaders, setAllCrusaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const { ajaxErrorWrapper } = useAjaxStatus();

    const loadCrusaderUserAsync = useCallback(async (userProfileId) => {
        try {
            const response = await getCrusader(userProfileId);
            setCrusaderUser(response.data.result);
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const loadAllCrusadersAsync = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getAllCrusaders();
            setAllCrusaders(response.data.result);
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const updateCrusaderAsync = async (payload) => {
        try {
            setLoading(true);
            await updateCrusader(payload);
            await loadCrusaderUserAsync(payload.id);
            toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        crusaderUser,
        allCrusaders,
        loading,
        loadCrusaderUserAsync,
        updateCrusaderAsync,
        loadAllCrusadersAsync,
    };
};

export default useCrusaderUser;
