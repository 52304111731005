import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import EventDateRow from "./eventDateRow";
import LoadingButton from "../../../common/loadingButton";
import useAjaxStatus from "../../../common/useAjaxStatus";
import b64toBlob from "../../../support/logic/b64toBlob";
import FileSaver from "file-saver";
import { getPermissionSlipsZip } from "../../api/programEvents";
import { useStyles } from "./styles";

const EventDateSeriesTable = ({ rows, maxItemsToShow, handleOnClickDate, waitlist }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handlePermissionSlipClick = async (id) => {
        try {
            setLoading(true);
            var response = await getPermissionSlipsZip(id);
            const blob = b64toBlob(response.data.result.data, response.data.result.mimeType);
            FileSaver.saveAs(blob, response.data.result.fileName);
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.bold}>
                    <TableCell style={{ width: "20%" }}>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Participants/Capacity</TableCell>
                    {!waitlist && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            rows.length > maxItemsToShow ? (
                                <div>
                                    {rows
                                        .slice(maxItemsToShow)
                                        .sort((firstEl, secondEl) => new Date(firstEl.date) - new Date(secondEl.date))
                                        .map(({ date }, index) => (
                                            <EventDateRow key={index} date={date} totalEvents={rows.length} />
                                        ))}
                                </div>
                            ) : (
                                ""
                            )
                        }
                    >
                        <TableCell className={classes.hoverDate} onClick={() => handleOnClickDate(rows.at(-1))}>
                            {rows
                                .slice(0, maxItemsToShow)
                                .sort((firstEl, secondEl) => new Date(firstEl.date) - new Date(secondEl.date))
                                .map(({ date }, index) => (
                                    <div key={index}>
                                        <EventDateRow
                                            key={index}
                                            date={date}
                                            totalEvents={rows.length}
                                            showMore={index === 2 && rows.length > maxItemsToShow}
                                        />
                                    </div>
                                ))}
                        </TableCell>
                    </Tooltip>
                    <TableCell className={classes.status}>{rows[0].status}</TableCell>
                    <TableCell>{rows.at(-1).participants}</TableCell>
                    {!waitlist && (
                        <TableCell>
                            <LoadingButton
                                variant="text"
                                color="primary"
                                onClick={() => handlePermissionSlipClick(rows[0].id)}
                                loading={loading}
                                disabled={rows[0].enrollmentTotal === 0}
                            >
                                Permission Slip.zip
                            </LoadingButton>
                        </TableCell>
                    )}
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default EventDateSeriesTable;
