import axios from "axios";

export const resendEmail = (id) => {
    return axios.post("/api/services/app/Admin/ResendEmail", {
        id,
    });
};

//Create Update
export const createUser = (user) => {
    return axios.post("/api/services/app/Admin/Create", user);
};
export const updateUser = (user) => {
    return axios.put("/api/services/app/Admin/Update", user);
};

//Get Users
export const getUser = (id) => {
    return axios.get("/api/services/app/Admin/GetUser", { params: { id } });
};

export const getAllUsersByRole = (id) => {
    return axios.get("/api/services/app/Admin/GetUsersByRole", { params: { id } });
};

export const searchForUser = (text, roleId) => {
    return axios.post("/api/services/app/Admin/SearchForUser", { text, roleId });
};
export const exportUser = async (text, roleId) => {
    return await axios.post("/api/services/app/Admin/ExportUser", { text, roleId });
};
//Role Methods
export const getAllRoles = () => {
    return axios.get("/api/services/app/Admin/GetAllRoles");
};

export const getAllRolesByUser = (id) => {
    return axios.get("/api/services/app/Admin/GetRolesByUser", { params: { id } });
};


export const getSchoolsForAdvisorId = (userId) => {

    return axios.get("/api/services/app/Advisors/GetSchoolsForAdvisorId",  { params: { userId } });
};


export const getInstitionsForAdvisorId = (userId) => {

    return axios.get("/api/services/app/Advisors/GetInstitutionsForAdvisorId", { params: { userId } });
};

//Assign Unassign roles
export const assign = (userId, roleId) => {
    return axios.post("/api/services/app/Admin/Assign", { userId, roleId });
};

export const unassign = (userId, roleId) => {
    return axios.post("/api/services/app/Admin/unassign", { userId, roleId });
};

export const adminForgotPassword = (userId) => {
    return axios.post("/api/services/app/accountManagement/AdminForgotPassword", { id: userId });
};

export const getAllCrusaders = (text) => {
    return axios.get("/api/services/app/Admin/GetAllCrusaders", { params: { text } });
};

export const getCrusader = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetCrusader", { params: { userProfileId } });
};

export const updateCrusader = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateCrusaderUser", payload);
};

export const getAllParents = (text) => {
    return axios.get("/api/services/app/Admin/GetAllParents", { params: { text } });
};

export const getParent = (userProfileId) => {
    return axios.get("/api/services/app/Admin/GetParent", { params: { userProfileId } });
};

export const updateParent = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateParentUser", payload);
};

export const updateUsername = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateUserName", payload);
};

export const updatePassword = (payload) => {
    return axios.put("/api/services/app/Admin/UpdatePassword", payload);
};

export const updateActiveStatus = (payload) => {
    return axios.put("/api/services/app/Admin/UpdateActiveStatus", payload);
};

export const checkUsernameExists = (username) => {
    return axios.post("/api/services/app/Admin/CheckUsernameExists", null, { params: { username } });
};

export const getAdvisorBasics = (userId) => {
    return axios.get("/api/services/app/Advisors/GetAdvisorBasics", { params: { userId } });
};

export const getPotentialAdvisorSubordinates = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetPotentialAdvisorSubordinates", { params: { userProfileId } });
};

export const assignAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisor", payload);
};

export const assignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisorSchool", payload);
};

export const unassignAdvisorSchool = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignAdvisorSchool", payload);
};

export const assignAdvisorInstitution = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignAdvisorInstitution", payload);
};

export const unassignAdvisorInstitution = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignAdvisorInstitution", payload);
};

export const assignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/AssignDirectorAdvisor", payload);
};
export const setDirector = (payload) => {
    return axios.post("/api/services/app/Advisors/SetDirector", payload);
};
export const setGap = (payload) => {
    return axios.post("/api/services/app/Advisors/SetGap", payload);
};
export const setHonorific = (payload) => {
    return axios.post("/api/services/app/Advisors/SetHonorific", payload);
};
export const setAccelerate = (payload) => {
    return axios.post("/api/services/app/Advisors/SetAccelerate", payload);
};
export const unassignDirectorAdvisor = (payload) => {
    return axios.post("/api/services/app/Advisors/UnassignDirectorAdvisor", payload);
};

export const updateProfile = (input) => {
    return axios.post('/api/services/app/accountManagement/UpdateProfile',  input );
};
export const getProfile = (userProfileId) => {
    return axios.get('/api/services/app/accountManagement/GetProfile', { params: { userProfileId } } );
};

export const updateMyProfile = (input) => {
    return axios.post('/api/services/app/accountManagement/UpdateMyProfile', input);
};
export const getStudentById = (userProfileId) => {
    return axios.get("/api/services/app/Advisors/GetStudentById", { params: { userProfileId } });
};