import React, { Component } from "react";
import { connect } from "react-redux";
import Dropdown, { DropdownTrigger, DropdownContent } from "react-simple-dropdown";
import FontAwesome from "react-fontawesome";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { FormattedMessage, injectIntl } from "react-intl";
import Modal from "react-modal";
import { ThemeProvider } from "@material-ui/core/styles";
import Settings from "../settings";
import UserProfile from "../profile";
import SimpleDialog from "../../../common/simpleDialog";
import { clearIdentifiers } from "../../../admissions/logic/admissionsRedux";
import { logoutAction } from "../../../redux/login";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import withAdmissions from "../../../support/hoc/withAdmissions";
import { SecurityContext } from "../../../support/security";
import { createCCMUITheme } from "../../../support/muiTheme";
import "./index.css";

class UserMenu extends Component {
    userDropdown = null;

    state = {
        showDeleteConfirm: false,
        showSettings: false,
        showProfile: false,
    };

    handleDeleteApplication = () => {
        this.hideDropdown();
        this.setState({ showDeleteConfirm: true });
    };

    confirmDeleteApplication = () => {
        this.setState({ showDeleteConfirm: false }, async () => {
            try {
                this.props.ajaxStatusLoading();
                //await deleteEntireApplication();
                this.props.loadIdentifiers();
                this.props.ajaxStatusComplete();
            } catch (err) {
                this.props.ajaxStatusError(err);
            }
        });
    };

    cancelDeleteApplication = () => {
        this.setState({ showDeleteConfirm: false });
    };

    handleLogout = () => {
        this.hideDropdown();
        this.props.clearIdentifiers();
        this.props.logout();
    };
    handleSettingsClick = () => {
        this.hideDropdown();
        this.setState({
            showSettings: true,
        });
    };
    hideDropdown() {
        this.userDropdown.hide();
    }

    assignUserDropdownRef = (ref) => {
        this.userDropdown = ref;
    };

    handleProfileClick = () => {
        this.hideDropdown();
        this.setState({
            showProfile: true,
        });
    };
    handleProfileCancel = () => {
        this.setState({
            showProfile: false,
        });
    };
    render() {
        const { showDeleteConfirm } = this.state;

        return (
            <SecurityContext.Consumer>
                {(context) => {
                    if (!context) {
                        return null;
                    }

                    const canDeleteApplication = false; // context.hasEditPermission("Admissions.Delete");
                    const canChangeSettings = context.hasEditPermission("Applications.Settings") || context.hasEditPermission("Finance.Settings");
                    return (
                        <div className="user-menu">
                            <Modal isOpen={this.state.showSettings} ariaHideApp={false}>
                                <Settings onClose={() => this.setState({ showSettings: false })} />
                            </Modal>
                            <ThemeProvider theme={createCCMUITheme()}>
                                <SimpleDialog open={this.state.showProfile} onClose={() => this.handleProfileCancel()} title="My Profile">
                                    <UserProfile onClose={() => this.handleProfileCancel()} />
                                </SimpleDialog>
                            </ThemeProvider>
                            <Dropdown ref={this.assignUserDropdownRef}>
                                <DropdownTrigger>
                                    <span className="fa-stack">
                                        <FontAwesome className="yellow" name="circle" stack="2x" />
                                        <FontAwesome className="white" name="ellipsis-h" stack="1x" />
                                    </span>
                                </DropdownTrigger>
                                <DropdownContent>
                                    <ul className="user-menu-list">
                                        <li>
                                            <div role="button" onClick={this.handleProfileClick} onKeyPress={this.handleProfileClick} tabIndex="0">
                                                My Profile
                                            </div>
                                        </li>
                                        {canDeleteApplication ? (
                                            <li>
                                                <div
                                                    role="button"
                                                    onClick={this.handleDeleteApplication}
                                                    onKeyPress={this.handleDeleteApplication}
                                                    tabIndex="0"
                                                >
                                                    <FormattedMessage id="header.deleteApplication" defaultMessage="Delete Entire Application" />
                                                </div>
                                            </li>
                                        ) : null}
                                        {canChangeSettings ? (
                                            <li>
                                                <div role="button" onClick={this.handleSettingsClick} onKeyPress={this.handleSettingsClick} tabIndex="0">
                                                    Settings
                                                </div>
                                            </li>
                                        ) : null}
                                        <li>
                                            <div role="button" onClick={this.handleLogout} onKeyPress={this.handleLogout} tabIndex="0">
                                                <FormattedMessage id="Logout" defaultMessage="Logout" />
                                            </div>
                                        </li>
                                    </ul>
                                </DropdownContent>
                            </Dropdown>
                            <SweetAlert
                                show={showDeleteConfirm}
                                title={this.props.intl.formatMessage({ id: "header.deleteApplication" })}
                                showCancel
                                onConfirm={this.confirmDeleteApplication}
                                onCancel={this.cancelDeleteApplication}
                                confirmBtnText={this.props.intl.formatMessage({ id: "app.ok" })}
                                cancelBtnText={this.props.intl.formatMessage({ id: "app.cancel" })}
                            >
                                {this.props.intl.formatMessage({ id: "header.deleteApplicationText" })}
                            </SweetAlert>
                        </div>
                    );
                }}
            </SecurityContext.Consumer>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutAction()),
    clearIdentifiers: () => dispatch(clearIdentifiers()),
});

UserMenu = connect(null, mapDispatchToProps)(UserMenu);

UserMenu = withAjaxStatus(UserMenu);
UserMenu = withAdmissions(UserMenu);

UserMenu = withRouter(UserMenu);

export default injectIntl(UserMenu);
