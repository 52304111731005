import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";

import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

import "./RequestForm.css";

const RequestForm = ({
  mDate,
  totalAmount,
  note,
  checkNumber,
  onClick,
  onNoteChange,
  onCheckNumberChange,
  onDateChange,
  onDateFocus,
  onDateBlur,
  isDisabled
}) => {
  const disabled = isDisabled ? "disabled" : "";
  const number = checkNumber === 0 || isNaN(checkNumber) ? "" : checkNumber;
  return (
    <div className="scholarship-refund-form">
      <div>
        <div className="row">
          <span>Check Date</span>
          <div className="spacer" />
          <span className="check-number-label">Check #</span>
          <div className="spacer" />
          <span>Total</span>
        </div>
        <div className="row">
          <Datetime
            inputProps={{ placeholder: "M/D/YY", disabled }}
            utc={true}
            value={mDate}
            onChange={onDateChange}
            onFocus={onDateFocus}
            onBlur={onDateBlur}
            timeFormat={false}
            dateFormat="M/D/YY"
          />
          <div className="spacer" />
          <div>
            <input
              className="check-number"
              type="text"
              placeholder="check#"
              value={number}
              onChange={onCheckNumberChange}
              disabled={isDisabled}
              maxLength="10"
            />
          </div>
          <div className="spacer" />
          <span>{formatCurrencyNoDecimalWithNegativeStyle(totalAmount)}</span>
        </div>
        <input
          type="text"
          placeholder="Notes"
          value={note}
          onChange={onNoteChange}
          disabled={isDisabled}
          maxLength={500}
        />
      </div>
      <div className="right">
        <button className="btn" onClick={onClick} disabled={isDisabled}>
          Submit Refund
        </button>
      </div>
    </div>
  );
};

RequestForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onCheckNumberChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDateFocus: PropTypes.func.isRequired,
  onDateBlur: PropTypes.func.isRequired,
  totalAmount: PropTypes.string,
  note: PropTypes.string,
  mDate: PropTypes.object,
  isDisabled: PropTypes.bool
};
export default RequestForm;
