export default {
    errorMessage: '',
    ajaxCallsInProgress: 0,
    languageMessages: null,
    locale: 'es',
    user: null,
    admissions: null,
    schools: [],
    cities: [],
    staffMiddleSchools: []
};
