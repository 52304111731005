import React, { Component } from 'react';
import { Field, FormSpy } from 'react-final-form';
import SimpleDialog from "../simpleDialog";
import LoadingButton from "../loadingButton";
import { FinalFormTextField } from "../formWrappers";
import Link from "@material-ui/core/Link";
import { IconButton, TextField } from '@material-ui/core';
import FontAwesome from "react-fontawesome";
class IPEDLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showExport: false,
            resList: [],
            iped: "",
            ipedName: "Search Other Institution",
            searchTerm: ""
        };
    }

    componentDidMount() {
        const ipeds = this.props.ipedList.filter(flt => flt.ipedNo == this.props.ipedNo);
        if (ipeds.length == 1) {
            this.setState({
                iped: ipeds[0].ipedNo,
                ipedName: ipeds[0].name,
                searchTerm: ipeds[0].name
            });
        }

    }
    updateSearch = async (event) => {

        this.setState({ searchTerm: event.target.value.toLowerCase() });

    }
  

    executeSearch = async (event) => {
        const srchtxt = this.state.searchTerm;
        const list = this.props.ipedList.filter(flt => flt.name.toLowerCase().includes(srchtxt) || flt.ipedNo.toLowerCase().includes(srchtxt));
        this.setState({ resList: list });
    }

    setIped = (iped, form) => {
        this.setState({ iped: iped.ipedNo, ipedName: iped.name, showExport: false, searchTerm: iped.name });
        // Update the form values directly
        form.change("institutionIPEDNo", iped.ipedNo);
        form.change("otherInstitutionName", iped.name);
    }

    render() {
        //console.log(this);
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flex: 1 }}>
                   
                    <Field name="institutionIPEDNo" component="input" type="hidden" />
                   
                    <Field name="otherInstitutionName" component="input" type="hidden" disabled style={{ flex: 1, minHeight: "40px" }} />
                    {this.state.ipedName}&nbsp;
                    <IconButton aria-label="search" onClick={() => this.setState({ showExport: true })}>
                        <FontAwesome name="search" />
                    </IconButton>
                    
                </div>
                <FormSpy subscription={{}}>
                    {({ form }) => (
                        <SimpleDialog open={this.state.showExport} onClose={() => this.setState({ showExport: false })} title="Search Other Institution">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', flex: 1 }}>
                                
                                <TextField
                                    name="searchText"
                                    onChange={this.updateSearch}
                                    fullWidth 
                                    variant="outlined"
                                    style={{ flex: 1, width: '100%', minHeight: "40px" }}
                                >{ this.state.searchTerm}</TextField>
                            <IconButton aria-label="search" onClick={() => this.executeSearch()}>
                                <FontAwesome name="search" />
                                </IconButton>
                            </div>
                            <ul>
                            {this.state.resList.map((prof) => (
                                <li key={prof.ipedNo}>
                                    <Link onClick={() => this.setIped(prof, form)}>{prof.name} - {prof.ipedNo}</Link>
                                </li>
                            ))}
                            </ul>
                        </SimpleDialog>
                    )}
                </FormSpy>
            </div>
        );
    }
}

export default IPEDLookup;
