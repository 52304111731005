import { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { logoutAction } from '../../../redux/login';

class AxiosInterceptor extends Component {
    componentDidMount() {
        axios.interceptors.response.use(null, this.onAxiosErrorResponse);
        axios.interceptors.request.use(this.addAuthBearer, null);
    }

    addAuthBearer = config => {
        let user = this.props.user;

        if (!user) {
            const json = sessionStorage.getItem('user');
            if (json) {
                user = JSON.parse(json);
            }
        }
        if (user !== null) {
            config.headers.authorization = `Bearer ${user.accessToken}`;
        }

        return config;
    };

    onAxiosErrorResponse = results => {
        if (results.response.status === 401) {
            this.props.logout();
            this.props.history.push('/login');
        }

        return Promise.reject(results);
    };

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutAction())
});

AxiosInterceptor = connect(
    mapStateToProps,
    mapDispatchToProps
)(AxiosInterceptor);
AxiosInterceptor = withRouter(AxiosInterceptor);

export default AxiosInterceptor;
