import React from 'react';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form';
import { required, renderTextArea } from '../../../../support/forms';

import './RejectApplicantModal.css';

let RejectApplicantModal = ({ isOpen, handleSubmit, onRequestClose, onSave }) => (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal reject-applicant-modal">
        <div>
            <h2>Reject Applicant</h2>
            <div>
                <span className="form-label">Please enter a rejection note:</span>
            </div>
            <Field component={renderTextArea} rows="10" name="note" validate={[required]} />
            <div className="button-container">
                <button className="btn" onClick={handleSubmit(onSave)}>
                    Ok
                </button>
                <button className="link-button" onClick={onRequestClose}>
                    Cancel
                </button>
            </div>
        </div>
    </Modal>
);

RejectApplicantModal = reduxForm({ form: 'RejectApplicantModal' })(RejectApplicantModal);

export default RejectApplicantModal;
