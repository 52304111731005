import axios from "axios";

export function create(values) {
    return axios.post("/api/services/app/RecruitmentCycle/Create", values);
}

export function remove(id) {
    return axios.delete("/api/services/app/RecruitmentCycle/Delete", { params: { id } });
}

export function getAll() {
    return axios.get("/api/services/app/RecruitmentCycle/GetAll");
}

export function update(values) {
    return axios.put("/api/services/app/RecruitmentCycle/Update", values);
}

export function getCurrentCycle() {
    return axios.get("/api/services/app/RecruitmentCycle/GetCurrentCycle");
}
