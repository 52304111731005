import axios from "axios";

export function createByUser() {
    return axios.post("/api/services/app/Application/CreateByUser");
}
export function createWithParent(SecondaryParentId) {
    return axios.get("/api/services/app/Application/CreateWithParent", { params: { secondaryParentId: SecondaryParentId } });
}
export function getByUser() {
    return axios.get("/api/services/app/Application/GetByUser");
}
export function getAllByUser() {
    return axios.get("/api/services/app/Application/GetAllByUser");
}

export function getParentStatusByUser() {
    return axios.get("/api/services/app/Application/GetParentStatusByUser");
}

export function getById(id) {
    return axios.get("/api/services/app/Application/GetById", { params: { applicationId: id } });
}

export function getApplicationIdForStudent(userProfileId) {
    return axios.get("/api/services/app/Application/RetrieveApplicationIdForStudent", { params: { userProfileId } });
}

export function addAnotherStudent(applicationId) {
    return axios.post("/api/services/app/Application/AddAnotherStudent", { id: applicationId });
}

export function removeStudent(applicationId, userProfileId) {
    return axios.delete("/api/services/app/Application/RemoveStudent", { params: { applicationId, userProfileId } });
}

export function deleteEntireApplication() {
    return axios.delete("/api/services/app/Application/DeleteEntireApplication");
}
export function deleteApplication(applicationId) {
    return axios.delete(`/api/services/app/Application/DeleteApplication`, {
        params: {
            ApplicationId: applicationId
        }
    });
}
export function submitApplication(applicationId) {
    return axios.post("/api/services/app/Application/SubmitApplication", { id: applicationId });
}

export function markComplete(applicationId) {
    return axios.post("/api/services/app/Application/MarkComplete", { id: applicationId });
}

export function markResubmit(applicationId) {
    return axios.post("/api/services/app/Application/MarkResubmit", { id: applicationId });
}

export function rejectApplication(applicationId, rejectReason) {
    return axios.post("/api/services/app/Application/RejectApplication", { applicationId, rejectReason });
}

export function rejectApplicant(applicationId, note) {
    return axios.post("/api/services/app/Application/RejectApplicant", { applicationId, note });
}

export function toggleLunchPending(applicationId, isPending) {
    return axios.post("/api/services/app/Application/ToggleLunchPending", { applicationId, isPending });
}
export function clearLunchPending(applicationId) {
    return axios.post("/api/services/app/Application/ClearLunchPending", { applicationId, isPending: false });
}

export function addEventInvite(applicationId, events) {
    return axios.post("/api/services/app/Application/AddEventInvite", { applicationId, events });
}

export function updateNotes(applicationId, notes) {
    return axios.put("/api/services/app/Application/UpdateNotes", { applicationId, notes });
}

export function getNotes(applicationId) {
    return axios.get("/api/services/app/Application/GetNotes", { params: { id: applicationId } });
}

export function unregister(applicationId) {
    return axios.post("/api/services/app/Application/Unregister", { id: applicationId });
}

export function markAttended(applicationId) {
    return axios.post("/api/services/app/Application/ToggleAttendedEvent", { id: applicationId });
}

export function confirmOrientationVideo(applicationId, confirmed) {
    return axios.post("/api/services/app/Application/ConfirmOrientationVideo", { applicationId, confirmed });
}

export function getApplicationRecruitmentCycle(applicationId) {
    return axios.get("/api/services/app/Application/GetApplicationRecruitmentCycle", { params: { applicationId } });
}

export function getApplicationSiblingsByApplicationId(applicationId) {
    return axios.get("/api/services/app/Application/GetApplicationSiblingsByApplicationId", { params: { applicationId } });
}

export function getApplicationSiblings() {
    return axios.get("/api/services/app/Application/GetApplicationSiblings");
}

export function addApplicationSibling(input) {
    return axios.post("/api/services/app/Application/AddApplicationSibling", input);
}

export function updateApplicationSibling(input) {
    return axios.put("/api/services/app/Application/UpdateApplicationSibling", input);
}

export function deleteApplicationSibling(input) {
    return axios.post("/api/services/app/Application/DeleteApplicationSibling", input);
}
export const getSecondaryParentsForFamily = familyId => {
    return axios.get('/api/services/app/Application/GetSecondaryParentsForFamily', { params: { familyId } });
};