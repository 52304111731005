import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";

class List extends Component {
    static permission = "Admin.Users";
    // onResendEmail = (id) => {
    //     this.props.ajaxStatusLoading();
    //     apis.resendEmail(id)
    //         .then((response) => {
    //             this.props.ajaxStatusComplete();
    //             toast.success(response.data.result);
    //         })
    //         .catch((error) => {
    //             this.props.ajaxStatusError(error.message);
    //         });
    // };

    render() {
        const columns = [
            {
                Header: "First Name",
                accessor: "firstName",
            },
            {
                Header: "Last Name",
                accessor: "lastName",
            },
            {
                Header: "Mobile Phone",
                accessor: "phoneNumber",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "User Name",
                accessor: "userName",
            },
            {
                Header: "Account?",
                accessor: "userId",
                width: 70,
                Cell: (row) => {
                    let hasAccount;
                    if ([8, 4].includes(this.props.currentRoleId)) {
                        // is parent or crusader
                        hasAccount = !!row.value ? "Yes" : "No";
                    } else {
                        hasAccount = "Yes"; // other profile types are always associated to an account
                    }
                    return <div style={{ textAlign: "center" }}>{hasAccount}</div>;
                },
            },
            {
                Header: "Active",
                accessor: "isActive",
                width: 70,
                Cell: (row) => {
                    const isActive = row.value ? "Yes" : "No";
                    return <div style={{ textAlign: "center" }}>{isActive}</div>;
                },
            },
            {
                Header: "Actions",
                accessor: "id",
                id: "id",
                width: 300,
                minWidth: 300,
                sortable: false,
                Cell: (row) => {
                    let url;
                    if (this.props.currentRoleId === 8) {
                        url = `/admin/users/crusader/${row.value}`;
                    } else if (this.props.currentRoleId === 4) {
                        url = `/admin/users/parent/${row.value}`;
                    } else {
                        url = `/admin/users/${row.value}`;
                    }
                    return (
                        <div
                            style={{
                                minWidth: "200px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "space-around",
                            }}
                        >
                            <Link to={url}>Edit</Link>
                            {/* <button className="btn resend-btn" onClick={this.onResendEmail.bind(null, row.value)}>
                                Resend Verification
                            </button> */}
                        </div>
                    );
                },
            },
        ];

        if (this.props.currentRoleId === 8 || this.props.currentRoleId === 4) {
            columns.splice(columns.length - 1, 0, {
                Header: "Mobile Registration",
                accessor: "completedRegistration",
                width: 150,
                Cell: (row) => {
                    const completedRegistration = row.value ? "Yes" : "No";
                    return <div style={{ textAlign: "center" }}>{completedRegistration}</div>;
                },
            });
        }

        return (
            <div className="admin-users-list">
                <ReactTable data={this.props.users} minRows={0} columns={columns} defaultPageSize={50} className="-striped -highlight" />
            </div>
        );
    }
}

List.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.email,
        })
    ),
};

List = withAjaxStatus(List);
export default List;
