import React, { useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import ReactTable from "react-table";
import { Button } from "@material-ui/core";

import ProgramCodeForm from "./programCodeForm";
import useProgramCodeHelpers from "./programCodeHelpers";
import SimpleDialog from "../../common/simpleDialog";

const useStyles = makeStyles((theme) => ({
    programCodeGrid: {},
}));

const gearUpActivityRenderHelper = (codes) => {
    let result = codes
        .reduce(
            (current, prev) =>
                current + " | " + (typeof prev.activityCode === "object" ? prev.activityCode.activityCode : prev.activityCode) + "-" + prev.shortDescription,
            ""
        )
        .slice(3);
    return result;
};

const coreOfferingRenderHelper = (codes) => {
    let result = codes.reduce((current, prev) => current + " | " + prev.abbreviation + "-" + prev.name, "").slice(3);
    return result;
};

const { sortProgramCodesAsc, sortActivityCodesAsc } = useProgramCodeHelpers();

const ProgramCodeGrid = ({ data, dataFilter, allActivityCodes, allCoreOfferings, programCodeOptions, onSave }) => {
    const classes = useStyles();

    const [editRowItem, setEditItem] = useState(null);

    const handleEditSubmit = (submitData) => {
        onSave(submitData, () => {
            setEditItem(null);
        });
    };

    return (
        <React.Fragment>
            <ReactTable
                data={data.filter(dataFilter)}
                minRows={0}
                columns={[
                    {
                        Header: "Program ID",
                        accessor: (d) => Number(d.programCode),
                        id: "programCode",
                        width: 100,
                    },
                    {
                        Header: "Program Description",
                        accessor: "programCodeDescription",
                        style: { whiteSpace: "unset" },
                        id: "programCodeDescription",
                    },
                    {
                        Header: "Subcode",
                        accessor: (d) => Number(d.subCode),
                        id: "subCode",
                        width: 100,
                    },
                    {
                        Header: "Subcode Description",
                        accessor: "subCodeDescription",
                        id: "subCodeDescription",
                    },
                    {
                        Header: "Gear Up Code(s)",
                        accessor: (d) => gearUpActivityRenderHelper(sortActivityCodesAsc(d.gearUpActivityCodes)),
                        id: "gearUpdCodes",
                        style: { whiteSpace: "unset" },
                    },
                    {
                        Header: "Core Offering(s)",
                        accessor: (d) => coreOfferingRenderHelper(sortProgramCodesAsc(d.coreOfferings)),
                        id: "coreOfferings",
                        style: { whiteSpace: "unset" },
                    },
                    {
                        sortable: false,
                        width: 75,
                        Cell: (row) => {
                            return (
                                <React.Fragment>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditItem(row.original);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </React.Fragment>
                            );
                        },
                    },
                ]}
                defaultSorted={[
                    {
                        id: "programCode",
                        desc: false,
                    },
                ]}
                defaultPageSize={50}
                className="-striped -highlight"
            />
            {Boolean(editRowItem) && (
                <SimpleDialog
                    className={classes.addEventDialog}
                    open={Boolean(editRowItem)}
                    onClose={() => setEditItem(null)}
                    title="Edit Program Code/Subcode"
                    maxWidth="xl"
                    fullWidth
                >
                    {
                        <ProgramCodeForm
                            allActivityCodes={allActivityCodes}
                            allCoreOfferings={allCoreOfferings}
                            programData={editRowItem}
                            saveButtonText="Save"
                            onSubmit={handleEditSubmit}
                            programCodeOptions={programCodeOptions}
                        />
                    }
                </SimpleDialog>
            )}
        </React.Fragment>
    );
};
export default ProgramCodeGrid;
