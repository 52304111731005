import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { injectIntl, FormattedMessage } from "react-intl";
import Button from "../../support/components/Button";
import { Gender, ApplicationStatus, Race } from "../logic/enums";
import withPromptChange from "../../support/hoc/withPromptChange";
import SimpleDialog from "../../common/simpleDialog";
import { getRaceValue } from "./getRaceValue";
import * as ApplicationApi from "../../api/applications";
import * as UserProfileApi from "../../api/userProfile";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import withCodeTables from "../../support/hoc/withCodeTables";
import withAdmissions from "../../support/hoc/withAdmissions";
import Student from "./student";
import { ThemeProvider } from "@material-ui/core/styles";
import { dateOfBirthFromFormToString } from "../../support/forms";
import { createCCMUITheme } from "../../support/muiTheme";
import { SecurityContext } from "../../support/security";

const FORM_NAME = "AdmissionsStudent";

class StudentContainer extends Component {
    state = {
        studentId: null,
        showConfirmRemoveStudent: false,
        recruitmentCycle: null,
        readOnly: false,
        showDupeConfirm: false,
        formValues: [],
        dupApp: []
    };

    isStaffReview = false;

    async componentDidMount() {
        if (this.props.admissions === null) {
            return;
        }
        let userProfileId = "";
        try {
            this.isStaffReview = this.props.match.params && this.props.match.params.id !== undefined;

            userProfileId = this.props.admissions.userProfiles[0].userProfileId;


        } catch (e) {
            //console.log(e);
        }
        //console.log(this.props);
        await this.loadProfile(userProfileId);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.admissions === null && this.props.admissions !== null) {
            const userProfileId = this.props.admissions.userProfiles[0].userProfileId;
            this.loadProfile(userProfileId);
        }

        if (prevProps.gender === Gender.Other.toString() && this.props.gender !== Gender.Other.toString()) {
            this.props.dispatch(change("AdmissionsStudent", "genderDescription", ""));
        }
    }

    async loadProfile(profileId, forceRefresh) {
        if (!forceRefresh && profileId === this.state.studentId) {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const data = (await UserProfileApi.getProfile(profileId)).data.result;
            const recruitmentCycle = (await ApplicationApi.getApplicationRecruitmentCycle(this.props.admissions.id)).data.result;
            this.props.ajaxStatusComplete();

            this.setState({ studentId: profileId, recruitmentCycle });

            const dateOfBirth = { year: "", month: "", day: "" };
            if (data.dateOfBirth !== null) {
                const mntDOB = moment.utc(data.dateOfBirth);
                dateOfBirth.year = mntDOB.year().toString();
                dateOfBirth.month = mntDOB.month().toString();
                dateOfBirth.day = mntDOB.date().toString();
            }

            const applicationUserProfile = this.props.admissions.userProfiles.find((u) => u.userProfileId === profileId);
            let schoolId = '';
            if (applicationUserProfile != null) {
                schoolId = applicationUserProfile.schoolId;
            }
            this.props.initialize({
                ...data,
                gender: data.gender !== null ? data.gender.toString() : null,
                citizenshipType: data.citizenshipType !== null ? data.citizenshipType.toString() : null,
                hispanic: data.hispanic !== null ? (data.hispanic ? "1" : "0") : null,
                raceWhite: this.isFlagSet(data.otherRace, Race.White),
                raceBlack: this.isFlagSet(data.otherRace, Race.Black),
                raceAsian: this.isFlagSet(data.otherRace, Race.Asian),
                raceIndian: this.isFlagSet(data.otherRace, Race.Indian),
                raceHawaiian: this.isFlagSet(data.otherRace, Race.Hawaiian),
                dateOfBirth: dateOfBirth,
                schoolId: schoolId
            });
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    handleSave = async (values) => {
        //console.log(this.props.admissions);
        if (this.props.admissions.progress.tabProgress["Admissions.Student"] == false) {
            if (this.props.checkForDuplicateApp != null) {
                const dupApp = this.props.checkForDuplicateApp(values);
                //console.log(dupApp);
                if (dupApp == null || dupApp == false) {
                    await this.doSave(values);
                } else {
                    this.setState({ dupApp: dupApp, formValues: values, showDupeConfirm: true });
                }
                return;
            }
        } else {
            await this.doSave(values);
        }
         
        
        };


doSave = async (values) => {
    const dateOfBirth = dateOfBirthFromFormToString(values.dateOfBirth, this.props.intl.locale);
    const input = {
        ...values,
        dateOfBirth,
        hispanic: values.hispanic ? (values.hispanic === "1" ? true : false) : null,
        race: values.race,
        otherRace: getRaceValue(values),
        applicationId: this.props.admissions.id,
    };

    try {
        this.props.ajaxStatusLoading();
        await UserProfileApi.updateStudentInfo(input);
        if (this.isStaffReview) {
            this.props.refreshApplication();
            this.loadProfile(this.state.studentId, true);
        } else {
            this.props.loadApplication(this.props.admissions.id);
        }
        this.props.ajaxStatusComplete();
        toast.success("Save Complete");
        window.scrollTo(0, 0);
    } catch (err) {
        this.props.ajaxStatusError(err);
    }

}
isFlagSet(value, enumValue) {
    if (value === null) {
        return false;
    }

    return (value & enumValue) === enumValue;
}

handleCancel = () => {
    this.props.reset();
};

mapSchools() {
    const schools = this.props.schools.filter((s) => s.type === 0 && s.isActive).map((item) => ({ label: item.name, value: item.id }));
    const selectMessage = this.props.intl.formatMessage({ id: "app.school.select", defaultMessage: "[student.school.select]" });

    schools.unshift({ label: `-- ${selectMessage} --`, value: "" });

    return schools;
}

handleAddStudent = async () => {
    try {
        this.props.ajaxStatusLoading();
        await ApplicationApi.addAnotherStudent(this.props.admissions.id);
        if (this.isStaffReview) {
            this.props.refreshApplication();
            this.loadProfile(this.state.studentId, true);
        } else {
            this.props.loadApplication(this.props.admissions.id)
        }
        this.props.ajaxStatusComplete();
    } catch (err) {
        this.props.ajaxStatusError(err);
    }
};

handleChangeStudent = (studentId) => {
    this.loadProfile(studentId);
};

handleDeleteStudent = () => {
    this.setState({ showConfirmRemoveStudent: true });
};

handleConfirmRemoveStudent = () => {
    this.setState({ showConfirmRemoveStudent: false }, async () => {
        try {
            this.props.ajaxStatusLoading();
            await ApplicationApi.removeStudent(this.props.admissions.id, this.state.studentId);
            if (this.isStaffReview) {
                this.props.refreshApplication();
            } else {
                this.props.loadApplication(this.props.admissions.id)
            }
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    });
};

handleCancelRemoveStudent = () => {
    this.setState({ showConfirmRemoveStudent: false });
};

getStudentAppId() {
    if (this.state.studentId === null) {
        return "";
    }

    const student = this.props.admissions.userProfiles.find((p) => p.userProfileId === this.state.studentId);

    if (student === undefined) {
        return "";
    }

    return student.id;
}
dupChoiceEdit = () => {
    this.setState({ dupApp: [], formValues: [], showDupeConfirm: false });
}

 dupChoiceContinue = async () => {

        this.setState({ dupApp: [], formValues: [], showDupeConfirm: false });
        await this.doSave(this.state.formValues);
}

dupChoiceDelete = () => {
    this.setState({ dupApp: [], formValues: [], showDupeConfirm: false });
    this.props.appDelete(this.props.admissions);
}

render() {
    if (this.props.admissions === null || this.state.studentId == null) {
        return null;
    }

    let readonly =
        this.props.admissions.status !== ApplicationStatus.NotSubmitted &&
        this.props.admissions.status !== ApplicationStatus.Rejected &&
        this.props.admissions.status !== ApplicationStatus.LunchPending &&
        this.props.admissions.status !== ApplicationStatus.Resubmit &&
        this.props.admissions.status !== ApplicationStatus.Accepted;
    if (this.props.readonly || this.props.admissions.status === ApplicationStatus.Submitted) {
        readonly = true;
    }
    const { showConfirmRemoveStudent } = this.state;
    //console.log(readonly);

    return (
        <ThemeProvider theme={createCCMUITheme()}>
            <SecurityContext.Consumer>
                {context => {
                    return (
                        <Student
                            race={this.props.race}
                            recruitmentCycle={this.state.recruitmentCycle}
                            isStaff={this.isStaffReview}
                            admissions={this.props.admissions}
                            selectedGender={this.props.gender}
                            schools={this.mapSchools()}
                            studentAppId={this.getStudentAppId()}
                            handleSave={this.handleSave}
                            handleCancel={this.handleCancel}
                            handleAddStudent={this.handleAddStudent}
                            handleDeleteStudent={this.handleDeleteStudent}
                            handleChangeStudent={this.handleChangeStudent}
                            showConfirmRemoveStudent={showConfirmRemoveStudent}
                            handleConfirmRemoveStudent={this.handleConfirmRemoveStudent}
                            handleCancelRemoveStudent={this.handleCancelRemoveStudent}
                            readonly={context.hasEditPermission("Admin.Admissions") ? false : readonly}
                        />
                    );
                }}
            </SecurityContext.Consumer>

            <FormattedMessage
                id="app.duplicate.found"
                defaultMessage="Warning: Duplicate application detected!"
                children={(formattedMessage) => (
                    <SimpleDialog open={this.state.showDupeConfirm} onClose={this.hideSec} title={formattedMessage} showCloseButton={true}>
                        <div>


                            {this.state.dupApp.map(app => {
                                //console.log(app);

                                return (
                                    <span>
                                        {app.userProfiles[0].firstName} {app.userProfiles[0].lastName}
                                        &nbsp;<br /><br />

                                    </span>
                                );
                            }
                            )
                            }

                            <FormattedMessage
                                id="app.duplicate.delete"
                                defaultMessage="Close &amp; Delte"
                                children={(closeandDelete) => (<Button
                                id="application.view"
                                defaultMessage="Continue"
                                className={`new-app-button`
                                }
                                onClick={() => this.dupChoiceDelete()}

                                >{closeandDelete}</Button>)} 
                            /> &nbsp;

                            <FormattedMessage
                                id="app.duplicate.continue"
                                defaultMessage="Continue Application"
                                children={(contapp) => (<Button
                                    id="application.view"
                                    defaultMessage="Go Back"
                                    className={`new-app-button`
                                    }
                                    onClick={() => this.dupChoiceContinue()}

                                >{contapp}</Button>)}
                            />
                        </div>
                    </SimpleDialog>
                )}
            />
        </ThemeProvider>

    );
}
}

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
    gender: selector(state, "gender"),
    race: selector(state, "race"),
});

StudentContainer = connect(mapStateToProps)(StudentContainer);
StudentContainer = reduxForm({ form: FORM_NAME })(StudentContainer);
StudentContainer = withAdmissions(StudentContainer);
StudentContainer = withAjaxStatus(StudentContainer);
StudentContainer = withCodeTables(StudentContainer);
StudentContainer = injectIntl(StudentContainer);
StudentContainer = withPromptChange(StudentContainer, FORM_NAME);
StudentContainer = withRouter(StudentContainer);

export default StudentContainer;
