import React from 'react';
import PropTypes from 'prop-types';

import './AllocatedByGrade.css';

const AllocatedByGrade = ({ yearName, year, number, amount, donation, onNumberChange, onAmountChange, isReadonly }) => {
    return (
        <div className="scholarship-programs-allocated">
            <span className="scholarship-programs-allocated-name">{yearName}</span>
            <span>{year}</span>
            <input type="text" value={number} onChange={onNumberChange} disabled={isReadonly} />
            <input type="text" value={amount} onChange={onAmountChange} disabled={isReadonly} />
            <span>{donation}</span>
        </div>
    );
};

AllocatedByGrade.propTypes = {
    yearName: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    number: PropTypes.number,
    amount: PropTypes.string,
    donation: PropTypes.string,
    onNumberChange: PropTypes.func.isRequired,
    onAmountChange: PropTypes.func.isRequired,
    isReadonly: PropTypes.bool.isRequired
};
export default AllocatedByGrade;
