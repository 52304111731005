export const createStructure = breakdowns => {
    const list1 = [];
    const list2 = [];
    const list3 = [];
    for (let index = 0, len = breakdowns.length; index < len; index++) {
        const breakdown = breakdowns[index];
        let list = list1;
        if (breakdown.type === 2) {
            list = list2;
        }
        if (breakdown.type === 3) {
            list = list3;
        }
        list.push({
            id: breakdown.id,
            scholarshipId: breakdown.scholarshipId,
            level: breakdown.level,
            type: breakdown.type,
            fullAmount: breakdown.amount,
            threeQuarterAmount: breakdown.amount * 0.75,
            halfAmount: breakdown.amount * 0.5,
            lessThanHalf: breakdown.amount * 0.25
        });
    }

    return {
        four: list1,
        two: list2, 
        trade: list3
    };
};
