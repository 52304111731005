import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useStyles } from "./styles";

const ApproveButton = ({ disabled = false, handleOnClick = () => {} }) => {
    const classes = useStyles();

    return (
        <>
            {disabled ? (
                <Tooltip title="Program Codes must be applied" placement="right" classes={{ tooltip: classes.customColor }}>
                    <span>
                        <Button variant="contained" color="primary" disabled>
                            Approve
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button variant="contained" color="primary" onClick={handleOnClick}>
                    Approve
                </Button>
            )}
        </>
    );
};

export default ApproveButton;
