const AJAX_STATUS_LOADING = "AJAX_STATUS_LOADING";
const AJAX_STATUS_COMPLETE = "AJAX_STATUS_COMPLETE";
const AJAX_STATUS_ERROR = "AJAX_STATUS_ERROR";
const AJAX_STATUS_CLEAR_ALL = "AJAX_STATUS_CLEAR_ALL";

export const ajaxClearAll = () => {
    return { type: AJAX_STATUS_CLEAR_ALL };
};
export const ajaxStatusLoadingAction = () => {
    return { type: AJAX_STATUS_LOADING };
};

export const ajaxStatusCompleteAction = () => {
    return { type: AJAX_STATUS_COMPLETE };
};

export const ajaxStatusErrorAction = (error) => {
    let message = "";
    if (error && error.message) {
        message = error.message;
    } else if (error) {
        message = error;
    }
    return { type: AJAX_STATUS_ERROR, message };
};

const isCallSuccessful = (type) => {
    return type.indexOf("_SUCCESS") !== -1;
};

export const ajaxCallsInProgressReducer = (state = 0, action) => {
    if (action.type === AJAX_STATUS_LOADING) {
        return state + 1;
    } else if (action.type === AJAX_STATUS_CLEAR_ALL) {
        return 0;
    } else if (action.type === AJAX_STATUS_COMPLETE || action.type === AJAX_STATUS_ERROR || isCallSuccessful(action.type)) {
        if (state !== 0) {
            return state - 1;
        }
    }

    return state;
};

export const errorMessageReducer = (state = "", action) => {
    if (action.type === AJAX_STATUS_ERROR) {
        return action.message;
    }

    return state;
};
