import axios from "axios";

export const searchStudent = (scholarshipId, searchText) => {
  return axios.post("/api/services/app/ScholarshipCertification/SearchStudent", {
    scholarshipId,
    searchText,
  });
};

export const getPayoutCounts = (scholarshipId, userProfileId) => {
  return axios.get("/api/services/app/ScholarshipCertification/GetPayoutCounts", {
    params: { scholarshipId, userProfileId },
  });
};

export const searchEligibleCrusaders = (input) => {
  return axios.post("/api/services/app/ScholarshipCertification/SearchEligibleCrusaders", input);
};
