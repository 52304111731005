import { formatCurrencyNoDecimal } from "../../support/numberSupport";

const getNameFromIndex = (index) => {
    if (index === 1) return "Sophomore";
    if (index === 2) return "Junior";
    if (index === 3) return "Senior";

    return "Freshman";
};

const populateFromAllocated = (breakdowns, data) => {
    let number = 0;
    let amount = 0;
    for (let index = 0; index < breakdowns.length; index++) {
        const breakdown = breakdowns[index];
        number += breakdown.numberOfScholarships;
        amount += breakdown.amountPerScholarship * breakdown.numberOfScholarships;
    }
    data.numberOfScholarships = number;
    data.totalAmount = amount;

    return {
        number,
        amount: formatCurrencyNoDecimal(amount),
    };
};
const populateFromAnnual = (annualAmount, annualTotal, index, breakdown) => {
    let amount = 0;
    let total = 0;
    let donation = 0;
    if (annualAmount && annualTotal) {
        total = Math.floor(annualTotal / 4);
        amount = Math.floor(annualAmount / 4 / total);
        donation = total * amount;
    }

    breakdown.amountPerScholarship = amount;
    breakdown.numberOfScholarships = total;

    return {
        id: breakdown.id,
        yearName: getNameFromIndex(index),
        year: breakdown.year,
        number: total,
        amount: formatCurrencyNoDecimal(amount),
        donation: formatCurrencyNoDecimal(donation),
    };
};
const populateFromServerData = (data) => {
    const institution = data.scholarshipInstitution;
    const breakdowns = data.scholarshipInstitutionBreakdowns;
    const years = [];

    for (let index = 0; index < breakdowns.length; index++) {
        const breakdown = breakdowns[index];
        const amount = formatCurrencyNoDecimal(breakdown.amountPerScholarship);
        const donation = formatCurrencyNoDecimal(breakdown.numberOfScholarships * breakdown.amountPerScholarship);
        years.push({
            id: breakdown.id,
            yearName: getNameFromIndex(index),
            year: breakdown.year,
            number: breakdown.numberOfScholarships,
            amount,
            donation,
        });
    }
    return {
        id: institution.id,
        allocationType: institution.allocationType,
        perStudentDonationAmount: institution.perStudentDonationAmount,
        number: institution.numberOfScholarships,
        amount: formatCurrencyNoDecimal(institution.totalAmount),
        years,
    };
};
/*
 *  This method creates a formated display object from
 *  the raw data object (from the server)
 *
 * @param data: the raw data object from the server
 * @param priority: determines if the data is updated
 *                  from the  ANNUAL amounts or from
 *                  the Allocated ("breakdown") amounts
 */
export const formatProgramDataForUI = (data, priority) => {
    //no priority data from server via api call
    if (!priority) return populateFromServerData(data);

    const institution = data.scholarshipInstitution;
    const breakdowns = data.scholarshipInstitutionBreakdowns;
    const totalNumber = institution.numberOfScholarships;
    const totalAmount = institution.totalAmount;
    const years = [];

    if (priority === 1) {
        //change came from Annual fields being updated
        for (let index = 0; index < breakdowns.length; index++) {
            years.push(populateFromAnnual(totalAmount, totalNumber, index, breakdowns[index]));
        }
        return {
            id: institution.id,
            allocationType: institution.allocationType,
            perStudentDonationAmount: institution.perStudentDonationAmount,
            number: totalNumber,
            amount: formatCurrencyNoDecimal(totalAmount),
            years,
        };
    }

    for (let index = 0; index < breakdowns.length; index++) {
        const breakdown = breakdowns[index];
        const amount = formatCurrencyNoDecimal(breakdown.amountPerScholarship);
        const donation = formatCurrencyNoDecimal(breakdown.numberOfScholarships * breakdown.amountPerScholarship);
        years.push({
            id: breakdown.id,
            yearName: getNameFromIndex(index),
            year: breakdown.year,
            number: breakdown.numberOfScholarships,
            amount,
            donation,
        });
    }

    const record = populateFromAllocated(breakdowns, data);
    record.id = institution.id;
    record.allocationType = institution.allocationType;
    record.perStudentDonationAmount = institution.perStudentDonationAmount;
    record.years = years;
    return record;
};
