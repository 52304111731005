import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { loadIdentifiers, loadApplication, refreshApplication, loadApplicationFromValues } from "../../admissions/logic/admissionsRedux";

const useAdmissions = () => {
    const admissions = useSelector((state) => state.admissions);
    const dispatch = useDispatch();

    return {
        admissions,        
        loadIdentifiers: useCallback(() => dispatch(loadIdentifiers()), [dispatch]),
        loadApplication: useCallback((applicationId) => dispatch(loadApplication(applicationId)), [dispatch]),
        refreshApplication: useCallback(() => dispatch(refreshApplication()), [dispatch]),
        loadApplicationFromValues: useCallback((app) => dispatch(loadApplicationFromValues(app)), [dispatch]),
    };
};

export default useAdmissions;
