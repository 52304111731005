import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./Button.css";

export const Button = ({ onClick, id, defaultMessage, className, type, invalid, children }) => {
    const clsName = className ? `btn ${className}` : "btn";
    const enableDisable = invalid ? "btn-disabled" : "btn-enabled";
    const style = `${clsName} ${enableDisable}`;
    const btnType = type ? type : "button";

    if (children) {
        return (
            <button type={btnType} className={style} onClick={onClick} disabled={invalid}>
                {children}
            </button>
        );
    }

    return (
        <FormattedMessage
            id={id}
            defaultMessage={defaultMessage}
            children={(formatedMessage) => (
                <button type={btnType} className={style} onClick={onClick} disabled={invalid}>
                    {formatedMessage}
                </button>
            )}
        />
    );
};

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    invalid: PropTypes.bool,
};

export default Button;
