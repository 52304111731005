import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AppReport from './appReport';
import StaffLayout from "../common/staffLayout";

import "./index.css";

class ReportsContainer extends React.Component {
    static permission = "Applications.Reports";

    componentDidMount() {
        this.props.loadData();
    }

    render() {
        const totalReports = this.props.data.length;
        const { data, selectedCycle, search } = this.props;

        return (
            <StaffLayout>
                <div className="application-reports">
                    <h3>Total Reports: {totalReports}</h3>
                    <AppReport data={data} selectedCycle={selectedCycle} search={search}/>
                </div>
            </StaffLayout>
        );
    }
}

ReportsContainer.propTypes = {
    data: PropTypes.array,
};

ReportsContainer = withRouter(ReportsContainer);

export default ReportsContainer;
