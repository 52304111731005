import React, { Component } from "react";
import { reduxForm, Field, reset, formValueSelector } from "redux-form";
import { required, renderField } from "../../../support/forms";
import { renderSelectList, renderSelectIdNameField } from "../../../support/forms";
import { FinalFormCheckbox, FinalFormTextField } from "../../../common/formWrappers";
import { unassignDirectorAdvisor, setDirector, getAdvisorBasics, getPotentialAdvisorSubordinates, assignAdvisorSchool, unassignAdvisorSchool, assignDirectorAdvisor, setGap, setHonorific, setAccelerate, getAdvisorGoals, setAdvisorGoal, removeAdvisorGoal, setDepHighSchool, setDepMiddleSchool, setDepFamilyEngagement, setDepPostSecondary, getObjectives, addAdvisorGoal, updateAdvisorGoal, deleteAdvisorGoal, returnAdvisorBasicsAsync, getCode, assignAdvisorInstitution, unassignAdvisorInstitution } from "./apis";
import Multiselect from 'multiselect-react-dropdown';
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from '@mui/material';
import AdvisorGoalsManager from "./advisorgoalsform";
import * as taggingApi from "../../../api/tagging";
import "./edit.css";

class Advisor extends Component {


    state = {
        advisorInfo: [],
        potSubords: [],
        schools: [],
        institutions: [],
        subs: [],
        showSubs: false,
        goals: [],
        goalsList: [],
        taggingOptions: [],
        isGAP: false,
        isHonorific: false,
        isAccelerate: false,
        depFamilyEngagement: false,
        depMiddleSchool: false,
        depHighSchool: false,
        depPostSecondary: false,
        disabled: true,
        availableObj: [],
        objTags: [],
        loaded: false
    };

    componentDidMount = async () => {
        const input = { id: this.props.userId };

        const adInf = await returnAdvisorBasicsAsync(input);
        const potSub = await getPotentialAdvisorSubordinates(adInf.data.result.userProfileId);
        const goals = await getAdvisorGoals(adInf.data.result.userProfileId);
        const taggingOptions = await taggingApi.getActiveTags();
        const obj = await getObjectives();
        const inf = adInf.data.result;
        const sup = adInf.data.result.isSupervisor;
        const toss = taggingOptions.data.result.filter(flt => flt.classification.advisorGoals == true);
        const objTags = taggingOptions.data.result.filter(flt => flt.classification.middleSchoolGoals == true || flt.classification.highSchoolGoals == true || flt.classification.postsecondaryGoals == true || flt.classification.familyEngagementGoals == true || flt.classification.gapGoals == true || flt.classification.honorificGoals == true || flt.classification.postsecondaryGoals == true || flt.classification.familyEngagementGoals == true || flt.classification.gapGoals == true || flt.classification.accelerateGoals == true);


        let tl = [];
        if (adInf.data.result.isStaff || adInf.data.result.isAdmin) {
            sup = false;
        }
        for (const tos of toss) {

            tl.push({ color: tos.classification.color, id: tos.id, label: tos.name });


        }

        const advGoals = goals.data.result;


        let schools = [];
        let institutions = [];
        let subs = [];
        if (adInf.data.result.schools != null) {
            adInf.data.result.schools.forEach((sch) => {

                let schInfo = this.props.schools.filter((school) => school.id == sch.schoolId)[0];

                schools.push(schInfo);
            });

        }
        if (adInf.data.result.institutions != null) {
            adInf.data.result.institutions.forEach((inst) => {

                let schInfo = this.props.institutions.filter((institution) => institution.id == inst.institutionId)[0];

                institutions.push(schInfo);
            });

        }

        const potSubs = potSub.data.result;

        if (adInf.data.result.subordinates != null && !adInf.data.result.isStaff && !adInf.data.result.isAdmin) {

            adInf.data.result.subordinates.forEach((sub) => {


                const subInfo = potSubs.filter((sb) => sb.userProfileId == sub.userProfileId);

                subs.push(subInfo[0]);
            });

        }


        this.setState({
            advisorInfo: inf,
            isGAP: inf.isGAP,
            isHonorific: inf.isHonorific,
            isAccelerate: inf.isAccelerate,
            depFamilyEngagement: inf.depFamilyEngagement,
            depMiddleSchool: inf.depMiddleSchool,
            depHighSchool: inf.depHighSchool,
            depPostSecondary: inf.depPostSecondary,
            potSubords: potSubs,
            showSubs: sup,
            schools: schools,
            subs: subs,
            goals: advGoals,
            goalsList: tl,
            taggingOptions: taggingOptions.data.result,
            disabled: false, //this.props.readonly,
            availableObj: obj.data.result,
            objTags: objTags,
            loaded: true,
            institutions: institutions
        });
    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    onSchoolSelect = async (selectedList, selectedItem) => {

        const data = { schoolId: selectedItem.id, userProfileId: this.state.advisorInfo.userProfileId };
        await assignAdvisorSchool(data);
    }
    onSchoolRemove = async (selectedList, removedItem) => {


        const data = { schoolId: removedItem.id, userProfileId: this.state.advisorInfo.userProfileId };
        await unassignAdvisorSchool(data);
    }

    directorChanged = async () => {
        let subs = [];
        const data = { userProfileId: this.state.advisorInfo.userProfileId, IsSupervisor: !this.state.showSubs };
        await setDirector(data);
        const adInf = await getAdvisorBasics(this.props.userId);

        adInf.data.result.subordinates.forEach((sub) => {


            const subInfo = this.state.potSubords.filter((sb) => sb.userProfileId == sub.userProfileId);

            subs.push(subInfo[0]);
        });

        this.setState({ showSubs: !this.state.showSubs, subs: subs, advisorInfo: adInf.data.result });
    }



    onSubSelect = async (selectedList, selectedItem) => {
        const data = { userProfileId: this.state.advisorInfo.userProfileId, subordinateUserProfileId: selectedItem.userProfileId }
        await assignDirectorAdvisor(data);
    }

    onSubRemove = async (selectedList, removedItem) => {
        const data = { userProfileId: this.state.advisorInfo.userProfileId, subordinateUserProfileId: removedItem.userProfileId }
        await unassignDirectorAdvisor(data);

    }


    onObjSelect = async (selectedList, selectedItem) => {
        const data = { AdvisorId: this.state.advisorInfo.userProfileId, TagsId: selectedItem.id, SubtagId: null }
        await setAdvisorGoal(data);
    }

    onObjRemove = async (selectedList, removedItem) => {
        const data = { AdvisorId: this.state.advisorInfo.userProfileId, TagsId: removedItem.id, SubtagId: null }
        await removeAdvisorGoal(data);

    }

    gapChanged = async () => {
        this.setState({ disabled: true }); // Set disabled to true at the start

        await this.setStateAsync(prevState => ({
            isGAP: !prevState.isGAP
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            IsGAP: this.state.isGAP
        };
        await setGap(data);

        this.setState({ disabled: false }); // Set disabled back to false at the end
    }

    honorificChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            isHonorific: !prevState.isHonorific
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            isHonorific: this.state.isHonorific
        };
        await setHonorific(data);

        this.setState({ disabled: false });
    }

    accelerateChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            isAccelerate: !prevState.isAccelerate
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            isAccelerate: this.state.isAccelerate
        };
        await setAccelerate(data);

        this.setState({ disabled: false });
    }

    depFamilyEngagementChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            depFamilyEngagement: !prevState.depFamilyEngagement
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            depFamilyEngagement: this.state.depFamilyEngagement
        };
        await setDepFamilyEngagement(data);

        this.setState({ disabled: false });
    }

    depMiddleSchoolChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            depMiddleSchool: !prevState.depMiddleSchool
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            depMiddleSchool: this.state.depMiddleSchool
        };
        await setDepMiddleSchool(data);

        this.setState({ disabled: false });
    }

    depHighSchoolChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            depHighSchool: !prevState.depHighSchool
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            depHighSchool: this.state.depHighSchool
        };
        //      //console.log(data);
        await setDepHighSchool(data);

        this.setState({ disabled: false });
    }

    depPostSecondaryChanged = async () => {
        this.setState({ disabled: true });

        await this.setStateAsync(prevState => ({
            depPostSecondary: !prevState.depPostSecondary
        }));
        const data = {
            userProfileId: this.state.advisorInfo.userProfileId,
            depPostSecondary: this.state.depPostSecondary
        };
        await setDepPostSecondary(data);

        this.setState({ disabled: false });
    }

    addAdvisorGoal = async (input) => {
        await addAdvisorGoal(input);
        const goals = await getAdvisorGoals(this.state.advisorInfo.userProfileId);
        this.setState({ goals: goals.data.result });
    }

    updateAdvisorGoal = async (input) => {
        await updateAdvisorGoal(input);
        const goals = await getAdvisorGoals(this.state.advisorInfo.userProfileId);
        this.setState({ goals: goals.data.result });
    }


    deleteAdvisorGoal = async (input) => {
        await deleteAdvisorGoal(input);
        const goals = await getAdvisorGoals(this.state.advisorInfo.userProfileId);
        this.setState({ goals: goals.data.result });
    }

    onInstSelect = async (selectedList, selectedItem) => {

        const data = { institutionId: selectedItem.id, userProfileId: this.state.advisorInfo.userProfileId };
        await assignAdvisorInstitution(data);

    }
    onInstRemove = async (selectedList, removedItem) => {


        const data = { institutionId: removedItem.id, userProfileId: this.state.advisorInfo.userProfileId };
        await unassignAdvisorInstitution(data);



    }

    render() {
        //console.log(this);
        if (!this.state.loaded) {

            return (
                <div>
                    Loading ...
                </div>

            )
        }


        return (
            <React.Fragment>
                <div className="objectivesSelect">
                    <br />
                    <span>Goals</span>
                    <AdvisorGoalsManager
                        taggingOptions={this.state.objTags} // Options to display in the dropdown
                        selectedValues={this.state.goals} // Preselected value to persist in dropdown
                        onSelect={this.onObjSelect} // Function will trigger on select event
                        onRemove={this.onObjRemove} // Function will trigger on remove event
                        displayValue="label" // Property name to display in the dropdown options
                        className="AdvisorObjectivesSelector"
                        disabled={this.state.disabled}
                        availableObjectives={this.state.availableObj}
                        onAdd={this.addAdvisorGoal}
                        onUpdate={this.updateAdvisorGoal}
                        onDelete={this.deleteAdvisorGoal}
                        advisorId={this.state.advisorInfo.userProfileId}
                    />

                </div>
                <span>Schools</span>
                <Multiselect
                    options={this.props.schools} // Options to display in the dropdown
                    selectedValues={this.state.schools} // Preselected value to persist in dropdown
                    onSelect={this.onSchoolSelect} // Function will trigger on select event
                    onRemove={this.onSchoolRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    className="AdvisorSchoolSelector"
                    disabled={this.state.disabled}

                />

                <br />
                <span>Institutions</span>
                <Multiselect
                    options={this.props.institutions} // Options to display in the dropdown
                    selectedValues={this.state.institutions} // Preselected value to persist in dropdown
                    onSelect={this.onInstSelect} // Function will trigger on select event
                    onRemove={this.onInstRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    className="AdvisorSchoolSelector"
                    disabled={this.state.disabled}

                />
                <br />
                <div className="directorCheckboxContainer">
                    <FormControlLabel control={<Checkbox
                        checked={this.state.isGAP}
                        disabled={this.state.disabled}
                        onChange={this.gapChanged}
                        label="GAP"

                    />} label="GAP"
                        className="directorLabel" />


                    <FormControlLabel control={<Checkbox
                        checked={this.state.isHonorific}
                        disabled={this.state.disabled}
                        onChange={this.honorificChanged}
                        label="Honorific"

                    />} label="Honorific"
                        className="directorLabel" />

                    <FormControlLabel control={<Checkbox
                        checked={this.state.isAccelerate}
                        disabled={this.state.disabled}
                        onChange={this.accelerateChanged}
                        label="Accelerate"

                    />} label="Accelerate"
                        className="directorLabel" />
                </div>

                <div >
                    <table>
                        <tr>
                            <td className="directorCheckboxContainer">
                                <FormControlLabel control={<Checkbox
                                    checked={this.state.depFamilyEngagement}
                                    disabled={this.state.disabled}
                                    onChange={this.depFamilyEngagementChanged}
                                    label="Family Engagement"

                                />} label="Family Engagement"
                                    className="directorLabel" />
                            </td>
                            <td className="directorCheckboxContainer">
                                <FormControlLabel control={<Checkbox
                                    checked={this.state.depMiddleSchool}
                                    disabled={this.state.disabled}
                                    onChange={this.depMiddleSchoolChanged}
                                    label="Middle School"

                                />} label="Middle School"
                                    className="directorLabel" />
                            </td>
                        </tr>
                        <tr>
                            <td className="directorCheckboxContainer">
                                <FormControlLabel control={<Checkbox
                                    checked={this.state.depHighSchool}
                                    disabled={this.state.disabled}
                                    onChange={this.depHighSchoolChanged}
                                    label="High School"

                                />} label="High School"
                                    className="directorLabel" />
                            </td>
                            <td className="directorCheckboxContainer">
                                <FormControlLabel control={<Checkbox
                                    checked={this.state.depPostSecondary}
                                    disabled={this.state.disabled}
                                    onChange={this.depPostSecondaryChanged}
                                    label="Post Secondary"

                                />} label="Post Secondary"
                                    className="directorLabel" />
                            </td>
                        </tr>
                    </table>


                </div>
                <br />
                <div className="directorCheckboxContainer">
                    <FormControlLabel control={<Checkbox
                        checked={this.state.showSubs}
                        disabled={this.state.disabled}
                        onChange={this.directorChanged}
                        label="Director"

                    />} label="Director"
                        className="directorLabel" />


                </div>

                {this.state.showSubs ? (
                    <div>
                        <span>Direct Reports</span>
                        <Multiselect
                            options={this.state.potSubords} // Options to display in the dropdown
                            selectedValues={this.state.subs} // Preselected value to persist in dropdown
                            onSelect={this.onSubSelect} // Function will trigger on select event
                            onRemove={this.onSubRemove} // Function will trigger on remove event
                            displayValue="displayName" // Property name to display in the dropdown options
                            className="DirectorAdvisorSelector"
                            disabled={this.state.disabled}
                        />
                    </div>
                ) : null}
                <br />
                </React.Fragment>
        );
    }
}

export default Advisor;