import React from "react";
import { FormattedMessage } from "react-intl";

import "./recruitmentClosed.css";

const RecruitmentClosed = () => (
    <div className="recruitment-closed">
        <FormattedMessage id="recruitmentClosed" />
    </div>
);

export default RecruitmentClosed;
