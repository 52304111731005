import axios from "axios";
import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Form, Field } from "react-final-form";
import { FinalFormCheckbox, FinalFormTextField } from "../../common/formWrappers";
import { composeValidators, maxLength, isPhoneNumber, required } from "../../common/formValidators";
import LoadingButton from "../../common/loadingButton";
import { PhoneMaskInput } from "../../common/masks";
import { getGeneralConfiguration, updateGeneralConfiguration } from "../api/programEventSettings";
import useAjaxStatus from "../../common/useAjaxStatus";
import { toast } from "react-toastify";
import LoadingIndicator from "../../common/loadingIndicator";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    generalConfiguration: {},
    heading: {
        marginBottom: theme.spacing(1),
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    formControlClassName: {
        height: 66,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
        justifyContent: "flex-end",
    },
}));

const GeneralConfiguration = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const { ajaxErrorWrapper } = useAjaxStatus();

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        (async () => {
            try {
                setLoading(true);
                const response = await getGeneralConfiguration(cancelTokenSource.token);
                setInitialValues(response.data.result);
            } catch (ex) {
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    const handleSave = async (values) => {
        try {
            setSaving(true);
            await updateGeneralConfiguration(values);
            toast.success("Changes saved successfully");
        } catch (ex) {
            ajaxErrorWrapper(ex);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className={classes.generalConfiguration}>
            <Typography className={classes.heading} variant="h5">
                General Configuration
            </Typography>
            <LoadingIndicator loading={loading} />
            <Form onSubmit={(values) => handleSave(values)} initialValues={initialValues}>
                {({ handleSubmit, values }) => {
                    return (
                        <form className={classes.form} onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Field
                                        component={FinalFormTextField}
                                        name="eventsContact"
                                        label="Events Contact Phone / Email"
                                        variant="outlined"
                                        margin="dense"
                                        required
                                        validate={
                                            values.eventsContactIsPhoneNumber === "true"
                                                ? composeValidators(required, maxLength(50), isPhoneNumber)
                                                : composeValidators(required, maxLength(50))
                                        }
                                        inputComponent={values.eventsContactIsPhoneNumber === "true" ? PhoneMaskInput : undefined}
                                    />
                                    <Field
                                        component={FinalFormCheckbox}
                                        name="eventsContactIsPhoneNumber"
                                        label="Is Phone Number (for formatting)"
                                        margin="dense"
                                        className={classes.formControlClassName}
                                        parse={(value) => {
                                            return value ? value.toString() : "false";
                                        }}
                                        format={(value) => {
                                            return value === "true";
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingButton type="submit" color="primary" variant="contained" loading={saving}>
                                Save
                            </LoadingButton>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default GeneralConfiguration;
