import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ContactInfo from "../../common/contactInfo";

import "./Instructions.css";

const Instructions = ({ intl, isDesktop }) => {
    return (
        <div className="household-form-instructions-container">
            <div className={`instructions ${!isDesktop ? "mobile" : ""}`}>
                <FormattedMessage id="app.instruction" defaultMessage="[Instructions]" children={(formattedMessage) => <h5>{formattedMessage}</h5>} />
                <div>
                    <FormattedMessage id="household.helptitle" />:
                </div>
                <FormattedMessage id="household.help" children={(formattedMessage) => <span className="instruction-text">{formattedMessage}</span>} />
                <ContactInfo />
            </div>
            <div style={{ flex: 1 }} />
        </div>
    );
};

Instructions.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
};

export default Instructions;
