import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    eventsNav: {
        marginTop: 5,
    },
}));

const EventsNav = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.eventsNav} staff-nav-menu`}>
            <NavLink to="/events/active" activeClassName="selected">
                <span>Active</span>
            </NavLink>
            <NavLink to="/events/inactive" activeClassName="selected">
                <span>Inactive</span>
            </NavLink>
            <NavLink to="/events/complete" activeClassName="selected">
                <span>Complete</span>
            </NavLink>
            <NavLink to="/events/settings" activeClassName="selected">
                <span>Settings</span>
            </NavLink>
        </div>
    );
};

export default EventsNav;
