import Moment from 'moment';

export const startBeforeEnd = (start, end) => {
    if (!start && !end) return true;
    if (!start && end) return false;
    if (start && !end) return true;
    const startTime = typeof start === 'string' ? Moment.utc(start) : start;
    const endTime = typeof end === 'string' ? Moment.utc(end) : end;
    const s = startTime.minutes() + startTime.hours() * 60;
    const e = endTime.minutes() + endTime.hours() * 60;
    return s < e;
};
