import React from "react";
import ConfirmDialog from "../../../common/confirmDialog";
import { removeFromWaitlist } from "../../api/programEvents";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/styles";
import useAjaxStatus from "../../../common/useAjaxStatus";

const useStyles = makeStyles({
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

const WaitlistUnenrollmentDialog = ({ isOpen, closeUnenrollmentDialog, selectedUsers, setRegisteredUsers }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const handleOnConfirm = async () => {
        const payload = selectedUsers.map((user) => user.waitlistId);

        try {
            const resp = await removeFromWaitlist(payload);
            resp.data.success && toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        }

        setRegisteredUsers((prev) => {
            const users = prev.filter((user) => !selectedUsers.filter((item) => item.waitlistId === user.waitlistId).length);
            return users;
        });

        closeUnenrollmentDialog();
    };

    const dialogContent = (
        <div className={classes.dialogContent}>
            <p>Are you sure you want to remove the following participants from this waitlist?</p>
            <ul>
                {selectedUsers.map((user, idx) => (
                    <li key={idx}>
                        {user.firstName} {user.lastName}
                    </li>
                ))}
            </ul>
            <br />
            <p>Participant(s) will be automatically notified of removal.</p>
        </div>
    );

    return (
        <ConfirmDialog
            open={isOpen}
            disablePortal
            handleClose={closeUnenrollmentDialog}
            confirmAction={handleOnConfirm}
            confirmActionText="Remove"
            fullWidth
            maxWidth="sm"
            title="Remove from Waitlist"
            body={dialogContent}
        />
    );
};

export default WaitlistUnenrollmentDialog;
