import React, { Component, Fragment } from "react";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import * as propTypes from "../../propTypes";
import * as TransactionApi from "../../../api/scholarshipTransactions";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../support/numberSupport";

import RequestForm from "./components/RequestForm";
import RequestList from "./components/RequestList";
import CrusaderList from "./components/CrusaderList";

import "./index.css";
import { transactionRequestType } from "../../enums";
import { createRefundTransactionRequest } from "../receiveRefund/apis";

const ZERO_AMOUNT = "$0.00";

class SubmitRefund extends Component {
  state = {
    currentId: null,
    date: null,
    totalAmount: 0,
    note: "",
    checkNumber: 0,
    disabled: false,
    refund: null,
    showAlert: false,
    alertTitle: "",
    alertMsg: "",
    statuses: [],
    isDisabled: true
  };
  UNSAFE_componentWillReceiveProps = props => {
    if (!props.scholarship) return;

    if (!this.showingExisting && !this.isLoading && (!this.scholarship || this.scholarship.id !== props.scholarship.id)) {
      this.scholarship = props.scholarship;
      this.doLoadData(props.scholarship.id);
    }
  };
  componentDidMount = () => {
    document.querySelector(".rdtPicker").style.visibility = "collapse";
    if (!this.props.scholarship) return;
    this.doLoadData(this.props.scholarship.id);
  };
  onSubmitRefund = async () => {
    const { date, checkNumber, note } = this.state;
    if (!checkNumber) {
      this.setState({
        showAlert: true,
        alertTitle: "Invalid data",
        alertMsg: "Invalid check Number"
      });
      return;
    }
    const now = moment.utc().startOf("day");
    if (date === "") {
      this.setState({
        showAlert: true,
        alertTitle: "Enter Required Information",
        alertMsg: "Please enter a date to complete the refund request."
      });
      return;
    }
    if (now.isAfter(date)) {
      this.setState({
        showAlert: true,
        alertTitle: "Past Date",
        alertMsg: "Date needs to be today or in the future."
      });
      return;
    }
    if (!checkNumber) {
      this.setState({
        showAlert: true,
        alertTitle: "Enter Required Information",
        alertMsg: "Please enter a check number to complete the refund request."
      });
      return;
    }
    if (this.state.refund.total === ZERO_AMOUNT) return;

    const scholarshipId = this.props.scholarship.id;

    try {
      const input = {
        scholarshipId,
        checkDate: date.format("MM/DD/YYYY hh:mm:ss A"),
        requestNote: note,
        checkNumber,
        type: transactionRequestType.refund
      };
      this.props.ajaxStatusLoading();
      await createRefundTransactionRequest(input);
      await this.doLoadData(scholarshipId);
      this.setState({
        currentId: null,
        note: "",
        checkNumber: "",
        date: moment.utc(new Date())
      });
      this.props.ajaxStatusComplete();
      this.showingExisting = false;
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving refund data");
    }
  };
  onCheckNumberChange = e => {
    const value = e.target.value;
    this.setState({ checkNumber: parseInt(value, 10) });
  };
  onNoteChange = e => {
    const value = e.target.value;
    this.setState({ note: value });
  };
  onDateChange = value => {
    this.setState({ date: value });
    document.querySelector(".rdtPicker").style.visibility = "collapse";
  };
  onDateFocus = () => {
    document.querySelector(".rdtPicker").style.visibility = "visible";
  };
  onDateBlur = () => {
    document.querySelector(".rdtPicker").style.visibility = "collapse";
  };
  onLoadCurrent = async () => {
    this.showingExisting = false;
    this.setState({
      currentId: null,
      note: "",
      checkNumber: "",
      date: moment.utc(new Date())
    });
    await this.doLoadData(this.props.scholarship.id);
  };
  onSubmitRefundClick = async request => {
    this.showingExisting = true;
    try {
      this.props.ajaxStatusLoading();
      const refundResults = (await TransactionApi.getRefundDetails(this.props.scholarship.id, request.id)).data.result;

      const total = refundResults.reduce((sum, item) => sum + item.amount, 0);

      this.setState({
        currentId: request.id,
        note: request.notes,
        checkNumber: request.checkNumber,
        date: moment(request.checkDate),
        refund: {
          date: moment(request.checkDate),
          total,
          details: refundResults
        },
        isDisabled: true
      });

      this.props.ajaxStatusComplete();
      this.showingExisting = false;
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving financial data");
    }
  };
  doLoadData = async scholarshipId => {
    try {
      this.isLoading = true;
      this.props.ajaxStatusLoading();
      const refundResults = await TransactionApi.getRefundDetails(scholarshipId);
      const statusResults = await TransactionApi.getRefunds(scholarshipId);
      const refundItems = refundResults.data.result;

      const total = refundItems.reduce((sum, item) => sum + item.amount, 0);

      this.setState({
        statuses: statusResults.data.result,
        refund: {
          date: new Date(),
          total,
          details: refundItems
        },
        isDisabled: refundItems.length === 0
      });

      this.props.ajaxStatusComplete();
      this.showingExisting = false;
    } catch (err) {
      this.props.ajaxStatusError("Error retrieving financial data");
    }
    this.isLoading = false;
  };
  render() {
    const total = this.state.refund ? this.state.refund.total : ZERO_AMOUNT;
      const date = this.state.refund ? moment.utc(this.state.refund.checkDate).format("M/D/YY") : null;
      //console.log(this);
    return (
      <Fragment>
        <div className="scholarship-refund">
          <div className="top">
            <RequestForm
              totalAmount={total}
              note={this.state.note}
              mDate={this.state.date}
              checkNumber={this.state.checkNumber}
              disabled={this.state.disabled}
              onClick={this.onSubmitRefund}
              onCheckNumberChange={this.onCheckNumberChange}
              onNoteChange={this.onNoteChange}
              onDateChange={this.onDateChange}
              onDateFocus={this.onDateFocus}
              onDateBlur={this.onDateBlur}
              isDisabled={this.state.isDisabled}
            />
            <RequestList currentId={this.state.currentId} refunds={this.state.statuses} onClick={this.onSubmitRefundClick} onLoadCurrent={this.onLoadCurrent} />
          </div>
          {this.state.refund ? (
            <Fragment>
              <div className="middle">
                <span>{date}</span>
                <div />
                {formatCurrencyNoDecimalWithNegativeStyle(this.state.refund.total)}
              </div>

              <CrusaderList refund={this.state.refund} />
            </Fragment>
          ) : null}
        </div>
        <SweetAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          onConfirm={() => {
            this.setState({ showAlert: false });
          }}
          confirmBtnText="OK"
        >
          {this.state.alertMsg}
        </SweetAlert>
      </Fragment>
    );
  }
}

SubmitRefund.propTypes = {
  scholarship: propTypes.scholarship
};
export default withAjaxStatus(SubmitRefund);
