import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { Field, Form } from "react-final-form";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingButton from "../../../common/loadingButton";
import { FinalFormTextField } from "../../../common/formWrappers";
import { required } from "../../../common/formValidators";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { EventStatus } from "../../enums";

const useStyles = makeStyles((theme) => ({
    descriptionDialog: {},
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const PermissionSlipDialog = ({ open, onClose, onSave, programEvent, disabled }) => {
    const classes = useStyles();
    const { ajaxCallsInProgress } = useAjaxStatus();
    const statusIsOpenEnrollment = [EventStatus.OpenEnrollment].includes(programEvent.status);

    return (
        <Form
            onSubmit={(values) => onSave(values)}
            initialValues={
                programEvent
                    ? {
                          permissionSlipLanguageEnglish: programEvent.permissionSlipLanguageEnglish,
                          permissionSlipLanguageSpanish: programEvent.permissionSlipLanguageSpanish,
                      }
                    : undefined
            }
        >
            {({ handleSubmit, form, values }) => {
                const handleClose = (e, reason) => {
                    if (reason) {
                        return;
                    }

                    form.initialize(
                        programEvent
                            ? {
                                  permissionSlipLanguageEnglish: programEvent.permissionSlipLanguageEnglish,
                                  permissionSlipLanguageSpanish: programEvent.permissionSlipLanguageSpanish,
                              }
                            : undefined
                    );
                    onClose();
                };

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Dialog
                            onClose={handleClose}
                            open={open}
                            className={classes.descriptionDialog}
                            maxWidth="md"
                            fullWidth
                            disableEscapeKeyDown
                            disablePortal
                        >
                            <DialogTitle>
                                Permission Slip Language
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Box display="flex" flexDirection="column">
                                    <Box mt={2}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="permissionSlipLanguageEnglish"
                                            label="English"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            multiline

                                            maxLength={500000}
                                            minRows={4}
                                            maxRows={8}
                                            required
                                            validate={required}
                                            disabled={statusIsOpenEnrollment || disabled}
                                        />
                                    </Box>
                                    <Box marginY={1}>
                                        <Field
                                            component={FinalFormTextField}
                                            name="permissionSlipLanguageSpanish"
                                            label="Spanish"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            multiline
                                            maxLength={500000}
                                            minRows={4}
                                            maxRows={8}
                                            required
                                            validate={required}
                                            disabled={statusIsOpenEnrollment || disabled}
                                        />
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} disabled={ajaxCallsInProgress !== 0}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    loading={ajaxCallsInProgress !== 0}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={statusIsOpenEnrollment || disabled}
                                >
                                    Save
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </form>
                );
            }}
        </Form>
    );
};

export default PermissionSlipDialog;
