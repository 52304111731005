import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";

import * as apis from "../../api/login";
import * as RecruitmentApi from "../../api/recruitment";
import NewAccount from "./NewAccount";
import withLocale from "../../support/hoc/withLocale";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";

import "./NewAccount";

let NewAccountContainer = ({
    ajaxStatusLoading,
    ajaxStatusComplete,
    setLocale,
    destroy,
    intl,
    termsGuardian,
    termsFirstName,
    termsMiddleName,
    termsLastName,
    locale,
}) => {
    const [state, setState] = useState({
        isAgree: false,
        showTerms: false,
        showLogin: false,
        showVerify: false,
        showDialog: false,
        dialogTitle: "",
        dialogText: "",
        userName: "",
        recruitmentOpen: true,
    });

    useEffect(() => {
        (async () => {
            ajaxStatusLoading();
            const result = (await RecruitmentApi.getCurrentCycle()).data.result;
            setState((prev) => ({
                ...prev,
                recruitmentOpen: result !== null,
            }));
            ajaxStatusComplete();
        })();
    }, [ajaxStatusLoading, ajaxStatusComplete]);

    const onLocaleChange = (e) => {
        const locale = e.target.value;
        setLocale(locale);
    };

    const onCancel = (e) => {
        e.preventDefault();
        setState((prev) => ({
            ...prev,
            showLogin: true,
        }));
        destroy();
    };

    const onCreateAccount = (values, e, { locale }) => {
        const { email, password, confirm, mobilePhone, userName } = values;

        setState((prev) => ({
            ...prev,
            userName,
            locale,
        }));
        ajaxStatusLoading();
        apis.createAccountByUserName(email, password, confirm, mobilePhone, userName, termsFirstName, termsMiddleName, termsLastName, termsGuardian, locale)
            .then((response) => {
                ajaxStatusComplete();
                setState((prev) => ({
                    ...prev,
                    showVerify: true,
                }));
            })
            .catch((error) => {
                ajaxStatusComplete();
                setState((prev) => ({
                    ...prev,
                    showDialog: true,
                    dialogTitle: intl.formatMessage({ id: "app.errorTitle" }),
                    dialogText: error.response.data.error.message,
                }));
            });
    };

    const onCloseDialog = () => {
        setState((prev) => ({
            ...prev,
            showDialog: false,
        }));
    };

    const onShowTerms = () => {
        setState((prev) => ({ ...prev, showTerms: true }));
    };

    if (state.showTerms) {
        return <Redirect to="/terms" />;
    }
    if (state.showLogin) {
        return <Redirect to="/login" />;
    }
    if (state.showVerify) {
        return <Redirect to={`/verify/${state.userName}`} />;
    }

    const isAgree = termsGuardian !== undefined;

    return (
        <React.Fragment>
            <NewAccount
                recruitmentOpen={state.recruitmentOpen}
                onLocaleChange={onLocaleChange}
                locale={locale}
                onBack={onCancel}
                onCreateAccount={onCreateAccount}
                onShowTerms={onShowTerms}
                isAgree={isAgree}
            />
            <SweetAlert show={state.showDialog} title={state.dialogTitle} onConfirm={onCloseDialog} confirmBtnText={intl.formatMessage({ id: "app.ok" })}>
                {state.dialogText}
            </SweetAlert>
        </React.Fragment>
    );
};

const selector = formValueSelector("terms");

const mapStateToProps = (state) => ({
    termsFirstName: selector(state, "firstName"),
    termsMiddleName: selector(state, "middleName"),
    termsLastName: selector(state, "lastName"),
    termsGuardian: selector(state, "guardian"),
});

NewAccountContainer = connect(mapStateToProps)(NewAccountContainer);
NewAccountContainer = withLocale(NewAccountContainer);
NewAccountContainer = withRouter(NewAccountContainer);
NewAccountContainer = withAjaxStatus(NewAccountContainer);

NewAccountContainer = reduxForm({
    form: "newaccount",
    destroyOnUnmount: false,
})(NewAccountContainer);

NewAccountContainer = injectIntl(NewAccountContainer);

export default NewAccountContainer;
