import React from "react";
import ReactTable from "react-table";
import moment from "moment";

import { formatCurrencyNoDecimal } from "../../../../support/numberSupport";

import "./payments.css";

const dateCell = (props) => {
  if (!props.value) {
    return "";
  }
  return moment(props.value).utc().format("M/D/YY");
};

const Payments = ({ data, getTdProps }) => (
  <div className="scholarship-send-payment-payments">
    <ReactTable
      className="financials-table -highlight"
      getTdProps={getTdProps}
      data={data}
      minRows={0}
      showPageSizeOptions={false}
      defaultPageSize={8}
      showPagination={true}
      columns={[
        {
          Header: "Institution",
          accessor: "institution",
        },
        {
          Header: "Requested",
          accessor: "requested",
          Cell: dateCell,
        },
        {
          Header: "Paid On",
          accessor: "paidOn",
          Cell: dateCell,
        },
        {
          Header: "Check #",
          accessor: "checkNum",
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: (props) => formatCurrencyNoDecimal(props.value),
        },
      ]}
    />
  </div>
);

export default Payments;
