import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import UserMenu from "./userMenu";

import Logo2 from "../../images/OWL_Logos_FInal-03-Light.png";

import "./index.css";

const Header = ({ user }) => {
    const displayName = user ? user.userDisplayName : "";
    return (
        <div className="header">
            <img src={Logo2} alt="Logo" />
            <div className="header-user">
                <div className="header-name">
                    <FormattedMessage id="header.welcome" defaultMessage="Welcome" />
                    <span>: {displayName}</span>
                </div>
                <UserMenu />
            </div>
        </div>
    );
};

Header.propTypes = {
    user: PropTypes.shape({
        displayName: PropTypes.string,
    }),
};

export default Header;
