import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";

// import { detectIE } from "../../support/browserSupport";
import Terms from "./Terms";
import { injectIntl } from "react-intl";

class TermsContainer extends Component {
  state = {
    showNewAccount: false
  };

  onBack = () => {
    this.props.destroy();
    this.setState({ showNewAccount: true });
  };

  onAgree = values => {
    this.setState({
      showNewAccount: true
    });
  };

  onPrint = () => {
    // const version = detectIE();
    // const title = this.props.intl.formatMessage({ id: 'login.termTitle' });
    // const paragraph1 = this.props.intl.formatMessage({ id: 'login.termParagraph1' });
    // const paragraph2 = this.props.intl.formatMessage({ id: 'login.termParagraph2' });
    // const paragraph3 = this.props.intl.formatMessage({ id: 'login.termParagraph3' });
    // const paragraph4 = this.props.intl.formatMessage({ id: 'login.termParagraph4' });
    // const paragraph5 = this.props.intl.formatMessage({ id: 'login.termParagraph5' });
    // const paragraph6 = this.props.intl.formatMessage({ id: 'login.termParagraph6' });
    // const paragraph7 = this.props.intl.formatMessage({ id: 'login.termParagraph7' });
    // const labelStyle = 'display: block;font-weight: bold;margin-bottom: 10px;';
    // const body = `<h1>${title}</h1>
    // <span style="${labelStyle}">${paragraph1}</span>
    // <span style="${labelStyle}">${paragraph2}</span>
    // <span style="${labelStyle}">${paragraph3}</span>
    // <span style="${labelStyle}">${paragraph4}</span>
    // <span style="${labelStyle}">${paragraph5}</span>
    // <span style="${labelStyle}">${paragraph6}</span>
    // <span style="${labelStyle}">${paragraph7}</span>`;
    // let win;

    // if (version === false || version >= 12) {
    //     win = window.open('', 'test', 'width=660,height=700');
    // } else {
    //     win = window.open('', 'test', 'width=660,height=700,toolbar=yes');
    // }

    // win.document.write(body);
    // win.focus();
    // win.print();
    // if (version === false || version >= 12) {
    //     win.close();
    // }
    // window.scrollTo(0, 0);
    window.print();
    return false;
  };

  render() {
    if (this.state.showNewAccount) {
      return <Redirect to="/newaccount" />;
    }

    return (
      <Terms
        onBack={this.onBack}
        onAgree={this.onAgree}
        onPrint={this.onPrint}
      />
    );
  }
}

TermsContainer = injectIntl(TermsContainer);

TermsContainer = reduxForm({
  form: "terms",
  destroyOnUnmount: false
})(TermsContainer);

export default TermsContainer;
