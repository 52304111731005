import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Rejected from "./rejected";
import StaffLayout from "../common/staffLayout";

class RejectedContainer extends React.Component {
    static permission = "Applications.Rejected";

    componentDidMount() {
        this.props.loadData();
    }

    getTrProps = (state, rowInfo) => {
        return {
            onClick: (e) => {
                this.rowClick(rowInfo.original);
            },
        }; 
    };

    rowClick = (row) => {
        window.previousLocation = "rejected";
        this.props.history.push(`/applications/application/${row.applicationId}/s/${row.userProfileId}`);
    };

    getSchoolData(schoolName) {
        return this.props.data.filter((d) => d.school === schoolName);
    }

    renderSchools() {
        return this.props.schools.map((school, index) => (
            <Rejected school={school.name} data={this.getSchoolData(school.name)} key={index} getTrProps={this.getTrProps} />
        ));
    }

    render() {
        const { showAll } = this.props;
        const totalRejected = this.props.data.length;
        const unassociatedData = this.getSchoolData("");

        return (
            <StaffLayout>
                <h3>Total Rejected: {totalRejected}</h3>
                <br />
                {this.renderSchools()}
                {unassociatedData.length > 0 && <Rejected school="Unassociated" data={unassociatedData} getTrProps={this.getTrProps} />}
            </StaffLayout>
        );
    }
}

RejectedContainer.propTypes = {
    showAll: PropTypes.bool.isRequired,
    data: PropTypes.array,
    schools: PropTypes.array,
};
const mapStateToProps = (state) => ({
    schools: state.staffMiddleSchools,
});

RejectedContainer = connect(mapStateToProps)(RejectedContainer);
RejectedContainer = withRouter(RejectedContainer);

export default RejectedContainer;
