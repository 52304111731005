import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useAjaxStatus from "../../../common/useAjaxStatus";
import { getParent, getAllParents, updateParent } from "../users/apis";

const useParentUser = () => {
    const [parentUser, setParentUser] = useState(null);
    const [allParents, setAllParents] = useState([]);
    const [loading, setLoading] = useState(true);
    const { ajaxErrorWrapper } = useAjaxStatus();

    const loadParentUserAsync = useCallback(async (userProfileId) => {
        try {
            const response = await getParent(userProfileId);
            setParentUser(response.data.result);
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const loadAllParentsAsync = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getAllParents();
            setAllParents(response.data.result);
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const updateParentAsync = async (payload) => {
        try {
            setLoading(true);
            await updateParent(payload);
            await loadParentUserAsync(payload.id);
            toast.success("Changes saved successfully");
        } catch (err) {
            ajaxErrorWrapper(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        parentUser,
        allParents,
        loading,
        loadParentUserAsync,
        updateParentAsync,
        loadAllParentsAsync,
        setParentUser,
    };
};

export default useParentUser;
