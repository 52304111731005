import React, { Component } from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from "react-simple-dropdown";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Checkbox, Typography } from '@material-ui/core';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import DateRange from './customDateRangePicker';



function calculateAgeInMonths(dateString) {
    const contDate = new Date(dateString);
    const currentDate = new Date();

    let ageInMonths = (currentDate.getFullYear() - contDate.getFullYear()) * 12;
    ageInMonths -= contDate.getMonth();
    ageInMonths += currentDate.getMonth();


    if (currentDate.getDate() < contDate.getDate()) {
        ageInMonths--;
    }

    return ageInMonths;
}
// Function to get the date 30 days before now
function getDate30DaysAgo() {
    const now = new Date();
    now.setDate(now.getDate() - 30);
    now.setHours(0, 0, 1, 0); // Set time to 1 second past midnight
    return now;
}

function getDate60DaysAgo() {
    const now = new Date();
    now.setDate(now.getDate() - 60);
    now.setHours(0, 0, 1, 0); // Set time to 1 second past midnight
    return now;
}

function getDate90DaysAgo() {
    const now = new Date();
    now.setDate(now.getDate() - 90);
    now.setHours(0, 0, 1, 0); // Set time to 1 second past midnight
    return now;
}


function generateRecentDates() {

    const dateRanges = [];
    const currentDate = new Date();
    const formattedDate = [

        String(currentDate.getMonth() + 1).padStart(2, '0'),
        String(currentDate.getDate()).padStart(2, '0'),
        currentDate.getFullYear(),
    ].join('/');

    const date30 = getDate30DaysAgo();
    const date60 = getDate60DaysAgo();
    const date90 = getDate90DaysAgo();
    const fmDt = formattedDate;
    dateRanges.push({
        label: ` Last 30 Days`,
        beginDate: date30,
        endDate: fmDt
    });
    dateRanges.push({
        label: ` Last 60 Days`,
        beginDate: date60,
        endDate: fmDt
    });
    dateRanges.push({
        label: ` Last 90 Days`,
        beginDate: date90,
        endDate: fmDt
    });

    return dateRanges;
}

function generateOlderDates() {
    const dateRanges = [];
    const OldDate = new Date('2000-01-01');

    const formattedDate = [
        String(OldDate.getMonth() + 1).padStart(2, '0'),
        String(OldDate.getDate()).padStart(2, '0'),
        OldDate.getFullYear(),
    ].join('/');

    const setToEndTime = (date) => {
        date.setHours(23, 59, 59);
        return date;
    };

    // Function to subtract one day
    const subtractOneDay = (date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    };

    const date30 = subtractOneDay(setToEndTime(getDate30DaysAgo()));
    const date60 = subtractOneDay(setToEndTime(getDate60DaysAgo()));
    const date90 = subtractOneDay(setToEndTime(getDate90DaysAgo()));

    dateRanges.push({
        label: `More Than 30 Days`,
        beginDate: formattedDate,
        endDate: date30
    });
    dateRanges.push({
        label: `More Than 60 Days`,
        beginDate: formattedDate,
        endDate: date60
    });
    dateRanges.push({
        label: `More Than 90 Days`,
        beginDate: formattedDate,
        endDate: date90
    });

    return dateRanges;
}



function generateSchoolYears() {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    // If the current date is before September 1st, subtract one year
    if (currentDate < new Date(currentYear, 8, 1)) { // Months are 0-indexed
        currentYear--;
    }

    const dateRanges = [];
    for (let i = 0; i < 3; i++) {
        const beginDate = new Date(currentYear - i, 8, 1); // September 1st
        const endDate = new Date(currentYear - i + 1, 7, 31); // August 31st


        const formattedBeginDate = [

            String(beginDate.getMonth() + 1).padStart(2, '0'),
            String(beginDate.getDate()).padStart(2, '0'),
            beginDate.getFullYear(),
        ].join('/');

        const formattedEndDate = [

            String(endDate.getMonth() + 1).padStart(2, '0'),
            String(endDate.getDate()).padStart(2, '0'),
            endDate.getFullYear(),
        ].join('/');

        const label = `${currentYear - i} - ${currentYear - i + 1}`;

        dateRanges.push({
            label,
            beginDate: formattedBeginDate,
            endDate: formattedEndDate
        });
    }

    return dateRanges;
}





class DateRangePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recentRanges: generateRecentDates(),
            olderRanges: generateOlderDates(),
            prefilledRanges: generateSchoolYears(),
            selectedDates: [],
            datesList: []
        };
    }


    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {
        

    }

    onDateRangeSelect = (selectedItem) => {
       
        if (selectedItem.label) {
            this.setState(prevState => ({
                selectedDates: [...prevState.selectedDates, selectedItem]
            }));
        }
    };

    onDateRangeRemove = (removedItem) => {
        
        this.setState(prevState => ({
            selectedDates: prevState.selectedDates.filter(item => item.label !== removedItem.label)
        }));
    };

    onCustomDateRangeSelect = (selectedItem) => {
        this.props.onDateToggle(selectedItem);
        if (selectedItem.label) {
            this.setState(prevState => ({
                selectedDates: [...prevState.selectedDates, selectedItem],
                datesList: [...prevState.datesList, selectedItem]
            }));
        } else {
            this.setState(prevState => ({
                datesList: [...prevState.datesList, selectedItem],
                selectedDates: [...prevState.selectedDates, selectedItem]
            }));
        }
    };

    onCustomDateRangeRemove = (removedItem) => {
       // this.props.onDateToggle(removedItem);
        
        this.setState(prevState => ({
            datesList: prevState.datesList.filter(item => item.label !== removedItem.label),
            selectedDates: prevState.selectedDates.filter(item => item.label !== removedItem.label)
        }));
    };



    toggleDates = (dateRange) => {
        
        this.props.onDateToggle(dateRange);
        

     

    }


    customDaterangeOptionRenderer = (val) => {

        

        const isSelected = this.props.selectedDates.some(dateRange =>
            dateRange.label === val.label
        );

        


        return (
            <div className="dateFilterSelection">
                <Checkbox
                    onChange={() => this.toggleDates(val)}
                    checked={isSelected}
                />   {val.label}
            </div>
        );
    };


    customSpecDaterangeOptionRenderer = (val) => {
        
        const isSelected = this.props.selectedDates.some(dateRange =>
            dateRange.label === val.label
        );

        const handleRemoveClick = (e, val) => {
            e.stopPropagation(); // This stops the event from bubbling up to the parent li
            this.onCustomDateRangeRemove(val);
        };

        return (
            <div className="dateFilterSelection">
                <Checkbox
                    onChange={() => this.toggleDates(val)}
                    checked={isSelected}
                />   {val.label}
                <RemoveCircleOutlineIcon onClick={(e) => handleRemoveClick(e, val)} className="removeIcon" />
            </div>
        );
    };

    render() {
        //console.log(this.state);
        return (
            <div className="dateFilterWrapper">
                <Dropdown>
                    <DropdownTrigger className="dropDownTrigger">
                        <div className="dateIconContainer">
                            <span> MM / DD / YYYY </span> &nbsp; &nbsp;
                            &nbsp;
                        </div>
                    </DropdownTrigger>

                    <DropdownContent className="dropDownContent" style={{ position: 'absolute', zIndex: 1000 }}>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>Custom Dates</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="advisor-dates-list">
                                    <li className="advisor-dates-list-range-picker">
                                        <DateRange onDateRangeSelect={this.onCustomDateRangeSelect} />
                                    </li>
                                    {this.state.datesList.map((yr, index) => (
                                        <li key={index} >
                                            {this.customSpecDaterangeOptionRenderer(yr)}
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <Typography>Recent Contact</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="advisor-dates-list">
                                    {this.state.recentRanges.map((yr, index) => (
                                        <li key={index}  >
                                            {this.customDaterangeOptionRenderer(yr)}
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                                <Typography>Older Contact</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="advisor-dates-list">
                                    {this.state.olderRanges.map((yr, index) => (
                                        <li key={index} >
                                            {this.customDaterangeOptionRenderer(yr)}
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>


                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                                <Typography>School Years</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="advisor-dates-list">
                                    {this.state.prefilledRanges.map((yr, index) => (
                                        <li key={index}  >
                                            {this.customDaterangeOptionRenderer(yr)}
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>


                    </DropdownContent>
                </Dropdown>
            </div>
        );
    }
}

export default DateRangePicker;
