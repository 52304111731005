import React, { Component } from 'react';
import { Button, TextField, Grid,  IconButton, Typography } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import DeDupeToggle from "./dedupeToggle";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import FontAwesome from "react-fontawesome";
import { deDupe, getPotentialDupes } from "./apis";
import { advisorSearch, getStudentById, getParentById, getAllSchools, getAllInstitutions, getCityList } from "../../../advisors/api.js";
import SimpleDialog from "../../../common/simpleDialog";
import moment from "moment";
import Link from "@material-ui/core/Link";
import { toast } from "react-toastify";


const theme = createTheme({
    palette: {
        primary: {
            main: '#123225', // Your primary color
        },
        secondary: {
            main: '#2c6648', // Your secondary color
        },
    },
});
class DeDupe extends Component {

    constructor(props) {
        super(props);

        this.state = {


            activeUP: [],
            dupes: [],
            loaded: false,
            activeDupe: [],
            showAlert: false,
            showDupes: false,
            cityOptions: [],
            showDeDupe: false,
            changes: [],
            searchVal: "",
            showSearch: false,
            searchStudents: [],
            searchPriParents: [],
            searchSecParents: [],
            par: true,
            showDeDupeConfirm: false,
            schools: [],
            institutions: [],
            cities: [],
            merged: false,
            searchTab: 0
        };
    }





    componentDidMount = async () => {
        const sch = await getAllSchools();
        const inst = await getAllInstitutions();
        const cit = await getCityList();
        this.setState({ schools: sch.data.result, institutions: inst.data.result, cities: cit.data.result });
    }

    componentDidUpdate = async (prevProps, prevState) => {

        if (this.props.activeUp != null && this.props.activeUp.id != null && this.state.loaded == false) {
            
            let prof = [];
            let par = true;
            if (this.props.activeUp.crusaderId != null) {
                const profData = await getStudentById(this.props.activeUp.id);

                prof = {
                    ...profData.data.result.userProfile,
                    ...((({ id, ...rest }) => rest)(profData.data.result.studentProfile))
                };
                par = false;
            } else {
                const profData = await getParentById(this.props.activeUp.id);

                prof = prof = {
                    ...profData.data.result.userProfile
                };
            }
            const dupesData = await getPotentialDupes(this.props.activeUp.id);
            const dupes = dupesData.data.result;
            console.log("componentDidUpdate");
            this.setState({ dupes: dupes, activeUP: prof, cityOptions: this.props.cityOptions, loaded: true, par: par })
        }

    }

    updateSearch = async (event) => {



        this.setState({ searchVal: event.target.value.toLowerCase() });


    }
    showSearch = () => {
        this.setState({ showSearch: true, searchTab: 1 });
    }
    hideSearch = () => {
        this.setState({ showSearch: false, searchTab: 0 });
    }
    showDedupeConfirm = () => {
        this.setState({ showDeDupeConfirm: true, showDeDupe: false });
    }
    hideDedupeConfirm = () => {
        this.setState({ showDeDupeConfirm: false, showDeDupe: true });
    }
    HandleDeDupe = async () => {
        try {
            const searchPriParents = this.state.searchPriParents.filter(parent => parent.id != this.state.activeDupe.id);
            const searchStudents = this.state.searchStudents.filter(student => student.id != this.state.activeDupe.id);
            

            const input = {
                UserProfileId: this.props.activeUp.id,
                DupeProfileId: this.state.activeDupe.id,
                Fields: this.state.changes
            }
            const res = await deDupe(input);
            
            if (res.data.success == true) {
                toast.success("Merge Complete");
                const dupesData = await getPotentialDupes(this.state.activeUP.id);
                const dupes = dupesData.data.result.filter(flt => flt.id != this.state.activeDupe.id);;
                console.log(this.state.activeDupe.id);
                console.log(dupes);
                this.setState({ dupes, searchPriParents, searchStudents, activeDupe: [], showDupes: false, showDeDupe: false, showDeDupeConfirm: false, merged: true });
                this.props.reload(this.props.activeUp.id);
            } else {


            }
        } catch {
            toast.error("Merge Failed");
        }




    };

    setTab = (event, newVal) => {
        

        switch (newVal) {
            case 0:
                this.setState({ searchTab: newVal, showDupes: true, showSearch: false });
                break
            case 1:
                this.setState({ searchTab: newVal, showDupes: false, showSearch: true });

                break;

            default:
                this.setState({ searchTab: newVal, showDupes: true, showSearch: false });
                break;


        }

        
    }

    setActiveDupe = async (dupe) => {

        let prof = [];
        if (this.props.activeUp.crusaderId != null) {
            const profData = await getStudentById(dupe.id);

            prof = {
                ...profData.data.result.userProfile,
                ...((({ id, ...rest }) => rest)(profData.data.result.studentProfile))
            };

        } else {
            const profData = await getParentById(dupe.id);

            prof = prof = {
                ...profData.data.result.userProfile
            };
        }

        this.setState({ activeDupe: prof, showDupes: false, showDeDupe: true, showSearch: false });
    };
    hideDedupe = () => {
        if (this.state.dupes.length > 0) {
            this.setState({ activeDupe: [], showDupes: true, showDeDupe: false });
        } else {
            this.setState({ activeDupe: [], showSearch: true, showDeDupe: false });
        }

    }
    showDupes = () => {
        this.setState({ showDupes: true, searchTab: 0 });
    }
    hideDupes = () => {
        this.setState({ showDupes: false });
    }
    onConfirm = async () => {

    };
    onCancel = () => {
        this.setState({

            showAlert: true
        });
    };
    runSearch = async () => {
        const res = await advisorSearch({ text: this.state.searchVal });

        let stu = [];
        let par = [];
        if (res.data.result.students.length > 0) {
            stu = res.data.result.students.filter((dup) => dup.id !== this.state.activeUP.id);
        }
        if (res.data.result.parents.length > 0) {
            par = res.data.result.parents.filter((dup) => dup.id !== this.state.activeUP.id);
        }
        this.setState({ searchStudents: stu, searchPriParents: par });
    }
    onDupeUpdate = (name, val) => {
        if (val) {
            // Add the name to the changes array if it's not already included
            this.setState((prevState) => ({
                changes: prevState.changes.includes(name)
                    ? prevState.changes
                    : [...prevState.changes, name]
            }));
        } else {
            // Remove the name from the changes array if it's present
            this.setState((prevState) => ({
                changes: prevState.changes.filter((change) => change !== name)
            }));
        }
    };


    render() {
       
        console.log(this);
        const { dupes, showDupes, showSearch, showDeDupe, activeUP, activeDupe, par, showDeDupeConfirm } = this.state;


        const commonFields = [
            { name: "firstName", label: "First Name" },
            { name: "middleName", label: "Middle Name" },
            { name: "lastName", label: "Last Name" },
            { name: "address1", label: "Address 1" },
            { name: "address2", label: "Address 2" },
            { name: "city", label: "City" },
            { name: "cityId", label: "City Id" },
            { name: "state", label: "State" },
            { name: "zipCode", label: "ZipCode" },
            { name: "email", label: "Email" },
            { name: "schoolId", label: "School Id" },
            { name: "mobilePhone", label: "Mobile Phone" },
            { name: "userId", label: "User Id" },
            { name: "primaryLocale", label: "Primary Locale" },
            { name: "gender", label: "Gender" },
            { name: "dateOfBirth", label: "Date Of Birth" },
            { name: "genderDescription", label: "Gender Description" },
            { name: "dietaryRestrictions", label: "Dietary Restrictions" },
            { name: "medications", label: "Medications" },
            { name: "allergies", label: "Allergies" },
            { name: "specialAccommodations", label: "Special Accommodations" },
            { name: "medicalConditions", label: "Medical Conditions" },
        ];

        // Additional fields for userType === 4
        const studentFields = !par ? [
            { name: "crusaderId", label: "Crusader ID" },
            { name: "ssn", label: "SSN" },
            { name: "alien", label: "Alien" },
            { name: "sasId", label: "SAS ID" },
            { name: "currentGrade", label: "Current Grade" },
            { name: "yearOfGraduation", label: "Year Of Graduation" },
            { name: "scholarshipLevel", label: "Scholarship Level" },
            { name: "cohortNumber", label: "Cohort Number" },
            { name: "selectedInstitutionId", label: "Selected Institution ID" },
            { name: "institutionIPEDNo", label: "Institution IPED No" },
            { name: "status", label: "Status" },
            { name: "gap", label: "Gap" },
            { name: "honorific", label: "Honorific" },
            { name: "accelerate", label: "Accelerate" },
            { name: "academicInterests", label: "Academic Interests" },
            { name: "collegeId", label: "College ID" },
            { name: "maxAllotmentReached", label: "Max Allotment Reached" },
            { name: "studentId", label: "Student ID" },
            { name: "iep", label: "IEP" },
            { name: "nonOnwardStudent", label: "Non-Onward Student" },
        ] : [];

        // Combine common and student-specific fields
        const fields = [...commonFields, ...studentFields];
        return (
            <ThemeProvider theme={theme}>
                <Button
                    style={{ float: 'right', backgroundColor: '#2c6648', color: "#f5e8d1", margin: "5px" }}
                    onClick={dupes.length === 0 ? this.showSearch : this.showDupes} id="dupeButton"
                >
                    {dupes.length === 0 ? "No Duplicates Detected" : "Duplicates Detected"}
                </Button>

                {/* Potential Duplicates Dialog */}
                <SimpleDialog
                    open={showDupes}
                    onClose={this.hideDupes}
                    title="Potential Duplicates"
                    showCloseButton={true}
                    style={{ minHeight: "350px" }}
                >
                    <Tabs value={this.state.searchTab} onChange={this.setTab} aria-label="Search" variant="fullWidth"
                        sx={{
                            '.MuiTabs-flexContainer': {
                                justifyContent: 'space-evenly',
                            },
                            '.MuiTab-root': {
                                border: '1px solid #000',
                                borderBottom: 'none',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                marginRight: '4px',
                                '&:last-child': {
                                    marginRight: 0,
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                },
                                '&:hover': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                    opacity: 1,
                                },
                            },
                        }}
                    >
                        <Tab label="Dupes" />
                        <Tab label="Search" />


                    </Tabs>
                    {dupes.map((dup) => (
                        <Link
                            key={dup.id}
                            onClick={() => this.setActiveDupe(dup)}
                            className="dashboardLink"
                        >
                            {dup.firstName} {dup.lastName} <b>Created:</b> {moment(dup.creationTime).format("M/D/YY h:mm a")} <b>Updated:</b> {moment(dup.lastModificationTime).format("M/D/YY h:mm a")} <b>UserId:</b> {dup.userId}
                            &nbsp;<br /><br />
                        </Link>
                    ))}
                </SimpleDialog>


                <SimpleDialog
                    open={showDeDupe}
                    onClose={this.hideDedupe}
                    title="Merge Duplicates"
                    showCloseButton={true}
                >
                    <div>
                        You have chosen to merge: <br /> {activeDupe.firstName} {activeDupe.lastName} <b>Created:</b> {moment(activeDupe.creationTime).format("M/D/YY h:mm a")} <b>Updated:</b> {moment(activeDupe.lastModificationTime).format("M/D/YY h:mm a")} <b>UserId:</b> {activeDupe.userId} <br /> into : <br />
                        {activeUP.firstName} {activeUP.lastName} <b>Created:</b> {moment(activeUP.creationTime).format("M/D/YY h:mm a")} <b>Updated:</b> {moment(activeUP.lastModificationTime).format("M/D/YY h:mm a")} <b>UserId:</b> {activeUP.userId}.<br />   Please use the toggles below to determine 
                        what data will be applied to the surviving record.
                    </div>
                    <form onSubmit={this.HandleDeDupe}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} style={{ textAlign: "right", color: "primary" }}>
                                <b>Survivor</b>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: "center" }}>
                           
                            </Grid>
                            <Grid item xs={5} style={{ textAlign: "left", color: "primary" }}>
                                <b>Duplicate</b>
                            </Grid>
                            {fields.map((field) => (
                                <DeDupeToggle
                                    key={field.name}
                                    name={field.name}
                                    label={field.label}
                                    val1={activeUP[field.name]}
                                    val2={activeDupe[field.name]}
                                    onChange={this.onDupeUpdate}
                                    cities={this.state.cities}
                                    schools={this.state.schools}
                                    institutions={this.state.institutions}
                                />
                            ))}
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button
                                    onClick={this.showDedupeConfirm}
                                    style={{ float: "center", backgroundColor: "#2c6648", color: "#f5e8d1", margin: "5px" }}
                                >
                                    Merge Records
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </SimpleDialog>

                {/* Search Dialog */}
                <SimpleDialog
                    open={showSearch}
                    onClose={this.hideSearch}
                    title="Search Duplicates"
                    showCloseButton={true}
                >
                    <Tabs value={this.state.searchTab} onChange={this.setTab} aria-label="Search" variant="fullWidth"
                        sx={{
                            '.MuiTabs-flexContainer': {
                                justifyContent: 'space-evenly',
                            },
                            '.MuiTab-root': {
                                border: '1px solid #000',
                                borderBottom: 'none',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                marginRight: '4px',
                                '&:last-child': {
                                    marginRight: 0,
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                },
                                '&:hover': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                    opacity: 1,
                                },
                            },
                        }}
                    >
                        <Tab label="Dupes" />
                        <Tab label="Search" />


                    </Tabs>
                    <div style={{ display: "flex", padding: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Search</Typography>
                            </Grid>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={11}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Enter first or last name to search"
                                        name="title"
                                        onBlur={this.updateSearch}
                                        onChange={this.updateSearch}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton aria-label="search" onClick={this.runSearch}>
                                        <FontAwesome name="search" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <div className="actionButtonWrapper">
                                <Button onClick={this.hideSearch} className="fieldSelectorButton">
                                    Cancel
                                </Button>
                            </div>
                            {par ? <Grid item xs={12}>
                                <ul>
                                    {this.state.searchPriParents.map((dup) => (
                                        <li key={dup.id}>
                                            <Link onClick={() => this.setActiveDupe(dup)}>
                                                {dup.firstName} {dup.lastName} <b>Created:</b> {moment(dup.creationTime).format("M/D/YY h:mm a")} <b>Updated:</b> {moment(dup.lastModificationTime).format("M/D/YY h:mm a")} <b>UserId:</b> {dup.userId}
                                            </Link>
                                        </li>
                                    ))}

                                </ul>
                            </Grid> : <Grid item xs={12}>
                                <ul>
                                    {this.state.searchStudents.map((dup) => (
                                        <li key={dup.id}>
                                            <Link onClick={() => this.setActiveDupe(dup)}>
                                                {dup.firstName} {dup.lastName} <b>Created:</b> {moment(dup.creationTime).format("M/D/YY h:mm a")} <b>Updated:</b> {moment(dup.lastModificationTime).format("M/D/YY h:mm a")} <b>UserId:</b> {dup.userId}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Grid>}


                        </Grid>
                    </div>
                </SimpleDialog>

                <SimpleDialog
                    open={showDeDupeConfirm}
                    onClose={this.hideDedupeConfirm}
                    title="Merge Duplicates"
                    showCloseButton={true}
                >

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Are you sure you want to merge {activeUP.firstName} {activeUP.lastName} with {activeDupe.firstName} {activeDupe.lastName}?
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button
                                onClick={this.HandleDeDupe}
                                style={{ float: "center", backgroundColor: "#2c6648", color: "#f5e8d1", margin: "5px" }}
                            >
                                Yes, Merge Records
                            </Button>
                        </Grid>
                    </Grid>

                </SimpleDialog>

            </ThemeProvider>
        );
    }


}

export default DeDupe;