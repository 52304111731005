import React, { Component, Fragment } from "react";
import AdvisorContext from "../../context/context";
import { Typography, Grid } from '@material-ui/core';

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';


const formatXAxis = (tickItem) => {
    // Split the tickItem by " - " and return the part after if it exists
    const parts = tickItem.split(" - ");
    return parts.length > 1 ? parts[1] : tickItem;
}
class MilestonesGraph extends Component {
    static contextType = AdvisorContext;

    constructor(props) {
        super(props);
        //console.log(props);
        this.state = {
            data: [],
            loaded: false,
            barData: [],
            advisorId: this.props.advisorId,
            total: 0,
            achieved: 0


        };
    }



    componentDidMount() {
        this.calculateData();
    }
    componentDidUpdate(prevProps, prevState) {
        //console.log(prevProps);
        //console.log(this);
        if (prevProps != this.props) {

            this.calculateData();
        }
            
        
    }


    calculateData = () => {
        let data = [];
        let barData = [];

        let matched = 0;
        let unmatched = 0;
        let tmp = [];
        if (this.props.user == 0) {
            tmp = this.context.myCases;
        } else {
            tmp = this.context.myCases.filter(myCase =>
                myCase.allAdvisors.some(advisor => advisor.userId == this.props.user)
            );
        }
        
        const appl = tmp.filter(flt => flt.studentProfile.currentGrade == this.props.objective.minGrade);

       

        let tot = 0;
        let success = 0;

        appl.forEach(st => {
            
            const tags = st.studentContactInfo.tags.filter(flt => flt.subTagId == this.props.objective.subtagId);
            const status = tags.length > 0;
            tot++;

            if (status) {
                success++;

            }
        });

        if (this.props.type == "pie") {
            
            data.push({
                name: 'Milestone Met',
                value: success,
                fill: '#123225'
            });

            data.push({
                name: 'Milestone Not Met',
                value: tot - success,
                fill: '#e2a331'
            });

        }
        if (this.props.type == "bar") {
         
            this.props.objectives.forEach(obj => {

                barData.push({
                    name: obj.title.replace(":", "-"),
                    meeting: obj.metObjective,
                    short: obj.total - obj.metObjective,
                    amt: obj.total,
                    uv: obj.metObjective,
                    pv: obj.total - obj.metObjective

                });

            });


        }


        /*
        this.context.gradeList.forEach((gra) => {
            const total = this.context.myCases.filter((flt) => flt.studentProfile.currentGrade == gra.value);
            if (total.length > 0) {
                const contacted = total.filter((flt) => flt.studentContactInfo.contacted == true);
                data.push({
                    name: 'Grade ' + gra.name,
                    Contacted: contacted.length,
                    Uncontacted: total.length - contacted.length,
                    amt: total.length,
                    fill:
                });
            }
           
        })
        */
        this.setState({ data: data, loaded: true, barData: barData });
    }

    renderBarChart = () => {

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={150}
                    height={150}
                    data={this.state.barData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Meeting" fill="#123225" />
                    <Bar dataKey="Short" fill="#e2a331" />
                </BarChart>
            </ResponsiveContainer>
        );
    }


    renderPieChart = () => {
        const renderCustomLabel = ({ name, percent }) => {
            return `${name}: ${(percent * 100).toFixed(0)}%`;
        };
        return (
            <ResponsiveContainer width="100%" height="100%">
                <PieChart >
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={this.state.data}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#123225"
                        label={renderCustomLabel} // Using the custom label function
                        labelLine={false} // Optional: if you don't want the lines from sectors to labels
                    />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        );
    }

    renderPercentage = () => {
        let total = 0;
        let achieved = 0;
        if (this.state.data.length > 1) { // Corrected typo here from `lenght` to `length`
            total = this.state.data[0].value + this.state.data[1].value;
            achieved = this.state.data[0].value;
        }

        let percentage = 0;
        try {
            if (total != 0) {
                percentage = ((achieved / total) * 100).toFixed(2);
            }

        } catch {

        }

        if (!this.state.loaded) {
            return (
                <div></div>
            );
        }
        return (
            <Typography variant="h5" color="primary" gutterBottom>
                {percentage}%
            </Typography>
        );
    }


    render() {

       


        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };
        if (this.state.data.length == 0) {
            
            return null;
        }
        if (this.state.data[0].value + this.state.data[1].value == 0) {

            return null;
        }
        return (
            <Grid item xs={6}>
            <Grid container spacing={2} style={{
                display: 'flex', // Use flexbox layout
                flexDirection: 'row', // Stack children vertically
                height: '100%', // Take the full height available
                justifyContent: 'flex-start', // Start alignment at the top for the first child
                minHeight: '300px',

            }}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        {this.props.display}
                    </Typography>
                    
                </Grid>
                {this.props.type == "pie" ? <>
                    <Grid item xs={4} style={{
                        flex: 1, // Take up all available space
                        display: 'flex', // Use flexbox layout
                        flexDirection: 'column', // Stack children vertically
                        justifyContent: 'center', // Center vertically in the remaining space
                        textAlign: 'center', // Center text horizontally
                    }}>
                        <span>{this.props.objective.displayName}</span><br />
                        <span style={{ fontSize: "small" }}>{this.props.objective.title}</span><br />
                        {this.state.data.length == 2 ? <span>{this.state.data[0].value} / {this.state.data[0].value + this.state.data[1].value}</span> : null}


                    </Grid>

                    <Grid item xs={8}>
                        <ResponsiveContainer width="95%" height="95%">
                            <PieChart width={250} height={250}>
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={true}
                                    data={this.state.data}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#123225"
                                    label={renderCustomizedLabel} // Using the custom label function
                                    labelLine={false} // Optional: if you don't want the lines from sectors to labels
                                />
                                <Legend verticalAlign="bottom" height={36} />
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>

                    </Grid>
                </> : <Grid item xs={12}>

                    <ResponsiveContainer width="95%" height="95%">
                        <BarChart
                            width={600}
                            height={600}
                            data={this.state.barData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" interval={0} angle={-90} textAnchor="end" height={300} tickFormatter={formatXAxis} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="meeting" fill="#123225" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                            <Bar dataKey="short" fill="#e2a331" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                        </BarChart>
                    </ResponsiveContainer>


                </Grid>}
                </Grid>
            </Grid>
        );



    }

}

export default MilestonesGraph;
