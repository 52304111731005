import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { reduxForm, formValueSelector, blur, change } from "redux-form";
import { loadCities } from "../../redux/codeTables";
import { toast } from "react-toastify";
import { ajaxStatusLoadingAction, ajaxStatusCompleteAction, ajaxStatusErrorAction } from "../../redux/ajax";
import withPromptChange from "../../support/hoc/withPromptChange";
import withAdmissions from "../../support/hoc/withAdmissions";
import { ApplicationStatus } from "../logic/enums";

import * as apis from "./apis";
import { SecurityContext } from "../../support/security";

import Form from "./Form";

import "./index.css";

const FORM_NAME = "parent";

const startBeforeEnd = (start, end) => {
    if (!start && !end) return true;
    if (!start && end) return false;
    if (start && !end) return true;
    const startTime = typeof start === "string" ? Moment(start) : start;
    const endTime = typeof end === "string" ? Moment(end) : end;
    const s = startTime.minutes() + startTime.hours() * 60;
    const e = endTime.minutes() + endTime.hours() * 60;
    return s < e;
};

const checkFromValues = (data, props) => {
    const errors = {};
    const message = (
        <FormattedMessage
            id="parent.endBeforStart"
            defaultMessage="[End time before start time]"
            children={(formattedMessage) => (
                <span style={{ color: "red" }} title={formattedMessage}>
                    {formattedMessage}
                </span>
            )}
        />
    );

    if (data.primaryPhonePrimary === "1" && !startBeforeEnd(data.primaryHomeStart, data.primaryHomeEnd)) {
        errors.primaryHomeEnd = message;
    }
    if (data.primaryPhonePrimary === "2" && !startBeforeEnd(data.primaryMobileStart, data.primaryMobileEnd)) {
        errors.primaryMobileEnd = message;
    }
    if (data.primaryPhonePrimary === "3" && !startBeforeEnd(data.primaryWorkStart, data.primaryWorkEnd)) {
        errors.primaryWorkEnd = message;
    }
    if (data.secondaryPhonePrimary === "1" && !startBeforeEnd(data.secondaryHomeStart, data.secondaryHomeEnd)) {
        errors.secondaryHomeEnd = message;
    }
    if (data.secondaryPhonePrimary === "2" && !startBeforeEnd(data.secondaryMobileStart, data.secondaryMobileEnd)) {
        errors.secondaryMobileEnd = message;
    }
    if (data.secondaryPhonePrimary === "3" && !startBeforeEnd(data.secondaryWorkStart, data.secondaryWorkEnd)) {
        errors.secondaryWorkEnd = message;
    }
    if (!data.secondaryEmail && data.secondarySendCopy) {
        errors.secondaryEmail = (
            <FormattedMessage
                id="parent.emailRequired"
                defaultMessage="[email required]"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }
    if (!data.primaryHomePhone && !data.primaryMobilePhone && !data.primaryWorkPhone) {
        errors.primaryHomePhone = (
            <FormattedMessage
                id="parent.onePhone"
                defaultMessage="[one phone required]"
                children={(formattedMessage) => (
                    <span style={{ color: "red" }} title={formattedMessage}>
                        {formattedMessage}
                    </span>
                )}
            />
        );
    }
    return errors;
};

class Parent extends Component {
    isStaffReview = false;

    componentDidMount = () => {
        
        const { match } = this.props;
        if (match.params && match.params.id)  {
            this.id = match.params.id;
            this.apiMethod = "getByApplicationId";
        } else if (this.props.admissions && this.props.admissions.id)
        {
            this.id = this.props.admissions.id;
            this.apiMethod = "getByApplicationId";
        }
                else{
            this.apiMethod = "get";
        }
        this.load();

        this.isStaffReview = match.params && match.params.id !== undefined;
    };
    UNSAFE_componentWillMount() {
        this.props.loadCities();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.admissions !== null && this.props.admissions.id !== nextProps.admissions.id) {
            this.load();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.primaryPhone === null && prevProps.initialized) {
            if (prevProps.primaryHomePhone !== this.props.primaryHomePhone) {
                this.props.change("primaryPhonePrimary", "1");
            }
            if (prevProps.primaryMobilePhone !== this.props.primaryMobilePhone) {
                this.props.change("primaryPhonePrimary", "2");
            }
            if (prevProps.primaryWorkPhone !== this.props.primaryWorkPhone) {
                this.props.change("primaryPhonePrimary", "3");
            }
        }

        if (this.props.secondaryPhone === null && prevProps.initialized) {
            if (prevProps.secondaryHomePhone !== this.props.secondaryHomePhone) {
                this.props.change("secondaryPhonePrimary", "1");
            }
            if (prevProps.secondaryMobilePhone !== this.props.secondaryMobilePhone) {
                this.props.change("secondaryPhonePrimary", "2");
            }
            if (prevProps.secondaryWorkPhone !== this.props.secondaryWorkPhone) {
                this.props.change("secondaryPhonePrimary", "3");
            }
        }
    }

    load() {
        this.props.ajaxStatusLoading();
        apis[this.apiMethod](this.id)
            .then((results) => {
                this.doLoadInitialData(results.data.result);
                this.props.ajaxStatusComplete();
            })
            .catch((error) => {
                if (error.response) {
                    this.props.ajaxStatusError(`status: ${error.response.status} message: ${error.response.statusText}`);
                    return;
                }
                this.props.ajaxStatusError(error);
            });
    }
    onSave = async (values) => {
        this.props.ajaxStatusLoading();
        const parentConfData = {
            ApplicationId: this.props.admissions.id,
            Value: true
        }

        try {
            await apis.updateParentConfirmation(parentConfData);
            const results = await apis.update(values);
            const SetSecondaryData = {
                ApplicationId: this.props.admissions.id,
                ParentId: results.data.result.secondaryUserId
            }
            await apis.setApplicationSecondaryParent(SetSecondaryData);

            if (this.isStaffReview) {
                this.props.refreshApplication();
            } else {
                this.props.loadApplication(this.props.admissions.id);
            }

            this.props.ajaxStatusComplete();
            this.doLoadInitialData(results.data.result);
            toast.success("Save Complete");
        } catch (error) {
            this.props.ajaxStatusError(`status: ${error.response.status} message: ${error.response.statusText}`);
        }
    };

    onCancel = (e) => {
        e.preventDefault();
        this.props.reset();
    };
    doLoadInitialData = (data) => {
        this.props.initialize(data);
    };

    render() {
        const {
            handleSubmit,
            isMobileUser,
            admissions,
            primaryHomePhone,
            primaryMobilePhone,
            primaryWorkPhone,
            secondaryHomePhone,
            secondaryWorkPhone,
            secondaryMobilePhone,
            secondaryGuardian,
            secMode
        } = this.props;

        let readonly =
            admissions === null ||
            (admissions.status !== ApplicationStatus.NotSubmitted &&
                admissions.status !== ApplicationStatus.Rejected &&
                admissions.status !== ApplicationStatus.LunchPending &&
                admissions.status !== ApplicationStatus.Submitted &&
                this.props.admissions.status !== ApplicationStatus.Resubmit &&
                this.props.admissions.status !== ApplicationStatus.Accepted
            );
        if (this.props.readOnly || admissions.status === ApplicationStatus.Submitted) {
            readonly = true;
        }
        //console.log(readonly);
        return (
            <SecurityContext.Consumer>
                {context => {
                    return (
                        <div className="parent-container">
                            <Form
                                {...this.props}
                                handleSubmit={handleSubmit}
                                isStaff={this.isStaffReview}
                                onSave={this.onSave}
                                onCancel={this.onCancel}
                                readonly={context.hasEditPermission("Admin.Admissions") ? false : readonly}
                                match={this.props.match}
                                cities={this.props.cities}
                                pristine={this.props.pristine}
                                primaryHomeDisabled={primaryHomePhone === null || primaryHomePhone === ""}
                                primaryWorkDisabled={primaryWorkPhone === null || primaryWorkPhone === ""}
                                primaryMobileDisabled={primaryMobilePhone === null || primaryMobilePhone === ""}
                                secondaryHomeDisabled={secondaryHomePhone === null || secondaryHomePhone === ""}
                                secondaryWorkDisabled={secondaryWorkPhone === null || secondaryWorkPhone === ""}
                                secondaryMobileDisabled={secondaryMobilePhone === null || secondaryMobilePhone === ""}
                                isMobileUser={isMobileUser}
                                secondaryGuardian={secondaryGuardian}
                                secMode={secMode }
                            />
                        </div>
                        );
                    }
                }
            </SecurityContext.Consumer>
        );
    }
}

Parent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    
};

const ReduxForm = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    fields: [
        "primaryPhonePrimary",
        "primaryHomeStart",
        "primaryHomeEnd",
        "primaryMobileStart",
        "primaryMobileEnd",
        "primaryWorkStart",
        "primaryWorkEnd",
        "secondaryPhonePrimary",
        "secondaryHomeStart",
        "secondaryHomeEnd",
        "secondaryMobileStart",
        "secondaryMobileEnd",
        "secondaryWorkStart",
        "secondaryWorkEnd",
        "secondarySendCopy",
        "secondaryEmail",
        "primaryHomePhone",
        "primaryMobilePhone",
        "primaryWorkPhone",
    ],
    validate: checkFromValues,
    asyncBlurFields: ["primaryAddress1"],
})(Parent);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
    const primaryPhone = selector(state, "primaryPhonePrimary");
    const secondaryPhone = selector(state, "secondaryPhonePrimary");
    const primaryLevelEducation = selector(state, "primaryLevelEducation");

    return {
        primaryPhone,
        primaryHomePhone: selector(state, "primaryHomePhone"),
        primaryMobilePhone: selector(state, "primaryMobilePhone"),
        primaryWorkPhone: selector(state, "primaryWorkPhone"),
        secondaryHomePhone: selector(state, "secondaryHomePhone"),
        secondaryMobilePhone: selector(state, "secondaryMobilePhone"),
        secondaryWorkPhone: selector(state, "secondaryWorkPhone"),
        isMobileUser: selector(state, "isMobileUser"),
        secondaryPhone,
        primaryLevelEducation,
        locale: state.locale,
        admissions: state.admissions,
        cities: state.cities,
        secondaryGuardian: selector(state, "secondaryGuardian"),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ajaxStatusLoading: bindActionCreators(ajaxStatusLoadingAction, dispatch),
        ajaxStatusComplete: bindActionCreators(ajaxStatusCompleteAction, dispatch),
        ajaxStatusError: bindActionCreators(ajaxStatusErrorAction, dispatch),
        loadCities: bindActionCreators(loadCities, dispatch),
        blurField: bindActionCreators(blur, dispatch),
        change: bindActionCreators(change, dispatch),
    };
};

let ParentForm = connect(mapStateToProps, mapDispatchToProps)(ReduxForm);


ParentForm = withAdmissions(ParentForm);
ParentForm = withPromptChange(ParentForm, FORM_NAME);
ParentForm = withRouter(ParentForm);

export default ParentForm;
