import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { withRouter, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { setLocaleAction } from "../../language/redux";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import { password, required, renderField, passwordMatch } from "../../support/forms/index";
import Button from "../../support/components/Button";
import * as apis from "../../api/login";

import "./index.css";
import "../index.css";

class ResetPassword extends Component {
    state = {
        showLogin: false,
    };

    componentDidMount = () => {
        const query = this.props.location.search.replace("?", "");
        const params = query.split("&");
        this.email = params[0].split("=")[1];
        this.code = params[1].split("=")[1];
        this.id = params[2].split("=")[1];
        this.locale = params[3].split("=")[1];
        this.props.setLocale(this.locale);
    };

    onSave = (values) => {
        this.props.ajaxStatusLoading();
        apis.resetPassword(this.email, values.password, values.confirm, this.code, this.id, this.locale)
            .then((response) => {
                this.props.ajaxStatusComplete();
                toast.success(this.props.intl.formatMessage({ id: "login.passwordSaved" }));
                this.setState({ showLogin: true });
            })
            .catch((error) => {
                this.props.ajaxStatusError(error.response.data.error.message);
            });
    };

    render() {
        if (this.state.showLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <div className="reset-password">
                <div className="logo" alt="logo" />
                <div className="reset-password-details">
                    <FormattedMessage
                        id="login.reset"
                        defaultMessage="Reset Password"
                        children={(formatedMessage) => <h1 className="screen-title">{formatedMessage}</h1>}
                    />
                    <FormattedMessage
                        id="login.new"
                        defaultMessage="New Password"
                        children={(formattedMessage) => (
                            <Field name="password" type="password" component={renderField} label={formattedMessage} validate={[required, password]} />
                        )}
                    />
                    <span className="login-password-requirements">
                        Passwords must be at least 8 characters long, and must contain a lowercase and uppercase letter, a number, and a special character
                        ($&amp;*@! etc)
                    </span>
                    <FormattedMessage
                        id="login.confirmPassword"
                        defaultMessage="Confirm"
                        children={(formattedMessage) => (
                            <Field name="confirm" type="password" component={renderField} label={formattedMessage} validate={[required, password]} />
                        )}
                    />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <FormattedMessage
                            id="app.save"
                            defaultMessage="Save"
                            children={(formattedMessage) => (
                                <Button
                                    id="login.submit"
                                    defaultMessage="[Submit]"
                                    label={formattedMessage}
                                    onClick={this.props.handleSubmit(this.onSave)}
                                    className="submit-button"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLocale: bindActionCreators(setLocaleAction, dispatch),
    };
};

ResetPassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

ResetPassword = reduxForm({
    form: "resetpassword",
    fields: ["password", "confirm"],
    validate: passwordMatch,
})(ResetPassword);

ResetPassword = withAjaxStatus(ResetPassword);
ResetPassword = withRouter(ResetPassword);
ResetPassword = injectIntl(ResetPassword);

ResetPassword = connect(null, mapDispatchToProps)(ResetPassword);

export default ResetPassword;
