import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./NavigationHeader.css";

import withUser from "../../../support/hoc/withUser";
import { SecurityContext } from "../../../support/security";

let NavigationHeader = ({ user }) => {
  //const isStaff = user && (user.userProfile.userType === 6 || user.userProfile.userType === 99);
  return (
    <SecurityContext.Consumer>
      {context => {
        return (
          <div className="scholarship-reports-header">
            {context.hasReadPermission("Scholarship.Reports") && (
              <NavLink to="/scholarship/reports/summary" activeClassName="active-link">
                <div className="option">Summary</div>
              </NavLink>
            )}
            {context.hasReadPermission("Scholarship.Reports") && (
              <NavLink to="/scholarship/reports/payhistory" activeClassName="active-link">
                <div className="option">Student Pay History</div>
              </NavLink>
            )}
            {context.hasReadPermission("Scholarship.Reports.CertificationList") && (
              <NavLink to="/scholarship/reports/certification" activeClassName="active-link">
                <div className="option">Certification List</div>
              </NavLink>
            )}
            {context.hasReadPermission("Scholarship.Reports") && (
              <NavLink to="/scholarship/reports/not-certified" activeClassName="active-link">
                <div className="option">Not-Certified List</div>
              </NavLink>
            )}
          </div>
        );
      }}
    </SecurityContext.Consumer>
  );
};

NavigationHeader.propTypes = {
  user: PropTypes.shape({
    userProfile: PropTypes.shape({
      userType: PropTypes.number
    })
  })
};

NavigationHeader = withUser(NavigationHeader);
NavigationHeader = withRouter(NavigationHeader);

export default NavigationHeader;
