import React from "react";
import moment from "moment";
import FileSaver from "file-saver";

import b64toBlob from "../../../../support/logic/b64toBlob";
import withAjaxStatus from "../../../../support/hoc/withAjaxStatus";
import { getOverallStats, exportCrusaders } from "../../../../api/applicationQuota";

import "./stats.css";

class QuotaStats extends React.Component {
    state = {
        data: {
            total: 0,
            males: 0,
            females: 0,
            nonLatino: 0,
            latino: 0,
            latinoNoAnswer: 0,
            white: 0,
            black: 0,
            asian: 0,
            indian: 0,
            hawaiian: 0,
            raceTwoOrMore: 0,
        },
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedRecruitmentCycle !== this.props.selectedRecruitmentCycle) {
            this.load();
        }
    }

    async load() {
        if (this.props.selectedRecruitmentCycle === "") {
            return;
        }

        try {
            this.props.ajaxStatusLoading();
            const data = (await getOverallStats(this.props.selectedRecruitmentCycle)).data.result;
            this.setState({ data });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    }

    onExportClick = async () => {
        try {
            this.props.ajaxStatusLoading();
            const response = (await exportCrusaders(this.props.selectedRecruitmentCycle)).data.result;
            this.props.ajaxStatusComplete();
            const blob = b64toBlob(response.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            FileSaver.saveAs(blob, "crusaders.xlsx");
        } catch (err) {
            this.props.ajaxStatusError(err);
        }
    };

    render() {
        const { data } = this.state;

        return (
            <div className="quota-stats">
                <div className="quota-stats-header">
                    <div>Total Quota</div>
                    <div>
                        {data.total} /{" "}
                        <input
                            className="form-input"
                            type="number"
                            value={this.props.quotaValue}
                            onChange={this.props.onQuotaChange}
                            onBlur={this.props.onQuotaBlur}
                            min="0"
                        />
                    </div>
                </div>
                <div className="quota-stats-date-container">
                    <div className="quota-stats-date">As of {moment().format("dddd, MMMM Do YYYY")}</div>
                    <div>
                        <button className="btn" onClick={this.onExportClick}>
                            Export to Excel
                        </button>
                    </div>
                </div>
                <div className="quota-stats-top-area">
                    <div>
                        <ul>
                            <li>
                                <span>{data.males}</span> Males
                            </li>
                            <li>
                                <span>{data.females}</span> Females
                            </li>
                            <li>
                                <span>{data.other}</span> Other
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <span>{data.nonLatino}</span> Non-Hispanic or Latino
                            </li>
                            <li>
                                <span>{data.latino}</span> Hispanic or Latino
                            </li>
                            <li>
                                <span>{data.latinoNoAnswer}</span> No Response
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <span>{data.grade5}</span> Grade 5
                            </li>
                            <li>
                                <span>{data.grade6}</span> Grade 6
                            </li>
                            <li>
                                <span>{data.grade7}</span> Grade 7
                            </li>
                            <li>
                                <span>{data.grade8}</span> Grade 8
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <span>{data.white}</span> White
                        </li>
                        <li>
                            <span>{data.black}</span> Black or African American
                        </li>
                        <li>
                            <span>{data.asian}</span> Asian
                        </li>
                        <li>
                            <span>{data.indian}</span> American Indian or Alaska Native
                        </li>
                        <li>
                            <span>{data.hawaiian}</span> Native Hawaiian or Other Pacific Islander
                        </li>
                        <li>
                            <span>{data.raceTwoOrMore}</span> Two or more
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

QuotaStats = withAjaxStatus(QuotaStats);

export default QuotaStats;
