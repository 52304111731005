import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { toast } from "react-toastify";
import UserConfirmation from "../../../support/components/userConfirm";
import withAjaxStatus from "../../../support/hoc/withAjaxStatus";
import Programs from "./components/Programs";
import StartCloseYear from "./components/StartCloseYear";
import * as apis from "./apis";

import "./index.css";

const PROGRAMS = 1;
const START_CLOSE_YEAR = 2;
//const STATUS_NOT_STARTED = 0;
const STATUS_STARTED = 1;

class Scholarship extends Component {
    static permission = "Admin.Scholarship";
    state = {
        show: START_CLOSE_YEAR,
        scholarships: [],
        showConfirm: false,
        showConfirmMessage: "",
    };
    onNav = (option) => {
        this.setState({
            show: option,
        });
    };
    componentDidMount = async () => {
        try {
            this.props.ajaxStatusLoading();
            var results = await apis.get();

            var scholarships = results.data.result;

            this.setState({
                scholarships,
            });
            this.props.ajaxStatusComplete();
        } catch (err) {
            this.props.ajaxStatusError("Error retrieving scholarships");
        }
    };
    onStartCloseClick = (scholarship) => {
        if (!scholarship) return;
        this.scholarship = scholarship;
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();

        if (scholarship.startYear > currentYear && scholarship.endYear > currentYear) {
            this.props.ajaxStatusError("Can not change status of a future school year");
            return;
        }
        if (scholarship.status === STATUS_STARTED && (scholarship.endYear > currentYear || (scholarship.endYear === currentYear && currentMonth < 6))) {
            this.props.ajaxStatusError("Can not close an academic year that ends in the future");
            return;
        }
        var message = `Please confirm starting academic year ${scholarship.startYear} - ${scholarship.endYear}`;
        if (scholarship.status === STATUS_STARTED) {
            message = `Please confirm closing academic year ${scholarship.startYear} - ${scholarship.endYear}`;
        }

        this.setState({
            showConfirmMessage: message,
            showConfirm: true,
        });
    };
    onStartCloseConfirmClick = async () => {
        if (!this.scholarship) return;

        try {
            this.props.ajaxStatusLoading();

            if (this.scholarship.status === STATUS_STARTED) {
                await apis.close(this.scholarship);
            } else {
                await apis.start(this.scholarship);
            }

            var results = await apis.get();

            var scholarships = results.data.result;

            this.setState({
                scholarships,
                showConfirm: false,
                showConfirmMessage: "",
            });
            this.props.ajaxStatusComplete();
            toast.success("Save Complete");
        } catch (err) {
            this.props.ajaxStatusError("Error setting scholarship status");
        }
    };
    render() {
        return (
            <div className="admin-scholarship">
                <div className="admin-scholarship-nav">
                    <div className="nav-button-with-arrow">
                        <button className="link-button" onClick={this.onNav.bind(this, PROGRAMS)}>
                            Programs
                        </button>
                        {this.state.show === PROGRAMS ? (
                            <FontAwesome name="arrow-down" className="complete" color="#00b5e2" />
                        ) : (
                            <div className="button-spacer-height" />
                        )}
                    </div>
                    <div className="nav-button-with-arrow">
                        <button className="link-button" onClick={this.onNav.bind(this, START_CLOSE_YEAR)}>
                            Start/Close Year
                        </button>
                        {this.state.show === START_CLOSE_YEAR ? (
                            <FontAwesome name="arrow-down" className="complete" color="#00b5e2" />
                        ) : (
                            <div className="button-spacer-height" />
                        )}
                    </div>
                </div>
                {this.state.show === PROGRAMS ? <Programs /> : null}
                {this.state.show === START_CLOSE_YEAR ? (
                    <StartCloseYear
                        scholarships={this.state.scholarships}
                        onClick={this.onStartCloseClick}
                        isReadonly={this.props.readonly === undefined ? false : this.props.readonly}
                    />
                ) : null}
                {this.state.showConfirm ? (
                    <UserConfirmation
                        message={this.state.showConfirmMessage}
                        title="Confirm"
                        yes="Yes"
                        cancel="No"
                        onCancel={() => this.setState({ showConfirm: false })}
                        onConfirm={this.onStartCloseConfirmClick}
                    />
                ) : null}
            </div>
        );
    }
}

Scholarship = withAjaxStatus(Scholarship);

export default Scholarship;
