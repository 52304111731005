import React from "react";
import Switch from "@material-ui/core/Switch";
import { FormControl, FormLabel } from "@material-ui/core";

const FinalFormSwitch = ({ label, input: { name, onChange, value, ...restInput }, meta, defaultValue, ...rest }) => (
    <FormControl>
        <FormLabel>{label}</FormLabel>
        <Switch {...rest} name={name} onChange={(event) => onChange(event.target.checked)} value={name} checked={!!value} defaultValue={defaultValue} />
    </FormControl>
);

export default FinalFormSwitch;
