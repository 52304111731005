import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { required } from "../../../../common/formValidators";
import LoadingButton from "../../../../common/loadingButton";
import { FinalFormCheckbox, FinalFormTextField } from "../../../../common/formWrappers";


class Subtag extends Component {

 

    handleSubmit = (values, form) => {
        
        this.props.onUpdate(values);
    }
    render() {
        
        return (<div className="subTag">
            <hr />

            <Form onSubmit={this.handleSubmit} initialValues={this.props.subTag}>
                {({ handleSubmit, values, dirty, errors }) => {

                    return (
                        <form onSubmit={handleSubmit} FormValues={this.props.subTag} noValidate className="test">

                            <div className={"test"}>
                                <table>
                                    <tr>
                                        <td>
                                            <Field name="id" component="input" type="hidden" />
                                            <Field name="tagId" component="input" type="hidden" />
                                            <Field
                                                component={FinalFormTextField}
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                margin="dense"
                                                required
                                                validate={required}
                                                wrapperClassName={"formlabel"}
                                            />
                                        </td>
                                        <td>

                                            <Field component={FinalFormCheckbox} name="isActive" label="Is Active" />&nbsp;
                                            <Field component={FinalFormCheckbox} name="ipedRequired" label="IPED Number Required" />&nbsp;
                                            <Field component={FinalFormCheckbox} name="descriptorRequired" label="Descriptor Required" />&nbsp;

                                        </td>
                                        <td>
                                            <LoadingButton
                                                wrapperClassName={"test"}
                                                className={"test"}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </LoadingButton>
                                        </td>
                                </tr>
                                </table>
                               

                            </div>
                            
                        </form>
                    );
                }}
            </Form>
        </div>);
    };
}

Subtag.propTypes = {
    schools: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};


export default Subtag;