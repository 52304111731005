import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import EligibilityCriteriaUi from "../../eligibleParticipantList/eligibilityCriteriaUi";
import { useInstitution, useSchool, useDistrict } from "../../hooks";

const EligibilityCriteria = ({
    setEligibilityCriteria,
    eligibilityCriteria
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const { institutionList, loading: loadingInstitutionList } = useInstitution();
    const { schoolList, loading: loadingSchoolList } = useSchool();
    const { districtList, loading: loadingDistrictList } = useDistrict();
    const institutionNameById = institutionList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});
    const schoolNameById = schoolList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});
    const districtNameById = districtList.reduce((total, curr) => ({ ...total, [curr.id]: curr.name }), {});

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleSave = (values) => {
        const payload = {
            ...values,
            gradeNumbers: values.gradeNumbers?.reduce(
                (total, curr) => (curr.selected ? [...total, {gradeNumber: curr.value }] : total),
                []
            ),
            mathMin: parseInt(values.mathMin),
            mathMax: parseInt(values.mathMax),
            scienceMin: parseInt(values.scienceMin),
            scienceMax: parseInt(values.scienceMax),
            englishMin: parseInt(values.englishMin),
            englishMax: parseInt(values.englishMax),
            institutions: values.institutions?.reduce((total, curr) => (curr?.id ? [...total, {institutionId: curr.id }] : total), []),
            schools: values.schools?.reduce((total, curr) => (curr?.id ? [...total, {schoolId: curr.id }] : total), []),
            districts: values.districts?.reduce((total, curr) => (curr?.id ? [...total, {districtId: curr.id }] : total), []),
            cohortNumbers: values.cohortNumbers.reduce(
                (total, curr) => (!!curr ? [...total, {cohortNumber: parseInt(curr) }] : total),
                []
            ),
        };
        handleDialogClose()
        setEligibilityCriteria(payload)
    }

    const handleClear = (payload) => {
        handleDialogClose()
        setEligibilityCriteria(payload)
    }

    return (
        <>
            <IconButton
                edge="end"
                size="large"
                color="primary"
                style={{ padding: 0, marginTop: 8 }}
                onClick={handleDialogOpen}
            >
                <FilterAltIcon />
            </IconButton>
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="lg"
            >
                <DialogContent>
                    <EligibilityCriteriaUi
                        isDialog={true}
                        isLoading={loading || loadingInstitutionList || loadingSchoolList || loadingDistrictList}
                        criteria={eligibilityCriteria}
                        handleSave={handleSave}
                        institutionNameById={institutionNameById}
                        schoolNameById={schoolNameById}
                        districtNameById={districtNameById}
                        institutionList={institutionList}
                        schoolList={schoolList}
                        districtList={districtList}
                        handleClear={handleClear}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EligibilityCriteria;
