import React from "react";
import { useStyles } from "./styles";

const ProgramCodeData = ({ eventCreditHours, eventTime }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h3>Event Hours: {eventCreditHours}</h3>
            <small>{eventTime}</small>
        </div>
    );
};

export default ProgramCodeData;
