import React, { useState, useEffect } from "react";
import moment from "moment";

import * as RecruitmentCycleApi from "../../../api/recruitment";
import * as RecruitmentCycleUtils from "../../logic/recruitmentCycle";
import withAjaxStatus from "../../hoc/withAjaxStatus";

import "./index.css";

const formatDate = (date) => moment(date).utc().format("MMMM Do YYYY");

const RecruitmentCycleSelector = ({ ajaxStatusLoading, ajaxStatusComplete, ajaxStatusError, value, onChange }) => {
    const [cycles, setCycles] = useState([]);

    const getDefaultCycle = (cycles) => {
        const savedCycle = localStorage.getItem("recruitmentCycle");

        if (savedCycle) {
            var cycle = cycles.find((i) => i.id === savedCycle);
            if (cycle) {
                return cycle.id;
            }
        }

        if (cycles.length > 0) {
            localStorage.setItem("recruitmentCycle", cycles[0].id);
            return cycles[0].id;
        }

        return "";
    };

    useEffect(() => {
        const loadRecruitmentCycles = async () => {
            try {
                ajaxStatusLoading();
                let recruitmentCycles = (await RecruitmentCycleApi.getAll()).data.result.items;
                recruitmentCycles = recruitmentCycles.filter(RecruitmentCycleUtils.filterCurrentOrPast(moment()));
                recruitmentCycles.sort(RecruitmentCycleUtils.itemSort);
                const defaultCycle = getDefaultCycle(recruitmentCycles);
                if (defaultCycle !== "") {
                    onChange(defaultCycle);
                }
                setCycles(recruitmentCycles);
                ajaxStatusComplete();
            } catch (err) {
                ajaxStatusError(err);
            }
        };

        loadRecruitmentCycles();
    }, [ajaxStatusLoading, ajaxStatusComplete, ajaxStatusError, onChange]);

    const onChangeCycle = (evt) => {
        onChange(evt.target.value);
        localStorage.setItem("recruitmentCycle", evt.target.value);
    };

    return (
        <div className="recruitment-cycle-selector">
            <span>Recruitment Cycle:</span>
            <select value={value} onChange={onChangeCycle}>
                {cycles.map((i) => (
                    <option key={i.id} value={i.id}>
                        {formatDate(i.startDate)} - {formatDate(i.endDate)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default withAjaxStatus(RecruitmentCycleSelector);
