import makeStyles from "@material-ui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    outlinedSection: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    sectionTitleWithIcon: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
    },
    icon: {
        color: "white",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        position: "relative",
        marginLeft: theme.spacing(1),
    },
    languageToggle: {
        marginLeft: "auto",
    },
}));
