import React from "react";
import ReactTable from "react-table";
import moment from "moment";
import { formatCurrencyNoDecimalWithNegativeStyle } from "../../../../support/numberSupport";

const StudentList = ({ data }) => {
  return (
    <div className="scholarship-recieve-refund-student-list">
      <ReactTable
        className="financials-table"
        data={data}
        minRows={0}
        showPagination={true}
        columns={[
          {
            Header: "Last Name",
            accessor: "lastName",
            minWidth: 150
          },
          {
            Header: "First Name",
            accessor: "firstName",
            minWidth: 150
          },
          {
            Header: "SSN/Alien ID",
            accessor: "ssnAlienId"
          },
          {
            Header: "Term",
            accessor: "termInt",
            minWidth: 150,
            Cell: row => {
              return <div>{row.original.term}</div>;
            }
          },
          {
            Header: "Certified",
            accessor: "status",
            minWidth: 150,
            Cell: row => {
              return <div>{row.original.certified}</div>;
            }
          },
          {
            Header: "Date",
            accessor: "date",
            Cell: row => {
              const date = moment.utc(row.original.date).format("M/D/YY");
              return <div>{date}</div>;
            }
          },
          {
            Header: "Reason",
            accessor: "reason",
            minWidth: 350,
            sortable: false
          },

          {
            Header: "Amount",
            accessor: "amount",
            Cell: row => {
              return (
                <div>
                  {formatCurrencyNoDecimalWithNegativeStyle(
                    row.original.amount
                  )}
                </div>
              );
            }
          }
        ]}
      />
    </div>
  );
};

export default StudentList;
