import React from "react";
import { Route, Redirect } from "react-router-dom";

import TertiaryNav from "../../../common/tertiaryNav";
import GeneralConfig from "./generalConfig";
import OrientationVideos from "./orientationVideos";
import RecruitmentCycle from "./recruitmentCycle";

import "./index.css";

const links = [
    {
        label: "General Configuration",
        to: "/admin/admissions/general-config",
        permission: "Admin.Admissions",
    },
    {
        label: "Orientation Videos",
        to: "/admin/admissions/orientation-videos",
        permission: "Admin.Admissions",
    },
    {
        label: "Recruitment Cycle Configuration",
        to: "/admin/admissions/recruitment-cycle",
        permission: "Admin.Admissions",
    },
];

const AdminAdmissionsPage = () => {
    return (
        <div className="admin-admissions-page">
            <TertiaryNav links={links} />
            <div className="hr-line-medium margin-top" />
            <Route path="/admin/admissions/general-config" component={GeneralConfig} />
            <Route path="/admin/admissions/orientation-videos" component={OrientationVideos} />
            <Route path="/admin/admissions/recruitment-cycle" component={RecruitmentCycle} />
            <Route path="/admin/admissions" exact render={() => <Redirect exact path="/admin/admissions" to="/admin/admissions/general-config" />} />
        </div>
    );
};

export default AdminAdmissionsPage;
