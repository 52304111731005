import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateVideoSettingsAction, updateAdmissionSettingsAction } from "../../redux/codeTables";

const useAdmissionsSettings = () => {
    const admissionSettings = useSelector((state) => state.admissionSettings);
    const dispatch = useDispatch();

    return {
        admissionSettings,
        updateVideoSettings: useCallback((videoSettings) => dispatch(updateVideoSettingsAction(videoSettings)), [dispatch]),
        updateAdmissionSettings: useCallback((admissionSettings) => dispatch(updateAdmissionSettingsAction(admissionSettings)), [dispatch]),
    };
};

export default useAdmissionsSettings;
