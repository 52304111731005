import React from 'react';
import { connect } from 'react-redux';

const withUser = WrappedComponent => {
    class WithUser extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        user: state.user
    });

    WithUser = connect(mapStateToProps)(WithUser);

    return WithUser;
};

export default withUser;
