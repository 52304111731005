import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resubmit from "./resubmit";
import StaffLayout from "../common/staffLayout";

class ResubmitContainer extends React.Component {
    static permission = "Applications.Resubmit";

    componentDidMount() {
        this.props.loadData();
    }

    getTrProps = (state, rowInfo) => {
        return {
            onClick: (e) => {
                this.rowClick(rowInfo.original);
            },
        }; 
    };

    rowClick = (row) => {
        window.previousLocation = "resubmit";
        this.props.history.push(`/applications/application/${row.applicationId}/s/${row.userProfileId}`);
    };

    getSchoolData(schoolName) {
        return this.props.data.filter((d) => d.school === schoolName);
    }

    renderSchools() {
        return this.props.schools.map((school, index) => (
            <Resubmit school={school.name} data={this.getSchoolData(school.name)} key={index} getTrProps={this.getTrProps} />
        ));
    }

    render() {
        const { showAll } = this.props;
        const totalResubmit = this.props.data.length;
        const unassociatedData = this.getSchoolData("");

        return (
            <StaffLayout>
                <h3>Total Resubmit: {totalResubmit}</h3>
                <br />
                {this.renderSchools()}
                {unassociatedData.length > 0 && <Resubmit school="Unassociated" data={unassociatedData} getTrProps={this.getTrProps} />}
            </StaffLayout>
        );
    }
}

ResubmitContainer.propTypes = {
    showAll: PropTypes.bool.isRequired,
    data: PropTypes.array,
    schools: PropTypes.array,
};
const mapStateToProps = (state) => ({
    schools: state.staffMiddleSchools,
});

ResubmitContainer = connect(mapStateToProps)(ResubmitContainer);
ResubmitContainer = withRouter(ResubmitContainer);

export default ResubmitContainer;
