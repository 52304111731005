import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { Prompt, useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import BackButton from "../../../common/backButton";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { toast } from "react-toastify";
import LoadingButton from "../../../common/loadingButton";
import { FinalFormDropdown, FinalFormTextField } from "../../../common/formWrappers";
import LoadingIndicator from "../../../common/loadingIndicator";
import { email, required } from "../../../common/formValidators";
import { PhoneMaskInput, ZipCodeMask } from "../../../common/masks";
import useParentUser from "../hooks/useParentUser";
import useCrusaderUser from "../hooks/useCrusaderUser";
import AccountLoginForm from "./AccountLoginForm";
import { updatePassword, updateUsername, updateActiveStatus, getPotentialDupes } from "./apis";
import { states as stateOptions } from "../../../support/states";
import { UserType } from "../../../support/enums";
import { getList as getCities } from "../../../api/city";
import DeDupe from "./dedupe";
const focusOnError = createDecorator();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        maxWidth: "80%",
        marginBottom: theme.spacing(2),
    },
    formHeader: {
        display: "grid",
        gridTemplateColumns: "70px auto 70px",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"backButton title saveButton"`,
        marginBottom: theme.spacing(3),
        position: "sticky",
        top: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: "#ffffff",
        zIndex: 1,
    },
    backButton: {
        gridArea: "backButton",
    },
    formTitle: {
        gridArea: "title",
        justifySelf: "center",
    },
    saveButtonContainer: {
        gridArea: "saveButton",
        justifySelf: "end",
    },
    verificationCodeBtn: {
        alignSelf: "start",
        marginBottom: theme.spacing(1),
    },
    accountLoginHeader: {
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"accountLoginTitle accountLoginSaveButton"`,
        marginBottom: theme.spacing(1),
    },
    accountLoginTitle: {
        gridArea: "accountLoginTitle",
    },
    accountLoginSaveButtonContainer: {
        gridArea: "accountLoginSaveButton",
        justifySelf: "end",
    },
    addIcon: {
        alignSelf: "flex-start",
        color: "#fff",
        fontWeight: "bold",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    autocompleteFormControl: {
        "& > div > div": {
            margin: 0,
        },
    },
    childMenuIconBtn: {
        marginLeft: "auto",
    },
}));

const ParentUserForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { parentUser, loading, loadParentUserAsync, updateParentAsync, setParentUser } = useParentUser();
    const { loadAllCrusadersAsync, loading: loadingCrusaderUsers } = useCrusaderUser();
    const [cityOptions, setCityOptions] = useState([]);
    const [dupes, setDupes] = useState([]);
    useEffect(() => {
        loadParentUserAsync(id);
        getPotentialDupes(id)
            .then((response) => {

                const users = response.data.result;
                
                setDupes(users);

            })
            .catch((error) => {
                this.props.ajaxStatusError(error.message);
            });
    }, [id, loadParentUserAsync]);

    useEffect(() => {
        loadAllCrusadersAsync();
    }, [loadAllCrusadersAsync]);


    useEffect(() => {
        const getCityOptions = async () => {
            const cities = await getCities();            
            const formattedCities = cities.data.result.map((city) => {
                return {label: city.name, value: city.name};
            });
            setCityOptions(formattedCities);
        }
        getCityOptions();
      }, []);

    const onUserUpdated = async () => {
       await loadParentUserAsync(id);

    }
    const handleUsernameSubmit = async ({ username }) => {
        try {
            await updateUsername({ userId: parentUser.user.userId, username });
            toast.success("Username updated successfully");
            setParentUser({ ...parentUser, user: { ...parentUser.user, userName: username } });
        } catch (error) {
            toast.error(error.response.data.error.message);
        }
    };

    const handlePasswordSubmit = async ({ password, confirmPassword }) => {
        try {
            await updatePassword({ userId: parentUser.user.userId, password, confirmPassword });
            toast.success("Password updated successfully");
        } catch (error) {
            toast.error(error.response.data.error.message);
        }
    };

    const handleActiveStatusSubmit = async ({ isActive }) => {
        try {
            await updateActiveStatus({ userId: parentUser.user.userId, isActive });
            toast.success("Active status updated successfully");
            setParentUser({ ...parentUser, user: { ...parentUser.user, isActive } });
        } catch (error) {
            toast.error(error.response.data.error.message);
        }
    };

    if (loading || loadingCrusaderUsers) {
        return <LoadingIndicator loading />;
    }

    return (
        <Form
            onSubmit={updateParentAsync}
            mutators={{ ...arrayMutators }}
            decorators={[focusOnError]}
            initialValues={{
                ...parentUser,
                children: parentUser.children.map((ch) => ({
                    ...ch,
                    anchorEl: React.createRef(),
                    showMenu: false,
                })),
            }}
        >
            {({ handleSubmit, form, values, dirty }) => {
                return (
                    <form className={classes.root} onSubmit={handleSubmit} noValidate>
                        <Prompt
                            when={dirty}
                            message={() => "You have unsaved changes. Are you sure you want to leave?||Are you sure?||Yes, Leave||No, stay here"}
                        />
                        <div className={classes.formHeader}>
                            <div className={classes.backButton}>
                                <BackButton />
                            </div>
                            <Typography className={classes.formTitle} variant="h5">{`Edit: ${values.firstName} ${values.lastName}`}</Typography>
                            <LoadingButton
                                wrapperClassName={classes.saveButtonContainer}
                                className={classes.saveButton}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Save
                            </LoadingButton>
                        </div>
                        <DeDupe dupes={dupes} cityOptions={cityOptions} activeUp={parentUser} reload={loadParentUserAsync} />
                        <Typography variant="h5" color="secondary" gutterBottom>
                            GENERAL
                        </Typography>
                        
                        <Box display="flex" flexDirection="column">
                            <Field
                                component={FinalFormTextField}
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                margin="dense"
                                required
                                validate={required}
                            />
                            <Field component={FinalFormTextField} name="middleName" label="Middle Name" placeholder="Middle Name" margin="dense" />
                            <Field
                                component={FinalFormTextField}
                                name="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                margin="dense"
                                required
                                validate={required}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            CONTACT
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field component={FinalFormTextField} name="email" label="Email" placeholder="Email" margin="dense" validate={email} />
                            <Field component={FinalFormTextField} name="mobilePhone" label="Cell Phone Number" margin="dense" inputComponent={PhoneMaskInput} />
                            <Field
                                component={FinalFormTextField}
                                name="address1"
                                label="Street Address Line 1"
                                placeholder="Street Address Line 1"
                                margin="dense"
                                required
                                validate={required}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="address2"
                                label="Street Address Line 2"
                                placeholder="Street Address Line 2"
                                margin="dense"
                            />
                            <Field 
                                component={FinalFormDropdown} 
                                name="city" 
                                label="City" 
                                placeholder="City" 
                                margin="dense"
                                choices={cityOptions}
                                required 
                                validate={required} />
                            <Field
                                component={FinalFormDropdown}
                                name="state"
                                variant="outlined"
                                margin="dense"
                                label="State"
                                choices={stateOptions}
                                required
                                validate={required}
                            />
                            <Field
                                component={FinalFormTextField}
                                name="zipCode"
                                label="Zip Code"
                                margin="dense"
                                fullWidth
                                required
                                validate={required}
                                inputComponent={ZipCodeMask}
                            />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            HEALTH
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field component={FinalFormTextField} name="allergies" label="Allergies" margin="dense" />
                            <Field component={FinalFormTextField} name="medications" label="Medications" margin="dense" />
                            <Field component={FinalFormTextField} name="dietaryRestrictions" label="Dietary Restrictions" margin="dense" />
                            <Field component={FinalFormTextField} name="medicalConditions" label="Medical Conditions" margin="dense" />
                            <Field component={FinalFormTextField} name="specialAccommodations" label="Special Accomodations" margin="dense" />
                        </Box>
                        <AccountLoginForm
                            user={parentUser.user}
                            onUsernameSubmit={handleUsernameSubmit}
                            onPasswordSubmit={handlePasswordSubmit}
                            onActiveStatusSubmit={handleActiveStatusSubmit}
                            userProfileId={id}
                            onUserUpdated={onUserUpdated}
                        />
                        <Typography variant="h5" color="secondary" gutterBottom>
                            EVENTS
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            <Field component={FinalFormTextField} name="serviceHours" label="Service Hours" margin="dense" disabled />
                        </Box>
                        <Typography variant="h5" color="secondary" gutterBottom>
                            STUDENTS
                        </Typography>
                        <Box display="flex" flexDirection="column">
                            {values.children.length === 0 && (
                                <Typography variant="body1" gutterBottom>
                                    No students linked to this student.
                                </Typography>
                            )}
                            {values.children.map((child, index) => {
                                //console.log(child);
                                const toggleMenu = () =>
                                    form.mutators.update("children", index, {
                                        ...child,
                                        showMenu: !child.showMenu,
                                    });
                                return (
                                    <Box key={child.userProfileId} display="flex" alignItems="center">
                                        <Typography variant="body1">
                                            {child.userProfile.firstName}&nbsp;{child.userProfile.lastName}
                                        </Typography>
                                        <IconButton
                                            aria-label="options"
                                            className={classes.childMenuIconBtn}
                                            ref={child.anchorEl}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleMenu();
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu id="simple-menu" anchorEl={child.anchorEl.current} keepMounted open={child.showMenu} onClose={toggleMenu}>
                                            {child.userProfile.userType === UserType.Applicant ? <MenuItem

                                                onClick={() => {
                                                    toggleMenu();
                                                    const win = window.open(`/applications/application/${child.applicationId}/s/${child.userProfileId}`, "_blank");
                                                    win.focus();
                                                }}
                                            >
                                                View application
                                            </MenuItem> : <MenuItem

                                                onClick={() => {
                                                    toggleMenu();
                                                    const win = window.open(`/admin/users/crusader/${child.userProfileId}`, "_blank");
                                                    win.focus();
                                                }}
                                            >
                                                View profile
                                            </MenuItem>}

                                        </Menu>
                                    </Box>
                                );
                            })}
                        </Box>
                    </form>
                );
            }}
        </Form>
    );
};

export default ParentUserForm;
