import React, { Component } from "react";

import { Grid, } from '@material-ui/core';

import AdvisorContext from "../context/context";


import "./Edit.css";


class LegacyEvent extends Component {

    static contextType = AdvisorContext;
    

    componentDidMount = async () => {


        
    }

    componentDidUpdate(prevProps, prevState) {
       


    }





   

    render() {
        const formattedDate = new Date(this.props.event.date).toLocaleDateString();
        //console.log(this);
        return (
            <div>
                <Grid container spacing={2}>
                    
                    <Grid item xs={6} className="advisorProfileNameColumn">
                        <span className="EventLabel">Program Id:</span> { this.props.event.programId}
                    </Grid>
                    <Grid item xs={6}>
                        <span className="EventLabel">Program Name:</span> {this.props.event.name}

                    </Grid>
                    <Grid item xs={6} className="advisorProfileNameColumn">
                        <span className="EventLabel">Program Date:</span> {formattedDate}
                    </Grid>
                    <Grid item xs={6}>
                        <span className="EventLabel">Credit Hours:</span> {this.props.event.creditHours}

                    </Grid>

                    <Grid item xs={6} className="advisorProfileNameColumn">
                        <span className="EventLabel">School Year:</span> {this.props.event.school_Year}
                    </Grid>
                    <Grid item xs={6} className="advisorProfileNameColumn">
                        <span className="EventLabel">Grade:</span> {this.props.event.grade}
                    </Grid>
                </Grid>



               
            </div>
        );
    }
}

export default LegacyEvent;
