import React, { useState } from "react";
import ReactTable from "react-table";
import moment from "moment";
import FileSaver from "file-saver";
import { useHistory } from "react-router";

import { exportAppReport } from "../../../../api/applicationReports";
import { getAll } from "../../../../api/recruitment";
import enumHelper from "../../../../support/logic/enumHelper";
import b64toBlob from "../../../../support/logic/b64toBlob";
import useAjaxStatus from "../../../../common/useAjaxStatus";
import { ApplicationStatus, GroupType } from "../../../../admissions/logic/enums";

import "./index.css";

const formatDate = (date) => moment(date).utc().format("MM/DD/YYYY");

const AppReport = (reportData) => {
    const [loading, setLoading] = useState(false);
    const { ajaxError, ajaxLoad, ajaxComplete } = useAjaxStatus();
    const history = useHistory();

    const getTrProps = (state, rowInfo) => {
        return {
            onClick: (e) => {
                rowClick(rowInfo.original);
            },
        };
    };

    const rowClick = (row) => {
        window.previousLocation = "reports";
        history.push(`/applications/application/${row.applicationId}/s/${row.userProfileId}`);
    };

    const onExport = async () => {
        ajaxLoad();
        setLoading(true);
        const result = (await exportAppReport(reportData.selectedCycle, reportData.search)).data.result;
        const blob = b64toBlob(result.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        FileSaver.saveAs(blob, "applicants.xlsx");
        ajaxComplete();
        setLoading(false);
    };
    return (
        <div className="application-reports-app">
            <div className="application-reports-app__actions">
                <button className={"application-reports-app__export"} onClick={onExport} disabled={loading}>
                    Export
                </button>
            </div>
            <ReactTable
                getTrProps={getTrProps}
                data={reportData.data}
                minRows={0}
                className="-highlight"
                defaultPageSize={15}
                pageSizeOptions={[5, 10, 15, 25, 50, 100]}
                defaultSorted={[{ id: "lastName", desc: false }]}
                columns={[
                    {
                        Header: "Last Name",
                        accessor: "lastName",
                    },
                    {
                        Header: "First Name (Middle)",
                        id: "firstName",
                        accessor: (item) => (item.middleName ? `${item.firstName} (${item.middleName})` : item.firstName),
                    },
                    {
                        Header: "DOB",
                        id: "dateOfBirth",
                        accessor: (item) => (item.dateOfBirth ? formatDate(item.dateOfBirth) : ""),
                    },
                    {
                        Header: "Status",
                        id: "status",
                        accessor: (item) => enumHelper.getDisplayName(ApplicationStatus, item.status),
                    },
                    {
                        Header: "Missing Documents",
                        accessor: "missingDocuments",
                        Cell: (item) => {
                            let missingDocs = "";
                            if(item != undefined){
                                missingDocs = (item.value != undefined) ? item.value.map((item) => enumHelper.getDisplayName(GroupType, item)).join(", ") : "";
                            }
                                return <span title={missingDocs}>{missingDocs}</span>;
                        },
                        sortMethod: (a, b, desc) => {
                            if (a.length > b.length) {
                                return 1;
                            }
                            if (a.length < b.length) {
                                return -1;
                            }

                            const a1 = a.map((item) => enumHelper.getDisplayName(GroupType, item)).join(", ");
                            const b1 = b.map((item) => enumHelper.getDisplayName(GroupType, item)).join(", ");

                            if (a1 > b1) {
                                return 1;
                            }

                            if (a1 < b1) {
                                return -1;
                            }

                            return 0;
                        },
                    },
                ]}
            />
        </div>
    );
};

export default AppReport;
