import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import withAdmissions from "../../support/hoc/withAdmissions";
import Form from "./Form";
import withAjaxStatus from "../../support/hoc/withAjaxStatus";
import { getCompactFormHtml, getMembershipFormHtml, signCompactAndMembershipForm } from "../../api/contract";
import ViewContract from "./ViewContract";
import { AdmissionsTabs } from "../logic/enums";

const FORM_NAME = "contracts";

class Contracts extends Component {
    constructor(props) {
        super(props);
        this.state = { compactForm: "<div></div>", membershipForm: "<div></div>", showOrientationComplete: false, showContractComplete: false };
    }

    componentDidMount() {
        this.getFormHtml();
        
    }

    getFormHtml = async () => {
        try {
            this.props.ajaxStatusLoading();
            const response = await Promise.all([getCompactFormHtml(this.props.locale), getMembershipFormHtml(this.props.locale)]);
            this.setState({ compactForm: response[0].data.result, membershipForm: response[1].data.result });
        } catch (err) {
            this.props.ajaxStatusError(err);
        } finally {
            this.props.ajaxStatusComplete();
        }
    };

    handleSave = async (values) => {
        try {
            this.props.ajaxStatusLoading();

            const input = { ...values, applicationId: this.props.admissions.id, currentLocale: this.props.locale };
            const orientationComplete = (await signCompactAndMembershipForm(input)).data.result;
            if (this.props.match && this.props.match.params.id) {
                this.props.loadApplication(this.props.admissions.id);
            } else {
                this.props.loadApplication(this.props.admissions.id);
            }
            if (orientationComplete) {
                this.setState({ showOrientationComplete: true });
            } else {
                this.setState({ showContractComplete: true });
            }
        } catch (err) {
            this.props.ajaxStatusError(err);
        } finally {
            this.props.ajaxStatusComplete();
        }
    };

    onConfirmOrientationComplete = () => {
        this.setState({ showOrientationComplete: false });
    };

    onConfirmContractComplete = () => {
        this.setState({ showContractComplete: false });
    };

    render() {
        return this.props.admissions && !this.props.admissions.progress.tabProgress[AdmissionsTabs.Contracts] ? (
            <Form
                handleSubmit={this.props.handleSubmit}
                onSave={(values) => this.handleSave(values)}
                formData={this.state}
                students={this.props.admissions ? this.props.admissions.userProfiles : []}
                match={this.props.match}
                readonly={this.props.readOnly }
            />
        ) : this.props.admissions && this.props.admissions.progress.tabProgress[AdmissionsTabs.Contracts] ? (
            <ViewContract
                documentId={this.props.admissions.contractDocumentId}
                showOrientationComplete={this.state.showOrientationComplete}
                onConfirmOrientationComplete={this.onConfirmOrientationComplete}
                showContractComplete={this.state.showContractComplete}
                onConfirmContractComplete={this.onConfirmContractComplete}
            />
        ) : null;
    }
}

const ReduxForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
})(Contracts);

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        admissions: state.admissions,
        initialValues: {
            compact: {
                parentName: `${state.user.userProfile.firstName} ${state.user.userProfile.lastName}`,
                students: state.admissions ? state.admissions.userProfiles.map((profile) => ({ studentName: profile.userProfileName })) : [],
            },
            membership: {
                parentName: `${state.user.userProfile.firstName} ${state.user.userProfile.lastName}`,
            },
        },
    };
};

let ContractsForm = connect(mapStateToProps)(ReduxForm);

ContractsForm = withAdmissions(ContractsForm);
ContractsForm = withAjaxStatus(ContractsForm);
ContractsForm = withRouter(ContractsForm);

export default ContractsForm;
