import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";

const ContractCompleteAlert = ({ intl, show, onConfirm }) => {
  const ok = intl.formatMessage({ id: "app.ok" });
  const title = intl.formatMessage({ id: "orientation.oneMoreStep" });
  const message = intl.formatMessage({ id: "orientation.contractComplete" });

  return (
    <SweetAlert show={show} title={title} onConfirm={onConfirm} confirmBtnText={ok}>
      {message}
    </SweetAlert>
  );
};

export default injectIntl(ContractCompleteAlert);
