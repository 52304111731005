import axios from 'axios';

export const getAll = () => {
    return axios.get('/api/services/app/Institution/GetAll');
};

export const getById = id => {
    return axios.get('/api/services/app/Institution/Get', { params: { id } });
};

export const create = institution => {
    return axios.post('/api/services/app/Institution/Create', institution);
};
export const update = institution => {
    return axios.put('/api/services/app/Institution/Update', institution);
};
export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}