import React, { useEffect, useState } from "react";
import FileSaver from "file-saver";
import { useLocation, useParams } from "react-router";
import { exportWaitlistUsers, getWaitlistUsers } from "../api/programEvents";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@material-ui/core";
import SimpleDialog from "../../common/simpleDialog";
import LoadingIndicator from "../../common/loadingIndicator";
import WaitlistSettings from "./components/waitlistSettings";
import WaitlistUnenrollmentDialog from "./components/waitlistUnenrollmentDialog";
import WaitlistAddToEventDialog from "./components/waitlistAddToEventDialog";
import moment from "moment";
import EventHeader from "../common/eventHeader/eventHeader";
import useAjaxStatus from "../../common/useAjaxStatus";
import b64toBlob from "../../support/logic/b64toBlob";
import { makeStyles } from "@material-ui/styles";
import enumHelper from "../../support/logic/enumHelper";
import { UserType } from "../../support/enums";
import LoadingButton from "../../common/loadingButton";
import useProgramEvent from "../hooks/useProgramEvent";

const enrollmentListColumns = [
    {
        field: "firstName",
        headerName: "First name",
        flex: 1,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 1,
    },
    {
        field: "userType",
        headerName: "User Type",
        flex: 1,
        valueFormatter: ({ value }) => {
            return enumHelper.getDisplayName(UserType, value);
        },
    },
    {
        field: "crusaderId",
        headerName: "ID",
        flex: 1,
    },
    {
        field: "currentGrade",
        headerName: "Grade",
        flex: 1,
    },
    {
        field: "dateAdded",
        headerName: "Sign-up Time",
        width: 250,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
    },
    {
        field: "lastInvitationDate",
        headerName: "Last Invitation Date",
        flex: 1,
        valueFormatter: ({ value }) => {
            return value ? moment(value).format("M/D/YYYY h:mm a") : "";
        },
    },
];

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    buttonWrapper: {
        display: "inline-block",
    },
    subHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    participantsCounter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "large",
    },
    unenrollAction: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "large",
        color: "blue",
        cursor: "pointer",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
}));

const Waitlist = ({topUser }) => {
    const classes = useStyles();
    const { ajaxErrorWrapper } = useAjaxStatus();

    const {
        state: { rowData },
    } = useLocation();
    const { id } = useParams();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [waitlistCapacityAux, setWaitlistCapacityAux] = useState(rowData.waitlistCapacity);
    const [waitlistResponseTimeAux, setWaitlistResponseTimeAux] = useState(rowData.waitlistResponseTime);
    const [waitlistResponseDurationAux, setWaitlistResponseDurationAux] = useState(rowData.waitlistResponseDuration);
    const { programEvent, loading: loadingProgramEvent } = useProgramEvent(id);
    const [isWaitlistSettingsDialogOpen, setIsWaitlistSettingsDialogOpen] = useState(false);
    const [isWaitlistUnenrollDialogOpen, setIsWaitlistUnenrollDialogOpen] = useState(false);
    const [isWaitlistAddToEventDialogOpen, setIsWaitlistAddToEventDialogOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {

        //console.log(topUser);
        //console.log(programEvent);

        if (programEvent != null) {
            if (topUser != null) {
                if (topUser.advProf.isAdmin == false && topUser.advProf.isStaff == false && topUser.advProf.isSupervisor == false && topUser.userId != programEvent.creatorUserId) {
                    setReadOnly(true);
                }
            }

        }



    }, [topUser, programEvent]);

    const openWaitlistSettingsDialog = () => setIsWaitlistSettingsDialogOpen(true);
    const closeWaitlistSettingsDialog = () => setIsWaitlistSettingsDialogOpen(false);

    const openWaitlistUnenrollDialog = () => setIsWaitlistUnenrollDialogOpen(true);
    const closeWaitlistUnenrollDialog = () => setIsWaitlistUnenrollDialogOpen(false);

    const openWaitlistAddToEventDialog = () => setIsWaitlistAddToEventDialogOpen(true);
    const closeWaitlistAddToEventDialog = () => setIsWaitlistAddToEventDialogOpen(false);

    const getRows = () => {
        return registeredUsers.map((item) => {
            return {
                ...item,
                id: item.userProfileId,
                dateAdded: `${moment(item.dateAdded).format("MM/DD/YYYY h:mm a")}`,
            };
        });
    };

    const handleExport = async () => {
        try {
            setExporting(true);
            const resp = await exportWaitlistUsers(id, rowData.id);
            const blob = b64toBlob(resp.data.result.data, resp.data.result.mimeType);
            FileSaver.saveAs(blob, resp.data.result.fileName);
            setExporting(false);
        } catch (err) {
            setExporting(false);
            ajaxErrorWrapper(err);
        }
    };

    useEffect(() => {
        getWaitlistUsers(id, rowData.id)
            .then((resp) => {
                setRegisteredUsers(resp.data.result);
                setIsLoading(false);
            })
            .catch((err) => {
                ajaxErrorWrapper(err);
            });
    }, [ajaxErrorWrapper, id, rowData.id]);

    if (isLoading) {
        return <LoadingIndicator loading={isLoading} />;
    }

    return (
        <>
            <EventHeader mainTitle={rowData.programName} subTitle="Waitlist" date={rowData.date} />
            <div className={classes.subHeader}>
                {selectedUsers.length > 0 ? (
                    <div className={classes.actions}>
                        <Button color="primary" onClick={openWaitlistUnenrollDialog} disabled={readOnly}>
                            Remove from Waitlist
                        </Button>
                        <Button color="primary" disabled={readOnly} onClick={openWaitlistAddToEventDialog}>
                            Add to Event
                        </Button>
                    </div>
                ) : (
                    <span className={classes.participantsCounter}>
                        {registeredUsers.length}/{waitlistCapacityAux || 0}
                    </span>
                )}
                <div>
                    <Button onClick={openWaitlistSettingsDialog} className={classes.button} variant="outlined" color="primary">
                        Settings
                    </Button>
                    <LoadingButton
                        wrapperClassName={classes.buttonWrapper}
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={handleExport}
                        loading={exporting}
                    >
                        Export
                    </LoadingButton>
                </div>
            </div>
            <div style={{ height: "100%", width: "100%" }}>
                <DataGrid
                    rows={getRows()}
                    columns={enrollmentListColumns}
                    checkboxSelection
                    onSelectionModelChange={(values) => {
                        const _selectedUsers = registeredUsers.filter((user) => values.includes(user.userProfileId));
                        setSelectedUsers(_selectedUsers);
                    }}
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    disableColumnMenu
                    disableColumnFilter
                    autoHeight
                />
            </div>

            <SimpleDialog maxWidth="sm" open={isWaitlistSettingsDialogOpen} onClose={() => setIsWaitlistSettingsDialogOpen(false)} title="Waitlist Settings">
                <WaitlistSettings
                    closeSettingsDialog={closeWaitlistSettingsDialog}
                    id={id}
                    programEventDateId={rowData.id}
                    registeredUsersCount={registeredUsers.length}
                    setWaitlistCapacityAux={setWaitlistCapacityAux}
                    setWaitlistResponseTimeAux={setWaitlistResponseTimeAux}
                    setWaitlistResponseDurationAux={setWaitlistResponseDurationAux}
                    disabled={readOnly}
                />
            </SimpleDialog>

            <WaitlistUnenrollmentDialog
                isOpen={isWaitlistUnenrollDialogOpen}
                closeUnenrollmentDialog={closeWaitlistUnenrollDialog}
                selectedUsers={selectedUsers}
                setRegisteredUsers={setRegisteredUsers}
            />

            <WaitlistAddToEventDialog
                isOpen={isWaitlistAddToEventDialogOpen}
                closeWaitlistAddToEventDialog={closeWaitlistAddToEventDialog}
                selectedUsers={selectedUsers}
                setRegisteredUsers={setRegisteredUsers}
                waitlistResponseTime={waitlistResponseTimeAux}
                waitlistResponseDuration={waitlistResponseDurationAux}
            />
        </>
    );
};

export default Waitlist;
