import React from "react";
import { Field } from "redux-form";
import Datetime from "react-datetime";
import moment from "moment";
import { required, zip } from "../../../support/forms";
import normalizeZip from "../../../support/logic/normalizeZip";

import "./form.css";

const renderField = ({ input, disabled, placeholder, maxlength, meta: { touched, error } }) => {
    return (
        <div className="form-inner-div">
            <input className="form-input" disabled={disabled} {...input} placeholder={placeholder} type="text" maxLength={maxlength} />
            {touched && error && <span className="form-input-error">{error}</span>}
        </div>
    );
};

const DatePicker = ({ input, disabled, meta: { touched, error }, ...otherProps }) => {
    return (
        <div className="date-picker">
            <Datetime
                inputProps={{ readOnly: true, placeholder: otherProps.dateFormat, disabled }}
                value={input.value}
                onChange={(param) => input.onChange(param)}
                {...otherProps}
                dateFormat="MM/DD/YY"
                utc
            />
            {touched && error && (
                <span className="form-input-error" style={{ paddingTop: "10px", paddingRight: "10px" }}>
                    {error}
                </span>
            )}
        </div>
    );
};

const timePickerField = ({ input, disabled, className, meta: { touched, error } }) => {
    let value = input.value;

    if (value && typeof value === "string") {
        value = moment.utc(value).format("h:mm a");
    }
    return (
        <div className="date-picker">
            <Datetime
                {...input}
                inputProps={{ readOnly: true, disabled, placeholder: "HH:MM pm" }}
                value={value}
                dateFormat={false}
                timeFormat="h:mm A"
                viewMode="time"
                className={className}
                timeConstraints={{ hours: { min: 0, max: 23, step: 1 }, minutes: { min: 0, max: 59, step: 15 } }}
                utc
            />
            {touched && error && (
                <span className="form-input-error" style={{ paddingTop: "3px", paddingRight: "3px" }}>
                    {error}
                </span>
            )}
        </div>
    );
};

const EventForm = ({ handleCancel, handleSave, handleAdd, onToBeHeld, isDisabled }) => {
    const disabled = isDisabled ? "disabled" : "";

    return (
        <div className="admissions-event-form">
            <div>
                <button className="btn" onClick={handleAdd}>
                    Add New Student Orientation Event
                </button>
            </div>
            <div className="admissions-event-form-container">
                <div className="row">
                    <div className="date-container">
                        <div className="title">Date</div>
                        <Field name="date" component={DatePicker} dateFormat="M/D/YY" timeFormat={false} validate={[required]} disabled={disabled} />
                    </div>
                    <div>
                        <div className="title">Time</div>
                        <div className="time-range-container">
                            <Field name="startTime" className="time-input" component={timePickerField} validate={[required]} disabled={disabled} />
                            <div className="sep">-</div>
                            <Field name="endTime" component={timePickerField} className="time-input" validate={[required]} disabled={disabled} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div>
                        <div className="title">Maximum Number of Attendees</div>
                        <Field name="maxAttendees" component={renderField} type="number" disabled={disabled} validate={[required]} className="form-input" />
                    </div>
                </div>
                <div className="hq-container">
                    <label>
                        <Field name="heldAtHeadquarters" component="input" type="checkbox" onClick={onToBeHeld} disabled={disabled} />
                        <span style={{ marginLeft: 10 }}>To be held at Onward We Learn headquarters</span>
                    </label>
                </div>
                <div className="title">Name &amp; Address</div>

                <Field name="name" component={renderField} placeholder="Name of Event" validate={[required]} maxlength="200" disabled={disabled} />

                <Field name="address1" component={renderField} placeholder="Address 1" maxlength="100" disabled={disabled} />
                <Field name="address2" component={renderField} placeholder="Address 2" maxlength="100" disabled={disabled} />

                <div className="row">
                    <Field name="city" component={renderField} placeholder="City" maxlength="50" disabled={disabled} />
                    <Field name="state" component={renderField} disabled="disabled" placeholder="State" />
                    <Field
                        name="zipCode"
                        component={renderField}
                        placeholder="Zip"
                        validate={[zip]}
                        normalize={normalizeZip}
                        disabled={disabled}
                        maxlength="10"
                    />
                </div>

                <div className="title">Notes</div>

                <Field name="notes" component="textarea" placeholder="Notes" disabled={disabled} />

                <div className="notes-info">Text will not automatically translate. Please include all necessary translations.</div>

                <div className="row">
                    <button className="btn" onClick={handleSave} disabled={disabled}>
                        Save
                    </button>
                    <button className="link-button" onClick={handleCancel} disabled={disabled}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EventForm;
