import React from "react";
import { Field } from "react-final-form";
import makeStyles from "@material-ui/styles/makeStyles";
import { FinalFormDropdown, FinalFormTextField } from "../../../common/formWrappers";
import { required } from "../../../common/formValidators";

const useStyles = makeStyles((theme) => ({
    eventBasicInfo: {
        width: "60%",
        display: "grid",
        gridTemplateColumns: "25% 25% 25% 25%",
        gridTemplateRows: "auto",
        gridTemplateAreas: `"department department season ." "name name sname sname"`,
        columnGap: theme.spacing(2),
    },
    departmentDropdown: {
        gridArea: "department",
    },
    seasonDropdown: {
        gridArea: "season",
    },
    nameTextField: {
        gridArea: "name",
    },
    sNameTextField: {
        gridArea: "sname",
    },
}));

const EventBasicInfo = ({ departmentOptions, seasons }) => {
    const classes = useStyles();

    return (
        <div className={classes.eventBasicInfo}>
            <Field
                component={FinalFormDropdown}
                className={classes.departmentDropdown}
                name="department"
                variant="outlined"
                margin="dense"
                required
                fullWidth
                label="Department"
                choices={departmentOptions}
                placeholder="Select a Department..."
                validate={required}
            />
            <Field
                component={FinalFormDropdown}
                className={classes.seasonDropdown}
                name="seasonId"
                variant="outlined"
                margin="dense"
                required
                fullWidth
                label="Season"
                choices={seasons}
                placeholder="Select a Season..."
                validate={required}
            />
            <Field
                component={FinalFormTextField}
                className={classes.nameTextField}
                name="name"
                variant="outlined"
                margin="dense"
                required
                fullWidth
                label="Event Name"
                maxLength={100}
                placeholder="Event Name"
                validate={required}
            />
            <Field
                component={FinalFormTextField}
                className={classes.sNameTextField}
                name="spanishName"
                variant="outlined"
                margin="dense"
                fullWidth
                label="Event Name (Spanish)"
                maxLength={100}
                placeholder="Event Name (Spanish)"
            />
        </div>
    );
};

export default EventBasicInfo;
