import React, { Component, Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

class UserConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.message && props.message !== ""
    };
  }
  onConfirm = () => {
    this.setState(
      {
        show: false
      },
      () => {
        this.props.onConfirm();
      }
    );
  };
  onCancel = () => {
    this.setState(
      {
        show: false
      },
      () => {
        this.props.onCancel();
      }
    );
  };
  render() {
    return (
      <Fragment>
        <SweetAlert
          show={this.state.show}
          title={this.props.title}
          showCancel
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          confirmBtnText={this.props.yes}
          cancelBtnText={this.props.cancel}
        >
          {this.props.message}
        </SweetAlert>
      </Fragment>
    );
  }
}

UserConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  yes: PropTypes.string.isRequired,
  cancel: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UserConfirm;
