import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";

import Button from "../../support/components/Button";

import { required, renderField } from "../../support/forms";

import "./LoginUI.css";
import "../index.css";

export const LoginUI = ({
    handleSubmit,
    onLocaleChange,
    onForgotPassword,
    onForgotUserName,
    onCreate,
    onLogin,
    onRememberMeChange,
    locale,
    isRememberMe,
    isStaff,
    onStaffLogin,
}) => {
    return (
        <div className="login-ui">
            <form className="login-details login-details-top" onSubmit={handleSubmit(onLogin)} noValidate>
                {!isStaff ? (
                    <Fragment>
                        <div className="logo" alt="logo" />
                        <FormattedMessage
                            id="login.titleStudent"
                            defaultMessage="[Student Application]"
                            children={(formatedMessage) => <h1 className="screen-title">{formatedMessage}</h1>}
                        />
                        <FormattedMessage
                            id="login.language"
                            defaultMessage="Select a preferred language"
                            children={(formatedMessage) => <span className="login-label">{formatedMessage}</span>}
                        />
                        <select onChange={onLocaleChange} value={locale}>
                            <FormattedMessage
                                id="login.english"
                                defaultMessage="English"
                                children={(formatedMessage) => <option value="en">{formatedMessage}</option>}
                            />
                            <FormattedMessage
                                id="login.spanish"
                                defaultMessage="Spanish"
                                children={(formatedMessage) => <option value="es-MX">{formatedMessage}</option>}
                            />
                        </select>

                        {/* <div className="login-staff-login-row">
                            <FormattedMessage
                                id="login.subTitle"
                                defaultMessage="Login for existing Accounts"
                                children={(formatedMessage) => <h2 className="screen-title">{formatedMessage}</h2>}
                            />
                            <FormattedMessage
                                id="login.staffLogin"
                                defaultMessage="[staff login]"
                                children={(formatedMessage) => (
                                    <button className="link-button" onClick={onStaffLogin} type="button">
                                        {formatedMessage}
                                    </button>
                                )}
                            />
                        </div> */}
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="logo" alt="logo" style={{ marginTop: "12px", paddingBottom: "50px" }} />
                    </Fragment>
                )}
                <FormattedMessage
                    id="login.userNameEmail"
                    defaultMessage="[User Name Email]"
                    children={(formattedMessage) => (
                        <Field name="userNameEmail" type="text" component={renderField} label={formattedMessage} validate={[required]} />
                    )}
                />
                <div className="login-forgot-pwd-name">
                    <FormattedMessage
                        id="forgot.userName"
                        defaultMessage="[forgot user name]"
                        children={(formatedMessage) => (
                            <button className="link-button forgot-userName" onClick={onForgotUserName} type="button">
                                {formatedMessage}
                            </button>
                        )}
                    />
                </div>
                <FormattedMessage
                    id="login.password"
                    defaultMessage="Password"
                    children={(formattedMessage) => (
                        <Field name="password" type="password" component={renderField} label={formattedMessage} validate={[required]} />
                    )}
                />
                <div className="login-forgot-pwd-name">
                    <FormattedMessage
                        id="login.forgot"
                        defaultMessage="Forgot Password?"
                        children={(formatedMessage) => (
                            <button className="link-button forgot-pwd" onClick={onForgotPassword} type="button">
                                {formatedMessage}
                            </button>
                        )}
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="login.remember"
                        defaultMessage="Remember Login Information"
                        children={(formatedMessage) => (
                            <label htmlFor="login_remember_id">
                                <input type="checkbox" id="login_remember_id" onChange={onRememberMeChange} checked={isRememberMe} />
                                {formatedMessage}
                            </label>
                        )}
                    />
                </div>
                <Button id="login.login" type="submit" defaultMessage="Login" onClick={handleSubmit(onLogin)} className="login-btn" />
                {!isStaff ? (
                    <Fragment>
                        <div className="login-line-container">
                            <div className="login-line" />
                        </div>
                        <div className="login-detail login-create-account-section">
                            <FormattedMessage
                                id="login.noAccount"
                                defaultMessage="Don't have an account yet?"
                                children={(formatedMessage) => <h2 className="screen-title">{formatedMessage}</h2>}
                            />
                            <Button
                                id="login.createNew"
                                type="button"
                                defaultMessage="CREATE A NEW ACCOUNT"
                                onClick={onCreate}
                                className="login-btn login-create-account"
                            />
                        </div>
                    </Fragment>
                ) : null}
            </form>
        </div>
    );
};

LoginUI.propTypes = {
    locale: PropTypes.string.isRequired,
    onLocaleChange: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onForgotUserName: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onRememberMeChange: PropTypes.func.isRequired,
    isRememberMe: PropTypes.bool.isRequired,
    email: PropTypes.string,
    isStaff: PropTypes.bool,
    onStaffLogin: PropTypes.func.isRequired,
};

LoginUI.defaultProps = {
    isStaff: false,
};

export default reduxForm({
    form: "loginui",
})(LoginUI);
