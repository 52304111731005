import axios from "axios";

export const getList = () => {
    return axios.get("/api/services/app/School/GetList");
};

export const getAll = () => {
    return axios.get("/api/services/app/School/GetAllForAdmin");
};

export const getById = (id) => {
    return axios.get("/api/services/app/School/Get", { params: { id } });
};

export const create = (school) => {
    return axios.post("/api/services/app/School/Create", school);
};

export const update = (school) => {
    return axios.put("/api/services/app/School/Update", school);
};

export const getDistrict = (id) => {
    return axios.get("/api/services/app/School/GetDistrict", { params: { id } });
};

export const getAllDistricts = (cancelToken) => {
    return axios.get("/api/services/app/School/GetAllDistricts", { cancelToken });
};

export const createDistrict = (district) => {
    return axios.post("/api/services/app/School/CreateDistrict", district);
};

export const updateDistrict = (district) => {
    return axios.put("/api/services/app/School/UpdateDistrict", district);
};
